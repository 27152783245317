$bp--flex-widths: 720px;
$bp--back-un-stack: 1130px;

.product__shade-sticky {
  overflow: visible;
  position: fixed;
  z-index: $z-highest;
  top: 60px;
}

input#filter-grid-switch {
  display: none;
}

.filter-grid-switch--label {
  display: block;
}

.shade-picker-filter-grid {
  @include transform(translate3d(0, 0, 0));
  @include transition-property(all);
  @include transition-duration(0.3s);
  @include transition-timing-function(ease-in);
  position: relative;
  #{$rdirection}: -200%; // IE8 fallback
  #{$rdirection}: -100vw; // position offscreen to the right
  z-index: 40;
  background-color: $color--white;
  width: 100vw;
  .filter-grid-open & {
    #{$rdirection}: 300%;
    @include breakpoint($width-xxlarge) {
    }
  }
}

.shade-picker-full__colors-mask {
  overflow: auto;
  position: relative;
  &.subnav-shift {
    top: 205px;
    .shade-picker-filter-grid.color & {
      top: 59px;
    }
  }
}

@include animate(show-shade) {
  0% {
    opacity: 0;
    width: 0%;
  }
  100% {
    opacity: 1;
    width: 25%;
  }
}

@include animate(hide-shade) {
  0% {
    opacity: 1;
  }
  50% {
    width: 25%;
  }
  100% {
    opacity: 0;
    width: 0%;
  }
}

.shade-picker-filter-grid-sizer {
  width: 25%;
  //width: 25vw; // @todo this fails in this display comment for now
}

// Hide filterable elements for MixItUp script
// https://mixitup.kunkalabs.com/learn/tutorial/get-started/#hide-target-elements
.shade-picker-filter-grid {
  .shade-picker__color {
    display: none;
  }
}

.shade-picker-full__colors {
  .shade-picker__color {
    width: 25%;
    //width: 25vw; // @todo this fails in this display comment for now
    float: #{$ldirection};
  }
}

.shade-picker-filter-grid--placeholder {
  width: 100%;
  width: 100vw;
  position: relative;
  z-index: 3;
  @include display_flex();
  justify-content: flex-start;
  margin-top: 60px;
}

.shade-picker-filter-grid--options {
  background-color: $color--white;
  border: {
    width: 1px 0;
    style: solid;
    color: $color--gray--lighter;
  }
  width: 100%;
  margin-top: 60px;
  min-height: 60px;
  position: relative;
  z-index: 3;
  @include display_flex();
  justify-content: flex-start;
  align-items: stretch;
  .will-expand,
  label {
    cursor: pointer;
  }
  > li {
    border: {
      width: 0 1px 0 0;
      style: solid;
      color: $color--gray--lighter;
    }
  }
  li {
    @include layout(8) {
      @include display_flex();
      flex-grow: 1;
      @include breakpoint($bp--flex-widths) {
        flex-basis: initial;
        flex-grow: 0;
      }
    }
    height: 60px;
    &.shade-picker-filter-grid--filters--clear {
      .filter-clear-all {
        bottom: 0;
        cursor: pointer;
        display: none;
        padding-#{$rdirection}: 15px;
        float: #{$rdirection};
        position: absolute;
        #{$rdirection}: 0;
        width: auto;
      }
      &.is-active {
        .filter-clear-all {
          display: block;
        }
      }
      &:not(.is-active) {
        border-#{$rdirection}-width: 0;
      }
      @include breakpoint($bp--flex-widths) {
        flex: 1;
      }
    }
    label {
      @include swap_direction(padding, 15px);
      @include display_flex();
      flex-direction: column;
      justify-content: space-between;
      p {
        margin-bottom: 0;
      }
    }
    > a {
      @include subnav-trade-cond;
      @include unset-underline-links($extend: false);
      align-self: flex-end;
      @include swap_direction(padding, 15px 25px 5px 15px);
      width: 100%;
      position: relative;
    }
    .corner-arrow {
      &:after {
        display: block;
        position: absolute;
        content: '';
        bottom: 5px;
        #{$rdirection}: 5px;
        width: 0;
        height: 0;
        border: {
          width: 7.5px;
          style: solid;
          color: transparent $color--black $color--black transparent;
        }
      }
    }
    > .filters-label {
      align-self: flex-end;
      @include swap_direction(padding, 15px 15px 5px);
    }
    > ul {
      @include single-transition(
        max-height,
        $default-transition-duration,
        $default-transition-function
      );
      @include reverse;
      background-color: $color--black;
      @include display_flex();
      position: absolute;
      top: 60px;
      #{$ldirection}: 0;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      justify-content: flex-start;
      align-items: stretch;
      align-content: stretch;
      .no-flexbox & {
        display: block;
        float: #{$ldirection};
        @include layout(8) {
          width: grid-span(1, 1);
        }
      }
      h3 {
        @include subnav-trade-cond;
      }
      li {
        position: relative;
        min-height: 140px;
        height: inherit;
        overflow: hidden;
        @include layout(6) {
          flex-basis: grid-span(1, 1);
        }
        &:hover {
          background-color: $color--gray--black;
        }
        .picker {
          &.picker-checkbox {
            width: 100%;
          }
        }
      }
      &.option-colour {
        li {
          min-height: 60px;
        }
      }
      .icon--check {
        display: none;
        position: absolute;
        top: 15px;
        #{$rdirection}: 15px;
        width: 1em;
        height: 1em;
        font-size: 1.5em;
      }
      input[type='checkbox']:checked {
        ~ label {
          .icon--check {
            display: block;
          }
        }
      }
    }
    &.will-expand {
      @include single-transition(
        margin-bottom,
        $default-transition-duration,
        $default-transition-function
      );
      margin-bottom: 0;
    }
    &.will-expand.hover {
      @include reverse;
      > a {
        position: relative;
        &:after {
          border-color: $color--white transparent transparent $color--white;
        }
      }
      > {
        ul {
          max-height: 239px;
          &.option-colour {
            max-height: 60px;
            li {
              .picker-checkbox {
                width: 100%;
              }
            }
          }
        }
      }
    }
    &.shade-picker-filter-grid--filters--clear {
      text-align: #{$rdirection};
      a {
        color: $color--gray;
      }
    }
  }
  input[type='checkbox'] {
    display: none;
  }
  // @todo see if we still use the form elements markup above
  // remove if no this looks like there were changes to the form js
  .picker {
    .picker-handle {
      @include swap_direction(border, none);
      position: absolute;
      #{$rdirection}: 0;
    }
    .picker-label {
      color: $color--white;
      padding-top: 0;
      padding-#{$rdirection}: 0;
    }
  }
  .picker-checkbox.picker .picker-flag:before {
    color: $color--white;
  }
}

.shade-picker-filter-grid--filters--sort {
  span {
    @include body-mono();
    display: block;
  }
}

.product__bag-bar--left-arrow {
  .add-to-bag-bar__icon {
    font-size: 16px;
    top: auto;
    &:before {
      content: map-get($icons, arrow--left);
      @include breakpoint($bp--xxlarge-up) {
        content: map-get($icons, arrow--left-medium);
      }
    }
    margin-#{$ldirection}: 10px;
    + span {
      @include transform(translateY(-10px) translateX(flipX(-2rem, #{$rdirection})));
      display: block;
      @include breakpoint($bp--xlarge-up) {
        @include transform(none);
        display: inline;
        margin-#{$rdirection}: 1em;
        float: #{$rdirection};
      }
    }
  }
}

.fav--bag--buttons {
  @include transition(visibility 0s linear);
  @include reverse;
  float: #{$rdirection};
  position: relative;
  visibility: hidden;
  z-index: 1;
  .shade-picker__color-wrapper:hover & {
    visibility: visible;
    .touch & {
      @include transition-delay(300ms);
    }
  }
  a {
    border-bottom-width: 0;
    float: #{$rdirection};
    height: 60px;
    @include swap_direction(margin, 0);
    position: relative;
    width: 60px;
    &.product__add-to-faves {
      margin-top: 20px;
      width: 40px;
    }
    // font icons
    i {
      position: absolute;
      font-size: 21px;
      #{$ldirection}: 20px;
      top: 20px;
    }
  }
  .product__inventory-status {
    text-align: center;
    height: 60px;
    @include swap_direction(padding, 0 6px);
    display: none;
    .product__inv-status-item {
      @include vertically-center;
      @include swap_direction(margin, 0);
      line-height: 1;
    }
  }
}

// hiding buttons based on inventory status
.shade-picker__color-wrapper:hover {
  .fav--bag--buttons.prod_inv_status-3,
  .fav--bag--buttons.prod_inv_status-5,
  .fav--bag--buttons.prod_inv_status-7 {
    a {
      display: none;
    }
    .product__inventory-status {
      display: block;
    }
  }
  .fav--bag--buttons.prod_inv_status-2.product__inventory-status {
    display: none;
  }
}

.shade-picker__color-wrapper:hover {
  .fav--bag--buttons.hide-non-shoppable-product {
    &.prod_inv_status-3 {
      li.js-inv-status-3.coming-soon {
        display: block;
      }
    }
    &.prod_inv_status-5 {
      li.js-inv-status-3.coming-soon {
        display: block;
      }
    }
    &.prod_inv_status-7 {
      li.js-inv-status-7.sold-out {
        display: block;
        @include swap_direction(padding, 22px 20px 22px 20px);
      }
    }
    display: block !important;
  }
}
