/* ==========================================================================
   Components Molecules - Blocks, Image Hover
   ========================================================================== */

.block-image-hover {
  position: relative;
  color: $color--black;
  display: block;
  //  max-width: 240px;

  // @TODO: clean this crap up, leftover from proto
  &:hover,
  &.focused {
    background: $color--black;
    color: $color--white;
    .block-image-hover__image {
      opacity: 0;
    }
    .block-image-hover__title {
      background-color: $color--black !important;
      border-color: $color--black !important;
      color: $color--white !important;
    }
    .block-image-hover__content {
      display: block;
    }
  }
}

.block-image-hover__title {
  @include antialiased;
  font-family: $headline-font-family;
  border-#{$ldirection}: 1px solid #dfdfdf;
  color: $color--black;
  height: 52px;
  font-size: 17px;
  line-height: 17px;
  @include swap_direction(padding, 0 20px);
  @include swap_direction(margin, 0);
  @if $fonts_update == true {
    font-family: $ano-bold_regular-font;
    font-size: 18px;
    font-weight: bold;
  }
  @include breakpoint(max-width 1185px) {
    font-size: 14px;
  }
}

// inner span to center title text
.block-image-hover__title-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.block-image-hover__image {
}

.block-image-hover__content {
  position: absolute;
  top: 52px;
  height: 240px;
  width: 240px;
  color: $color--white;
  display: none;
  font-family: $body-font-family;
  font-size: 12px;
  @include swap_direction(padding, 0);
}

.block-image-hover__excerpt {
  @include swap_direction(padding, 10px 0 0 20px);
  width: 100%;
  @if $fonts_update == true {
    font-family: $helvetica-font;
    font-size: 15px;
    letter-spacing: 0;
  }
}

.block-image-hover__link {
  bottom: 20px;
  #{$ldirection}: 20px;
  position: absolute;
  text-decoration: underline;
  @if $fonts_update == true {
    @include h6();
    font-size: 15px;
  }
  &:hover {
    text-decoration: none;
  }
}
