/* ==========================================================================
   Global Icons - Flags, Icon Base
   ========================================================================== */

/*
 * Taken from `flag-icon-css` project:
 * https://github.com/lipis/flag-icon-css
 */

$flag-icon-css-path: '/media/export/cms/flags' !default;
$flag-icon-rect-path: '/4x3' !default;
$flag-icon-square-path: '/1x1' !default;

[class*='flag-icon-'] {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 1em;
  position: relative;
  width: (4 / 3) * 1em;
  &:before {
    content: '\00a0';
  }
  &.flag-icon-squared {
    width: 1em;
  }
}

/* Mixin - Flag Icon
   ========================================================================== */
/*
 * @params
 *  $country
 */

@mixin flag-icon($country) {
  background-image: (url('#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg'));
  &.flag-icon-squared {
    background-image: (url('#{$flag-icon-css-path}#{$flag-icon-square-path}/#{$country}.svg'));
  }
}
