$xsmall-down: 359px;

.btn {
  width: 100%;
  margin: 10px 0;
  padding: 0 10px;
}

#links-panel {
  margin-top: 1em;
}

#favorites-panel {
  .product-list {
    .add-to-cart {
      input[type='submit'].btn-mini {
        padding: 0 10px;
        font-size: 10px;
      }
    }
  }
}

.chronopay_message {
  float: left;
}

.order-summary__tax {
  &-label,
  &-value {
    display: none;
  }
}

.site-container {
  .prod_inv_status-3 {
    .product__footer {
      a.notify_me {
        display: block !important;
      }
    }
  }
  .product__link-to-spp,
  .view-all-shades {
    #sku_count {
      display: none;
    }
  }
  .artistry--artist {
    .product--teaser {
      .product__detail {
        .product__header {
          .product__price {
            font-family: $roboto-condensed-bold-font;
          }
        }
      }
    }
  }
  .page--spp__product {
    .product__footer {
      .product__price {
        font-family: $roboto-condensed-bold-font;
      }
    }
    .product__detail {
      .product__name {
        font-size: 2.3rem;
      }
    }
  }
  .product__detail {
    .product__name {
      word-wrap: inherit;
    }
  }
  .culture-detail {
    &__article-content {
      .culture__social-links {
        width: 6.5em;
      }
    }
  }
  .artistry-video-player__products {
    .product__footer {
      .product_content_fav_mobile {
        top: 0;
      }
    }
  }
  @include breakpoint($bp--xsmall-down) {
    .sec-nav__title-category,
    .sec-nav__title {
      font-size: 19px !important;
    }
  }
  .grid--mpp__item {
    .product--teaser {
      .product__header {
        .product__price {
          font-family: $roboto-condensed-bold-font;
        }
        .product__name {
          @include breakpoint($bp--xsmall-down) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
  @media (min-width: $xsmall-down) and (max-width: $width-xsmall) {
    .customer-service__page-content {
      h2 {
        font-size: 3.5rem;
      }
    }
  }
  @media (max-width: $xsmall-down) {
    .customer-service__page-content {
      h2 {
        font-size: 3rem;
      }
    }
  }
  .custom-grid {
    &__item-inner {
      .text-block--quote {
        .footer {
          bottom: 0px;
        }
      }
      .media-block {
        &__cta {
          bottom: 5px;
        }
      }
    }
  }
}

.artistry-video-player-wrapper {
  .product--teaser {
    .product__detail {
      h3.product__name {
        font-size: 1.3rem;
      }
    }
    .product__footer {
      .product__add-to-bag {
        font-size: 12px;
        padding: 0 3px;
        height: 60px;
        line-height: 60px;
      }
      .product_content_fav_mobile {
        .product__link-to-spp {
          font-size: 12px;
          padding: 0 3px;
          height: 60px;
          line-height: 60px;
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    .align-r {
      .input-btn {
        width: auto;
        @include swap_direction(padding, 11px);
      }
    }
  }
}

.store_landing_only {
  .tout-block-landscape__body-above {
    .tout-block-landscape__header-image {
      background-image: url('/media/export/cms/locator/makeup-services_ru.png');
    }
  }
}

.section-stores {
  .store_landing_only {
    .tout-block-landscape__cta {
      line-height: 25px;
    }
  }
}

.appt-book {
  #appointment-book-sections {
    .my-appointments {
      .appointments-container.past {
        .appointment-details {
          .date-time {
            width: 75%;
          }
        }
      }
    }
    .appointment-select {
      .appointments-container {
        .service_tile_wrapper {
          padding: 21px 15px 20px 6px;
        }
      }
    }
  }
  .book-now-bar {
    .price {
      text-transform: uppercase !important;
    }
  }
  .confirm {
    .confirm-container {
      .sign-in-container {
        .sign-in-link-container {
          h4 {
            margin-top: auto;
            text-indent: 10px;
            float: none;
          }
          a {
            float: left;
          }
        }
      }
    }
  }
  .confirmation,
  .my-appointments {
    .confirmation-container,
    .appointment-details {
      .date-time {
        .appt-date-cal {
          background-position: 11.5em 0.25em !important;
        }
      }
    }
  }
}

.submenu--sticky.appointment-nav {
  .submenu__content {
    .submenu__header {
      .appointment-nav__link.appointment-header-nav__link {
        background: #f1f2f2 url('/media/export/cms/makeup_services/gnav_hd_make_services_ru.png') no-repeat center
          center;
        background-size: 190px auto;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  #ship-calc-overlay {
    .close-popup {
      margin-left: 0 !important;
    }
  }
}

.shipping-calc-results {
  display: block;
  padding-left: 5px;
  .shipping-cost {
    .country-container {
      float: left;
      margin-bottom: 5px;
    }
  }
}

@media screen and (min-width: 767px) {
  .shipping-calc-results {
    .shipping-cost {
      .country-container {
        float: none !important;
        display: block;
      }
    }
  }
}

.custom-grid {
  .carousel {
    &.carousel--has-dots {
      &.basic-carousel-item-formatter {
        height: 200px !important;
      }
    }
  }
}

.device-mobile {
  .shipping-cost-widget {
    margin-bottom: -5px;
    .shipping-cost {
      margin: 1.5em 0;
    }
    .shipping-price {
      margin-right: 5px;
    }
    .shipping-title {
      font-family: $roboto-condensed-bold-font;
      text-transform: uppercase;
      line-height: 18px;
      margin-bottom: 5px;
    }
    .city_region {
      text-transform: uppercase;
    }
  }
  a.shipping_calc {
    font-size: 1rem;
    font-family: 'roboto-mono_regular', Helvetica, Arial, Sans-Serif;
    line-height: 1.5;
    cursor: pointer;
    display: inline-block;
  }
  #ship-calc-overlay {
    .country-container {
      float: left;
      width: 100%;
      margin-bottom: 10px;
      input,
      select {
        width: 100%;
        float: left;
      }
    }
    .calculation-result {
      .shipping-type {
        font-weight: bold;
      }
    }
    .close-popup {
      padding: 10px 10px 9px;
      background: $color--black;
      margin-left: 15px;
      font-family: $roboto-condensed-bold-font;
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      color: $color--white;
      float: right;
      border: none;
    }
    .input-btn {
      border: none;
      padding: 10px 10px 9px;
      background: $color--black;
      font-family: $roboto-condensed-bold-font;
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      color: $color--white;
    }
    .missing_region,
    .missing_city {
      color: $color--red !important;
    }
  }
  .shipping-calc-link {
    display: inline-block;
    width: 100%;
  }
}

@media (max-width: 1022px) {
  .page--spp__product {
    .product--full {
      .product__add-to-bag {
        background-color: black;
        color: white;
        line-height: 60px;
        text-align: center;
        vertical-align: middle;
        width: auto;
        display: block !important;
      }
    }
  }
}

.product--full {
  .product__footer {
    .product__sticky-footer {
      .product__price {
        line-height: 1.25;
      }
    }
  }
}

.mpp-filter-with-attributes {
  top: auto;
  margin-top: -110px;
  bottom: auto;
  height: 100%;
}

.product__footer {
  .product__size {
    padding-left: 15px;
  }
}

.product_content_fav_mobile {
  li.shipping-calc-link {
    padding-left: 5px;
    padding-top: 10px;
  }
}

.product__description--compact.expando-block {
  margin-left: 0px;
}

#cboxContent {
  #cboxClose {
    margin-right: auto;
  }
}

.device-mobile {
  .shipping-cost-widget {
    p {
      margin-bottom: 0.5em;
    }
  }
}

.viewcart-panel__content {
  .cart-item {
    .mobile_visibility {
      display: none;
    }
  }
}

body {
  height: auto !important;
}
