body,
.product__description-trigger-title,
.add-to-bag-bar__shade-desc,
.site-header .site-header__tools .site-my-mac__link {
  font-family: $roboto-condensed-regular-font;
}

.site-header {
  font-family: $roboto-condensed-bold-font;
}

.site-footer {
  &--top {
    font-family: $roboto-condensed-regular-font;
    h6 {
      font-family: $roboto-condensed-bold-font;
    }
  }
  &--bottom {
    font-family: $roboto-condensed-bold-font;
  }
}

.site-header li.site-navigation__item,
.site-header li.site-navigation__more {
  //Compensate for the slightly bigger font size.
  line-height: 57px;
}

.product--teaser .product__name,
.product--full .product__name,
.sec-nav__title,
.sec-nav__link,
.sec-nav__sub-link,
.view-all-shades {
  font-family: $roboto-condensed-bold-font;
}

.product--teaser .product__name {
  line-height: 1.6rem;
}

.section-makeup-services {
  h1.content-4col__headline,
  h6 {
    font-family: $roboto-condensed-bold-font;
  }
  p {
    font-family: $roboto-condensed-regular-font;
  }
}

.customer-service__menu {
  font-family: $roboto-condensed-regular-font;
  h6 {
    font-family: $roboto-condensed-bold-font;
  }
}

.contact-us-page {
  font-family: $roboto-condensed-regular-font;
  .title {
    font-family: $roboto-condensed-bold-font !important;
  }
}

.customer-service__page {
  &-content {
    font-family: $roboto-condensed-regular-font;
    p {
      font-family: $roboto-condensed-regular-font;
    }
    h1.mac-faq__heading,
    .mac-faq__q,
    h4 {
      font-family: $roboto-condensed-bold-font;
    }
    .mac-faq__a {
      font-family: $roboto-condensed-regular-font;
    }
    .narrowed_body_copy {
      font-family: $roboto-condensed-regular-font;
      p {
        font-family: $roboto-condensed-regular-font;
      }
      span,
      h2,
      h5,
      b,
      strong {
        font-family: $roboto-condensed-bold-font;
      }
    }
  }
}

.block-image-hover__content {
  font-family: $roboto-condensed-regular-font;
}
