/* ==========================================================================
   Components Organisms - Cardstack
   ========================================================================== */

/*
 * The card masked functionality seen on collections landing
 */

.collection-landing-brick-card-mask__image {
  height: 492px;
  position: relative;
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.collection-landing-brick-card-mask__caption {
  @include swap_direction(padding, 15px);
}

.shop-the-collection__carousel-slidee {
  .shop-the-collection__carousel-item {
    &.plus {
      a.collection-item-expand {
        background: url('/media/export/cms/collections/indicator_plus.png');
        height: 26px;
        width: 26px;
      }
    }
    &.minus {
      a.collection-item-expand {
        background: url('/media/export/cms/collections/indicator_minus.png');
        height: 26px;
        width: 26px;
      }
    }
  }
}
