/* ==========================================================================
   Components Organisms - Mobile Nav
   ========================================================================== */
.mobile-navigation {
  display: none;
}

.mobile-nav__overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  background-color: $color--black;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 9998;
  .sidr-open & {
    display: block;
  }
}

.sidr {
  display: none;
  position: fixed;
  top: 60px;
  padding-bottom: 72px;
  @include antialiased;
  font-family: $headline-font-family;
  height: calc(100% - 60px);
  z-index: 999999;
  width: 87.5%; // 560px wide at 640 breakpoint
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 15px;
  @include reverse;
  .contextual-links-container {
    display: none !important;
    visibility: hidden !important;
  }
  &.left {
    #{$ldirection}: -100%;
    #{$rdirection}: auto;
  }
  .site-navigation {
    #{$ldirection}: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .mm-whats-new-items-mobile {
    #{$ldirection}: 0;
    position: absolute;
    width: 100%;
  }
  .field-menu {
    //padding: 0 10px 0 20px;
  }

  // top level menu
  .menu[menu-sub='0'] {
    color: $color--gray--light;
    a {
      color: $color--gray--light;
    }
    // change color for the current menu
    &.is-current,
    &.is-current a {
      color: $color--white;
    }
    /// apply padding to every top level li
    & > li {
      padding-#{$ldirection}: 20px;
      padding-#{$rdirection}: 10px;
    }
    // WHATS NEW menu items should go full width.
    & li.mm-whats-new-items-mobile-menu {
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
      // indent the menu title
      .menu--title {
        padding-#{$ldirection}: 20px;
        padding-#{$rdirection}: 10px;
      }
    }
    // WHATS NEW menu items optionally as links no images
    & .mm-whats-new-item-mobile__link {
      padding-#{$ldirection}: 40px;
      padding-#{$rdirection}: 10px;
      line-height: 36px;
      padding-top: 24px;
    }
    // last first level link
    & > li.last {
      padding-bottom: 24px;
    }
  }

  // expandable container
  .menu-item-container {
    // disable default chrome outline on focus
    outline: none;
    .menu-container .menu--title,
    .menu-container a {
      // default menu link color
      color: $color--gray--light;
    }
    &.is-current .menu-container .menu--title,
    &.is-current a {
      // current expanded menu default color
      color: $color--white;
    }
  }
  .menu-item-container.is-current a {
    // current expanded menu default color
    color: $color--white;
  }
  .menu-container {
    display: none;
    margin-#{$ldirection}: 20px;
  }
  // override for WHATS NEW menu
  .mm-whats-new-items-mobile-menu .menu-container {
    margin-#{$ldirection}: 0;
  }
  li {
    cursor: pointer;
    display: block;
    @include fontsize-rem($typesize--30px);
    overflow: hidden;
    text-transform: uppercase;
    line-height: 36px;
    padding-top: 24px;
    @if $fonts_update == true {
      @include h6();
      font-size: 18px;
    }
    // override for WHATS NEW menu
    &.mm-whats-new-items-mobile-menu {
      padding-top: 12px;
    }
  }
  a {
    border-bottom: none;
    display: block;
    @include fontsize-rem($typesize--30px);
    overflow: hidden;
    text-transform: uppercase;
    line-height: 1.1em;
    @if $fonts_update == true {
      @include h6();
      font-size: 18px;
    }
  }
} // .sidr
