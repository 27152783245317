.samples {
  .details {
    float: right;
  }
}

.checkout {
  .recommended-products-panel {
    display: none;
  }
  .cart-items {
    .cart-item {
      &__qty {
        font-size: 0.8rem;
        line-height: 18px;
      }
      &__total {
        width: 33%;
        @include breakpoint($bp--medium-up) {
          width: 13.3333%;
        }
      }
    }
  }
  .signin-panel__title {
    display: none;
  }
  #shipping-panel {
    .ship-method-home-group,
    .ship-method-collection-group {
      label {
        display: none;
      }
    }
  }
  .gift-options {
    display: none;
  }
  #confirmation-page {
    .field-are-required {
      display: none;
    }
  }
}

.delivery_method {
  label {
    display: block;
  }
}

#viewcart-panel {
  .cart-header__title {
    font-size: 21px;
  }
}

#offer-code-panel p.note {
  display: none;
}

.offer-code-overlay .offer-code__content {
  margin: 40px 0 0;
}

.viewcart,
.checkout {
  .responsive-container {
    .checkout__content {
      .messages-container {
        .single-message {
          padding: 0px;
        }
      }
    }
  }
}

.viewcart {
  #links-panel {
    display: none;
  }
  .site-container {
    #order-summary-panel {
      .order-summary__total-label,
      .order-summary__total-value {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.active-panel-shipping {
  .email-and-sms-promotions {
    .picker-checkbox {
      label {
        width: 85%;
      }
    }
  }
}

.active-panel-review {
  #order-summary-panel {
    .order-summary-panel__title {
      span {
        display: none;
      }
    }
  }
  #offer-code-panel {
    display: none;
  }
}

.checkout__content {
  .viewcart-panel {
    padding: 0;
  }
  .cart-header__title--total {
    margin-#{$ldirection}: 83.3333%;
  }
  .cart-item__total {
    margin-#{$ldirection}: 78.3333%;
  }
  .cart-header__title--qty {
    margin-#{$ldirection}: 64.3333%;
  }
  @include breakpoint($bp--xlarge-down) {
    @include breakpoint($bp--large-up) {
      .cart-item__total {
        margin-left: 80.3333%;
      }
      .order-summary__content {
        .value {
          margin-left: 80.3333%;
        }
      }
      #bottom-viewcart-buttons {
        .continue-buttons {
          padding-right: 9%;
        }
      }
    }
  }
}

.samples-panel {
  .samples {
    .sample-select-button {
      width: 125px !important;
    }
  }
}

.mobile_visibility {
  display: block;
}

.checkout.panel {
  #shipping-panel {
    #shipping-panel-edit {
      margin-top: 11px;
      float: right;
    }
  }
}
