/* ==========================================================================
   Layout - Makeup Services Landing Page
   ========================================================================== */

.makeup-services {
  @include breakpoint($bp--large-up) {
    img.tout-block-landscape__image-mobile {
      display: none;
      visibility: hidden;
      float: none;
    }
  }
  .btn {
    width: 228px;
    background-color: transparent;
    font-size: 18px;
    line-height: 3em;
    color: $white;
    border: 1px solid $white;
  }
  .cta {
    text-align: center;
    padding-bottom: 0;
  }
}

.makeup-services-landing-page {
  @include clearfix;
  clear: both;
  text-align: center;
  @include breakpoint($bp--large-up) {
    h4,
    .at-h4__makeupservices {
      margin-bottom: 0.5em;
    }
    .tout-split-container {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      width: 100%;
      display: flex;
    }
    .tout-50__column {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      width: 50%;
      flex: 1;
      .cta {
        padding-bottom: 72px;
      }
      &:last-child {
        .cta {
          border-left: 1px solid #c7c7c7;
        }
        .tout-block-landscape__body {
          border-left: 1px solid #c7c7c7;
        }
      }
    }
    .tout-block-landscape {
      max-width: 100%;
    }
    .makeup-services__title {
      padding-top: 42px;
      padding-bottom: 42px;
    }
    .makeup-services__title p {
      font-size: 14px;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 0;
    }
  }
}

.tout-block-portrait__body-above-ombrelips-shopnow,
.tout-block-landscape__body-above-petal-lips-mobile {
  margin-bottom: 198px;
  display: block;
}

.tout-block-portrait__body-above-trending-shopnow,
.tout-block-landscape__body-above-petal-lips {
  margin-top: 234px;
  display: block;
}

.tout-block-landscape {
  &__body-above-steve-yoni-collection {
    margin-top: 144px;
    display: block;
  }
  &__body-text {
    padding-top: 16px;
    max-width: 880px;
    text-align: center;
    margin: auto;
    margin-top: 90px;
  }
  &__body-above-steve-yoni-spacer {
    padding-top: 126px;
    display: block;
  }
  &__body-above-ombrelips-mobile {
    display: block;
    margin-top: 180px;
  }
}

.basic-responsive-v1-pro-palette,
.basic-responsive-v1-movie-makeup-spacer,
.basic-responsive-v1-tsa-approved-spacer {
  display: inline-block;
}

.makeup-services--50-50 {
  @include breakpoint($bp--large-up) {
    figcaption.tout-block-landscape__caption {
      top: 20%;
      min-height: 40%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .tout-block-landscape__body {
      border-left: 1px solid #c7c7c7;
      text-align: center;
      padding: 3em 40px 45px;
      padding-top: 40px;
      margin-bottom: 0;
      min-height: 540px;
      p {
        margin-bottom: 22px;
      }
    }
    .tout-block-landscape__header-image {
      margin-bottom: 2%;
      img {
        max-width: 30%;
      }
    }
    .tout-block-landscape__cta {
      height: auto;
      margin-bottom: 2%;
    }
    .tout-block-landscape__cta--html {
      color: $white;
      width: 80%;
      margin: 0 auto;
      a {
        color: $white;
      }
    }
  }
  @include breakpoint($bp--xlarge-up) {
    figcaption.tout-block-landscape__caption {
      top: 22%;
    }
    .tout-block-landscape__header-image {
      margin-bottom: 3%;
    }
    .tout-block-landscape__cta {
      margin-bottom: 3%;
    }
  }
  @include breakpoint($bp--xxlarge-up) {
    figcaption.tout-block-landscape__caption {
      top: 24%;
    }
    .tout-block-landscape__body {
      padding: 3em 113px 45px;
    }
    .tout-block-landscape__body {
      min-height: 510px;
    }
    .tout-block-landscape__header-image {
      margin-bottom: 5%;
    }
    .tout-block-landscape__cta--html {
      width: 55%;
    }
  }
}

.makeup-services--full-width {
  @include breakpoint($bp--large-up) {
    .cta {
      border-left: 1px solid #c7c7c7;
      text-align: center;
      padding-bottom: 1em;
    }
    .tout-block-landscape__headline {
      display: none;
    }
    .tout-block-landscape__caption {
      top: 55%;
      bottom: 0;
      padding: 0;
    }
    img.tout-block-landscape__image {
      display: inline;
      visibility: visible;
      min-height: 358px;
    }
    .tout-block-landscape__body-apps {
      a {
        border-bottom-style: none;
      }
      img {
        max-width: 150px;
        max-height: 39px;
      }
    }
    img.tout-block-landscape__image-mobile {
      display: none;
      visibility: hidden;
      float: none;
    }
    .tout-block-landscape__body-above {
      p {
        padding-top: 15px;
        padding-left: 22%;
        padding-right: 22%;
        color: $white;
        padding-bottom: 15px;
        margin-bottom: 0;
        line-height: 1.25em;
        font-size: 0.95em;
      }
      img {
        max-width: 370px;
        max-height: 30px;
      }
    }
    .tout-block-landscape__body-copy {
      p {
        padding: 15px;
      }
      a {
        color: $white;
      }
    }
  }
  @include breakpoint($bp--large-up) {
    .tout-block-landscape__body-above {
      p {
        line-height: 1.5em;
      }
    }
    img.tout-block-landscape__image {
      min-height: auto;
    }
    .tout-block-landscape__body-above {
      p {
        padding-top: 25px;
        padding-bottom: 25px;
        font-size: 1em;
      }
    }
  }
  @include breakpoint($bp--xlarge-up) {
    .tout-block-landscape__body-copy p,
    .tout-block-landscape__body-above p {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .tout-block-landscape__body-above p {
      padding-left: 24%;
      padding-right: 24%;
    }
  }
}

//Mobile
.makeup-services {
  @include breakpoint($bp--medium-down) {
    img.tout-block-landscape__image,
    img.tout-block-landscape__header-image {
      display: none;
      visibility: hidden;
    }
    .tout-block-landscape__body-above,
    .tout-block-landscape__body {
      p {
        padding-left: 1.25em;
        padding-right: 1.25em;
        text-align: center;
        color: $black;
      }
    }
  }
}

.makeup-services-landing-page {
  @include breakpoint($bp--medium-down) {
    .makeup-services__title {
      min-height: 206px;
      padding-top: 1.75em;
      padding-bottom: 1.75em;
      margin-top: 0;
      margin-bottom: 0;
      h4 {
        margin-bottom: 0.5em;
      }
    }
    .tout-50__column {
      padding-bottom: 40px;
      padding-top: 0;
      .cta {
        padding-bottom: 0;
      }
    }
  }
}

.makeup-services--50-50 {
  @include breakpoint($bp--medium-down) {
    .tout-block-landscape__caption {
      -webkit-transform: translateY(0%);
      -moz-transform: translateY(0%);
      -ms-transform: translateY(0%);
      -o-transform: translateY(0%);
      transform: translateY(0%);
      position: relative;
      top: 0;
      padding: 0;
    }
    .tout-block-landscape__body-above p.tout-block-landscape__body-copy {
      padding: 5%;
      color: #fff;
    }
    .tout-block-landscape__body {
      padding-top: 1em;
    }
    .tout-split-container {
      display: block;
    }
    .tout-block-landscape__body-above {
      margin: 1em 0 0;
    }
    .tout-block-landscape__body-above img {
      display: none;
      visibility: hidden;
    }
    .cta {
      padding-top: 1em;
    }
    .tout-block-landscape__cta--html {
      margin: 1em 0 0;
      padding: 0 1.25em;
      color: $black;
      a {
        color: $black;
      }
    }
    .btn {
      width: 307px;
      background-color: $black;
      font-size: 18px;
      line-height: 3em;
    }
  }
}

.makeup-services--full-width {
  @include breakpoint($bp--medium-down) {
    .cta {
      text-align: center;
      padding-bottom: 1em;
    }
    .tout-block-landscape__caption {
      transform: none;
      position: relative;
      top: 0;
      padding-top: 40px;
    }
    .tout-block-landscape__body-above {
    }
    .tout-block-landscape__header-image {
      img {
        display: none;
        visibility: hidden;
      }
    }
    .tout-block-landscape__body-apps {
      a {
        border-bottom-style: none;
      }
      img {
        width: 30%;
      }
    }
    .tout-block-landscape__body {
      padding-top: 0;
      padding-left: 1.25em;
      padding-right: 1.25em;
      text-align: center;
    }
    .tout-block-landscape__body-copy {
      a,
      p a {
        color: $black;
      }
      p {
        padding: 1.25em;
      }
    }
  }
}

.br-book_appointment {
  margin-bottom: 108px;
  display: block;
}
