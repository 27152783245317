/* Mixin - Full Container
   ========================================================================== */

@mixin full-container {
  display: block;
  width: 100%;
}

/* Extendable - Responsive Container
   ========================================================================== */

.site-container {
  min-height: 640px;
  min-width: 320px;
  overflow: hidden;
  @include breakpoint($bp--largest-up) {
    @include flex(1 0 auto);
  }
  @include breakpoint($bp--medium-down) {
    min-height: 400px;
  }
  @include breakpoint($width-xxlarge) {
    min-height: 540px;
  }
  .page-product & {
    min-height: 0;
  }
}

.br-space {
  display: block;
}

.site-content,
.responsive-container {
  @include responsive-container;
  .signup-thanks__header {
    padding-top: 133px;
    margin-top: -133px;
    @include breakpoint($width-large) {
      p {
        letter-spacing: -1px;
        margin-top: 2px;
      }
    }
  }
}

.full-container {
  @include full-container;
}
/* Creative Refresh, Fall 2018 */
.responsive-hp {
  @include responsive-container;
}

.mpp-responsive-container {
  @include swap_direction(margin, 0 auto);
  width: 100%;
  max-width: $site-width;
  @include breakpoint($bp--xwide-up) {
    max-width: 1620px;
  }
}
/* misc perlgem pages */
.sign-in-page {
  @include swap_direction(padding, 20px 20px 0 10px);
  .section-head__header {
    @include swap_direction(margin, 0 0 20px 0);
    @if $fonts_update == true {
      font-size: 30px;
      @include breakpoint($bp--large-up) {
        font-size: 45px;
      }
    }
  }
  .section-head__link {
    @include swap_direction(margin, 0 0 20px 0);
  }
  .form-item {
    @include swap_direction(margin, 0 0 20px 0);
    @if $fonts_update == true {
      input {
        &:last-child {
          margin-bottom: 0.7em;
        }
      }
    }
  }
}

.profile-page,
.address__content,
.add-payment-page__content {
  @include swap_direction(padding, 0 20px 0 10px);
}

.payment-form {
  max-width: 550px;
  .adyen-checkout {
    &__input,
    &__label__text--error {
      border-radius: 0;
    }
    &__label {
      display: block;
    }
    &__label__text {
      text-transform: capitalize;
    }
  }
}

.non-adyen-fields {
  .payment-address {
    max-width: 550px;
  }
}

.account-page__panel {
  .mac-select__account-panel {
    padding-top: 10px;
    @include breakpoint($bp--xlarge-down) {
      @include swap_direction(padding, 10px);
    }
  }
  .orders-list {
    @include breakpoint($bp--xlarge-down) {
      @include swap_direction(padding, 0 10px);
    }
  }
}

.account-order-history,
.order-details-page {
  @include swap_direction(padding, 0 10px);
}

.section-terms-conditions,
.section-privacy-policy,
.section-macselect-terms-conditions,
.section-mac-pro,
.section-supplier-relations {
  .site-container {
    @include swap_direction(padding, 0 10px);
  }
}
