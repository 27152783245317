@include keyframes(bounce) {
  0%,
  20%,
  50%,
  80%,
  100% {
    @include transform(translateY(0));
  }
  40% {
    @include transform(translateY(-20px));
  }
  60% {
    @include transform(translateY(-10px));
  }
}
