/* ==========================================================================
   Layout - Country Chooser Page - these styles are separated out from
   _content-3col to be specific to Country Chooser
   ========================================================================== */

.country-chooser {
  h3 {
    @include h6();
  }
  ul {
    @include swap_direction(margin, 2em 0 4em 0);
    li {
      margin-bottom: 1em;
      a {
        color: $color--gray--light;
        &:hover {
          color: $color--black;
          border-bottom-color: $color--black;
        }
      }
      .country-chooser__divider {
        color: $color--gray--light;
      }
    }
  }

  // Special font for the following countries: Vietnam, Greece, Russia
  a[data-country='vietnam'],
  a[data-country='greece'],
  a[data-country='russia'] {
    font-family: $roboto-condensed-regular-font;
    letter-spacing: 0.075em;
  }
  header {
    &.content-3col__header {
      margin-bottom: 0px;
    }
  }
}

.country-chooser__footer {
  margin-bottom: 120px;
}
