.enhanced-delivery-page {
  &.checkout {
    .gift-wrap {
      background: $color--white;
      padding: 0 0 10px;
      &__cnt {
        background: $color--white;
        display: flex;
        margin: 0;
        padding: 12px 15px;
        @include breakpoint($width-large) {
          @include swap_direction(padding, 0 12px 0 13px);
        }
        &--thumb {
          @include swap_direction(padding, 0 16px 0 0);
          margin: 25px 0 0;
          width: 117px;
          @include breakpoint($width-large) {
            @include swap_direction(margin, 8px 8px 0 0);
          }
        }
        &--info {
          @include swap_direction(padding, 0 15px 0 0);
          width: calc(100% - 117px);
          @include breakpoint($width-large) {
            padding: 0;
          }
        }
        &--title h2 {
          font-size: 14px;
          margin: 16px 0 8px;
          @include breakpoint($width-large) {
            margin-top: 8px;
          }
        }
        &--message {
          p {
            margin: 0 0 5px;
            opacity: 0.7;
          }
        }
        &--desc {
          p {
            margin-bottom: 16px;
          }
        }
        &--price {
          &-text {
            margin: 0;
            &-desc {
              opacity: 0.7;
            }
            &-volume {
              float: $rdirection;
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }
    .gift-options {
      border-bottom: 1px solid;
      border-top: 1px solid;
      float: none;
      margin: 13px 0 0;
      padding: 11px 17px 10px;
      width: 100%;
      @include breakpoint($width-xlarge) {
        margin-bottom: 16px;
        padding: 10px 15px;
      }
      .checkout {
        &__subtitle {
          font-size: 16px;
          line-height: 1.63;
          margin: 0;
          .accordion-icon {
            top: 13px;
          }
        }
      }
      .picker {
        &.picker-checkbox {
          overflow: visible;
          .picker-handle {
            background: $color--gray--dusty;
            border: 0;
            border-radius: 34px;
            height: 16px;
            margin-bottom: 22px;
            width: 40px;
            .picker-flag {
              opacity: 1;
              position: relative;
              &::before {
                @include transition(0.4s);
                @include box-shadow(0 1px 2px 0 $color--gray--checkout);
                #{$ldirection}: 0;
                background: $color--white;
                border-radius: 50%;
                bottom: -3px;
                content: '';
                height: 23px;
                position: absolute;
                width: 23px;
              }
            }
          }
          .gift-yes {
            display: none;
          }
          &.checked {
            .picker-handle {
              background: $color--green--medium;
            }
            .picker-flag {
              &::before {
                @include transform(translateX(18px));
                background: $color--white;
              }
            }
            .gift-yes {
              display: block;
            }
            .gift-no {
              display: none;
            }
          }
        }
      }
      .giftwrap__title,
      .label-content {
        color: $color--black--opacity-88;
        font-size: 14px;
      }
      .label-content {
        color: $color--black--opacity-64;
      }
      .giftmessage {
        clear: both;
        .checkout {
          &__subtitle {
            display: none;
          }
        }
        @include breakpoint($width-large) {
          margin-#{$ldirection}: 120px;
        }
        .form-item {
          margin-bottom: 22px;
          input,
          input:focus,
          .message-field {
            border-color: $color--gray--dusty;
          }
        }
        .card-message {
          margin-bottom: 6px;
          p {
            color: $color--black--opacity-88;
            font-size: 14px;
            line-height: 1;
            margin: 0 0 10px;
            width: 100%;
          }
          .gift_option_giftmessage {
            position: relative;
          }
          label {
            font-size: 16px;
            height: auto;
            line-height: normal;
            margin: 0;
            padding-top: 0;
          }
          .message-field {
            background: $color--white;
            box-shadow: none;
            color: $color--black--opacity-88;
            font-size: 14px;
            padding: 12px;
            & + label {
              &::before {
                font-size: 14px;
                position: absolute;
                top: 15px;
              }
            }
            &.touched + label {
              &::before {
                background: $color--white;
              }
            }
            &:focus + label {
              &::before {
                @include transform(none);
                background: $color--white;
                font-size: 10px;
                top: -5px;
              }
            }
          }
        }
        #gift-max-char {
          font-family: $roboto-mono_regular-font;
          font-size: 10px;
          line-height: 2.4;
        }
      }
      &__image {
        float: $ldirection;
        width: 90px;
        & + .giftwrap {
          margin-#{$ldirection}: 100px;
          @include breakpoint($width-large) {
            margin-#{$ldirection}: 120px;
          }
        }
      }
    }
  }
  &.active-panel-shipping {
    .gift-wrap {
      display: none;
    }
    &.checkout {
      .checkout__content {
        #shipping-panel {
          #checkout_shipping_panel {
            .gift-options {
              .sub-section img {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
