/*
Version: 3.5.1 Timestamp: Tue Jul 22 18:58:56 EDT 2014
*/
.select2-container {
  @include swap_direction(margin, 0);
  position: relative;
  display: inline-block;
  /* inline-block for ie7 */
  zoom: 1;
  *display: inline;
  vertical-align: middle;
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
  /*
    Force border-box so that % widths fit the parent
    container without overlap because of margin/padding.
    More Info : http://www.quirksmode.org/css/box.html
  */
  -webkit-box-sizing: border-box; /* webkit */
  -moz-box-sizing: border-box; /* firefox */
  box-sizing: border-box; /* css3 */
}

.select2-container .select2-choice {
  display: block;
  height: 26px;
  overflow: hidden;
  position: relative;
  /* border: 1px solid #aaa; */
  white-space: nowrap;
  line-height: 26px;
  color: #444;
  text-decoration: none;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.5, #fff));
  background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 50%);
  background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#ffffff', endColorstr = '#eeeeee', GradientType = 0);
  background-image: linear-gradient(to top, #eee 0%, #fff 50%);
}

html[dir='rtl'] .select2-container .select2-choice {
  @include swap_direction(padding, 0 8px 0 0);
}

.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: #aaa;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.9, #fff));
  background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 90%);
  background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
  background-image: linear-gradient(to bottom, #eee 0%, #fff 90%);
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-#{$rdirection}: 42px;
}

.select2-container .select2-choice > .select2-chosen {
  margin-#{$rdirection}: 26px;
  margin-#{$ldirection}: 26px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: none;
  width: auto;
}

html[dir='rtl'] .select2-container .select2-choice > .select2-chosen {
  margin-#{$ldirection}: 26px;
  margin-#{$rdirection}: 0;
}

.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  #{$rdirection}: 24px;
  top: 8px;
  font-size: 1px;
  text-decoration: none;
  @include swap_direction(border, 0);
  background: image-url('fallbacks/select2.png') right top no-repeat;
  cursor: pointer;
  outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block;
}

.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer;
}

.select2-drop-mask {
  @include swap_direction(border, 0);
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0);
  position: fixed;
  #{$ldirection}: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  /* styles required for IE to work */
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-drop {
  width: 100%;
  margin-top: -1px;
  position: absolute;
  z-index: 9999;
  top: 100%;
  background: #fff;
  color: #000;
  @include swap_direction(border, 1px solid #aaa);
  border-top: 0;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop.select2-drop-above {
  margin-top: 1px;
  border-top: 1px solid #aaa;
  border-bottom: 0;
  -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop-active {
  @include swap_direction(border, 1px solid $color--gray--lighter);
  border-top: none;
}

.select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #5897fb;
}

.select2-drop-auto-width {
  border-top: 1px solid #aaa;
  width: auto;
}

.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}

.select2-container .select2-choice .select2-arrow {
  display: inline-block;
  width: 18px;
  height: 100%;
  position: absolute;
  #{$rdirection}: 8px;
  top: 0;
  border-#{$ldirection}: 1px solid #aaa;
  background-clip: padding-box;
  background: #ccc;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ccc), color-stop(0.6, #eee));
  background-image: -webkit-linear-gradient(center bottom, #ccc 0%, #eee 60%);
  background-image: -moz-linear-gradient(center bottom, #ccc 0%, #eee 60%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#eeeeee', endColorstr = '#cccccc', GradientType = 0);
  background-image: linear-gradient(to top, #ccc 0%, #eee 60%);
}

html[dir='rtl'] .select2-container .select2-choice .select2-arrow {
  border-#{$ldirection}: none;
  border-#{$rdirection}: none;
}

.select2-container .select2-choice .select2-arrow b {
  display: block;
  width: 100%;
  height: 100%;
  background: image-url('fallbacks/select2.png') no-repeat 0 1px;
}

html[dir='rtl'] .select2-container .select2-choice .select2-arrow b {
  background-position: 2px 1px;
}

.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  @include swap_direction(margin, 0);
  padding-#{$ldirection}: 4px;
  padding-#{$rdirection}: 4px;
  position: relative;
  z-index: 10000;
  white-space: nowrap;
}

.select2-search input {
  width: 100%;
  height: auto !important;
  min-height: 26px;
  @include swap_direction(padding, 4px 20px 4px 5px);
  @include swap_direction(margin, 0);
  outline: 0;
  font-family: sans-serif;
  font-size: 1em;
  @include swap_direction(border, 1px solid #aaa);
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff image-url('fallbacks/select2.png') no-repeat 100% -22px;
  background: image-url('fallbacks/select2.png') no-repeat 100% -22px,
    -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: image-url('fallbacks/select2.png') no-repeat 100% -22px,
    -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: image-url('fallbacks/select2.png') no-repeat 100% -22px,
    -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: image-url('fallbacks/select2.png') no-repeat 100% -22px,
    linear-gradient(to bottom, #fff 85%, #eee 99%) 0 0;
}

html[dir='rtl'] .select2-search input {
  @include swap_direction(padding, 4px 5px 4px 20px);
  background: #fff image-url('fallbacks/select2.png') no-repeat -37px -22px;
  background: image-url('fallbacks/select2.png') no-repeat -37px -22px,
    -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: image-url('fallbacks/select2.png') no-repeat -37px -22px,
    -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: image-url('fallbacks/select2.png') no-repeat -37px -22px,
    -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: image-url('fallbacks/select2.png') no-repeat -37px -22px,
    linear-gradient(to bottom, #fff 85%, #eee 99%) 0 0;
}

.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px;
}

.select2-search input.select2-active {
  background: #fff url('../../mac_base/js/bower_components/select2/select2-spinner.gif') no-repeat 100%;
  background: url('../../mac_base/js/bower_components/select2/select2-spinner.gif') no-repeat 100%,
    -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: url('../../mac_base/js/bower_components/select2/select2-spinner.gif') no-repeat 100%,
    -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url('../../mac_base/js/bower_components/select2/select2-spinner.gif') no-repeat 100%,
    -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url('../../mac_base/js/bower_components/select2/select2-spinner.gif') no-repeat 100%,
    linear-gradient(to bottom, #fff 85%, #eee 99%) 0 0;
}

.select2-dropdown-open .select2-choice {
  border-bottom-color: transparent;
  -webkit-box-shadow: 0 1px 0 #fff inset;
  box-shadow: 0 1px 0 #fff inset;
  border-bottom-#{$ldirection}-radius: 0;
  border-bottom-#{$rdirection}-radius: 0;
  background-color: #eee;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #fff), color-stop(0.5, #eee));
  background-image: -webkit-linear-gradient(center bottom, #fff 0%, #eee 50%);
  background-image: -moz-linear-gradient(center bottom, #fff 0%, #eee 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to top, #fff 0%, #eee 50%);
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  @include swap_direction(border, 1px solid #5897fb);
  border-top-color: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(0.5, #eee));
  background-image: -webkit-linear-gradient(center top, #fff 0%, #eee 50%);
  background-image: -moz-linear-gradient(center top, #fff 0%, #eee 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
}

.select2-dropdown-open .select2-choice .select2-arrow {
  background: transparent;
  border-#{$ldirection}: none;
  filter: none;
}

html[dir='rtl'] .select2-dropdown-open .select2-choice .select2-arrow {
  border-#{$rdirection}: none;
}

.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px 1px;
}

html[dir='rtl'] .select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -16px 1px;
}

.select2-hidden-accessible {
  @include swap_direction(border, 0);
  clip: rect(0 0 0 0);
  height: 1px;
  @include swap_direction(margin, -1px);
  overflow: hidden;
  @include swap_direction(padding, 0);
  position: absolute;
  width: 1px;
}
/* results */
.select2-results {
  max-height: 200px;
  @include swap_direction(padding, 0 0 0 4px);
  @include swap_direction(margin, 4px 4px 4px 0);
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html[dir='rtl'] .select2-results {
  @include swap_direction(padding, 0 4px 0 0);
  @include swap_direction(margin, 4px 0 4px 4px);
}

.select2-results ul.select2-result-sub {
  @include swap_direction(margin, 0);
  padding-#{$ldirection}: 0;
}

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold;
}

.select2-results .select2-result-label {
  @include swap_direction(padding, 3px 7px 4px);
  @include swap_direction(margin, 0);
  cursor: pointer;
  min-height: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select2-results-dept-1 .select2-result-label {
  padding-#{$ldirection}: 20px;
}

.select2-results-dept-2 .select2-result-label {
  padding-#{$ldirection}: 40px;
}

.select2-results-dept-3 .select2-result-label {
  padding-#{$ldirection}: 60px;
}

.select2-results-dept-4 .select2-result-label {
  padding-#{$ldirection}: 80px;
}

.select2-results-dept-5 .select2-result-label {
  padding-#{$ldirection}: 100px;
}

.select2-results-dept-6 .select2-result-label {
  padding-#{$ldirection}: 110px;
}

.select2-results-dept-7 .select2-result-label {
  padding-#{$ldirection}: 120px;
}

.select2-results .select2-highlighted {
  background: #3875d7;
  color: #fff;
}

.select2-results li em {
  background: #feffde;
  font-style: normal;
}

.select2-results .select2-highlighted em {
  background: transparent;
}

.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000;
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-ajax-error,
.select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item;
  padding-#{$ldirection}: 5px;
}
/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
  display: none;
}

.select2-more-results.select2-active {
  background: #f4f4f4 url('../../mac_base/js/bower_components/select2/select2-spinner.gif') no-repeat 100%;
}

.select2-results .select2-ajax-error {
  background: rgba(255, 50, 50, 0.2);
}

.select2-more-results {
  background: #f4f4f4;
  display: list-item;
}
/* disabled styles */

.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  @include swap_direction(border, 1px solid #ddd);
  cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4;
  background-image: none;
  border-#{$ldirection}: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}
/* multiselect */

.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0 5px 0 0);
  position: relative;
  @include swap_direction(border, 1px solid #aaa);
  cursor: text;
  overflow: hidden;
  background-color: #fff;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(1%, #eee), color-stop(15%, #fff));
  background-image: -webkit-linear-gradient(top, #eee 1%, #fff 15%);
  background-image: -moz-linear-gradient(top, #eee 1%, #fff 15%);
  background-image: linear-gradient(to bottom, #eee 1%, #fff 15%);
}

html[dir='rtl'] .select2-container-multi .select2-choices {
  @include swap_direction(padding, 0 0 0 5px);
}

.select2-locked {
  @include swap_direction(padding, 3px 5px 3px 5px !important);
}

.select2-container-multi .select2-choices {
  min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
  @include swap_direction(border, 1px solid #5897fb);
  outline: none;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.select2-container-multi .select2-choices li {
  float: #{$ldirection};
  list-style: none;
}

html[dir='rtl'] .select2-container-multi .select2-choices li {
  float: #{$rdirection};
}

.select2-container-multi .select2-choices .select2-search-field {
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0);
  white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
  @include swap_direction(padding, 5px);
  @include swap_direction(margin, 1px 0);
  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  @include swap_direction(border, 0);
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #fff url('../../mac_base/js/bower_components/select2/select2-spinner.gif') no-repeat 100% !important;
}

.select2-default {
  color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
  @include swap_direction(padding, 3px 5px 3px 18px);
  @include swap_direction(margin, 3px 0 3px 5px);
  position: relative;
  line-height: 13px;
  color: #333;
  cursor: default;
  @include swap_direction(border, 1px solid #aaaaaa);
  -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #e4e4e4;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#f4f4f4', GradientType=0);
  background-image: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    color-stop(20%, #f4f4f4),
    color-stop(50%, #f0f0f0),
    color-stop(52%, #e8e8e8),
    color-stop(100%, #eee)
  );
  background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: linear-gradient(to top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
}

html[dir='rtl'] .select2-container-multi .select2-choices .select2-search-choice {
  @include swap_direction(margin, 3px 5px 3px 0);
  @include swap_direction(padding, 3px 18px 3px 5px);
}

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #d4d4d4;
}

.select2-search-choice-close {
  display: block;
  width: 12px;
  height: 13px;
  position: absolute;
  #{$rdirection}: 3px;
  top: 4px;
  font-size: 1px;
  outline: none;
  background: image-url('fallbacks/select2.png') right top no-repeat;
}

html[dir='rtl'] .select2-search-choice-close {
  #{$rdirection}: auto;
  #{$ldirection}: 3px;
}

.select2-container-multi .select2-search-choice-close {
  #{$ldirection}: 3px;
}

html[dir='rtl'] .select2-container-multi .select2-search-choice-close {
  #{$ldirection}: auto;
  #{$rdirection}: 2px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
  background-position: right -11px;
}

.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
  background-position: right -11px;
}
/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  @include swap_direction(border, 1px solid #ddd);
  cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  @include swap_direction(padding, 3px 5px 3px 5px);
  @include swap_direction(border, 1px solid #ddd);
  background-image: none;
  background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled
  .select2-choices
  .select2-search-choice
  .select2-search-choice-close {
  display: none;
  background: none;
}
/* end multiselect */

.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline;
}

.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  @include swap_direction(border, 0 !important);
  @include swap_direction(margin, 0 !important);
  @include swap_direction(padding, 0 !important);
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  #{$ldirection}: 0px !important;
  top: 0px !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  #{$ldirection}: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}
