#loyalty__panel__benefits {
  @include loyalty-panel;
  @include breakpoint($bp--largest-up) {
    float: right;
    width: 49%;
  }
  .loyalty__panel__benefits__title {
    margin: 0 0 15px 0;
  }
  .loyalty__panel__benefits__description {
    ul {
      list-style: disc;
      list-style-image: image-url('global/mac-bullet-small.png');
      margin-left: 0;
    }
    p {
      margin: 0;
    }
  }
}
