body.lp {
  @include swap_direction(padding, 0);
  @include swap_direction(margin, 0);
}

#custom-container #lp-dashboard-btn-container {
  position: absolute;
  top: 2px;
  #{$rdirection}: 2px;
  color: white;
  font-size: 1.2em;
  @include swap_direction(padding, 5px 0px 9px);
  margin-#{$rdirection}: 5px;
  display: block;
}

#custom-container #brand-logo-placement {
  @include swap_direction(border, 0px);
  margin-top: 4px;
}

#lp-dashboard-btn-container div {
  border-bottom: 1px solid $color--white;
  float: #{$ldirection};
  margin-#{$rdirection}: 12px;
  margin-top: 9px;
}

#lp-dashboard-btn-container a {
  color: white;
}

#custom-container #user-input-container {
  width: 477px;
  position: absolute;
  top: 60px;
  @include swap_direction(border, 1px solid $color--gray--lighter);
}

#custom-container {
  width: 850;
  overflow: hidden;
  font-size: 0.9em;
}

#chat_window,
.chat-window-container {
  height: 138px;
  overflow: hidden;
}

#chat_window.livechat__chatwindow-mobile {
  overflow: scroll;
}

#chatLines {
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
  .no-icon-line {
    @include swap_direction(padding, 3px 6px);
  }
  .icon {
    width: 32px;
    height: 32px;
    overflow: hidden;
    @include swap_direction(border, 1px solid $color--gray--light);
    float: #{$ldirection};
    @include swap_direction(margin, 2px 10px 0 12px);
  }
}

.rich-line-content {
  width: 100%;
  clear: both;
  @include swap_direction(padding, 12px);
}

.rich-line-content .icon {
  float: #{$ldirection};
  clear: #{$ldirection};
}

.rich-line-content .txt-line {
  float: #{$ldirection};
  width: 380px;
}

.rich-line-content .line-by {
  font-weight: bold;
}

.rich-line-content .icon-agent,
.rich-line-content .icon-system {
  background-color: #000000;
}

#custom-container .beauty-consultant-profile {
  @include swap_direction(margin, 0 auto);
  width: 200px;
}

.beauty-consultant-profile .consultant-msg {
  padding-#{$ldirection}: 35px;
  visibility: visible;
}

.agent-typing-msg {
  clear: both;
  @include swap_direction(padding, 3px 6px);
}

#user-input-container .chat-window-container {
  position: relative;
  float: #{$ldirection};
  width: 475px;
  height: 475px;
  overflow-y: none; /* scroll */
  overflow-x: hidden;
}

#custom-container .text-input-container {
  position: absolute;
  top: 537px;
  border-#{$rdirection}: 1px solid lightgray;
  height: 167px;
  width: 477px;
  padding-top: 14px;
}

#custom-container .input-field-container {
  /* width: 373px; */
  float: #{$ldirection};
  height: 30px;
}

#custom-container input.chat-input-field {
  width: 325px;
  height: 48px;
  font-size: 1em;
  @include swap_direction(padding, 4px 10px);
  margin-#{$ldirection}: 15px;
}
/* */

#emailTranscript,
#closeChat {
  cursor: pointer;
  font-family: arial;
  font-size: 9px;
  margin-top: 9px;
  letter-spacing: 0.3em;
  border-bottom: 0px;
}

#close-chat-container {
  margin-#{$rdirection}: 100px;
}

#custom-container .btn {
  cursor: pointer;
}

#custom-container .add-to-bag-btn-container .btn-addToBag {
  color: $color--white;
  cursor: pointer;
  padding-top: 11px;
  font-family: $headline-font-family;
  background: $color--black;
  text-transform: uppercase;
  text-align: center;
}

#custom-container {
  .send-btn,
  #requestChat {
    @include swap_direction(border, 1px solid $color--gray);
    cursor: pointer;
    display: block;
    float: #{$rdirection};
    height: 48px;
    @include swap_direction(margin, 0px 10px 7px 10px);
    width: 110px;
    background: $color--black;
    color: $color--white;
    font-family: $headline-font-family;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 0.03em;
    text-align: center;
    padding-top: 11px;
  }
  #requestChat {
    padding-top: 0px;
  }
}

#custom-container .btn-upload {
  background: none;
  @include swap_direction(border, 0 none);
  @include swap_direction(margin, 0 auto);
  width: 100%;
  color: $color--gray--light;
  font-family: $headline-font-family;
  font-size: 1.2em;
  text-decoration: underline;
  margin-top: -10px;
  display: block;
  @include swap_direction(padding, 0 20px);
  text-align: #{$ldirection};
  text-transform: uppercase;
}

#custom-container .btn-upload img {
  @include swap_direction(margin, 0 auto);
  display: block;
}

#custom-container .upload-silhoette {
  width: 154px;
  height: 154px;
  @include swap_direction(margin, 0 auto);
  margin-top: 15px;
}

#custom-container .brand-topband {
  @include swap_direction(padding, 15px);
  background-color: black;
}

#custom-container .right-col-top .top-right-photo-upload-container {
  .login-text {
    @include swap_direction(margin, 0 auto);
    @include swap_direction(padding, 3px);
    width: 200px;
  }
  button.btn {
    text-align: center;
  }
}

#vba-window-container #vba-logo {
  font-size: 1.5em;
  color: $color--gray--light;
}

#vba-logo {
  height: 30px;
  width: 212px;
  margin-#{$ldirection}: 10px;
}
/* layout */

#custom-container .right-col-top .top-right-video-container {
}

#custom-container .right-col-top .top-right-photo-upload-container {
}

#custom-container .right-col-top .top-right-photo-upload-container .login-text {
  font-size: 0.8em;
  @include swap_direction(padding, 3px 12px);
  text-align: center;
}

#custom-container .right-col-top {
  position: absolute;
  top: 60px;
  #{$ldirection}: 478px;
  border-bottom: 1px solid $color--gray--lighter;
  border-#{$rdirection}: 1px solid $color--gray--lighter;
  border-#{$ldirection}: 0px;
  float: #{$rdirection};
  height: 285px;
  width: 360px;
  overflow: hidden;
  line-height: 1.5em;
}

#custom-container .right-col-bottom {
  position: absolute;
  #{$ldirection}: 478px;
  top: 350px;
  float: #{$rdirection};
  clear: both;
  width: 360px;
  height: 365px;
  @include swap_direction(border, 1px solid $color--gray--lighter);
  border-#{$ldirection}: 0px;
  border-top: 0px;
  border-bottom: 0px;
}

#chatContainer {
  position: relative;
  margin-top: 3px;
  display: none;
  position: absolute;
  height: 100%;
  overflow: auto;
  width: 475px;
}
/* photo upload */

#custom-container .section-heading-photo {
  display: none;
  @include swap_direction(margin, 12px 12px 12px 45px);
  letter-spacing: 0.1em;
}

#custom-container .section-heading-consultant {
  @include swap_direction(margin, 12px 12px 15px 28px);
  letter-spacing: 0.1em;
  overflow: hidden;
  width: 200px;
}

#custom-container .section-heading-consultant img {
  display: block;
  margin-#{$ldirection}: -13px;
}

#custom-container .icon-consultant,
#custom-container .icon-chat-visitor {
  /* width:74px; height:86px; */
}

#custom-container .chat-face-icon {
  background-color: none;
  /* float: left; */
  @include swap_direction(margin, 24px 24px 12px 35px);
  text-align: #{$ldirection};
}

#custom-container .consultant-name {
  font-size: 12px;
  font-weight: bold;
  clear: #{$ldirection};
  padding-#{$ldirection}: 35px;
  text-align: #{$ldirection};
}

#custom-container .consultant-location {
  clear: #{$ldirection};
  text-align: #{$ldirection};
  padding-#{$ldirection}: 35px;
  text-transform: uppercase;
}

#photo-upload-query .next-row {
  clear: both;
}

#photo-upload-query .choose-photo-btn {
}

#photo-upload-query .file-chosen-state span {
  height: 12px;
  width: 175px;
  display: block;
  text-overflow: ellipsis;
}

#photo-upload-query .msg-instructions .profile-pic-upload__help p {
  margin-bottom: 6px;
}

#photo-upload-query .msg-instructions .profile-pic-upload__help ul {
  line-height: 24px;
  list-style: outside disc none;
  @include swap_direction(margin, 0 0 0 20px);
}

#photo-upload-query .msg-instructions {
  @include swap_direction(margin, 0 auto);
  font-size: 0.9em;
  @include swap_direction(padding, 12px 0 14px 0);
  width: auto;
  text-align: #{$ldirection};
}

#photo-upload-query .btn-instructions {
  @include swap_direction(margin, 0 auto);
  font-size: 0.9em;
  @include swap_direction(padding, 12px 0 14px 0);
  width: auto;
  text-align: center;
  margin-top: 24px;
}

#photo-upload-query .btn-submit {
  clear: both;
}

#photo-upload-query .msg-header {
  text-align: center;
  margin-top: 24px;
  font-size: 1.2em;
}

#photo-upload-query .photo-upload-row {
  width: 400px;
}

#photo-upload-query #choose-file-btn {
  float: #{$ldirection};
  @include swap_direction(padding, 8px);
  text-align: center;
  width: 140px;
  @include swap_direction(margin, 0px);
  cursor: pointer;
  background-color: $color--black;
}

#photo-upload-query #file-save-btn {
  padding-top: 7px;
  width: 80px;
  @include swap_direction(margin, 32px auto);
}

#photo-upload-query #file-chosen-state {
  float: #{$ldirection};
  @include swap_direction(padding, 6px 0);
  margin-#{$ldirection}: 24px;
  text-align: center;
  border-bottom: 1px solid $color--black;
}

#photo-upload-query #file-chosen-state span {
  color: $color--black;
}

.vba-x-close {
  height: 20px;
  position: absolute;
  #{$rdirection}: 0;
  top: 0;
  width: 20px;
  cursor: pointer;
}

body.lp #cboxOverlay {
  background: none repeat scroll 0 0 gray;
  opacity: 0.92;
  height: 100%;
  position: fixed;
  width: 100%;
}

body.lp #cboxOverlay {
  #{$ldirection}: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 9999;
  display: none;
}
/* */
#beauty-consultant-panel-integrated {
  display: none;
  .section-heading-consultant {
    display: none;
  }
  .btn-arrowRight {
    float: #{$rdirection};
    @include swap_direction(margin, -50px 33px 0 0);
    cursor: pointer;
    width: 55px;
  }
}
/* */

#product-recommendations-panel {
  display: block;
}

#product-recommendations-panel .section-heading-lbl {
  @include swap_direction(margin, 12px);
  letter-spacing: 0.1em;
  font-weight: bold;
  margin-bottom: 15px;
}

#product-recommendations-panel .product-sku-panel {
  @include swap_direction(padding, 25px);
  padding-top: 0px;
}

#product-recommendations-panel .carousel-left-arrow img,
#product-recommendations-panel .carousel-left-arrow-off img {
  margin-#{$ldirection}: 0px;
}

#product-recommendations-panel .carousel-right-arrow img,
#product-recommendations-panel .carousel-right-arrow-off img {
  margin-#{$ldirection}: 0px;
}

#product-recommendations-panel .carousel-left-arrow-off,
#product-recommendations-panel .carousel-left-arrow {
  margin-#{$ldirection}: 10px;
}

#product-recommendations-panel .carousel-right-arrow-off,
#product-recommendations-panel .carousel-right-arrow {
  margin-#{$rdirection}: 8px;
}

#product-recommendations-panel .carousel-left-arrow,
#product-recommendations-panel .carousel-right-arrow {
  opacity: 1;
  width: 55px;
  cursor: default;
  margin-top: 175px;
}

#product-recommendations-panel .carousel-left-arrow-off,
#product-recommendations-panel .carousel-right-arrow-off {
  opacity: 0.5;
  width: 55px;
  cursor: default;
  margin-top: 175px;
}

#product-recommendations-panel .carousel-left-arrow-off {
  float: left;
}

#product-recommendations-panel .carousel-right-arrow-off {
  float: right;
}

#product-recommendations-panel .carousel-left-arrow {
  float: #{$ldirection};
  cursor: pointer;
}

#product-recommendations-panel .carousel-right-arrow {
  float: #{$rdirection};
  cursor: pointer;
}

#product-recommendations-panel .carousel-content-container {
  float: #{$ldirection};
  height: 300px;
  overflow: hidden;
  width: 180px;
}

#product-recommendations-panel .carousel-content-container .carousel-panel-mask {
  min-width: 2000px;
  #{$ldirection}: 0;
  position: relative;
}

#product-recommendations-panel .carousel-content-container .carousel-content-panel {
  width: 180px;
  height: 275px;
  float: #{$ldirection};
  @include swap_direction(padding, 0 20px);
}

#product-recommendations-panel .carousel-content-container .img-col {
  width: 100%;
  float: #{$ldirection};
  text-align: center;
}

#product-recommendations-panel .carousel-content-container .img-col .mock-prod-img {
  width: 60px;
  height: 90px;
}

#product-recommendations-panel .carousel-content-container .detail-col {
  float: #{$ldirection};
  width: 100%;
}

#product-recommendations-panel .carousel-content-container .prod-title {
  font-size: 1.3em;
  overflow: hidden;
  width: 100%;
  text-transform: uppercase;
  font-family: $ano-bold_regular-font;
  line-height: 1.22222;
}

#product-recommendations-panel .carousel-content-container .prod-subtitle {
  font-size: 1em;
  overflow: hidden;
  width: 100%;
}

#product-recommendations-panel .carousel-content-container .prod-units {
  @include swap_direction(padding, 0px);
  overflow: hidden;
  width: 100%;
  text-transform: uppercase;
}

#product-recommendations-panel .carousel-content-container .shade-container {
  @include swap_direction(padding, 4px 0px);
  overflow: hidden;
  width: 100%;
  height: 22px;
}

#product-recommendations-panel .carousel-content-container .shade-container .shade-shim {
  overflow: hidden;
  float: #{$ldirection};
  @include swap_direction(border-radius, 18px);
  -moz-border-radius: 18px;
  width: 18px;
  height: 18px;
}

#product-recommendations-panel .carousel-content-container .shade-container .shade-name {
  overflow: hidden;
  float: #{$ldirection};
  width: 100%;
  margin-#{$ldirection}: 2px;
}

#product-recommendations-panel .carousel-content-container .price-info {
  font-family: $ano-bold_regular-font;
  font-size: 12px;
  overflow: hidden;
  width: 100%;
  height: 14px;
  .favorites-hitarea {
    margin-top: -15px;
    .icon--heart-o {
      float: #{$rdirection};
      cursor: pointer;
    }
  }
}

#product-recommendations-panel .carousel-content-container .add-to-bag-btn-container {
  position: absolute;
  bottom: -265px;
}

#product-recommendations-panel .carousel-content-container .btn-addToBag {
  height: 48px;
  background-color: $color--black;
  width: 120px;
  cursor: pointer;
  font-size: 1.4em;
  color: $color--white;
  text-align: center;
  @include swap_direction(padding, 12px 0);
}

#product-recommendations-panel .carousel-content-container .msg-addToBag {
  margin-#{$ldirection}: 2px;
  margin-top: 3px;
  display: none;
}

#product-recommendations-panel .section-heading-consultant img {
  display: block;
  margin-#{$ldirection}: -8px;
}
/* landing and post chat target pages */
.beauty-consultations {
  text-align: center;
  @include swap_direction(padding, 5px);
  text-align: center;
  @include swap_direction(margin, 0px auto);
  width: 750px;
  text-transform: uppercase;
}

.beauty-consultations .conditional-has-added-to-bag {
  display: none;
  margin-top: 24px;
  margin-bottom: 12px;
}

.beauty-consultations .beauty-consultations-headline {
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: bold;
}

.thankyou-page-tiles {
  @include swap_direction(margin, 0 auto);
  width: 945px;
  margin-bottom: 20px;
}

.thankyou-page-tiles img {
  padding-#{$rdirection}: 3px;
}

.thankyou .beauty-consultations-headline {
  @include swap_direction(margin, 36px);
}

.beauty-consultations .beauty-consultations-large-msg {
  font-size: 1.5em;
  width: 720px;
  @include swap_direction(margin, 0px auto);
  text-transform: uppercase;
  font-weight: bold;
}

.beauty-consultations-large-msg .quotes {
  font-size: 24px;
  font-style: italic;
  line-height: 28px;
}

.beauty-consultations-large-msg .msg-content {
  width: 500px;
  @include swap_direction(margin, 0 auto);
}

.beauty-consultations-large-msg .msg-content .h6 {
  padding-bottom: 12px;
  font-size: 15px;
  font-weight: bold;
}

.beauty-consultations-large-msg .msg-content p {
  font-size: 14px;
  margin-bottom: 14px;
}

.vba-tgt-pg-divider {
  border-bottom: 1px solid $color--gray--light;
  width: 500px;
  @include swap_direction(margin, 0 auto);
}

.beauty-consultations-lbl {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
}

.beauty-consultations-placement-msg {
  font-size: 1.4em;
}

.beauty-consultations .btn-choices-row {
  width: 345px;
  height: 45px;
  margin: 0px auto;
}

.beauty-consultations .btn-choices-row .or-space {
  padding: 10px;
}

.beauty-consultations .btn-choices-row div {
  float: left;
}

.beauty-consultations #order-question-btn,
.beauty-consultations #write-review-btn {
  @include swap_direction(margin, 0 auto);
  width: 150px;
  background-color: #0d091a;
  color: white;
}

.beauty-consultations #start-chat-btn {
  @include swap_direction(border-radius, 5px);
  @include swap_direction(padding, 10px);
  width: 150px;
}

.beauty-consultations .beauty-consultations-instructions {
  font-size: 0.9em;
}

.beauty-consultations-instructions a {
  text-decoration: underline;
  color: $color--black;
}
/* thank you page b */
.beauty-consultations-instructions button.create-account {
  @include swap_direction(border, 1px solid #ff4661);
  color: #ff4661;
  @include swap_direction(padding, 3px 6px);
  height: 36px;
  background-color: $color--white;
}
/* */
.vba-btn {
  color: $color--white;
  @include swap_direction(margin, 0 auto);
  cursor: pointer;
}

.vba-txt-btn {
  text-align: center;
  width: 175px;
  background-color: #ff4661;
  color: $color--white;
  height: 32px;
  @include swap_direction(margin, 10px auto);
  padding-top: 10px;
  cursor: pointer;
}

#photo-upload-query .file-save-btn-row {
  float: #{$ldirection};
  clear: both;
  width: 80px;
  position: relative;
}

#photo-upload-query .vba-txt-btn {
  background-color: $color--black;
}
