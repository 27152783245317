.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: #{$rdirection};
}

.cleft {
  clear: #{$ldirection};
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  word-wrap: break-word;
}

.site-container {
  .page--spp__product {
    .product__footer {
      a.notify_me {
        padding: 0px 20px;
        width: 100%;
        position: static;
        font-size: 1.5em;
        height: 60px;
        line-height: 64px;
      }
      .product__add-to-bag.coming-soon {
        display: none;
      }
    }
  }
  .grid--mpp {
    &__item {
      .product--not-orderable,
      .prod_inv_status-2 {
        .product__header {
          .product__name {
            height: auto !important;
          }
        }
        .product__footer {
          .product__add-to-bag.coming-soon,
          .product__link-to-spp {
            display: none;
          }
          li {
            float: none;
            width: 100%;
            &.temp-out-of-stock {
              padding-top: 0px;
            }
            a.notify_me {
              color: white;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              text-align: center;
              height: 60px;
              line-height: 64px;
              border-style: solid;
              border-color: white;
              border-width: 0;
              background-color: black;
              text-transform: uppercase;
              cursor: pointer;
              display: block;
              width: 100%;
              clear: both;
              margin-top: 20px;
              position: static;
              @if $fonts_update == false {
                font-family: $ano-bold_regular-font;
                font-size: 1.5rem;
              }
            }
            .temp-outof-stock_text {
              position: static;
              margin-top: 20px;
              text-align: center;
            }
            .coming-soon_text {
              position: static;
              text-align: right;
              @include swap_direction(margin, 0);
            }
          }
        }
      }
      .product--teaser {
        .product__header {
          .product__name {
            height: auto !important;
          }
        }
      }
    }
  }
  .product__footer {
    .product_content_fav_mobile {
      .product__price_ppu {
        display: none;
      }
    }
  }
  .field-elc-mpp-content {
    .sec-nav {
      .sec-nav__sections {
        .sec-nav__item {
          span {
            margin-left: 25px;
          }
        }
      }
    }
  }
  .store-locator {
    .form--search--advanced {
      .form--search {
        @include swap_direction(padding, 0 0 74px);
      }
    }
  }
  .prod_inv_status-3 {
    .product__footer {
      .product__inv-status-item.coming-soon {
        display: block;
      }
    }
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  height: 275px;
  @include swap_direction(margin, 17px 7px 5px 7px);
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 20px;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    @include swap_direction(margin, 10px 0 0 0);
  }
  .field {
    .email_input {
      float: #{$ldirection};
      width: 65%;
    }
    .align-r {
      float: #{$ldirection};
      @include swap_direction(margin, 0 0 0 10px);
    }
    .terms_condition_cont {
      float: #{$rdirection};
      @include swap_direction(margin, 10px 5px 0 0);
    }
  }
}

// Country picker (splash screen for ME sites)
.country-picker-v1 {
  .welcome-header {
    span {
      vertical-align: middle;
    }
  }
  .language-select__link {
    &.en {
      font-size: 12px;
    }
    &.ar {
      font-size: 20px;
    }
    font-family: $tstar-font;
    display: inline-block;
    vertical-align: middle;
    html[lang='en-e-AE'] &.en,
    html[lang='en-e-SA'] &.en,
    html[lang='ar-e-AE'] &.ar,
    html[lang='ar-e-SA'] &.ar {
      display: none;
    }
  }
}

.culture-detail__article-content__meta {
  .culture__social-links {
    min-width: 70px;
  }
}

.review-panel {
  #continue-btn {
    position: relative;
  }
}
