$lp--one-column-mpp-height: (min-height 640px) (orientation portrait);

#prechat_container {
  #cboxClose {
    #{$rdirection}: 20px;
  }
}

.cbox-prechat-mod {
  width: 525px !important;
}
/* prechat overlay */
.prechat-form-container {
  overflow: hidden;
  background-color: $color--white;
  overflow: hidden;
  .prechat-form-body {
    @include swap_direction(padding, 15px 10px 5px 15px);
    .wrapper-msg {
      position: relative;
    }
  }
  .msg-wrapper {
    padding-bottom: 13px;
  }
  .liveperson-btn-container {
    margin-top: 18px;
    width: 495px;
    .column-content {
      width: 235px;
    }
  }
  font-family: $tstar-font;
}

#cboxLoadedContent {
  /* allows for edge to edge black header */
  @include swap_direction(padding, 0);
}

.prechat-form-container .prechat-x-close {
  background: url('/images/btns/x-close.png') no-repeat scroll 100% 60% transparent;
  clear: both;
  float: #{$rdirection};
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-#{$rdirection}: -10px;
}

.prechat-form-container #beauty-advice-btn {
  float: #{$ldirection};
}

.prechat-form-container #order-question-btn {
  float: #{$rdirection};
}

.prechat-form-container .require-msg {
  font-size: 9px;
  text-transform: none;
  position: absolute;
  margin-top: 20px;
}

.prechat-form-container .instruction-msg,
.prechat-form-container .chat-hours-msg {
  font-size: 12px;
  margin-#{$rdirection}: 3px;
}

.prechat-form-container .instruction-msg {
  float: #{$ldirection};
}

.prechat-form-container .chat-hours-msg {
  float: #{$rdirection};
}

.prechat-form-container .registered-input {
  height: 30px;
  line-height: 18px;
  font-size: 12px;
  word-wrap: break-word;
}

.prechat-form-container .prechat-form-body .registered-input span {
  letter-spacing: 0.1em;
  text-transform: lowercase;
  width: auto;
  line-height: 50px;
}

.prechat-form-container .form-row-msg p {
  font-size: 0.8em;
}

.prechat-form-container h4 {
  background-color: #000;
  color: #fff;
  text-align: #{$ldirection};
  margin-top: 2px;
  letter-spacing: 0;
  font-size: 20px;
  position: relative;
  top: -2px;
  height: 39px;
  @include swap_direction(padding, 4px 0 0 8px);
  .sub_head {
    font-family: $tstar-font;
  }
}

.prechat-form-container .form-row {
  clear: both;
}

.prechat-form-container .form-row .form-col-one,
.prechat-form-container .form-row .form-col-two {
  float: #{$ldirection};
}

.prechat-form-container .form-row .form-col-one {
  width: 238px;
}

.prechat-form-container .form-row .form-col-two {
  width: 239px;
}

.prechat-form-container .textarea-form-row {
  @include swap_direction(padding, 12px 0 6px 0);
}

.prechat-form-container .textarea-top-msg {
  clear: both;
  padding-top: 16px;
}

.prechat-form-container textarea {
  width: 495px;
  box-sizing: border-box;
}

#prechat_container .textarea-top-msg {
  margin-top: 12px;
}

#prechat_container .textarea-top-msg,
.prechat-form-container label {
  display: block;
  font-size: 12px;
  letter-spacing: 0.2em;
  color: black;
}

.prechat-form-container .prechat-input {
  width: 100%;
}

.prechat-form-container .prepopulated-input {
  margin-top: 6px;
}

.prechat-form-container .form-col-one {
  float: #{$ldirection};
}

.prechat-form-container .form-col-two {
  float: #{$ldirection};
  margin-#{$ldirection}: 18px;
}

.prechat-form-container .form-col {
  width: 49%;
}

.prechat-form-container .form-row-btn {
  @include swap_direction(margin, 10px auto);
  text-align: center;
}

.prechat-form-container .form-spacer {
  float: #{$ldirection};
  @include swap_direction(margin, 20px);
}

.prechat-form-container .form-row-msg {
  clear: both;
}

.prechat-form-container .form-row-btn {
  @include swap_direction(margin, 0 auto);
  width: 150px;
  background-color: #0d091a;
  display: inline-block;
  text-align: center;
}

.prechat-form-container .form-row-btn div {
  float: #{$ldirection};
}

.prechat-form-container .form-row-btns {
  width: 480px;
  @include swap_direction(margin, 10px auto);
  cursor: pointer;
}

.prechat-form-container .form-row-btn span {
  color: white;
}

.prechat-form-container #textarea-your-question {
  font-size: 12px;
  padding-top: 12px;
  margin-bottom: 6px;
  height: 105px;
}

.prechat-form-container .lpbuttonproduct-msg,
.prechat-form-container .footarea .prechat-messaging-area {
  color: black;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.3em;
  padding-top: 0;
  text-transform: none;
}

.prechat-form-container {
  .prechat-form-body {
    .msg-wrapper {
      padding-bottom: 20px;
    }
    span {
      font-size: 12px;
      width: 138px;
      display: inline-block;
    }
    .beautyChat {
      width: 162px;
      height: 53px;
      line-height: 56px;
      background: $color--white;
      border: 1px solid #999999;
      color: $color--black;
      display: inline-block;
      font-size: 16px;
      letter-spacing: 0;
      position: relative;
      top: 4px;
      #{$ldirection}: 4px;
    }
    .ordertechChat {
      width: 162px;
      height: 53px;
      line-height: 56px;
      padding: 0;
      background: $color--white;
      border: 1px solid #999999;
      color: $color--black;
      display: inline-block;
      font-size: 16px;
      letter-spacing: 0;
      position: relative;
      top: 4px;
      #{$ldirection}: 9px;
    }
    .beautychat-hours {
      font-size: 12px;
      width: 165px;
      display: inline-block;
      float: #{$ldirection};
      margin-#{$ldirection}: 151px;
      margin-#{$rdirection}: 13px;
      margin-top: 9px;
      text-transform: none;
    }
    .ordertechchat-hours {
      font-size: 12px;
      width: 164px;
      display: inline-block;
      margin-top: 9px;
      margin-bottom: 15px;
      text-transform: none;
    }
    input[type='checkbox'] {
      display: none;
    }
    .btn.button-select {
      background: $color--black;
      color: $color--white;
    }
    .btn.button-unselect {
      background: $color--white;
      color: #cccccc;
    }
    .btn:hover {
      text-decoration: none;
    }
  }
}
/* lpbuttonproduct */
.product-question-btn .chat_link_left_text_container {
}

.product-question-btn .chat_link_left_text {
  color: white;
  font-size: 16px;
  letter-spacing: 0.2em;
  text-decoration: none;
  text-transform: uppercase;
}
/* styles used to override globals as inline prechat css */
h4.prechat-heading {
  text-align: #{$ldirection};
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: normal;
  margin-top: 5px;
}

.prechat-form-container {
  text-align: #{$ldirection};
  font-size: 20px;
  text-transform: uppercase;
}

.lpbuttonproduct-msg {
  clear: both;
  @include swap_direction(padding, 6px 0px);
  font-size: 10px;
}

.prechat-form-container .not-you-link {
  font-size: 11px;
}

.prechat-messaging-area a {
  border-bottom: 1px solid $color--black;
  padding-bottom: 1px;
  text-decoration: none;
}

.prechat-messaging-area p {
  @include swap_direction(margin, 0px);
  padding-bottom: 16px;
}

.prechat-messaging-area {
  clear: both;
  font-size: 11px;
  letter-spacing: 0.2em;
  @include swap_direction(padding, 0);
  text-transform: none;
  color: black;
}

.prechat-messaging-area-top {
  margin-bottom: 20px;
  text-transform: none;
  font-size: 11px;
}

.prechat-messaging-area-top .required {
  letter-spacing: 0.2em;
  @include swap_direction(padding, 3px 0);
  color: black;
}

.prechat-msg-emailtranscript-send {
  color: black;
  font-size: 0.5em;
  letter-spacing: 0.3em;
  margin-top: 12px;
  text-transform: none;
}

#prechat_container .prechat-form-container .not-you-container {
  display: none;
}

#prechat_container .prechat-form-container .textarea-top-msg {
  visibility: hidden;
  height: 18px;
}

#prechat_container .prechat-form-container .instruction-msg {
  /* visibility:hidden; */
}

#prechat_container .prechat-form-container span.order-msg,
#prechat_container .prechat-form-container span.beauty-msg {
  width: auto;
}

#prechat_container .prechat-form-container span.order-msg {
  display: none;
}

#prechat_container .prechat-form-container .prechat-messaging-area {
  clear: #{$ldirection};
  float: #{$ldirection};
  width: 258px;
  font-size: 12px;
  @include swap_direction(padding, 0 3px 0 1px);
}

#prechat_container .prechat-form-container .lpbuttonproduct-msg,
#prechat_container .prechat-form-container .willdo-msg,
#prechat_container .prechat-form-container .require-msg {
  display: none;
}

#prechat_container .prechat-form-container .form-col-one label,
#prechat_container .prechat-form-container .form-col-two label {
  display: none;
}

.prechat-form-container .livechat-btn-container .product-question-btn {
  float: #{$rdirection};
  width: 200px;
  clear: none;
  background-color: gray;
  height: 40px;
  margin-#{$rdirection}: 2px;
}

#prechat_container .prechat-form {
  display: inline-block;
}

#prechat_container .prechat-form-hide {
  opacity: 0.5;
}

#prechat_container .prechat-form-overlay {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

#prechat_container .lpbuttonorder-container {
  display: none;
}

.prechat-form-container .livechat-btn-container {
  padding-top: 10px;
  width: 225px;
  float: #{$rdirection};
}

#live_chat .select2-results {
  @include swap_direction(border, 1px solid $color--gray--light);
  @include swap_direction(padding, 0);
  @include swap_direction(margin, 0px 0px 4px 0px);
}

#product-page-livechat-hitarea {
  height: 25px;
  overflow: hidden;
  width: 95px;
  z-index: 99;
  position: fixed;
  bottom: 10px;
  #{$ldirection}: 10px;
  cursor: pointer;
}

#product-page-livechat-hitarea .product-page-livechat-hitarea-mask {
  position: relative;
  top: -1px;
  #{$ldirection}: 0px;
  color: red;
  width: 350px;
}

.page-products {
  #product-page-livechat-hitarea {
    display: block;
  }
  #product-page-livechat-hitarea {
    @include breakpoint($lp--one-column-mpp-height) {
      display: none;
    }
  }
}

.section-collections {
  #product-page-livechat-hitarea {
    display: none;
  }
}
