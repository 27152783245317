/* Singularity Grids
   ========================================================================== */
/*
 * Documentation: https://github.com/Team-Sass/Singularity/wiki
 */

// equidistant grids
$grid--2: 2;
$grid--3: 3;
$grid--4: 4;
$grid--5: 5;
$grid--6: 6;
$grid--8: 8;
$grid--12: 12;
// assymetrical grids
$grid--3-2: 3 2;
$grid--2-3: 2 3;
$grid--3-2-3: 3 2 3;
$grid--2-3-3: 2 3 3;
$grid--3-3-2: 3 3 2;
$grid--2-1: 2 1;
// product images on left, details on right

// gutters (no gutter by default)
@include add-gutter(0);
$grid-gutter: res-width($spacing);
//manually add on padding where needed

/* Mixin - Pyramid Grid
   ========================================================================== */
/*
 * @params
 *  $gutter
 *  $item
 */

@mixin pyramid-grid($gutter: 0, $item: '.grid--item') {
  @include clearfix();
  #{$item} {
    width: 100%;
    @include breakpoint($bp--small-up) {
      @include layout($grid--2, $gutter) {
        &:nth-of-type(2n + 1) {
          @include grid-span(1, 1);
          clear: both;
        }
        &:nth-of-type(2n + 2) {
          @include grid-span(1, 2);
        }
      }
    }
    @include breakpoint($bp--medium-up) {
      @include layout($grid--3, $gutter) {
        &:nth-of-type(3n + 1) {
          @include grid-span(1, 1);
          clear: both;
        }
        &:nth-of-type(3n + 2) {
          @include grid-span(1, 2);
        }
        &:nth-of-type(3n + 3) {
          @include grid-span(1, 3);
        }
      }
    }
    @include breakpoint($bp--large-up) {
      @include layout($grid--4, $gutter) {
        &:nth-of-type(4n + 1) {
          @include grid-span(1, 1);
          margin-#{$ldirection}: 0;
          clear: both;
        }
        &:nth-of-type(4n + 2) {
          @include grid-span(1, 2);
        }
        &:nth-of-type(4n + 3) {
          @include grid-span(1, 3);
        }
        &:nth-of-type(4n + 4) {
          @include grid-span(1, 4);
        }
      }
    }
  }
}

/* Mixin - 2/3/4 Pyramid Grid
   ========================================================================== */
/*
 * @params
 *  $gutter
 *  $item
 */

@mixin pyramid-grid-2up-mobile($gutter: 0, $item: '.grid--item') {
  @include clearfix();
  #{$item} {
    @include layout($grid--2, $gutter) {
      &:nth-of-type(2n + 1) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(2n + 2) {
        @include grid-span(1, 2);
      }
    }
    @include breakpoint($bp--medium-up) {
      @include layout($grid--3, $gutter) {
        &:nth-of-type(3n + 1) {
          @include grid-span(1, 1);
          clear: both;
        }
        &:nth-of-type(3n + 2) {
          @include grid-span(1, 2);
        }
        &:nth-of-type(3n + 3) {
          @include grid-span(1, 3);
        }
      }
    }
    @include breakpoint($bp--large-up) {
      @include layout($grid--4, $gutter) {
        &:nth-of-type(4n + 1) {
          @include grid-span(1, 1);
          margin-#{$ldirection}: 0;
          clear: both;
        }
        &:nth-of-type(4n + 2) {
          @include grid-span(1, 2);
        }
        &:nth-of-type(4n + 3) {
          @include grid-span(1, 3);
        }
        &:nth-of-type(4n + 4) {
          @include grid-span(1, 4);
        }
      }
    }
  }
}

/* Mixin - Pyramid Grid - 3 across
   ========================================================================== */
/*
 * @params
 *  $gutter
 *  $item
 */

@mixin pyramid-grid-3across($gutter: 0, $item: '.grid--item') {
  @include clearfix();
  #{$item} {
    width: 100%;
    @include breakpoint($bp--small-up) {
      @include layout($grid--2, $gutter) {
        &:nth-of-type(2n + 1) {
          @include grid-span(1, 1);
          clear: both;
        }
        &:nth-of-type(2n + 2) {
          @include grid-span(1, 2);
        }
      }
    }
    @include breakpoint($bp--medium-up) {
      @include layout($grid--3, $gutter) {
        &:nth-of-type(3n + 1) {
          @include grid-span(1, 1);
          clear: both;
        }
        &:nth-of-type(3n + 2) {
          @include grid-span(1, 2);
        }
        &:nth-of-type(3n + 3) {
          @include grid-span(1, 3);
        }
      }
    }
  }
}

/* Mixin - Pyramid Grid - Collapsible
  * this does not have clearing
  * elements can be hidden and shown with js
   ========================================================================== */
/*
 * @params
 *  $gutter
 *  $item
 */

@mixin pyramid-grid-collapsible($gutter: 0, $item: '.grid--item') {
  @include clearfix();
  #{$item} {
    float: #{$ldirection};
    width: 100%;
    @include breakpoint($bp--small-up) {
      @include layout($grid--2, $gutter) {
        width: 50%;
      }
    }
    @include breakpoint($bp--medium-up) {
      @include layout($grid--3, $gutter) {
        width: 33.33333%;
      }
    }
    @include breakpoint($bp--large-up) {
      @include layout($grid--4, $gutter) {
        width: 25%;
      }
    }
  }
}

/* Mixin - Pyramid Grid - MPP Redesign 2019
   ========================================================================== */
/*
 * @params
 *  $gutter
 *  $item
 */

@mixin pyramid-grid-mpp-custom-layout($gutter: 0, $item: '.grid--item') {
  @include clearfix();
  #{$item} {
    float: #{$ldirection};
    width: 100%;
    min-height: 500px;
    @include breakpoint($bp--medium-up) {
      @include layout($grid--2, $gutter) {
        width: 47%;
        min-height: 600px;
      }
      max-height: 610px;
    }
    @include breakpoint($bp--large-up) {
      @include layout($grid--3, $gutter) {
        width: 29.3333%;
      }
      max-height: 600px;
    }
    &.rectangle_wide {
      overflow: hidden;
      @include breakpoint($bp--large-up) {
        display: block;
        width: 64.6666%; // 3 items per row
      }
    }
    &.rectangle_tall {
      overflow: hidden;
      @include breakpoint($bp--large-up) {
        min-height: 1200px; // 3 items per row
        display: block;
      }
    }
    .tout-block-landscape {
      padding: 0;
    }
  }
}
