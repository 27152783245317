//////////////////////////////
// Modern Clearfix Mixin
//////////////////////////////
@mixin clearfix($extend: null) {
  $extend: if($extend != null, $extend, toolkit-get('clearfix extend'));

  @if $extend == true {
    @include dynamic-extend('clearfix') {
      @include clearfix(false);
    }
  } @else {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}
