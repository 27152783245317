// tooltipster styles
// theme: 'mac-tooltip'
.mac-tooltip {
  @include border-radius(0);
  @include opacity(0.9);
  @include transform(scale(1));
  background: $color--gray--lightest;
  @include swap_direction(border, none);
  .tooltipster-content {
    color: $color--black;
    font-size: $typesize--12px;
    line-height: 1.5;
    @include swap_direction(padding, 15px 10px);
    text-transform: uppercase;
    white-space: nowrap;
    a {
      border-bottom: 1px solid $color--black;
    }
  }
}

// "Grow" animation.
.tooltipster-grow {
  @include transform(scale(0, 0));
  @include transition-property(transform);
  -webkit-backface-visibility: hidden;
}

.tooltipster-grow-show {
  @include transform(scale(1, 1));
  @include transition-timing-function(cubic-bezier(0.175, 0.885, 0.32, 1.15));
}
