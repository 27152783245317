#palette_designer {
  display: block;
  @include swap_direction(padding, 48px 0 20px 20px);
  //    color: #fff;
  position: relative;
  max-width: 1600px;
  min-width: 837px;
  min-height: 600px;
}

#loader {
  position: absolute;
  text-align: center;
  top: 240px;
  width: 530px;
  z-index: 9999;
}

#loader_cover {
  display: block;
  opacity: 1;
  //    background: black;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}
/* PALETTE HEADER TEXT
-------------------------------------------- */
#palette_header {
  margin-bottom: 50px;
}

#palette_header H1 {
  font-family: 'TradeBold', Arial, sans-serif;
  font-size: 52px;
  letter-spacing: -2px;
  text-transform: uppercase;
  margin-bottom: -7px;
  margin-#{$ldirection}: -4px;
  color: #fff;
}

#palette_header H2 {
  font-family: 'TradeBold', Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  text-transform: uppercase;
  margin-bottom: 1px;
  color: #fff;
}

#palette_header P {
  font-family: 'TradeCond', Arial, sans-serif;
  font-size: 16px;
  color: #97989b;
  width: 500px;
  line-height: 18px;
  margin-bottom: 7px;
  text-transform: uppercase;
}

#palette_header .price {
  font-family: 'TradeCond', Arial, sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
}
/* FIELD AND BAG BUTTON
-------------------------------------------- */
#palette_footer {
  @include swap_direction(margin, 18px 0 0 -11px);
}

#palette_designer .progress {
  height: 33px;
  background-position: 1px 3px;
}
/* SWATCH TABS
-------------------------------------------- */
#swatch_tab {
  position: absolute;
  top: -25px;
  #{$ldirection}: -1px;
  font-family: 'TradeBold', Arial, sans-serif;
  font-size: 11px;
  text-transform: uppercase;
}

#swatch_tab .tab {
  text-decoration: none;
  color: #fff;
  @include swap_direction(padding, 5px 7px 3px);
  display: inline-block;
  background-color: #323232;
  @include swap_direction(border, 1px solid #545454);
  border-bottom: none;
  line-height: 16px;
  margin-#{$rdirection}: 3px;
  -webkit-transition: background-color 0.2s linear, color 0.2s linear, border-color 0.2s linear;
  -moz-transition: background-color 0.2s linear, color 0.2s linear, border-color 0.2s linear;
  -o-transition: background-color 0.2s linear, color 0.2s linear, border-color 0.2s linear;
  transition: background-color 0.2s linear, color 0.2s linear, border-color 0.2s linear;
}

#swatch_tab .tab.hidden {
  background-color: #191919;
  border-color: #363636;
  color: #666;
  padding-bottom: 2px;
  margin-bottom: 1px;
}

#swatch_tab .tab:hover {
  color: #fff;
  background-color: #323232;
  border-color: #545454;
}
/* SWATCH CONTAINER
-------------------------------------------- */
#palette_swatches {
  float: #{$ldirection};
  background-color: #323232;
  @include swap_direction(padding, 10px);
  height: 260px;
  width: 176px;
  @include swap_direction(border, 1px solid #545454);
  position: relative;
  @include swap_direction(margin, 0 30px 7px 0);
  z-index: 1;
}

#palette_swatches .swatches {
  width: 176px;
  height: 228px;
  position: relative;
  background-color: none;
}

#palette_swatches .swatches.hide {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}

#palette_swatches .swatches .tooltip {
  position: absolute;
  @include swap_direction(padding, 7px);
  @include swap_direction(border, 1px solid #000);
  z-index: 99999;
  display: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  font-family: Arial, sans-serif;
  font-size: 11px;
  white-space: nowrap;
  visibility: visible !important;
}

#palette_swatches .swatches .tooltip.show {
  display: block;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

#palette_swatches .swatches .swatch {
  width: 15px;
  height: 15px;
  float: #{$ldirection};
  margin-#{$rdirection}: 1px;
  margin-bottom: 1px;
  position: relative;
  background-color: none;
}

#palette_swatches .swatches .swatch .swatch_circle {
  cursor: move;
  display: none;
  position: absolute;
  z-index: 9999;
  overflow: hidden;
  *zoom: 1;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#palette_swatches .swatches .swatch .border {
  filter: alpha(opacity=0);
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  @include swap_direction(border, 1px solid #fff);
  position: absolute;
  background-color: none;
  top: 0;
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  bottom: 0;
  display: block;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

#palette_swatches .swatches .swatch .border_inner {
  display: block;
  background-color: none;
  @include swap_direction(border, 1px solid black);
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  top: 0;
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  bottom: 0;
}

#palette_swatches .swatches .swatch.over .border_inner,
#palette_swatches .swatches .swatch.over .border {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

#palette_swatches .swatches .swatch.out .border_inner,
#palette_swatches .swatches .swatch.out .border {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}

#palette_swatches .swatches .swatch IMG {
  display: block;
}
/* PALETTE PAN
-------------------------------------------- */
#palette_pan {
  float: #{$ldirection};
  margin-top: -25px;
  position: relative;
  z-index: 0;
}

#palette_pan .selection .title {
  text-transform: capitalize;
}
/* ERROR POPUP
-------------------------------------------- */
#palette_error {
  height: 48px;
  #{$ldirection}: 0;
  position: absolute;
  top: 219px;
  width: 172px;
  @include swap_direction(padding, 7px);
  background: url(/media/images/custom_palette/messagebox.png) no-repeat 0 0;
}

#palette_error .title {
  font-family: 'TradeBold', Arial, sans-serif;
  color: black;
  text-transform: uppercase;
}

#palette_error .message {
  font-family: Arial, sans-serif;
  color: #666;
  font-size: 11px;
}
/* PAN HOLDER CONTAINER
-------------------------------------------- */
#pan_holder {
  margin-top: 15px;
  position: relative;
}

#pan_holder IMG {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#pan_holder .dropzone {
  z-index: 0;
  position: absolute;
}

#pan_holder .dropzone .swatch_circle {
  position: absolute;
  cursor: move;
  z-index: 999;
  overflow: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#pan_holder .bg_circle {
  position: absolute;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=00);
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

#pan_holder .bg_circle.hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
}
/* PAN TITLE CONTAINER
-------------------------------------------- */
#palette_names {
  position: absolute;
}

#palette_names .row {
  overflow: hidden;
}

#palette_names.x2 .row {
  width: 280px;
}

#palette_names.x4 .row {
  width: 280px;
}

#palette_names.eye_x6 .row {
  width: 310px;
}

#palette_names.blush_x6 .row {
  width: 310px;
}

#palette_names.x15 .row {
  width: 500px;
}

#palette_names.x30 .row {
  width: 500px;
}

#palette_names .name {
  float: #{$ldirection};
  font-family: 'TradeCond', Arial, sans-serif;
  font-size: 12px;
  margin-#{$ldirection}: 5px;
  text-transform: uppercase;
  width: 94px;
  height: 15px;
}
