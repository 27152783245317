.checkout {
  .cart-item {
    &__qty,
    &__price {
      &-label {
        display: none;
      }
      &-kit-item {
        text-align: center;
        margin-top: 6px;
        margin-bottom: 0px;
      }
    }
    .remove_link {
      padding-top: 7px;
      display: inline-block;
      @include breakpoint($bp--medium-up) {
        padding-top: 0;
        display: inline;
      }
    }
    &__remove-form a,
    .remove-sample {
      @include body-mono();
    }
  }
  &__content {
    .loading {
      // the following are override styles, for mac_base styling, to get loading
      // messages to appear properly for UK's OPC implementation
      text-transform: none;
      margin: 1em 0;
      @include breakpoint($bp--medium-up) {
        width: 100%;
        float: none;
        margin-left: 0;
        margin-right: 0;
        padding-right: 0;
      }

      // this bit of styling comes from the BrZ UK1 implementation, to set up
      // loading message styling as it should be
      * {
        opacity: 0.8;
        filter: alpha(opacity=80);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
      }
    }
  }
  .gift-options__content.left {
    float: left;
  }
}

.cart-item {
  // breaking this out, for better DRY implementation
  &.sample &__remove-form {
    .checkout & {
      // changing the way the below is implemented, to provide the easiest way to keep inline content in this element's
      // container on a different line from the remove sample link (a sibling of this element, and an inline element),
      // when this element shouldn't shown
      //display: none;
      visibility: hidden;
      height: 0;
    }
  }
}

.select2 {
  &-container &-choice &-arrow {
    b {
      .checkout__content .product & {
        @include icon(caret-down);
        font-size: 24px;
        &:before {
          @include transform(rotate(-45deg));
        }
      }
    }
  }
}

.checkout__sidebar {
  #samples-panel {
    .product {
      margin-top: 0;
      word-break: break-word;
    }
  }
}
