@include breakpoint($bp--medium-down) {
  .livechat {
    @include swap_direction(padding, 0 $spacing);
    text-transform: uppercase;
    select {
      @include swap_direction(border, 1px solid $color--gray--light);
    }
  }
  .prechat_survey {
    #contact_form_fields .picker-label {
      width: 80%;
    }
  }
  .livechat__title {
    font-size: 1.5rem;
    border-bottom: 1px solid #c7c7c7;
    line-height: 1;
    @include swap_direction(margin, 0 0 20px 0);
    @include swap_direction(padding, 15px 0);
    text-align: center;
  }
  .btn--livechat {
    @include swap_direction(margin, 2em 0);
    -webkit-appearance: none;
    @include swap_direction(border-radius, 0);
  }
  .chat__input {
    -webkit-appearance: none;
    @include swap_direction(border-radius, 0);
  }
  .livechat__chatwindow {
    @include swap_direction(border, 1px #999 solid);
    @include swap_direction(padding, 3px);
  }
  .livechat__close {
    margin-bottom: 1em;
  }
  .remindme-later_msg {
    margin-bottom: 2em;
  }
  .chat_input {
    @include swap_direction(margin, 1em 0);
  }
  .prechat--required_fields {
    @include swap_direction(margin, 1em 0);
  }
  .cart-dialog p {
    display: block;
    clear: both;
    font-size: 0.9em;
    padding-top: 6px;
  }
  .cart-dialog {
    @include swap_direction(padding, 15px);
    @include swap_direction(margin, 10px auto);
    width: 275px;
    background-color: white;
    text-align: center;
    position: relative;
    height: 100px;
    z-index: 1002;
    margin-top: 150px;
    padding-top: 6px;
  }
  .livechat__chatwindow {
    .client {
      color: #666666;
    }
    .server,
    .client {
      padding-bottom: 0.7em;
      .by {
        font-weight: bold;
      }
      .text {
        text-transform: none;
      }
    }
  }
  .livechat-close-dialog {
    width: 100px;
    background-color: #000000;
    color: #ffffff;
    @include swap_direction(padding, 5px);
    cursor: pointer;
    text-transform: uppercase;
    font-family: $body-font-family--bold;
  }
  .livechat_title_desc {
    padding-bottom: 3em;
  }
  .continue-button {
    float: #{$ldirection};
  }
  .end-chat {
    float: #{$rdirection};
    .block-button {
      color: #ffffff;
    }
  }
  .overlay-mobile-container {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .overlay-mobile {
    background-color: black;
    height: '800px';
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1001;
  }
}

.prechat {
  position: relative;
  overflow: hidden;
  .chat-selection {
    margin-bottom: 3px;
  }
  .select-chat {
    margin-bottom: 8px;
  }
  .beautyChat {
    font-size: 17px;
    color: $color--black;
    height: 49px;
    border: 1px solid #999999;
    background: none;
    width: 49%;
    margin-#{$rdirection}: 2px;
    line-height: 46px;
    float: #{$ldirection};
  }
  .ordertechChat {
    font-size: 17px;
    color: $color--black;
    width: 49%;
    height: 49px;
    border: 1px solid #999999;
    background: none;
    line-height: 15px;
    display: table-cell;
    vertical-align: middle;
  }
  .beautychat-hours,
  .ordertechchat-hours {
    width: 49%;
    float: #{$ldirection};
    font-size: 10px;
    margin-top: 10px;
    text-transform: none;
  }
  .prechat_survey {
    margin-top: 38px;
  }
  .prechat-form {
    display: inline-block;
    width: 100%;
  }
  .prechat-form-hide {
    opacity: 0.5;
  }
  .order_chat_select {
    display: none;
  }
  .prechat-form-overlay {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1000;
  }
  .btn:hover {
    color: $color--black;
    text-decoration: none;
    border-bottom: 1px solid #999999;
  }
  .btn.button-select {
    color: $color--black;
    border: 1px solid #999999;
  }
  .btn.button-unselect {
    color: #cccccc;
    border: 1px solid #cccccc;
  }
}

.livechat .select2-container .select2-choice {
  border-width: 1px;
  padding-#{$ldirection}: 8px;
}

.lp_desktop {
  .lp_maximized,
  .lp_minimized {
    #{$ldirection}: auto;
  }
}
