/* ==========================================================================
   Components Organisms - Store Locator Landing
   ========================================================================== */

// Google Maps overrides
.gm-style {
  img {
    max-width: none;
  }
  label {
    display: inline;
    width: auto;
  }
}

.store-locator {
  @include background(transparent -9999px -9999px no-repeat);
  @include background-size(100% auto);
  position: relative;
  &.store-locator-landing {
    @include breakpoint($bp--largest-up) {
      @include background-size(cover);
      background-color: $color--gray--dark;
      background-position: center top;
      @include swap_direction(padding, 6% 0 16% 0);
    }
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(padding, 10% 0 12% 0);
    }
    @include breakpoint($bp--xxlarge-up) {
      @include swap_direction(padding, 9% 0 9% 0);
    }
  }
  .store-locator-landing__form {
    .store-locator-landing__title {
      font-size: 30px;
      @include swap_direction(margin, 22px 0 15px 0);
      text-align: center;
      @include breakpoint($bp--largest-up) {
        color: $color--white;
        font-size: 36px;
        @include swap_direction(margin, 20px 0);
        text-align: center;
      }
    }
    .icon--search {
      display: none;
    }
  }
  .form--search--advanced {
    max-height: 100%;
    width: auto;
    > .top {
      border-bottom: none;
      height: auto;
      @include swap_direction(padding, 0 30px);
      text-align: center;
      @include breakpoint($bp--largest-up) {
        border-bottom: none;
        height: auto;
        @include swap_direction(padding, 0 0 3.5% 0);
      }
    }
    .form--search {
      display: block;
      @include swap_direction(margin, 0 0 20px 0);
      @include swap_direction(padding, 0 0 50px 0);
      position: relative;
      height: 175px;
      @include breakpoint($bp--largest-up) {
        height: auto;
        display: inline-block;
        @include swap_direction(margin, 4px 4px 20px 0);
        @include swap_direction(padding, 0);
        vertical-align: top;
      }
      .form--inline {
        display: block;
        margin: 0;
        padding: 5px 0;
        @include breakpoint($bp--largest-up) {
          border: none;
          display: block;
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 0);
        }
        &:before {
          content: '';
        }
        .form-type-textfield {
          width: 100%;
          @include breakpoint($bp--largest-up) {
            width: 350px;
          }
        }
        .form-text {
          border-width: 1px;
          @include swap_direction(border-radius, 0px);
          border-bottom-#{$ldirection}-radius: 0px;
          border-bottom-#{$rdirection}-radius: 0px;
          border-top-#{$ldirection}-radius: 0px;
          border-top-#{$rdirection}-radius: 0px;
          font-size: 12px;
          @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 767px) {
            font-size: 16px;
            height: 56px;
          }
          text-transform: uppercase;
          @include breakpoint($bp--largest-up) {
            padding: 11px 12px;
            height: 60px;
          }
          &:focus {
            @include box-shadow(0 0 0 rgba(0, 0, 0, 0));
          }
        }
        .input.error {
          border: 1px solid $color--red;
        }
        .btn {
          bottom: 0;
          #{$ldirection}: 0;
          position: absolute;
          @include breakpoint($bp--largest-up) {
            bottom: auto;
            #{$ldirection}: auto;
            position: relative;
            vertical-align: top;
            width: auto;
          }
          &.store-locator__submit {
            width: 70%;
            @include breakpoint($bp--medium-up) {
              width: 85%;
            }
            @include breakpoint($width-xlarge) {
              width: 228px;
            }
          }
        }
        .btn.store-locator__submit,
        .geo_container__mobile {
          bottom: 40px;
          @include breakpoint($bp--largest-up) {
            bottom: 0;
          }
        }
      }
    }
    .geo_container {
      color: $color--black;
      display: inline-block;
      @include swap_direction(margin, 0 0 20px 0);
      position: relative;
      @include breakpoint($bp--largest-up) {
        color: $color--white;
      }
      .icon--geolocation {
        display: inline-block;
        font-size: 24px;
        @include swap_direction(margin, -5px 0 0 0);
      }
      &.geo_container__mobile {
        display: block;
        width: 25%;
        position: absolute;
        bottom: 0;
        #{$rdirection}: 0;
        margin: 0;
        @include breakpoint($bp--largest-up) {
          display: none;
        }
        @include breakpoint($bp--medium-up) {
          width: 11%;
        }
        .btn {
          width: 60px;
          #{$rdirection}: 0px;
        }
      }
      &.geo_container__pc {
        display: none;
        @include breakpoint($bp--largest-up) {
          display: inline-block;
        }
      }
    }
    .toggle-wrapper {
      position: relative;
      top: 85px;
      @include breakpoint($bp--largest-up) {
        top: auto;
        display: block;
        @include swap_direction(margin, 22px 0 22px 0);
      }
      @include breakpoint($bp--largest-up) {
        margin: 0;
      }
      .js-dropdown-toggle {
        display: block;
        @include breakpoint($bp--medium-up) {
          display: inline;
        }
      }
    }
    .form--search .store-locator__submit {
      margin-#{$ldirection}: 0;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      @include swap_direction(border-radius, 0); /* future proofing */
      -khtml-border-radius: 0; /* for old Konqueror browsers */
      @if $fonts_update == true {
        font-size: 14px;
      } @else {
        font-size: 18px;
      }
      @include breakpoint($bp--largest-up) {
        font-size: 15px;
      }
      @include breakpoint($bp--large-up) {
        width: auto;
        @include swap_direction(margin, 0);
      }
    }
    .toggle {
      border-bottom: none;
      text-decoration: underline;
      @include breakpoint($bp--largest-up) {
        border-bottom: none;
        color: $color--white;
        display: block;
        text-align: center;
        text-decoration: underline;
        position: absolute;
        #{$rdirection}: 25%;
        padding-top: 10px;
      }
      &:after {
        font-size: 15px;
        text-decoration: none;
        vertical-align: baseline;
        @include breakpoint($bp--largest-up) {
          color: $color--white !important;
          font-size: 15px;
          vertical-align: baseline;
        }
      }
    }
    .options {
      border-bottom: 1px solid $color--gray--lighter;
      border-top: 1px solid $color--gray--lighter;
      display: none;
      @include breakpoint($bp--largest-up) {
        background: $color--white;
        border-top: none;
        padding: 28px 17px 17px;
        position: relative;
      }
      @at-root .store-locator-landing .form--search--advanced .options {
        @include breakpoint($bp--largest-up) {
          position: absolute;
          width: 100%;
        }
      }
      &:before {
        @include breakpoint($bp--largest-up) {
          border-bottom: 20px solid $color--white;
          border-#{$ldirection}: 20px solid transparent;
          border-#{$rdirection}: 20px solid transparent;
          content: ' ';
          height: 0;
          #{$ldirection}: 50%;
          margin-#{$ldirection}: -10px;
          position: absolute;
          top: -20px;
          width: 0;
        }
      }
      .responsive-container {
        @include breakpoint($bp--largest-up) {
          max-width: 680px;
        }
      }
      .form-item-wrapper {
        display: block;
        vertical-align: top;
        @include breakpoint($bp--largest-up) {
          display: inline-block;
          width: 41.5%;
        }
        @include breakpoint($bp--medium-down) {
          width: 100%;
          @include swap_direction(margin, 0px auto);
          @include swap_direction(padding, 0 13px);
        }
        .store-locator__submit {
          @include swap_direction(border-radius, 0);
        }
        label {
          display: block;
          @include swap_direction(margin, 0 0 1em 0);
          @include swap_direction(margin, 0 0 1em 0);
          @include breakpoint($bp--largest-up) {
            font-family: $headline-font-family;
            text-transform: uppercase;
          }
        }
        &.submit_container {
          width: 84px;
        }
      }
      .form-item {
        display: block;
        margin-bottom: 1.125em;
        @include breakpoint($bp--largest-up) {
          display: inline-block;
          width: 100%;
        }
        input[type='text'] {
          text-transform: uppercase;
        }
      }
      .country,
      .city_intl {
        @include swap_direction(border, 1px solid $color--gray);
      }
    }
  }
  &.store-locator-results {
    @include breakpoint($bp--largest-up) {
      .store-locator-landing__form {
        position: absolute;
        z-index: 100;
        pointer-events: none;
        @include layout($grid--4) {
          @include grid-span(2, 1);
        }
        .store-locator-landing__title {
          display: none;
        }
      }
      .form--search--advanced {
        background: $color--white;
        pointer-events: auto;
        @include layout($grid--4) {
          @include grid-span(4, 1);
        }
        > .top {
          @include swap_direction(padding, 10px 10px 10px 20px);
          text-align: #{$ldirection};
          .icon--search {
            display: inline-block;
            font-size: 24px;
            @include breakpoint($bp--largest-up) {
              margin-top: 4%;
            }
          }
          .form--search {
            .form-type-textfield {
              width: auto;
              @include breakpoint($bp--largest-up) {
                width: 50%;
              }
            }
            .store-locator__submit {
              @include breakpoint($bp--largest-up) {
                width: 42%;
              }
            }
          }
        }
        .form-item-wrapper {
          @include breakpoint($bp--largest-up) {
            width: 35%;
          }
        }
        .geo_container {
          color: $color--black;
          vertical-align: top;
        }
        .toggle {
          color: $color--black;
          &:after {
            color: $color--black !important;
          }
        }
        .options {
          border-top: 1px solid $color--gray--lighter;
          &:before {
            @include swap_direction(border, none);
          }
        }
      }
    }
    @include breakpoint($bp--largest-up) {
      .store-locator-landing__form {
        @include layout($grid--4) {
          @include grid-span(3, 1);
        }
        .form--search--advanced {
          @include layout($grid--4) {
            @include grid-span(2, 2);
          }
        }
      }
    }
  }
  .location_info {
    background: $color--black;
    color: $color--white;
    font-family: $body-font-family;
    @include swap_direction(padding, 15px);
    text-transform: uppercase;
    width: 100%;
    a {
      color: $color--white;
    }
    .store_name {
      @include swap_direction(margin, 0);
    }
    .add-to-favorites {
      font-size: 20px;
    }
    &-col1 {
      @include breakpoint($bp--largest-up) {
        float: #{$ldirection};
        width: 49%;
      }
    }
    &-col2 {
      @include breakpoint($bp--largest-up) {
        float: #{$rdirection};
        width: 49%;
      }
    }
    &-close {
      cursor: pointer;
      float: #{$rdirection};
      font-size: 17px;
      @include swap_direction(margin, -5px 0 15px 15px);
    }
    .distance {
      float: #{$rdirection};
      @include swap_direction(margin, 0 0 15px 15px);
      text-transform: none;
    }
  }
  .results_panel {
    background-color: $color--white;
    position: relative;
    z-index: 99;
    @include breakpoint($bp--small-down) {
      height: auto !important;
    }
    .locations_map_panel {
      display: none;
      @include breakpoint($bp--largest-up) {
        display: block;
        @include layout($grid--4) {
          @include grid-span(2, 1);
        }
      }
      @include breakpoint($bp--largest-up) {
        display: block;
        @include layout($grid--4) {
          @include grid-span(3, 1);
        }
      }
      .map {
        height: 100%;
        @include swap_direction(margin, 0);
        min-height: 849px;
        width: 100%;
      }
      #services {
        @include swap_direction(padding, 0 20px);
        position: relative;
        #cboxClose {
          padding-top: 20px;
        }
        .store_services {
          padding-top: 16px;
          .content-3col__header {
            border-bottom-color: $color--gray--lighter;
          }
          .content-3col__content {
            padding: 0 3em 0 0;
          }
          .content-3col__headline {
            text-align: #{$ldirection};
            font-size: 30px;
          }
          h1,
          h2 {
            @include h5();
          }
          h3 {
            @include h6();
          }
        }
      }
    }
    .locations_table_panel {
      position: relative;
      overflow: hidden;
    }
    .location_info {
      width: 300px;
      @include breakpoint($bp--largest-up) {
        width: 540px;
      }
    }
    .results_side {
      background-color: $color--white;
      @include breakpoint($bp--largest-up) {
        @include layout($grid--4) {
          @include grid-span(2, 3);
        }
      }
      @include breakpoint($bp--largest-up) {
        @include layout($grid--4) {
          @include grid-span(1, 4);
        }
      }
      &-title {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 25px);
      }
      .sub-options {
        @include swap_direction(padding, 0 25px 10px);
        > label {
          display: block;
          font-family: $headline-font-family;
          @include breakpoint($bp--largest-up) {
            display: none;
          }
        }
        label {
          color: $color--black;
          text-transform: uppercase;
        }
        .form-item {
          display: block;
          float: none;
          @include swap_direction(margin, 0);
          width: auto;
        }
        .picker {
          @include swap_direction(margin, 0);
        }
        .filters {
          @include swap_direction(border, 1px solid $color--gray);
          padding-#{$ldirection}: 10px;
        }
        .description {
          height: 40px;
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 5px 0 0 0);
        }
        p {
          @include swap_direction(margin, 0);
        }
      }
      .picker-handle {
        background-color: $color--white;
      }
      .checked .picker-handle {
        background-color: $color--black;
      }
      .results-count {
        @include swap_direction(padding, 0 25px 10px);
        text-align: #{$rdirection};
        text-transform: uppercase;
        @if $fonts_update == false {
          font-family: $body-font-family;
        }
      }
      .doors_results-wrapper {
        border-top: solid 1px $color--gray--lighter;
        @include breakpoint($bp--largest-up) {
          height: 572px;
          overflow: auto;
          @include swap_direction(margin, 30px 0);
          position: relative;
          // hide scrollbar
          #{$rdirection}: -20px;
          margin-#{$ldirection}: -20px;
        }
      }
      table {
        table-layout: fixed;
        width: 100%;
        .door_hours,
        .door_events {
          background-color: $color--white;
          border-bottom: solid 1px $color--gray--lighter;
          @include swap_direction(padding, 5px 0);
        }
      }
      .store_day {
        @include swap_direction(margin, 10px auto);
        table-layout: auto;
        width: 100%;
        &.caps {
          text-transform: uppercase;
        }
      }
      .door_row {
        border-bottom: solid 1px $color--gray--lighter;
        cursor: pointer;
        &:hover {
          background-color: $color--gray--lightest;
        }
      }
      td.marker {
        @include swap_direction(padding, 15px 0 10px 17px);
        width: 64px;
        .result_number {
          display: inline-block;
          img {
            width: auto;
            height: 41px;
          }
        }
      }
      td.store-details {
        @include swap_direction(padding, 15px 0 10px 0);
        .store_name {
          display: none;
          margin: 0;
          @include breakpoint($bp--largest-up) {
            display: block;
          }
        }
        .store_name-mobile {
          display: block;
          @include breakpoint($bp--largest-up) {
            display: none;
          }
        }
      }
      td.distance {
        @include swap_direction(padding, 0 25px 0 0);
        text-align: #{$rdirection};
        width: 100px;
      }
      .door_info {
        @include swap_direction(padding, 0 25px 0 0);
        a {
          clear: both;
          display: block;
          float: #{$ldirection};
          margin-top: 3px;
        }
        .services_link.desktop {
          display: none;
          @include breakpoint($bp--largest-up) {
            display: block;
          }
        }
        .services_link.mobile {
          display: block;
          @include breakpoint($bp--largest-up) {
            display: none;
          }
        }
        .makeup_store_link_desktop {
          display: none;
          @include breakpoint($bp--largest-up) {
            display: block;
          }
        }
        .makeup_store_link_mobile {
          display: block;
          @include breakpoint($bp--largest-up) {
            display: none;
          }
        }
        .distance {
          float: #{$rdirection};
        }
      }
      .directions_link {
        .desktop {
          display: none;
          @include breakpoint($bp--largest-up) {
            display: inline;
          }
        }
        .mobile {
          display: inline;
          @include breakpoint($bp--largest-up) {
            display: none;
          }
        }
      }
      .shade-picker__controls {
        display: none;
        line-height: 26px;
        @include breakpoint($bp--largest-up) {
          display: block;
        }
        &.shade-picker__previous {
          top: 30px;
        }
        &.shade-picker__next {
        }
      }
    }
  }
}

.store_landing_only,
.makeup_studio_only {
  color: white;
  .tout-block-landscape {
    max-width: none;
    img {
      width: 100%;
    }
    &__body,
    &__body-above {
      img {
        width: auto;
      }
    }
  }
  .tout-block-landscape__headline {
    padding-top: 30px;
    margin-bottom: 0.2em;
    font-size: 20px;
    @include breakpoint($bp--medium-up) {
      @include fontsize-rem($typesize--24px);
      margin-bottom: 0.5em;
      padding-top: 0;
    }
  }
  .tout-block-landscape__cta {
    height: 30px;
    font-size: 14px;
    font-weight: normal;
    line-height: 12px;
    text-decoration: underline;
    @if $fonts_update == false {
      font-family: $body-font-family;
    }
    @include breakpoint($bp--medium-up) {
      height: 60px;
      line-height: 64px;
      font-size: 21px;
    }
    @include breakpoint($bp--larger-up) {
      border: solid 1px $color--white;
      text-decoration: none;
      @if $fonts_update == false {
        font-family: $ano-bold_regular-font;
      }
    }
  }
  .tout-block-landscape__headline {
    @include breakpoint($bp--medium-up) {
      font-size: 4rem;
    }
    @include breakpoint($bp--larger-up) {
      display: none;
    }
  }
  .tout-block-landscape__body-above {
    display: block;
    .tout-block-landscape__body-copy {
      display: none;
      @include breakpoint($bp--larger-up) {
        display: block;
      }
      a {
        color: $color--white;
      }
    }
    width: 100%;
    @include breakpoint($bp--medium-up) {
      width: 642px;
    }
    margin: 0 auto;
    h4 {
      @include swap_direction(margin, 0 0 1.5rem 0);
      font-size: 20px;
      @include breakpoint($bp--medium-up) {
        font-size: 2.5rem;
      }
    }
    .tout-block-landscape__header-image {
      margin: 0 auto;
      background-size: contain;
      background-repeat: no-repeat;
      width: 200px;
      height: 60px;
      @include swap_direction(margin, 30px auto 10px auto);
      @include breakpoint($bp--medium-up) {
        @include swap_direction(margin, 0 auto 1.5rem auto);
        width: 260px;
        height: 71px;
      }
    }
  }
}

.store_landing_only .tout-block-landscape__header-image {
  background-image: url('/media/export/cms/locator/makeup-services.png');
}

.makeup_studio_only {
  padding-top: 7px;
  .tout-block-landscape__header-image {
    background-image: url('/media/export/cms/locator/makeup_studio.png');
  }
}

.section-stores {
  .select2-results .select2-disabled {
    display: none;
  }
  .site-container {
    min-height: 360px;
  }
}

h4.store-heading,
h2.store-heading {
  display: none;
  @include breakpoint($bp--medium-up) {
    display: block;
  }
  margin: 1.3em 0;
  text-align: center;
}

.store_event {
  overflow: hidden;
}

//IE10+ store locator navigation button broken images
.section-stores {
  .locations_map_panel {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan down'],
      div[title^='Pan right'],
      div[title^='Pan up'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.international-search {
  .store-locator-landing__title {
    letter-spacing: normal;
  }
  .form--search--advanced {
    .top {
      display: none;
    }
    .options {
      display: block;
      position: initial;
      background: none;
      border: none;
      padding-top: 0;
      @include breakpoint($bp--medium-up) {
        padding-top: 10px;
      }
      .form--search--intl {
        @include swap_direction(padding, 1px 0 50px 3px);
        background: $color--white;
        display: inline;
        .form-item-wrapper {
          margin-top: 5px;
        }
        .submit_container {
          background: $color--white;
          width: 104px;
          margin-top: 0;
          .btn {
            height: 58px;
          }
        }
      }
    }
  }
  &.store-locator-results {
    .options {
      display: block;
      border: none;
      padding-top: 10px;
    }
  }
}
