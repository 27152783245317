.search-dimension {
  @include swap_direction(padding, $spacing 20px);
  background: $color--gray--lightest;
  border-color: $color--gray--light;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  overflow: auto;
  &:before {
    content: '';
    position: absolute;
    @include swap_direction(margin, -$spacing 0 0 -20px);
    height: 100%;
    border-#{$ldirection}: $color--gray--light solid 1px;
  }
  &:first-of-type:before {
    border-#{$ldirection}-width: 0;
  }
  &:last-of-type {
    border-bottom-width: 0;
  }
  @include breakpoint($bp--larger-up) {
    float: #{$ldirection};
    width: 20%;
    border-bottom-width: 0;
  }
  &__header {
    @include h6();
    @include fontsize-rem($typesize--18px);
    margin-top: 0;
  }
  dd {
    @include swap_direction(margin, 0);
  }
  a {
    @include unset-underline-links();
    display: block;
    color: $color--gray--light;
    @include swap_direction(padding, 5px 0);
  }
}
