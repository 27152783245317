/* NOTIFICATIONS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

$color--dark--blue: #040a2b;
$enable_cookie-img: false !default;
/* DEFAULT CONTAINER STYLE */
#bt_notification {
  div.bt-bottom-right {
    position: fixed;
    border: 1px solid $color--white;
    width: 100%;
    #{$rdirection}: 0;
    bottom: 0;
    @include breakpoint($bp--medium-up) {
      #{$rdirection}: 10px;
      width: 24.5em;
      bottom: 10px;
    }
  }
  .bt-content {
    padding: 5px;
    background-color: $color--black;
    z-index: 10000;
    font-size: 12px;
    line-height: 16px;
    color: $color--white;
    border: 1px solid $color--black;
    position: relative;
    transition: all 5s;
    -webkit-transition: all 5s; /* Safari */
    .bt-close-link {
      position: absolute;
      top: 10px;
      #{$rdirection}: 10px;
    }
    .bt-pp-section {
      display: none;
    }
    .bt-cookie-section {
      padding: 5px;
    }
    h2 {
      border: 0;
      font-size: 20px;
      margin-bottom: 10px;
    }
    .btn-wrap {
      text-align: center;
      background: $color--black;
      margin-top: 10px;
      border: 1px solid $color--white;
      a {
        width: 100%;
        font-weight: normal;
        padding: 0 3px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
      }
    }
    a {
      color: $color--white;
      font-weight: bold;
      border: 0;
      width: 100%;
      &.bt-close-link {
        display: block;
        width: 15px;
        height: 15px;
        background: url('/media/images/cookies/close_x.png') 50% 50% no-repeat;
      }
    }
  }
}
/* SETTINGS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_settings {
  .bt-content {
    padding: 28px;
    border: 2px solid $color--black;
    border-radius: 6px;
    height: 100%;
    background-color: $color--white;
    z-index: 1001;
    font-size: 12px;
    .bt-intro {
      .bt-intro-copy {
        p {
          margin-top: 1em;
          font-size: 14px;
          line-height: 16px;
        }
      }
      h2 {
        font-weight: 400;
        text-transform: uppercase;
        font-size: 36px;
        margin: 9px 0;
        line-height: 43px;
        color: $black;
      }
    }
    /* TOGGLE SLIDER */
    .slide {
      padding-#{$ldirection}: 0;
      margin: 10px auto;
      @include breakpoint($bp--medium-up) {
        padding-#{$ldirection}: 20px;
        width: 525px;
        margin: 20px 0;
      }
      .slide-toggle {
        margin: 0 auto;
        width: 292px;
        background-image: url('/media/images/cookies/m_all-off.png');
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        @include breakpoint($bp--medium-up) {
          margin: 0;
          width: 563px;
          background: transparent url('/media/images/cookies/all-off.png') left 100% no-repeat;
          background-size: contain;
        }
        li {
          cursor: pointer;
          float: $ldirection;
          width: 96px;
          height: 157px;
          list-style-type: none;
          @include breakpoint($bp--medium-up) {
            width: 175px;
            height: 148px;
          }
          span {
            display: none;
          }
        }
      }
      @if $enable_cookie-img == true {
        .slide-toggle {
          background: url('/media/images/cookies/m_all-off_v1.png') no-repeat;
          width: 288px;
          background-size: 288px 148px;
          @include breakpoint($bp--medium-up) {
            background: url('/media/images/cookies/all-off_v1.png');
            width: 525px;
            background-size: 525px 148px;
          }
          li {
            width: 144px;
            height: 148px;
            @include breakpoint($bp--medium-up) {
              width: 262px;
            }
            &.selected + .mask {
              border: 0;
            }
          }
        }
      }
    }
    @if $enable_cookie-img == true {
      .slide {
        width: 288px;
        @include breakpoint($bp--medium-up) {
          width: 525px;
        }
      }
    }
    .mask {
      position: absolute;
      overflow: hidden;
      top: 0px;
      #{$ldirection}: 0;
      width: 0;
      border-#{$rdirection}: 1px solid $color--gray--lighter;
      transition: width 1s;
      -webkit-transition: width 1s; /* Safari */
      .slide-toggle {
        background-image: url('/media/images/cookies/m_all-on.png');
        @include breakpoint($bp--medium-up) {
          background-image: url('/media/images/cookies/all-on.png');
        }
      }
      .drag {
        #{$rdirection}: 24px;
        bottom: 10px;
        position: absolute;
        width: 26px;
        height: 11px;
        background: transparent url('/media/images/cookies/icon_arrow.png') 50% 100% no-repeat;
        @include breakpoint($bp--medium-up) {
          bottom: 12px;
          #{$rdirection}: 70px;
        }
      }
      @if $enable_cookie-img == true {
        .slide-toggle {
          background: url('/media/images/cookies/m_all-on_v1.png') no-repeat;
          background-size: 288px 148px;
          @include breakpoint($bp--medium-up) {
            background-image: url('/media/images/cookies/all-on_v1.png');
            background-size: 525px 148px;
            margin: 0;
          }
        }
        .drag {
          right: 58px;
          @include breakpoint($bp--medium-up) {
            right: 120px;
          }
        }
      }
    }
    /* PRIVACY INFORMATION */
    .bt-privacy-info {
      display: none;
      overflow: hidden;
      &.selected {
        display: block;
        &[data-userprivacy='OPTED_IN'] {
          .bt-privacy-willnot {
            display: none;
          }
        }
      }
      h3 {
        @include swap_direction(padding, 36px 36px 36px 40px);
        background-position: 10px 50%;
        background-repeat: no-repeat;
        background-color: $color--gray--white;
        margin: 9px 0;
        font-size: 36px;
        line-height: 43px;
      }
      div {
        width: 100%;
        float: none;
        margin-#{$rdirection}: 2%;
        @include breakpoint($bp--medium-up) {
          width: 48%;
          float: $ldirection;
        }
        ul {
          margin: 1em;
          list-style-type: none;
          padding-#{$ldirection}: 0;
          li {
            font-size: 14px;
            line-height: 16px;
            margin: 0.8em 0;
            padding-#{$ldirection}: 30px;
            background-position: 0 50%;
            background-repeat: no-repeat;
          }
        }
      }
      .bt-privacy-will {
        h3 {
          background-image: url('/media/images/cookies/icon_safe_lock.png');
        }
        ul {
          li {
            background-image: url('/media/images/cookies/icon_safe_thick.png');
            list-style-type: none;
          }
        }
      }
      .bt-privacy-willnot {
        h3 {
          background-image: url('/media/images/cookies/icon_unsafe_lock.png');
        }
        ul {
          li {
            background-image: url('/media/images/cookies/icon_unsafe_thick.png');
            list-style-type: none;
          }
        }
      }
    }
  }
}
/* TEMPORARY HIDE COUNTRY LABEL */
.address-form__country-container {
  .label {
    visibility: hidden;
  }
}

.customer-service-section {
  #bt_settings {
    div {
      display: inline;
    }
  }
}

.tealium {
  display: none !important;
}
