/* ==========================================================================
   Components Atoms - Forms, Generic Forms
   ========================================================================== */

$picker-size: 20px;

label:not(.no-js-element) {
  display: none;
  .radio &,
  .checkbox & {
    display: inline-block;
    max-width: 85%;
  }
}

.field-container {
  @include clearfix;
  clear: both;
  @include breakpoint($bp--medium-up) {
    max-width: 660px;
  }
}

.form-item {
  margin-bottom: $spacing;
  .description {
    color: $color--gray;
    margin-top: -3px;
  }
  .field-container--grid & {
    @include breakpoint($bp--medium-up) {
      @include layout($grid--2, 1em) {
        &:nth-of-type(2n + 1) {
          @include grid-span(1, 1);
          clear: both;
          .valid_marker,
          .invalid_marker {
            right: 18px;
          }
        }
        &:nth-of-type(2n + 2) {
          @include grid-span(1, 2);
        }
        &.postal-code {
          .valid_marker,
          .invalid_marker {
            right: 5px;
          }
        }
      }
    }
  }
}

@if $fonts_update == true {
  form#profile_preferences {
    .profile-info__fieldset {
      .field-container.field-container--grid {
        .form-item.profile-info__item.csrHide {
          & + .profile-info__item.csrHide {
            width: 100%;
            text-align: center;
            margin-#{$ldirection}: 0;
            margin-#{$rdirection}: 0;
            clear: none;
            padding-#{$rdirection}: 0;
            @include breakpoint($bp--small-up) {
              width: 50%;
              float: $rdirection;
            }
          }
        }
      }
    }
  }
}
/* Frontend Validation Styles */
.panel {
  input {
    @include swap_direction(border, 1px solid $color--gray--light);
    @include swap_direction(border-radius, 0);
    &.error {
      border-color: red;
      box-shadow: none;
    }
  }
  select {
    height: 40px;
    border-bottom: 1px solid $color--gray--lightest;
    line-height: 20px;
    margin-bottom: 10px;
    &.error {
      border-color: red;
      box-shadow: none;
    }
  }
  input,
  select {
    resize: none;
    outline: none;
    @include swap_direction(border, 1px solid #ccc);
    @include swap_direction(border-radius, 0);
    &:required {
      box-shadow: none;
      &.error,
      &:invalid {
        border-color: red;
        box-shadow: none;
        &:empty,
        &[value=''],
        &:not(:checked),
        &.empty {
          border-color: #ccc;
          &.error,
          &.checkedEmpty,
          &.touched {
            border-color: red;
          }
        }
      }
      &:valid {
        border-color: $color--black;
        &.error {
          border-color: red;
        }
      }
      &[data-error] {
        border-color: red;
      }
    }
    &:focus {
      border-color: #00bafa;
    }
  }
  select {
    @include breakpoint($bp--medium-down) {
      border-width: 0px;
    }
  }
  input[type='button'],
  input[type='submit'] {
    &:valid {
      border-color: #ccc;
    }
  }
  .inline-error {
    position: absolute;
    @include box-shadow(#333 4px 4px 10px 1px);
    @include swap_direction(border, 1px solid #ccc);
    background-color: white;
    @include border-radius(3px);
    @include swap_direction(padding, 0.4em 0.8em);
  }
  .valid_marker,
  .invalid_marker {
    font-size: 2em;
    position: absolute;
    #{$rdirection}: 5px;
    bottom: 12px;
  }
  .valid_marker {
    @include icon(check, $size: ($picker-size - 1));
    color: black;
  }
  .invalid_marker {
    color: red;
    #{$rdirection}: 8px;
    font-family: 'Arial Unicode MS';
  }
  .form-item.select {
    .invalid_marker,
    .valid_marker {
      display: none;
    }
  }
  .select2-container.checkedEmpty {
    border: 1px solid red;
  }
}
/* restyle form elements that don't need validity styling */
.product .qty select:valid {
  border-color: #ccc;
}

.replenishment select:required:valid {
  border-color: #ccc;
}

#payment-panel .cvv {
  input {
    width: 7em;
  }
  .valid_marker {
    left: 55px;
  }
  .invalid_marker {
    left: 55px;
  }
}

.field-container .form-item input,
select,
textarea,
.select2-container {
  &.error {
    @include swap_direction(border, 1px solid red);
    box-shadow: none;
  }
}
