.order-details-page {
  .order-info {
    @include pyramid-grid(1em, '.order-info__item');
    .order-info__item {
      padding-bottom: 1em;
    }
  }
  .order-details {
    @include breakpoint($bp--medium-up) {
      @include layout($grid--4) {
        .order-details__item {
          @include grid-span(1, 1);
          clear: both;
          overflow: hidden;
          text-overflow: ellipsis;
          @include swap_direction(padding, 1em 0);
          &.js-billing_address_container {
            @include grid-span(1, 2);
            clear: none;
          }
        }
      }
    }
    .pay-by-link {
      margin: 1em 0;
    }
  }
  .product-header {
    border-bottom: 1px solid $color--gray--lightest;
    margin-bottom: 1em;
    @include heading--small;
    @include clearfix();
    @include layout($grid--12, 1em) {
      .product {
        @include grid-span(5, 1);
      }
      .tracking {
        @include grid-span(2, 6);
      }
      .status {
        @include grid-span(2, 8);
      }
      .price {
        @include grid-span(1, 10);
      }
      .quantity {
        @include grid-span(1, 11);
        text-align: center;
      }
      .total {
        @include grid-span(1, 12);
      }
    }
  }
  .cart-item {
    border-bottom: 1px solid $color--gray--lightest;
    margin-bottom: 1em;
    @include swap_direction(padding, 1em 0);
    @include clearfix();
    @include breakpoint($bp--medium-up) {
      .cart-item__price--mobile-only,
      .cart-item__qty-label {
        display: none;
      }
      @include layout($grid--12, 1em) {
        .cart-item__thumb {
          @include grid-span(2, 1);
        }
        .cart-item__desc {
          @include grid-span(3, 3);
        }
        .cart-item__tracking {
          @include grid-span(2, 6);
        }
        .cart-item__status {
          @include grid-span(2, 8);
        }
        .cart-item__price {
          @include grid-span(2, 10);
        }
        .cart-item__type {
          @include grid-span(3, 3);
        }
        .cart-item__qty {
          @include grid-span(1, 11);
          text-align: center;
        }
        .cart-item__total {
          @include grid-span(3, 12);
        }
      }
    }
    &.bundle-kit {
      .cart-item__thumb {
        float: none;
        text-align: center;
        @include breakpoint($bp--medium-up) {
          float: $ldirection;
        }
      }
    }
  }
  .order-totals {
    table {
      float: #{$rdirection};
      td {
        text-align: #{$rdirection};
        padding-#{$ldirection}: 1em;
      }
    }
  }
}
