.btn,
input[type='submit'] {
  @include btn;
}
/* specific exceptions to button styling */
.site-header .site-header__tools .site-bag__contents-checkout {
  font-size: 18px;
  @if $fonts_update == true {
    font-size: 15px;
  }
}

.btn--small {
  @include btn--small;
}

.btn--med {
  @include btn--med;
}

.btn--fixed_width {
  width: 228px;
}

.btn--full {
  @include btn--full;
}

.btn--light {
  @include btn--light;
}

.btn--clear-bg {
  @include btn--clear-bg;
}

.btn--border {
  @include btn--border;
}

.btn[disabled] {
  @include btn--disabled;
}

.bar-button {
  @include bar-button;
}

.byline-btn {
  @include byline-btn;
  &--light {
    @include byline-btn--light;
  }
  &--selected {
    @include byline-btn--selected;
  }
  &__title {
    @include byline-btn__title;
  }
  &__byline {
    @include byline-btn__byline;
  }
}

.shade-slider {
  @include byline-btn;
  background: $color--black;
  cursor: pointer;
  &--open {
    cursor: default;
    &:hover {
      background: $color--black;
    }
    .shade-slider__slide {
      display: block;
    }
  }
  &__title {
    @include byline-btn__title;
  }
  &__slide {
    display: none;
    position: relative;
    width: 259px;
  }
  &__image {
    @include swap_direction(border, solid 2px $color--white);
    height: 32px;
    background-size: 100% 32px;
    background-repeat: no-repeat;
    background-color: $color--black;
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
  }
  &__choices {
    @include clearfix();
    width: 259px;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
  }
  &__choice {
    font-size: $typesize--11px;
    text-align: center;
    @include swap_direction(margin, 0);
    float: #{$ldirection};
    width: 25%;
    color: $color--white;
    position: relative;
    text-transform: uppercase;
    border-color: transparent;
    border-width: 0 2px;
    border-style: solid;
    cursor: pointer;
    &-text {
      background: $color--black;
      display: block;
      margin-top: 32px;
      font-family: $headline-font-family;
      line-height: 1;
      @include swap_direction(padding, 8px 0 7px);
      position: relative;
    }
    &--active {
      border-color: $color--white;
      cursor: default;
      .shade-slider__choice-text {
        @include icon(caret-up);
        color: $color--black;
        background: $color--white;
        &:hover {
          &:before {
            color: $color--white;
          }
        }
        &:before {
          // icon styles
          color: $color--white;
          position: absolute;
          top: -15px;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          font-size: 20px;
          display: block;
          text-align: center;
        }
      }
    }
  }
}

// 2021 style refresh
// NEW CTA classes
// see global/buttons.scss for mixins
// NOTE: remove the a elements after extends (ew) are removed from the themes.

// Primary CTA (rounded button) - outlined (light with dark label)
.cta-primary,
a.cta-primary {
  @include cta-primary-outline;
}

// Primary CTA (rounded button) - filled (dark bg light label)
.cta-primary--dark,
a.cta-primary--dark {
  @include cta-primary-filled;
}

// Secondary CTA (text) - dark (dark text)
.cta-secondary,
a.cta-secondary {
  @include cta-secondary;
}
.cta-secondary--with-arrow,
a.cta-secondary--with-arrow {
  @include cta-secondary--with-arrow;
}

// Secondary CTA (text) - inverted (light text on dark bg)
.cta-secondary--inverted,
a.cta-secondary--inverted {
  @include cta-secondary--inverted;
}
.cta-secondary--inverted-with-arrow,
a.cta-secondary--inverted-with-arrow {
  @include cta-secondary--inverted-with-arrow;
}

// Cover CTA (large text with arrow icon) - dark (dark text)
.cta-cover,
a.cta-cover {
  @include cta-cover;
}
.cta-cover--with-arrow,
a.cta-cover--with-arrow {
  @include cta-cover--with-arrow;
}

// Cover CTA (large text with arrow icon) - inverted (light text on dark bg)
.cta-cover--inverted,
a.cta-cover--inverted {
  @include cta-cover--inverted;
}
.cta-cover--inverted-with-arrow,
a.cta-cover--inverted-with-arrow {
  @include cta-cover--inverted-with-arrow;
}

// Secondary CTA Text Link.
// Border-bottom property for multiline usage.
.cta-secondary--text-link,
a.cta-secondary--text-link {
  @include cta-secondary;
  border-width: 0;
  line-height: 1.4;
  & > span {
    border-bottom: inherit;
    border-bottom-color: inherit;
    border-width: 1px;
    color: inherit;
  }
  &:not(.mantle-cta--custom-bordercolor) {
    border-width: 0;
  }
}
