#loyalty__panel__offers {
  @include loyalty-panel;
  border-bottom: $border;
  clear: both;
  margin: 0 0 15px 0;
  @include breakpoint($bp--largest-up) {
    border: none;
    margin: 0;
  }
  .loyalty__panel__offers__title {
  }
  .loyalty__panel__offers__offer {
    position: relative;
    padding: 0 0 20px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .loyalty__panel__offers__offer-expires {
    color: red;
  }
  .loyalty__panel__offers__offer-description {
    @include breakpoint($bp--largest-up) {
      width: 50%;
    }
  }
}
