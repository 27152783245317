$appt_booking: true;
$tstar-font: roboto-condensed-regular, Helvetica, Arial, Sans-Serif;
$tstar-bold-font: roboto-condensed-bold, Helvetica, Arial, Sans-Serif;
$ano-bold_regular-font: roboto-condensed-bold, Helvetica, Arial, Sans-Serif;
$times-font: TimesNewRoman, 'Times New Roman', Times, serif;
$roboto-condensed-bold-font: roboto-condensed-bold, Helvetica, Arial, Sans-Serif;
$roboto-condensed-regular-font: roboto-condensed-regular, Helvetica, Arial, Sans-Serif;

$opc_pickpoint: true;
$opc_update: true;
$password_strengthen: true;
$gnav_update: true;

// 2021 styling refresh for MPP.
$cr21_mpp: false;