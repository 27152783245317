.enhanced-delivery-page:not([class*='enhanced_signin_page']) {
  .site-header {
    &__fixed-wrapper,
    &__container {
      background: $color--black;
    }
    &__fixed-wrapper {
      height: 60px !important;
    }
    &__container {
      .site-header {
        &__prefix {
          [class*='block-template-site-logo'] {
            .site-logo {
              &__image {
                display: none;
                @include breakpoint($width-largest) {
                  display: block;
                }
                &.no-svg {
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                  display: block;
                  visibility: visible;
                  @include breakpoint($width-largest) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    &__suffix {
      display: none;
    }
    @include breakpoint($width-largest) {
      &__menu {
        display: none;
      }
    }
  }
}
