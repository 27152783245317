.product--full.product--shadegrid {
  .product__detail {
    .product__product-details-shade-description {
      display: none;
      margin-left: 15px;
      @include breakpoint($bp--xlarge-up) {
        display: block;
        margin-left: 0px;
        margin-top: 10px;
      }
    }
    .product__sticky-footer {
      .product__product-details-shade-description {
        display: block;
        margin-left: 0;
        margin-bottom: 8px;
      }
    }
    .shade-picker {
      position: static;
      @include breakpoint($bp--xlarge-up) {
        position: absolute;
      }
    }
  }
  .product__header {
    @include breakpoint($bp--xlarge-up) {
      min-height: 215px;
    }
  }
  .product__name {
    @include heading--small;
    font-size: 1.75rem;
    @include breakpoint($bp--xlarge-up) {
      font-size: 2.5rem;
    }
  }
  .product__size {
    display: block;
    border: 0;
    padding: 0;
    min-height: auto;
    margin-top: 0;
    @include breakpoint($bp--xlarge-up) {
      float: left;
    }
  }
  .product__add-to-faves {
    font-size: inherit;
    line-height: inherit;
    margin-right: 0;
    .product__add-to-faves--text {
      display: inline;
    }
    .icon--heart-o {
      display: none;
    }
    @include breakpoint($bp--xlarge-up) {
      float: #{$ldirection};
      clear: #{$ldirection};
    }
    border-bottom: 1px solid #dbdbdb;
    display: block;
    margin-top: 10px;
    text-decoration: none;
  } // end .product__add-to-faves
  .product__footer {
    margin-top: initial;
    .product__price {
      display: inline-block;
      margin: 10px 10px 0 0;
      @include breakpoint($bp--xlarge-up) {
        display: block;
        float: #{$rdirection};
        margin-top: 0;
        margin-bottom: 0;
        margin-#{$ldirection}: 0;
        margin-#{$rdirection}: 0;
      }
    }
    a.product__add-to-bag {
      display: none;
      @include breakpoint($bp--xlarge-up) {
        display: block;
      }
    }
    .product__sticky-footer .product__add-to-bag {
      display: block;
    }
    .product_content_fav_mobile {
      border-top: 0;
      padding: 15px;
      width: 100%;
      @include breakpoint($bp--xlarge-up) {
        border-bottom: $border;
        border-top: $border;
        border-bottom: 0;
        padding: 15px 0 0 0;
        float: none;
      }
    }
    .product__inventory-status {
      clear: both;
      @include breakpoint($bp--large-down) {
        display: none;
      }
    }
    .shade-picker {
      @include breakpoint($bp--xlarge-up) {
        display: none;
      }
      .product--shade-picker--grid & {
        position: relative;
      }
    }
  } // end .product_footer
  .product__product-details-shade {
    margin-top: 12px;
  }
  .product__share-wrapper--pc {
    float: #{$rdirection};
    clear: #{$rdirection};
    margin-top: 10px;
  }
  .shade-picker-dropdown {
    display: block;
    #{$ldirection}: auto;
    padding-left: 15px;
    padding-right: 15px;
    position: static;
    width: 100%;
    @include breakpoint($bp--xlarge-up) {
      padding-left: 0;
      padding-right: 0;
    }
    .select2-container {
      margin-#{$ldirection}: 0;
      border-color: $color--gray--lighter;
      width: 100%;
      overflow: hidden;
      .select2-choice {
        border: 0;
        background-image: initial;
        background-color: initial;
        height: auto;
        text-decoration: none;
        padding-top: 8px;
        padding-bottom: 6px;
        padding-left: 8px;
        .select2-arrow {
          background-image: initial;
          background-color: initial;
          #{$rdirection}: -10px;
          top: 20px;
        }
      }
      &.select2-dropdown-open {
        //        .select2-choice {
        //        margin-#{$ldirection}: 0;
        //        }
      }
      .select2-chosen {
        @include heading--small;
      }
    }
  } // end .shade-picker-dropdown
  .select2-dropdown-open {
    // /*    width: calc(100% - 30px) !important; */
    width: 100%;
    //     .select2-choice {
    //       margin-#{$ldirection}: 0;
    //     }
  }
  // mobile plain dropdown
  // (mobile gets native selects)
  select {
    &.select-touch__chosen {
      @include heading--small;
      text-decoration: none;
    }
  }
  .product__product-details-badge-new {
    color: $color--red;
    text-transform: uppercase;
    margin-right: 10px;
  }
  .product__images {
    .carousel {
      .slick-prev,
      .slick-next {
        width: 24px;
        height: 45px;
        top: 40%;
        z-index: 1000;
      }
    }
    .slick-dots {
      li {
        display: none;
        @include breakpoint($bp--xlarge-up) {
          display: inline-block;
        }
        height: 112px;
        width: 128px;
        button {
          @include border-radius(0);
          border: 0;
          background-size: 128px 112px;
          height: 112px;
          width: 128px;
        }
        &.slick-active,
        &:hover {
          button {
            //          background-color: $color--gray--black;
            border: 1px solid $color--gray--light;
          }
        }
      }
    }
    .slider-nav {
      @include swap_direction(margin, 10px 50px);
      display: none;
      .slick-track {
        margin: auto;
      }
      @include breakpoint($bp--xlarge-up) {
        display: block;
      }
      .thumb-image-slick {
        @include swap_direction(margin, 0 5px);
        border: 1px solid transparent;
        &.slick-current {
          border-color: $color--gray--light;
        }
      }
      button {
        &.slick-prev,
        &.slick-next {
          width: 16px;
          height: 30px;
        }
      }
    }
    .slick-prev,
    .slick-next {
      &:before {
        content: none;
      }
      &.slick-disabled {
        opacity: 0.25;
      }
    }
    .slick-prev {
      background: url('/media/images/global/carousel-arrow-previous.png') no-repeat;
      background-size: 100%;
    }
    .slick-next {
      background: url('/media/images/global/carousel-arrow-next.png') no-repeat;
      background-size: 100%;
    }
  } // end .product__images
  .product__sticky-footer {
    @include breakpoint($bp--xlarge-up) {
      display: none;
    }
    box-shadow: 0px -2px 8px #888888;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 2000;
    background-color: $color--white;
    padding: 6px 10px 10px 10px;
    .product__shadename {
      @include heading--small;
      font-size: 1.75rem;
      float: left;
      @include breakpoint($bp--xlarge-up) {
        font-size: 2.5rem;
      }
    }
    .product__price {
      @include heading--small;
      font-size: 1.75rem;
      float: right;
      margin: 0;
      @include breakpoint($bp--xlarge-up) {
        font-size: 2.5rem;
      }
    }
    .product__buzzwords {
      clear: left;
    }
    .product__add-to-bag,
    .product__inventory-status {
      clear: none;
      float: #{$rdirection};
      width: 52%;
      margin: 0;
      display: block;
      &.btn--full {
        width: 100%;
      }
    }
    ul.product__inventory-status {
      li {
        margin: 0;
      }
    }
    .product__smoosh {
      float: #{$ldirection};
      width: 45%;
      height: 60px;
    }
  } // end .product__sticky-footer

  .product__shade-column,
  .product__shade-single {
    border-left: $border;
    padding: 0;
  }
  .product__shade-picker-mobile {
    .shade-picker__colors {
      width: 100%;
    }
  }
  .product__shade-picker-mobile {
    @include breakpoint($bp--xlarge-up) {
      display: none;
    }
    @include breakpoint($bp--large-down) {
      display: block;
      padding-bottom: 10px;
      padding-#{$ldirection}: 1px;
    }
    .shade-picker__colors-mask {
      top: 0;
    }
    .close_icon_bg {
      .shade-picker__colors-mask {
        top: 0;
      }
    }
  }
  .shade-picker__smoosh--active {
    background: 0 0 no-repeat;
    background-size: cover;
    height: 100%;
    max-height: 241px;
    width: 100%;
    display: none;
    @include breakpoint($bp--xlarge-up) {
      display: block;
    }
  }
  .shade-picker__filters {
    height: auto;
    line-height: inherit;
    padding: 8px 12px;
    @include breakpoint($bp--xlarge-up) {
      height: 60px;
      line-height: 60px;
    }
    &-label {
      @include swap_direction(margin, 0 10px 0 0);
      display: block;
      float: #{$ldirection};
      font-family: $ano-bold_regular-font;
      font-size: 15px;
      font-weight: normal;
      text-transform: uppercase;
      // shade picker label SPP
      .product--full & {
        @include breakpoint($bp--xlarge-up) {
          line-height: 62px;
        }
        font-size: 12px;
        font-family: $roboto-mono_bold-font;
        font-weight: normal;
        text-transform: uppercase;
        vertical-align: middle;
      }
    }
    @include breakpoint($bp--large-down) {
      select {
        height: auto;
        line-height: inherit;
        margin-top: 0;
      }
    }
    .shade-picker-sort {
      width: auto;
      border: 0;
    }
    .select2-container {
      min-width: 150px;
      width: auto !important;
      border: 1px solid $color--gray--lighter;
      .select2-choice {
        border: none;
        height: 38px;
        line-height: 38px;
        text-decoration: underline;
        > .select2-chosen {
          @include swap_direction(margin, 0 40px 0 10px);
        }
        .select2-arrow {
          #{$rdirection}: -5px;
          top: 4px;
          @include breakpoint($bp--xlarge-up) {
            #{$rdirection}: 0px;
            top: 7px;
          }
        }
      }
    }
    .select2-dropdown-open {
      border-color: $color--gray--lighter;
    }
  }
  .shade-picker__colors-wrapper {
    height: 100%;
    .product__shade-picker-mobile & {
      height: initial;
    }
    position: relative;
    .shade-picker__colors-mask {
      bottom: 0;
      left: 0;
      position: relative;
      right: 0;
      top: 0;
      width: 100%;
      @include breakpoint($bp--xlarge-up) {
        height: 400px;
      }
    }
  }
  .shade-picker__colors--grid {
    @include clearfix();
    padding: 0 10px;
  }
  .shade-picker__color--grid-item {
    float: left;
    margin: 1px;
    padding: 0 0 15.5% 0;
    width: 15.8%;
    @include breakpoint($bp--xxlarge-up) {
      width: 16%;
    }
    .product__shade-picker-mobile & {
      width: 16%;
    }
    .shade-picker__color-wrapper {
      height: 100%;
      min-height: 0;
      position: absolute;
      width: 100%;
    }
    .shade-picker__color-texture {
      background: -4px -4px no-repeat;
      background-size: 200%;
      border: 4px solid transparent;
    }
    &.shade-picker__color--selected {
      .shade-picker__color-texture {
        border-color: $color--black;
      }
    }
    &:hover {
      .shade-picker__color-texture {
        border-color: $color--gray--lighter;
      }
    }
  }
} // end .product__shadegrid
