.enhanced-delivery-page {
  &.checkout {
    #map-overlay-background {
      z-index: 2;
    }
    .loading.fade-in {
      margin-top: 0;
      top: 0;
      .text {
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        position: fixed;
        top: 50%;
      }
    }
    .email-and-sms-promotions {
      .content {
        padding-top: 0;
      }
      .pc_email_promo_container {
        .picker.picker-checkbox {
          .picker-handle {
            margin-top: 3px;
            .picker-flag {
              &::before {
                top: 4px;
              }
            }
          }
        }
      }
    }
    .terms-container {
      margin: 30px 17px 0;
      @include breakpoint($width-xlarge) {
        margin: 30px 0 0;
      }
      .picker-label {
        float: none;
        line-height: 1.6;
        .label-content {
          color: $color--black;
        }
      }
      .picker-checkbox {
        display: inline-flex;
      }
      .picker-handle {
        float: none;
        .picker-flag {
          &::before {
            font-size: 18px;
          }
        }
      }
    }
    &.active-panel-review {
      .checkout.panel {
        margin-top: 50px;
        @include breakpoint($width-xlarge) {
          margin-top: 0;
        }
      }
      .checkout__sidebar {
        &.right {
          margin-top: 24px;
        }
      }
      .review-panel.panel {
        max-width: 560px;
        #checkout_complete {
          .payment-type {
            .address-to-use {
              float: none;
              margin: 0;
              padding: 0;
              width: 100%;
              .picker-radio {
                .picker-label {
                  height: auto;
                }
              }
              &:last-child {
                .picker-radio {
                  border-bottom: 1px solid $color--mercury;
                }
              }
            }
          }
        }
        .single-message {
          word-break: normal;
        }
      }
    }
    &.active-panel-shipping {
      .checkout__content {
        #shipping-panel {
          #shipmethod-panel {
            .ship-method-group-label {
              .picker-label {
                font-family: $ano-bold_regular-font;
                font-size: 16px;
                font-weight: 700;
                line-height: 1;
                margin: 0;
                padding: 0 2px;
                width: 100%;
              }
            }
          }
          .checkout-shipping-panel-section {
            #pickpoint_customer_info.click-and-collect {
              .checkout__subtitle {
                padding-#{$ldirection}: 0;
              }
            }
          }
          .pickpoint_dropdowns {
            .checkout__panel-title {
              margin-bottom: 16px;
            }
          }
        }
      }
      .checkout {
        margin-top: 50px;
        @include breakpoint($width-xlarge) {
          margin-top: 16px;
        }
        .checkout__sidebar {
          &.right {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .checkout {
    .checkout__sidebar {
      .ship-label-sec {
        .shipping-label-data {
          .shipping-option {
            display: none;
          }
        }
      }
      #offer-code-panel {
        .offer-code__input {
          .valid_marker,
          .invalid_marker {
            z-index: 1;
          }
          .invalid_marker {
            #{$rdirection}: 20px;
            top: 16px;
          }
        }
      }
    }
    .column.left {
      &.checkout__content {
        .delivery_method {
          .select2-container {
            border: 0;
            height: 54px;
            padding: 0;
            pointer-events: none;
            .select2-arrow {
              b {
                &::before {
                  content: none;
                }
              }
            }
            .select2-choice {
              height: auto;
              line-height: 4.4;
              .select2-chosen {
                margin-#{$ldirection}: 0;
              }
            }
          }
        }
      }
      #shipping-panel {
        &.panel {
          .name-fields {
            .form-item {
              .invalid_marker {
                margin-#{$rdirection}: 0;
              }
            }
          }
          .country-id.select {
            .select2-arrow {
              display: none;
            }
            &::after {
              #{$rdirection}: 25px;
              border: 5px solid transparent;
              border-top-color: $color--black;
              bottom: 17px;
              content: '';
              pointer-events: none;
              position: absolute;
            }
          }
        }
        .shipmethod-panel {
          &.panel {
            border: 0;
            padding: 0;
            .ship-method-group {
              display: none;
            }
          }
          .ship-method-group-label {
            margin: 25px 17px;
            @include breakpoint($width-xlarge) {
              margin: 25px 0 42px;
            }
            .ship-method-home {
              & + .picker-radio {
                @include swap_direction(margin, 0 9px 0 0);
                @include breakpoint($width-xlarge) {
                  margin-#{$rdirection}: 12px;
                }
              }
            }
            .ship-method-pick-point {
              & + .picker-radio {
                @include swap_direction(margin, 0 0 0 9px);
                @include breakpoint($width-xlarge) {
                  margin-#{$ldirection}: 12px;
                }
              }
            }
          }
        }
        .edit-address-container {
          .city_container {
            label {
              display: block;
            }
          }
        }
        .new-address-container,
        .edit-address-container {
          .country {
            background: 0;
            border: 0;
            padding: 0;
          }
          &.click-and-collect {
            .personal-details {
              margin-top: 16px;
            }
            .local-collection {
              .set-collection-point {
                .form-item {
                  text-align: $rdirection;
                  .local-collection-button {
                    height: 48px;
                    line-height: 3.2;
                    margin-top: 16px;
                    @include breakpoint($width-largest) {
                      width: 348px;
                    }
                  }
                }
              }
            }
            .pickpoint_dropdowns {
              .label-content {
                display: none;
              }
            }
          }
          .country-id {
            &.select {
              &.form-item {
                .label-content {
                  display: none;
                }
                .select2-container {
                  padding: 0;
                  width: 100% !important;
                  a {
                    text-decoration: none;
                    .select2-chosen {
                      margin-#{$ldirection}: 10px;
                    }
                    .select2-arrow {
                      b {
                        &::before {
                          bottom: 19px;
                          font-size: 19px;
                          transform: rotate(0);
                          @include breakpoint($width-xlarge) {
                            bottom: 15px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .phone_label_container {
            display: none;
          }
        }
        .edit-shipping-address {
          .country_container {
            background: 0;
            border: 0;
            padding: 0;
            @include breakpoint($bp--small-down) {
              @include swap_direction(padding, 16px 20px 14px 15px);
              background: $color--gray--thick;
              border: 1px solid $color--very--light--grey;
              margin-bottom: 5px;
            }
            .valid_marker,
            .required_mark,
            label {
              display: none;
            }
            &.country-id.select {
              background: 0;
              border: 0;
              margin-bottom: 22px;
              padding: 0;
            }
          }
          .delivery_instruction_container {
            label {
              bottom: 77px;
            }
          }
        }
        .delivery-method {
          margin: 30px 0;
          .checkout__subtitle {
            margin-bottom: 15px;
            padding-#{$ldirection}: 15px;
            @include breakpoint($width-xlarge) {
              padding-#{$ldirection}: 0;
            }
          }
          .delivery_method {
            padding: 0 17px;
            @include breakpoint($width-xlarge) {
              padding: 0;
            }
          }
        }
        .messages-container {
          .single-message {
            word-break: normal;
          }
        }
      }
    }
  }
}
