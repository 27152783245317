.giftcard-container {
  @include responsive-container;
}

.gift-card-form {
  [type='text'],
  [type='email'],
  [type='number'],
  [type='password'],
  [type='search'] {
    margin-bottom: $spacing;
  }
  &__select {
    margin-bottom: $spacing;
    @include breakpoint($bp--large-up) {
      width: 50%;
      padding-#{$rdirection}: $spacing;
    }
  }
  &__title {
    @include h4();
  }
  &__legend {
    margin-bottom: $spacing;
  }
  &__text-fields {
    @include breakpoint($bp--large-up) {
      @include layout($grid--2, $spacing) {
        .form-type-text {
          &:nth-of-type(2n + 1) {
            @include grid-span(1, 1);
            clear: both;
          }
          &:nth-of-type(2n + 2) {
            @include grid-span(1, 2);
          }
        }
      }
    }
  }
  textarea {
    height: (58px * 2); // double text field height
    margin-bottom: $spacing;
  }
  &__terms {
    @include clearfix();
    display: block;
    width: 100%;
    margin-bottom: $spacing;
  }
  &__preview {
    display: none;
    .btn {
      @include btn--light;
      width: 100%;
      margin-bottom: $spacing;
      @include swap_direction(border, solid 1px black);
    }
  }
  &__submit {
    .btn {
      width: 100%;
      margin-bottom: $spacing;
    }
  }
  @include breakpoint($bp--small-up) {
    @include layout($grid--2, $spacing) {
      &__preview {
        @include grid-span(1, 1);
      }
      &__submit {
        @include grid-span(1, 2);
      }
    }
  }
}

.gift-card-balance,
.gift-card-order {
  &__photo {
    img {
      width: 100%;
    }
  }
  &__content {
    padding: 20px $spacing $spacing;
  }
  @include breakpoint($bp--large-up) {
    &__photo {
      @include grid-span(6, 1);
    }
    &__content {
      @include grid-span(6, 7);
    }
  }
}

.gift-card-balance {
  @include swap_direction(margin, 60px 0);
  &__photo {
  }
  &__title {
    @include h4();
    @include swap_direction(margin, 0 0 15px 0);
  }
  &__fields {
    margin-bottom: $spacing;
    overflow: hidden;
    width: 100%;
  }
  .giftcard_number_container,
  .giftcard_pin_container {
    float: none;
    width: 98%;
    margin-bottom: 1em;
  }
  @include breakpoint($bp--large-up) {
    .giftcard_number_container,
    .giftcard_pin_container {
      width: 49%;
      margin-bottom: 0;
    }
    .giftcard_number_container {
      float: #{$ldirection};
    }
    .giftcard_pin_container {
      float: #{$rdirection};
    }
  }
  .btn {
    float: #{$rdirection};
  }
  .required_mark {
    display: none;
  }
}

#giftcard_balance .gift-card-balance__fields input.error {
  border: 1px solid $color--red;
}

.gift-card-physical {
  @include clearfix();
  border-bottom: $border;
  &__content {
    position: relative;
    @include swap_direction(padding, $spacing);
    border-top: $border;
    @include breakpoint($bp--large-up) {
      float: #{$rdirection};
      width: 25%;
      border-top: 0;
      margin-top: 62px;
      border-#{$ldirection}: $border;
    }
  }
  &__image-container {
    @include breakpoint($bp--large-up) {
      float: #{$ldirection};
      width: 75%;
    }
  }
  &__image {
    width: 100%;
    @include swap_direction(margin, 0 auto);
    width: 100%;
    max-width: 640px;
    @include breakpoint($bp--larger-up) {
      width: 640px;
    }
  }
  &__title {
    @include h4();
  }
  &__errors {
  }
  &__intro {
  }
  &__select {
    margin-bottom: $spacing;
  }
  &__buttons {
    @include breakpoint($bp--large-up) {
      position: absolute;
      bottom: $spacing;
      #{$ldirection}: $spacing;
      #{$rdirection}: $spacing;
    }
    > p {
      margin-bottom: $spacing;
    }
    > input {
      @include btn;
      width: 100%;
    }
  }
}

.retrieve-giftcard {
  .panel {
    @include breakpoint($bp--large-up) {
      width: 75%;
    }
  }
}

.gift-card_physical__learn-more-text {
  display: none;
}

.gift-card_physical__works-well-with {
  border-top: solid 1px $color--gray--lighter;
  padding-bottom: 0;
  clear: both;
}

.gift-card-physical__workswith .expando-block__title {
  padding-bottom: 15px;
}

.section-giftcards,
.section-egiftcards {
  .site-container {
    min-height: 426px;
  }
}

.gift-card-physical__buttons {
  input {
    margin-bottom: 15px;
  }
}
