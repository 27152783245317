$color--black: #000;

.product--teaser {
  .product__header {
    .product__price_ppu {
      clear: both;
    }
  }
}

.product__share-wrapper--mobile {
  padding: 10px 0 20px 0;
}

.layout--artistry-artist {
  .artist--faves {
    .artist--products__carousel-slide {
      .product__footer {
        .product__inventory-status {
          li {
            width: 100%;
            .notify_me {
              width: 100%;
              height: 60px;
              line-height: 55px;
              margin-bottom: 20px;
            }
            .temp-outof-stock_text {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .artist--videos {
    .media-block {
      &__caption--two-col {
        footer {
          clear: both;
        }
      }
    }
  }
}

.bt-content-privacy-text {
  margin-top: 15px;
}

.artistry--artist {
  .artist-video-carousel-v1 {
    &.artist--videos {
      .carousel {
        .media-block--video {
          .media-block {
            &__caption--two-col {
              footer {
                float: none;
              }
            }
          }
        }
      }
    }
  }
}

.site-container {
  .collection-detail-formatter {
    overflow: hidden !important;
  }
  .custom-grid {
    overflow: hidden !important;
    .custom-grid {
      overflow: hidden !important;
    }
  }
}

body.section-fix {
  .site-container div {
    overflow: hidden !important;
  }
}

.product--shaded--single {
  .product__sku-details {
    .view-all-shades--mobile,
    .product__product-details-shade--single {
      display: none;
    }
  }
}

#bt_notification {
  .bt-cookie-section {
    float: left;
    width: 100%;
    .bt-buttons {
      float: left;
      width: 100%;
      margin-top: 10px;
      height: 52px;
      .btn-wrap {
        float: left;
        width: 48%;
        height: auto;
        margin-top: 0;
        text-align: center;
        &.accept-privacy {
          margin-right: 10px;
          a {
            &.btn {
              background-color: $color--white;
              color: $color--black;
              font-weight: 600;
              font-size: 15px;
              height: 44px;
              line-height: unset;
              padding: 16px 0;
            }
          }
        }
        &.personalize-privacy {
          a {
            padding: 16px 0;
            line-height: unset;
            height: 44px;
          }
        }
      }
    }
  }
}

@if $password_strengthen {
  .profile-password-update {
    &__rules {
      font-size: 8px;
      li {
        color: $color--black;
      }
    }
  }
  .password-field {
    &__rules {
      font-size: 8px;
    }
  }
}

.custom-kit-formatter {
  &__landing-content & {
    &__align-top {
      top: 33%;
    }
    &__start-cta {
      background: $color--white;
      color: $color--black;
      padding: 0 17px;
      height: 42px;
    }
  }
}

body {
  &.toolbar-drawer {
    /* stylelint-disable-next-line declaration-no-important */
    padding-top: 0 !important;
  }
}
