// re-declaring variables as _print.scss could be imported on its own
$text-direction: ltr !default;
$rdirection: right !default;
$ldirection: left !default;

@media print {
  @page {
    // Chrome's Print preview match inspector.
    //size: 486mm 635mm;
  }
  ul {
    page-break-inside: avoid;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  img {
    page-break-after: avoid;
  }
  div {
    page-break-inside: avoid;
  }

  /* ==========================================================================
     20-Global
     ========================================================================== */
  // _header
  .site-header {
    display: none;
  }

  // _site_footer
  .site-footer {
    display: none;
  }

  /* ==========================================================================
     40-components
     ========================================================================== */
  // _account_nav
  .account-nav {
    display: none;
  }
  //_buttons
  %btn {
    display: none;
  }

  // _carousels
  .carousel-controls {
    display: none;
  }

  // _favorites
  .favorites-page {
    &__content {
      .carousel {
        display: none;
      }
      // Logo for print.
      .print-logo {
        margin: 0.1cm;
        display: block;
        max-width: 119px;
        width: 100%;
        color: black;
        border-bottom: none;
      }
      // favorites header
      .favorites-page__header {
        display: none;
      }
      // favorites carousel
      .favorites {
        &-back {
          display: none;
        }
      }
      .favorites-none {
        &__text {
          margin: 18px 18px 35px 18px;
        }
      }
      // board
      .favorites-board {
        &__list {
          .pyramid-grid {
            width: 100%;
            padding-#{$rdirection}: 4cm;
            height: 100%;
          }
        }
        &__item {
          page-break-inside: avoid;
        }
        &__list-tools {
          display: none;
        }
        &__list-edit {
          display: none;
        }
        .favorite-actions__link {
          display: none;
        }
        &__footer {
          display: none;
        }
      }
    }
  }

  // _platform-toolbar
  .toolbar-menu {
    display: none;
  }

  // _products
  .product__add-to-bag {
    display: none;
  }
  .product__name,
  .product__subline {
    .product--teaser & {
      padding-#{$rdirection}: 0px;
    }
  }
  .product__images {
    .product--teaser & {
      max-width: 100% !important;
      margin: 10px;
    }
    .collection-quickshop & {
      display: none;
    }
  }
  .product__images-inner {
    .product--teaser & {
      text-align: center;
    }
    .product--full & {
      img {
        max-height: 100%;
        page-break-inside: avoid;
      }
    }
  }
  .back-to-mac__send_your_package--column,
  .return-package-formatter__contents-print--column {
    float: #{$ldirection};
    display: block;
    width: 23%;
    margin-#{$rdirection}: 10px;
  }
  .return-package--printable-module {
    // !import used to fence future changes, print content form should allways
    // just load the print form from the page.
    > * {
      display: none !important;
    }
    > .return-package__print-content {
      display: block !important;
    }
    .return-package-formatter__contents-print--column {
      width: 32%;
      margin: 0 0.5%;
    }
  }
}
