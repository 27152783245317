/**
 * @file
 * Product Common Classes
 * Added as part of the 2018 Creative Refresh
 *
 */

.product-brief-carousel {
  border-bottom: 1px solid $color--gray--light;
  border-top: 1px solid $color--gray--light;
  position: relative;
  @include breakpoint($bp--large-up) {
    border-left: 1px solid $color--gray--light;
    border-right: 1px solid $color--gray--light;
  }
  .slick-slider {
    margin: 0;
  }
  .slick-list {
    @include swap_direction(padding, 0 15% 0 0);
    @include breakpoint($bp--large-up) {
      padding: 0;
    }
  }
  .carousel-controls {
    height: 0;
    display: block;
    #{$ldirection}: 0;
    position: absolute;
    top: 44%;
    width: 100%;
  }
  &.grid--mpp {
    padding: 0;
  }
  &.grid--mpp__carousel {
    .slick-next,
    .slick-prev {
      height: 30px;
      width: 30px;
      &:before {
        color: $color--black;
        font-size: 30px;
      }
    }
    .slick-prev {
      @include icon('arrow--left');
      left: 2px;
      right: auto;
      @include breakpoint($bp--large-up) {
        left: -1px;
      }
      &:before {
        display: none;
        @include breakpoint($bp--large-up) {
          display: block;
          font-size: 15px;
        }
      }
    }
    .slick-next {
      @include icon('arrow--right');
      padding-left: 10px;
      position: absolute;
      right: 2px;
      @include breakpoint($bp--large-up) {
        padding-left: 0;
        right: -1px;
      }
      &:before {
        font-size: 15px;
      }
    }
    .grid--mpp__items {
      box-sizing: border-box;
      width: 100%;
      .grid--mpp__item {
        .product__image-medium {
          max-width: inherit;
        }
        border-#{$rdirection}: 1px solid $color--gray--light;
        border-#{$ldirection}: none;
        font-size: 12px;
        margin-bottom: 0;
        padding: 1em 1.5em;
        @include breakpoint($bp--large-up) {
          max-width: 100%;
          width: 100%;
          &:nth-child(3) {
            border-right: none;
          }
        }
        .product-brief__badge,
        .product-brief__product-name {
          @if $fonts_update == true {
            font-family: $helvetica-font;
            font-size: 11px;
            letter-spacing: 0;
          }
        }
      }
    }
  }
}
