/* ==========================================================================
   Components Molecules - FAQ question and answer styles
   ========================================================================== */
.mac-faq-wrapper {
  @include breakpoint($bp--large-up) {
    margin-top: 100px;
  }
}

.mac-faq {
  @include responsive-container;
  border-top: $color--gray--lighter 1px solid;
  @include swap_direction(padding, 0);
  position: relative;
  &.mac-faq--expanded {
    @include swap_direction(border, none);
  }
  &:nth-last-of-type {
    border-bottom: $color--gray--lighter 1px solid;
    &.mac-faq--expanded {
      @include swap_direction(border, none);
    }
  }
}

.mac-faq__heading {
  @include h4();
}

.mac-faq__q {
  @include h6();
  cursor: pointer;
  @include swap_direction(margin, 0 auto);
  position: relative;
  @include swap_direction(padding, 1em 1em 1em 3em);
  .mac-faq--expanded & {
    background: $color--gray--lightest;
  }
}

.mac-faq__num {
  #{$ldirection}: 1em;
  position: absolute;
}

.mac-faq__q-icon {
  @include animation-all;
  position: absolute;
  #{$rdirection}: 1em;
  .mac-faq--expanded & {
    @include transform(rotate(180deg));
  }
}

.mac-faq__a {
  display: none;
  height: 0;
  overflow: hidden;
  @include swap_direction(padding, 3em 4.5em);
  @include breakpoint($bp--xsmall) {
    @include swap_direction(padding, 3em 1.5em);
  }
  .mac-faq--expanded & {
    display: block;
    height: auto;
  }
  ul {
    list-style: disc;
    list-style-image: image-url('global/mac-bullet-small.png');
    margin-#{$ldirection}: 0;
  }
}
