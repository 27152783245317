// scss-lint:disable ImportantRule

.hidden {
  @include hidden();
}

.visuallyhidden {
  @include visuallyhidden();
}

// Hide visually and from screenreaders, but maintain layout
.invisible {
  visibility: hidden;
}

.noscroll {
  overflow: hidden !important;
}

.fixedpos {
  position: fixed !important;
  width: 100%;
}

.relativepos {
  position: relative !important;
}

.header-pos {
  position: absolute !important;
}

.narrowed_body_copy {
  max-width: 850px;
}

/* Presentational Classes
   ========================================================================== */

.show {
  display: block;
}

.hide-text {
  text-indent: -9999px;
}

/* Text Alignment
   ========================================================================== */

.align-left {
  text-align: #{$ldirection};
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: #{$rdirection};
}

/* Block Alignment
   ========================================================================== */

.block-align-left {
  @include breakpoint($bp--large-up) {
    margin-inline: 0 auto;
  }
}

.block-align-center {
  @include breakpoint($bp--large-up) {
    margin-inline: auto;
  }
}

.block-align-right {
  @include breakpoint($bp--large-up) {
    margin-inline: auto 0;
  }
}

.block-align-left-mobile {
  margin-inline: 0 auto;
  @include breakpoint($bp--large-up) {
    margin-inline: initial;
  }
}

.block-align-center-mobile {
  margin-inline: auto;
  @include breakpoint($bp--large-up) {
    margin-inline: initial;
  }
}

.block-align-right-mobile {
  margin-inline: auto 0;
  @include breakpoint($bp--large-up) {
    margin-inline: initial;
  }
}

/* Floats & Clearing floats
   ========================================================================== */

// Fluid Images for responsive designs
img.full {
  max-width: 100%;
  width: 100%;
  height: auto;
}

/* ==========================================================================
   Components Atoms - Utilities
   ========================================================================== */

.clear-left {
  clear: #{$ldirection} !important;
}

.clear-right {
  clear: #{$rdirection} !important;
}

.clear-both,
.clear {
  clear: both !important;
}

.float-left {
  float: #{$ldirection} !important;
}

.float-right {
  float: #{$rdirection} !important;
}

.float-none {
  float: none !important;
}

//emphasis colors
.information {
  color: $color--information;
}

.warning {
  color: $color--warning;
}

.success {
  color: $color--success;
}

.error {
  color: $color--error;
  &.error_messages {
    text-transform: uppercase;
  }
}

.grid--mpp {
  .error-message-limited-remaining {
    display: none;
  }
}

.bg-img {
  @include bg-img();
  // modifications via parent classes
  .make--bg-img-contained & {
    background-size: contain;
  }
  .make--bg-img-bgrd-white & {
    background-color: $color--white;
  }
  .make--bg-img-bgrd-black & {
    background-color: $color--black;
  }
}

.bg-img--expand {
  @include bg-img--expand();
}

body.ajax-wait *,
body.ajax-wait {
  cursor: progress !important;
}

/* ==========================================================================
   Generic padding classes using spacing variable
   ========================================================================== */

.padding--full {
  @include swap_direction(padding, $spacing);
}

.padding--vertical {
  @include swap_direction(padding, $spacing 0);
}

.padding--horizontal {
  @include swap_direction(padding, 0 $spacing);
}

/* ==========================================================================
   Utility classes for standardization - Creative Refresh, Fall 2018
   DO NOT change these unless you know ALL the places that will be affected
   ========================================================================== */

.block-eyebrow {
  font-family: $roboto-mono_regular-font;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 7px;
  line-height: 1;
  margin-bottom: 19px;
  text-transform: uppercase;
  @if $fonts_update == true {
    font-family: $helvetica-font;
  }
}

.block-headline {
  font-size: 40px;
  letter-spacing: 0;
  text-transform: uppercase;
  font-family: $ano-bold_regular-font;
  @if $fonts_update == true {
    font-weight: bold;
    line-height: 45px;
    margin: 0 0 5px -3px;
  } @else {
    line-height: 35px;
    margin: 0 0 0 -3px; // this is dumb but helvetica neue adds a pad at large size
  }
  @include breakpoint($bp--large-up) {
    line-height: 50px;
    @if $fonts_update == true {
      font-size: 52px;
      margin: 0 0 10px -3px;
    } @else {
      font-size: 55px;
    }
  }
}

.block-copy {
  @if $fonts_update == true {
    font-family: $helvetica-font;
    font-size: 15px;
    letter-spacing: 0;
  }
}

.block-cta {
  @if $fonts_update == true {
    @include h6();
    margin-bottom: 1.5em;
  }
}
