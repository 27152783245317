/* ==========================================================================
   Components Molecules - Blocks, Image Overlay
   ========================================================================== */

.block-image-overlay {
  position: relative;
  @include swap_direction(margin, 0);
  &__content-wrapper {
    @include reverse-text;
    @include unset-underline-links($extend: false);
    background: $color--overlay;
    bottom: 0;
    #{$ldirection}: 0;
    @include opacity(0);
    position: absolute;
    #{$rdirection}: 0;
    top: 0;
    @include transform-style(preserve-3d);
    @include single-transition(opacity);
    .no-touch & {
      &:hover {
        @include opacity(1);
      }
    }
    .block-image-overlay--hide-overlay & {
      background: transparent;
    }
  }
  &__link {
    bottom: 15px;
    display: block;
    position: absolute;
    text-align: center;
    width: 100%;
    @include breakpoint($bp--largest-up) {
      bottom: auto;
      display: none;
      position: relative;
    }
    .slick-initialized & {
      bottom: auto;
      display: none;
      position: relative;
    }
    &.btn {
      @include heading-mono;
      font-weight: normal;
    }
  }
  .block-image-overlay__alt-image {
    @include transform(translateX(flipX(-50%, #{$rdirection})));
    bottom: 53.75%;
    display: inline;
    #{$ldirection}: 50%;
    max-width: 75%;
    position: absolute;
  }
  &__content {
    @include transform(translate(flipX(-50%, $rdirection), -50%));
    #{$ldirection}: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 75%;
    .block-image-overlay--has-alt-image & {
      @include transform(translate(flipX(-50%, $rdirection), 0));
      top: 49.25%;
    }
    .block-image-overlay__link {
      display: none;
      @include breakpoint($bp--largest-up) {
        display: inline;
      }
      .slick-initialized & {
        display: inline;
      }
    }
  }
  &--instagram {
    &--font {
      &-small {
        .block-image-overlay__title,
        .at-h6__icon {
          @include h6();
        }
      }
    }
    .block-image-overlay__title {
      .icon--instagram {
        margin-top: -5px;
      }
    }
    .block-image-overlay__content-wrapper {
      color: white;
    }
  }
}
