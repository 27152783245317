/* ==========================================================================
   Components Molecules - Forms, Full Forms
   ========================================================================== */

/* Search
   ========================================================================== */

.form--search {
  > .form--inline {
    @include icon(search, $size: 22px);
    margin-top: -3px;
    @include swap_direction(padding, 0 1px 0 5px);
    .form-text {
      border-width: 0;
      font-size: $typesize--14px;
    }
  }
}

/* Search, Advanced
   ========================================================================== */

.form--search--advanced {
  $form--search--advanced__top-height: 54px;
  overflow: hidden;
  @include single-transition($property: max-height);
  max-height: $form--search--advanced__top-height;
  width: 640px; // @todo contemplate the need for a hard-set width; or make this responsive
  &.has-js-dropdown-open {
    max-height: 400px; // bigger than it should be, but not too much (or animation looks weird)
    .toggle {
      @include icon(arrow--up, $pseudo: after);
    }
  }
  > .top {
    border-bottom: solid 1px $color--border;
    @include swap_direction(padding, 6px 15px);
    height: $form--search--advanced__top-height;
  }
  .form--search {
    display: inline-block;
    .form-type-textfield {
      width: 350px;
    }
  }
  .toggle {
    text-transform: uppercase;
    @include icon(arrow--down, $pseudo: after);
    &:after {
      margin-#{$ldirection}: 6px;
    }
    .has-js-dropdown-open & {
      @include icon(arrow--up, $pseudo: after);
    }
  }
  > .options {
    @include swap_direction(padding, 28px 17px);
    .form-item {
      display: inline-block;
    }
  }
  .sub-options {
    padding-top: 17px;
    @include clearfix();
    > .form-item {
      float: #{$ldirection};
      width: 25%;
      padding-#{$rdirection}: 1%;
    }
  }
}

/* Newsletter
   ========================================================================== */

.form--newsletter {
  > fieldset {
    @include swap_direction(padding, 15px);
  }
  &__subtitle {
    @include fontsize-rem(15px);
    .holiday-2015 {
      @include fontsize-rem(13px);
    }
    @include swap_direction(margin, 0 0 10px 0);
    @include breakpoint($bp--xxlarge-up) {
      @include fontsize-rem($typesize--24px);
      .holiday-2015 {
        @include fontsize-rem(18px);
        line-heigh: 0.75em;
        display: inline-block;
      }
      @include swap_direction(margin, 0 0 15px 0);
    }
  }
  .error_messages {
    line-height: 1;
    @include swap_direction(margin, 0 0 10px 0);
    @include breakpoint($bp--xxlarge-up) {
      @include swap_direction(margin, 0 0 15px 0);
    }
  }
  &__form-items {
    .form-item {
      @include swap_direction(margin, 0 0 10px 0);
      @include breakpoint($bp--xxlarge-up) {
        @include swap_direction(margin, 0 0 15px 0);
      }
    }
    .form-text {
      @include swap_direction(padding, 6px 12px);
      @include breakpoint($bp--xlarge-up) {
        @include swap_direction(padding, 8px 12px);
      }
      @include breakpoint($bp--xxlarge-up) {
        @include swap_direction(padding, 21px 12px);
      }
    }
    .form-type-label {
      display: none;
    }
    .form-type-checkbox {
      display: none;
      padding-#{$ldirection}: 30px;
      @include breakpoint($bp--xxlarge-up) {
        padding-#{$ldirection}: 39px;
      }
      .picker {
        @include swap_direction(margin, 0);
      }
      .picker-handle {
        top: 0;
      }
      .picker-label {
        font-size: 11px;
        text-transform: none;
        @include breakpoint($bp--xlarge-up) {
          font-size: 12px;
        }
        p {
          line-height: 1;
          @include swap_direction(margin, 0);
        }
      }
    }
    .btn {
      height: 30px;
      line-height: 30px;
      width: 100%;
      @include breakpoint($bp--xxlarge-up) {
        height: 60px;
        line-height: 64px;
      }
    }
  }
}

.site-email-sms-signup {
  background: $color--white;
  height: 100%;
  position: relative;
  &__thank-you {
    background-color: $color--gray--black;
    height: 0;
    #{$ldirection}: 0;
    padding-top: 118%;
    position: absolute;
    top: 0;
    width: 100%;
    &__content {
      @include transform(translateY(-50%));
      color: $color--white;
      @include swap_direction(padding, 0 15px);
      position: absolute;
      text-align: center;
      top: 50%;
      width: 100%;
      h3 {
        @include swap_direction(margin, 0);
      }
    }
  }
  &__alternate {
    height: 0;
    #{$ldirection}: 0;
    padding-top: 118%;
    position: absolute;
    top: 0;
    width: 100%;
    &__content {
      #{$ldirection}: 0;
      @include swap_direction(padding, 15px);
      position: absolute;
      top: 0;
      width: 100%;
      &-title {
        @include fontsize-rem(15px);
        margin-top: 0;
        @include breakpoint($bp--xxlarge-up) {
          @include fontsize-rem($typesize--24px);
        }
      }
    }
    &-cta {
      bottom: 15px;
      #{$ldirection}: 15px;
      position: absolute;
    }
  }
}

.email-sms-signup-v2 {
  padding-top: 10px;
  .form--newsletter {
    > fieldset {
      @include breakpoint($bp--xxlarge-up) {
        @include swap_direction(padding, 15px 0);
      }
    }
    &__subtitle {
      span {
        display: block;
        font-family: $roboto-mono_regular-font;
        @include fontsize-rem($typesize--12px);
        padding-top: 5px;
      }
    }
    &__form-items {
      .form--txtfield {
        @include breakpoint($bp--xlarge-up) {
          width: 640px;
          height: 60px;
          margin-bottom: 10px;
        }
      }
      .form-wrapper {
        .newsletter--btn-submit {
          width: 80px;
          height: 60px;
          line-height: 60px;
        }
      }
    }
  }
}

.email-sms-signup-v3 {
  padding-top: 10px;
  .form--newsletter {
    > fieldset {
      @include breakpoint($bp--xxlarge-up) {
        @include swap_direction(padding, 15px 0);
      }
    }
    &__subtitle {
      margin-bottom: 0;
    }
    &__subtitle2 {
      display: block;
      font-family: $roboto-mono_regular-font;
      @include fontsize-rem($typesize--12px);
    }
    &__form-items {
      .form--txtfield {
        @include breakpoint($bp--xlarge-up) {
          width: 640px;
          height: 60px;
          margin-bottom: 10px;
          &:first-child {
            margin-top: 20px;
          }
        }
      }
      .form-type-checkbox {
        @include breakpoint($bp--xlarge-up) {
          width: 640px;
          .picker-checkbox {
            text-align: justify;
          }
        }
      }
      .form-wrapper {
        .newsletter--btn-submit {
          width: 80px;
          height: 60px;
          line-height: 60px;
        }
      }
    }
  }
  .site-email-sms-signup {
    &__thank-you {
      padding-top: 0px;
      position: relative;
      height: 215px;
      background: none;
    }
    &__thank-you__content {
      color: $color--black;
    }
  }
}
