.enhanced-delivery-page {
  .packstation_title {
    display: none;
  }
  .ship-method-home-group {
    &.ship-method-group {
      display: block;
    }
    .delivery-options-title {
      font-family: $ano-bold_regular-font;
      font-size: 16px;
      letter-spacing: 0.25px;
      margin-bottom: 24px;
      text-transform: uppercase;
    }
    .shipmethod-home-delivery {
      .picker {
        border: 1px solid;
        color: $color--black;
        margin-bottom: 16px;
        padding: 17px 15px;
      }
      .picker-label {
        color: $color--black;
      }
    }
  }
  .ship-method-group-item,
  .ship-methods {
    background: url('/media/images/checkout/home_delivery_icon.png') no-repeat center 22px;
    border: 1px solid;
    border-radius: 2px;
    color: $color--gray-warning;
    cursor: pointer;
    margin: 0 10px;
    opacity: 0.3;
    padding: 55px 0 20px;
    text-align: center;
    text-transform: uppercase;
    width: 50%;
    &--pack {
      background-image: url('/media/images/checkout/icon-location.svg');
    }
    &.active {
      color: $color--nero--thin;
      opacity: 1;
    }
  }
  .ship-method {
    &-clickcollect {
      background-image: url('/media/images/checkout/icon-location.svg');
    }
    &-group {
      display: flex;
      margin: 25px 7px;
      @include breakpoint($width-xlarge) {
        margin: 25px -10px 42px;
      }
      &-pack-active {
        .checkout-shipping-panel-section {
          .checkout__subtitle {
            .delivery_title {
              display: none;
            }
          }
        }
        .packstation_title {
          display: block;
        }
      }
      &-pack-active,
      &-home-active {
        .delivery-address-container.delivery_home,
        .delivery_pack {
          display: none;
        }
      }
    }
    .email-and-sms-promotions {
      .pc-email-promo-container {
        .picker {
          &.picker-checkbox {
            .picker-handle {
              @include swap_direction(margin, 0 15px 0 0);
              border: 2px solid $color--black--opacity-54;
              height: 18px;
              width: 18px;
              .picker-flag {
                &::before {
                  #{$ldirection}: 1px;
                  font-size: 16px;
                  position: absolute;
                  top: 5px;
                }
              }
            }
          }
          .picker-label {
            color: $color--black;
            font-size: 12px;
            line-height: 1.7;
            a {
              border-bottom: 0;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  #shipmethod-panel {
    border-bottom: 0;
    max-width: 562px;
    margin: 0 auto;
    #checkout_shipmethod {
      padding: 0;
    }
    header {
      margin: 27px 0 25px;
      @include breakpoint($width-largest) {
        margin-top: 43px;
      }
    }
    .ship-method-group {
      display: none !important;
    }
    .picker-handle {
      display: none;
    }
    .picker-radio {
      float: $ldirection;
      margin: 0;
      .picker-label {
        color: $color--gray-warning;
        line-height: 1;
      }
      &.checked {
        .picker-label {
          color: $color--nero--thin;
          opacity: 1;
        }
      }
    }
    label {
      @include swap_direction(margin, 7px 0 0 4px);
      color: $color--gray--lighter;
      float: $ldirection;
      line-height: 0.8;
    }
    .ship-method-group-label {
      display: flex;
      margin: 25px 7px;
      @include breakpoint($width-xlarge) {
        margin: 25px -10px 42px;
      }
      .picker {
        &-radio {
          border-radius: 2px;
          border: 1px solid;
          cursor: pointer;
          margin: 0 10px;
          opacity: 0.3;
          padding: 55px 0 20px;
          text-align: center;
          text-transform: uppercase;
          width: 50%;
          &.checked {
            opacity: 1;
          }
        }
        &-handle {
          height: 0;
          visibility: hidden;
        }
        &-label {
          width: 100%;
        }
      }
      .ship-method-home {
        & + .picker {
          background: url('/media/images/checkout/home_delivery_icon.png') no-repeat center 22px;
        }
      }
      .ship-method-pick-point {
        & + .picker {
          background: url('/media/images/checkout/icon-location.svg') no-repeat center 22px;
        }
      }
    }
  }
}
