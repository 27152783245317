/* ==========================================================================
   Components Molecules - Forms, Form Layout
   ========================================================================== */

.form--inline {
  .form-item,
  .btn {
    display: inline-block;
    margin-bottom: 0;
  }
}
