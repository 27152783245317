// Home Page Grid Generalized Styles (can be used elsewhere)
.custom-grid {
  @include clearfix();
  &--debug {
    outline: dotted 1px red;
    .custom-grid__row {
      @include grid-debugger($show-note: true);
      padding-top: 32px;
      position: relative;
      &:before {
        content: attr(data-name);
        @include h4();
        position: absolute;
        top: 5px;
        #{$ldirection}: 5px;
        background: red;
        z-index: 3;
        color: white;
      }
    }
    &-dark .custom-grid__row {
      > * {
        outline: none;
        &:before {
          background: hsl(0, 0%, 20%);
          @include swap_direction(border, solid 3px white);
        }
      }
    }
  }
  img:not(.default-size) {
    width: 100%;
  }
  .custom-grid__row {
    background: $color--black;
    overflow: hidden;
  }
  .custom-grid__item {
    overflow: hidden;
  }

  // gutters
  &.custom-grid-gutter {
    @include swap_direction(padding, 0 20px 20px 0);
    .custom-grid__item-gutter {
      @include swap_direction(padding, 20px 0 0 20px);
    }
  }
  @include layout($grid--4, 0) {
    @for $width from 1 through 4 {
      @for $location from 1 through 4 {
        [data-width-sm='#{$width}'][data-location-sm='#{$location}'] {
          @include grid-span($width, $location);
          @for $height from 1 through 10 {
            &[data-height-sm='#{$height}'] {
              $ratio: ($width / ($height * 0.75));
              padding-top: (column-span($width, $location) / $ratio);
            }
            &[data-margin-top-sm='#{$height}'] {
              $ratio: ($width / ($height * 0.75));
              margin-top: (column-span($width, $location) / $ratio);
            }
          }
        }
      }
    }
    .custom-grid__item.sm-grid-clear--left {
      clear: #{$ldirection};
    }
    .custom-grid__item.sm-grid-clear--right {
      clear: #{$rdirection};
    }
    .custom-grid__item.sm-grid-clear--both {
      clear: both;
    }
    .lg-grid-hide {
      display: block;
    }
    .sm-grid-hide {
      display: none;
    }
    .custom-grid__item {
      position: relative;
      height: 0;
    }
    .custom-grid__item-inner {
      // intrinsic ratio child
      display: block;
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      top: 0;
      @include swap_direction(margin, 0);
      border-bottom: solid white $gap;
    }
    [data-location-sm='1'][data-width-sm='2'] {
      .custom-grid__item-inner {
        border-#{$rdirection}: solid white ($gap / 2);
      }
    }
    [data-location-sm='3'][data-width-sm='2'] {
      .custom-grid__item-inner {
        border-#{$ldirection}: solid white ($gap / 2);
      }
    }
    .unique-square {
      padding-top: 100% !important;
    }

    // START: specific component overrides
    .media-block__caption--two-col > footer {
      float: none;
    }
    .media-block__body {
      display: none;
    }
    .text-block {
      background: $color--white;
      position: absolute;
      height: 100%;
      width: 100%;
    }
    // this fixes elements that had `position: relative` - caused div to collapse and show no image
    .media-block__media,
    .block-image-overlay {
      position: static;
    }
    .media-block,
    .block,
    .node,
    .content {
      height: 100%;
      @include breakpoint($bp--small-down) {
        display: block;
        position: relative;
      }
    }
    .carousel {
      height: 100%;
      .slick-slider,
      .slick-list,
      .slick-track {
        height: 100%;
      }
      &.carousel--has-dots {
        .slick-slider,
        .slick-list,
        .slick-track {
          height: auto;
        }
      }
    }
    // END: specific component overrides

    // START: Drupal Contextual Links
    .contextual-links-region {
      position: static;
      width: 100%;
      height: 100%;
    }
    // END: Drupal Contextual Links
  }
  @include breakpoint($bp--largest-up) {
    @include layout($grid--4, 0) {
      [data-margin-top-sm] {
        margin-top: 0;
      }
      @for $width from 1 through 4 {
        @for $location from 1 through 4 {
          [data-width-lg='#{$width}'][data-location-lg='#{$location}'] {
            @include grid-span($width, $location);
            @for $height from 1 through 3 {
              &[data-height-lg='#{$height}'] {
                $ratio: ($width / ($height * 0.75));
                padding-top: (column-span($width, $location) / $ratio);
              }

              &[data-margin-top-lg='#{$height}'] {
                $ratio: ($width / ($height * 0.75));
                margin-top: (column-span($width, $location) / $ratio);
              }
            }
          }
        }
      }
      .sm-grid-hide {
        display: block;
      }
      .lg-grid-hide {
        display: none;
      }
      .custom-grid__item[class*='sm-grid-clear'] {
        clear: none;
      }
      .custom-grid__item.lg-grid-clear--left {
        clear: #{$ldirection};
      }
      .custom-grid__item.lg-grid-clear--right {
        clear: #{$rdirection};
      }
      .custom-grid__item.lg-grid-clear--both {
        clear: both;
      }
      [data-location-sm][data-width-sm] .custom-grid__item-inner {
        border-width: 0;
      }

      // scss-lint:disable ImportantRule
      .unique-snowflake {
        padding-top: 42.25% !important;
      }
      .unique-snowflake-2 {
        margin-top: 32.7% !important;
      }
      .unique-small-snowflake {
        padding-top: 29.5% !important;
      }
      .unique-tiny-snowflake {
        padding-top: 12.75% !important;
      }
      // scss-lint:enable ImportantRule
    }
  }
  // $bp--large-up

  @include breakpoint($bp--xxlarge-up) {
    // specific component overrides
    .media-block__caption--two-col > footer {
      float: #{$rdirection};
    }
    .media-block__body {
      display: block;
    }
  }

  // carousel overrides
  .carousel {
    .slick-dots {
      bottom: 0;
      color: $color--white;
      height: 60px;
      line-height: 60px;
      li {
        width: 100%;
      }
    }
    .carousel-controls {
      bottom: 0;
      position: absolute;
      width: 100%;
      .slick-prev,
      .slick-next {
        &:before {
          color: $color--white !important;
        }
      }
      .btn {
        display: none;
      }
    }
    &.carousel--small-controls .slick-dots {
      height: 30px;
      line-height: 30px;
      @include breakpoint($bp--largest-up) {
        height: 60px;
        line-height: 60px;
      }
    }
    &.carousel--small-controls .carousel-controls {
      bottom: 15px;
      height: 30px;
      line-height: 30px;
      @include breakpoint($bp--largest-up) {
        bottom: 0;
        height: 60px;
        line-height: 60px;
      }
    }
    &.carousel--has-dots {
      .slick-dots {
        @include breakpoint($bp--small-down) {
          height: 25px;
          margin-top: -25px;
          padding-bottom: 5px;
          line-height: 15px;
        }
        bottom: 25px;
        height: 27px;
        line-height: 25px;
        @include breakpoint($bp--largest-up) {
          height: 25px;
        }
        li {
          width: 10px;
        }
      }
    }
    &.basic-carousel-item-formatter {
      position: relative;
      .carousel-controls {
        background-color: $color--black;
      }
    }
  }
}

.device-mobile {
  .custom-grid {
    overflow-x: hidden;
    .carousel {
      &.carousel--has-dots {
        .slick-slider {
          height: inherit;
        }
        .slick-dots {
          padding-top: 5px;
          padding-bottom: 15px;
          @include breakpoint($bp--small-down) {
            top: -5px;
          }
        }
      }
      &.landscape {
        height: 100% !important;
      }
    }
  }
}

@include breakpoint($bp--small-up) {
  .custom-grid {
    .carousel {
      &.carousel--has-dots {
        .slick-dots {
          bottom: -15px;
        }
      }
    }
  }
}

@include breakpoint($bp--medium-up) {
  .custom-grid {
    .carousel {
      &.carousel--has-dots {
        .slick-dots {
          bottom: -9px;
          height: 0;
          padding-top: 5px;
          padding-bottom: 25px;
        }
      }
    }
  }
}
