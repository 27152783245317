.email-signup-new-user {
  margin: 0 auto;
  width: 90%;
  @include breakpoint($bp--medium-up) {
    width: 100%;
  }
  .picker-label {
    width: 90%;
  }
  #form--email_signup_new_user--field--PHONE2 {
    margin-bottom: 12px;
  }
  margin: 0 auto;
  .picker {
    margin-bottom: 6px;
  }
  .picker-radio {
    display: inline-block;
  }
  .my-store {
    display: none;
  }
  .at-h3__typography,
  .at-h5__typography {
    font-family: $ano_regular-font;
  }
  .at-h3__typography {
    text-align: center;
  }
  .email-signup,
  .mobile-signup {
    .field-container {
      font-family: $ano_regular-font;
      margin: 0 auto;
      .picker-label,
      p {
        font-size: 12px;
      }
    }
    .picker-label {
      text-transform: none;
    }
  }
  .email-signup {
    .validate-email {
      margin-bottom: 12px;
      font-family: $ano_regular-font;
      font-size: 12px;
    }
  }
  .mobile-signup {
    @if $hide_sms_opt {
      .sms-promotions {
        display: none;
      }
    }
    input[type='text'] {
      width: 100%;
      margin-#{$rdirection}: 33px;
      margin-bottom: 20px;
      font-family: $ano_regular-font;
      font-size: 12px;
      @include breakpoint($bp--large-up) {
        width: 80%;
      }
    }
  }
  .form-submit {
    font-size: 12px;
  }
}
