/* LBO */

$bp--xmedium-up: $width-large - 1;

.link-based-offer-ref {
  display: none;
}

@if $lbo-enabled {
  #viewcart-panel {
    .lbo-container {
      .checkout__sidebar & {
        display: none;
      }
      .lbo-msg {
        display: inline;
        .error & {
          display: none;
        }
      }
      border-bottom: 1px solid #dbdbdb;
      @include swap_direction(margin, 8px 0 25px);
      @include swap_direction(padding, 0 15px 20px);
      font-family: $tstar-font;
      font-size: 12px;
      @include breakpoint($bp--largest-up) {
        @include swap_direction(margin, 60px 0 25px);
        @include swap_direction(padding, 0);
        border: none;
      }
      &__header {
        display: inline;
        font-family: $headline-font-family;
        font-size: 18px;
        margin-#{$rdirection}: 10px;
      }
      h1,
      h2 {
        display: inline;
        font-family: $tstar-font;
        font-size: 12px;
      }
      h1 {
        text-transform: uppercase;
      }
      h2 {
        text-transform: none;
      }
    }
  }
  .lbo-banner {
    border-top: 1px solid $white;
    @include swap_direction(padding, 10px 0);
    height: auto;
    &__img {
      position: absolute;
      max-height: 100%;
      max-width: none;
    }
    &__close {
      transform: none;
      .site-banner & {
        top: 25%;
        @include breakpoint($bp--largest-up) {
          top: 50%;
        }
      }
      i.icon--remove {
        .site-banner & {
          font-size: 26px;
        }
      }
    }
    &__container {
      .site-banner & {
        transform: translateY(0%);
      }
      &--content {
        padding-#{$ldirection}: 40px;
        padding-#{$rdirection}: 40px;
        width: 100%;
        &-link {
          display: inline-block;
          @include swap_direction(margin, 0 0 5px 0);
          text-transform: uppercase;
          text-decoration: underline;
          font-size: 12px;
          @include breakpoint($bp--largest-up) {
            border-bottom: 1px solid $white;
            text-decoration: none;
          }
          a {
            color: inherit;
            font-size: inherit;
            border-bottom: none;
          }
        }
        &-heading {
          font-family: $headline-font-family;
          font-size: 16px;
          text-transform: uppercase;
          @include breakpoint($bp--largest-up) {
            font-size: 18px;
          }
        }
        &-message {
          .lbo-msg {
            display: inline;
          }
          font-family: $tstar-font;
          font-style: normal;
          display: inline;
          font-size: 12px;
          @include breakpoint($bp--largest-up) {
            font-size: 14px;
          }
          h1,
          h2 {
            display: inline;
            text-transform: uppercase;
            font-family: $tstar-font;
            font-size: 12px;
            line-height: inherit;
            @include swap_direction(margin, 0);
            padding-#{$ldirection}: 0;
            @include breakpoint($bp--largest-up) {
              display: inline-block;
            }
          }
          h1 {
            @include breakpoint($bp--largest-up) {
              font-size: 12px;
              margin-#{$rdirection}: 5px;
              text-transform: none;
            }
          }
          h2 {
            text-transform: none;
            @include breakpoint($bp--largest-up) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
