/* ==========================================================================
   Global Extends - Flexbox
   ========================================================================== */

/* Mixin - Flex Column
   ========================================================================== */
/*
 * @params
 *  $height
 */

@mixin flex-column($height: 100%) {
  height: $height;
  @include flexbox(
    (
      display: flex,
      flex-direction: column,
      justify-content: space-between
    )
  );
}

/* Mixin - Flex Row
   ========================================================================== */
/*
 * @params
 *  $height
 *  $extend
 */

@mixin flex-row($height: 100%) {
  height: $height;
  @include flexbox(
    (
      display: flex,
      flex-direction: row,
      justify-content: space-between
    )
  );
}

/* Mixin - Flex Item
   ========================================================================== */
/*
 * @params
 *  $flex
 *  $extend
 */

@mixin flex-item($flex: auto, $extend: true) {
  @if $extend and $flex == auto {
    @include flex-auto;
  } @else if $extend and $flex == 1 {
    @include flex-1;
  }
  @include flexbox(
    (
      flex: $flex
    )
  );
}

/* Mixin - No Flexbox / Reset
   ========================================================================== */

@mixin no-flexbox-ir-reset() {
  .no-flexbox & {
    padding-top: 0;
    height: auto;
    > * {
      position: relative;
      width: auto !important;
      height: auto !important;
    }
  }
}
/* flex box compatibility for older browsers */
@mixin display_flex() {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

/* Mixin - Flex Column Auto
   ========================================================================== */

@mixin flex-column-auto {
  @include flex-column(auto);
}

/* Mixin - Flex Auto
   ========================================================================== */

@mixin flex-auto {
  @include flex-item(auto, false);
}

/* Mixin - Flex One
   ========================================================================== */

@mixin flex-1 {
  @include flex-item(1, false);
}

/* Mixin - Flex None
   ========================================================================== */

@mixin flex-none {
  @include flex-item(none, false);
}
