.search-results {
  &__title {
    @include h4();
    @include swap_direction(margin, $spacing 0); // $spacing = 35px
    @include swap_direction(padding, 0);
    text-align: center;
    line-height: 1;
  }
  &__loader {
    @include swap_direction(margin, 0 auto);
    background: url('/media/dev/global/icon_loading.gif') 50% no-repeat;
    width: 16px;
    height: 16px;
  }
  &__summary {
    font-family: $roboto-mono_regular-font;
    font-size: 10px;
  }
  &__filters {
    background: $color--gray--lightest;
    position: relative;
    width: 100%;
  }
  &__breadcrumbs {
    @include swap_direction(padding, $spacing 0 0 20px);
    @include breakpoint($bp--medium-up) {
      @include swap_direction(padding, $spacing 20px);
      float: #{$rdirection};
    }
    dd {
      margin-#{$ldirection}: 0;
    }
  }
  .pagination {
    @include swap_direction(padding, $spacing 0);
    clear: both;
    width: 100%;
    text-align: center;
  }

  // @TODO temporary hide of shade preview for launch
  // product will not re-render currently when we select a shade from results
  .product--teaser:hover .shade-picker__trigger {
    display: none;
  }
  .tout-block-landscape {
    img {
      @include breakpoint($bp--large-down) {
        width: 100%;
      }
    }
  }
}

.page-search__form--wrapper {
  border-bottom: $border;
}

.page-search__field {
  @include header-search-typography();
  @include swap_direction(padding, 0 $spacing);
  height: $site-header-height;
  border-width: 0;
  line-height: $site-header-height; //line-height: 1 not centering
  @at-root .touch & {
    @include swap_direction(padding, 15px $spacing);
    line-height: normal;
  }
  &:focus {
    border-width: 0;
  }
}

// filtering container triggers
.search-filter__trigger {
  @include h6();
  @include swap_direction(margin, 0);
  background-color: $color--gray--lightest;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: middle;
  @include breakpoint($bp--large-up) {
    width: 25%;
    position: absolute;
    top: 60px;
    #{$rdirection}: 0;
    padding-#{$ldirection}: 10px;
    text-align: #{$ldirection};
    z-index: $z-high;
    @if $gnav_update {
      top: $gnav-height + 10px;
    }
  }
}

.search-filter__trigger_icon--right {
  @include icon(arrow--right);
  bottom: 24px;
  #{$rdirection}: 10px;
  position: absolute;
  @include breakpoint($bp--xlarge-up) {
    &::before {
      content: map-get($icons, arrow--right-medium);
    }
  }
  .search-filter--open & {
    display: none;
  }
}

.search-filter__trigger-text {
  line-height: get-line-height(12px, 60px);
  .search-filter--open & {
    display: none;
  }
}

.search-filter__trigger_icon--left {
  @include icon(arrow--left);
  display: none;
  bottom: 24px;
  #{$rdirection}: 10px;
  position: absolute;
  @include breakpoint($bp--xlarge-up) {
    &::before {
      content: map-get($icons, arrow--left-medium);
    }
  }
  .search-filter--open & {
    display: inline;
  }
}

.search-filter__startover-text {
  display: none;
  line-height: get-line-height(12px, 60px);
  .search-filter--open & {
    display: inline;
  }
}

// no results
.search-results__no-results--header {
  @include swap_direction(padding, 2em 0);
  border-top: $border;
  border-bottom: $border;
  text-transform: uppercase;
}

.search-results__no-results--popular a {
  @include unset-underline-links($extend: true);
}

.search-results__results {
  &.grid--mpp {
    padding-top: 0;
    margin-top: 15px;
    .grid--mpp__item {
      outline: none;
      &.first {
        .product--teaser {
          @include breakpoint($bp--xsmall-down) {
            border-top: none;
          }
        }
      }
      @mixin child($n) {
        @for $i from 1 to $n {
          &:nth-child(#{$i}) {
            .product--teaser {
              border-top: none;
            }
          }
        }
      }
      @include breakpoint($bp--xlarge-up) {
        @include child(5);
      }
      @include breakpoint($bp--medium) {
        @include child(4);
      }
    }
    .product--teaser.prod_inv_status-3 {
      &.product--shaded {
        .product__footer .product__inventory-status {
          padding-top: 7px;
          li.coming-soon-text {
            display: block;
            position: relative;
          }
        }
      }
    }
  }
  .product--teaser {
    &__msg-preorder {
      p {
        @include breakpoint($bp--small-up) {
          margin-bottom: 0px;
        }
      }
    }
  }
}
