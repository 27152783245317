.account {
  .artist-picks-page {
    &__content {
      @include swap_direction(padding, 0 15px 0);
    }
    .item-headers {
      display: none;
      @include breakpoint($bp--medium-up) {
        width: 90%;
        display: block;
      }
      border-bottom: 1px solid gray;
      margin-#{$ldirection}: 50px;
      height: 25px;
      font-weight: bold;
      text-transform: uppercase;
      .product-label {
        float: #{$ldirection};
        margin-#{$ldirection}: 20px;
        width: 200px;
      }
      .price-label {
        float: #{$rdirection};
        width: 200px;
        margin-#{$rdirection}: 40px;
      }
    }
    .recommended-products__products {
      width: 100%;
      @include breakpoint($bp--medium-up) {
        margin-#{$ldirection}: 50px;
        margin-bottom: 75px;
        float: #{$ldirection};
      }
      .product {
        .product__images-inner {
          img {
            width: 108px;
            height: 124px;
          }
        }
      }
    }
    .recommended-products__links {
      clear: both;
      @include swap_direction(margin, 0px auto);
      width: 100%;
      @include breakpoint($bp--medium-up) {
        width: 200px;
      }
      text-align: center;
      background-color: $color--black;
      color: $color--white;
      .recommended-products__link {
        font-size: 1.5rem;
        @include breakpoint($bp--medium-up) {
          line-height: 48px;
          font-size: 15px;
        }
        height: auto;
      }
      a {
        border-bottom: 0px;
      }
    }
    .recommended-products__products {
      .product__detail {
        height: auto;
        @include breakpoint($bp--medium-up) {
          width: 75%;
        }
        .product__sku-details {
          height: auto;
          .product__rating {
            display: none;
          }
        }
        .product__price {
          top: 10px;
          position: absolute;
          #{$ldirection}: 110px;
          @include breakpoint($bp--medium-up) {
            #{$ldirection}: auto;
            #{$rdirection}: 200px;
            top: 25px;
          }
        }
        .product__add-to-bag {
          position: absolute;
          #{$rdirection}: 0;
          top: 75px;
          @include breakpoint($bp--medium-up) {
            top: 18px;
          }
        }
      }
      .product__images {
        width: 110px;
        height: 125px;
        position: relative;
        float: #{$ldirection};
        top: 0;
      }
    }
  }
  .artist-picks-page {
    .product--teaser {
      min-height: 150px;
      .product__name,
      .product__subline {
        padding-#{$rdirection}: 0px;
        @include breakpoint($bp--medium-up) {
          padding-#{$rdirection}: 95px;
        }
      }
      .product__description-short {
        @include swap_direction(margin, 6px 0 15px 0);
        @include breakpoint($bp--medium-up) {
          @include swap_direction(margin, 6px 80px 15px 0);
        }
      }
    }
    .artist-picks-page__header {
      font-size: 2em;
      text-align: center;
      @include swap_direction(padding, 20px 0);
      @include breakpoint($bp--medium-up) {
        @include swap_direction(padding, 20px);
        margin-#{$ldirection}: 50px;
        text-align: #{$ldirection};
      }
    }
    .artist-picks-page__subheader {
      font-size: 2em;
      text-align: center;
      @include swap_direction(padding, 20px 0);
      @include breakpoint($bp--medium-up) {
        @include swap_direction(padding, 0px);
        margin-bottom: 20px;
        text-align: #{$ldirection};
      }
    }
    .product--wrapper {
      float: #{$ldirection};
      width: 100%;
      height: 250px;
      margin-bottom: 15px;
      @include breakpoint($bp--medium-up) {
        width: 90%;
        height: 150px;
        float: none;
      }
    }
    .product--footer {
      position: relative;
      @include breakpoint($bp--medium-up) {
        position: initial;
      }
    }
    .product-info__button {
      text-decoration: none;
    }
    .product-info__add-to-bag {
      color: white;
      text-decoration: none;
    }
    .product-info__link {
      @include swap_direction(border, none);
    }
    .product-info__button,
    js-add-button {
      width: 200px;
      text-align: center;
      height: 40px;
      @include swap_direction(padding, 12px);
      background-color: black;
      color: white;
    }
    .product .product-info div {
      float: #{$ldirection};
    }
    .product-image {
      height: 110px;
      margin-#{$rdirection}: 15px;
      width: 80px;
    }
    .product-info .product-info__product {
      width: 500px;
    }
    .product-info__name {
      width: 65%;
    }
    .product-info__sizes,
    .sizes {
      display: none;
    }
    .product-info__price,
    .price {
      width: 150px;
    }
    span.product-info__re-order {
      display: none;
    }
  }
  .artist-picks-page {
    position: relative;
    .transcript-lines__header {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.24em;
      @include swap_direction(margin, 22px 0 10px);
      text-transform: uppercase;
    }
    .transcript__header {
      position: relative;
      .transcript__header--date {
        display: block;
        float: none;
        font-weight: normal;
      }
      .transcript__header--consultant {
        font-weight: normal;
        .consultant-thumb {
          width: 32px;
          height: 32px;
          display: none;
        }
      }
      .transcript__header--view-details {
        float: #{$rdirection};
        margin-top: -3px;
      }
    }
    .transcript__chat {
      margin-bottom: 35px;
      .transcript__chat--skus .recommended-skus {
        display: none;
      }
      .transcript-lines {
        .view-more {
          @include swap_direction(border, 0 none);
          display: block;
          @include swap_direction(margin, 0 auto);
          text-align: center;
          width: 100px;
        }
      }
    }
    .artist-picks-page__chats {
      width: 100%;
      @include breakpoint($bp--medium-up) {
        float: #{$ldirection};
        clear: both;
        width: 90%;
        @include swap_direction(margin, 25px auto 25px 50px);
      }
      .transcript {
        @include swap_direction(padding, 15px 0 5px 10px);
        @include breakpoint($bp--medium-up) {
          @include swap_direction(padding, 15px 0 5px 0);
        }
        @include swap_direction(margin, 0 auto);
        border-top: 1px solid gray;
        .recommended-skus__links-add-all a {
          cursor: pointer;
        }
      }
    }
  }
}
