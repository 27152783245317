///
/// Checkout pages styling
///

//colors
$checkout-border-header: $color--gray--light;

// Local variables
$checkout-spacing: 20px; // common shared margin/paddings
$cart-spacing: 10px;
$checkout-pushdown: 35px; // used to nudge content down from top
$viewcart-pushdown: 35px; // used to nudge content down from top
$cart-container-width: 1024px; // comps
$checkout-sidebar-width: percentage(356px / $cart-container-width);
$cart-sidebar-width: percentage(256px / $cart-container-width); // this will override
$cart-sidebar-width: $checkout-sidebar-width; //same as onepage
$cart-line: 1px solid $color--gray--light; // line used everywhere

/// Cart item values
// product
$product-width-mobile: 25%;
$product-width-desktop: 25%;
// description
$desc-width-mobile: 60%;
$desc-width-desktop: 35%;
// price
$price-width: 15%;
// replenishment
// $replenishment-width-desktop: 20%;
// qty
$qty-width: 15%;
// total
$total-width-desktop: 10%;

@mixin loyalty-clear {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin loyalty-panel {
  @include loyalty-clear;
  display: block;
  padding: 20px;
}

.loyalty__page__index-2col {
  border-bottom: $border;
  margin: 0 0 15px 0;
}
