// Customizable "defaulting" icon slide for favorite's list.
@mixin icon-overlay(
  $display: block,
  $width: 154px,
  $opacity: 0.75,
  $padding_top: 39px,
  $top: auto,
  $h4_visibility: hidden,
  $icon_url: 'perlgem/heart_cross.png'
) {
  background: $color--white;
  display: $display;
  @include opacity($opacity);
  @include swap_direction(padding, $padding_top 0 0 0);
  position: absolute;
  text-align: center;
  top: $top;
  width: 100%;
  i {
    // Dotted heart with a plus sign is the default.
    background: transparent image-url($icon_url) center center no-repeat;
    @include background-size(100%);
    display: inline-block;
    height: $width;
    vertical-align: top;
    width: $width;
  }
  h4 {
    // Header
    visibility: $h4_visibility;
  }
}

.favorites-landing-page {
  .account-nav {
    display: none;
    @include breakpoint($bp--largest-up) {
      display: block;
    }
  }
}

.favorites-page {
  @include swap_direction(padding, 0 10px);
  &__content {
    // Logo for print.
    .print-logo {
      .print {
        @media screen {
          display: none;
        }
      }
    }
    // header
    .favorites-page__header {
      @include clearfix();
      border-bottom: 1px solid $color--gray--lighter;
      @include swap_direction(margin, 0 auto);
      @include swap_direction(padding, 50px 0 0 0);
      &-title {
        @include swap_direction(margin, 0 0 40px 0);
        @include swap_direction(padding, 0);
        text-align: center;
        @include breakpoint($bp--large-up) {
          float: #{$ldirection};
          @include swap_direction(padding, 0 2.5% 0 0);
          text-align: #{$rdirection};
          width: 26.5%;
        }
      }
      &-description {
        @include swap_direction(margin, 0 0 40px 0);
        @include swap_direction(padding, 0);
        @include breakpoint($bp--large-up) {
          float: #{$ldirection};
          @include swap_direction(padding, 0 5.25% 0 1.25%);
          width: 73%;
        }
        @include breakpoint($bp--xlarge-up) {
          width: 58%;
        }
      }
    }

    // favorites carousel
    .favorites {
      &.carousel {
        display: none;
        @include breakpoint($bp--largest-up) {
          display: block;
        }
      }
      &.favorites-landing-board {
        display: block;
        @include breakpoint($bp--largest-up) {
          display: none;
        }
      }
      &-back {
        float: #{$ldirection};
        position: relative;
        @include breakpoint($bp--large-up) {
          top: 15px;
        }
      }
      &__header {
        @include swap_direction(padding, 20px);
        .favorites-back {
          float: none;
          @include breakpoint($bp--large-up) {
            float: #{$ldirection};
          }
        }
      }
      &__title {
        clear: both;
        line-height: 1;
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 15px 0);
        text-align: center;
        @include breakpoint($bp--large-up) {
          clear: none;
        }
        span {
          color: $color--gray--light;
        }
      }
      &__list {
        border-#{$ldirection}: 1px solid $color--gray--lighter;
        border-top: 1px solid $color--gray--lighter;
        @include clearfix();
        .favorites__item {
          border-#{$rdirection}: 1px solid $color--gray--lighter;
          border-bottom: 1px solid $color--gray--lighter;
          position: relative;
          .product-info {
            text-align: center;
          }
          &:hover {
            .js-add-to-list-overlay {
              display: none;
            }
            .icon-overlay {
              display: none;
            }
          }
          &.ui-draggable-blur {
            @include opacity(0.5);
          }
          img {
            width: 124px;
          }
        }
        .js-add-to-list-overlay {
          background: $color--white;
          display: none;
          position: absolute;
          top: 0;
          .add-to-list__header {
            @include reverse;
          }
          .add-to-list__lists {
            height: 100px;
            overflow-y: scroll;
          }
        }
        .add-to-list__new {
          .create-wishlist__header {
            .label {
              display: none;
            }
          }
          .create-wishlist__link {
            display: none;
          }
          .icon-overlay {
            display: none;
          }
        }
        .icon-overlay {
          @include icon-overlay(
            $width: 100px,
            $display: none,
            $padding_top: 50px,
            $top: 0,
            $h4_visibility: visible
          );
        }
        .carousel-slide {
          @include swap_direction(margin, 0);
          a {
            @include swap_direction(border, none);
            display: block;
            text-decoration: none;
          }
          img {
            display: inline;
          }
          @include breakpoint($bp--medium-up) {
            float: #{$ldirection};
            height: 100%;
            @include swap_direction(margin, 0);
            min-height: 1px;
            width: 12.5%;
          }
        }
        &.slick-initialized {
          border-#{$ldirection}: none;
          .favorites__item {
            border-#{$ldirection}: 1px solid $color--gray--lighter;
            border-#{$rdirection}: none;
          }
        }
      }
    }
    .favorites-none {
      @include swap_direction(padding, 0 0 35px 0);
      text-align: center;
      &__text {
        @include swap_direction(margin, 0 0 35px 0);
      }
      &__link {
      }
    }

    // lists
    .favorites-mylists {
      display: none;
      @include breakpoint($bp--largest-up) {
        display: block;
      }
      &__lists {
        @include clearfix();
        .carousel-slide {
          @include swap_direction(margin, 0 0 20px 0);
          @include breakpoint($bp--medium-up) {
            float: #{$ldirection};
            height: 100%;
            @include swap_direction(margin, 0 1.5% 0 0);
            min-height: 1px;
            width: 23.75%;
          }
        }
        .lists {
          @include swap_direction(border, 1px solid $color--gray--lighter);
          &__header {
            border-bottom: 1px solid $color--gray--lighter;
            @include swap_direction(margin, 0);
            @include swap_direction(padding, 20px 10px);
            text-align: center;
            min-height: 101px;
            .lists__link {
              @include unset-underline-links($extend: false);
              span {
                color: $color--gray--light;
              }
            }
          }
          &:hover {
            .lists__header {
              background: $color--black;
              color: $color--white;
              .lists__link {
                color: $color--white;
              }
            }
          }
          .js-add-to-list-overlay {
            display: none;
          }
          .favorite-actions {
            display: none;
          }
        }
        .carousel-noslide {
          @include swap_direction(margin, 0 0 20px 0);
          @include breakpoint($bp--medium-up) {
            float: #{$ldirection};
            @include swap_direction(margin, 0);
            width: 23.75%;
          }
        }
        &.slides-0 {
          .carousel-noslide {
            @include breakpoint($bp--medium-up) {
              float: none;
              @include swap_direction(margin, 0);
              width: 100%;
            }
          }
        }
        &.slick-initialized {
          .slick-list {
            @include breakpoint($bp--medium-up) {
              float: #{$ldirection};
              width: 76%;
              z-index: 10;
            }
          }
          .carousel-slide {
            img {
              display: inline;
            }
            @include breakpoint($bp--medium-up) {
              @include swap_direction(margin, 0 0.75% 0 0);
            }
          }
          .carousel-noslide {
            @include breakpoint($bp--medium-up) {
              float: #{$rdirection};
              z-index: 100;
            }
          }
        }
      }
      .list__item {
        @include swap_direction(border, 1px solid $color--gray--lighter);
        min-height: 401px;
        .lists__items {
          height: 328px;
          overflow: hidden;
          position: relative;
          width: 100%;
          .icon-overlay {
            @include icon-overlay(
              $display: none,
              $padding_top: 50px,
              $top: 0,
              $h4_visibility: visible
            );
            &.empty-list {
              @include icon-overlay(
                $display: block,
                $padding_top: 50px,
                $top: 0,
                $h4_visibility: visible,
                $icon_url: 'perlgem/heart_no_cross.png'
              );
            }
          }
          .favorites__item {
            border-bottom: 1px solid $color--gray--lighter;
            border-#{$rdirection}: 1px solid $color--gray--lighter;
            clear: none;
            float: #{$ldirection};
            height: 164px;
            overflow: hidden;
            text-align: center;
            width: 50%;
            &:nth-child(even) {
              border-#{$rdirection}: none;
            }
            &:nth-child(3n),
            &:nth-child(4n) {
              border-bottom: none;
            }
          }
        }
        &.ui-droppable-hover {
          .lists__items {
            .icon-overlay {
              @include icon-overlay(
                $display: block,
                $padding_top: 50px,
                $top: 0,
                $h4_visibility: visible,
                $icon_url: 'perlgem/heart_no_cross.png'
              );
            }
          }
        }
        &.ui-droppable-active {
          .lists__items {
            .icon-overlay {
              display: block;
            }
          }
        }
      }
      .create-wishlist {
        text-align: center;
        &__header {
          border-bottom: 1px solid $color--gray--lighter;
          @include swap_direction(margin, 0 0 10px 0);
          .label {
            min-height: 101px;
          }
          .label,
          input[type='text'] {
            @include swap_direction(border, none);
            @include h4();
            @include swap_direction(margin, 0);
            @include swap_direction(padding, 20px 10px);
            text-align: center;
          }
          input[type='text'] {
            display: none;
          }
        }
        &__link {
          display: none;
        }
      }
      &__create-list {
        position: relative;
        .icon-overlay {
          @include icon-overlay;
        }
      }
      .js-confirm-box,
      .js-add-to-list-overlay {
        display: none;
      }
    }

    // board
    .favorites-board {
      @include swap_direction(padding, 20px);
      @include breakpoint($bp--xlarge-down) {
        @include swap_direction(padding, 10px);
      }
      &__list {
        position: relative;
        .pyramid-grid {
          @include pyramid-grid(2em);
        }
        .icon-overlay {
          @include icon-overlay(
            $display: none,
            $padding_top: 180px,
            $top: 0,
            $h4_visibility: visible
          );
          height: 100%;
          width: 100%;
        }
        &.ui-droppable-active {
          .icon-overlay {
            display: block;
          }
        }
        &-edit {
          float: #{$ldirection};
          position: relative;
          @include breakpoint($bp--large-up) {
            top: 15px;
          }
          .sep,
          .remove-wishlist {
            display: inline;
          }
        }
        &-tools {
          float: #{$rdirection};
          position: relative;
          @include breakpoint($bp--large-up) {
            top: 15px;
          }
          .edit-wishlist {
            .edit {
              display: inline;
            }
            .save {
              display: none;
            }
          }
        }
        .favorites__title {
          clear: both;
          @include swap_direction(margin, 0 auto);
          max-width: 640px;
          @include breakpoint($bp--large-up) {
            clear: none;
          }
        }
        #alter_list {
          display: none;
          @include swap_direction(margin, 0 auto);
          max-width: 640px;
          @include swap_direction(padding, 35px 0);
          text-align: center;
          input[type='text'] {
            @include swap_direction(border, none);
            @include h4();
            @include swap_direction(margin, 0);
            max-width: 500px;
            @include swap_direction(padding, 0 10px);
            text-align: center;
          }
        }
      }
      &__item {
        .product-info__link {
          @include unset-underline-links($extend: false);
        }
        .favorite-actions {
          position: relative;
          #{$rdirection}: auto;
          text-align: #{$rdirection};
        }
        .product__images {
          @include breakpoint($bp--small-up) {
            bottom: 67px;
          }
          @include breakpoint($bp--xlarge-up) {
            bottom: 58px;
          }
        }
        .product {
          &__header {
            padding: 0;
          }
          &__footer,
          &__images {
            position: unset;
          }
        }
      }
      &__footer {
        margin-bottom: 70px;
        text-align: center;
      }
      &__list.edit {
        .favorites__title {
          display: none;
        }
        #alter_list {
          display: block;
        }
        .favorites-board__list-edit {
          .sep,
          .remove-wishlist {
            display: inline;
          }
        }
        .favorites-board__list-tools {
          .edit-wishlist {
            .edit {
              display: none;
            }
            .save {
              display: inline;
            }
          }
        }
        .favorite-actions__link {
          visibility: visible;
        }
      }
    }
  }
  .ui-draggable-active {
    background: $color--white;
    @include swap_direction(border, 1px solid $color--gray--lighter);
    list-style: none;
    text-align: center;
    .js-add-to-list-overlay {
      display: none;
    }
  }
  .confirm-delete {
    display: none;
  }
  .favorites__share:hover .favorites__social-links {
    display: inline-block;
  }
  .favorites__share-wrapper--pc {
    display: inline-block;
    .favorites__share {
      &:hover .favorites__social-links {
        display: inline-block;
      }
    }
  }
  .favorites__share-wrapper--mobile {
    text-align: center;
    @include swap_direction(padding, $spacing 0);
    .favorites__social-links {
      @include swap_direction(margin, 0 auto);
    }
  }
  .favorites__social-links {
    @include breakpoint($bp--medium-up) {
      position: absolute;
      top: 0;
    }
    display: none;
    background: white;
    width: 5.5em;
    li {
      float: #{$ldirection};
      clear: none;
      margin-#{$rdirection}: 5px;
      cursor: pointer;
    }
  }
}

.favorites--zoom {
  position: relative;
  .favorites--zoom--initial {
    @include transition-duration(600ms);
    @include transition-timing-function(ease-in-out);
    position: absolute;
    opacity: 1;
  }
  .favorites--zoom--secondary {
    @include perspective(200px);
    position: absolute !important;
    top: 0;
    #{$ldirection}: 0;
    .favorites__item {
      @include transition-duration(600ms);
      @include transition-timing-function(ease-in-out);
      @include transform-style(preserve-3d);
      @include transform(scaleX(1.5) scaleY(1.5) translate3d(300px, 300px, 0px));
      position: absolute;
      // "!important" is applied to width and height here to avoid refactoring overly deeply nested selectors elsewhere.
      width: 32.9% !important;
      height: 33.33% !important;
      opacity: 0;
      // "!important" is applied to borders in this case so we can keep the other aspects of this class general
      @include swap_direction(border, 1px solid $color--white !important);
      border-bottom: 1px solid $color--gray--lighter !important;
      border-#{$rdirection}: 1px solid $color--gray--lighter !important;
      overflow: hidden;
      background-color: white;
      display: none;
      a {
        display: block;
      }
      img {
        margin-top: -1rem;
      }
      &:nth-child(n + 1):nth-child(-n + 9) {
        display: block;
      }
      &:nth-child(n + 1):nth-child(-n + 3) {
        top: -66.66%;
      }
      &:nth-child(n + 4):nth-child(-n + 6) {
        top: 33.33%;
      }
      &:nth-child(n + 7):nth-child(-n + 9) {
        // "!important" is applied here to over-write other earlier nth-child selectors
        border-bottom: none !important;
        top: 133.33%;
      }
      &:nth-child(1),
      &:nth-child(4),
      &:nth-child(7) {
        #{$ldirection}: -66.66%;
      }
      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(8) {
        #{$ldirection}: 33.33%;
      }
      &:nth-child(3n) {
        // "!important" is applied here to over-write other earlier nth-child selectors
        border-#{$rdirection}: none !important;
        #{$ldirection}: 133.33%;
      }
    }
  }
  &:hover {
    @include transition-duration(200ms);
    .favorites--zoom--initial {
      @include transform(scaleX(0) scaleY(0) translate3d(0, 0, 200px));
      opacity: 0;
    }
    .favorites--zoom--secondary {
      &.no--animation {
        display: none;
      }
      .favorites__item {
        @include transform(scaleX(1) scaleY(1) translate3d(0, 0, 0));
        opacity: 1;
        &:nth-child(n + 1):nth-child(-n + 3) {
          top: 0;
        }
        &:nth-child(n + 4):nth-child(-n + 6) {
          top: 33.33%;
        }
        &:nth-child(n + 7):nth-child(-n + 9) {
          top: 66.66%;
        }
        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(7) {
          #{$ldirection}: 0;
        }
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(8) {
          #{$ldirection}: 33%;
        }
        &:nth-child(3n) {
          #{$ldirection}: 66%;
        }
      }
    }
  }
}
