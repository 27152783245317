body {
  overflow: hidden;
}

.site-header {
  & &__tools {
    .site-bag {
      &__contents {
        &-title {
          text-align: #{$ldirection};
        }
        .cart-product-column {
          float: none;
          width: auto;
        }
      }
    }
  }
}

//hide EDD for EMEA sites
.product__edd,
.js-product__edd--pc-trigger,
.js-product__edd--mobile-trigger {
  display: none;
  visibility: hidden;
}

// for some reason mac_base is hiding labels indiscriminately!
label.no-hide {
  display: block;
}

.form-label-email-promotions {
  padding-left: 13px;
}

#colorbox {
  #cboxWrapper {
    #cboxContent {
      #cboxLoadedContent {
        .waitlist-iframe-wrapper {
          width: 100%;
          height: 100%;
          margin: 0px;
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  h2.waitlist_header {
    font-size: 20px;
    margin: 5px 0px !important;
  }
  p.waitlist_message {
    margin-bottom: 0.5em;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
    margin: 80px 0 0px 0;
    text-align: center;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
    text-align: center;
  }
  .field {
    width: 100% !important;
    float: #{$ldirection};
    position: relative;
    .email_input {
      float: #{$ldirection};
      width: 100%;
      input {
        width: 79%;
        padding: 12px;
      }
    }
    .email_promo {
      float: #{$ldirection};
      width: 100%;
      padding-top: 10px;
      input[type='checkbox'] {
        -webkit-appearance: checkbox;
      }
      label {
        display: inline !important;
      }
    }
    .align-r {
      float: left;
      margin-left: 10px;
      position: absolute;
      top: 0px;
      #{$rdirection}: 10px;
      .input-btn {
        padding: 10px;
        height: auto;
        line-height: normal;
        width: 70px;
      }
    }
  }
}

.site-container {
  .order-details-page {
    .order-details-page__content {
      padding: 0 15px 0 15px;
    }
  }
  .store-locator {
    .form--search--advanced .options {
      .submit_container {
        // MAC global set the size of the selects on largest-up for pc, but the button is uncoditionally small.
        // mac_base/scss/40-components/_store-locator-landing.scss:280
        // To counter this, use the a custom "larger down" breakpoint
        @include breakpoint(max-width $width-largest - 1) {
          width: 100%;
          .btn {
            // This is only set to 100% in the mobile CSS so duplicate here to by fully responsive on PC
            width: 100%;
          }
        }
      }
    }
  }
}

[dir='rtl'] .field-container--grid .form-item:nth-of-type(2n + 1) {
  margin-left: auto;
  margin-right: 0;
  padding-right: 0;
}

.ie {
  .checkout.viewcart.panel {
    margin: 10px 15px;
  }
}

.homepage-popup {
  #cboxContent {
    #cboxLoadedContent {
      background-color: black;
      overflow: hidden;
    }
    #cboxClose {
      color: white;
    }
  }
}

#popover_container {
  .email-sms-signup-popup-v1 {
    background-color: black;
    .email_sms_signup_popup {
      h3 {
        text-align: center;
        color: white;
      }
      .subheading h4 {
        line-height: 1.5em;
      }
    }
    .form--newsletter__subtitle {
      color: white;
      text-align: center;
    }
    .subheading {
      color: white;
      text-align: center;
    }
    .form--newsletter__form-items {
      padding: 0 20px;
      .form-type-textfield {
        width: 70%;
        display: inline-block;
      }
      .form-text {
        line-height: 35px;
        padding: 0px 13px;
      }
      .form-actions {
        width: 30%;
        float: right;
        padding: 3px 0;
        border: 1px solid white;
      }
      .popup-offer-footer-msg {
        color: white;
        text-align: center;
        margin-top: 15px;
      }
      #form--email_sms_signup--field--PC_EMAIL_ADDRESS {
        height: 35px;
      }
    }
  }
  .site-email-sms-signup__thank-you {
    padding-top: 80%;
    background-color: black;
  }
}

.appt-book {
  .appt-book-location-selector {
    .form-container {
      .ui-select {
        padding-bottom: 20px;
      }
    }
  }
}
/* Info popup styles for privacy policy */
.info-link {
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
  float: left;
  clear: left;
  margin-right: 20px;
}

.field-container {
  .info-message-txt {
    display: none;
  }
}

.font-clr {
  color: $color--white;
}

.info-icon {
  cursor: pointer;
  background: $color--black;
  color: $color--white;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: block;
  text-transform: lowercase;
  padding: 1px 0px 0px 6px;
}

.email-signup-new-user {
  .my-store {
    margin-top: 20px;
  }
}

.info-message-txt {
  display: none;
}

#info-message-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  background-color: $color--white;
  padding: 20px;
  width: 90%;
  border: 1px solid $color--black;
  max-width: 500px;
  &:before {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: 0px;
    border: 10px solid transparent;
    border-bottom-color: $color--black;
    top: -10px;
    margin-top: -10px;
  }
  &.top-right-arrow {
    &:before {
      left: 478px; /* 500px minus Border 2px and Triangle 20px */
    }
  }
  #info-text {
    position: relative;
    span {
      cursor: pointer;
      border-bottom: 1px solid $color--gray--lightest;
      text-transform: uppercase;
    }
  }
  #info-close {
    position: absolute;
    top: -20px;
    right: -20px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

#registration_short {
  .form-item.new-account__item.checkbox.password {
    .picker.picker-checkbox,
    .password-notice {
      float: left;
    }
    .password-notice {
      margin: 10px 0px 0px 10px;
    }
  }
}

.info-for-left,
.info-icon-right {
  float: left;
}

.info-icon-right {
  margin-left: 10px;
}

.appt-book {
  .confirm {
    .confirm-container {
      .book-appt-container {
        a {
          &.info-icon {
            color: $color--white;
          }
        }
        .password-notice {
          float: right;
        }
        p {
          &.appt-book-register-password-text {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.checkout__content {
  #review-panel {
    .review-legal {
      padding-bottom: 30px;
    }
  }
  #sign-in-panel {
    .review-legal {
      padding-bottom: 30px;
    }
  }
}
/* Account signin page styling */
.sign-in-page {
  .sign-in-container {
    width: 100%;
  }
}

.product--shaded--single {
  .product__product-details-shade--single {
    display: none;
  }
}
/* Style For Hiding Offer Product's Shade Price */
.offerspick {
  &__offers {
    .offerspick {
      &__shade {
        &__price {
          display: none;
        }
      }
    }
  }
}
/* GDPR Live Chat Style */
.section-chatprivacypolicy {
  .site-header,
  .site-footer {
    display: none;
  }
  // !important was used because the live chat styles are controlled by LivePerson as inline.
  .LPMcontainer {
    display: none !important;
  }
}
.kit-container {
  @include swap_direction(padding, 90px 0 0 0);
  @include breakpoint($bp--medium-down) {
    @include swap_direction(margin, 0 0 0 17%);
    @include swap_direction(padding, 10px 0 0 0);
    clear: both;
  }
  .checkout__sidebar & {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    clear: both;
  }
  .cart-item {
    &__desc-info {
      @include swap_direction(margin, 0 0 20px 0);
      @include swap_direction(padding, 0 0 20px 0);
      border-bottom: 1px solid #{$color--black};
      &:last-child {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
        border-bottom: none;
      }
    }
    .swatch {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      display: inline-block;
      position: relative;
      top: 6px;
    }
    .shade {
      @include swap_direction(padding, 0 0 0 5px);
      display: inline-block;
      &-label {
        display: none;
      }
    }
    .checkout__sidebar & {
      @include swap_direction(margin, 0);
    }
  }
}

.order-details-page {
  .virtual-bundling {
    .kit-container {
      @include swap_direction(margin, 0 0 0 17%);
      @include breakpoint($bp--medium-down) {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 15px 0 0 0);
      }
    }
    .kit-item {
      border-bottom: none;
      .cart-item {
        &__total {
          display: none;
        }
      }
    }
    .cart-item {
      &__total {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0 4.8% 0 0);
        @include breakpoint($bp--medium-down) {
          float: #{$ldirection};
        }
        float: #{$rdirection};
        width: auto;
      }
    }
  }
}
.custom-kit-formatter {
  &__overlay {
    .custom-kit-formatter {
      &__title {
        letter-spacing: 0;
      }
    }
  }
}

.email-signup-new-user {
  .mobile-signup {
    input[type='text'] {
      width: 100%;
    }
  }
  .submit-section {
    .field-container {
      margin: 0 auto;
    }
  }
}

.trending-looks-block-item-overlay-stage {
  & &__close {
    position: absolute;
    #{$rdirection}: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    @include breakpoint ($bp--medium-down) {
      #{$rdirection}: 10px;
      top: 15px;
    }
    &::before,
    &::after {
      position: absolute;
      #{$ldirection}: 15px;
      top: 3px;
      content: ' ';
      height: 27px;
      width: 2px;
      background-color: $color--black;
      transform: rotate(45deg);
      @include breakpoint ($bp--medium-down) {
        background-color: $color--white;
      }
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}

.trending-looks-block-item-step {
  &__product {
    .product-brief-v2 {
      .product-brief {
        &__cta {
          .product-add-to-bag {
            max-width: unset;
          }
        }
      }
    }
  }
}

.lc_service_drawer-v1 {
  .lc_service_drawer {
    &__body_container {
      .lc_service_drawer {
        &__button {
          color: $color--white;
        }
      }
    }
  }
}

.section-track {
  .elc-order-tracking {
    .elc-order-tracking__content {
      .elc-order-tracking-progress-bar {
        margin-bottom: 3.25em;
      }
    }
  }
}

.payments-icon {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px;
  .visa-card,
  .master-card,
  .amex,
  .paypal {
    @include swap_direction(margin, 5px 16px 0 0);
    background-position: center;
    background-size: 100%;
    height: 16.5px;
  }
}

#bottom-viewcart-buttons {
  .payments-icon {
    display: none;
  }
}
