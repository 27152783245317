@charset "UTF-8";

/**
 * MAC Base
 * Here is where the magic happens.
 *
 */

@import 'theme-bootstrap';
@import 'core/styles';
@import 'base/styles';
@import '20-global/global';
@import '30-vendor/loyalty/loyalty';
@import '30-vendor/loyalty/loyalty-benefits';
@import '30-vendor/loyalty/loyalty-how-to-earn';
@import '30-vendor/loyalty/loyalty-join';
@import '30-vendor/loyalty/loyalty-leave';
@import '30-vendor/loyalty/loyalty-next-tier';
@import '30-vendor/loyalty/loyalty-notification';
@import '30-vendor/loyalty/loyalty-offers';
@import '30-vendor/loyalty/loyalty-points';
@import '30-vendor/loyalty/loyalty-shared';
@import '30-vendor/loyalty/loyalty-transactions';
@import '30-vendor/elc_video_player';
@import '30-vendor/forms__selects--select2-base';
@import '30-vendor/loyalty_v2/loyalty';
@import '30-vendor/loyalty_v2/loyalty-checkout-birthday';
@import '30-vendor/loyalty_v2/loyalty-checkout-offers';
@import '30-vendor/loyalty_v2/loyalty-offers-sku';
@import '30-vendor/loyalty_v2/loyalty-offers';
@import '30-vendor/loyalty_v2/loyalty-points';
@import '30-vendor/loyalty_v2/loyalty-benefits-table';
@import '30-vendor/loyalty_v2/loyalty-events_and_promos';
@import '30-vendor/loyalty_v2/loyalty-marketing-page';
@import '30-vendor/loyalty_v2/loyalty-transaction-table';
@import '30-vendor/loyalty_v2/loyalty-ageconsent';
@import '40-components/adaptive-placeholders';
@import '40-components/address-book';
@import '40-components/add-to-bag-bar';
@import '40-components/appointment-booking';
@import '40-components/artist-picks';
@import '40-components/block-image-hover';
@import '40-components/block-image-overlay';
@import '40-components/card-mask';
@import '40-components/carousels';
// are this still used ?
@import '40-components/chat__livechat';
@import '40-components/chat__prechat';
@import '40-components/chat__vba';
// CR21 redesign chat
@import '40-components/proactive-chat';

@import '40-components/colorbox';
@import '40-components/contact-us';
@import '40-components/customer-service-menu';
@import '40-components/delivery-date';
@import '40-components/events-near-you';
@import '40-components/expando-block';
@import '40-components/faq';
@import '40-components/favorites';
@import '40-components/form--inline';
@import '40-components/forms__full-forms';
@import '40-components/forms__generic';
@import '40-components/forms__radios-checkboxes';
@import '40-components/forms__selects';
@import '40-components/forms__text-fields';
@import '40-components/grid-filter';
@import '40-components/grid--mpp';
@import '40-components/icons--flag-base';
@import '40-components/icons--flag-list';
@import '40-components/link-based-offer';
@import '40-components/loyalty';
@import '40-components/mac-tooltip';
@import '40-components/multi-use-tout';
@import '40-components/nav-load-more';
@import '40-components/overlay-hover';
@import '40-components/palette__artist';
@import '40-components/palette__palettedesigner';
@import '40-components/palette__paletteintro';
@import '40-components/past-purchases';
@import '40-components/platform-toolbar';
@import '40-components/product';
@import '40-components/products-shadegrid';
@import '40-components/replenishment';
@import '40-components/search-dimension';
@import '40-components/search-results';
@import '40-components/sec-nav';
@import '40-components/shade-filter';
@import '40-components/shade-picker';
@import '40-components/shop-the-collection';
@import '40-components/slidein-clickaway';
@import '40-components/social-links';
@import '40-components/sticky-cards';
@import '40-components/store-locator-landing';
@import '40-components/submenu';
@import '40-components/text-block';
@import '40-components/text_brick';
@import '40-components/tout-landscape-block';
@import '40-components/view-all-shades';
@import '40-components/product_store_check';
@import '40-components/wp-samples';
@import '40-components/overlay-block';
@import '40-components/bopis';
@import '40-components/bopis-update';
@import '40-components/loyalty-redesign/loyalty-marketing';
@import '40-components/loyalty-redesign/loyalty-marketing-benefits';
@import '40-components/loyalty-redesign/loyalty-navigation';
@import '40-components/loyalty-redesign/loyalty-transaction';
@import '40-components/loyalty-redesign/loyalty-points';
@import '40-components/loyalty-redesign/loyalty-offers-sku';
@import '40-components/loyalty-redesign/loyalty-level-up-marketing';
@import '40-components/loyalty-redesign/loyalty-benefits-faq';
@import '40-components/loyalty-redesign/loyalty-menu-list';
@import '40-components/loyalty-redesign/loyalty-checkout-cart';
@import '40-components/bopis_updates/_bopis_updates_checkout_shared';
@import '40-components/bopis_updates/_bopis_updates_checkout';

// SMACSS/40-components/Appointment Booking
$appt_booking: false !default;

@if $appt_booking == true {
  @include appt_booking;
}

// Social Login
@import '40-components/social_login';

// UPS Validation Overlay
@import '40-components/ups_validation_overlay';
@import '50-templates/custom-grid';
@import '50-templates/grid-classes';
@import '50-templates/single-row-grid-formatter';
@import '60-pages/back-to-mac';
@import '60-pages/country-chooser';
@import '60-pages/egift-card';
@import '60-pages/email-signup';
@import '60-pages/gift-card';
@import '60-pages/makeup-services';
@import '60-pages/order-details-page';
@import '60-pages/page--spp';
@import '60-pages/password_request';
@import '60-pages/spp-custom_palette';
@import '70-print/print';

// bopis__updates
$bopis_update: false !default;

@if $bopis_update == true {
  @include bopis_updates;
}

// Power reviews
@import 'power_review/_power-review-base';

// NEW directories for template_api specific scss.
// Set by global templates team for 2018 Creative Refresh.
// Please do not add new files here without checking first.

// Products - 2018 Creative Refresh
@import 'template_api/products/product_common_classes';
@import 'template_api/products/product_brief';
@import 'template_api/products/product_full';
@import 'template_api/products/product_full_shades';

//Includes Welcome 15 offer
$welcome15_offer: false !default;

@if $welcome15_offer == true {
  @include welcome15__offer;
}

//Persistent Sticky Checkout - Mobile
@import '40-components/_sticky_checkout_mobile';

//Includes Persistent Sticky Checkout Mobile
$sticky_checkout: false !default;

@if $sticky_checkout == true {
  @include sticky_checkout_styles;
}

//Loyalty Redesign V2
$loyalty_v2: false !default;

@if $loyalty_v2 == true {
  @include loyalty_v2;
}

//Hide Cart, Order Summary, Shipping & Payment Panel
$checkout_index_hide_section: false !default;

@if $checkout_index_hide_section == true {
  @include checkout_index_hide_section;
}

html,
body {
  font-family: $ano_regular-font;
}
