/* ==========================================================================
   Base - Visibility
   ========================================================================== */

.mobile_hidden {
  @include breakpoint($bp--medium-down) {
    display: none !important;
  }
}

.pc_hidden {
  @include breakpoint($bp--medium-up) {
    display: none !important;
  }
}

.mobile_visibility {
  display: none;
  @include breakpoint($bp--medium-up) {
    display: block;
  }
}

html.js .js_hidden {
  display: none;
}

.show-medium-up--block {
  @include show-medium-up--block();
}

.show-xlarge-up--block {
  @include show-xlarge-up--block();
}

.show-medium-up--inline {
  @include show-medium-up--inline();
}

.show-xlarge-up--inline {
  @include show-xlarge-up--inline();
}

.hide-medium-up {
  @include hide-medium-up();
}

.hide-xlarge-up {
  @include hide-xlarge-up();
}
