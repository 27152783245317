/* ==========================================================================
   Components Molecules - Shade Picker
   ========================================================================== */

/*
 * The full shade picker used in:
 *
 * A) SPP sidebar shade picker: ?p=pages-spp
 * B) SPP shade picker dropdown: ?p=pages-spp
 * C) SPP buy now sticky bar: ?p=pages-spp
 *
 * Contexts that affect the shadepicker behavior:
 *
 * 1) MPP: .product--teaser
 * 2) SPP: .product--full
 * 3) Collections quickshop: .collection-quickshop
 *
 */

/* Extendable - Shade Full
   ========================================================================== */

@mixin shade-full() {
  position: absolute;
  height: 100%;
  width: 100%;
}

// Overall shade picker element
.shade-picker {
  position: absolute;
  #{$ldirection}: 0;
  bottom: 0;
  z-index: $z-lowest;
  width: 100%;
  overflow: hidden;
  .product--teaser & {
    height: 0; // start collapsed because mpp sp expands up
    bottom: 59px;
    background: $color--black;
  }
  .product--full & {
    position: static;
    // position to the right on product--full
    @include breakpoint($bp--medium-up) {
      position: absolute;
      height: 100%;
    }
  }
  .collection-quickshop & {
    position: static; // override default shadepicker abs pos
  }
}
// Scrollable piece within .shade-picker that "masks" the really long color list
.shade-picker__colors-mask {
  #{$rdirection}: -15px; // THIS HIDES THE SCROLLBAR!
  overflow: auto;
  position: absolute;
  top: 55px; // prev button + close button
  bottom: 30px;
  #{$ldirection}: 0;
  z-index: 5;
  .os--Windows & {
    #{$rdirection}: -17px;
  }
  .shade-picker--plain & {
    top: 25px;
  }
  .collection-quickshop & {
    position: static; // temp will be abs soon
    overflow: auto;
  }
  .product--full & {
    top: 30px;
    &.js-colors-mask {
      top: 0;
    }
  }
}

.product--full {
  .close_icon_bg {
    .shade-picker {
      &__colors-mask {
        top: 0px;
        @include breakpoint($bp--medium-down) {
          top: 30px;
        }
      }
      &__close {
        @include breakpoint($bp--medium-down) {
          width: 100%;
          @include swap_direction(padding, 5px 5px 0 0);
        }
      }
    }
  }
}

.shade-picker__colors {
  // MAC-996 Apply the Window fix styling by default now.
  margin-#{$rdirection}: 0px; // This makes up for our hidden scrollbar
  // But Windows hates it
  //.os--Windows & {
  //  margin-right: 0;
  //}
}

.shade-picker__color {
  position: relative;
  cursor: pointer;
}

// Up/down + close controls
.shade-picker__controls-wrapper {
  display: none;
  .product--teaser &,
  .product--full & {
    display: block;
  }
}

.shade-picker__controls {
  height: 30px;
  line-height: 26px;
  background-color: $color--gray--black;
  position: absolute;
  width: 100%;
  color: $color--white;
  z-index: 10;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.shade-picker__close {
  background-color: $color--black;
  cursor: pointer;
  height: 25px;
  @include swap_direction(padding, 0 6px 0 0);
  text-align: #{$rdirection};
  top: 0;
  .icon--remove {
    @include breakpoint($bp--medium-down) {
      display: block;
    }
    font-size: 9px;
    font-weight: bold;
    &:before {
      color: $color--white;
    }
  }
  // @todo test for large screens
  .product--show-mobile-shade-column & {
    background-color: $color--gray--black;
    #{$rdirection}: 0;
    @include swap_direction(padding, 4px 5px 0 0);
    height: 30px;
    width: 30px;
    z-index: $z-above-clinks;
    .icon--remove {
      font-size: 20px;
    }
  }
}

.shade-picker__previous {
  top: 0;
  text-align: center;
  .shade-picker--no-prev-controls & {
    color: $color--gray--dark;
  }
  .icon--arrow--up {
    font-size: 16px;
  }
  .product--teaser & {
    top: 25px;
  }
  .shade-picker--plain & {
    display: none;
  }
}

.shade-picker__next {
  bottom: 0;
  text-align: center;
  .shade-picker--no-next-controls & {
    color: $color--gray--dark;
  }
  .icon--arrow--down {
    font-size: 16px;
  }
  .shade-picker--plain & {
    display: none;
  }
}

// Fake a singular element
.collection-quickshop .shade-picker__color:not(.shade-picker__color--selected) {
  display: none;
}

.shade-picker__color-wrapper {
  // not needed on product list views on touch devices
  .touch .product--teaser & {
    display: none;
  }
  // show for mobile single view.
  .touch .js-single-column .product--teaser & {
    display: block;
  }
  &--mini {
    .touch .js-single-column .product--teaser & {
      display: none;
    }
  }
  position: relative;
  min-height: 60px;
  .collection-quickshop & {
  }
  .page--spp__product .product--multishaded & {
    min-height: 240px;
  }
  .product--full & {
    height: 100%;
  }
  .product--full &.shade-picker__color-wrapper-multishaded {
    height: 180px;
  }
}

.shade-picker__color-texture {
  @include shade-full();
  background-size: cover;
  background-position: 50% 50%;
  .collection-quickshop & {
    background-color: $color--gray--white;
  }
  .product--shaded--single & {
    @include breakpoint($bp--xlarge-up) {
      height: 180px;
    }
  }
}

.shade-picker__color-chip {
  @include animation-opacity;
  @include shade-full();
  opacity: 1;
  .collection-quickshop & {
    display: none;
  }
  .shade-picker__color:hover &,
  .shade-picker__color--selected & {
    opacity: 0;

    // mpp shadepickers should not hover showing smoosh
    .product--teaser & {
      opacity: 1;
    }
  }
}

.shade-picker__multishaded-image {
  @include shade-full();
  display: none;
  .product--full & {
    display: block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
}

.shade-picker__multishaded-image--hover {
  @include shade-full();
  display: none;
  .product--full .shade-picker__color:hover &,
  .shade-picker__color--selected & {
    display: block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
}

.shade-picker__color-details {
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  width: 100%;
  .collection-quickshop & {
    position: static;
    padding-top: $spacing;
  }
  .product--full & {
    @include breakpoint($bp--xlarge-up) {
      display: none;
    }
  }
}

.shade-picker__color-name {
  @include emphasis-caps;
  text-transform: uppercase;
  color: $color--white;
  line-height: 60px;
  @include swap_direction(padding, 0 22px);
  @include fontsize-rem($typesize--18px);
  .shade-picker__color--selected &,
  .shade-label--dark & {
    color: $color--black;
  }
  .collection-quickshop & {
    padding-#{$ldirection}: 0;
    line-height: inherit;
  }
}

.shade-picker__finish-name {
  font-size: 12px;
  @if $fonts_update == true {
    font-family: $body-new-font-family;
  } @else {
    font-family: $body-font-family;
  }
}

.shade-picker__color-description {
  display: none;
  .collection-quickshop & {
    display: block;
  }
}

.shade-picker__color-actions {
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  width: 100%;
  display: none;
  // Only show if not coming soon/sold out
  .product--teaser .shade-picker__color:hover &,
  .product--teaser .shade-picker__color.hover & {
    display: block;
  }
}

.shade-picker__color-actions a {
  @include reverse;
  border-bottom-width: 0;
  float: #{$rdirection};
  height: 60px;
  @include swap_direction(margin, 0);
  position: relative;
  width: 60px;
  &.product__add-to-faves {
    position: relative;
    top: 0;
    .icon--heart-o {
      font-size: 17px;
    }
  }
  // font icons
  i {
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    #{$ldirection}: 20px;
    top: 20px;
  }
}

.prod_inv_status-2 > .shade-picker__color-actions a,
.prod_inv_status-3 > .shade-picker__color-actions a,
.prod_inv_status-5 > .shade-picker__color-actions a,
.prod_inv_status-7 > .shade-picker__color-actions a {
  display: none;
}

.prod_inv_status-2 > .shade-picker__color-actions.hide-inventory__status a {
  display: block;
}

.prod_inv_status-2 > .shade-picker__color-actions .js-inv-status-2,
.prod_inv_status-3 > .shade-picker__color-actions .js-inv-status-3,
.prod_inv_status-5 > .shade-picker__color-actions .js-inv-status-5,
.prod_inv_status-7 > .shade-picker__color-actions .js-inv-status-7,
.prod_inv_status-2.product--preorder
  > .shade-picker__color-actions
  .product__inv-status-item.preorder-noshop,
.prod_inv_status-3.product--preorder
  > .shade-picker__color-actions
  .product__inv-status-item.preorder-noshop,
.prod_inv_status-5.product--preorder
  > .shade-picker__color-actions
  .product__inv-status-item.preorder-noshop,
.prod_inv_status-7.product--preorder
  > .shade-picker__color-actions
  .product__inv-status-item.preorder-noshop {
  @include swap_direction(padding, 20px 20px 0 20px);
  background-color: $color--black;
  color: $color--white;
  display: block;
  height: 60px;
  text-align: center;
  @if $fonts_update == true {
    font-size: 12px;
  } @else {
    font-family: $body-font-family;
  }
}

.prod_inv_status-2 > .shade-picker__color-actions .js-inv-status-2 {
  .shade-picker--plain & {
    padding-#{$rdirection}: 0;
  }
}

.prod_inv_status-2 > .shade-picker__color-actions .js-inv-status-2 {
  width: 50%;
  @include swap_direction(padding, 15px 5px);
}

.shade-picker__color--selected {
}

.shade-picker__trigger {
  position: absolute;
  bottom: 30px;
  display: none;
  z-index: 50;
  .collection-quickshop & {
    display: none;
  }
  &.btn {
    height: 45px;
    line-height: 45px;
  }
  .product--teaser:hover &,
  .product--teaser.hover & {
    @include breakpoint($bp--small-up) {
      display: block;
    }
  }
  .product--teaser--tiny:hover &,
  .touch .product--teaser:hover & {
    @include breakpoint($bp--small-up) {
      display: none;
    }
  }
  .shade-picker--open:hover &,
  .product--teaser.product--teaser--mini.product--sku-product:hover &,
  .product--teaser.product--teaser--tiny.product--sku-product:hover & {
    display: none;
  }
  &:focus {
    .product--teaser & {
      outline: none;
    }
  }
}

// wrapper for large shades overlay trigger
.view-all-shades__wrapper {
  position: absolute;
  width: 25%;
  #{$rdirection}: 0;
  z-index: $z-above-prod-sub-nav;
  top: -60px;
}

// Shade sticky bar
$shade-picker-float-width: 100%;

.shade-picker-float {
  position: relative;
  cursor: pointer;
  // hover children should still trigger
  &:hover .shade-picker-float__colors-mask {
    display: block;
    opacity: 1;
  }
}

.shade-picker-float__colors-mask {
  @include animation-opacity;
  @include abs-pos();
  #{$ldirection}: 0;
  top: 100%;

  // for animation
  display: none;
  opacity: 0;

  // scroller
  overflow-y: auto;
  width: $shade-picker-float-width;
  height: auto;
  max-height: 415px;
  background-color: $color--white;
  @include swap_direction(border, 1px solid $color--gray--lighter);
}

.shade-picker-float__color {
  min-height: 60px;
  color: $color--gray;
  list-style: none;
  @include swap_direction(padding, 24px 0 16px 15px);
  &:hover {
    background-color: lighten($color--gray--lightest, 10%);
  }
}

.product--full .shade-picker-dropdown {
  .select2-arrow b {
    height: 37px;
  }
}

.shade-picker__color {
  .shade-picker__color-wrapper {
    .fav--bag--buttons {
      a.product__add-to-faves {
        @include breakpoint($width-xlarge) {
          margin-top: 12px;
        }
      }
    }
  }
}
