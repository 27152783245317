/* ==========================================================================
   Components Organisms - Events Near You
   ========================================================================== */

.events-near-you {
  border-bottom: 1px solid $color--gray--lightest;
  @include breakpoint($bp--medium-up) {
    border-bottom: none;
  }
  .events-near-you__title {
    font-size: 18px;
    @include swap_direction(margin, 40px 0 10px 0);
    text-align: center;
    @include breakpoint($bp--medium-up) {
      font-size: 36px;
      @include swap_direction(margin, 40px 0);
    }
  }
  .media-block--event {
    .media-block__headline {
      @include heading--small;
      @include breakpoint($bp--small-down) {
        color: $color--black;
      }
    }
    .media-block__media {
      img {
        width: 100%;
      }
    }
    .description {
      @include swap_direction(margin, 15px 0 20px 0);
    }
    .media-block__cta {
      @include btn;
      @include breakpoint($bp--small-down) {
        width: 100%;
      }
      @include breakpoint($bp--medium-up) {
        padding: {
          #{$ldirection}: 0;
          #{$rdirection}: 0;
        }
        text-decoration: underline;
        font-family: $body-font-family;
        @include fontsize-rem(12px);
        line-height: 1.333333333;
        text-decoration: none;
        border-bottom: 1px solid $color--white;
        height: inherit;
        font-weight: bold;
      }
    }
    @include breakpoint($bp--small-down) {
      .media-block__caption {
        background-color: transparent;
        color: $color--black;
      }
    }
  }
  .grid-container--4 {
    .grid--item {
      clear: both;
      float: none;
      @include swap_direction(margin, 0);
      width: auto;
    }
    @include breakpoint($bp--medium-up) {
      @include layout($grid--2) {
        .grid--item {
          &:nth-of-type(2n + 1) {
            @include grid-span(1, 1);
          }
          &:nth-of-type(2n + 2) {
            @include grid-span(1, 2);
          }
          &:nth-of-type(2n + 3) {
            @include grid-span(1, 1);
            clear: both;
          }
          &:nth-of-type(2n + 4) {
            @include grid-span(1, 2);
          }
        }
      }
    }
    @include breakpoint($bp--large-up) {
      @include layout($grid--4) {
        .grid--item {
          &:nth-of-type(4n + 1) {
            @include grid-span(1, 1);
          }
          &:nth-of-type(4n + 2) {
            @include grid-span(1, 2);
          }
          &:nth-of-type(4n + 3) {
            @include grid-span(1, 3);
          }
          &:nth-of-type(4n + 4) {
            @include grid-span(1, 4);
          }
        }
      }
    }
  }
}
