#loyalty__panel__leave {
  display: none;
  padding: 20px;
  &.is-member {
    display: block;
  }
  .loyalty__panel__leave__title {
    margin: 0 0 30px 0;
    text-align: center;
  }
  .loyalty__panel__leave__are-you-sure {
    text-transform: uppercase;
  }
  .loyalty__panel__leave__contact {
    margin-bottom: 5em;
  }
  .loyalty__panel__leave__confirmation {
    display: none;
  }
  .loyalty__panel__leave__form-leave__item {
    margin: 0 0 30px 0;
  }
  &.loyalty__panel__leave--confirmation {
    .loyalty__panel__leave__are-you-sure,
    .loyalty__panel__leave__warning,
    .loyalty__panel__leave__contact,
    .loyalty__panel__leave__error,
    #loyalty_join {
      display: none;
    }
    .loyalty__panel__leave__confirmation {
      display: block;
    }
  }
  @include breakpoint($bp--largest-up) {
  }
}
