/**
 * @file
 * Product Brief - new file for creative refresh 2018
 * styles for new template product_brief_v1
 *
 */
.product-brief {
  color: $color--gray--light;
  font-size: 12px;
  min-height: 431px; // image 320px plus footer margin 20px plus 2 lines text 24px plus button 36px plus rating 31px
  position: relative;
  @if $fonts_update == false {
    font-family: $roboto-mono_regular-font;
    font-weight: normal;
  }
  @include breakpoint($bp--large-up) {
    min-height: 395px; // 431 minus button 36px plus
    a:hover {
      border: none;
    }
  }
  &:hover {
    @include breakpoint($bp--large-up) {
      .product-brief__cta,
      .product-brief__inventory-status {
        opacity: 1;
        visibility: visible;
      }
      a {
        border: none;
      }
    }
  }
  .product-brief-carousel & {
    height: inherit;
  }
  &--has-alt-image:hover {
    .product-brief__image-img {
      opacity: 0;
      visibility: hidden;
    }
    .product-brief__image-img--alt {
      opacity: 1;
      visibility: visible;
    }
  }
  &__top {
    height: 31px;
    position: relative;
  }
  &__badge {
    #{$ldirection}: 1em;
    position: absolute;
    text-transform: uppercase;
    z-index: 2;
    margin-top: 4px;
  }
  &__rating {
    position: absolute;
    width: 90px;
  }
  &__badge + &__rating {
    #{$rdirection}: 0;
  }
  &__image {
    position: relative;
    width: 100%;
    text-align: center;
    &-img {
      margin: 0 auto;
      max-height: 320px;
      max-width: 100%;
      opacity: 1;
      visibility: visible;
      text-align: center;
      transition: opacity 1s ease-in-out;
      width: auto;
      &.product-brief__image-img--alt {
        @include vertically-center(true, true, false);
        opacity: 0;
        visibility: hidden;
        z-index: 1;
      }
    }
    &-link {
      border-bottom: none;
      display: block;
      &:hover,
      &:visited {
        border-bottom: none;
      }
    }
  }
  &__product-name {
    margin: 0;
    float: #{$ldirection};
  }
  &__product-name,
  &__shade,
  &__price {
    color: $color--gray--light;
    font-size: 12px;
    font-weight: 500;
    @if $fonts_update == true {
      font-family: $ano-bold_regular-font;
    } @else {
      font-family: $roboto-mono_regular-font;
    }
  }
  &__price {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-flow: wrap;
    width: 40%;
    float: #{$rdirection};
    top: 0;
    #{$rdirection}: 1em;
    .product-price-v1 {
      float: #{$rdirection};
      text-align: #{$rdirection};
      .product-price {
        padding-#{$rdirection}: 7px;
      }
    }
  }
  &__ppu {
    position: relative;
    float: #{$rdirection};
    &.delimiter:before {
      content: '/';
    }
    &-price:before {
      float: #{$rdirection};
      width: 100%;
    }
    &-price {
      text-align: #{$rdirection};
      width: 100%;
    }
  }
  &__name-link,
  &__shade {
    border: none;
    display: inline-block;
    width: 60%;
    word-wrap: break-word;
  }
  &__shade {
    color: $color-shade-name;
  }
  &__footer {
    margin: 20px 0 12px 0;
    position: relative;
    width: 100%;
    padding-bottom: 12px;
  }
  &__cta {
    clear: both;
    text-align: center;
    [data-sku-inv-status='3'] & {
      display: none;
      &:hover,
      &:visited {
        display: none;
      }
    }
    @include breakpoint($bp--large-up) {
      @include vertically-center(true, true, false);
      opacity: 0;
      transition: opacity 1s ease-in-out;
      visibility: hidden;
      width: auto;
      z-index: 2;
    }
  }
  &-cta,
  .product-add-to-bag {
    background-color: $color--black;
    border: none;
    color: $color--white;
    display: block;
    font-family: $roboto-mono_bold-font;
    font-size: 12px;
    line-height: 36px;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    @if $fonts_update == true {
      @include h6();
      line-height: 36px;
    }
    [data-sku-inv-status='3'] & {
      display: none;
      &:hover,
      &:visited {
        display: none;
      }
    }
    &:hover,
    &:visited {
      border: none;
      color: $color--white;
      text-decoration: none;
      background-color: $color--black;
    }
    @include breakpoint($bp--large-up) {
      font-family: $roboto-mono_regular-font;
      font-size: 15px;
      height: 45px;
      line-height: 45px;
      padding: 0 30px;
      width: auto;
      @if $fonts_update == true {
        @include h6();
        line-height: 45px;
      }
      &:hover,
      &:visited {
        border: none;
        color: $color--white;
      }
    }
  }
}
// Inventory status specific for sold out products
// specificity wars unite for GLUXRE-678
// this is what we're working with...
// product js-pr-product product--brief product-brief product-brief-v1 js-product-brief
// grid--mpp__item .product--teaser.product--shaded.product--single-not-orderable.prod_inv_status-7.product--not-orderable.all-shaded-sold-out.product--single-not-orderable.product--not-orderable .fade-non-shoppable-product
// product__sku-image--default--medium fade-non-shoppable-product
// .product--brief.product-brief.product-brief-sku-inv-status-7 {
//   .product-brief__add-to-bag,
//   .product-brief__link-to-spp,
//   .product-brief__add-to-bag:hover,
//   .product-brief__add-to-bag:visited,
//   .product-brief__link-to-spp:hover,
//   .product-brief__link-to-spp:visited {
//     display: none;
//     opacity: 0;
//     visibility: hidden;
//   }
//   .product__image-medium {
//     opacity: .5;
//   }
//   .product__sku-image--default--medium.fade-non-shoppable-product {
//     opacity: .5;
//   }
// }
