.password-request-page__content {
  @include breakpoint($bp--xlarge-up) {
    width: 68%;
  }
  @include breakpoint($bp--xlarge-down) {
    @include swap_direction(padding, 0 10px);
  }
  .password-request__text {
    margin-bottom: 2rem;
  }
  .request-form__item {
    input[type='email'] {
      &::placeholder {
        text-transform: capitalize;
        color: $color--gray--lightest;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        text-transform: capitalize;
        color: $color--gray--lightest;
      }
    }
    @include breakpoint($bp--xlarge-up) {
      display: inline-block;
      span {
        display: block;
      }
      input[type='email'] {
        width: 320px;
      }
      .pwd_req_submit {
        width: 160px;
        height: 58px;
        line-height: 1;
      }
    }
  }
}

.password-sent-page__content {
  @include swap_direction(padding, 0 10px);
  @include breakpoint($bp--xlarge-up) {
    width: 68%;
  }
  .pwd_sent_emailaddr {
    font-weight: bold;
  }
  .pwd_sent_signin {
    width: 320px;
    @include breakpoint($bp--small-down) {
      display: block;
      @include swap_direction(margin, 0 auto);
    }
  }
}

.password-reset-page__content {
  @include swap_direction(padding, 0 10px);
  .password-reset__text {
    @include swap_direction(padding, 0 0 10px 0);
  }
  .password-reset__form {
    .password-reset__fieldset {
      .field-container {
        .form-item {
          input[type='submit'] {
            width: 160px;
          }
        }
        .password-reset__item {
          @include breakpoint($bp--xlarge-up) {
            float: none;
          }
          @include breakpoint($bp--xlarge-up) {
            input[type='password'] {
              width: 318px;
            }
          }
        }
      }
    }
  }
}
