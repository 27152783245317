/* ==========================================================================
   Components Molecules - Blocks, Basic Landscape Tout Block
   template file is:
   ~/template_api/editorial/basic_landscape_tout_v1
   ========================================================================== */

.tout-block-landscape {
  @include clearfix();
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
  @include swap_direction(padding, 0 5px);
  img {
    @include swap_direction(margin, 0 0 20px 0);
    @include breakpoint($bp--medium-down) {
      width: 100%;
    }
  }
  &__caption {
    @include transform(translateY(-50%));
    align-self: center;
    @include swap_direction(padding, $spacing);
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    text-align: center;
  }
  &__subheadline {
    @include eyebrow-text;
    position: absolute;
    width: 100%;
    text-align: center;
  }
  &__headline {
    @include heading--small;
    text-align: center;
    margin-bottom: 0.5em;
    margin-top: 0;
    @include breakpoint($bp--medium-up) {
      margin-bottom: 0.2em;
    }
    @include breakpoint($bp--largest-up) {
      margin-bottom: 0.5em;
    }
  }
  &__body-above {
    p {
      @include breakpoint($bp--medium-up) {
        margin-bottom: 0.8em;
      }
      @include breakpoint($bp--largest-up) {
        margin-bottom: 1.5em;
      }
    }
  }
  &__body {
    padding-top: 24px;
    @include breakpoint($bp--medium-up) {
      padding-top: 10px;
    }
    @include breakpoint($bp--largest-up) {
      padding-top: 24px;
    }
    p {
      @include breakpoint($bp--medium-up) {
        margin-bottom: 0.2em;
      }
      @include breakpoint($bp--xlarge-up) {
        margin-bottom: 1.5em;
      }
    }
  }
  &__cta {
    @include heading--small;
    background-color: transparent;
    @include breakpoint($bp--medium-up) {
      margin-top: 0.3em;
    }
    @include breakpoint($bp--largest-up) {
      margin-top: 0;
    }
  }
}

.image-item {
  .tout-image {
    position: relative;
    .tout-block-landscape {
      &__image {
        width: 100%;
        min-height: 180px;
        @include breakpoint($bp--largest-up) {
          min-height: auto;
        }
        @include breakpoint($bp--medium-down) {
          display: none;
          .expando-block--expanded & {
            display: block;
          }
        }
      }
      &__image-header {
        position: absolute;
        top: 42%;
        width: 100%;
        text-align: center;
        @include breakpoint($bp--medium-down) {
          display: none;
          .expando-block--expanded & {
            display: block;
          }
        }
      }
    }
  }
  .tout-block-landscape {
    &__image_header_subtext {
      text-align: center;
      padding-top: 20px;
      @include breakpoint($bp--medium-down) {
        display: none;
        .expando-block--expanded & {
          display: block;
        }
      }
    }
  }
}
