/* ==========================================================================
   Components Molecules - Blocks, Multi-Use Tout
   ========================================================================== */

.multi-use-tout {
  position: relative;
  @include breakpoint($bp--large-down) {
    display: none;
  }
  &__image {
    width: 100%;
  }
  &__caption {
    @include fill-rel-parent;
    height: inherit;
    width: 100%;
    max-width: 1281px;
    margin: 0 auto;
    &-inner-inner {
      @include vertically-center($absolute-positioning: true);
      @include swap_direction(padding, 4% $spacing);
      text-align: #{$ldirection};
      width: 62.5%;
      position: absolute;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      @include swap_direction(margin, 0 auto);
      @if $fonts_update == true {
        width: 100%;
      }
      @include breakpoint($bp--medium-up) {
        width: 50%;
        @if $fonts_update == true {
          width: 100%;
        }
      }
      @include breakpoint($bp--large-up) {
        width: 37.5%;
        @if $fonts_update == true {
          width: 100%;
        }
      }
    }
  }
  &__eyebrow {
    @include preheading-mono;
  }
  &__title {
    @include heading-trade-large;
    @include h3();
    @include swap_direction(margin, 0);
    line-height: 1;
    @include breakpoint($bp--medium-up) {
      @include fontsize-rem($typesize--60px);
    }
    @include breakpoint($bp--xlarge-up) {
      @include fontsize-rem($typesize--100px);
    }
    @if $fonts_update == true {
      font-size: 15px;
      letter-spacing: 0;
      @include breakpoint($bp--medium-landscape) {
        font-size: 80px;
        letter-spacing: -5px;
      }
      @include breakpoint($bp--xxlarge-up) {
        font-size: 130px;
        letter-spacing: -5px;
      }
    }
    &--small {
      @include h4();
    }
    &--medium {
      @include h1();
    }
  }
  &__body {
    @include body-mono();
    &--dash {
      &:before {
        @include heading--small;
        content: '_';
        display: block;
        line-height: 0;
        margin-bottom: 36px;
      }
    }
  }
  &__cta {
  }

  // alignment options
  &--align {
    &-raised {
      .multi-use-tout__caption-inner-inner {
        @include transform(none);
        #{$ldirection}: 25%;
        #{$rdirection}: auto;
        text-align: #{$ldirection};
        top: 25%;
        &--no-body {
          @include transform(none);
          top: 20%;
          width: 75%;
          @include breakpoint($bp--xxlarge-up) {
            @include transform(none);
            top: 120px;
          }
        }
      }
    }
    &-center {
      .multi-use-tout__caption-inner-inner {
        text-align: center;
        top: 50%;
      }
    }
    &-left {
      .multi-use-tout__caption-inner-inner {
        #{$ldirection}: 25%;
        #{$rdirection}: auto;
        text-align: #{$ldirection};
        width: 75%;
        .multi-use-tout__body {
          width: 70%;
        }
      }
    }
    &-right {
      .multi-use-tout__caption-inner-inner {
        #{$rdirection}: 25%;
        #{$ldirection}: auto;
        text-align: #{$rdirection};
      }
    }
    &-top-left {
      .multi-use-tout__caption-inner-inner {
        @include transform(none);
        top: $spacing;
        #{$ldirection}: $spacing;
        #{$rdirection}: auto;
        padding-top: 0;
        padding-#{$ldirection}: 0;
        text-align: #{$ldirection};
      }
    }
    &-top-right {
      .multi-use-tout__caption-inner-inner {
        @include transform(none);
        top: $spacing;
        #{$rdirection}: $spacing;
        #{$ldirection}: auto;
        padding-top: 0;
        padding-#{$rdirection}: 0;
        text-align: #{$rdirection};
      }
    }
  }
}

// classes we can use for positioning
// multi-use-tout__has-dash
// multi-use-tout__has-cta
// multi-use-tout__has-copy

.multi-use-tout--align-raised.multi-use-tout__has-copy {
  .multi-use-tout__caption-inner-inner {
    top: 10%;
  }
  &.multi-use-tout__has-cta,
  &.multi-use-tout__has-dash {
    .multi-use-tout__caption-inner-inner {
      top: 0;
    }
  }
}
//
