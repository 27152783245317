/* Click and Collect */
.viewcart-buttons-panel .content .continue-buttons .paypal-checkout .pp_disabled {
  display: none;
}

.viewcart-buttons-panel .content .continue-buttons .paypal-checkout.disabled {
  cursor: default;
}

.viewcart-buttons-panel .content .continue-buttons .paypal-checkout.disabled .pp_disabled {
  display: block;
  background-color: white;
  width: 210px;
  height: 50px;
  top: -57px;
  position: relative;
  opacity: 0.8;
}

.viewcart-buttons-panel .content .continue-buttons .paypal_message {
  width: 100%;
  text-align: center;
}

#checkout_shipmethod {
  padding-#{$ldirection}: 155px;
  padding-#{$rdirection}: 10.5%;
}

.local-collection-map {
  height: 100%;
}

.local-collection-map iframe {
  border: 0;
  width: 100%;
  height: 97%;
}

#shipmethod-panel .ship-method-group {
  width: 100%;
}

.bottom-viewcart-buttons .content .viewcart-buttons-panel .continue-buttons {
  text-align: center;
  width: 100%;
}

.checkout__sidebar #shipmethod-panel .ship-method-group-label {
  margin-left: 0px;
}

.checkout__sidebar #shipmethod-panel #checkout_shipmethod {
  padding: 0px;
}

#shipmethod-panel .ship-method-group-label input {
  float: right;
  height: auto;
  margin-right: 5px;
  margin-left: 15px;
}

#shipmethod-panel .ship-method-group-label label {
  float: $ldirection;
  line-height: 1.2em;
}

#shipmethod-panel .ship-method-group-label .picker-handle {
  float: $ldirection;
}

#shipmethod-panel .ship-method-collection-group label {
  float: right;
  margin-top: 10px;
  clear: both;
}

#shipmethod-panel .ship-method-group-label .shipclearfix {
  clear: both;
  padding: 2px;
}

#shipping-panel .click-and-collect .local-collection .local-collection-button {
  width: 29%;
}
/* Click and Collect - v2 */
/* inline styles */
.map-container .address-map-location-panel .locations .messages {
  background-color: white;
  border: 0;
}

.store-collection-button,
.local-collection-button {
  width: 206px;
  cursor: pointer;
}

.map-container .map {
  height: 300px;
}

.map-container .address-map-search-panel {
  font-size: 1.4em;
}

.map-container .address-map-search-panel * {
  box-sizing: border-box;
}

.map-container .address-map-search-panel .search-box {
  overflow: hidden;
  width: 30em;
}

.map-container .address-map-search-panel .search-box label {
  display: block;
  font-size: inherit;
  margin: 0 0 1em;
  font-weight: bold;
}

.map-container .address-map-search-panel .search-box-field {
  width: 100%;
  font-size: inherit;
  margin: 0;
  height: 45px !important;
  -webkit-appearance: textfield;
  @include swap_direction(padding, 0 0 0 13px !important);
}

.map-container .address-map-search-panel .search-box-submit,
.map-container .search-box-geolocate,
.map-container .address-map-search-panel .search-box-cancel {
  width: 32%;
  font-size: inherit;
  margin-right: 2px;
  float: left;
  clear: none;
}

.map-container .address-map-search-panel .search-box-cancel i:before {
  padding-right: 0;
}

.map-container .address-map-search-panel .search-box-submit {
  margin-left: 0;
}

.map-container .address-map-search-panel .status-bar-wrapper {
  width: 80%;
  margin-left: 10%;
}

.map-container .address-map-search-panel .status-bar-wrapper .status-bar .message {
  padding: 0.5em;
  border: 1px solid #aaa;
  box-shadow: 0px 1px 4px #333;
}

.map-container .address-map-search-panel .status-bar-wrapper .status-bar .message:first-child {
  border-radius: 5px 5px 0 0;
}

.map-container .address-map-search-panel .status-bar-wrapper .status-bar .message:last-child {
  border-radius: 0 0 5px 5px;
}

.map-container .address-map-search-panel .status-bar-wrapper .status-bar .message .close {
  float: right;
}

.map-container .address-map-search-panel .status-bar-wrapper .status-bar .message .close a {
  text-decoration: none;
  color: inherit;
}

.map-container .address-map-search-panel .status-bar-wrapper .status-bar .message.error {
  background: #fed2024;
  color: white;
}

.map-container .address-map-search-panel .status-bar-wrapper .status-bar .message.warning {
  background: #f6e914;
  color: black;
}

.map-container .address-map-search-panel .status-bar-wrapper .status-bar .message.success {
  background: white;
  border-color: black;
  color: black;
}

.map-container .address-map-search-panel .status-bar-wrapper .status-bar .message.information {
  background: black;
  color: white;
}

.map-container .gm-style {
  font-family: 'tstar_mono_roundregular', Helvetica, Arial, sans-serif;
}

.map-container .address-map-directions-panel,
.map-container .address-map-location-panel {
  display: none;
}

.map-container .address-map-directions-panel .locations,
.map-container .address-map-location-panel .locations {
  height: 100%;
  overflow-y: scroll;
  background-color: $white;
  cursor: pointer;
  [dir='rtl'] & {
    padding-right: 10px;
  }
}

.map-container .address-map-directions-panel .location {
  overflow: hidden;
  padding: 0.5em 0.5em 1em;
  margin: 0;
  border-bottom: 1px solid #ccc;
  color: black;
  -webkit-transition: padding 0.5s, background 0.5s;
  transition: padding 0.5s, background 0.5s;
}

.map-container .address-map-location-panel .location {
  overflow: hidden;
  padding: 20px 0 0.5em 1em;
  margin: 0;
  border-bottom: 1px solid #ccc;
  color: black;
  -webkit-transition: padding 0.5s, background 0.5s;
  transition: padding 0.5s, background 0.5s;
}

.map-container .address-map-location-panel .location .detail_reveal {
  display: none;
}

.map-container .address-map-location-panel .location .detail_reveal.selected {
  display: block;
}

.map-container .address-map-location-panel .location:hover,
.map-container .address-map-location-panel .location.selected {
  padding: 2em 0.5em;
  background-color: #c7c7c7;
}

.map-container .address-map-location-panel .location:first-child {
  padding-top: 60px;
}

.map-container .address-map-location-panel .map .location {
  min-width: 200px;
} /* InfoWindow popup */
.map-container .address-map-directions-panel .location p,
.map-container .address-map-location-panel .location p {
  margin: 0 0 0.5em;
}

.map-container .address-map-directions-panel .location .image,
.map-container .address-map-location-panel .location .image {
  float: left;
  width: 100px;
  margin: 0 1em 0 0;
  display: none;
}

.map-container .address-map-directions-panel .location .image img,
.map-container .address-map-location-panel .location .image img {
  width: 100%;
}

.map-container .address-map-directions-panel .location .distance,
.map-container .address-map-location-panel .location .distance {
  float: right;
  color: #999;
}

.map-container .address-map-directions-panel .location .distance .uom,
.map-container .address-map-location-panel .location .distance .uom {
  text-transform: lowercase;
}

.map-container .address-map-directions-panel .location .location_description,
.map-container .address-map-location-panel .location .location_description {
  font-style: italic;
}

.map-container .address-map-directions-panel .location .title h2,
.map-container .address-map-location-panel .location .title h2 {
  font-weight: bold;
  font-size: 14px;
  border: 0;
  padding: 0.5em 0;
  color: black;
}

.map-container .address-map-directions-panel .location div.button,
.map-container .address-map-location-panel .location div.button {
  clear: both;
  margin-top: 1em;
}

.map-container .location .button a {
  background-color: inherit;
  font-family: 'tstar_mono_roundregular', Helvetica, Arial, sans-serif;
  width: 8em;
  height: 2em;
  line-height: 1.5em;
  color: inherit;
  font-size: 11px;
  text-decoration: underline;
}

.map-container .location .button .get-directions {
  width: 13em;
  margin-left: 0.5em;
}

.map-container .address-map-location-panel .location .title {
  font-weight: bold;
}

.map-container .map-info-window {
  background: black;
  color: white;
  padding: 10px;
}

.map-container .map-info-window .title span {
  color: white;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.map-container .map-info-window .location {
  width: 268px;
  padding-left: 17px;
}

.map-container .map-info-window .location .title {
  padding-bottom: 5px;
}

.map-container .directions {
  background: white;
  color: black;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100%;
}

.map-container .map-info-window .location .image {
  display: none;
}

.adp-placemark {
  background-color: white;
}

.adp-placemark td {
  padding: 3px;
  color: black;
}

.adp-substep,
.adp-summary,
.adp-text {
  color: black;
  max-width: 200px;
}

.map-container .adp-step,
.adp-text {
  width: 90% !important;
}

.adp {
  padding-top: 50px;
}
/* overlay styles */
.click_and_collect_map {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
}

.click_and_collect_map .close-container {
  display: none;
}

.click_and_collect_map .overlay-content {
  height: 100%;
  width: 100%;
  position: relative;
}

.click_and_collect_map .overlay-content .map-container {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.click_and_collect_map .overlay-content .map-container .address-map-search-panel {
  position: absolute;
  top: 0px;
  z-index: 1;
  margin-#{$ldirection}: 19.5em;
  margin-top: 1em;
  border-top: 0;
  [dir='rtl'] & {
    left: 100px;
  }
}

.click_and_collect_map .overlay-content .map-container .address-map-search-panel .status-bar-wrapper {
  position: fixed;
  bottom: 20px;
  left: 50%;
  width: 30em;
  margin-left: -15em;
}

.click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-field {
  float: left;
  width: 26em;
  padding: 1.3em;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  [dir='rtl'] & {
    float: right;
  }
}

.click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-submit {
  height: 2.7em;
  width: 3.6em;
  line-height: 2.5em;
  color: $white;
  float: left;
  [dir='rtl'] & {
    float: right;
  }
}

.click_and_collect_map .overlay-content .map-container .search-box-toggle {
  width: 4em;
  height: 4em;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  background-color: $white;
  color: $black;
  padding: 9px;
  font-size: 14px;
  border: solid 1px;
  cursor: pointer;
  right: 1.7em;
  margin-bottom: 15px;
  z-index: 1;
  [dir='rtl'] & {
    position: fixed;
    right: unset;
    left: 1.7em;
    bottom: 8em;
  }
}

.click_and_collect_map .overlay-content .map-container .search-box-toggle-tooltip {
  position: absolute;
  width: 23em;
  height: 5.9em;
  color: white;
  background-color: black;
  font-size: 13px;
  bottom: 4.7em;
  right: 1em;
  z-index: 1;
  padding: 11px;
  padding-right: 60px;
}

.click_and_collect_map .overlay-content .map-container .collection-tooltip,
.click_and_collect_map .overlay-content .map-container .store-tooltip {
  display: none;
}

.local-collection .overlay-content .map-container .search-box-toggle .toggle-img {
  background: url('/media/export/cms/cc/mac_icon_off.png') no-repeat 0px 15px !important;
  background-size: 38px 7px !important;
  height: 2em;
  width: 3.1em;
}

.store-collection .overlay-content .map-container .search-box-toggle .toggle-img {
  background: url('/media/export/cms/cc/ups_icon.png') no-repeat;
  height: 2.5em;
  width: 3.1em;
}

.click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-submit,
.click_and_collect_map .overlay-content .map-container .search-box-cancel {
  background-color: black;
  text-align: center;
  cursor: pointer;
}

.click_and_collect_map .overlay-content .map-container .search-box-cancel {
  position: absolute;
  top: 0.62em;
  right: 0.7em;
  padding-top: 0.1em;
  margin: 0;
  z-index: 1;
  width: 2.5em;
  height: 1.66em;
  color: $white;
  font-size: 27px;
  [dir='rtl'] & {
    right: unset;
    left: 0.7em;
  }
}

.click_and_collect_map .overlay-content .map-container .search-box-cancel .icon--remove:before {
  padding: 0;
}

.click_and_collect_map .overlay-content .map-container .search-box-geolocate {
  position: relative;
  width: 1.7em;
  height: 1.7em;
  font-size: 32.9px;
  right: 0.7em;
  z-index: 1;
  overflow: hidden;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: $white;
  text-align: center;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  background: $black;
  cursor: pointer;
  [dir='rtl'] & {
    position: fixed;
    right: unset;
    left: 0.7em;
    bottom: 1.5em;
  }
}

.click_and_collect_map .overlay-content .map-container .search-box-geolocate .icon--geolocation {
  font-size: 24px;
}

.click_and_collect_map
  .overlay-content
  .map-container
  .address-map-search-panel
  .search-box-submit
  .loading
  span:before,
.click_and_collect_map .overlay-content .map-container .search-box-geolocate .loading i:before,
.click_and_collect_map .overlay-content .map-container .search-box-cancel .loading i:before {
  content: '';
}

.click_and_collect_map
  .overlay-content
  .map-container
  .address-map-search-panel
  .status-bar-wrapper
  .status-bar
  .message {
  border-top: 0;
}

.click_and_collect_map
  .overlay-content
  .map-container
  .address-map-search-panel
  .status-bar-wrapper
  .status-bar
  .message:first-child {
  border-radius: 0;
}

.click_and_collect_map .overlay-content .map-container .address-map-directions-panel,
.click_and_collect_map .overlay-content .map-container .address-map-location-panel {
  top: 0px;
  bottom: 0px;
  display: block;
  position: absolute;
  width: 28em;
  padding-#{$rdirection}: 24px;
  z-index: 2;
}

.click_and_collect_map .overlay-content .map-container .address-map-directions-panel header,
.click_and_collect_map .overlay-content .map-container .address-map-location-panel header {
  width: 24.7em;
  position: absolute;
  top: 0px;
  background: white;
  padding-top: 15px;
  height: 5em;
  z-index: 2;
}

html.mac .click_and_collect_map .overlay-content .map-container .address-map-directions-panel header,
html.mac .click_and_collect_map .overlay-content .map-container .address-map-location-panel header {
  height: 40px !important;
}

.click_and_collect_map .overlay-content .map-container .address-map-directions-panel header {
  height: 45px;
}

.click_and_collect_map .overlay-content .map-container .address-map-location-panel header h2,
.click_and_collect_map .overlay-content .map-container .address-map-directions-panel header h2 {
  color: black;
  font-size: 2em;
  padding-left: 0.5em;
}

.click_and_collect_map .overlay-content .map-container .address-map-location-panel a.close,
.click_and_collect_map .overlay-content .map-container .address-map-directions-panel a.close {
  display: block;
  position: absolute;
  right: 6px;
  top: 5px;
  text-decoration: none;
  z-index: 3;
  margin: 0;
  background: none;
  width: 13px;
  height: auto;
  text-indent: 0px;
}

.click_and_collect_map .overlay-content .map-container .address-map-directions-panel a.close {
  right: 43px;
  top: -2px;
  color: black;
}

.click_and_collect_map .overlay-content .map-container .address-map-location-panel a.close span:before {
  display: block;
  font-family: 'icons';
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '';
  font-size: 16px;
  color: black;
  text-decoration: none;
}

.click_and_collect_map .overlay-content .map-container .address-map-location-panel.active a.close span:before {
  content: '';
}

.click_and_collect_map .overlay-content .map-container .address-map-directions-panel header div a.close span:before {
  display: block;
  font-family: 'Arial';
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '';
  font-size: 16px;
  color: black;
  text-decoration: none;
}

.click_and_collect_map .overlay-content .map-container .address-map-directions-panel,
/*.click_and_collect_map .overlay-content .map-container .address-map-location-panel.hover { left: -280px; }*/
.click_and_collect_map .overlay-content .map-container .address-map-directions-panel,
.click_and_collect_map .overlay-content .map-container .address-map-location-panel.active {
  left: 0;
  [dir='rtl'] & {
    right: 0;
  }
}

.click_and_collect_map .overlay-content .map-container .map {
  height: 100%;
  width: 100%;
}

.click_and_collect_map .overlay-content .map-container .map-side-tools {
  bottom: 15%;
  right: 0.4em;
  position: absolute;
}

@media (max-width: 1023px) {
  .map-container .address-map-search-panel .search-box-field {
    width: 40%;
    font-size: inherit;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-field {
    width: 21em;
  }
}

@media (max-width: 850px) {
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-wrapper {
    width: 100%;
    float: none;
    clear: both;
    padding-left: 10px;
  }
  .click_and_collect_map .overlay-content .map-container .search-box-cancel {
    right: 0px;
    font-size: 21px;
    top: 0px;
    height: 45px;
    [dir='rtl'] & {
      right: unset;
      left: 0;
    }
  }
  .map-container .location .button a {
    font-size: 10px;
  }
  .map-container .location .button .get-directions {
    margin-left: 0px;
  }
  .click_and_collect_map .overlay-content .map-container .map-info-window {
    max-width: 300px;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel {
    margin-left: 0;
    margin-top: 2em;
    [dir='rtl'] & {
      margin-right: 0;
      margin-top: 0;
      left: 0;
    }
  }
  .address-map-location-panel header h2 {
    margin-top: -5px;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-directions-panel,
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel {
    width: 100%;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel a.close,
  .click_and_collect_map .overlay-content .map-container .address-map-directions-panel a.close {
    right: 35px;
    [dir='rtl'] & {
      right: unset;
      left: 25px;
    }
  }
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel header,
  .click_and_collect_map .overlay-content .map-container .address-map-directions-panel header {
    width: 100%;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-directions-panel {
    top: 0;
    max-height: 100%;
    overflow-y: scroll;
  }
  .click_and_collect_map .overlay-content .search-box-cancel span:before {
    padding-top: 0;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel {
    top: auto;
    left: 0pc;
    bottom: 0px;
    width: 100%;
    height: 30px;
    margin: 0;
    padding-bottom: 0;
    overflow: hidden;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel.active {
    top: 0px;
    height: auto;
    z-index: 3;
    [dir='rtl'] & {
      padding: 0;
    }
  }
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel a.close span:before {
    font-family: 'icons';
    content: '';
    color: black;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel.active a.close span:before {
    content: '';
  }
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel header {
    padding-top: 10px;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel .location:first-child {
    padding-top: 50px;
  }
  .map-container .address-map-location-panel .location .title .distance {
    display: block;
  }
  .map-container .address-map-location-panel .location .title .distance .uom {
    color: #888888;
  }
  .map-container .address-map-location-panel .location .distance {
    display: none;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-directions-panel,
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel {
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  #checkout_shipmethod {
    padding: 0px;
  }
  .map-container .address-map-search-panel .search-box {
    width: auto;
  }
  .map-container .address-map-search-panel .search-box-field {
    width: 30%;
  }
  .click_and_collect_map .overlay-content .map-container .map-info-window .location .title {
    padding-top: 2px;
  }
  .click_and_collect_map .overlay-content .map-container .map-info-window .location .location_description,
  .click_and_collect_map .overlay-content .map-container .map-info-window .location .opening_hours,
  .click_and_collect_map .overlay-content .map-container .map-info-window .location .image {
    display: none;
  }
  .click_and_collect_map .overlay-content .map-container .map-info-window .location .location_more_info {
    color: white !important;
    text-decoration: underline;
    cursor: pointer;
    line-height: 2em !important;
    display: block;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .status-bar-wrapper {
    position: fixed;
    bottom: 50px;
    width: 100%;
    margin: 0;
    left: 0;
    text-align: center;
  }
  #shipmethod-panel .ship-method-group-label label {
    line-height: 1.5em;
  }
  #shipmethod-panel .ship-method-group-label {
    margin-left: 0px;
  }
  body#viewcart .viewcart-buttons-panel .continue-buttons a.continue-checkout {
    margin: 10px 0;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-field {
    width: 19.3em;
  }
  .search-box-wrapper .search-box .search-box-submit {
    height: 200px;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-wrapper {
    width: 100%;
    float: none;
    clear: both;
    padding-left: 0px;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel {
    margin-top: 0em;
  }
  .map-container .map-info-window .location {
    width: auto;
    padding: 0;
  }
  .map-container .location .title span {
    line-height: 2em;
  }
  .adp-placemark td {
    padding-left: 22px;
  }
}

@media (max-width: 480px) {
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel {
    margin-left: 0;
    border-radius: 0;
    width: 100%;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-directions-panel {
    top: 0;
    max-height: 100%;
    overflow-y: scroll;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel.hover {
    left: 0pc;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-location-panel a.close {
    top: 2px;
    right: 25px;
    border: 0;
  }
  /* TEMPORARY MOBILE STYLING */
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-wrapper {
    width: 100%;
    background-color: white;
    border-bottom: 1px black solid;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-field {
    width: 89%;
    border: 0;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .search-box-submit {
    background-color: white;
    color: black;
    width: 10%;
  }
  .click_and_collect_map .overlay-content .map-container .search-box-cancel {
    z-index: 2;
  }
}
