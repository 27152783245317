.site-container #product-page-livechat-hitarea {
  width: 322px;
  height: 45px;
  bottom: -6px;
  right: 0;
  left: auto;
  #footer_sticky_chat {
    background: #000;
    padding: 14px 10px;
    width: 322px;
    border: 1px solid #fff;
    #online-icon {
      color: #fff;
      font: 16px Arial, Verdana, sans-serif;
      border-bottom: none;
      img {
        padding-right: 10px;
      }
    }
  }
}

#lhc_status_container {
  display: none;
  bottom: 100px;
}

#lpChat select.lp_select_field {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  -o-appearance: menulist;
}

@mixin txttrans() {
  &::-webkit-input-placeholder {
    text-transform: none;
  }
  &:-moz-placeholder {
    text-transform: none;
  }
  &:-ms-input-placeholder {
    text-transform: none;
  }
  &::-moz-placeholder {
    text-transform: none;
  }
}

#lpChat {
  input[type='text'] {
    @include txttrans();
  }
  input[type='email'] {
    @include txttrans();
  }
  textarea {
    @include txttrans();
  }
  select {
    text-transform: none;
  }
  option {
    text-transform: none;
  }
}

.site-header {
  .site-header__tools {
    .site-email-signup__contents-form {
      .form-type-checkbox {
        display: block;
        margin: 10px 0;
      }
    }
  }
}

.site-header {
  $self: &;
  #{$self} {
    &__fixed-wrapper {
      #{$self} {
        &__prefix {
          @media screen and (min-width: $width-xxlarge) {
            margin-#{$ldirection}: 45px;
          }
        }
        &__suffix {
          @media screen and (min-width: $width-largest) {
            position: inherit;
          }
          @media screen and (min-width: $width-xxlarge) {
            padding-#{$rdirection}: 45px;
          }
          #{$self} {
            &__tools {
              .active {
                .site-bag {
                  &__link {
                    span {
                      color: $color--black;
                    }
                  }
                }
              }
              .site-bag {
                &.active {
                  height: 60px;
                }
                &__contents {
                  @media screen and (min-width: $width-medium) {
                    margin-top: 10px;
                  }
                }
                &__link-icon {
                  @media screen and (min-width: $width-largest) {
                    font-size: 30px;
                    bottom: 5px;
                  }
                }
                &__link-count {
                  color: $color--white;
                  top: 5px;
                }
              }
              .site-my-mac {
                @media screen and (min-width: $width-medium) {
                  background-color: $color--gray--black;
                  height: 60px;
                  padding: 0 5px;
                }
                @media screen and (min-width: $width-medium) {
                  padding: 0 15px;
                }
                &.active {
                  background-color: $color--white;
                  color: $color--black;
                  padding: 0 10px;
                  .site-my-mac {
                    &__contents {
                      @media screen and (min-width: $width-medium) {
                        top: 60px;
                      }
                    }
                    &__link {
                      span {
                        @media screen and (min-width: $width-largest) {
                          color: $color--black;
                        }
                      }
                    }
                  }
                }
                &__link {
                  @media screen and (min-width: $width-largest) {
                    padding-top: 17px;
                  }
                }
                &__link-header {
                  @media screen and (min-width: $width-largest) {
                    display: block;
                    font-size: 10px;
                    line-height: 1;
                  }
                  @media screen and (min-width: $width-largest) {
                    padding: 0 15px 0 15px;
                  }
                }
              }
              .block-template-site {
                &-email-signup-v1 {
                  &:after {
                    display: none;
                  }
                  @media screen and (min-width: $width-xxlarge) {
                    padding-#{$rdirection}: 10px;
                  }
                }
                &-my-mac-v1 {
                  #{$rdirection}: 60px;
                  @media screen and (min-width: $width-largest) {
                    #{$rdirection}: 0;
                  }
                }
              }
              .site-email-signup {
                &.active {
                  @media screen and (min-width: $width-largest) {
                    height: 61px;
                    padding: 0 11px;
                  }
                  .site-email-signup {
                    &__link-icon {
                      @media screen and (min-width: $width-largest) {
                        top: 13px;
                        position: relative;
                      }
                    }
                    &__contents {
                      @media screen and (min-width: $width-largest) {
                        top: 60px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &__menu {
          @media screen and (min-width: $width-largest) {
            width: 660px;
          }
          @media screen and (min-width: $width-xxlarge) {
            width: 920px;
          }
        }
      }
    }
  }
  .field-collection-slides {
    li {
      &.site-navigation {
        &__item {
          a {
            font-size: 13px !important;
          }
        }
      }
    }
  }
}

.top-header {
  .site-header {
    .site-header {
      &__tools {
        .site-my-mac {
          &__link-header {
            padding: 0;
            @media screen and (min-width: $width-largest) {
              padding: 0 15px 0 15px;
            }
          }
        }
      }
      &__fixed-wrapper {
        .site-header {
          &__menu {
            margin-#{$ldirection}: 0;
            @media screen and (min-width: $width-largest) {
              width: 662px;
              margin-#{$ldirection}: 172px;
            }
            @media screen and (min-width: $width-xxlarge) {
              width: 920px;
            }
          }
          .block-template-site-my-mac-v1 {
            bottom: 8px;
            @media screen and (min-width: $width-largest) {
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

body {
  .grid--mpp__item {
    .product-brief-v2 {
      .product-inventory-status {
        &__temp-oos-text,
        &__coming-soon-text {
          margin-top: 0;
          .notify-status {
            width: 50%;
            .notify_me {
              font-size: 15px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  &.section-now-trending {
    .product-brief-v2 {
      .product-brief {
        &__cta {
          .product-add-to-bag {
            width: 190px;
          }
        }
      }
    }
  }
  .hotspot-product {
    &__card {
      .product-brief-v2 {
        .product-brief {
          &__cta {
            .product-add-to-bag {
              font-size: 11px;
              @include breakpoint($bp--medium-up) {
                font-size: 15px;
              }
            }
            .cta-vto-link {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

.tabbed-products-block-v2 {
  .product-brief-v2 {
    .product-brief {
      &__cta {
        top: unset;
        .product-brief-cta,
        .product-add-to-bag {
          max-width: unset;
        }
      }
    }
  }
}

.tout-block-landscape {
  padding: 0;
  img {
    margin: 0;
  }
}
