/* ==========================================================================
   Components Organisms - Platform Toolbar
   ========================================================================== */

/*
 * overrides of platform toolbar
 * so this works better with our pages
 */

.toolbar-menu {
  a {
    text-transform: none;
  }
}

#toolbar div.toolbar-menu {
  background: #333;
}
