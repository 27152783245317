.enhanced-delivery-page {
  &.checkout {
    .email-and-sms-promotions {
      border-bottom: 1px solid;
      border-top: 1px solid;
      float: none;
      margin: 13px 0 0;
      overflow: hidden;
      padding: 10px 17px;
      width: 100%;
      @include breakpoint($width-xlarge) {
        margin-top: 1px;
        padding: 10px 15px;
      }
      .checkout__subtitle {
        font-size: 16px;
        line-height: 1.63;
        margin: 0;
        width: 100%;
        .accordion-icon {
          top: 10px;
        }
      }
      .content {
        float: $ldirection;
        padding-top: 18px;
      }
      .pc_email_promo_container {
        .picker {
          &.picker-checkbox {
            .picker-handle {
              @include swap_direction(margin, 0 15px 0 0);
              border: 1px solid $color--gray--dusty;
              border-radius: 2px;
              height: 18px;
              width: 18px;
              .picker-flag {
                &::before {
                  font-size: 16px;
                  position: absolute;
                  top: 1px;
                  #{$ldirection}: 1px;
                }
              }
            }
          }
          .picker-label {
            color: $color--black;
            font-size: 12px;
            line-height: 1.7;
            a {
              border-bottom: 0;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
