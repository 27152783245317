/* ==========================================================================
   Global Variables - Breakpoints
   ========================================================================== */

/*
 * Breakpoint Configuration
 * @see https://github.com/Team-Sass/breakpoint/wiki
 *
 * Usage:
 * display: none; // hidden for mobile
 * @include breakpoint($bp--small-up) {
 *   display: block; // shown above 401
 * }
 */

// width sign posts
$width-xxxsmall: 321px !default;
$width-xxsmall: 361px !default;
$width-xsmall: 401px !default;
$width-small: 481px !default;
$width-medium: 641px !default;
$width-large: 769px !default;
$width-larger: 901px !default;
$width-largest: 967px !default;
$width-xlarge: 1023px !default;
$width-xxlarge: 1281px !default;
$width-xxlarger: 1368px !default;
$width-xxxlarge: 1441px !default;
$width-xxxlargest: 1550px !default;
$width-wide: 1601px !default;
$width-xwide: 1921px !default;
$width-xxwide: 2101px !default;

// height sign posts
$height-tall: 623px !default;

$site-width: $width-xxlarge; // design max-width

// Breakpoint variables are prefixed with "$bp--"
// Mobile-first queries
$bp--xxxsmall-up: $width-xxxsmall; // 321px
$bp--xxsmall-up: $width-xxsmall; // 361px
$bp--xsmall-up: $width-xsmall; // 401px
$bp--small-up: $width-small; // 481px
$bp--medium-up: $width-medium; // 641px
$bp--large-up: $width-large; // 769px
$bp--larger-up: $width-larger; // 901px
$bp--largest-up: $width-largest; // 967px
$bp--xlarge-up: $width-xlarge; // 1023px
$bp--xxlarge-up: $width-xxlarge; // 1281px
$bp--xxlarger-up: $width-xxlarger; // 1368px
$bp--xxxlarge-up: $width-xxxlarge; // 1441px
$bp--xxxlargest-up: $width-xxxlargest; //1550px
$bp--wide-up: $width-wide; // 1601px
$bp--xwide-up: $width-xwide; // 1921px
$bp--xxwide-up: $width-xxwide; // 2101px

// Fenced queries
$bp--xxxsmall: max-width $width-xxxsmall - 1;
$bp--xxsmall: max-width $width-xxsmall - 1;
$bp--xsmall: max-width $width-small - 1;
$bp--small: $width-small $width-medium - 1;
$bp--medium: $width-medium $width-large;
$bp--large: $width-large + 1 $width-xlarge - 1;

// Queries with no-query fallbacks
$bp--large-up--no-mq: $bp--large-up, 'no-query' '.no-mq';
$bp--medium-up--no-mq: $bp--medium-up, 'no-query' '.no-mq';

// Mobile-last queries (USE SPARINGLY)
$bp--xxsmall-down: max-width $width-xsmall - 1;
$bp--xsmall-down: max-width $width-small - 1;
$bp--small-down: max-width $width-medium - 1;
$bp--medium-down: max-width $width-large - 1;
$bp--xmedium-down: max-width $width-larger - 1;
$bp--large-down: max-width $width-xlarge - 1;
$bp--xlarge-down: max-width $width-xxlarge - 1;

// iPad
$bp--xlarger-up: max-width $width-xlarge + 1; // 1024px

// Height for iphone6plus includes safari address bar and bottom bar
$bp--tall-up: min-height $height-tall; // 623px

$bp--medium-portrait: ($width-large - 1 $width-xlarge + 1) (orientation portrait); // iPad portrait
$bp--medium-landscape: ($width-large - 1 $width-xlarge + 1) (orientation landscape); // iPad landscape
/*
 * UNISON: Let javascript respond to the above breakpoint
 *
 * Unison.on('usn-x-small', function() {
 *   console.log('x-small breakpoint');
 * });
 * Unison.on('usn-small', function() {
 *   console.log('small breakpoint');
 * });
 * Unison.on('usn-medium', function() {
 *   console.log('another medium function');
 * });
 * etc ...
 *
 */
$debug: false;

// create sass list to pass media query data
// if a breakpoint name changes, remember to
// update it in the list below as well
$mq-sync: (
  usn-zero '0px',
  usn-x-small $width-xsmall,
  usn-small $width-small,
  usn-medium $width-medium,
  usn-large $width-large,
  usn-larger $width-larger,
  usn-largest $width-largest,
  usn-x-large $width-xlarge,
  usn-xx-large $width-xxlarge,
  usn-xxx-large $width-xxxlarge,
  usn-wide $width-wide,
  usn-xwide $width-xwide,
  usn-xxwide $width-xxwide
);
