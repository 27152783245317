/* ==========================================================================
   Components Molecules - Add to Bag Bar
   ========================================================================== */

/*
 * Bar that shows at the top of a product organism when scrolled past a certain
 * point. See positioning behavior within _product.scss.
 *
 * @partial: 01-molecules/10-products/01-add-to-bag-bar.mustache
 */

// Bar grid, pulling in global defined
$add-bar-columns: $grid--12;

/* Mixin - Add to Bag Bar, Item Padding
   ========================================================================== */

// Shared padding of items in bar
@mixin add-to-bag-bar-item-padding {
  @include swap_direction(padding, 24px 0 16px 15px); // bottom padding for greater touch
}

.add-to-bag-bar {
  background: $color--black;
}

.add-to-bag-bar__item {
  @include h6();
  height: 60px;
  @include swap_direction(margin, 0);
  line-height: 1;
}

// Direction
.add-to-bag-bar__direction {
  // layout
  @include layout($add-bar-columns) {
    @include grid-span(2, 1);
  }
  // styles
  background-color: $color--gray--lightest;
  position: relative;
}

.add-to-bag-bar__icon {
  @include icon(arrow--up-medium);
  font-size: 45px;
  position: absolute;
  top: 10px;
  #{$ldirection}: 0;
}

.add-to-bag-bar__up {
  @include add-to-bag-bar-item-padding;
  height: 60px;
  position: relative;
  padding-#{$ldirection}: 35px;
  cursor: pointer;
}

.add-to-bag-bar__left {
  @include add-to-bag-bar-item-padding;
  display: none; // temp
}

// Shadepicker
.add-to-bag-bar__shade-picker {
  // layout
  @include add-to-bag-bar-item-padding;
  @include layout($add-bar-columns) {
    @include grid-span(3, 3);
  }
  // styles
  color: $color--white;
  border-#{$rdirection}: 1px solid $color--gray--lightest;
  // triangle
  &:after {
    content: '';
    @include corner-triangle;
  }
  .shade-label--dark & {
    color: $color--black;
  }
}

.add-to-bag-bar__shade-desc {
  // layout
  @include add-to-bag-bar-item-padding;
  @include layout($add-bar-columns) {
    @include grid-span(4, 6);
  }
  // styles
  color: $color--white;
  text-transform: none;
  font-size: 13px;
  @if $fonts_update == true {
    font-family: $body-new-font-family;
  } @else {
    font-family: $body-font-family;
  }
  .shade-label--dark & {
    color: $color--black;
  }
}

// Price
.add-to-bag-bar__price {
  // layout
  @include add-to-bag-bar-item-padding;
  @include layout($add-bar-columns) {
    @include grid-span(1, 10);
  }
  // styles
  color: $color--white;
  .shade-label--dark & {
    color: $color--black;
  }
}

// Add button
.add-to-bag-bar__add-button,
.product__bag-bar .product__inventory-status {
  // layout
  @include add-to-bag-bar-item-padding;
  text-align: #{$rdirection};
  @include swap_direction(padding, 10px 10px 0 0);
  @include layout($add-bar-columns) {
    @include grid-span(2, 11);
  }
  // styles
  color: $color--white;
}

.product__bag-bar .product__inventory-status {
  position: absolute;
  #{$rdirection}: 0;
}

.prod_inv_status-2 .product__bag-bar .temp-out-of-stock,
.prod_inv_status-3 .product__bag-bar .coming-soon,
.prod_inv_status-5 .product__bag-bar .inactive,
.prod_inv_status-7 .product__bag-bar .sold-out {
  @include swap_direction(margin, $spacing 0 0 0);
  height: 40px;
  line-height: 44px;
  @include swap_direction(padding, 0 25px 0 25px);
  width: auto;
  cursor: default;
  background-color: $color--gray--light;
  float: #{$rdirection};
  @include swap_direction(margin, 3px 0 0 0);
  text-decoration: none;
}

.prod_inv_status-2.product--not-preorder .product__bag-bar .temp-out-of-stock {
  display: none;
}
