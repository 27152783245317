// Loop through our map and load font files
// $fonts map defined in scss/global/fonts.scss

@each $font-index, $font-info in $fonts {
  $font-name-mac: map-get($font-info, font-name-mac);
  $font-folder-mac: map-get($font-info, font-folder-mac);
  $font-filename-mac: map-get($font-info, font-filename-mac);
  $font-weight-mac: map-get($font-info, font-weight-mac);
  $font-style-mac: map-get($font-info, font-style-mac);
  @font-face {
    font-family: '#{$font-name-mac}';
    src: url('/_fonts/maccosmetics/#{$font-folder-mac}/#{$font-filename-mac}.woff2') format('woff2');
    font-weight: '#{$font-weight-mac}';
    font-style: '#{$font-style-mac}';
    font-display: swap;
  }
}

// This is updated for 2018 as we only need to support woff and woff2 formats
// reading from $fonts_new
@each $font-index, $font-info in $fonts_new {
  $font-name-mac: map-get($font-info, font-name-mac);
  $font-folder-mac: map-get($font-info, font-folder-mac);
  $font-filename-mac: map-get($font-info, font-filename-mac);
  $font-weight-mac: map-get($font-info, font-weight-mac);
  $font-style-mac: map-get($font-info, font-style-mac);
  @font-face {
    font-family: '#{$font-name-mac}';
    src: url('/_fonts/maccosmetics/#{$font-folder-mac}/#{$font-filename-mac}.woff2') format('woff2');
    font-weight: '#{$font-weight-mac}';
    font-style: '#{$font-style-mac}';
    font-display: swap;
  }
}


//Tstar font replaces the roboto mono font when "$tstar_font_update == true"

$tstar_font_update: false !default;

@if $tstar_font_update == true {
  $roboto-mono_regular-font: $tstar-font;
  $roboto-mono_bold-font: $tstar-bold-font;
}
