.enhanced-delivery-page:not([class*='enhanced_signin_page']) {
  .customer-links {
    background: $color--black;
    border-top: 1px solid $color--mercury;
    display: block;
    margin-top: 65px;
    padding: 35px 0 31px;
    @include breakpoint($width-largest) {
      display: none;
    }
    &__container {
      padding: 0 20px;
      .link-content {
        @include display-flex;
        margin: 0 -3px;
      }
      .customer-link {
        &__info {
          @include display-flex;
          @include swap_direction(margin, 0 -6px 0 0);
          width: 100%;
        }
        &__list {
          border: 1px solid $color--white;
          color: $color--white;
          font-size: 14px;
          margin: 0;
          padding: 10px 0;
          text-align: center;
          text-transform: uppercase;
          width: 50%;
          &:last-child {
            width: 100%;
          }
        }
      }
      .customer-links {
        border: 1px solid $color--white;
        color: $color--white;
        font-size: 14px;
        margin: 0 7px;
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
        width: 50%;
        &:last-child {
          width: 100%;
        }
      }
      .links,
      .contact-link {
        color: $color--white;
      }
      .contact-link {
        border: 0;
      }
      .icon-call,
      .chat-link {
        &::before {
          content: '';
          display: inline-block;
          vertical-align: bottom;
          width: 30px;
        }
      }
      .chat-link {
        &::before {
          background: url('/media/images/checkout/chat-bubble-icon.png') no-repeat 0 5px;
          background-size: 70%;
          height: 25px;
        }
      }
      .icon-call {
        &::before {
          background: url('/media/images/checkout/call-icon.png') no-repeat 0 5px;
          background-size: 70%;
          height: 26px;
        }
      }
    }
  }
}
.customer-links {
  display: none;
}
