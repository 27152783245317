/* ==========================================================================
   Components Organisms - Grid MPP
   ========================================================================== */

/*
 * Product-specific styles in _product.scss
 * This file mainly for overal mpp behavior and layout
 */

$grid-initial-offset: 30%;
$grid-image-offset: 52%;
$grid-initial-offset--neg: -30%;
$grid-image-offset--neg: -52%;

.grid--mpp {
  // Set MPP grid to use a float because we need to remove random
  // items and have them collapse
  &:not(.grid--mpp__custom-layout) {
    @include pyramid-grid-collapsible($gutter: 0.17, $item: '.grid--mpp__item');
  }
  @include swap_direction(padding, 0 $spacing 0);
  @include breakpoint($bp--medium-up) {
    @include swap_direction(padding, 40px $spacing 0);
  }
  &__custom-layout {
    @include pyramid-grid-mpp-custom-layout($gutter: 0, $item: '.grid--mpp__item');
    overflow: hidden;
    padding: 0;
    @include breakpoint($bp--medium-up) {
      width: calc(
        #{$site-width} - 20px
      ); // decrease the header left padding to allign the grid to it
      max-width: calc(100% - 20px);
      margin: 0 10px;
    }
    @include breakpoint($bp--xwide-up) {
      margin: 0 auto;
    }
    .grid--mpp__gutter-sizer {
      width: 6%;
    }
    &.mixitup-initialized {
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
      .grid--mpp__gutter-sizer {
        display: none;
      }
    }
  }
  body.has-sort-grid-menu & {
    @include breakpoint($bp--xlarge-up) {
      margin-top: 38px;
    }
  }
  .page-products & {
    @include breakpoint($bp--small-down) {
      margin-top: 60px;
    }
    @include breakpoint($width-medium $width-xlarge) {
      margin-top: 15px;
    }
  }
  .page-products .mpp-custom-override-layout & {
    @include breakpoint($bp--small-down) {
      margin-top: 0;
    }
  }
  &--raised {
    background-color: $color--white;
    position: relative;
    margin-top: 60px;
    @include breakpoint($bp--medium-up) {
      margin-top: -41px;
    }
    @include breakpoint($bp--medium-up) {
      margin-top: 0;
      @include breakpoint(min-height 615px) {
        margin-top: 0;
      }
    }
    @include breakpoint($bp--large-up) {
      margin-top: 0;
      @include breakpoint(min-height 680px) {
        margin-top: 0;
      }
    }
    @include breakpoint($bp--larger-up) {
      margin-top: 0;
      @include breakpoint(min-height 750px) {
        margin-top: 0;
      }
    }
    @include breakpoint($bp--largest-up) {
      margin-top: 0;
    }
    @include breakpoint($bp--xlarge-up) {
      margin-top: $grid-initial-offset;
      @include breakpoint(min-height 880px) {
        margin-top: $grid-image-offset;
      }
    }
    @include breakpoint($bp--xxlarge-up) {
      margin-top: $grid-initial-offset;
      @include breakpoint(min-height 960px) {
        margin-top: $grid-image-offset;
      }
    }
    @include breakpoint($bp--xxxlarge-up) {
      margin-top: $grid-initial-offset;
      @include breakpoint(min-height 1045px) {
        margin-top: $grid-image-offset;
      }
    }
    @include breakpoint($bp--wide-up) {
      margin-top: $grid-initial-offset;
      @include breakpoint(min-height 1215px) {
        margin-top: $grid-image-offset;
      }
    }
    @include breakpoint($bp--xwide-up) {
      margin-top: $grid-initial-offset;
      @include breakpoint(min-height 1305px) {
        margin-top: $grid-image-offset;
      }
    }
    @include breakpoint($bp--xxwide-up) {
      margin-top: $grid-initial-offset;
      @include breakpoint(min-height 1500px) {
        margin-top: $grid-image-offset;
      }
    }
    .grid-filter--open & {
      margin-top: 0;
    }
  }
  &__item {
    margin-bottom: $spacing;
    @include breakpoint($bp--small-up) {
      margin-bottom: ($spacing * 3);
      @include swap_direction(padding, 0 10px);
    }
    &.filterable-products__grid-item-tout {
      .grid--mpp__custom-layout & {
        text-align: center;
        min-height: 500px;
        @include breakpoint($bp--medium-up) {
          min-height: 600px;
          margin-bottom: 0;
        }
      }
      .grid--mpp__custom-layout.mixitup-initialized & {
        min-height: unset;
        margin-bottom: 50px;
      }
      img {
        .grid--mpp__custom-layout & {
          width: unset;
          margin: 0;
        }
      }
      .block-template-basic-landscape-tout-v1,
      .block-template-basic-portrait-tout-v1 {
        .grid--mpp__custom-layout & {
          margin: 0 auto;
          max-width: 390px;
          width: 100%;
          padding: 0 10px;
          @include breakpoint($bp--medium-up) {
            padding: 0;
            max-width: unset;
          }
        }
      }
    }
    .grid--mpp__custom-layout & {
      @include breakpoint($bp--small-up) {
        margin-bottom: 0;
        padding: 0;
      }
    }
    .grid--mpp__custom-layout.mixitup-initialized & {
      // don't remove required for mixitup layout
      display: none;
    }
    &:not(.has-shade-picker, .filterable-products__grid-item-tout) {
      .grid--mpp__custom-layout & {
        min-height: 500px;
        @include breakpoint($bp--medium-up) {
          min-height: 600px;
        }
      }
    }
    @include breakpoint($bp--small-down) {
      &[data-match-height] {
        // overrides JS-set height value, used for equal height columns; not needed in a single column
      }
    }
  }
}

.ambient-header--raised {
  position: absolute;
  top: 0;
  width: 100%;
  @include breakpoint($bp--xlarge-up) {
    top: auto;
  }
  .multi-use-tout__title {
    font-size: 8rem;
    margin-top: 17%;
    @include breakpoint($bp--xlarge-up) {
      margin-top: 3%;
    }
  }
  // custom case for mpp header raised with no subnav
  // match the --raised breakpoints for the mpp grid here but margin is negative
  .mpp-subnav-hidden & {
    margin-top: $grid-initial-offset--neg;
    @include breakpoint($bp--medium-up) {
      margin-top: $grid-initial-offset--neg;
      @include breakpoint(min-height 615px) {
        margin-top: $grid-image-offset--neg;
      }
    }
    @include breakpoint($bp--large-up) {
      margin-top: $grid-initial-offset--neg;
      @include breakpoint(min-height 680px) {
        margin-top: $grid-image-offset--neg;
      }
    }
    @include breakpoint($bp--larger-up) {
      margin-top: $grid-initial-offset--neg;
      @include breakpoint(min-height 750px) {
        margin-top: $grid-image-offset--neg;
      }
    }
    @include breakpoint($bp--xlarge-up) {
      margin-top: $grid-initial-offset--neg;
      @include breakpoint(min-height 880px) {
        margin-top: $grid-image-offset--neg;
      }
    }
    @include breakpoint($bp--xxlarge-up) {
      margin-top: $grid-initial-offset--neg;
      @include breakpoint(min-height 960px) {
        margin-top: $grid-image-offset--neg;
      }
    }
    @include breakpoint($bp--xxxlarge-up) {
      margin-top: $grid-initial-offset--neg;
      @include breakpoint(min-height 1045px) {
        margin-top: $grid-image-offset--neg;
      }
    }
    @include breakpoint($bp--wide-up) {
      margin-top: $grid-initial-offset--neg;
      @include breakpoint(min-height 1215px) {
        margin-top: $grid-image-offset--neg;
      }
    }
    @include breakpoint($bp--xwide-up) {
      margin-top: $grid-initial-offset--neg;
      @include breakpoint(min-height 1305px) {
        margin-top: $grid-image-offset--neg;
      }
    }
    @include breakpoint($bp--xxwide-up) {
      margin-top: $grid-initial-offset--neg;
      @include breakpoint(min-height 1500px) {
        margin-top: $grid-image-offset--neg;
      }
    }
  }
  @include breakpoint($bp--large-down) {
    .multi-use-tout {
      &__caption,
      &__image {
        display: none;
      }
    }
  }
}

.field-elc-mpp-content,
.field-content {
  .ambient-header--raised {
    position: fixed;
    top: 60px;
    @include breakpoint($bp--xxlarge-up) {
      top: 120px;
    }
    z-index: -1;
    margin-top: 0;
    .mpp-subnav-hidden & {
      top: 60px;
    }
  }
}

@include breakpoint($bp--large-down) {
  .field-content {
    .ambient-header--raised {
      position: static;
    }
  }
}

.grid--mpp-3across {
  @include swap_direction(padding, $spacing $spacing 0);
  @include pyramid-grid-3across($gutter: 0.17, $item: '.grid--mpp__item');
  &--raised {
    background-color: $color--white;
    margin-top: -300px;
    position: relative;
  }
  &__item {
    margin-bottom: $spacing;
  }
  .expando-block & {
    @include swap_direction(padding, 0 $spacing 0);
    float: left;
    clear: both;
    width: 100%;
    @include breakpoint($bp--medium-up) {
      @include swap_direction(padding, 40px $spacing 0);
    }
  }
}

.grid--mpp-hide-buzzword {
  .grid--mpp__item {
    .product__description-short {
      display: none;
    }
  }
}

.grid--mpp__item {
  .product__skus-name {
    display: none;
  }
  .product__name,
  .product__subline {
    &.product__name_short {
      display: none;
    }
  }
  .product__image-medium {
    max-width: 280px;
  }
}

.grid--mpp__item,
.artist--products__carousel {
  .product__footer {
    .product__price_ppu,
    .product__price-installment {
      position: absolute;
      bottom: 0px;
      top: 42px;
      @if $product__price_ppu == true {
        width: 50%;
      }
      @include breakpoint($bp--large-down) {
        display: none;
      }
    }
    .product__price-installment {
      #{$rdirection}: 0;
    }
  }
  .product__header {
    .product__price_ppu,
    .product__price-installment {
      display: none;
      @include breakpoint($bp--large-down) {
        display: block;
      }
    }
    .product__price_set {
      @include breakpoint($bp--large-down) {
        @include swap_direction(margin, 0 0 11px 0);
      }
    }
  }
}

.artist--products__carousel {
  .product__footer {
    .product__price_ppu,
    .product__price-installment {
      top: 58px;
    }
  }
}

.artistry--artist {
  .product__header {
    .product__price_ppu,
    .product__price-installment {
      display: none;
    }
  }
  .product__footer {
    .product__price-installment {
      @include breakpoint($bp--large-down) {
        @include swap_direction(margin, 0 0 10px 0);
      }
    }
  }
}

.grid--mpp-show-name_short {
  .grid--mpp__item {
    .product__name,
    .product__subline {
      display: none;
      &.product__name_short {
        display: block;
      }
    }
  }
}

.grid--mpp-hide-prices {
  .grid--mpp__item {
    .product__price {
      display: none !important;
    }
  }
}

.mpp-custom {
  margin-bottom: $spacing;
  &.mpp-custom__header--no-border {
    border-top-width: 0;
  }
  &.grid--mpp--raised {
    & ~ section.mpp-custom {
      background-color: $color--white;
      margin-top: 0;
    }
  }
}

.mpp-custom__header {
  @include breakpoint($bp--medium-up) {
    padding-top: 40px;
    .mpp-custom-override-layout & {
      padding-bottom: 33px;
    }
  }
  @include breakpoint($bp--large-up) {
    border-top: $border;
  }
  & + .expando-block {
    padding-top: 0;
    border-top: none;
  }
  &--top {
    @include breakpoint($bp--medium-up) {
      padding-top: 100px;
    }
  }
  &.mpp-custom__header--link-overlay {
    @include swap_direction(padding, 25px 0);
    @include breakpoint($bp--medium-up) {
      border-top: $border;
      @include swap_direction(padding, 0);
      padding-top: 40px;
    }
    .mpp-custom__header-subheader {
      @include body-mono();
      cursor: pointer;
      display: inline-block;
      line-height: 1;
      border-bottom: 1px solid $black;
      text-transform: uppercase;
      a {
        border-bottom: none;
      }
    }
  }
  &--top {
    @include breakpoint($bp--medium-up) {
      padding-top: 40px;
      .tout-block-landscape__image {
        margin-top: 15px;
      }
    }
  }
  .mpp-custom-override-layout & {
    border: none;
  }
}

.mpp-custom__anchor {
  margin-top: -80px;
  position: absolute;
  &--sticky_menu {
    @include breakpoint($bp--medium-up) {
      margin-top: -120px;
    }
  }
}

.site-banner__is-open .mpp-custom__anchor--sticky_menu {
  @include breakpoint($bp--medium-up) {
    margin-top: -180px;
  }
}

.collection-custom__anchor {
  margin-top: -60px;
  position: absolute;
}
// complementary products display only 4
.mpp-complementary-products__item.js-display-ok:nth-child(n + 5) {
  display: block;
}

.products-prefilter--hidden {
  display: none;
  .grid-filter--open & {
    display: block;
  }
}

.prod_inv_status-2 {
  .product__footer {
    .product__inventory-status {
      display: block;
    }
  }
}

.prod_inv_status-3 {
  .product__footer {
    .product__inventory-status {
      display: block;
      .coming-soon {
        display: block;
      }
    }
  }
}

.grid--mpp__item {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        .temp-out-of-stock__text {
          margin-top: 40px;
          position: absolute;
          #{$rdirection}: 0;
        }
        a.notify_me {
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 0px 15px);
          line-height: 30px;
          @include breakpoint($bp--large-down) {
            @include swap_direction(padding, 0);
            width: 100%;
            position: static;
            line-height: 60px;
            @if $fonts_update == false {
              font-size: 18px;
            }
          }
        }
        a.notify_me:hover {
          text-decoration: none;
        }
      }
    }
  }
  .product--teaser.prod_inv_status-3 {
    .product__footer {
      .product__inventory-status li.coming-soon-text {
        float: #{$rdirection};
        text-decoration: none;
        height: 2em;
        line-height: 2em;
        font-size: 1.25em;
        margin: 0;
        cursor: default;
        @include swap_direction(padding, 0 20px);
        @include breakpoint($bp--large-down) {
          display: block;
          width: 100%;
          height: 60px;
          font-size: 1.5em;
          line-height: 64px;
          position: relative;
        }
      }
    }
  }
  .product--teaser.prod_inv_status-2 {
    .product__footer {
      .product__inventory-status li {
        .temp-out-of-stock__msg {
          float: #{$rdirection};
          @include swap_direction(padding, 6px 0px);
        }
      }
    }
  }
  .product--teaser.prod_inv_status-3.product--shaded
    .product__footer
    .product__inventory-status
    li.coming-soon-text {
    display: none;
  }
}

.page--spp__product {
  .product--not-shoppable {
    .product__footer {
      .product__inventory-status {
        li.product__inv-status-item {
          position: relative;
          @include breakpoint($bp--large-down) {
            width: auto;
          }
        }
        li.product__inv-status-item.coming-soon {
          @include swap_direction(padding, 0);
          cursor: default;
          &:hover {
            text-decoration: none;
          }
        }
        .coming-soon_text {
          @include breakpoint($bp--large-up) {
            @include swap_direction(padding, 0 22px);
          }
        }
        .notify-status {
          @include swap_direction(margin, 0 0 3px 0);
          @include breakpoint($bp--medium-down) {
            @include swap_direction(margin, 50px 14px 0 14px);
          }
        }
        .notify_me {
          @include swap_direction(margin, 10px 0 0 0);
        }
        .coming-soon_text,
        .temp-out-of-stock__text {
          position: absolute;
          text-align: center;
          @include swap_direction(padding, 0 23px);
          @include swap_direction(margin, 10px 0 0 0);
          #{$ldirection}: 0;
          @include breakpoint($bp--medium-down) {
            position: absolute;
            bottom: 100%;
            #{$rdirection}: 0;
            text-align: #{$ldirection};
            @include swap_direction(padding, 0 0 0 15px);
          }
        }
        .coming-soon_text {
          @include breakpoint($bp--large-up) {
            top: 100%;
          }
        }
      }
    }
  }
}

.page--spp__product {
  .product__detail {
    .product__footer {
      .product__price_ppu {
        .product__size--hiphen--ppu,
        .product__size--ppu {
          display: none;
        }
      }
      .product__price-installment {
        @include breakpoint($bp--large-down) {
          position: static;
          @include swap_direction(margin, 0 0 11px 15px);
        }
      }
    }
  }
}

.artistry--artist {
  .artist--products__carousel {
    .product__header {
      .product__price_ppu,
      .product__price-installment {
        @include breakpoint($bp--large-down) {
          display: block;
        }
      }
    }
  }
  .product--teaser.prod_inv_status-3 {
    .product__footer {
      .product__inventory-status li.coming-soon-text {
        float: #{$rdirection};
      }
    }
  }
}

.section-giftcards {
  .grid--mpp-3across {
    @include breakpoint($bp--medium) {
      margin-top: 25px;
    }
  }
}

.page-products-products-goodbyes {
  .grid--mpp {
    @include breakpoint($bp--medium-up) {
      margin-top: -35px;
    }
    @include breakpoint($bp--small-down) {
      margin-top: 15px;
    }
  }
}

.page-products-products-vips {
  .grid--mpp {
    @include breakpoint($bp--medium) {
      margin-top: 0px;
    }
    @include breakpoint($bp--small-down) {
      margin-top: 10px;
    }
  }
}

.grid--mpp__item,
.artist--products__carousel {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        .temp-out-of-stock__text,
        .coming-soon_text {
          display: none;
        }
      }
    }
  }
  .product--teaser.prod_inv_status-2,
  .product--teaser.prod_inv_status-3 {
    .product__footer {
      .product__inventory-status li {
        float: none;
        @include swap_direction(padding, 0);
        &.coming-soon-text {
          padding: 0 20px;
        }
      }
    }
  }
}

.spp-workswith-v1 {
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        .product__inventory-status {
          li.temp-out-of-stock {
            a.notify_me {
              @include swap_direction(margin, 0);
              position: absolute;
              width: auto;
              font-size: 15px;
              line-height: 30px;
              height: 30px;
              @include breakpoint($bp--large-down) {
                font-size: 1.5rem;
                text-align: center;
                height: 60px;
                line-height: 64px;
                width: 100%;
                clear: both;
                position: relative;
              }
            }
          }
        }
      }
    }
  }
}

.section-giftcards {
  .grid--mpp-3across {
    @include breakpoint($bp--medium) {
      margin-top: 25px;
    }
  }
}

.page-products-products-goodbyes {
  .grid--mpp {
    @include breakpoint($bp--medium-up) {
      margin-top: -35px;
    }
  }
}

.page-products-products-vips {
  .grid--mpp {
    @include breakpoint($bp--medium) {
      margin-top: 0px;
    }
  }
}

.grid--mpp__item,
.artist--products__carousel {
  .product--teaser {
    @include breakpoint($bp--large-down) {
      &.prod_inv_status-2 {
        .product__image-medium {
          .temp-out-of-stock__text {
            top: 22%;
          }
        }
      }
      &.prod_inv_status-3 {
        .product__image-medium {
          .coming_soon-full_text {
            top: 22%;
          }
        }
      }
    }
  }
}
