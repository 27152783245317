.enhanced_cart_page {
  .accordionPanel {
    &.accordion-processed {
      &.rewards-panel {
        border-top: 1px solid $color--black;
        margin-top: 25px;
      }
      background: $color--white;
      .loyalty-offer-code-panel {
        background: $color--white;
        border-top: 1px solid $color--black;
        margin-top: 15px;
        @include breakpoint($bp--medium-up) {
          border-top: 0;
          margin-top: 0;
        }
        &__select {
          line-height: 1.2;
          color: $color--black--opacity-6;
          @if $fonts_update == true {
            font-size: 13px;
          } @else {
            font-family: $body-font-family;
            font-size: 11px;
          }
          @include breakpoint($bp--large-up) {
            font-size: 14px;
          }
        }
        .offer-code-wallet {
          &__code__description {
            text-align: center;
            @include breakpoint($bp--large-up) {
              text-align: $ldirection;
              min-height: 225px;
              position: relative;
            }
            &-title {
              line-height: 1.13;
              font-size: 14px;
              letter-spacing: 0.5px;
              font-weight: 400;
              overflow: hidden;
              @if $fonts_update == false {
                font-family: $ano-bold_regular-font;
              }
              @include breakpoint($bp--large-up) {
                font-size: 18px;
                height: 40px;
                text-align: center;
              }
            }
            &-description {
              color: $color--black--opacity-6;
              @include breakpoint($bp--large-up) {
                color: $color--black;
                text-align: center;
                height: 50px;
                overflow: hidden;
                font-size: 14px;
                line-height: 1.2;
              }
            }
            &-expiration {
              line-height: 1.2;
              height: auto;
              color: $color--black--opacity-6;
              @if $fonts_update == false {
                font-size: 9px;
              }
              @include breakpoint($bp--large-up) {
                font-size: 14px;
                color: $color--black;
                text-align: center;
                height: 50px;
                overflow: hidden;
              }
            }
          }
        }
        .offer-code-content__wallet {
          .slick-next,
          .slick-prev {
            top: 28%;
          }
          .offer-code-wallet {
            &__button--remove {
              background: $color--black;
              border: 1px solid $color--white;
              color: $color--white;
            }
          }
        }
      }
    }
  }
  .checkout__sidebar {
    .loyalty-birthday-panel {
      background: $color--white;
      .product-brief-carousel {
        .slick-list,
        .product--teaser {
          background: $color--white;
        }
        .slick-list {
          margin: 0 auto;
        }
        .product--teaser {
          min-height: auto;
        }
        .product__name {
          font-size: 18px;
          line-height: 1.2;
          height: auto;
          clear: both;
          padding-#{$rdirection}: 0;
          width: 80%;
          margin: 0 auto;
        }
      }
      &__footer {
        div {
          width: 210px;
          margin: 0 auto;
          .btn--full {
            padding: 12px 15px;
            height: 40px;
            line-height: 1;
            font-size: 14px;
          }
        }
      }
    }
    .checkout-panel {
      &--loyalty-points {
        width: 100%;
        background: $color--white;
        margin-top: 0;
        .loyalty-points-content {
          &__summary__member-content,
          &__summary__non-member-content {
            margin: 0 15px;
            padding: 15px 0;
            color: $color--black--opacity-6;
            border-top: 1px solid $color--gray--tier5;
            line-height: 1.34;
            @if $fonts_update == false {
              font-size: 12px;
            }
          }
        }
      }
    }
    .accordionPanel {
      &.accordion-processed {
        border-top: 1px solid $color--gray--tier5;
        border-bottom: 1px solid $color--gray--tier5;
        padding: 0;
        &.need-help-panel {
          border: 0;
          padding: 12px 15px;
        }
      }
    }
  }
  .checkout__content {
    .checkout-panel {
      &--loyalty-points {
        display: none;
        @include breakpoint($bp--large-up) {
          width: 100%;
          display: block;
        }
        .loyalty-points-content {
          &__summary__member-content,
          &__summary__non-member-content {
            color: $color--black--opacity-6;
          }
        }
      }
    }
  }
  .loyalty-birthday-panel {
    padding: 15px;
    margin: 0;
    .btn--full {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
  .viewcart {
    section#order-summary-panel {
      border: none;
    }
  }
}

.checkout__content {
  .loyalty-offer-code-panel {
    &__points {
      font-size: 16px;
      margin-#{$ldirection}: 15px;
      @include breakpoint($bp--medium-up) {
        margin-#{$ldirection}: 15%;
      }
    }
    header {
      padding: 0 10px;
    }
    .offer-code-content__wallet {
      .slick-slide {
        .offer-code-wallet {
          &__button {
            width: 150px;
            background: $color--white;
            color: $color--black;
            border: 1px solid $color--black--opacity-6;
            height: 40px;
            padding: 14px 25px;
            line-height: 1;
            &.disabled {
              background: $color--gray--checkout;
              border: none;
              color: $color--black;
              pointer-events: none;
            }
            @include breakpoint($bp--medium-up) {
              padding: 7px 25px;
            }
            @include breakpoint($bp--large-up) {
              width: 100%;
              position: absolute;
              bottom: 0;
            }
          }
          &__code__image-container {
            img {
              width: 180px;
              margin: 0 auto;
            }
          }
        }
        .offer-code-wallet__code {
          &__description-description {
            min-height: auto;
            margin-bottom: 10px;
          }
          &__description-points {
            width: 100%;
            @include breakpoint($bp--large-up) {
              position: absolute;
              bottom: 50px;
              text-align: center;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        .product {
          &__sku-details {
            .product {
              &__actions {
                text-align: center;
                position: relative;
                @include breakpoint($bp--large-up) {
                  text-align: inherit;
                }
              }
              &_shade {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
