.bopis {
  background: $color-grey-fill;
  border-radius: 0;
  padding: 15px;
  margin: 20px 0;
  &__zip-container {
    margin: 15px 0;
  }
  &__delivery-container {
    padding-top: 18px;
  }
  &__zip-title {
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include breakpoint($bp--large-up) {
      flex-wrap: nowrap;
    }
    &-block {
      font-size: 14px;
      line-height: 18px;
      font-family: $ano_regular-font;
      width: 52%;
      .location {
        &__zip-title-code-value {
          justify-content: flex-end;
        }
      }
    }
    &-block:last-child {
      text-align: right;
      flex-grow: unset;
      display: block;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      width: 48%;
      @include breakpoint($bp--large-up) {
        margin-top: 0;
      }
    }
    &-heading {
      &.bopis__zip-title-heading {
        font-family: $ano-bold_regular-font;
        font-size: 12px;
        letter-spacing: 0.3px;
      }
    }
    &-notice {
      font-size: 14px;
      line-height: 18px;
      font-family: $ano_regular-font;
    }
    &-code {
      text-decoration: underline;
      font-weight: bold;
      cursor: pointer;
    }
  }
  &-more-info-container {
    font-size: 14px;
    margin-top: 10px;
    color: $color--gray--medium--dark;
    display: none;
    a {
      text-transform: none;
    }
    .selection-on-pdp & {
      display: block;
    }
  }
  &-delivery-message-container {
    display: none;
    .selection-on-pdp & {
      display: block;
    }
  }
  &-selected-delivery-status {
    color: $color-apple-green;
    &.not-available {
      color: $color-dark-red;
    }
  }
  &__zip-form {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding: 0 15px;
    position: relative;
    @include breakpoint($bp--large-up) {
      padding: 0;
    }
    &.invalid-zip {
      .bopis-invalid-zip {
        display: block;
      }
    }
    .bopis-invalid-zip {
      color: $red;
      position: absolute;
      display: none;
      top: 47px;
      font-size: 14px;
    }
    &-input {
      padding-#{$ldirection}: 45px;
      border: 1px solid $color-off-black;
      width: 100%;
      text-transform: uppercase;
      &::placeholder {
        font-size: 14px;
        text-transform: initial;
      }
    }
    &-input-wrap {
      position: relative;
      width: 100%;
      max-width: none;
      @include breakpoint($bp--small-up) {
        max-width: none;
      }
      .location__input-icon {
        position: absolute;
        top: 50%;
        #{$ldirection}: 0;
        transform: translate3d(0, -50%, 0);
        max-width: 45px;
        padding: 15px;
      }
    }
    &-text {
      width: 100%;
      display: block;
      padding: 0 20px;
      @include breakpoint($bp--large-up) {
        padding: 0;
      }
    }
    &--desktop {
      display: none;
      @include breakpoint($bp--large-up) {
        display: flex;
      }
    }
  }
  &__search_btn {
    top: 0;
    border-color: $brown;
  }
  &__search-current-location {
    position: absolute;
    #{$rdirection}: 0;
    top: 2px;
    width: 55px;
    height: calc(100% - 5px);
    background: url('/media/export/cms/bopis/Icon_ geolocation.png') no-repeat center/40%;
    display: inline-block;
    cursor: pointer;
    @include breakpoint($bp--large-up) {
      width: 45px;
    }
  }
  &__delivery-container {
    .bopis__delivery-item {
      display: flex;
      justify-content: space-between;
      background: $color--white;
      border-radius: 12px;
      border: none;
      padding: 10px;
      margin-bottom: 8px;
      &.dm-active {
        border: 2px solid $color--gray--lighter;
      }
      .selection-on-pdp & {
        &:hover {
          border: 2px solid $color--gray--lighter;
        }
        &:not(.dm-active) {
          cursor: pointer;
        }
      }
      .bopis-more-info {
        display: inline-block;
        border: 1px solid $color--gray--light;
        background-color: $color--gray--light;
        border-radius: 50%;
        height: 15px;
        line-height: 13px;
        width: 15px;
        margin: 0 5px 0 0;
        cursor: pointer;
        font-size: 11px;
        font-weight: 700;
        @include breakpoint($bp--large-up) {
          margin: 0 5px 0 10px;
        }
        .selection-on-pdp & {
          display: none;
        }
      }
      &-text {
        display: block;
        font-family: $ano-bold_regular-font;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.3px;
        @include breakpoint($bp--large-up) {
          display: flex;
        }
      }
      &-icon {
        align-self: center;
        margin-#{$rdirection}: 10px;
        margin-#{$ldirection}: 2px;
        padding-top: 4px;
        width: 20px;
        .selection-on-pdp & {
          align-self: auto;
          margin-bottom: 5px;
          margin-#{$rdirection}: 0;
          margin-#{$ldirection}: 0;
        }
        @include breakpoint($bp--medium-up) {
          margin-#{$rdirection}: 20px;
          margin-#{$ldirection}: 10px;
        }
        &.postmates-icon {
          margin-#{$rdirection}: 0;
          padding: 0;
          @include breakpoint($bp--large-up) {
            padding: 0 0 0 5px;
          }
        }
      }
      &-icon-postmates {
        margin-#{$ldirection}: 8px;
        margin-#{$rdirection}: 0;
      }
    }
  }
  &__delivery-item {
    border-bottom: 1px solid $color--black;
    margin-bottom: 18px;
    padding-bottom: 13px;
    display: flex;
    & p {
      margin-bottom: 0;
    }
    &-icon {
      align-self: baseline;
      margin-#{$rdirection}: 8px;
    }
    &-text-wrap {
      flex-grow: 1;
      @include breakpoint($bp--large-up) {
        flex-grow: 0;
      }
    }
    &-text {
      display: block;
      line-height: 20px;
      width: 100%;
      @include breakpoint($bp--large-up) {
        display: flex;
      }
    }
    &-price {
      flex-grow: 1;
      text-align: right;
      font-family: $ano-bold_regular-font;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.3px;
      .selection-on-pdp & {
        text-align: #{$ldirection};
        font-family: $ano_regular-font;
        margin-top: 5px;
      }
    }
    &-notice {
      color: $color-apple-green;
      font-family: $ano_regular-font;
      font-size: 14px;
      line-height: 18px;
      .selection-on-pdp & {
        display: none;
      }
      &.loading-effect-active {
        margin: 10px 0;
        min-height: 1px;
      }
      & > .bopis-shop-name {
        font-weight: normal;
      }
      & > .bopis-call-notice {
        color: $color--black;
      }
    }
    &-link {
      font-family: $ano_regular-font;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      padding-top: 4px;
    }
    &-disabled {
      color: $gray;
      & .bopis__delivery-item-notice {
        color: $color-dark-red;
      }
      & .bopis__delivery-item-icon {
        opacity: 0.5;
      }
    }
  }
}

.bopis-product {
  display: flex;
  align-items: flex-start;
  margin: 20px 30px 17px;
  @include breakpoint($bp--large-up) {
    margin: 20px 0 15px;
  }
  &__image {
    flex-shrink: 0;
    width: 46px;
    margin-#{$rdirection}: 7px;
    text-align: center;
  }
  &__name {
    padding-bottom: 16px;
    h6 {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.bopis-buttons__section {
  display: block;
  @include breakpoint($bp--large-up) {
    display: none;
  }
}

.bopis-info-popup {
  padding: 30px 20px 0;
  font-size: 16px;
  @include breakpoint($bp--large-up) {
    font-size: 14px;
    padding: 35px 30px 0;
  }
  &__item-disabled {
    color: $color-bopis-error;
    margin: 30px 0;
    @include breakpoint($bp--large-up) {
      margin: 30px 20px;
    }
  }
}

#colorbox.bopis-overlay {
  #cboxWrapper {
    #cboxContent {
      #cboxLoadedContent {
        @include breakpoint($bp--large-up) {
          padding: 0;
        }
      }
      &:not(.edit-bag-modal) {
        #cboxClose {
          top: 0;
          #{$rdirection}: 10px;
          width: 20px;
          height: 30px;
          line-height: 1;
          @include breakpoint($bp--large-up) {
            width: 30px;
            #{$rdirection}: 20px;
            line-height: 1.3;
          }
          &::before {
            font-size: 16px;
            line-height: 1;
            font-weight: bold;
          }
        }
      }
    }
    .invis-search__container {
      .heading-section {
        text-align: center;
      }
      .invis-search {
        &__header {
          font-weight: 300;
          font-size: 20px;
          text-align: center;
          font-weight: bold;
          width: 100%;
          margin: auto;
          padding: 0;
          @include breakpoint($bp--large-up) {
            width: 100%;
            font-size: 28px;
          }
          &--cart {
            padding: 25px 30px 0;
          }
        }
        &__sub-header {
          @include swap_direction(margin, 5px 0 20px);
          text-align: center;
          @include breakpoint($bp--large-up) {
            @include swap_direction(margin, 5px 0 18px);
          }
        }
      }
      .search__row {
        margin-top: 0;
        @include breakpoint($bp--large-up) {
          @include swap_direction(margin, 30px 20px 20px);
          &--sm {
            margin: 10px 30px;
          }
        }
      }
      .bopis__zip-form-input {
        height: 48px;
        text-align: left;
        border-radius: 3px;
        background-color: $color--gray--white;
        border-color: $color--gray--white;
        @include breakpoint($bp--large-up) {
          font-size: 14px;
          line-height: inherit;
        }
        &:focus {
          border-color: $color--gray--white;
        }
      }
      .bopis__search_btn {
        padding: 2px;
        font-size: 12px;
        border-color: $brown;
        &:hover {
          background: $color-off-black;
          color: $white;
        }
        @include breakpoint($bp--large-up) {
          padding: 4px 11px 3px 19px;
          background: $color-off-black;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .bopis__search-current-location {
        flex-shrink: 0;
        width: 45px;
      }
      .bopis-results-container {
        @include breakpoint($bp--large-up) {
          width: 47%;
          float: #{$ldirection};
        }
        .bopis__zip-form {
          margin: 0 0 16px;
          @include breakpoint($bp--xlarge-up) {
            @include swap_direction(margin, 0 0 12px);
            display: flex;
          }
        }
        .bopis__store-count {
          text-align: #{$ldirection};
          margin: 16px 0;
          font-size: 14px;
          float: #{$ldirection};
          @include breakpoint($bp--large-up) {
            @include swap_direction(margin, 0 0 7px);
            padding: 5px 0;
            font-size: 15px;
          }
          .zipcode-result {
            display: none;
            @include breakpoint($bp--xlarge-up) {
              display: inline;
            }
          }
        }
        .bopis__stores-filter {
          padding: 0 15px;
          margin-bottom: 15px;
          .bopis__store-count {
            margin: 0;
          }
          @include breakpoint($bp--large-up) {
            padding: 0;
            margin-bottom: 10px;
          }
        }
        .bopis__available-filter {
          position: relative;
          float: #{$rdirection};
          @include breakpoint($bp--large-up) {
            top: 5px;
          }
          .bopis__filter-text {
            margin-#{$ldirection}: 45px;
          }
          .bopis__filter-handler {
            opacity: 0;
            width: 0;
            height: 0;
          }
          .bopis__filter-slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            #{$ldirection}: 0;
            #{$rdirection}: 0;
            background-color: $color--gray--light;
            color: transparent;
            border-radius: 10px;
            width: 40px;
            height: 17px;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            &::before {
              position: absolute;
              top: -3px;
              content: '';
              height: 22px;
              width: 22px;
              border-radius: 50%;
              background-color: $color--white;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }
          }
          input:checked + .bopis__filter-slider {
            background-color: $color--black;
            &::before {
              -webkit-transform: translateX(18px);
              -ms-transform: translateX(18px);
              transform: translateX(18px);
            }
          }
        }
        .bopis-results {
          padding: 0 15px;
          width: 100%;
          @include breakpoint($bp--large-up) {
            overflow: auto;
            height: 380px;
            margin: 0;
            padding: 0 5px 0 0;
            border-bottom: none;
            border-top: none;
            &::-webkit-scrollbar {
              width: 3px;
            }
            &::-webkit-scrollbar-thumb {
              background: $color-off-black;
            }
            &::-webkit-scrollbar-track {
              background: $light-gray;
            }
          }
          .invis-results__list {
            margin-top: 0;
            height: auto;
            overflow: auto;
          }
          .invis-result {
            padding: 15px 0;
            border-bottom: 1px solid $gray;
            &.selected {
              background: none;
            }
            &:last-child {
              border-bottom: none;
            }
            @include breakpoint($bp--large-up) {
              padding: 0;
              &:last-child {
                border-bottom: 1px solid $gray;
              }
              &:first-child {
                border-top: 1px solid $gray;
              }
            }
            .invis-result-inner {
              display: flex;
              @include breakpoint($bp--large-up) {
                padding: 14px 0;
                border-bottom: none;
              }
              .invis-result__info {
                width: 100%;
                padding: 0;
                font-size: 14px;
                @include breakpoint($bp--large-up) {
                  width: 60%;
                }
                .bopis-curbside-pickup-message {
                  color: $color-apple-green;
                  display: block;
                  padding-bottom: 5px;
                }
                span {
                  padding-bottom: 5px;
                }
                .bopis-overlay__address {
                  margin: 0;
                }
              }
              .bopis-inv-status {
                width: 100%;
                padding-#{$ldirection}: 0;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                @include breakpoint($bp--large-up) {
                  width: 40%;
                  @include swap_direction(padding, 0 0 0 4px);
                  font-size: 14px;
                }
                @include breakpoint($bp--large-down) {
                  width: 48%;
                }
                @include breakpoint($bp--medium-down) {
                  width: 100%;
                }
                .bopis-inv-status-text {
                  position: relative;
                  @include swap_direction(padding, 0 0 0 20px);
                  text-align: #{$ldirection};
                  text-transform: none;
                  color: $color-dark-red;
                  min-height: auto;
                  font-size: 14px;
                  &.bopis-inv-status-available {
                    color: $color-apple-green;
                  }
                  img {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 15px;
                    height: 15px;
                  }
                  .status-icon {
                    display: inline-block;
                  }
                }
                .bopis-inv-status-message {
                  font-size: 14px;
                  font-weight: normal;
                  text-align: #{$ldirection};
                  text-transform: uppercase;
                }
                .bopis-curbside-pickup-message {
                  position: relative;
                  @include swap_direction(padding, 0 0 0 20px);
                  color: $color-apple-green;
                  font-size: 14px;
                  font-weight: normal;
                  .status-icon {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 15px;
                    height: 15px;
                  }
                }
              }
              .invis-result {
                &__store-info {
                  font-size: 14px;
                  line-height: 1.2;
                  a {
                    border: none;
                  }
                }
                &__store-name {
                  display: flex;
                  text-transform: none;
                  font-size: 16px;
                  font-weight: bold;
                  padding-bottom: 5px;
                  margin: 0;
                }
                &__distance {
                  padding-#{$ldirection}: 10px;
                  text-transform: none;
                  white-space: nowrap;
                }
                &__store-adress,
                &__store-phone,
                &__store-distance,
                &__store-hours {
                  margin-bottom: 5px;
                  display: flex;
                }
                &__store-phone,
                &__store-hours {
                  align-items: center;
                }
                &__store-phone {
                  .bopis-overlay__icon-sm {
                    margin-bottom: 2px;
                  }
                }
                &__store-hours {
                  margin-bottom: 0;
                }
              }
              .button {
                min-width: 87px;
                margin-top: 10px;
                padding: 2px 5px 1px;
                white-space: nowrap;
                font-size: 12px;
                top: auto;
                bottom: 0;
                right: 0;
                border: none;
                letter-spacing: 1px;
                position: absolute;
                @include icon('caret--right', after);
                &:hover {
                  background: none;
                  color: $color-off-black;
                  font-weight: bold;
                }
                &.loading-effect-active {
                  flex-grow: 1;
                  & > .loading-text-effect {
                    align-items: flex-end;
                  }
                }
              }
            }
            &__directions {
              @include swap_direction(margin, 6px 0 0);
              text-transform: none;
              a {
                text-decoration: underline;
                &:hover {
                  text-decoration: none;
                }
              }
            }
            &__week {
              p {
                margin-bottom: 10px;
                font-weight: 700;
                cursor: pointer;
                &::after {
                  content: '';
                  display: inline-block;
                  width: 6px;
                  height: 6px;
                  margin-#{$ldirection}: 6px;
                  margin-bottom: 2px;
                  border: 2px solid $color-off-black;
                  border-#{$ldirection}: none;
                  border-bottom: none;
                  transform: rotate(135deg);
                }
              }
              &.active {
                p {
                  &::after {
                    transform: rotate(-45deg);
                    margin-bottom: 0;
                  }
                }
              }
            }
            &__week-list {
              display: none;
              li {
                margin: 2px 0;
                font-size: 14px;
              }
              &--item {
                display: inline-block;
                width: 35%;
              }
            }
          }
        }
        .bopis-buttons__section {
          text-align: center;
          padding: 0 15px;
          .bopis-result__button {
            width: 50%;
            float: #{$ldirection};
            font-size: 15px;
            padding: 7px 35px;
            margin: auto;
            text-transform: uppercase;
            display: block;
            text-decoration: none;
            position: relative;
            top: 1px;
            background: $white;
            color: $color-off-black;
            border: 1px solid $color-off-black;
          }
          .bopis-result__button.active {
            background-color: $color-off-black;
            color: $white;
          }
        }
      }
      .bopis-map-container {
        top: 20px;
        padding: 0 20px;
        @include breakpoint($bp--large-up) {
          width: 51%;
          float: #{$rdirection};
          padding: 0;
          top: 0;
        }
        .bopis-map {
          height: 400px;
          border: 1px solid $color-off-black;
          @include breakpoint($bp--large-up) {
            height: 475px;
          }
          @include breakpoint($bp--xlarge-up) {
            height: 475px;
          }
          .gm-style {
            .gm-control-active {
              min-width: 0;
            }
            .gm-style-iw {
              background: $color-off-black;
              color: $white;
            }
            .gm-style-iw-t {
              &::after {
                background-color: $color-off-black;
              }
              .gm-style-iw-d {
                overflow: auto !important;
                min-height: 110px;
                padding: 10px 20px 0 5px;
                font-family: MetaWebPro;
                font-size: 14px;
                font-weight: 100;
                text-transform: uppercase;
                line-height: 1.5;
              }
              .gm-ui-hover-effect {
                min-width: 30px;
              }
            }
            .tooltip-doorname {
              margin-bottom: 3px;
            }
            .tooltip-phone a {
              color: $white;
            }
            .tooltip-direction {
              margin-top: 3px;
              a {
                color: $white;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.bopis-overlay {
  @include breakpoint($bp--large-up) {
    .bopis-mobile {
      display: none !important;
    }
  }
  @include breakpoint($bp--medium-down) {
    .bopis-pc {
      display: none !important;
    }
  }
  #cboxLoadingOverlay,
  #cboxLoadingGraphic {
    display: none !important;
  }
  &__icon-sm {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    margin-bottom: 5px;
    img {
      vertical-align: text-top;
    }
  }
  .postmats-modal-info-overlay {
    padding: 0 20px;
    @include breakpoint($bp--large-up) {
      padding: 0;
    }
    &__header {
      font-size: 28px;
      line-height: 1;
      text-align: center;
      padding: 10px 0;
      @include breakpoint($bp--large-up) {
        background: $color-off-black;
        color: $white;
        font-weight: 300;
      }
      .postmates-icon {
        display: inline-block;
        position: relative;
        bottom: 4px;
        padding: 4px;
        & > img {
          width: 25px;
          @include breakpoint($bp--large-up) {
            width: auto;
          }
        }
      }
      br {
        @include breakpoint($bp--large-up) {
          display: none;
        }
      }
    }
    &__sub-header {
      text-align: center;
      padding-top: 40px;
      font-size: 14px;
    }
    &__steps {
      margin-top: 28px;
      @include breakpoint($bp--large-up) {
        display: flex;
        padding: 0 5%;
      }
    }
    &__step-1,
    &__step-2,
    &__step-3 {
      padding-bottom: 20px;
      @include breakpoint($bp--large-up) {
        width: 25%;
        padding: 0;
        margin: 0 auto;
      }
    }
    &__title {
      text-align: center;
      font-size: 16px;
      text-transform: uppercase;
      margin: 10px 0;
      @include breakpoint($bp--large-up) {
        margin-bottom: 15px;
      }
    }
    &__description {
      font-size: 14px;
      text-align: center;
    }
    &__icon {
      text-align: center;
      padding-bottom: 10px;
      @include breakpoint($bp--large-up) {
        padding-bottom: 30px;
      }
    }
  }
  #cboxContent {
    #cboxLoadedContent {
      padding: 30px 0 20px;
      @include breakpoint($bp--large-up) {
        padding: 0;
      }
    }
    &:not(.edit-bag-modal) {
      #cboxClose {
        top: 12px;
        #{$rdirection}: 15px;
        @include breakpoint($bp--large-up) {
          #{$ldirection}: auto;
        }
        &::before {
          font-size: 16px;
          line-height: 1;
          font-weight: bold;
        }
        .icon--remove {
          font-size: 1rem;
          font-weight: 700;
          @include breakpoint($bp--large-up) {
            font-size: 1.3rem;
          }
        }
      }
    }
    &.edit-bag-modal {
      .edit-bag-title {
        font-weight: 700;
      }
      .edit-bag-description {
        .bopis-inv-status-text {
          margin-top: 10px;
        }
      }
      #cboxClose {
        &::before {
          font-size: 20px;
          line-height: 20px;
          font-weight: bold;
        }
      }
    }
  }
}

.edit-bag {
  padding: 20px 30px;
  font-size: 14px;
  &-title {
    text-align: center;
    margin: 10px;
  }
  &-notice {
    text-align: center;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15px;
    @include breakpoint($bp--large-up) {
      flex-direction: row;
    }
  }
  &-items {
    margin-bottom: 15px;
    @include breakpoint($bp--large-up) {
      width: 49%;
    }
  }
  &-items-list {
    max-height: 190px;
    overflow: auto;
  }
  &-item {
    display: flex;
    margin-bottom: 15px;
    padding: 5px 15px;
    border: 1px solid $color-off-black;
    .bopis-inv-status-text {
      color: $color-dark-red;
      display: flex;
      align-items: flex-start;
      img {
        margin-#{$rdirection}: 5px;
      }
    }
  }
  &-address {
    margin-bottom: 15px;
    @include breakpoint($bp--large-up) {
      width: 49%;
    }
  }
  &-image {
    max-width: 75px;
    margin-#{$rdirection}: 10px;
  }
  &-description {
    flex: 1;
  }
  &-address-content {
    border: 1px solid $color-off-black;
    padding: 15px;
    height: max-content;
  }
  &-address-content-name {
    display: flex;
    justify-content: space-between;
  }
  &-options-title {
    text-transform: uppercase;
  }
  &-option-item {
    padding: 10px 0;
    position: relative;
    &::after {
      content: '';
      clear: both;
      display: table;
    }
    label {
      text-transform: none;
    }
  }
  &-option-another-store {
    width: calc(100% - 20px);
    margin-top: 10px;
    margin-#{$ldirection}: 20px;
    @include breakpoint($bp--large-up) {
      width: 400px;
    }
    .select-box__label {
      text-overflow: ellipsis;
    }
  }
  &-actions {
    text-align: center;
    margin: 10px;
    .button {
      padding: 10px 60px;
      font-weight: bold;
      width: 100%;
      margin-bottom: 10px;
      @include breakpoint($bp--large-up) {
        width: auto;
        min-width: 248px;
        margin: 0 8px;
      }
    }
  }
}

.bopis-popup-block {
  &--mobile {
    @include breakpoint($bp--large-up) {
      display: none;
    }
  }
  &--desktop {
    display: none;
    @include breakpoint($bp--large-up) {
      display: block;
    }
  }
}

.info-modal-overlay {
  padding: 0 16px;
  @include breakpoint($bp--large-up) {
    padding: 40px 40px 28px;
  }
  &__header {
    padding: 35px 0 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-family: $ano-bold_regular-font;
    margin-bottom: 8px;
    @include breakpoint($bp--large-up) {
      padding: 0;
      font-size: 28px;
    }
  }
  &__sub-header {
    text-align: center;
    font-size: 15px;
    line-height: 1.4;
  }
  &__steps {
    @include breakpoint($bp--large-up) {
      display: flex;
      justify-content: center;
      padding: 0 41px;
    }
  }
  &__step {
    padding-bottom: 20px;
    @include breakpoint($bp--large-up) {
      width: 33.333%;
      padding: 0 41px;
    }
  }
  &__step-image {
    text-align: center;
    padding: 20px 0;
  }
  &__step-number {
    padding-bottom: 9px;
    font-size: 28px;
    line-height: 1;
    font-weight: 300;
    text-align: center;
  }
  &.bopis-overlay {
    .info-modal-overlay {
      &__step-title {
        @include breakpoint($bp--large-up) {
          min-height: 40px;
        }
      }
    }
  }
  &__step-title {
    padding-bottom: 0;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-family: $ano-bold_regular-font;
    @include breakpoint($bp--large-up) {
      min-height: auto;
    }
  }
  &__step-desc {
    text-align: center;
  }
}

.replenishment__error-message {
  display: none;
  padding: 6px 0;
  color: $red;
}

.bopis-failure-popup #cboxWrapper #cboxLoadedContent {
  box-sizing: border-box;
}

.select-store-button {
  background: transparent;
  border-radius: 20px;
  border: 1px solid $color-off-black;
  padding: 5px 10px;
  position: absolute;
  #{$rdirection}: 0;
  bottom: 35px;
  &.selected,
  &:hover {
    background: $color-off-black;
    color: $color--white;
  }
}

.selection-on-pdp {
  .bopis__delivery-container {
    display: flex;
    gap: 10px;
    padding-top: 5px;
    .bopis__delivery-item {
      flex-direction: column;
      flex: 1 1 0;
    }
  }
  .bopis__delivery-item {
    .bopis__delivery-item-link {
      display: none;
    }
  }
}
