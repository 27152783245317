$view-ipad-portrait: $width-large - 1 $width-xlarge - 1; // mobile_hidden class used has max-width: 768px, hiding checnges in iPad Portrait View
/* bopis standard space */
$bp-sp: 20px;
/* bopis small space */
$bp-sp-sm: 10px;

.invis-search__container {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  .invis-result__inv-status {
    margin: 1.5em 0 0 0;
    text-transform: uppercase;
    color: $color--red;
  }
  label {
    display: block;
  }
  .no_results_found {
    @include breakpoint($bp--xlarge-up) {
      padding: 0 $bp-sp;
      width: 45%;
      float: right;
    }
  }
  .picker {
    .picker-handle {
      border: 1px solid $black;
    }
  }
  .picker-radio.picker.checked {
    .picker-flag {
      margin: 5px;
    }
  }
  .invis_header {
    font-size: 24px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: left;
    padding-right: 30px;
    @include breakpoint($bp--xlarge-up) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .invis-search__close#cboxClose {
    display: block;
    @include breakpoint($bp--large-up) {
      display: none;
    }
    @include breakpoint($bp--medium-down) {
      display: none;
    }
  }
  .search__row {
    margin-top: $bp-sp;
    flex: 1;
    overflow: auto;
  }
  .invis-results {
    float: right;
    width: 100%;
    @include breakpoint($bp--xlarge-up) {
      width: 45%;
    }
  }
  .invis-result {
    border-top: 1px solid $black;
    padding-bottom: 30px;
    padding-top: 30px;
    .invis-result-inner {
      display: flex;
      @include breakpoint($bp--medium-down) {
        flex-direction: column;
      }
    }
  }
  .invis-result.no-inventory,
  .invis-result.no-inventory a {
    color: #666666;
  }
  .invis-result.selected {
    background-color: #dbdbdb;
  }
  .invis-result__info {
    display: flex;
    flex: 1;
    padding-right: 15px;
  }
  .invis-result__store-name {
    margin-bottom: 15px;
  }
  .invis-result__directions {
    margin-top: 15px;
    margin-bottom: 0;
    margin-left: 62px;
    text-transform: uppercase;
    @include breakpoint($bp--large-down) {
      margin-top: 0;
    }
    &.bopis-checkout-directions {
      margin-left: 0;
    }
    a {
      text-transform: none;
    }
  }
  .invis-result__distance {
    margin-bottom: 0;
    font-size: 13px;
    text-transform: none;
    display: inline-block;
  }
  .invis-results__map {
    display: none;
    float: left;
    clear: both;
    width: 100%;
    height: 180px;
    &.bopis-map {
      display: block;
    }
    @include breakpoint($bp--xlarge-up) {
      display: block;
      width: 55%;
      height: 420px;
    }
    .map__root {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
  .invis-results__messages {
    text-transform: uppercase;
    color: $color--red;
    padding-left: 15px;
    width: auto;
    @include breakpoint($bp--xlarge-up) {
      max-width: 445px;
      width: 58%;
      float: right;
      padding-left: 0;
      &.no-doors-available {
        width: 100%;
        max-width: 100%;
      }
    }
    .postmates-overlay & {
      @include breakpoint($bp--xlarge-up) {
        width: 100%;
        max-width: initial;
      }
    }
  }
  .stores__container {
    height: calc(80vh - 154px);
    width: 35%;
    li {
      border-bottom: 1px solid $black;
      padding: 30px 15px 30px 15px;
    }
  }
  .invis-sku__container {
    display: flex;
    width: auto;
    text-transform: uppercase;
    @include breakpoint($bp--xlarge-up) {
      flex-direction: column;
      margin-right: 15px;
    }
  }
  .invis-sku__image {
    float: left;
    margin-right: 5px;
    max-height: 60px;
  }
  .invis-sku__name {
    display: inline-block;
    font-size: 16px;
    strong {
      font-size: 16px;
    }
  }
  .invis-sku__shadename {
    display: block;
    font-size: 16px;
  }
  .invis-sku__add-to-cart {
    @include btn;
    min-width: 100px;
    @include breakpoint($bp--xlarge-up) {
      min-width: 200px;
    }
  }
  .invis-results__list-wrapper {
    overflow: hidden;
    position: relative;
    @include breakpoint($bp--xlarge-up) {
      height: 420px;
      .bopis-overlay & {
        height: 525px;
      }
    }
    .shade-picker__controls {
      display: none;
      @include breakpoint($bp--xlarge-up) {
        display: block;
      }
    }
  }
  .invis-results__list {
    height: calc(100% - 45px);
    overflow: auto;
    @include breakpoint($bp--xlarge-up) {
      margin-top: 28px;
    }
  }
  .invis-search__row {
    clear: both;
    margin-top: $bp-sp;
    @include breakpoint($bp--xlarge-up) {
      align-items: baseline;
      display: flex;
      justify-content: space-between;
    }
  }
  .invis-form__container {
    margin-left: 15px;
    margin-right: 15px;
    width: auto;
    clear: both;
    @include breakpoint($bp--xlarge-up) {
      display: flex;
      justify-content: center;
      align-self: flex-end;
      margin-left: 0;
      min-width: 675px;
      width: 700px;
      margin-right: 0;
      text-align: right;
    }
  }
  .invis-bopis-not-available {
    padding: 15px;
    text-transform: none;
    display: inline-block;
  }
  .invis-form__input--zip {
    width: 62%;
    height: 45px;
    padding: $bp-sp 12px;
    border: 1px solid $color--gray;
    @include breakpoint($bp--xlarge-up) {
      width: 185px;
    }
    .postmates-overlay & {
      max-width: 220px;
    }
  }
  .invis-form__select--radius {
    width: 35%;
    height: 45px;
    max-width: 130px;
    float: right;
    @include breakpoint($bp--xlarge-up) {
      width: 38%;
      float: none;
    }
  }
  .invis-form__item {
    margin-right: 5px;
  }
  .invis-form__current-location {
    background: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAeAB4AAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAeAB4DASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9/CcV+bX/AAU3/wCCwHhix1vwf4V+C3ibxt4w8R+EPiJpFz44bwH4fv8AWba00aF2a/t5rq3iaB22FQ0KOXyMEAiof+Con/BTvwl8cLbTf2Zvh14i8Y6J45+KPjyw+HOu6l/wjOo6culafNcNHqL2t5c2620kjRI8SFHfIlLLnaDXyd/wV6/4Lz63/wAETvj5pX7M/wCzX8Nfh54f8M/D7SbKa+OqadPJE8lwguBHCkUsWcxujSSsWd3kfJBBJAP2F/ZR/bf+F37bfhW81b4aeLLTxAmlzfZtSsnglstR0qX/AJ53NpOiTwNwceYgzjIyK9Xr8bbn9uzRPjL+yZ8Bv+Ciei+H08A+ONK8VWngv4lw6bbtMPEGjXF4un3UBRQZLsRyC3nt+GkjwVGSpFfpx+yX+238P/22/Derar8PrvxBd2WiXK2l2dV8OahorrIy7xtS8hiZxj+JQQOmc0Acz/wUp/ZI1T9sH9ms6X4W1G30b4g+D9XsvGHgzULlN1vbazYSebbiYd4ZPmifHIWUkcivzC/4KG6R+xd/wUD8Y6Nq37Yek/FT9mb46eGrcaVrCwaZdrBrflc+VbXiWtzaX0OSxheMiZlcDBAAH7e14r+23+x//wANj+Hfh7Yf8JD/AMI5/wAIJ480fxv5n2D7Z9u+wSO/2XHmR7PM3Y8zLbcfcagD4s/Z6+DujfttXHwY+HXwb+HfiP4afsg/ATXIPFA1TXNMm0y88catauZrW3tba6AuTbCaVrie5mQGWT5VwQWr9PaKKAP/2Q==');
    background-repeat: no-repeat;
    background-position: 10px 50%;
    cursor: pointer;
    font-size: 16px;
    font-family: $ano-bold_regular-font;
    padding: 18px $bp-sp 18px 50px;
    border: 1px solid $color--gray;
    margin-right: 5px;
    display: inline-block;
    text-transform: uppercase;
    @include breakpoint($bp--xlarge-up) {
      width: 220px;
    }
  }
  .invis-form__location-or-text {
    padding: 21px 10px;
    font-size: 1.3rem;
    line-height: 1.2;
    font-family: $ano-bold_regular-font;
  }
  .invis-form__input--zip,
  .invis-form__select--radius {
    vertical-align: top;
    @include breakpoint($bp--xlarge-up) {
      width: 30%;
      height: 60px;
      max-width: 130px;
      margin-right: $bp-sp-sm;
      color: $color--gray;
      text-decoration: none;
      .bopis-overlay & {
        height: 45px;
      }
    }
  }
  .invis-loading {
    text-align: center;
  }
}

.invis-form__btn--submit {
  @include btn;
  display: block;
  width: 100%;
  max-width: 100%;
  @include breakpoint($bp--large-up) {
    display: inline-block;
    width: 200px;
  }
  .postmates-overlay & {
    max-width: 220px;
  }
  &.quicksearch-submit {
    @include breakpoint($bp--large-up) {
      width: auto;
    }
  }
  .bopis-zip-quicksearch & {
    @include breakpoint($bp--xlarge-up) {
      width: 300px;
      margin-top: 10px;
    }
  }
  @include breakpoint($bp--large-down) {
    margin-top: 10px;
  }
  &.bopis-select-store {
    width: auto;
    padding: 0 10px;
    @include breakpoint($bp--xlarge-up) {
      width: 100%;
    }
  }
}

.device-mobile .invis-start__content {
  border-top: none;
  padding-top: 0;
  .icon--geolocation {
    top: calc(50% - 10px);
  }
}

.invis-start__panel {
  clear: both;
  text-align: center;
  padding-top: 15px;
  .invis-start__msg--available {
    display: inline;
  }
  .invis-start__link--open-store {
    text-transform: none;
    border-bottom: 0.5px solid $color--black;
  }
  .invis-start__link--open-finder,
  .invis-start__link--open-results {
    display: inline-block;
    font-family: $helvetica-font;
  }
  .icon--geolocation {
    font-size: 20px;
    position: absolute;
    top: calc(50% - 7px);
    left: 15px;
  }
}

.invis-result__marker {
  height: 41px;
  width: auto;
  padding: 0 15px 0 15px;
}

.invis-search__header {
  font-size: 28px;
}

.invis-content {
  &.mobile_hidden {
    @include breakpoint($bp--medium-up) {
      display: block !important;
    }
  }
}
// infoWindow style overides
.gm-style {
  .gm-style-iw {
    background-color: $black;
    color: $white;
    padding: 15px 10px;
    padding-right: 10px !important;
    padding-bottom: 15px !important;
  }
  .gm-style-iw-d {
    overflow: auto !important;
  }
  .gm-style-iw-t {
    &::after {
      background: $black;
    }
  }
}

.spp-shipping-head {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-family: $ano-bold_regular-font;
  font-size: 16px !important;
  display: block;
  line-height: 15px !important;
  letter-spacing: 0.32px;
  @if $fonts_update == true {
    @include h5();
  }
}

.shipping-modal-link {
  text-transform: none;
}
