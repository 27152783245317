/* Mixin function for review star Background image */
@mixin pr-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 16px;
  $pr-width-value: 14px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('#{$base-theme-path}images/icons/src/stars_full_reviews_#{$pr-value}_stars.svg');
      block-size: $pr-height-value;
      inline-size: $pr-width-value;
      margin-inline-end: 4px;
    }
  }
}
/* Mixin function for review star Large Background image */
@mixin pr-large-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 17px;
  $pr-width-value: 18px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('#{$base-theme-path}images/icons/src/stars_full_reviews_#{$pr-value}_stars.svg');
      block-size: $pr-height-value;
      inline-size: $pr-width-value;
      margin-inline-end: 4px;
    }
  }
}
/* Mixin function for Rating decimal */
@mixin pr-rating-decimal {
  @include swap_direction(padding, 7px);
  display: inline-block;
  border: none;
  color: $color--black;
  font-family: $ano-bold_regular-font;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1.3;
  &:after {
    @include swap_direction(padding, 7px);
    content: '/  5';
  }
}
/* Mixin function for links Border */
@mixin pr-link-border {
  border-bottom: 1px solid $color--gray--lightest;
  text-decoration: none;
  padding-bottom: 1.8px;
}
/* Mixin function for basic font styles */
@mixin pr-reviews-base-font-style {
  @include body-text;
  color: $color--black;
}
/* Mixin function for Sign In page textboxes */
@mixin pr-signin-textbox {
  outline: 0;
  padding: 21px 12px;
  width: 100%;
  font-size: 12px;
  color: $color--black;
  font-family: $roboto-mono_regular-font;
}
/* Mixin function for SignIn page submit button */
@mixin pr-signin-submit-btn {
  @include swap_direction(padding, 0 20px);
  font-family: $ano-bold_regular-font;
  margin-top: 20px;
  color: $color--white;
  font-size: 15px;
  text-align: center;
  height: 60px;
  line-height: 64px;
  border-style: solid;
  border-color: $color--white;
  border-width: 0;
  display: inline-block;
  background-color: $color--black;
  text-transform: uppercase;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  @if $fonts_update == true {
    @include h6();
  }
}
/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-reviewdisplay,
.product-grid__content,
.write-review__details,
.product-full__review-snippet,
.esearch-product__rating,
#pr-reviewsnippet,
.search-wrapper__results {
  .p-w-r {
    /* To display a Star background Image */
    @include pr-background-image();
    @include swap_direction(margin, 0);
    a {
      color: $color--black;
      &:hover {
        color: $color--gray;
        text-decoration: underline;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    button {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    span,
    p,
    strong {
      @include pr-reviews-base-font-style();
      font-weight: normal;
    }
    .pr-rd-merchant-response-headline {
      strong {
        @include body-text--bold;
      }
    }
    .pr-filter-btn--button {
      @if $cr21 == true {
        @include primary-wrapper--pill;
        block-size: 33px;
        padding-block: 2px;
        padding-inline: 12px;
      }
    }
    .pr-cross-icon {
      line-height: 1;
      svg {
        vertical-align: unset;
        @if $cr21 == true {
          display: none;
        }
      }
      @if $cr21 == true {
        position: relative;
        &::before {
          content: '';
          inline-size: 12px;
          block-size: 12px;
          background: url('#{$base-theme-path}svg-icons/src/icon--close-thick.svg')
            center
            center/cover
            no-repeat;
          position: absolute;
          inset-inline-start: 2px;
          inset-block-start: 1px;
        }
      }
    }
    .pr {
      &-textinput,
      &-textarea {
        @include pr-reviews-base-font-style();
        &::placeholder {
          font-size: 12px;
          font-family: $roboto-mono_regular-font;
          color: $color-grey-lowcontrast;
        }
      }
      &-alert-container,
      &-header-product-img,
      &-header-required,
      &-logo-container,
      &-header-product-name,
      &-review-snapshot-header-intro,
      &-snippet-rating-decimal,
      &-snippet-reco-to-friend,
      &-verified_reviewer {
        display: none;
      }
      &-table-cell {
        @include swap_direction(padding, 0);
      }
      &-helper-text {
        color: $color--gray;
      }
      &-clear-all-radios {
        color: $color--black;
        letter-spacing: 0;
        &:hover {
          color: $color--black;
          text-decoration: underline;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &-header-table {
        .pr-header-title {
          text-transform: uppercase;
          font-weight: bold;
          clear: both;
        }
      }
      &-review-display {
        dt,
        dd,
        dl {
          @include pr-reviews-base-font-style();
        }
      }
    }
  }
}
/* MPP Page */
.mpp-custom,
.grid--mpp,
.collection-products__list,
.artistry--artist,
.artist--products__list,
.artist--products carousel,
.artistry-video-player__products {
  .product__header {
    .product-full__review-snippet,
    .esearch-product__rating {
      @include breakpoint($bp--medium-down) {
        position: relative;
        margin-bottom: 16px;
      }
      margin-bottom: 0;
      text-transform: uppercase;
      position: absolute;
      #{$rdirection}: 0;
      top: 0;
      line-height: 1;
      .pr-snippet {
        @include breakpoint($bp--medium-down) {
          margin-#{$ldirection}: 0px;
        }
        .pr-category-snippet__total {
          display: none;
        }
        @include pr-background-image();
      }
      .pr-no-reviews {
        .pr-snippet {
          display: none;
        }
      }
    }
  }
}
/* SPP Page */
.product-full__review-snippet,
.esearch-product__rating {
  @include breakpoint($bp--medium-down) {
    position: absolute;
    top: 17px;
    #{$rdirection}: 9px;
    width: 90px;
  }
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-stars-container {
          &:before {
            @include breakpoint($bp--medium-down) {
              content: '';
            }
            content: 'Overall rating: ';
            vertical-align: sub;
            font-size: 12px;
            text-transform: capitalize;
            font-family: $roboto-mono_regular-font;
          }
        }
      }
      .pr-snippet-stars-reco-reco {
        display: none;
      }
      .pr-snippet {
        .pr-snippet-read-and-write {
          @include breakpoint($bp--medium-down) {
            display: none;
          }
          .pr-snippet-review-count {
            @include swap_direction(margin, 0 15px 0 0px);
            &:before {
              content: 'Read all ';
            }
          }
          a,
          span {
            @include pr-link-border();
            text-transform: uppercase;
            color: $color--black;
            font-size: 12px !important;
            font-family: $roboto-mono_regular-font;
            letter-spacing: normal;
          }
          a:hover {
            color: $color--gray;
          }
        }
        .pr-snippet-stars-container {
          @include pr-background-image();
          .pr-snippet-rating-decimal {
            @include pr-rating-decimal();
          }
          @include breakpoint($bp--medium-down) {
            .pr-snippet-rating-decimal {
              display: none;
            }
          }
        }
      }
    }
    .pr-no-reviews {
      .pr-snippet {
        @include breakpoint($bp--medium-down) {
          display: none;
        }
      }
      .pr-snippet-stars-reco-inline {
        .pr-snippet-read-and-write {
          margin-bottom: 5px;
          .pr-snippet-review-count {
            display: none;
          }
        }
        .pr-snippet-stars-container {
          display: none;
        }
      }
    }
  }
}

.spp_reviews {
  position: relative;
  .review-header-link {
    float: #{$rdirection};
  }
  & > p:first-child {
    color: $color--black;
    font-size: 30px;
    text-transform: uppercase;
    font-family: $ano-bold_regular-font;
    letter-spacing: normal;
    margin-top: 30px;
    margin-bottom: 0;
    text-align: center;
    @if $fonts_update == true {
      @include h6();
    }
  }
  .pr-review-heading {
    text-align: center;
    color: $color--black;
    font-size: 28px;
    font-family: $ano-bold_regular-font;
    padding-top: 20px;
    @if $fonts_update == true {
      @include h6();
    }
  }
  .review-header-link-container {
    @include breakpoint($bp--medium-down) {
      text-align: center;
    }
    margin-bottom: 1%;
    .review-header-link {
      @include breakpoint($bp--medium-down) {
        float: none;
      }
      @include swap_direction(margin, 0 2% 1% 0);
    }
  }
  .review-header-all-stars {
    .pr-category-snippet {
      &:before {
        content: 'Overall rating: ';
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        vertical-align: sub;
        font-family: $roboto-mono_regular-font;
      }
      .pr-category-snippet__total {
        display: none;
      }
      .pr-category-snippet__rating {
        &.pr-category-snippet__item {
          vertical-align: inherit;
        }
        .pr-snippet-rating-decimal {
          @include pr-rating-decimal();
          &:after {
            @include swap_direction(padding, 0);
            content: '/5';
          }
        }
      }
    }
  }
}

#pr-reviewdisplay {
  border-top: 1px solid $color--gray--lighter;
  clear: both;
  .p-w-r {
    .pr-review-snapshot-block-container {
      display: flex;
    }
    .pr-review-snapshot-faceoff {
      display: block;
    }
    .pr-review-snapshot-msq-container {
      display: none;
    }
    .pr-helpful-active {
      background: none !important;
    }
    .pr-rid-large,
    .pr-rid-small {
      display: none;
    }
    .pr-review-snapshot-header {
      .pr-review-snapshot-header-intro {
        @include swap_direction(margin, 0);
        padding-top: 35px;
        display: block;
        border: none;
        .pr-headline {
          display: block;
          font-family: $ano-bold_regular-font;
          text-align: center;
          color: $color--black;
          font-size: 30px;
          text-transform: uppercase;
          @if $fonts_update == true {
            @include h6();
          }
        }
        .pr-subheadline {
          display: none;
        }
      }
    }
    .pr-rd-reviewer-details {
      .pr-rd-details {
        margin-inline-end: 3px;
        @include breakpoint($bp--medium-up) {
          width: 100%;
        }
      }
      display: flex;
      flex-wrap: wrap;
    }
    .pr-review-snapshot-block-container {
      @include swap_direction(margin, 0 auto);
      text-align: start;
      inline-size: 100%;
      height: auto;
      flex-wrap: wrap;
      .pr-review-snapshot-block {
        inline-size: 94%;
        min-block-size: 32.6%;
        @include breakpoint($bp--medium-up) {
          inline-size: 50%;
          min-block-size: 210px;
        }
        border: none;
        .pr-review-snapshot-block-headline,
        dt {
          display: none;
        }
        .pr-review-snapshot-histogram {
          .pr-histogram-stars {
            block-size: 43px;
            &:focus {
              box-shadow: none;
            }
          }
          .pr-histogram-label,
          .pr-histogram-count {
            @include body-text--bold;
            text-transform: uppercase;
          }
          .pr-histogram-label {
            padding-inline-start: 0;
          }
          .pr-histogram-count {
            padding-inline-end: 0;
          }
        }
        &.pr-review-snapshot-block-histogram {
          inline-size: 100%;
          margin-block: 0;
          @include breakpoint($bp--medium-up) {
            inline-size: 25%;
            padding-inline-end: 48px;
          }
          .pr-histogram-cross {
            display: none;
          }
        }
        &.pr-review-snapshot-block-pros {
          @include breakpoint($bp--medium-down) {
            min-height: initial;
          }
          .pr-snapshot-tag-def {
            @include breakpoint($bp--medium-down) {
              padding-top: 0;
            }
          }
          .pr-review-snapshot-tags {
            dt {
              margin-block: 0;
              margin-inline: auto;
              padding-block: 19px 10px;
              padding-inline: 0 10px;
              display: block;
              background: transparent;
            }
            .pr-review-snapshot-block-headline {
              @include body-text--bold;
              margin-block: 0;
              margin-inline: auto;
              text-align: start;
              display: block;
              text-transform: uppercase;
              background: unset;
            }
            .pr-snapshot-tag-def-filtering {
              height: 23px;
              &:first-of-type {
                margin-top: 0;
              }
              &:hover {
                background-color: transparent;
              }
            }
            .pr-snapshot-tag-def-filtering--active {
              background-color: transparent;
              .pr-filter-btn--active {
                background-color: none;
              }
            }
            &:hover {
              background-color: transparent;
            }
            .pr-filter-btn {
              &:active {
                background-color: none;
              }
              .pr-cross-icon {
                display: none;
              }
            }
          }
          .pr-review-snapshot-msq {
            .pr-filter-btn {
              &:hover {
                span {
                  border-block-end: 1px solid $color--black;
                }
              }
              &.pr-filter-btn--active {
                span {
                  border-block-end: 1px solid $color--black;
                }
              }
            }
          }
        }
        &.pr-review-snapshot-block-cons {
          width: 100%;
          @include breakpoint($bp--medium-up) {
            width: 23%;
          }
          .pr-review-snapshot-tags {
            display: none;
          }
          .pr-review-snapshot-snippets {
            @include breakpoint($bp--medium-down) {
              padding-#{$ldirection}: 0;
            }
            padding-#{$ldirection}: 5%;
            .pr-snippet-stars-reco-stars {
              .pr-snippet-rating-decimal {
                @include title-01;
                padding-inline: 0;
                margin-inline: 0;
                color: $color--black;
                display: block;
                border: none;
                float: none;
                &:after {
                  content: ' / 5';
                }
              }
              .pr-snippet-stars-container {
                .pr-rating-stars {
                  @include pr-large-background-image();
                  margin-block-end: 16px;
                  float: unset;
                  @include breakpoint($bp--medium-up) {
                    inline-size: 100%;
                    text-align: end;
                  }
                  .pr-star-v4 {
                    &:last-of-type {
                      margin-inline-end: 0;
                    }
                  }
                }
              }
              .pr-snippet-read-and-write {
                .pr-snippet-review-count {
                  @include body-text--small-bold;
                  text-transform: uppercase;
                  margin-block-end: 16px;
                  &:before {
                    content: '(';
                  }
                  &:after {
                    content: ')';
                  }
                }
              }
            }
          }
        }
        .pr-review-snapshot-snippets {
          .pr-snippet-stars-reco-inline {
            .pr-snippet-stars-reco-reco {
              display: none;
            }
            .pr-snippet-stars-reco-stars {
              @include breakpoint($bp--medium-down) {
                float: none;
                margin-top: 0;
              }
              float: #{$ldirection};
              margin-top: 22px;
              .pr-snippet {
                .pr-snippet-read-and-write {
                  .pr-snippet-write-review-link {
                    @if $cr21 == false {
                      @include swap_direction(padding, 12px 35px);
                      @include swap_direction(margin, 0 0 0 10px);
                      background-color: $color--black;
                      color: $color--white;
                      font-family: $ano-bold_regular-font;
                      font-size: 15px !important;
                      white-space: nowrap;
                      @if $fonts_update == true {
                        @include h6();
                      }
                    } @else {
                      @include cta-primary-filled;
                    }
                  }
                }
              }
            }
          }
        }
        .pr-snapshot-tag-def {
          display: none;
        }
        .pr-msq-list {
          dd {
            padding: 0;
            display: block;
            line-height: 1;
          }
          .pr-filter-btn {
            display: inline-block;
            border: unset;
            .pr-filter-btn__child {
              padding-inline-start: 0;
              &.pr-filter-btn__buttonText,
              &.pr-filter-btn__voteCount {
                @include body-text--small-bold;
                background-color: $color--white;
              }
              &.pr-filter-btn__buttonText {
                text-transform: uppercase;
                padding-inline-end: 5px;
                white-space: normal;
              }
              &.pr-filter-btn__voteCount {
                padding: 1px 0;
                color: $color-grey-lowcontrast;
                &:before {
                  content: '(';
                }
                &:after {
                  content: ')';
                }
              }
            }
          }
          .pr-more-button {
            @include swap_direction(padding, 10px 0);
            font-family: $ano-bold_regular-font;
            font-size: 15px;
            color: $color--black;
            display: block;
            text-decoration: underline;
            @if $fonts_update == true {
              @include h6();
            }
            span:first-child,
            span:last-child {
              display: none;
            }
            span {
              font-family: $ano-bold_regular-font;
              font-size: 15px;
              color: $color--black;
              @if $fonts_update == true {
                @include h6();
              }
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-ratings-histogram {
        @include breakpoint($bp--medium-up) {
          margin-block-start: 10px;
        }
        .pr-ratings-histogram-bar {
          block-size: 4px;
        }
        .pr-ratings-histogram-barValue {
          background: $color--black;
          block-size: 4px;
        }
      }
      .pr-review-snapshot-content-block {
        margin-bottom: 0px;
      }
      .pr-snippet-reco-to-friend {
        display: block;
      }
      .pr-snippet-stars-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-inline-start: auto;
        inline-size: 100%;
        @include breakpoint($bp--medium-up) {
          align-items: flex-end;
        }
      }
      .pr-snippet-stars-reco-inline {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .pr-snippet-stars-reco-stars {
          inline-size: 100%;
          .pr-snippet {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .pr-snippet-read-and-write {
            margin-inline: auto;
            @include breakpoint($bp--medium-up) {
              margin-inline-end: unset;
            }
            .pr-snippet-review-count {
              display: block;
              text-align: center;
              @include breakpoint($bp--medium-up) {
                text-align: end;
              }
            }
            .pr-snippet-write-review-link {
              @include swap_direction(padding, 0);
              @include pr-link-border();
              font-size: 12px !important;
              font-family: $roboto-mono_regular-font;
              @if $fonts_update == true {
                @include h5();
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .pr-review-display {
      .pr-modal-container {
        @include breakpoint($bp--medium-down) {
          z-index: 2000;
        }
      }
      dl[data-reactid-powerreviews*='macselectmember'] dt,
      dl[data-reactid-powerreviews*='macproartist'] dt {
        display: none;
      }
      time {
        color: $color--gray--medium;
      }
      article.pr-review {
        @include breakpoint($bp--medium-down) {
          width: 100%;
          &:nth-child(3) {
            padding-top: 10px;
          }
        }
        border-top: none;
        width: 937px;
        & + article {
          border-top: 1px solid $color--gray--lighter;
        }
      }
      .pr-rd-def-list {
        display: table;
        width: 100%;
        dt {
          @include body-text--bold;
          display: block;
          height: auto;
          padding-block-end: 8px;
          padding-inline-end: 24px;
          text-transform: capitalize;
          display: table-cell;
          width: 39%;
          @include breakpoint($bp--large-up) {
            padding-inline-end: 72px;
          }
        }
        dd {
          @include body-text;
          width: 60%;
          display: table-cell;
          padding: 0;
          @include breakpoint($bp--large-up) {
            padding-inline-end: 72px;
            padding-block-end: 8px;
          }
        }
      }
      .pr-rd-main-header {
        position: relative;
        margin-bottom: 5px;
        display: flex;
        flex-wrap: wrap;
        @include breakpoint($bp--medium-down) {
          margin-bottom: 0;
          padding-bottom: 0;
        }
        @if $cr21 == true {
          .pr-rd-sort,
          .pr-rd-review-header-sorts {
            inline-size: 100%;
            @include breakpoint($bp--medium-up) {
              inline-size: auto;
            }
          }
          .pr-rd-sort {
            @include primary-select;
            @include breakpoint($bp--medium-up) {
              margin-inline-start: 50px;
            }
          }
          .pr-rd-review-header-sorts {
            padding-block: 0;
            .pr-rd-sort-group {
              padding: 0;
            }
          }
          #pr-rd-filter-variant-by {
            display: none;
            margin-inline-start: 0;
            @include breakpoint($bp--medium-up) {
              display: inline-block;
            }
          }
        } @else {
          #pr-rd-filter-variant-by {
            @include breakpoint($bp--medium-down) {
              width: 100%;
              font-size: 15px;
              border: 1px solid $color--gray--lighter;
              background: url(/media/dev/icons/src/arrow--down.svg) 90% 50% / 10px no-repeat;
            }
            @include swap_direction(padding, 18px 26px 18px 20px);
            border: none;
            width: auto;
            font-size: 15px;
            height: auto;
            cursor: pointer;
            text-decoration: none;
            max-width: unset;
            display: flex;
            font-family: $ano-bold_regular-font;
            border-#{$rdirection}: 1px solid $color--gray--lighter;
            position: relative;
            background: transparent;
            background: url(/media/dev/icons/src/arrow--down.svg) no-repeat;
            background-position: calc(100% - 13px) 50%;
            [dir='rtl'] & {
              background-position: 13px 50%;
            }
            background-size: 10px;
            @include breakpoint($bp--medium-up) {
              @include swap_direction(padding, 18px 26px 18px 0);
            }
            &:focus {
              box-shadow: none;
              outline: none;
            }
            optgroup {
              border: 1px solid $color--black;
            }
          }
          .pr-rd-review-header-sorts {
            #{$ldirection}: unset;
            order: 0;
            border-#{$rdirection}: 1px solid $color--very--light--grey;
            @include breakpoint($bp--medium-down) {
              width: 100%;
              position: initial;
              border-#{$ldirection}: none;
              border: 0;
              border-bottom: 1px solid $color--gray--lighter;
              padding: 15px 0;
            }
            position: relative;
            @include swap_direction(padding, 18px 0 14px 20px);
            width: auto;
            .pr-rd-sort-group {
              @include breakpoint($bp--medium-down) {
                padding: unset;
                border: 1px solid $color--gray--lighter;
              }
              width: 100%;
              float: #{$ldirection};
              font-family: $ano-bold_regular-font;
              font-size: 15px;
              padding: 0;
              &:before {
                content: ' ';
                text-transform: uppercase;
              }
              .pr-rd-sort {
                @include breakpoint($bp--medium-down) {
                  max-width: initial;
                }
                width: 100%;
                background: url(/media/dev/icons/src/arrow--down.svg) no-repeat;
                background-size: 10px;
                background-position: calc(100% - 13px) 50%;
                [dir='rtl'] & {
                  background-position: 13px 50%;
                }
                outline: none;
                border: none;
                font-size: 15px;
                font-family: $ano-bold_regular-font;
                height: auto;
                text-decoration: none;
                min-width: initial;
                cursor: pointer;
                @include swap_direction(padding, 0 20px 0);
                height: 45px;
                @include breakpoint($bp--medium-up) {
                  height: 35px;
                  @include swap_direction(padding, 0 26px 0 0);
                }
                &:focus {
                  box-shadow: none;
                }
                optgroup {
                  border: 1px solid $color--black;
                }
              }
              &:nth-child(2) {
                display: none;
              }
            }
          }
        }
        .pr-rd-review-header-contents {
          padding-block: 15px 40px;
          padding-inline: 0;
          border-top: 0;
          background-color: $color--white;
          @if $cr21 == false {
            border-top: 1px solid $color--gray--lighter;
            padding-block: 0;
          }
          border-bottom: 1px solid $color--gray--lighter;
          display: flex;
          flex-wrap: wrap;
          order: 2;
          .pr-multiselect {
            float: #{$ldirection};
            &.pr-multiselect-button-bestuses {
              @include breakpoint($bp--medium-down) {
                @include swap_direction(padding, 0);
              }
              border-#{$ldirection}: 1px solid $color--gray--lighter;
            }
            &.pr-multiselect-button-wasthisagift {
              @include breakpoint($bp--medium-down) {
                width: 108px;
                .pr-multiselect-button {
                  .pr-caret-icon {
                    #{$rdirection}: 2px;
                  }
                }
              }
            }
            .pr-multiselect-button {
              @include breakpoint($bp--medium-down) {
                @include swap_direction(padding, 10px 0 10px 8px);
                @include swap_direction(margin, 0);
                min-width: 75px;
              }
              @include swap_direction(padding, 16px 27px 16px 8px);
              border: none;
              min-width: 110px;
              span {
                @include breakpoint($bp--medium-down) {
                  font-size: 12px;
                }
                font-size: 18px;
                font-family: $ano-bold_regular-font;
                text-transform: uppercase;
                line-height: normal;
              }
              .pr-caret-icon {
                @include breakpoint($bp--medium-down) {
                  margin-top: -15px;
                  #{$rdirection}: 0;
                }
                margin-top: -17px;
                #{$rdirection}: 18px;
              }
              .pr-multiselect-count {
                @include breakpoint($bp--medium-down) {
                  display: none;
                }
                background: $color--gray--lighter;
              }
            }
          }
          .pr-multiselect-options {
            @include breakpoint($bp--medium-down) {
              top: 30px;
            }
            width: auto;
            overflow-y: auto;
            top: 48px;
          }
          .pr-rd-search-container {
            width: 100%;
            position: relative;
            order: 2;
            margin-#{$ldirection}: auto;
            @include breakpoint($bp--medium-up) {
              @if $cr21 == false {
                min-width: 25%;
              }
              width: auto;
            }
            .pr-rd-search-reviews-input {
              input {
                &:active + button,
                &:focus + button {
                  border: none;
                }
              }
              .pr-rd-search-reviews-icon-button {
                @if $cr21 == false {
                  inset-inline-start: 15px;
                  inset-block-start: 15px;
                } @else {
                  inset-inline-start: 3px;
                  inset-block-start: 3px;
                  .pr-search-icon {
                    display: none;
                  }
                  &:after {
                    content: '';
                    block-size: 20px;
                    inline-size: 20px;
                    background-image: url('#{$base-theme-path}svg-icons/src/icon--search.svg');
                    position: absolute;
                    inset-inline-start: 10px;
                    inset-block-start: 8px;
                  }
                }
                background-color: transparent;
                outline: none;
                width: 32px;
                cursor: pointer;
                border: 0;
                svg {
                  path {
                    fill: $color--black;
                  }
                }
              }
              @if $cr21 == true {
                input {
                  @include body-text--small-bold;
                  @include primary-wrapper--pill;
                  padding-block: 21px;
                  padding-inline: 36px 24px;
                  background-color: $color--black--opacity-10;
                  border: unset;
                }
              } @else {
                input {
                  @include swap_direction(padding, 30px 40px 30px 45px);
                  font-family: $ano-bold_regular-font;
                  border: none;
                  outline: none;
                  font-size: 15px;
                  border: 1px solid $color--black;
                  @if $fonts_update == true {
                    @include h6();
                  }
                  margin: 0.3em 0;
                  &::placeholder {
                    color: $color--black !important;
                    font-size: 15px;
                    font-family: $ano-bold_regular-font;
                    @if $fonts_update == true {
                      @include h6();
                    }
                  }
                  &:active + button,
                  &:focus + button {
                    border: none;
                  }
                }

                .pr-rd-search-reviews-clear-button {
                  @include breakpoint($bp--medium-down) {
                    #{$rdirection}: 12px;
                  }
                  top: 13px;
                  #{$rdirection}: 18%;
                  &:hover,
                  &:active,
                  &:focus {
                    box-shadow: none;
                    border: none;
                  }
                  .pr-cross-icon__line {
                    stroke: $color--black;
                  }
                }
              }
            }
          }
        }
        &.pr-rd-main-header-with-filters {
          .pr-rd-review-total {
            display: none;
          }
          .pr-rd-display-search-no-results {
            .pr-rd-review-total {
              display: block;
              font-size: 15px;
              padding-bottom: 0;
              font-family: $ano-bold_regular-font;
              @if $fonts_update == true {
                @include h6();
              }
            }
          }
        }
        .pr-review {
          .pr-rd-description {
            .pr-rd-right {
              .pr-rd-details {
                text-transform: capitalize;
                time {
                  color: $color--black;
                }
              }
            }
          }
        }
        .pr-rd-main-header-search-sort {
          .pr-rd-main-header-search {
            @include breakpoint($bp--medium-down) {
              @include swap_direction(padding, inherit);
              float: none;
              position: inherit;
              width: 100%;
            }
            @include swap_direction(padding, 0);
            float: #{$rdirection};
            #{$rdirection}: 0;
            top: 16px;
            height: 63px;
            position: absolute;
            width: 25%;
          }
          .pr-rd-search-container {
            .pr-rd-search-reviews-input {
              @if $cr21 == false {
                input {
                  @include swap_direction(padding, 31px 20px 32px 25px);
                  background-color: $color--gray--lighter;
                  font-family: $roboto-mono_regular-font;
                  border: none;
                  font-size: 12px;
                  &::placeholder {
                    color: $color--black !important;
                  }
                }
                .pr-rd-search-reviews-clear-button {
                  @include breakpoint($bp--medium-down) {
                    #{$rdirection}: 12px;
                  }
                  top: 13px;
                  &:hover,
                  &:active,
                  &:focus {
                    box-shadow: none;
                    border: none;
                  }
                  .pr-cross-icon__line {
                    stroke: $color--black;
                  }
                }
                .pr-rd-search-reviews-icon-button {
                  display: none;
                }
              }
            }
          }
          .pr-rd-review-header-sorts {
            @include breakpoint($bp--medium-down) {
              @include swap_direction(padding, 24px 0 0);
              width: 100%;
              float: none;
            }
            @include swap_direction(padding, 5px 0 0 25px);
            width: 25%;
            float: #{$rdirection};
            .pr-rd-sort-group {
              @include breakpoint($bp--medium-down) {
                float: #{$rdirection};
              }
              float: #{$ldirection};
              font-family: $roboto-mono_regular-font;
              font-size: 12px;
              padding: 0;
              &:before {
                content: 'sort by: ';
                text-transform: uppercase;
              }
              &:last-child {
                &:before {
                  content: '';
                }
              }
              .pr-rd-sort {
                @include breakpoint($bp--medium-down) {
                  background: url(/media/dev/icons/src/arrow--down.svg) 100% 45% / 10px no-repeat;
                  min-width: 0;
                  width: 50%;
                }
                outline: none;
                background: url(/media/dev/icons/src/arrow--down.svg) 72% 45% / 10px no-repeat;
                border: none;
                font-size: 12px;
                height: 25px;
                text-decoration: none;
                &:focus {
                  box-shadow: none;
                }
              }
            }
          }
        }
      }
      .pr-rd-main-footer,
      .pr-review {
        margin: 0 auto;
        clear: both;
        border: 0;
      }
      .pr-rd-no-reviews {
        @include swap_direction(padding, 30px 0);
        .pr-snippet-write-review-link {
          @include pr-reviews-base-font-style();
          text-transform: uppercase;
          letter-spacing: normal;
        }
      }
      .pr-rd-helpful-action-btn-group {
        margin-inline: 0;
      }
      .pr-review {
        .pr-rd-content-block {
          width: 100%;
        }
        border-top: 1px solid $color--gray--lighter;
        padding-top: 15px;
        &:first-of-type {
          border-top: 0;
        }
        a,
        span {
          @include pr-reviews-base-font-style();
          text-decoration: none;
          letter-spacing: normal;
        }
        a:hover {
          color: $color--gray;
        }
        .pr-rd-header {
          &.pr-rd-content-block {
            margin-block-end: 0;
          }
          .pr-rd-review-headline {
            @include body-text--bold;
            color: $color--black;
            text-transform: uppercase;
            margin-inline-start: 0;
            margin-block: 8px;
            @include breakpoint($bp--medium-up) {
              margin-inline-start: 10px;
              margin-block: 0;
            }
          }
          .pr-rd-star-rating {
            @include breakpoint($bp--medium-up) {
              margin-bottom: 15px;
            }
          }
        }
        .pr-rd-description {
          .pr-rd-description-text {
            @include swap_direction(padding, 0 15px 0 0);
            word-wrap: break-word;
            line-height: 1.25;
            @include breakpoint($bp--medium-up) {
              max-inline-size: calc(100% - 200px);
            }
            em {
              color: $color--black;
              font-weight: 700;
              font-family: $roboto-mono_bold-font;
            }
          }
          .pr-rd-right {
            p,
            span {
              color: $color--gray--medium;
              font-size: 12px;
              text-transform: inherit;
              line-height: 1.5;
            }
            .pr-rd-author-nickname,
            .pr-rd-author-location {
              text-transform: none;
              .pr-rd-bold {
                display: none;
              }
              span {
                &.pr-bold {
                  text-transform: Capitalize;
                }
              }
            }
            .pr-rd-details {
              .pr-rd-bold {
                display: none;
              }
            }
          }
        }
        .pr-accordion {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          &.pr-accordion-exapanded {
            .pr-accordion-btn {
              order: 2;
            }
            .pr-accordion-content {
              display: flex;
              margin-block: 0 8px;
            }
          }
          .pr-accordion-btn {
            span {
              @include body-text--small-bold;
              text-transform: uppercase;
              border-block-end: 1px solid $color--black--opacity-30;
              padding-inline-end: 0;
            }
            .pr-caret-icon {
              display: none;
            }
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
          .pr-accordion-content {
            display: none;
            inline-size: 100%;
            dl {
              &.pr-rd-review-tag {
                display: none;
                &:last-child {
                  display: block;
                }
                dt,
                dd {
                  @include swap_direction(padding, 0px 0 10px 0);
                  background: transparent;
                }
              }
            }
            .pr-rd-content-block {
              width: 100%;
              margin-bottom: 0;
            }
            .pr-rd-tags {
              dl[data-reactid-powerreviews$='$tag-groups-pros'],
              dl[data-reactid-powerreviews$='$tag-groups-bestuses'] {
                display: none;
              }
            }
            .pr-rd-subratings {
              dl[data-reactid-powerreviews$='$msq-wasthisagift'] {
                display: none;
              }
            }
          }
        }
        .pr-rd-images {
          margin-bottom: 0px;
          .pr-media-modal {
            top: 55px;
            .button__close {
              top: 135px;
              #{$rdirection}: 15px;
            }
            .carousel__footer {
              bottom: 30px;
              .pr-rid-stars {
                svg {
                  vertical-align: initial;
                }
              }
            }
          }
        }
        .pr-modal-content {
          .pr-flag-review-btn {
            color: $color--white;
            font-size: 12px;
            font-family: $ano-bold_regular-font;
            text-transform: uppercase;
            border-radius: 0;
            @if $fonts_update == true {
              @include h6();
            }
            &.pr-flag-review-btn-cancel {
              background-color: $color--gray--lighter;
              &:hover {
                background-color: $color--gray--lighter;
              }
            }
          }
          .pr-flag-review-btn-submit {
            background-color: $color--black;
          }
          .pr-flag-review-btn:hover {
            background-color: $color--black;
            color: $color--white;
          }
          .pr-flag-review-label {
            @include pr-reviews-base-font-style();
          }
        }
        .pr-rd-footer {
          padding-block: 15px;

          .pr-rd-bottomline {
            margin-block-end: 24px;
            .pr-rd-bold {
              display: none;
            }
            span {
              @include body-text--bold;
              color: $color--black;
            }
          }
          .pr-rd-flag-review-container {
            display: inline-flex;
            align-items: center;
            .pr-rd-flag-review-btn {
              @include body-text--small-bold;
              block-size: 16px;
              display: flex;
              border-block-end: 1px solid $color--black--opacity-30;
              color: $color--black;
              text-decoration: none;
              text-transform: uppercase;
            }
            a {
              @include breakpoint($bp--medium-down) {
                margin-#{$ldirection}: 8px;
              }
              @include breakpoint(($bp--medium-down) (orientation portrait)) {
                margin-#{$ldirection}: 0px;
              }
              @include breakpoint(($bp--xxxsmall) (orientation portrait)) {
                margin-#{$ldirection}: 0px;
                padding-#{$ldirection}: 0px;
              }
              @include pr-link-border();
              color: $color--gray--medium;
              text-transform: uppercase;
            }
            .pr-flag-icon {
              @include breakpoint($bp--medium-down) {
                vertical-align: middle;
                margin-top: -8px;
              }
              vertical-align: super;
            }
            .pr-flag-review-thankyou {
              color: $color--black;
            }
          }
          .pr-rd-helpful-text {
            @include body-text;
            margin-inline-end: 8px;
            color: $color--black;
            @include breakpoint($bp--medium-up) {
              margin-inline-end: 24px;
            }
          }
          .pr-rd-helpful-action {
            @include breakpoint($bp--medium-down) {
              margin-bottom: 14px;
              margin-#{$rdirection}: 0px;
            }
            @include breakpoint(($bp--medium-down) (orientation portrait)) {
              margin-bottom: 0px;
            }
            @include breakpoint(($bp--xxxsmall) (orientation portrait)) {
              margin-bottom: 0;
            }
            color: $color--gray--medium;
            margin-#{$rdirection}: 10px;
            &-group {
              display: flex;
              flex-wrap: wrap;
            }
          }
          .pr-helpful {
            &-btn {
              border: none;
              background: none;
              text-transform: capitalize;
              vertical-align: top;
              margin: 0;
              padding: 0;
              .pr-thumbs-icon {
                display: none;
              }
            }
            &-yes,
            &-no {
              margin-inline-end: 8px;
              @include breakpoint($bp--medium-up) {
                margin-inline-end: 24px;
              }
            }
            &-yes {
              .pr-helpful-count {
                &:before {
                  content: '#{$reviews_helpful_yes} (';
                  visibility: visible;
                  text-transform: capitalize;
                }
                &:after {
                  content: ')';
                }
              }
            }
            &-no {
              .pr-helpful-count {
                &:before {
                  content: '#{$reviews_helpful_no} (';
                  visibility: visible;
                  text-transform: capitalize;
                }
                &:after {
                  content: ')';
                }
              }
            }
          }
        }
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          padding: 10px 0;
          border-block-end: 0;
          inline-size: 100%;
          border-block-start: 1px solid $color--gray--lighter;
          display: flex;
          flex-direction: column;
          @include breakpoint($bp--medium-up) {
            border-block-end: 1px solid $color--gray--lighter;
            flex-direction: row;
          }
          .pr-rd-to-top {
            @include body-text--small-bold;
            line-height: get-line-height(12px, 24px);
            padding-inline-end: 0;
            text-align: center;
            text-transform: none;
            margin-inline: auto;
            block-size: fit-content;
            padding-block-start: 2px;
            display: flex;

            @if $cr21 == true {
              @include cta-secondary;
              border-bottom-color: $color--black--opacity-30 !important;
              text-transform: uppercase;
              text-decoration: none;
            }
            &:hover {
              color: $color--black;
            }
          }
          .pr-rd-review-position {
            margin-block-end: 24px;
            @include breakpoint($bp--medium-up) {
              margin-block: 0;
            }
          }
          .pr-rd-pagination {
            border: none;
            padding: 0;
            margin-block: 24px;
            @include breakpoint($bp--medium-up) {
              margin-block: 0;
            }
            .pr-rd-pagination-btn {
              @if $cr21 == true {
                @include cta-secondary;
                padding-inline-start: 0;
                border-bottom-color: $color--black--opacity-30 !important;
                &:hover {
                  text-decoration: none;
                }
                span {
                  display: none;
                }
              } @else {
                margin-top: 5px;
                color: $color--black;
                font-size: 12px;
              }
            }
          }
        }
      }
      &.pr-rd-display-desktop,
      &.pr-rd-display-tablet {
        .pr-rd-description {
          .pr-rd-side-content-block {
            #{$ldirection}: unset;
            inset-inline-end: 0;
            inset-block-start: 40px;
            z-index: 1;
          }
        }
        .pr-rd-image-container {
          @include swap_direction(margin, 0 10px 15px 0);
        }
      }
    }
    .pr-rd-review-product {
      margin-bottom: 15px;
    }
  }
}
/* WAR form */
#power_review_container {
  .write-review__container {
    @include breakpoint($bp--medium-down) {
      @include swap_direction(padding, 20px);
    }
    @include swap_direction(margin, 0 auto);
    @include swap_direction(padding, 0px 45px);
    max-width: 1280px;
    text-align: center;
    .write-review__image {
      @include breakpoint($bp--medium-down) {
        float: none;
        width: 100%;
      }
      float: #{$ldirection};
      width: 50%;
      text-align: center;
      .product-image-link {
        border-bottom: none;
      }
    }
    .write-review__details {
      @include breakpoint($bp--medium-down) {
        float: none;
        width: 100%;
      }
      float: #{$rdirection};
      width: 50%;
      clear: #{$rdirection};
      h3 {
        font-size: 12px;
        color: $color--black;
        font-family: $roboto-mono_regular-font;
        text-align: #{$ldirection};
        text-transform: none;
        padding-bottom: 20px;
        border-bottom: 1px solid $color--gray--lighter;
      }
      .pr-war-heading {
        font-size: 18px;
        color: $color--black;
        text-transform: uppercase;
        font-family: $ano-bold_regular-font;
        line-height: normal;
        text-align: #{$ldirection};
        margin-bottom: 15px;
        padding-top: 40px;
        @if $fonts_update == true {
          @include h6();
          font-size: 18px;
        }
      }
    }
    .write-review__product-name {
      font-size: 30px;
      color: $color--black;
      text-transform: uppercase;
      font-family: $ano-bold_regular-font;
      line-height: normal;
      text-align: #{$ldirection};
      padding-bottom: 10px;
      @if $fonts_update == true {
        @include h6();
        font-size: 30px;
      }
    }
  }
  .sign-in-page {
    @include breakpoint($bp--medium-down) {
      overflow: hidden;
      padding: 30px;
      padding-top: 0;
    }
    @include swap_direction(padding, 0px);
    @include swap_direction(margin, 0 auto);
    overflow: hidden;
    max-width: 1024px;
  }
  .pr_prod_img {
    @include breakpoint($bp--medium-down) {
      width: 100%;
      float: none;
      text-align: center;
    }
    width: 50%;
    float: #{$ldirection};
  }
  .email_check,
  .full_sign_in {
    @include breakpoint($bp--medium-down) {
      width: 100%;
      float: none;
    }
    width: 50%;
    float: #{$ldirection};
    .row {
      margin: 10px 0;
    }
    input.btn-pr {
      @include pr-signin-submit-btn;
    }
  }
  .email_check {
    h2 {
      font-size: 18px;
      color: $color--black;
      font-family: $ano-bold_regular-font;
      text-transform: uppercase;
      margin: 40px 0;
      margin-bottom: 5px;
      line-height: 0.875;
      font-weight: normal;
      letter-spacing: 0;
    }
    h3 {
      font-size: 12px;
      color: $color--black;
      font-family: $roboto-mono_regular-font;
      margin-bottom: 25px;
      text-transform: none;
      line-height: 1.22222;
      padding-top: 10px;
    }
    .pr_email {
      @include pr-signin-textbox;
    }
    input.btn-pr {
      width: 228px;
    }
  }
  .full_sign_in {
    h2 {
      &:first-child {
        @include swap_direction(margin, 40px 0);
        font-size: 18px;
        color: $color--black;
        font-family: $ano-bold_regular-font;
        line-height: 0.875;
        letter-spacing: 0;
        font-weight: 400;
        text-transform: uppercase;
      }
      &:nth-child(2) {
        font-size: 12px;
        color: $color--black;
        font-family: $roboto-mono_regular-font;
        line-height: 1.22222;
        letter-spacing: normal;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 0.25em;
        margin-top: 0.25em;
      }
    }
    .pr_row_email,
    .pr-row_password {
      .form-text {
        @include pr-signin-textbox;
      }
      div {
        margin-top: 8px;
      }
    }
  }
}

.write_a_review__container {
  padding-bottom: 25px;
  .pr-shade-label {
    display: block;
    text-align: #{$ldirection};
    padding-top: 20px;
    padding-bottom: 5px;
  }
  #variant-selector {
    @include breakpoint($bp--medium-down) {
      width: 100%;
    }
    background: url(/media/dev/icons/src/arrow--down.svg) 96% 45% / 10px no-repeat;
    line-height: normal;
    width: 50%;
    clear: both;
    float: #{$ldirection};
    height: 41px;
    margin-bottom: 20px;
    text-decoration: none;
    &:focus {
      border: 1px solid $color--black;
      outline: none;
    }
  }
  #pr-write {
    #pr-alert {
      display: block;
    }
    .pr-war {
      .pr-header {
        border: none;
        .pr-header-title {
          display: none;
        }
      }
      #pr-war-form {
        .pr-pros-form-group,
        .pr-wasthisagift-form-group,
        .pr-iamamacselectmember-form-group,
        .pr-iamamacproartist-form-group,
        .pr-loyaltymember-form-group,
        .pr-wheredidyoupurchasethisproductmy-form-group,
        .pr-loyaltymember2-form-group,
        .pr-iamamacproartist2-form-group,
        .pr-iamamacselectmember2-form-group {
          display: none;
        }
        .pr-media_image-form-group,
        .pr-media_videourl-form-group {
          display: block;
        }
        .form-group {
          @include breakpoint($bp--medium-down) {
            margin-bottom: 15px;
          }
          select {
            @include breakpoint($bp--medium-down) {
              max-width: 100%;
            }
            background: url(/media/dev/icons/src/arrow--down.svg) 96% 45% / 10px no-repeat;
            border-radius: 0;
            text-decoration: none;
            font-size: 12px;
            text-transform: none;
            &:focus {
              border: 1px solid $color--black;
              outline: none;
              box-shadow: none;
            }
            option {
              text-transform: none;
            }
          }
          #pr-wasthisagift {
            .btn-toggle {
              .pr-btn {
                display: inline;
                margin-#{$rdirection}: 4%;
              }
            }
          }
          &.pr-cons-form-group,
          &.pr-bestuses-form-group,
          &.pr-describeyourself-form-group {
            display: none;
          }
          &.pr-bottomline-form-group {
            .pr-control-label {
              vertical-align: bottom;
              margin-bottom: 0;
            }
          }
          &.pr-rating-form-group {
            @include breakpoint($bp--medium-down) {
              margin-bottom: 20px;
            }
            margin-bottom: 5px;
            span {
              font-family: $ano-bold_regular-font;
              font-size: 18px;
            }
          }
          .pr-control-label {
            span {
              text-transform: inherit;
            }
          }
          .pr-helper-text,
          .pr-clear-all-radios {
            display: none;
          }
          .pr-clear-all-radios,
          .pr-helper-text {
            color: $color--black;
            font-family: $roboto-mono_regular-font;
            font-size: 12px;
          }
          .btn-toggle {
            @include breakpoint($bp--medium-down) {
              width: 100%;
            }
            width: auto;
            .pr-btn {
              @if $cr21 == true {
                @include primary-wrapper--pill;
                line-height: get-line-height(12px, 24px);
              } @else {
                @include breakpoint($bp--medium-down) {
                  &:nth-child(1) {
                    margin-#{$ldirection}: 0;
                  }
                  line-height: 2;
                }
                @include swap_direction(padding, 3px 0px);
                color: $color--black;
                font-family: $roboto-mono_regular-font;
                font-size: 12px;
                border: none;
                padding: 0;
                line-height: 1;
                float: #{$ldirection};
                margin-#{$ldirection}: 20px;
                &.active {
                  &:before {
                    background-position: 0 -296px;
                  }
                  &:hover {
                    &:before {
                      background-position: 0 -296px;
                    }
                  }
                }
                &:focus,
                &:hover,
                &.active {
                  border: none;
                  outline: none;
                  background-color: transparent;
                }
                &:hover {
                  &:before {
                    background-position: 0 -247px;
                  }
                }
                &:before {
                  content: ' ';
                  background: url('/media/images/global/rating/icon-filters.png')
                    no-repeat
                    0 -198px;
                  line-height: 2;
                  padding-#{$ldirection}: 2.5em;
                  display: inline-block;
                  position: relative;
                  #{$ldirection}: 0px;
                  top: 5px;
                  height: 24px;
                  margin-#{$rdirection}: 2px;
                }
              }
            }
          }
          .pr-clear-all-radios {
            @include pr-link-border();
            text-transform: uppercase;
            text-decoration: none;
            &:hover {
              color: $color-grey-lowcontrast;
            }
          }
          .pr-btn-add-tag {
            a {
              @include breakpoint($bp--medium-down) {
                width: 100%;
              }
              color: $color--white;
              background: $color--black;
              font-family: $roboto-mono_regular-font;
              font-size: 12px;
              text-decoration: none;
              &:after {
                color: $color--white;
              }
              &::after {
                content: '';
              }
            }
          }
          .tag-container {
            .pr-btn-add-tag-submit {
              color: $color--white;
              background: $color--black;
              border: 1px solid $color--black;
              div {
                font-size: 12px;
              }
            }
            .pr-label-control {
              &.checked {
                color: $color--white;
                background: $color--black;
                font-family: $roboto-mono_regular-font;
                font-size: 12px;
                span {
                  color: $color--white;
                  text-transform: uppercase;
                }
              }
              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
          }
          .pr-btn-fileinput {
            @include breakpoint($bp--medium-down) {
              width: 100%;
              display: block;
            }
            min-width: 132px;
            background: $color--black;
            color: $color--white;
            font-family: $roboto-mono_regular-font;
            font-size: 12px;
            span {
              color: $color--white;
              font-family: $roboto-mono_regular-font;
              font-size: 12px;
              &:after {
                color: $color--white;
              }
              &::after {
                content: '';
              }
            }
          }
          .pr-media-preview {
            input.pr-textinput {
              display: none;
            }
            .pr-loading-indicator {
              @include swap_direction(margin, 15px 10px);
            }
            .pr-btn-danger {
              @include swap_direction(padding, 8px 12px);
              background: $color--black;
              border: 1px solid $color--black;
            }
          }
          &.pr-has-error {
            .pr-control-label {
              span {
                color: $color--red;
              }
            }
            .form-control {
              border-color: $color--red;
            }
          }
          input,
          textarea {
            @include pr-reviews-base-font-style();
            border-radius: 0;
            &:focus {
              border: 1px solid $color--black;
              outline: none;
              box-shadow: none;
            }
          }
          input {
            pr-textinput {
              height: 32px !important;
            }
          }
          @include pr-large-background-image();

          &.pr-name-form-group,
          &.pr-location-form-group,
          &.pr-headline-form-group,
          &.pr-comments-form-group {
            label {
              display: none;
            }
          }
          #pr-loyaltymember {
            .btn-toggle {
              .pr-btn {
                &:nth-child(1) {
                  margin-#{$ldirection}: 0px;
                }
              }
            }
          }
        }
      }
      .pr-submit {
        .pr-footer {
          .pr-subscript {
            @include swap_direction(margin, 10px 0 30px 0);
            @include pr-reviews-base-font-style();
            text-transform: none;
            display: block;
            float: none;
            line-height: 25px;
            text-align: #{$ldirection};
            width: auto;
            a {
              @include pr-link-border();
              text-transform: none;
              font-size: 12px;
              &:hover {
                @include pr-link-border();
              }
            }
          }
          .pr-accessible-btn {
            @include breakpoint($bp--medium-down) {
              width: 100%;
              display: block;
            }
            @include swap_direction(padding, 20px);
            display: inline-block;
            zoom: 1;
            background: $color--black;
            border-style: solid;
            border-width: 1px;
            border-color: $color--black;
            overflow: visible;
            vertical-align: top;
            cursor: pointer;
            color: $color--white;
            font-family: $ano-bold_regular-font;
            font-size: 15px;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            width: 65%;
            margin-bottom: 15px;
            font-weight: normal;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    /* Thank you page */
    .thank-you-page {
      span,
      p,
      a,
      h3 {
        @include pr-reviews-base-font-style();
      }
      #loyaltymember2,
      #iamamacproartist2,
      #iamamacselectmember2 {
        display: none;
      }
      h3.subtitle {
        text-transform: none;
      }
      h5 {
        a {
          text-transform: none;
          text-decoration: underline;
        }
      }
      .pr-row {
        h2.headline,
        h1.title {
          color: $color--black;
          font-family: $ano-bold_regular-font;
          font-size: 18px;
          font-weight: normal;
        }
        &.review-preview {
          .pr-rating-number {
            display: none;
          }
          .pr-rating-date {
            border: none;
          }
          #war-ty {
            &-pros,
            &-cons {
              display: none;
            }
          }
          .pr-attribute-header {
            background-color: transparent;
            color: $color--black;
            font-size: 12px;
            font-family: $roboto-mono_bold-font;
            padding-#{$ldirection}: 0px;
          }
          .pr-attribute-list {
            li {
              @include pr-reviews-base-font-style();
              padding-#{$ldirection}: 0px;
            }
          }
          .col-sm-12 {
            .pr-text {
              width: 100%;
              .pr-dark {
                font-family: $ano-bold_regular-font;
                text-transform: uppercase;
                font-size: 16px;
                padding-bottom: 25px;
                font-weight: bold;
                width: 55%;
                padding-#{$rdirection}: 5%;
                display: inline-block;
                vertical-align: top;
                color: $color--black;
              }
              span {
                width: 40%;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.product__footer {
  .pr-snippet-stars-reco-inline {
    .pr-snippet-stars-reco-stars {
      .pr-accessible-text {
        display: none;
      }
      .pr-snippet-stars-container {
        &:before {
          @include breakpoint($bp--medium-down) {
            content: '';
          }
          content: 'Overall rating: ';
          vertical-align: middle;
          font-size: 12px;
          text-transform: capitalize;
          font-family: $roboto-mono_regular-font;
        }
        .pr-snippet-stars-png {
          @include pr-background-image();
          display: inline-block;
          vertical-align: middle;
          .pr-rating-stars {
            display: inline-block;
          }
          .pr-star-v4 {
            display: inline-block;
            height: 19px;
            width: 16px;
          }
        }
        .pr-snippet-rating-decimal {
          display: none;
          padding-inline: 0;
          @include pr-rating-decimal();
          @include breakpoint($bp--medium-up) {
            display: flex;
          }
        }
      }
      .pr-snippet-read-and-write {
        @include breakpoint($bp--medium-down) {
          display: none;
        }
        margin-bottom: 15px;
        .pr-snippet-review-count {
          @include swap_direction(margin, 0 15px 0 0);
          &:hover {
            color: $color--gray;
          }
          text-transform: uppercase;
          color: $color--black;
          font-size: 12px !important;
          border-bottom: 1px solid $color--gray--lighter;
          font-family: $roboto-mono_regular-font;
          letter-spacing: normal;
          cursor: pointer;
          &:before {
            content: 'Read all ';
          }
        }
      }
    }
    .pr-snippet-stars-reco-reco {
      display: none;
    }
  }
}

.collection-products__list {
  .product__header {
    .product-full__review-snippet {
      @include breakpoint($bp--medium-down) {
        margin-bottom: 10px;
      }
    }
  }
}

.artistry-video-player__products {
  .product__header {
    .product-full__review-snippet {
      position: initial;
      margin-bottom: 16px;
      .pr-snippet {
        margin: initial;
      }
    }
  }
}

.artistry--artist {
  .product--teaser--tiny {
    .product__detail {
      @include breakpoint($bp--medium-down) {
        min-height: 225px;
      }
    }
    .product__header {
      .product-full__review-snippet {
        position: initial;
        margin-bottom: 16px;
        .pr-snippet {
          margin: initial;
        }
      }
    }
  }
}
/* Ask & Answer implementation */

#pr-questionsnippet {
  .p-w-r {
    @include swap_direction(margin, 0);
    .pr-snippet-qa {
      @include swap_direction(padding, 10px 15px 0);
      @include breakpoint($width-large) {
        @include swap_direction(padding, 0);
      }
      .pr-ask-question-link {
        color: $color--black;
        margin-top: 0px;
        font-family: $roboto-mono_regular-font;
        border-bottom: 1px solid $color--gray--lighter;
        display: none;
        &:hover {
          text-decoration: unset;
          color: $color--gray;
        }
        @include breakpoint($width-large) {
          display: inline-block;
        }
      }
      .pr-ask-question-cta,
      .pr-ask-question-subtext {
        display: none;
      }
      .pr-snippet-qa-metrics {
        .pr-qa-large {
          display: none;
        }
        .pr-answered-question-circle {
          display: none;
        }
        .pr-snippet-qa-answer-link {
          display: none;
          font-family: $roboto-mono_regular-font;
          color: $color--black;
          font-weight: bolder;
          @include breakpoint($bp--medium-up) {
            display: inline-block;
          }
        }
      }
    }
  }
}

#pr-questiondisplay {
  .pr-qa-pro-img {
    @include breakpoint($width-large) {
      float: #{$ldirection};
      z-index: 9;
      position: sticky;
      width: 48%;
      padding-#{$ldirection}: 10%;
      display: block;
      margin-bottom: 30px;
      .ask_product_image {
        float: #{$rdirection};
      }
    }
    display: none;
  }
  .p-w-r {
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0);
    .pr-control-label {
      display: none;
    }
    .pr-waqwaa {
      .pr-helper-text,
      .pr-waq-legal-text {
        @include body-text--small;
        padding-bottom: 20px;
        color: $color--black;
      }
      .pr-helper-text {
        @include breakpoint($bp--large-up) {
          padding-bottom: 8px;
          margin-block: 0;
        }
      }
      .pr-waq-legal-text {
        span {
          @include body-text--small;
          color: $color--black;
          a {
            color: $color--black;
            text-transform: capitalize;
          }
        }
      }
      .form-group {
        margin-block-end: 0;
        .pr-form-control-error-wrapper {
          svg {
            inset-block-start: 20px;
            inset-block-end: 10px;
          }
        }
        &.pr-waq-legal-text {
          margin-bottom: 10px;
        }
        #questionCategory {
          .pr-btn {
            @include body-text--small-bold;
            text-align: center;
            line-height: get-line-height(12px, 24px);
            margin-bottom: 10px;
            text-transform: uppercase;
            @if $cr21 == true {
              @include primary-wrapper--pill;
            }
            else {
              border: 1px solid $color--gray--lightest;
            }
            @include breakpoint($bp--large-up) {
              margin-bottom: 0px;
              font-size: 12px;
              margin-bottom: 8px;
            }
            &:nth-child(1) {
              border-top: 1px solid $color--gray--lightest;
            }

            &.active {
              background-color: $color--black;
              &:hover {
                color: $color--white;
              }
            }
          }
          .pr-control-label {
            @include breakpoint($bp--large-up) {
              text-transform: uppercase;
            }
            display: block;
            font-family: $tstar-bold-font;
            .pr-required-indicator {
              color: $color--black;
            }
          }
        }
        .form-control {
          @include body-text--small;
          background: $color--white;
          border-radius: initial;
          border-color: $color-grey-lowcontrast;
          box-shadow: initial;
          margin-block-end: 16px;
          @include breakpoint($bp--large-up) {
            margin-block-end: 8px;
          }
          &::placeholder {
            @include body-text--small;
            color: $color-grey-lowcontrast;
          }
          @if $cr21 == true {
            border-radius: 25px;
          }
        }
        &.pr-has-error {
          .form-control {
            border-color: $color--red;
            &::placeholder {
              color: $color--red;
            }
          }
        }
        &.questionText-form-group,
        answerText-form-group {
          .pr-control-label {
            display: block;
            .pr-required-indicator {
              color: $color--black;
            }
          }
        }
      }
      .pr-alert-message {
        @include body-text;
        @include breakpoint($bp--large-up) {
          inline-size: 100%;
        }
        .pr-alert-container {
          max-inline-size: unset;
        }
        .pr-alert_heading {
          @include body-text;
        }
        .pr-form-control-error-icon {
          @if $cr21 == true {
            inset-block-start: 20px;
          }
        }
      }
      .pr-question-form {
        @include breakpoint($bp--large-up) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
          gap: 0px 24px;
          grid-auto-flow: row;
          grid-template-areas: 'form-start form-end';
          direction: $text-direction;
        }
        & > div {
          &:first-of-type {
            grid-area: form-start;
            grid-row: auto;
          }
          &:last-of-type {
            @include breakpoint($bp--large-up) {
              grid-area: form-end;
            }
            .pr-form-group {
              grid-row: auto;
              position: relative;
              &.questionText-form-group {
                grid-area: form-end;
                grid-row: auto;
              }
            }
            .pr-control-label {
              margin-block-end: 8px;
            }
            .authorEmail-form-group {
              margin-block-start: 32px;
            }
            .pr-submit {
              width: 100%;
              position: relative;
              grid-area: form-end;
              grid-row: auto;
            }
          }
        }
        .pr-header-required {
          display: none;
        }
      }
    }
    .pr-qa-display-ask-question {
      padding-block: 32px;
      margin-bottom: 0px;
      margin: 0 auto;
      width: 100% !important; // override important from vendor
      @include breakpoint($bp--large-up) {
        position: inherit;
        padding-inline-start: 12px;
        margin-inline: auto 0;
        float: none;
      }

      button {
        padding: 10px 100px;
        width: 100% !important; // override important from vendor
        @include breakpoint($bp--large-up) {
          width: auto !important; // override vendor style
        }
      }
      .pr-submit {
        button {
          @include breakpoint($bp--large-up) {
            width: 100% !important; // override vendor style
          }
        }
      }
      .pr-accessible-btn,
      button {
        @if $cr21 == true {
          @include cta-primary-filled;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          text-transform: uppercase;
          .pr-qa-display-link {
            @include body-text--small-bold;
            line-height: get-line-height(12px, 45px);
          }
        } @else {
          @include breakpoint($width-large) {
            width: auto !important;
            float: #{$rdirection};
            font-size: 18px;
            @if $fonts_update == true {
              font-size: 15px;
            }
          }
          @include swap_direction(padding, 15px 62px);
          @include swap_direction(margin, 0 0 25px 0);
          background-color: $color--black;
          font: 15px/16px normal;
          width: 100% !important;
          border: 0px;
          font-family: $ano-bold_regular-font;
          border-radius: 0px;
          text-transform: uppercase;
          @if $fonts_update == true {
            @include h6();
            margin: 0 0 25px;
          }
          &:hover {
            color: $color--white;
          }
          &:focus {
            color: $color--white;
          }
        }
        .pr-cross-icon {
          display: none;
        }
        .pr-qa-display-text {
          color: $color--white;
          margin-inline: auto;
          text-transform: uppercase;
        }
      }
      .p-w-r {
        .pr-waqwaa {
          max-inline-size: unset;
          clear: both;
        }
      }
    }
    .pr-qa-display {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      &.pr-qa-display-desktop {
        @include breakpoint($width-large) {
          @include swap_direction(padding, 15px 0);
        }
        padding-#{$rdirection}: 0px;
      }
      .pr-qa-display-headline {
        @include swap_direction(margin, 0);
        @include breakpoint($bp--large-up) {
          display: flex;
          position: relative;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
        }
        // no class available
        #pr-question-form-content-container {
          @include breakpoint($bp--large-up) {
            inline-size: 60%;
            margin-inline: auto;
            background: $color--white;
            z-index: 1;
          }
        }

        .pr-qa-display-thankyou-headline {
          margin-top: 40px;
          font-family: $tstar-font;
          font-size: 22px;
          text-align: center;
          float: #{$ldirection};
        }
        .pr-qa-display-search {
          @include breakpoint($bp--large-up) {
            overflow: hidden;
          }
          width: 100%;
          background-color: unset;
          padding: initial;
          form {
            width: 100%;
            label {
              display: none;
              @include breakpoint($bp--large-up) {
                @include title-03;
                display: flex;
                justify-content: center;
                width: 100% !important; // override style from vendor
                padding-inline-end: 0;
                margin-block-end: 24px;
                text-transform: uppercase;
              }
            }
            .pr-qa-display-searchbar {
              block-size: 60px;
              max-inline-size: 456px;
              margin-inline: auto;
              display: block;
              .pr-qa-search-btn {
                @if $cr21 == false {
                  inline-size: 50px !important;
                  block-size: 60px !important;
                  padding-block: 10px;
                  padding-inline: 15px;
                  background-color: $color--gray--lighter;
                } @else {
                  inset-inline-start: 3px;
                  inset-block-start: 3px;
                  background-color: unset;
                  .pr-search-icon {
                    display: none;
                  }
                  &:after {
                    content: '';
                    block-size: 20px;
                    inline-size: 20px;
                    background-image: url('#{$base-theme-path}svg-icons/src/icon--search.svg');
                    position: absolute;
                    inset-inline-start: 10px;
                    inset-block-start: 3px;
                  }
                }
                inset-inline-start: 0;
                box-shadow: none;
                border-radius: 0px;
                border: 0px;
                span {
                  path {
                    fill: $color--white;
                  }
                }
              }
              &-input {
                @if $cr21 == true {
                  @include body-text--small-bold;
                  @include primary-wrapper--pill;
                  background-color: $color--black--opacity-10;
                  padding-inline-start: 36px;
                  border: unset;
                } @else {
                  @include swap_direction(padding, 10px 15px 10px 55px);
                  background-color: $color--gray--lighter;
                  border-radius: 0;
                  border: 0;
                  color: $color--white;
                  font-family: $ano-bold_regular-font;
                  height: 60px !important;
                  outline: none;
                  &.custom-outline {
                    outline: none;
                  }
                  &::placeholder {
                    color: $color--white;
                  }
                }
              }
            }
          }
        }
        .pr-qa-display-sort {
          padding-block: 0 32px;
          @include breakpoint($bp--large-up) {
            inset-inline-end: 0;
            flex: 1;
            border-bottom: 0;
            width: auto;
            margin-top: 30px;
          }
          .pr-qa-sort-group {
            width: 100%;
            @include breakpoint($width-large) {
              width: auto;
            }
            select {
              background: url(/media/dev/icons/src/arrow--down.svg) 90% 45%/10px no-repeat;
            }
            .pr-qa-sort {
              @include body-text--small-bold;
              @if $cr21 == true {
                @include primary-select;
              } @else {
                @include swap_direction(padding, 0 25px 0 0);
                border: initial;
                cursor: pointer;
                max-width: 100%;
                height: 30px;
                width: 100%;
                float: #{$rdirection};
                text-decoration: unset;
                margin-bottom: 15px;
                @include breakpoint($width-large) {
                  @include swap_direction(padding, 0 20px);
                  margin-top: 0px;
                  width: 250px;
                  margin-top: 10px;
                  font-size: 19px;
                  height: 60px;
                  background: none;
                }
              }
            }
            &:after {
              border-width: 0;
              border-style: none;
            }
            label {
              display: none;
            }
          }
          .pr-qa-sort-headline {
            @include body-text;
            margin-block-start: 0;
            color: $color--black !important;
            width: 100%;
            text-align: center;
            @include breakpoint($width-large) {
              margin-top: 30px;
              width: auto;
            }
          }
        }
        .pr-h1 {
          display: none;
        }
      }
      .pr-qa-display-question {
        width: 100%;
        float: unset;
        clear: both;
        &list {
          padding: 0;
          margin: 30px 0 0 0;
          overflow: hidden;
          border-bottom: 1px solid $color--gray--lighter;
          border-#{$ldirection}: unset;
          .pr-qa-display-item {
            margin-block-end: 16px;
            @include breakpoint($width-large) {
              margin-bottom: 30px;
              width: 100%;
            }
            .pr-qa-display-item-icon {
              background-color: $color--black;
              font-size: 16px;
              padding-top: 0;
              width: 35px;
              height: 35px;
              line-height: 31px;
              #{$ldirection}: 0;
              top: 0;
              text-indent: -9999px;
              &:after {
                @include body-text--bold;
                font-size: 24px;
                content: '?';
                position: absolute;
                inset-block-start: 0;
                inset-inline-start: 0;
                text-indent: 0;
                inline-size: 32px;
              }
            }
            .pr-qa-display-item-text {
              @include body-text--bold;
              padding-inline-start: 45px;
              text-transform: uppercase;
              color: $color--black !important;
            }
          }
          .pr-qa-display-item-details {
            display: flex;
            width: 100%;
            margin-block-end: 16px;
            @include breakpoint($bp--large-up) {
              padding-inline-start: 5px;
              width: 23%;
              position: unset;
              inset-inline-end: 0;
              inset-inline-start: unset;
              margin-block-start: 38px;
              flex-wrap: wrap;
            }
            .pr-subscript,
            time {
              @include body-text;
              inset-block-start: 0;
              padding-inline-start: 0;
              color: $color-grey-lowcontrast;
              display: block;
            }
          }
          .pr-qa-display-answer-link-container {
            @include swap_direction(margin, 0 0 25px 0);
            .pr-btn-answer {
              @include swap_direction(padding, 15px 100px);
              background: $color--black;
              text-transform: uppercase;
              font-family: $ano-bold_regular-font;
              margin-bottom: 0px;
              span {
                color: $color--white;
                &.pr-cross-icon {
                  display: none;
                }
              }
              .pr-qa-display-text {
                text-shadow: none;
                background-image: none;
              }
            }
          }
          .pr-qa-display-add-answer {
            @include breakpoint($width-large) {
              clear: both;
              width: 75%;
              float: #{$ldirection};
              margin-#{$ldirection}: 0px;
            }
            .p-w-r {
              .pr-waqwaa {
                max-width: 100%;
                .form-group {
                  margin-bottom: 20px;
                }
                .pr-answer-form {
                  .form-control {
                    &.custom-outline {
                      outline: unset;
                    }
                  }
                  .pr-submit {
                    @include breakpoint($width-large) {
                      margin-bottom: 15px;
                    }
                    .pr-accessible-btn {
                      padding: 15px 75px;
                      background: $color--black;
                      border: 0px;
                      border-radius: 0px;
                      text-transform: uppercase;
                      font: 15px/16px normal;
                      font-family: $ano-bold_regular-font;
                      color: $color--white;
                      &:hover {
                        color: $color--white;
                      }
                    }
                  }
                  .answerText-form-group {
                    .pr-control-label {
                      display: block;
                      .pr-required-indicator {
                        color: $color--black;
                      }
                    }
                  }
                }
              }
            }
          }
          .pr-qa-display-answer {
            @include breakpoint($width-large) {
              display: inline-block;
              width: 75%;
              float: #{$ldirection};
              padding-inline-start: 0;
            }
            &-by-container {
              padding-inline-start: 0;
              &-unverified {
                padding-inline-start: 3px;
              }
              &-expert {
                border-inline-start: 0;
              }
            }

            &-details {
              @include swap_direction(padding, 0 0 0 2px);
              font-family: $roboto-mono_regular-font;
              span {
                @include body-text;
                color: $color--black;
                &.pr-qa-display-answer-by {
                  margin-block-end: 16px;
                  span {
                    @include body-text--bold;
                  }
                }
              }
              .pr-helpful-voting {
                .pr-helpful-btn {
                  border: 0;
                  border-radius: 0;
                  &.pr-helpful-active {
                    background: $color--black !important;
                    .pr-thumbs-icon {
                      path {
                        fill: $color--white;
                      }
                    }
                    .pr-helpful-count {
                      color: $color--white;
                    }
                  }
                  .pr-thumbs-icon {
                    path {
                      fill: $color--white;
                      stroke: $color--black;
                      stroke-width: 10px;
                    }
                  }
                  .pr-helpful-count {
                    @include sub-title--bold;
                    color: $color--black;
                    line-height: 24px;
                    &:before {
                      content: '(';
                    }
                    &:after {
                      content: ')';
                    }
                  }
                  &.pr-helpful-yes {
                    &:active {
                      background: $color--black;
                    }
                    .pr-helpful-count {
                      line-height: 30px;
                    }
                  }
                  &.pr-helpful-no {
                    .pr-helpful-count {
                      line-height: 34px;
                    }
                  }
                }
              }
            }
            &-icon {
              display: none;
            }
          }
        }
      }
    }
  }
}

.question-processed {
  display: none;
  margin-left: 10px;
}

.tabs-nav {
  display: flex;
  justify-content: flex-start;
  @include breakpoint($bp--large-up) {
    width: 100%;
    margin-block: 0;
    justify-content: flex-end;
    margin-block-start: -40px;
  }
  li {
    @include swap_direction(margin, 0px 10px);
    @include sub-title;
    text-transform: uppercase;
    border-bottom: 1px solid $color--gray--lighter;
    position: relative;
    @include breakpoint($bp--large-up) {
      @include swap_direction(margin, 0 0 0 17px);
      @include swap_direction(padding, 0 0);
      cursor: pointer;
    }
    &:first-of-type {
      margin-inline-start: 0;
    }
    &.tab-active {
      @include sub-title--bold;
      color: $color--black;
      border-bottom: 1px solid $color--black;
    }
  }
}

#pr-waqwaa-header {
  margin-top: 45px;
}

.pr-waqwaa {
  .pr-header-table {
    .table-cell {
      display: block;
    }
  }
}

.tabs-content {
  &.tab-2 {
    margin-block-start: 30px;
    @include breakpoint($bp--large-up) {
      margin-block-start: 0;
    }
  }
  .p-w-r {
    .pr-qa-display {
      display: flex;
      flex-wrap: wrap;
      .pr-qa-display-search {
        background: none;
        .pr-qa-display-search-no-results {
          margin-block-start: 0;
          @include breakpoint($bp--large-up) {
            margin-block-start: 60px;
          }
        }
      }
      .pr-btn-ask-question:focus {
        .pr-qa-display-text {
          text-shadow: none;
          background-image: none;
        }
      }
      .pr-btn-show-more {
        color: $color--black;
        margin-#{$ldirection}: 37px;
        .pr-qa-display-text {
          color: $color--black;
          font-family: $ano-bold_regular-font;
        }
        .pr-caret-icon__line {
          stroke: $color--black;
        }
        &:hover {
          stroke: $color--black;
        }
        &focus {
          stroke: $color--black;
        }
      }
      .pr-qa-display-questionlist {
        .pr-qa-display-answer-link-container {
          .pr-btn-answer {
            padding: 13px 100px;
          }
        }
      }
      .pr-qa-display-show-more-questions {
        @if $cr21 == true {
          @include cta-secondary;

          order: 1;
          padding: 0;
          display: flex;
          margin-inline: auto;
          width: fit-content !important; // override important from vendor
          @include breakpoint($bp--large-up) {
            margin-inline-end: 0;
          }
          &.pr-qa-display-btn {
            border-block-end-color: $color--black--opacity-30;
          }
        } @else {
          margin-top: 25px;
          background: $color--black;
          font-family: $ano-bold_regular-font;
          text-transform: uppercase;
          &:hover {
            border: 1px solid $color--black;
            background: $color--white;
            .pr-caret-icon__line {
              stroke: $color--black;
            }
          }
          .pr-caret-icon__line {
            stroke: $color--white;
          }
          .pr-qa-display-btn-text {
            color: $color--white;
            span {
              color: $color--white;
            }
          }
        }

        .pr-caret-icon {
          @if $cr21 == true {
            display: none;
          }
        }
        .pr-qa-display-btn-text {
          @include cta-text;
          color: $color--black;
          text-transform: uppercase;
          span {
            @include cta-text;
            color: $color--black;
          }
          abbr[title] {
            @if $cr21 == true {
              padding-inline-start: 3px;
            }
          }
        }
      }
      .pr-control-label {
        padding-block-start: 32px;
        span {
          @include sub-title;
          color: $color--black;
          text-transform: uppercase;
        }
      }
      .btn-group {
        margin-block: 0;
      }
    }
  }
}

.p-w-r {
  .pr-waqwaa {
    #questionCategory {
      .pr-btn {
        &.active {
          background-color: $color--black;
        }
      }
    }
    .pr-accessible-btn {
      background-color: $color--black;
      border-bottom: 0;
      &:hover {
        background-color: $color--white;
        color: $color--black;
        border: 1px solid $color--black;
      }
    }
  }
}

.esearch-product__rating {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet {
        .pr-snippet-read-and-write {
          display: none;
        }
        .pr-snippet-stars-container {
          &:before {
            content: '';
          }
          .pr-snippet-rating-decimal {
            display: none;
          }
        }
      }
    }
  }
}

.search-results__products {
  .search-results__results {
    .product__rating {
      display: none;
    }
  }
}
