.enhanced-delivery-page {
  .checkout-progress-bar {
    display: none;
  }
  &.active-panel-shipping {
    .checkout-progress-bar {
      display: block;
    }
  }
  &.active-panel-shipping,
  &.active-panel-review {
    .checkout {
      .checkout-progress-bar {
        margin: 25px auto;
        max-width: 465px;
        @include breakpoint($width-xlarge) {
          margin: 43px auto;
        }
        &__list {
          list-style-type: none;
          &-item {
            color: $color--gray--dusty--disable;
            font-size: 12px;
            &::after,
            &::before {
              background: $color--gray--dusty--disable;
            }
            &::after {
              height: 2px;
              top: 5px;
            }
            &:nth-child(2) {
              &::before {
                background: $color--gray--dusty--disable;
                border: 1px solid $color--gray--dusty--disable;
                color: $color--gray--dusty--disable;
              }
            }
            &::before {
              border: 1px solid $color--gray--dusty--disable;
              color: $color--gray--dusty--disable;
              height: 12px;
              line-height: 1;
              width: 12px;
            }
            .shipping-progress-text {
              color: $color--gray--dusty--disable;
              font-family: $roboto-mono_bold-font;
              font-size: 12px;
              letter-spacing: 0.25px;
              pointer-events: none;
              position: relative;
              top: 0;
            }
            &--complete {
              &::before {
                background: $color--black;
                border: 1px solid;
                border-radius: 50%;
                color: $color--black;
                height: 12px;
                width: 12px;
              }
              a.shipping-progress-text {
                color: $color--black;
                top: 0;
              }
            }
            &--review {
              display: none;
            }
          }
        }
      }
    }
  }
  &.active-panel-review {
    .checkout {
      .checkout-progress-bar {
        display: block;
        &__list-item {
          &:nth-child(2) {
            &::before {
              background: $color--black;
              border-color: $color--black;
              border-radius: 50%;
              color: $color--black;
              height: 12px;
              width: 12px;
            }
            &::after,
            &::before {
              background: $color--black;
            }
            .shipping-progress-text {
              color: $color--black;
              pointer-events: none;
              top: 0;
            }
          }
        }
      }
    }
  }
}
