/* ==========================================================================
   Global Icons - Flags, Icon List
   ========================================================================== */

.flag-icon-ae {
  @include flag-icon(ae);
}

.flag-icon-ar {
  @include flag-icon(ar);
}

.flag-icon-at {
  @include flag-icon(at);
}

.flag-icon-au {
  @include flag-icon(au);
}

.flag-icon-be {
  @include flag-icon(be);
}

.flag-icon-br {
  @include flag-icon(br);
}

.flag-icon-ca {
  @include flag-icon(ca);
}

.flag-icon-ch {
  @include flag-icon(ch);
}

.flag-icon-cl {
  @include flag-icon(cl);
}

.flag-icon-cn {
  @include flag-icon(cn);
}

.flag-icon-cz {
  @include flag-icon(cz);
}

.flag-icon-de {
  @include flag-icon(de);
}

.flag-icon-dk {
  @include flag-icon(dk);
}

.flag-icon-es {
  @include flag-icon(es);
}

.flag-icon-fr {
  @include flag-icon(fr);
}

.flag-icon-gb {
  @include flag-icon(gb);
}

.flag-icon-gr {
  @include flag-icon(gr);
}

.flag-icon-hk {
  @include flag-icon(hk);
}

.flag-icon-hu {
  @include flag-icon(hu);
}

.flag-icon-il {
  @include flag-icon(il);
}

.flag-icon-in {
  @include flag-icon(in);
}

.flag-icon-it {
  @include flag-icon(it);
}

.flag-icon-jp {
  @include flag-icon(jp);
}

.flag-icon-kr {
  @include flag-icon(kr);
}

.flag-icon-sa {
  @include flag-icon(sa);
}

.flag-icon-me {
  @include flag-icon(me);
}

.flag-icon-mx {
  @include flag-icon(mx);
}

.flag-icon-my {
  @include flag-icon(my);
}

.flag-icon-ng {
  @include flag-icon(ng);
}

.flag-icon-nl {
  @include flag-icon(nl);
}

.flag-icon-no {
  @include flag-icon(no);
}

.flag-icon-nz {
  @include flag-icon(nz);
}

.flag-icon-pe {
  @include flag-icon(pe);
}

.flag-icon-ph {
  @include flag-icon(ph);
}

.flag-icon-pl {
  @include flag-icon(pl);
}

.flag-icon-ru {
  @include flag-icon(ru);
}

.flag-icon-se {
  @include flag-icon(se);
}

.flag-icon-sg {
  @include flag-icon(sg);
}

.flag-icon-th {
  @include flag-icon(th);
}

.flag-icon-tr {
  @include flag-icon(tr);
}

.flag-icon-tw {
  @include flag-icon(tw);
}

.flag-icon-us {
  @include flag-icon(us);
}

.flag-icon-ve {
  @include flag-icon(ve);
}

.flag-icon-vn {
  @include flag-icon(vn);
}

.flag-icon-za {
  @include flag-icon(za);
}
