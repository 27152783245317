@mixin opc_update_emea_mobile {
  // add global opc styles here

  .confirm {
    header {
      #mobile-nav,
      .header-search__icon,
      .site-header__suffix {
        display: none !important;
      }
    }
    #confirmation-page {
      header {
        .checkout-header__title {
          font-size: 18px;
        }
        .registration-panel__title {
          font-size: 3rem;
        }
      }
      .confirmation-mail-text {
        padding: 10px 20px;
      }
    }
    .site-container {
      min-height: 620px;
      .checkout__sidebar {
        padding-bottom: 2.5em;
        #need-help-panel {
          .links_list {
            list-style: none;
          }
        }
      }
      .actions {
        .print-buttons {
          display: none;
        }
      }
    }
    #footer,
    .secure_checkout,
    #links-panel {
      display: none;
    }
  }
  .viewcart {
    .site-container {
      .continue-shopping {
        display: none;
      }
      .checkout-header {
        .continue-buttons {
          display: none;
        }
      }
      .opc__footer {
        @include swap_direction(margin, 10px 0px);
      }
      #bottom-viewcart-buttons {
        display: none;
        @include breakpoint($bp--medium-up) {
          display: block;
        }
      }
    }
  }
  .viewcart,
  .checkout {
    .site-container {
      @include swap_direction(padding, 0px 0px 76px 0px);
      #order-summary-panel {
        background: $white;
        @include swap_direction(padding, 1em 0px);
        .shipmethod-panel {
          .shipmethod-panel__title {
            display: none;
          }
          form {
            p:empty {
              margin-bottom: 0px;
            }
          }
        }
        .ship-method-group {
          max-width: 100%;
          .form-item {
            margin-bottom: 0px;
          }
          select {
            margin-bottom: 10px;
          }
          .select2-container {
            border: 1px solid $color--black;
            margin-bottom: 10px;
            @include swap_direction(padding, 5px);
            .select2-arrow {
              top: 10px;
            }
          }
        }
        .total {
          margin-bottom: 10px;
          &.order-summary__total-label {
            width: 50.66667%;
          }
          &.order-summary__total-value {
            width: 49.33333%;
          }
        }
        .no-hide {
          display: none;
        }
      }
      .viewcart-panel__content {
        .cart-item {
          position: relative;
          &__price {
            font-family: $ano-bold_regular-font;
            font-size: 18px;
            @include breakpoint($bp--small-down) {
              width: 65%;
              float: $rdirection;
              margin-top: 5px;
            }
          }
          &__remove-form {
            @include breakpoint($bp--small-down) {
              width: 65%;
              float: $rdirection;
              .remove_link {
                #{$rdirection}: 0px;
                #{$ldirection}: auto;
              }
            }
          }
          &__qty {
            @include breakpoint($bp--small-down) {
              @include swap_direction(margin, 0 0 0 35%);
            }
            outline: none;
            box-shadow: none;
            border: none;
            line-height: 30px;
            select {
              padding-#{$ldirection}: 0;
              outline: none;
              &:required:valid {
                border: none;
                line-height: 30px;
              }
              &:focus {
                outline: none;
                border: none;
              }
            }
            &:focus {
              outline: none;
              border: none;
            }
          }
        }
      }
      .checkout__sidebar {
        display: block;
        @include swap_direction(padding, 0px);
        #links-panel {
          margin-top: 1em;
        }
      }
    }
    #offer-code-panel {
      #offer_code {
        .offer-code__input {
          margin-top: 10px;
          padding-top: 0;
        }
      }
    }
  }
  .checkout {
    .samples-page {
      .samples-panel {
        #sign-in-panel {
          display: block;
        }
        .checkout-header {
          border-bottom: 1px solid $border_color !important;
        }
        @include swap_direction(padding, 1em 0px 0px);
        &__continue-checkout {
          @include swap_direction(margin, 0px);
        }
        .samples {
          .sample-select-button {
            width: auto;
            background-color: #f0f0f0;
            &.selected {
              color: white;
              background-color: black;
            }
          }
        }
        .opc__footer {
          @include swap_direction(margin, 10px 0px);
        }
      }
    }
    &.active-panel-signin {
      .checkout__content {
        #sign-in-panel {
          border-bottom: none;
        }
      }
      .opc__footer {
        .secure_checkout {
          display: none;
        }
      }
      #shipping-panel,
      #review-panel,
      #guarantee-panel,
      #gift-options-panel,
      #registration-panel,
      .checkout__sidebar {
        display: none;
      }
    }
    &.active-panel-shipping {
      .checkout__content {
        #review-panel {
          display: none;
        }
        #shipping-panel {
          > header {
            text-align: center;
            margin-bottom: 10px;
          }
          #checkout_shipping_panel {
            .gift-options {
              .sub-section {
                img {
                  display: none;
                }
              }
            }
          }
          #shipmethod-panel {
            .ship-method-group-label {
              label {
                width: 80%;
              }
            }
            .ship-method-home-group {
              label {
                display: none;
              }
            }
          }
        }
      }
      .checkout__sidebar {
        #shopping-bag-wrapper-panel,
        #order-summary-panel,
        #shipmethod-panel,
        #viewcart-panel,
        #offer-code-panel {
          display: none;
        }
      }
    }
    &.active-panel-review {
      #shipping-panel {
        display: block;
        > header {
          text-align: center;
          margin-bottom: 10px;
          .user_email {
            display: none;
          }
        }
        #shipping-panel-edit {
          margin-top: 26px;
          float: $rdirection;
        }
      }
      #review-panel {
        .change-address {
          margin-top: 30px;
          float: $rdirection;
        }
        .save-address-static-btn {
          position: static;
        }
        #checkout_complete {
          .picker-checkbox {
            label {
              width: 82%;
            }
          }
        }
      }
      .checkout {
        &__sidebar {
          display: block;
          .viewcart-panel {
            .cart-item {
              &__qty {
                width: 35%;
              }
              &__total {
                text-align: $rdirection;
                display: block;
                width: 29%;
                line-height: 30px;
                @include swap_direction(margin, 0 !important);
              }
            }
          }
        }
      }
    }
    &.payment {
      .site-container {
        .checkout-header {
          display: none;
        }
        #payment-panel {
          .checkout__panel-title {
            display: block;
            text-align: center;
          }
        }
      }
    }
    .opc__footer {
      .secure_checkout {
        @include swap_direction(padding, 20px 0 0 0);
        font-weight: bold;
        font-size: 16px;
      }
      .opc__footer-copyright {
        @include swap_direction(padding, 20px 0);
      }
      text-transform: uppercase;
      @include fontsize-rem($typesize--12px);
      color: $color--gray--dark;
      text-align: center;
      @if $fonts_update == false {
        font-family: $headline-font-family;
      }
    }
    .checkout-progress-bar {
      margin-#{$ldirection}: -15px;
      margin-#{$rdirection}: -15px;
    }
    &.active-panel-signin,
    &.active-panel-shipping,
    &.active-panel-review,
    &.payment {
      .checkout-header {
        border-bottom: none;
      }
    }
    &.active-panel-shipping,
    &.active-panel-review {
      .checkout-header {
        display: none;
      }
      #sign-in-panel {
        display: none;
      }
      #shipping-panel,
      #review-panel {
        .field-container {
          &.field-container--grid {
            &.city-state-zip {
              .form-item {
                &.with-field-lookup,
                &.phone-1 {
                  width: 100%;
                }
                &.postcode-lookup,
                &.city-lookup,
                &.city,
                &.postal-look-up {
                  width: 48%;
                  margin-#{$rdirection}: 2%;
                }
                &.find-town,
                &.town-lookup,
                &.postal-code,
                &.city-look-up {
                  width: 48%;
                  float: $rdirection;
                  margin-#{$rdirection}: 0%;
                }
              }
            }
          }
        }
      }
    }
  }
}
