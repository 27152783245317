/* Typography related classes for custom HTML and editorial usage
   ========================================================================== */

.font-tstar {
  @include body-mono();
}

.font-tstar-bold {
  @include body-mono($bold: true);
}

.font-tradegothic {
  @include heading-base();
}

.font-times {
  font-family: $times-font;
}
