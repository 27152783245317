/* ==========================================================================
   Components Atoms - Social Links
   ========================================================================== */

.social-link {
  &--facebook {
    @include icon(facebook);
  }
  &--twitter {
    @include icon(twitter);
  }
  &--instagram {
    @include icon(instagram);
  }
  margin-#{$ldirection}: 2em;
  &:before {
    font-size: 2em;
    margin-#{$ldirection}: -1em;
    @include unset-underline-links($extend: false);
  }
}
