/* ==========================================================================
   Components Atoms - Buttons
   ========================================================================== */

/* Mixin - Button Base
   ========================================================================== */

@mixin btn {
  @include unset-underline-links($extend: false);
  @include link-colors(
    $normal: white,
    $hover: white,
    $active: white,
    $visited: white,
    $focus: white
  );
  @include antialiased;
  @include swap_direction(padding, 0 20px);
  text-align: center;
  height: 60px;
  border-style: solid;
  border-color: $color--white;
  border-width: 0; // need `.btn--border` or `.btn--clear-bg` to invoke border; other classes determine color
  display: inline-block;
  background-color: $color--black;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @if $fonts_update == true {
    @include h6();
    line-height: 64px;
  } @else {
    font-family: $headline-font-family;
    font-size: 15px;
    line-height: 64px;
    text-transform: uppercase;
  }
}

/* Mixin - Button Small
   ========================================================================== */

@mixin btn--small() {
  height: 30px;
  line-height: 30px;
}

/* Mixin - Button Medium
   ========================================================================== */

@mixin btn--med() {
  height: 40px;
  line-height: 44px;
}

/* Mixin - Button Full
   ========================================================================== */

@mixin btn--full {
  width: 100%;
}

/* Mixin - Button Light
   ========================================================================== */

@mixin btn--light {
  @include unset-underline-links($extend: false);
  @include link-colors(
    $normal: black,
    $hover: black,
    $active: black,
    $visited: black,
    $focus: black
  );
  background-color: $color--white;
  border-color: $color--black;
  border-width: 1px;
  &:hover {
    @include swap_direction(border, solid 1px $color--black);
  }
}

/* Mixin - Button Clear Background
   ========================================================================== */

@mixin btn--clear-bg {
  background-color: transparent;
  border-width: 1px;
  &:hover {
    @include swap_direction(border, solid 1px $color--white);
  }
}

/* Mixin - Button Border
   ========================================================================== */

@mixin btn--border {
  border-width: 1px;
}

/* Mixin - Button Disabled
   ========================================================================== */

@mixin btn--disabled {
  @include opacity(0.5);
  cursor: not-allowed;
  &:hover {
    text-decoration: none;
  }
}

// Large buttons seen in the breadcrumbs bar
@mixin bar-button {
  @include fontsize-rem(18px);
  line-height: 1;
  font: {
    family: $headline-font-family;
    weight: normal;
  }
  text-transform: uppercase;
  height: 60px;
  background-color: $color--gray--lightest;
  position: relative;
  cursor: pointer;
  @include swap_direction(padding, 32px 19px 0 10px);
  @if $fonts_update == true {
    font-size: 18px;
  }
  &:hover {
    background-color: $color--black;
    color: $color--white;
  }
  i {
    float: #{$rdirection};
  }
}

@mixin byline-btn {
  @include swap_direction(border, solid 1px $color--white);
  background: $color--black;
  color: $color--white;
  @include swap_direction(padding, $spacing);
  display: block;
  &:hover {
    background: $color--gray--dark;
    color: $color--white;
  }
}

@mixin byline-btn--selected {
  background: $color--gray--darkest;
}

@mixin byline-btn--light {
  background: $color--white;
  @include swap_direction(border, solid 1px $color--black);
  color: $color--black;
  &:hover {
    background: $color--gray--darker;
  }
  &:active {
    background: $color--gray--lightest;
    color: $color--gray--darker;
  }
}

@mixin byline-btn__title {
  @include h5();
  text-transform: uppercase;
  margin-bottom: 0;
}

@mixin byline-btn__byline {
  @include body-mono();
  text-transform: uppercase;
  color: $color--gray--lighter;
}

// 2021 style refresh
// NEW CTA mixins

// CTA buttons
@mixin cta-button {
  @include body-text--small-bold;
  border-radius: 100px;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  padding: 0 20px;
}

// Primary CTA (rounded button) - outlined (light with dark label)
@mixin cta-primary-outline {
  @include cta-button();
  border-width: 1px;
  border-style: solid;
  text-decoration: none;
  &:not(.mantle-cta--custom-textcolor) {
    color: $color-cta-black;
  }
  &:not(.mantle-cta--custom-bordercolor) {
    border: 1px solid $color-cta-grey;
    &:hover,
    &:active {
      border-color: $color-cta-black;
    }
  }
  &:not(.mantle-cta--custom-bgcolor) {
    background-color: $color-cta-white;
  }
}

@mixin cta-primary-outline--disabled {
  @include cta-button();
  background: $color-cta-white;
  border: $color-cta-grey solid 1px;
  color: $color-cta-grey;
  text-decoration: none;
  &:hover,
  &:active,
  &:visited {
    border: $color-cta-grey solid 1px;
    color: $color-cta-grey;
    cursor: not-allowed;
    text-decoration: none;
  }
}

// Primary CTA (rounded button) - filled (dark bg light label)
@mixin cta-primary-filled {
  @include cta-button();
  border-width: 1px;
  border-style: solid;
  text-decoration: none;
  &:not(.mantle-cta--custom-textcolor) {
    color: $color-cta-grey--light;
    &:hover,
    &:active,
    &:visited {
      color: $color-cta-grey--light;
    }
  }
  &:not(.mantle-cta--custom-bordercolor) {
    border: 1px solid $color-cta-black;
    &:hover,
    &:active,
    &:visited {
      border-bottom-color: $color-cta-black;
    }
  }
  &:not(.mantle-cta--custom-bgcolor) {
    background-color: $color-cta-black;
  }
}

@mixin cta-primary-filled--disabled {
  @include cta-button();
  background: $color-cta-grey;
  border: $color-cta-grey solid 1px;
  color: $color-cta-grey--light;
  &:hover,
  &:active,
  &:visited {
    color: $color-cta-grey--light;
    cursor: not-allowed;
    text-decoration: none;
  }
}

// Text CTA styles
@mixin cta-text {
  @include sub-title--bold;
  display: inline-block;
  position: relative;
}

// Secondary CTA (text) - dark (dark text)
// 14 px / 14 mob
@mixin cta-secondary {
  @include cta-text;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  &:not(.mantle-cta--custom-textcolor) {
    color: $color-cta-black;
    &:hover,
    &:active,
    &:visited {
      color: $color-cta-black;
    }
  }
  &:not(.mantle-cta--custom-bordercolor) {
    border-bottom: 1px solid $color-cta-black;
    &:hover,
    &:active,
    &:visited {
      border-bottom-color: $color-cta-black;
    }
  }
  &:not(.mantle-cta--custom-bgcolor) {
    background-color: transparent;
  }
}
// with arrow
@mixin cta-secondary--with-arrow {
  @include cta-secondary;
  padding-#{$rdirection}: 18px;
  background-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-#{$rdirection}.svg');
  background-position-x: #{$rdirection};
  background-position-y: 1px;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

// Secondary CTA (text) - inverted (light text on dark bg)
@mixin cta-secondary--inverted {
  @include cta-text;
  border-style: solid;
  border-width: 1px;
  &:not(.mantle-cta--custom-textcolor) {
    color: $color-cta-white;
    &:hover,
    &:active,
    &:visited {
      color: $color-cta-white;
    }
  }
  &:not(.mantle-cta--custom-bordercolor) {
    border-bottom: 1px solid $color-cta-white;
    &:hover,
    &:active,
    &:visited {
      border-bottom-color: $color-cta-white;
    }
  }
}
// with arrow
@mixin cta-secondary--inverted-with-arrow {
  @include cta-secondary--inverted;
  padding-#{$rdirection}: 18px;
  background-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-#{$rdirection}-white.svg');
  background-position-x: #{$rdirection};
  background-position-y: 1px;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

// Cover CTA (large text with arrow icon) - dark (dark text)
// 24 px / 20 mob
@mixin cta-cover-type {
  @include title-03();
  display: inline-block;
  position: relative;
}

@mixin cta-cover {
  @include cta-cover-type();
  border-bottom-style: solid;
  border-bottom-width: 1px;
  &:not(.mantle-cta--custom-textcolor) {
    color: $color-cta-black;
    &:hover,
    &:active,
    &:visited {
      color: $color-cta-black;
    }
  }
  &:not(.mantle-cta--custom-bordercolor) {
    border-bottom: 1px solid $color-cta-black;
    &:hover,
    &:active,
    &:visited {
      border-bottom-color: $color-cta-white;
    }
  }
  &:not(.mantle-cta--custom-bgcolor) {
    background-color: transparent;
  }
}
// with arrow
@mixin cta-cover--with-arrow {
  @include cta-cover;
  padding-#{$rdirection}: 28px;
  background-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-#{$rdirection}.svg');
  background-position-x: #{$rdirection};
  background-position-y: 2px;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  @include breakpoint($bp--large-up) {
    background-size: 18px 18px;
    padding-#{$rdirection}: 32px;
  }
}

// Cover CTA (large text with arrow icon) - inverted (light text on dark bg)
@mixin cta-cover--inverted {
  @include cta-cover-type;
  &:not(.mantle-cta--custom-textcolor) {
    color: $color-cta-white;
    &:hover,
    &:active,
    &:visited {
      color: $color-cta-white;
    }
  }
  &:not(.mantle-cta--custom-bordercolor) {
    border-bottom: 1px solid $color-cta-white;
    &:hover,
    &:active,
    &:visited {
      border-color: $color-cta-white;
    }
  }
}
// with arrow
@mixin cta-cover--inverted-with-arrow {
  @include cta-cover--inverted;
  padding-#{$rdirection}: 28px;
  background-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-#{$rdirection}-white.svg');
  background-position-x: #{$rdirection};
  background-position-y: 2px;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  @include breakpoint($bp--large-up) {
    background-size: 18px 18px;
    padding-#{$rdirection}: 32px;
  }
}
