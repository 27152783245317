#palette_intro {
  position: relative;
  height: 100%;
}

#palette_media {
  position: relative;
}

#palette_media .ipadcover {
  display: none;
}

#playbutton {
  display: none;
  position: absolute;
  #{$ldirection}: 50%;
  top: 50%;
  margin-top: -30px;
  margin-#{$ldirection}: -53px;
  z-index: 999;
}

#palette_layout {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  top: 52px;
  #{$ldirection}: 20px;
  *width: 450px;
  z-index: 1;
  -webkit-transition: opacity 0.4s linear;
  -moz-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
}

#palette_layout.show {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

#palette_layout .section {
  color: white;
  margin-bottom: 20px;
  text-transform: uppercase;
}

#palette_layout .section .title {
  font-family: 'TradeBold', Arial, sans-serif;
  font-size: 52px;
  letter-spacing: -1px;
  @include swap_direction(margin, 0 0 -12px -3px);
  *margin-bottom: -5px;
}

#palette_layout .section .text {
  font-family: 'TradeCond', Arial, sans-serif;
  font-size: 16px;
  line-height: 17px;
  width: 400px;
  display: block;
  margin-bottom: 7px;
}

#palette_layout .section .explorebtn {
  font-family: 'TradeCond', Arial, sans-serif;
  color: #97989b;
  text-decoration: none;
  float: #{$ldirection};
  line-height: 24px;
  font-size: 16px;
  background: url(/media/images/custom_palette/darkbtn_bg.png) repeat-x 0 0;
  width: 180px;
  *width: 167px;
}

#palette_layout .section .explorebtn:hover {
  color: white;
}

#palette_layout .section .darkbtn_tip {
  background: url(/media/images/custom_palette/mac_assets.png) repeat-x -20px -73px;
  float: #{$ldirection};
  width: 7px;
  height: 21px;
}

#palette_layout .section .default {
  *float: left;
}

#palette_layout .section .darkbtn_end {
  background: url(/media/images/custom_palette/mac_assets.png) repeat-x -54px -73px;
  float: #{$rdirection};
  width: 20px;
  height: 21px;
}

#palette_video {
  min-width: 1087px;
  min-height: 782px;
}

#size_dropdown {
  font-family: 'TradeCond', Arial, sans-serif;
  font-size: 16px;
  position: relative;
  color: #97989b;
  width: 210px;
  z-index: 999;
}

#size_dropdown .selection {
  background: url(/media/images/custom_palette/darkbtn_bg.png) repeat-x 0 0;
  cursor: pointer;
  line-height: 23px;
  position: relative;
  z-index: 999;
  width: 100%;
}

#size_dropdown .selection.over {
  color: white;
}

#size_dropdown .arrow {
  background: url(/media/images/custom_palette/mac_assets.png) repeat-x -31px -73px;
  width: 20px;
  height: 21px;
  display: block;
  float: #{$rdirection};
}

#size_dropdown UL {
  width: 207px;
  z-index: 0;
  position: absolute;
  overflow: hidden;
  @include swap_direction(border, 1px solid #2c2c2c);
  border-top: none;
  height: 0;
  top: 20px;
  background-color: black;
  -webkit-transition: height 0.2s linear;
  -moz-transition: height 0.2s linear;
  -o-transition: height 0.2s linear;
  transition: height 0.2s linear;
}

#size_dropdown UL LI {
  padding-#{$ldirection}: 5px;
  padding-top: 3px;
}

#size_dropdown UL LI A {
  text-decoration: none;
}

#size_dropdown UL LI A:hover {
  color: white;
}
