/*
 * Overlay hover
 * Hover over a block and see the contents of a hovered child element
 */

.overlay-hover-wrapper {
  position: relative;
}

.overlay-hover {
  @include fill-rel-parent();
  background-size: cover;
  background-position: 50% 50%;
  z-index: 10;
  &:hover {
    opacity: 0.25;
  }
}

.overlay-hover-image {
}

/*
 * Clickaway hover
 * A block starts out overlaying content, disappears with a click
 */

.overlay-clickaway {
  position: relative;
}

.overlay-clickaway__starting {
  @include fill-rel-parent();
}
