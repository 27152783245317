.delivery-date__container {
  max-width: 480px;
  @include swap_direction(padding, 0 $spacing);
  &__instructions {
    margin-#{$rdirection}: 25%;
  }
  .form-item {
    @include layout($grid--2, 1em) {
      &:nth-of-type(2n + 1) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(2n + 2) {
        @include grid-span(1, 2);
      }
    }
    @include breakpoint($bp--medium-up) {
      @include layout($grid--4, 1em) {
        &:nth-of-type(2n + 1) {
          @include grid-span(3, 1);
          clear: both;
        }
        &:nth-of-type(2n + 2) {
          @include grid-span(1, 4);
        }
      }
    }
  }
  .delivery-date__terms {
    a {
      float: #{$rdirection};
    }
  }
  table {
    width: 100%;
    th,
    td {
      @include swap_direction(padding, 1em 5% 1em 0);
      border-bottom: 1px solid $color--gray--lightest;
      vertical-align: bottom;
    }
  }
  .delivery-date__table--date {
    width: 45%;
  }
  .delivery-date__table--price {
    width: 15%;
  }
  .delivery-date__table--method {
    width: 25%;
  }
}
