#artist-palettes #backbutton {
  width: 88px;
  height: 20px;
  line-height: 20px;
  @include swap_direction(margin, 16px 15px 0);
  display: block;
}

#artist-palettes .overlay-container {
  font-size: 11px;
  font-family: arial, verdana, helvetica, clean, sans-serif;
  text-transform: none;
  color: #777;
}

#artist-palettes #cartconfirm-palette {
  margin-#{$ldirection}: -48px;
}

#artist-palettes {
  width: 907px;
  font: 15px/16px 'TradeCond';
  text-transform: uppercase;
  color: #97989b;
  letter-spacing: 0;
  margin-top: 35px;
}

#artist-palettes .artist-header H1 {
  font: 52px/52px 'TradeBold';
  color: white;
  @include swap_direction(padding, 3px 15px 0);
  @include swap_direction(margin, 0 0 -5px);
}

#artist-palettes .artist-header H2 {
  @include swap_direction(padding, 0 15px 10px);
  margin-bottom: 0;
  font-family: 'TradeCond', Arial, sans-serif;
  color: white;
  font-size: 15px;
}

#artist-palettes UL {
}

#artist-palettes UL LI {
  @include swap_direction(padding, 7px 15px);
  background-color: #1e1e1e;
  overflow: hidden;
  @include swap_direction(margin, 0 0 20px);
  position: relative;
}

#artist-palettes .artist-name {
  color: white;
  @include swap_direction(margin, 0 0 2px);
}

#artist-palettes .text {
  @include swap_direction(margin, 0 0 1px);
}

#artist-palettes .photo {
  width: 114px;
  float: #{$ldirection};
  @include swap_direction(margin, 0 20px 0 0);
}

#artist-palettes .palette {
  width: 211px;
  float: #{$ldirection};
  @include swap_direction(margin, 0 18px 0 0);
}

#artist-palettes .palette-grid-container {
  background-position: 0 0;
  background-repeat: no-repeat;
  @include swap_direction(border, 1px solid #1e1e1e);
}

#artist-palettes .palette-grid-container-2 {
  width: 108px;
  height: 65px;
}

#artist-palettes .palette-grid-container-4 {
  width: 97px;
  height: 101px;
}

#artist-palettes .palette-grid-container-6 {
  width: 163px;
  height: 104px;
}

#artist-palettes .palette-grid-container-15 {
  width: 211px;
  height: 135px;
}

#artist-palettes .palette-grid-container-30 {
  width: 211px;
  height: 262px;
}

#artist-palettes .palette-grid-2 {
  margin: 7px 0 0 2px;
}

#artist-palettes .palette-grid-4 {
  margin: 1px 0 0 -3px;
}

#artist-palettes .palette-grid-6 {
  margin: 2px 0 0 3px;
}

#artist-palettes .palette-grid-15 {
  margin: 10px 0 0 2px;
}

#artist-palettes .palette-grid-30 {
  margin: 7px 0 0 3px;
}

#artist-palettes .palette A.swatch {
  width: 28px;
  height: 28px;
  display: block;
  float: #{$ldirection};
  position: relative;
  text-decoration: none;
}

#artist-palettes .palette-grid-2 A.swatch {
  margin: 16px 0 0 16px;
}

#artist-palettes .palette-grid-4 A.swatch {
  margin: 16px 0 0 16px;
}

#artist-palettes .palette-grid-6 A.swatch {
  margin: 16px 0 0 18px;
}

#artist-palettes .palette-grid-15 A.swatch {
  margin: 9px 0 0 11px;
}

#artist-palettes .palette-grid-30 A.swatch {
  margin: 8px 0 0 11px;
}

#artist-palettes .palette-grid-30 .grid-spacer {
  height: 23px;
  clear: left;
}

#artist-palettes .palette A.swatch .tooltip {
  top: -8px;
  #{$ldirection}: 12px;
  @include swap_direction(border, 1px solid #333333);
  font-family: arial, verdana, helvetica, clean, sans-serif;
  font-size: 11px;
  text-transform: none;
  color: #000;
  z-index: 9;
  background-color: #999;
}

#artist-palettes .palette A.swatch:hover .tooltip {
  visibility: visible;
  z-index: 10;
}

#artist-palettes .details {
  width: 273px;
  float: #{$ldirection};
  @include swap_direction(margin, 0 20px 0 0);
}

#artist-palettes .details .text {
  min-height: 114px;
}

#artist-palettes .details .description {
  text-indent: -4px;
  margin-bottom: 26px;
}

#artist-palettes .details .actions {
  position: absolute;
  bottom: 3px;
}

#artist-palettes .details .price {
  display: inline;
  color: white;
  vertical-align: bottom;
  line-height: 19px;
}
#{$ie8} #artist-palettes .details .price,
#{$ie9} #artist-palettes .details .price {
  float: #{$ldirection};
  @include swap_direction(margin, 6px 5px 0 0);
}

#artist-palettes .details .addtobag {
  display: inline-block;
}

#artist-palettes .details .progress {
  @include swap_direction(margin, 0 0 3px 30px);
}

#artist-palettes .create {
  width: 220px;
  float: #{$ldirection};
}

#artist-palettes .create-link {
  width: 94px;
  height: 11px;
  display: block;
  background-image: url('/media/images/custom_palette/start_now_bg.jpg');
  color: #97989b;
  text-decoration: none;
  @include swap_direction(padding, 5px 7px);
  line-height: 13px;
}

.ie #artist-palettes .create-link {
  height: 15px;
  @include swap_direction(padding, 3px 7px);
}

#artist-palettes .create-link:hover {
  color: white;
}

#artist-palettes .ui_lightbutton {
  background-position: -120px -24px;
  width: 100px;
  @include swap_direction(padding, 6px);
  margin-#{$ldirection}: 5px;
  margin-bottom: 2px;
  height: 12px;
}
