/**
 * @file 10-base/_suppress_ecom.scss
 *
 * shared styles for the "suppress ecom for retailers" feature
 *
 */

body.is-retailer {
  .product-full__add-to-bag,
  .product-brief__add-to-bag,
  .product-add-to-bag,
  .product__add-to-bag,
  .cart-product-button,
  .custom-kit-formatter__add-to-bag,
  .product-vto__add-to-bag,
  .add-to-bag-bar__add-button,
  .add-to-bag-bar__shade-desc,
  .recommended-item__button,
  .addtobag input[type='submit'],
  .shade-picker__color-actions a[data-test-id='shade_add_to_cart'],
  .recommended-item_button,
  .add-to-bag,
  .add_to_cart_btn,
  .add_to_cart,
  .add-link,
  .add_button,
  .btn-add-to-bag,
  button[data-test-id='add-to-cart'] {
    visibility: hidden;
  }
}
