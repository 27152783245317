// scss-lint:disable IdSelector
/* ==========================================================================
   Components Organisms - Colorbox
   ========================================================================== */

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/

#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0; //overwritten in js; do not change to rtl variable
  z-index: 9999;
  overflow: hidden;
}

#cboxWrapper {
  max-width: none;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: #{$ldirection};
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 14px;
  .cboxLoadedContent-error {
    display: block;
    padding-#{$rdirection}: 20px;
  }
}

#cboxTitle {
  @include swap_direction(margin, 0);
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}

input.error {
  border: 1px solid $color--red;
  box-shadow: none;
}

.cboxPhoto {
  float: #{$ldirection};
  @include swap_direction(margin, auto);
  @include swap_direction(border, 0);
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  @include swap_direction(border, 0);
  @include swap_direction(padding, 0);
  @include swap_direction(margin, 0);
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/

#cboxOverlay {
  background: $color--black;
}

#colorbox {
  outline: 0;
}

#cboxContent {
  background: $color--white;
}

.cboxIframe {
  background: $color--white;
}

#cboxError {
  @include swap_direction(padding, 50px);
  @include swap_direction(border, 1px solid $color--gray--lighter);
}

#cboxLoadedContent {
  background: $color--white;
  @include swap_direction(padding, 1em);
  .overlay-content-inner-wrapper {
    width: 90%;
  }
}

#cboxTitle {
  position: absolute;
  top: -20px;
  #{$ldirection}: 0;
  color: $color--gray--lighter;
}

#cboxCurrent {
  position: absolute;
  top: -20px;
  #{$rdirection}: 0px;
  color: $color--gray--lighter;
}

#cboxLoadingGraphic {
  background: url(images/loading.gif) no-repeat center center;
}
/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */

#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  @include swap_direction(border, 0);
  @include swap_direction(padding, 0);
  @include swap_direction(margin, 0);
  overflow: visible;
  width: auto;
  background: none;
}
/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */

#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  #{$rdirection}: 90px;
  color: $color--white;
}

#cboxPrevious {
  position: absolute;
  top: 50%;
  #{$ldirection}: 5px;
  margin-top: -32px;
  background: url(images/controls.png) no-repeat top left;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
  &:hover {
    background-position: bottom left;
  }
}

#cboxNext {
  position: absolute;
  top: 50%;
  #{$rdirection}: 5px;
  margin-top: -32px;
  background: url(images/controls.png) no-repeat top right;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
  &:hover {
    background-position: bottom right;
  }
}

#cboxClose {
  position: absolute;
  top: 5px;
  #{$rdirection}: 0;
  display: block;
  width: 28px;
  height: auto;
  font-size: 16px;
  &:hover {
    background-position: bottom center;
  }
}

// BV check registration status for write-a-review
#colorbox .email_check,
#colorbox .full_sign_in {
  h2 {
    font-size: 24px;
    @include swap_direction(margin, 40px 0);
  }
  h3 {
    font-size: 20px;
  }
  .row {
    @include swap_direction(margin, 10px 0);
  }
  .btn-bv {
    margin-top: 20px;
  }
}

#colorbox .email_check {
  margin-left: 20px;
  margin-right: 20px;
  h2,
  h3 {
    max-width: 420px;
  }
  h2 {
    font-size: 22px;
    margin-bottom: 5px;
  }
  h3 {
    font-family: $body-font-family;
    font-size: 12px;
    margin-bottom: 25px;
    text-transform: none;
  }
  .btn-bv {
    width: 228px;
  }
}