.saved-tokens {
  @include responsive-container;
  @include swap_direction(padding, 0 10px);
  @include breakpoint($bp--medium-up) {
    @include layout($grid--2, 1em) {
      .saved-payments {
        @include grid-span(1, 1);
      }
    }
  }
  .saved-payment__item {
    @include swap_direction(padding, 1em 0);
    max-width: 480px;
  }
  .saved-payments__header {
    margin-top: 1em;
    text-align: center;
    @include breakpoint($bp--medium-up) {
      text-align: #{$ldirection};
    }
  }
}
