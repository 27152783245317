/* ==========================================================================
   Components Atoms - Text Block
   ========================================================================== */

.text-block {
  @include swap_direction(padding, 15px);
  &--inverted {
    @include reverse;
  }
  &__headline {
    @include h3();
    @include fontsize-rem($typesize--18px);
    line-height: 1;
    margin-top: 0;
    @include breakpoint($bp--medium-up) {
      @include fontsize-rem($typesize--24px);
    }
    // adds a seperator only if there's a header then a body
    + .text-block__body {
      &:before {
        @include heading--small;
        content: '_';
        display: block;
        line-height: 0;
        margin-bottom: 36px;
      }
    }
  }
  // may or may not contain `<p>`s - it's up to Drupal Rich Text Editor to insert them
  &__body {
    @include body-mono();
  }
  &__cta {
    .custom-grid & {
      position: absolute;
      bottom: 15px;
      #{$ldirection}: 15px;
    }
  }
  &--quote {
    .text-block__headline {
      @include preheading-mono;
    }
    .text-block__body {
      @include title-section;
      @include fontsize-rem($typesize--18px);
      @include swap_direction(padding, 0 0 0 0.45em);
      text-indent: -0.45em;
      @include breakpoint($bp--xsmall-up) {
        @include fontsize-rem($typesize--24px);
      }
      @include breakpoint($bp--medium-up) {
        @include fontsize-rem($typesize--30px);
      }
      @include breakpoint($bp--medium-up) {
        @include fontsize-rem($typesize--30px);
      }
      @include breakpoint($bp--xxlarge-up) {
        @include fontsize-rem($typesize--36px);
      }
    }
    .footer {
      bottom: 15px;
      line-height: 1;
      position: absolute;
    }
    .text-block__name,
    .text-block__date {
      text-transform: uppercase;
    }
  }
}
// custom style variation from CMS hides the dash
.text-block__body.text-block__body--nodash:before {
  display: none;
}
