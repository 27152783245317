input[type='button'],
input[type='submit'] {
  &:valid {
    border: none;
  }
}

.field-container {
  @include clearfix;
  clear: both;
  @include breakpoint($bp--medium-up) {
    max-width: 910px;
  }
}

.checkout__content {
  .select2-container {
    border-color: #ccc;
    padding: 0px 0 0 2px;
    &.error {
      // This style is more specific than the error style overriding it, so re apply the red border
      // mac_base/scss/40-components/_forms__generic.scss:161
      border-color: red;
    }
  }
  .viewcart-panel .select2-container {
    border: none;
  }
  .select2-choice {
    border-bottom: none;
  }
}

#checkout_shipmethod {
  .ship-method-group {
    max-width: 350px;
  }
  .select2-container {
    border: none;
    padding-left: 0;
    .select2-choice > .select2-chosen {
      margin-#{$ldirection}: 0;
      margin-#{$rdirection}: 40px;
    }
  }
  .select2-container .select2-choice {
    border-width: 0;
    background: transparent;
  }
  .select2-container .select2-choice .select2-arrow {
    background: transparent;
  }
}

.checkout {
  #top-viewcart-buttons {
    margin: 0 0 15px 0;
    padding: 0;
    a.continue-checkout {
      margin-top: 5px;
    }
  }
  .product__desc-info {
    min-height: 0;
  }
  .checkout__subtitle.inline-title {
    display: inline-block;
  }
  &__content {
    .panel {
      .btn {
        min-width: 228px;
      }
    }
  }
  .registration-panel {
    input[type='text'],
    input[type='password'] {
      max-width: 400px;
    }
  }
  .city-state-zip {
    .postal-code {
      width: 50%;
    }
  }

  //Show the label for the country field
  .country-id {
    display: block;
    label {
      display: block;
    }
  }
  &__content {
    // hide the viewcart panel header, which has been placed differently within the markup (outside of the content/sidebar columns, as per MAC NA)
    .viewcart-header {
      display: none;
    }
  }
  &__sidebar {
    .tab-bar {
      display: none;
    }
    .samples-panel {
      &__submit-container {
        display: none;
      }
      footer {
        display: none;
      }
      .sample-select-button {
        display: none;
      }
    }
    .favorites-panel {
      footer {
        display: none;
      }
    }
    .viewcart-panel,
    .viewcart-shopping-bag-panel {
      .cart-header {
        display: none;
      }
    }
    .viewcart-header {
      position: relative;
    }
    .item-count {
      text-transform: uppercase;
    }
  }
  &-header {
    // set up some specific rules for the checkout header
    position: relative;
    &__title {
      @include breakpoint($bp--small-down) {
        padding-top: 2.5rem;
      }
    }
    .continue-shopping {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .mobile-breadcrumb {
    display: none;
  }
  .continue-buttons {
    .btn {
      &.disabled {
        background-color: $color--gray--lighter;
        cursor: default;
        text-decoration: none;
      }
    }
  }
  .checkout__content .order-summary__content {
    margin-top: 1.8em;
    @include breakpoint($bp--medium-up) {
      .label {
        white-space: nowrap;
      }
    }
  }
}

.sms-promo-wrapper {
  .form-element-prefix {
    display: block;
  }
}

#offer-code-panel {
  p {
    display: none;
    &.note {
      display: block;
    }
  }
}

#checkout_complete {
  .picker-radio {
    float: left;
  }
  .picker-handle {
    margin-right: 5px;
  }
}

.viewcart-buttons-panel {
  @include breakpoint($bp--small-down) {
    #bottom-viewcart-buttons & {
      .continue-shopping {
        display: none;
      }
      .checkout__sidebar & {
        background-color: $color--white;
      }
    }
  }
  .or {
    display: none;
  }
}

.shipping-panel {
  &.substate-manual {
    #manual-address-fields {
      display: block !important;
      visibility: visible !important;
    }
  }
  #continue-btn {
    clear: both;
  }
  p:last-child {
    margin-bottom: 0.5em;
  }
}

.sign-in-panel {
  .img_mail {
    display: none;
  }
  #error_checkout\.please_signin {
    display: none;
  }
  .checkout__return-user {
    #checkout__return-user-errors {
      // Add a gap underneath so it doesn't clash with the adaptive placeholder label
      li:last-child {
        margin-bottom: 8px;
      }
    }
  }
}

.samples {
  &-page {
    .product {
      &-name {
        font-size: 1.5rem;
      }
    }
  }
  &-page &-panel {
    &__submit-container {
      display: none;
    }
  }
}

.field-container--grid {
  &.address-to-use,
  &.date-of-birth {
    .form-item {
      @include breakpoint($bp--medium-up) {
        @include layout($grid--3, 1em) {
          &:nth-of-type(3n + 1) {
            @include grid-span(1, 1);
            clear: both;
            .valid_marker,
            .invalid_marker {
              right: 18px;
            }
          }
          &:nth-of-type(3n + 2) {
            @include grid-span(1, 2);
          }
          &:nth-of-type(3n + 3) {
            @include grid-span(1, 3);
          }
        }
      }
    }
  }
}

.site-container {
  .viewcart {
    .checkout__content {
      padding-#{$ldirection}: 0;
      .guarantee-panel {
        border-bottom: none;
      }
      .cart-item:last-child {
        margin-bottom: 0;
      }
      .viewcart-panel,
      .viewcart-shopping-bag-panel {
        .checkout__panel-content {
          .products {
            .sample {
              .qty {
                padding-top: 18px;
              }
            }
          }
        }
      }
    }
  }
}

#gc_iframe {
  width: 90%;
  height: 50em;
}

.checkout__sidebar {
  #viewcart-shopping-bag-panel {
    background: none;
    padding: 0;
    header {
      background: $color--gray--white;
      padding: 1em;
    }
    .checkout__panel-content {
      margin-top: 1em;
      margin-bottom: 0;
    }
    .product-list {
      max-height: 500px;
      overflow: auto;
    }
    .cart-item {
      background: #f0f0f0;
      margin-top: 1em;
      padding: 1em;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

#confirmation-page {
  .checkout-header__title {
    @include breakpoint($bp--large-up) {
      // NA puts some other content on this row so adjusts the padding and width:
      // mac_base/scss/40-components/_opc__page--checkout.scss:123
      // Set it back to full width for all EMEA locales.
      width: 100%;
      text-align: center;
      padding-right: 0;
      padding-left: 0;
      right: 0;
    }
  }
  #registration-panel {
    margin-left: 0;
    .messages-container {
      float: none;
      display: block;
      width: 100%;
    }
  }
}

.checkout-header__title {
  @include breakpoint($bp--small-down) {
    padding-top: 1rem;
  }
}

.city-results select.city-selector {
  padding: 17px;
  height: auto;
}

.city-results p {
  padding-top: 21px;
}

#favorites-panel {
  .product-list {
    min-height: 185px;
  }
}
