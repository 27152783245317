@mixin sticky-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  overflow: hidden;
  text-align: center;
}

.sticky-cards-page {
  figure.cover {
    @include sticky-cover;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
    }
  }
  span.cover {
    @include sticky-cover;
  }
  section {
    position: relative;
  }
  .frame {
    position: fixed;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    overflow: hidden;
    &.touch-detected {
      position: static;
    }
    @include breakpoint($bp--large-down) {
      position: relative;
    }
  }
  .vid {
    position: absolute;
    #{$ldirection}: 125%;
    top: 50%;
    margin-top: -360px;
    margin-#{$ldirection}: -540px;
    z-index: 99;
    -webkit-transition: 0.7s ease;
    -moz-transition: 0.7s ease;
    -ms-transition: 0.7s ease;
    transition: 0.7s ease;
    overflow: hidden;
    &.full {
      position: absolute;
      background: #000;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      z-index: 99;
      @include swap_direction(margin, 0);
      -webkit-transition: 0.7s ease;
      -moz-transition: 0.7s ease;
      -ms-transition: 0.7s ease;
      transition: 0.7s ease;
      @include breakpoint($bp--large-down) {
        position: static;
      }
      video {
        width: auto;
        height: 100%;
        @include breakpoint($bp--large-down) {
          width: 1080px;
          height: auto;
        }
      }
    }
    video {
      z-index: 99;
      width: 1080px;
      height: 720px;
    }
    .video-player-wrapper {
      max-width: 100%;
    }
    .video_player {
      max-width: 100%;
      .video_player_poster img.poster {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
    .video_player_controls_wrapper {
      display: none;
    }
    .play {
      position: absolute;
      #{$ldirection}: 50%;
      /* left: 72%; */
      top: 50%;
      font-variant: 600;
      @include swap_direction(margin, -25px 0 0 -27px);
      font-size: 30px;
      cursor: default;
      z-index: 999;
      text-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
      -webkit-transition: 0.3s ease;
      -moz-transition: 0.3s ease;
      -ms-transition: 0.3s ease;
      transition: 0.3s ease;
      /*
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          opacity: 0;
      */
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
      opacity: 1;
      &.active {
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
        opacity: 0;
      }
    }
    &.ended .play {
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
      opacity: 1;
    }
    .watch {
      @include transform(rotate(-90deg));
      color: #333;
      text-align: center;
      position: absolute;
      font-weight: bold;
      font-size: 20px;
      #{$ldirection}: -45px;
      top: 50%;
      margin-top: -40px;
      z-index: 98;
      cursor: pointer;
      i {
        display: block;
        @include swap_direction(margin, 6px auto);
        width: 2px;
        height: 80px;
        background: #333;
        -webkit-transition: 0.3s ease;
        -moz-transition: 0.3s ease;
        -ms-transition: 0.3s ease;
        transition: 0.3s ease;
      }
      &.active i {
        height: 40px;
      }
    }
  }
  .scroll {
    display: none;
  }
}

.sticky-progress-counter {
  top: 6em;
  #{$rdirection}: 1em;
  position: fixed;
  height: 2em;
  z-index: 10000;
  @include breakpoint($bp--large-down) {
    display: none;
  }
}

.collection-landing-brick-card-sticky__caption {
  @include transition-property(opacity);
  @include transition-duration(0.3s);
  @include transition-delay(1s);
  @include transform(translateX(flipX(-50%, #{$rdirection})));
  position: absolute;
  bottom: 0;
  #{$ldirection}: 50%;
  @include swap_direction(padding, 15px);
  opacity: 0;
  z-index: 100;
  .active & {
    opacity: 1;
  }
  @include breakpoint($bp--large-down) {
    opacity: 1;
  }
}

.sticky-cards-page {
  .collection-landing-brick {
    &__logo {
      display: none;
      float: #{$ldirection};
      margin-bottom: $spacing;
      padding-#{$rdirection}: 15px;
      width: auto;
      @include breakpoint($bp--xlarge-up) {
        display: block;
      }
      img {
        width: 100%;
      }
    }
  }
}

.card-sticky-section {
  &.card--video {
    display: none;
  }
  @include breakpoint($bp--large-down) {
    &.card--video {
      display: block;
    }
    .js-turn-img-to-bg {
      .bg-img--expand {
        position: relative;
        top: auto;
        bottom: auto;
        #{$ldirection}: auto;
        #{$rdirection}: auto;
      }
      picture {
        display: block !important;
      }
      img {
        width: 100%;
      }
    }
  }
}
