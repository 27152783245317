/* ==========================================================================
   Components Organisms - Shop the Collection
   ========================================================================== */

/*
 * Collections page and components
 */

// Vars
$sharedHeight: 483px;
$indicatorBar: 30px; // requested width in MAC788

// Organism
.shop-the-collection {
  @include swap_direction(padding, 0 0 15px 0);
  .product__header {
    @include breakpoint($bp--largest-up) {
      min-height: 120px;
    }
  }
  .product__name,
  .product__subline {
    @include swap_direction(padding, 0 50px 0 0);
  }
  .product__footer {
    @include breakpoint($bp--largest-up) {
      bottom: 0;
      #{$ldirection}: auto;
    }
  }
  .product__product-details-shade {
    @include breakpoint($bp--largest-up) {
    }
  }
}

.shop-the-collection__carousel {
  border-top: $border;
}

// right side
.collection-quickshop {
  position: relative;
  margin-#{$ldirection}: $spacing;
  border-top: $border;
  height: 100%;
  @include breakpoint($bp--medium-up) {
    margin-#{$rdirection}: $spacing;
  }
  .product {
    padding-top: 20px;
    .product__inventory-status li.coming-soon-text {
      width: 100%;
      font-size: 15px;
      margin-top: 6px;
    }
  }
}

.collection-quickshop,
.collection-quickshop .product,
.product-render-anchor {
  height: 100%;
}

// Left side
@mixin slider-item--active--stc {
  @include pseudo-base();
  @include abs-pos();
  bottom: -20px;
  height: 5px;
  background: $color--black;
  width: $indicatorBar;
  margin-#{$ldirection}: -($indicatorBar * 0.5);
  #{$ldirection}: 50%;
}

.shop-the-collection__carousel-frame {
  width: 100%;
  @include swap_direction(padding, 0);
  margin-bottom: 0;
}

.shop-the-collection__carousel-slidee {
  @include swap_direction(margin, 0 auto);
  @include swap_direction(padding, 0);
  height: 100%;
  list-style: none;
  width: 100%; // need this wide at start for proper js, js sets to proper width
  clear: both;
  &.shop-the-collection__list-left {
    @include swap_direction(margin, 0);
  }
}

.shop-the-collection__carousel-item {
  float: left;
  @include swap_direction(margin, 0 0 20px 0);
  @include swap_direction(padding, 0 7px);
  min-width: 30px;
  height: auto !important;
  position: relative;
  cursor: pointer;
  &:hover {
    @include slider-item--active--stc;
  }
  .collection-item-expand {
    @include swap_direction(border, none);
    #{$ldirection}: 0;
    display: block;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: 5%;
    width: 100%;
    #{$ldirection}: 50%;
    margin-#{$ldirection}: -13px;
  }
}

.shop-the-collection__online-exclusive {
  &.last {
    margin-#{$rdirection}: 86px;
  }
}

.shop-the-collection__carousel-item--active {
  @include slider-item--active--stc;
}

.shop-the-collection__carousel__item img {
  width: auto;
  max-width: none;
  display: block;
}

.collection-prop-image {
  height: 340px; // images coming over from collections field should be this size
}

.collection-drop-image {
  position: absolute;
  bottom: 0;
  #{$ldirection}: 0;
  display: none;
}

// Scrollbar
.shop-the-collection__carousel-scrollbar {
  @include swap_direction(margin, 1em 0 1em 0);
  height: 2px;
  background: hsl(0, 0%, 80%);
  line-height: 0;
  display: none; // requested remove, but keep styles
}

.shop-the-collection__carousel-scrollbar-handle {
  width: 100px;
  height: 100%;
  background: hsl(234, 11%, 18%);
  cursor: pointer;
}

.shop-the-collection__carousel-scrollbar-mousearea {
  position: absolute;
  top: -9px;
  #{$ldirection}: 0;
  width: 100%;
  height: 20px;
}

// Controls
.shop-the-collection__carousel-controls {
  cursor: pointer;
  margin-top: 50px;
  position: relative;
  .prevPage {
    @include icon('arrow--left-medium', $text-replace: true);
    float: left;
    display: block;
    width: 81px;
    height: 20px;
    position: relative;
    z-index: 20;
    .shop-the-collection__carousel--no-arrow & {
      visibility: hidden;
    }
  }
  .nextPage {
    @include icon('arrow--right-medium', $text-replace: true);
    float: right;
    display: block;
    width: 81px;
    height: 20px;
    position: relative;
    z-index: 20;
    .shop-the-collection__carousel--no-arrow & {
      visibility: hidden;
    }
  }
  .disabled {
    @include opacity(0.25);
  }
}

.shop-the-collection__add-all {
  width: 200px;
  position: absolute;
  text-align: center;
  #{$ldirection}: 50%;
  margin-#{$ldirection}: -100px;
}

.collection_text_content {
  .shop-the-collection {
    @include swap_direction(padding, 0px);
    .shop-the-collection__quickshop {
      height: 481px;
      &.text_quickshop {
        background: hsl(17, 43%, 55%);
      }
      .collection-quickshop {
        display: block;
        .collection_text {
          color: white;
          @include swap_direction(padding, 35% 0);
          text-align: center;
          .shop-link-collections a.btn {
            background: transparent;
          }
        }
        .product__footer {
          bottom: 51px;
        }
        .collection_text__close {
          width: 24px;
          height: 24px;
          @include swap_direction(margin, 6px 0 0 6px);
          cursor: pointer;
          position: absolute;
          #{$rdirection}: 0;
          top: 15px;
        }
      }
    }
    .shop-the-collection__carousel {
      .shop-the-collection__carousel-frame {
        .shop-the-collection__carousel-slidee {
          @include swap_direction(margin, 0 !important);
          &.text_quickshop {
            pointer-events: none;
            .slider-item--active:after,
            .slider-carousel__item:hover:after,
            .shop-the-collection__carousel-item:hover:after,
            .shop-the-collection__carousel-item--active:after {
              width: 0px;
            }
          }
        }
      }
    }
  }
}
