/* ==========================================================================
   Components Molecules - Blocks, Expando Block
   ========================================================================== */

/*
 * A block that turns into a click-expandable with a title at mobile
 */

.expando-block {
  @include swap_direction(padding, 15px 0);
  border-top: $border;
  margin-#{$ldirection}: 15px;
  margin-#{$rdirection}: 15px;
  @include breakpoint($bp--xlarge-up) {
    @include swap_direction(padding, 0);
    border-top-width: 0;
    margin-#{$rdirection}: 0;
    margin-#{$ldirection}: 0;
    &.responsive-container {
      margin-#{$rdirection}: auto;
      margin-#{$ldirection}: auto;
    }
  }
  &.responsive-container {
    width: auto;
  }
  &.product__description--compact {
    border-top: none;
  }
}

.expando-block--last {
  border-bottom: $border;
  margin-bottom: 15px;
  @include breakpoint($bp--xlarge-up) {
    @include swap_direction(border, 0);
    @include swap_direction(margin, 0);
  }
  .after-product & {
    margin-bottom: 0px;
  }
}

.expando-block__title {
  @include title-section;
  position: relative;
  cursor: pointer;
  .expando-block--expanded & {
    margin-bottom: 15px;
  }
  .product--full &,
  .after-product &,
  .product__reviews-spp & {
    font-size: 15px;
  }
  @include breakpoint($bp--xlarge-up) {
    display: none;
  }
}

.expando-block__icon {
  @include abs-pos();
  #{$rdirection}: 0;
  top: 0;
  @include animation-all;
  //  top: -5px;
  #{$rdirection}: 15px;
  .expando-block--expanded & {
    @include transform(rotate(90deg));
  }
  .product--full &,
  .after-product & {
    font-size: 15px;
  }
  [dir='rtl'] & {
    right: auto;
  }
}

.expando-block__content {
  @include animation-all;
  height: 0;
  overflow: hidden;
  .expando-block--expanded & {
    height: auto;
  }
  @include breakpoint($bp--xlarge-up) {
    display: block;
    height: auto;
  }
  h6 {
    margin-top: 1em;
    &:first-child {
      margin-top: 0.25em;
    }
    @include breakpoint($bp--xlarge-up) {
      margin-top: 0.25em;
    }
  }
}

.site-container .mpp-custom {
  margin-bottom: 0;
  .expando-block__title {
    @include breakpoint($bp--large-down) {
      text-align: center;
      font-size: 18px;
    }
  }
}
