$tier1color1: rgb(255, 160, 191) !default;
$tier1color2: rgb(211, 5, 71) !default;
$tier2color1: rgb(249, 165, 140) !default;
$tier2color2: rgb(130, 59, 52) !default;
$tier3color1: rgb(153, 186, 221) !default;
$tier3color2: rgb(109, 40, 170) !default;
$lovertier4color1: rgba(157, 158, 161, 1) !default;
$lovertier4color2: rgba(166, 166, 169, 1) !default;
$lovertier4color3: rgba(176, 176, 179, 1) !default;
$lovertier4color4: rgba(121, 121, 123, 1) !default;
$lovertier4color5: rgba(58, 59, 59, 1) !default;
$mac-lover: false !default;

// MAC Select color swatches for different tier levels
@mixin mac-select--tier1 {
  background-color: mix($tier1color1, $tier1color2, 50%);
  @include background(linear-gradient(to right, $tier1color1, $tier1color2));
}

.mac-select__swatch-tier1 {
  @include mac-select--tier1();
}

@mixin mac-select--tier2 {
  background-color: mix($tier2color1, $tier2color2, 50%);
  @include background(linear-gradient(to right, $tier2color1, $tier2color2));
}

.mac-select__swatch-tier2 {
  @include mac-select--tier2();
}

@mixin mac-select--tier3 {
  background-color: mix($tier3color1, $tier3color2, 50%);
  @include background(linear-gradient(to right, $tier3color1, $tier3color2));
}

.mac-select__swatch-tier3 {
  @include mac-select--tier3();
}

.mac-select__swatch-tier4 {
  background: $color--black;
}

@mixin mac-select--lovertier4 {
  @include background(
    linear-gradient(
      45deg,
      $lovertier4color1 0%,
      $lovertier4color2 41%,
      $lovertier4color3 59%,
      $lovertier4color4 76%,
      $lovertier4color5 100%
    )
  );
}

.mac-select__swatch-lovertier4 {
  @include mac-select--lovertier4();
}
// middle colors of tier gradients, for text effects
.mac-select__color-tier1 {
  color: mix($tier1color1, $tier1color2, 50%);
}

.mac-select__color-tier2 {
  color: mix($tier2color1, $tier2color2, 50%);
}

.mac-select__color-tier3 {
  color: mix($tier3color1, $tier3color2, 50%);
}

.mac-select__color-tier4 {
  color: $color--black;
}

// MAC Select Marketing Page
.js-loyalty {
  @include swap_direction(padding, 0 20px);
  @include breakpoint($bp--largest-up) {
    @include swap_direction(margin, 0 0 55px 25%);
    max-width: 660px;
    @include swap_direction(padding, 0);
  }
  .loyalty-user__create-account {
    margin-top: 40px;
  }
}

.mac-select__marketing-page {
  &__image,
  &__banner-image,
  &__banner-content {
    display: block;
    height: auto;
    @include swap_direction(margin, 0 auto);
    width: 100%;
    .loyalty-select__bottom & {
      display: none;
    }
    #loyalty__page__index-bottom & {
      display: none;
    }
  }
  &__banner {
    position: relative;
    &-content {
      position: static;
      margin-top: -50px;
      width: 100%;
      color: $color--white;
      text-align: center;
      @include breakpoint($bp--largest-up) {
        position: absolute;
        width: 25%;
        margin: 0;
        text-align: left;
        color: $color--black;
      }
      h6 {
        padding-bottom: 50px;
        color: $color--white;
        @include breakpoint($bp--largest-up) {
          padding-bottom: 0;
          color: $color--black;
        }
      }
      p {
        font-size: 11px;
        color: $color--black;
        @include breakpoint($bp--largest-up) {
          color: $color--white;
        }
      }
      .loyalty-select__bottom & {
        display: none;
      }
    }
  }
  &__content {
    @include swap_direction(padding, 20px 0);
    text-align: center;
    #loyalty__page__index-bottom & {
      @include swap_direction(padding, 0);
    }
  }
  &__header {
    @include swap_direction(padding, 0 20px);
    @include breakpoint($bp--largest-up) {
      @include swap_direction(padding, 0);
      text-align: #{$ldirection};
    }
    .loyalty-select__bottom & {
      display: none;
    }
    #loyalty__page__index-bottom & {
      display: none;
    }
    .is-eligible & {
      display: none;
    }
    &-col1 {
      @include breakpoint($bp--largest-up) {
        float: #{$ldirection};
        width: 25%;
      }
    }
    &-col2 {
      @include breakpoint($bp--largest-up) {
        @include swap_direction(padding, 0 0 0 25%);
      }
    }
  }
  &__title {
    @include swap_direction(margin, 0);
  }
  &__intro {
    @include swap_direction(margin, 20px 0);
    @include breakpoint($bp--largest-up) {
      @include swap_direction(margin, 30px 0 15px 0);
    }
    p {
      @include swap_direction(margin, 0);
    }
  }
  &__join {
    font-size: $typesize--18px;
    max-width: 299px;
    width: 100%;
  }
  &__signin {
    @include swap_direction(margin, 15px 0 30px 0);
    p {
      @include swap_direction(margin, 0);
      @include breakpoint($bp--largest-up) {
        display: inline;
      }
    }
  }
  &__benefits {
    border-bottom: 1px solid $color--border;
    @include swap_direction(margin, 15px 0);
    position: relative;
    @include breakpoint($bp--largest-up) {
      @include swap_direction(margin, 30px 0);
    }
    .loyalty-select__top & {
      display: none;
    }
    input.error {
      border: 1px solid;
    }
    #loyalty__page__index-bottom & {
      @include swap_direction(margin, 0 0 15px 0);
    }
    /* 3 columns */
    .mac-select__marketing-page__benefits__section:first-child:nth-last-child(3),
    .mac-select__marketing-page__benefits__section:first-child:nth-last-child(3)
      ~ .mac-select__marketing-page__benefits__section,
    &__section {
      @include breakpoint($bp--largest-up) {
        @include layout($grid--3) {
          &:nth-of-type(3n + 1) {
            @include grid-span(1, 1);
          }
          &:nth-of-type(3n + 2) {
            @include grid-span(1, 2);
            .mac-select__marketing-page__benefits__section-copy {
              border-#{$ldirection}: 1px solid $color--border;
              border-#{$rdirection}: 1px solid $color--border;
            }
          }
          &:nth-of-type(3n + 3) {
            @include grid-span(1, 3);
          }
        }
      }
    }
    &__section {
      &-title {
        color: $color--white;
        height: 90px;
        line-height: 1;
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 15px 20px);
        h3 {
          @include swap_direction(margin, 0);
          .inactive & {
            @include opacity(0.4);
          }
        }
        p {
          line-height: 1;
          @include swap_direction(margin, 0);
          .inactive & {
            @include opacity(0.4);
          }
        }
      }
      &-copy {
        @include swap_direction(padding, 10px 30px);
        text-align: #{$ldirection};
        .inactive & {
          @include opacity(0.4);
        }
        ul {
          list-style: disc;
          list-style-image: image-url('global/mac-bullet-small.png');
          margin-#{$ldirection}: 0;
        }
        li {
          @include swap_direction(margin, 10px 0);
        }
        p {
          @include swap_direction(margin, 0);
        }
      }
      #loyalty__page__index-bottom & {
        border-bottom: none;
      }
    }
  }
  &__disclaimer {
    color: $color--gray;
    .loyalty-select__top & {
      display: none;
    }
    p {
      @include swap_direction(margin, 0 0 15px 0);
    }
  }
  &__questions {
    @include swap_direction(padding, 0 20px 15px);
    .loyalty-select__top & {
      display: none;
    }
    #loyalty__page__about &,
    #loyalty__page__index-bottom & {
      display: none;
    }
    .is-eligible & {
      display: none;
    }
    &-title {
      @include swap_direction(margin, 0 0 15px 0);
    }
    &-copy {
      p {
        @include swap_direction(margin, 0);
      }
    }
  }
}

.loyalty-select__top {
  .tout-block-landscape {
    display: none;
  }
}

.mac-select__homepage-tout {
  height: 100%;
  @include swap_direction(padding, 15px);
  position: relative;
  @include breakpoint($bp--largest-up) {
    text-align: #{$ldirection};
  }
  .unique-small-snowflake & {
    background: $color--white;
  }
  .unique-tiny-snowflake & {
    @include reverse;
  }
  &__title {
    @include fontsize-rem(18px);
    @include swap_direction(margin, 0 0 5px 0);
    @include breakpoint($bp--medium-up) {
      @include fontsize-rem($typesize--24px);
      @include swap_direction(margin, 0 0 15px 0);
    }
    @include breakpoint($bp--largest-up) {
      @include fontsize-rem(15px);
      @include swap_direction(margin, 0 0 5px 0);
    }
    @include breakpoint($bp--xxlarge-up) {
      @include fontsize-rem($typesize--24px);
      @include swap_direction(margin, 0 0 15px 0);
    }
  }
  &__copy {
    line-height: 1.2;
    @include swap_direction(margin, 0 0 15px 0);
    @include breakpoint($bp--medium-up) {
      line-height: 1.5;
    }
    @include breakpoint($bp--largest-up) {
      line-height: 1;
    }
    @include breakpoint($bp--xlarge-up) {
      line-height: 1.2;
    }
    @include breakpoint($bp--xxlarge-up) {
      line-height: 1.5;
    }
    p {
      @include swap_direction(margin, 0);
    }
  }
  &__ctas {
    bottom: 15px;
    #{$ldirection}: auto;
    position: absolute;
    width: 100%;
  }
}

.mac-select__account-panel {
  #loyalty__panel__points & {
    @include swap_direction(padding, 20px);
    text-align: center;
    @include breakpoint($bp--largest-up) {
      @include swap_direction(margin, 0 auto);
      max-width: 60%;
    }
  }
  &__logo {
    img {
      display: block;
      height: 45px;
      @include swap_direction(margin, 15px 0 15px 0);
      width: auto;
    }
    #loyalty__panel__points & {
      @include swap_direction(margin, 15px auto);
      max-width: 248px;
      width: 30%;
      img {
        height: auto;
        @include swap_direction(margin, auto);
      }
    }
  }
  &__content {
    &-title {
      @include swap_direction(margin, 0 0 15px 0);
    }
  }
  &__progress {
    #loyalty__panel__points & {
      @include breakpoint($bp--largest-up) {
        max-width: 50%;
      }
    }
    .mac-select__account-panel__logo {
      display: block;
      @include swap_direction(margin, 15px auto 30px);
      max-width: 248px;
      text-align: center;
      width: 30%;
      @include breakpoint($bp--largest-up) {
        margin-top: 36px;
      }
    }
    .mac-select__account-panel__content {
      &-id,
      &-spend {
        @include swap_direction(margin, 0 0 5px 0);
        text-transform: uppercase;
        #loyalty__panel__points & {
          white-space: nowrap;
        }
      }
    }
  }
}

.mac-select__progress {
  @include swap_direction(margin, 20px 0);
  position: relative;
  &-tier {
    height: 45px;
    @include swap_direction(margin, 10px 0);
    #loyalty__panel__points & {
      height: 60px;
    }
  }
  &-indicator {
    background: $color--white;
    height: 65px;
    position: absolute;
    top: -10px;
    width: 1px;
    #loyalty__panel__points & {
      background: $color--gray--lightest;
      height: 80px;
    }
  }
  &-border {
    @include swap_direction(border, 1px solid $color--white);
    border-#{$rdirection}: none;
    height: 45px;
    position: absolute;
    top: 0;
    @if $mac-lover == true {
      border: 0;
    }
    #loyalty__panel__points & {
      @include swap_direction(border, 3px solid $color--gray--lightest);
      border-#{$rdirection}: none;
      height: 64px;
      top: -2px;
      @if $mac-lover == true {
        border: 0;
      }
    }
  }
  &-label {
    text-align: #{$ldirection};
    white-space: nowrap;
  }
}

.panel--loyalty-join {
  .loyalty__logo {
    display: block;
    @include swap_direction(margin, 15px auto);
    max-width: 100px;
    width: 30%;
    @include breakpoint($bp--largest-up) {
      display: inline;
      @include swap_direction(margin, 0);
    }
  }
}

.loyalty__content-bottom {
  &__section {
    border-top: $border;
    &-header {
      @include swap_direction(padding, 30px 20px);
    }
    &-title {
      cursor: pointer;
      text-align: center;
      i {
        float: #{$rdirection};
      }
      &.open {
        i:before {
          content: map-get($icons, arrow--up);
        }
      }
    }
    &-content {
      &.macselect-faqs {
        .node {
          p {
            display: none;
          }
        }
        .mac-faq {
          &__heading {
            display: none;
          }
          p {
            display: block;
          }
        }
      }
    }
  }
}

.checkout__sidebar {
  .loyalty-anniversary-panel {
    &.panel {
      @include swap_direction(padding, 0);
    }
    &__header {
      @include swap_direction(padding, 1em);
    }
    .product {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      &__sku-details {
        @include swap_direction(padding, 1em);
        position: relative;
        .product__image-small {
          max-width: 124px;
        }
        .shade-picker__color-name {
          line-height: 1;
          @include swap_direction(margin, 0 0 10px 0);
          @include swap_direction(padding, 0);
        }
        .shade-picker__color-description {
          display: block;
        }
      }
    }
    .shade-picker {
      &__wrapper {
        overflow: hidden;
        position: relative;
      }
      &__controls {
        i {
          float: none;
          &:before {
            color: $color--white;
          }
        }
        .icon--arrow--up {
          &:before {
            content: map-get($icons, arrow--up);
          }
        }
        .icon--arrow--down {
          &:before {
            content: map-get($icons, arrow--down);
          }
        }
      }
      &__previous {
        top: 0;
      }
      &__next {
        bottom: 0;
      }
      &__colors {
        height: 300px;
        @include swap_direction(margin, 30px 0px);
        overflow: auto;
        position: relative;
        width: calc(100% + 17px);
        &-item,
        .shade-picker__color-texture,
        .shade-picker__color-chip {
          height: 60px;
        }
        .shade-picker__color-wrapper {
          display: block;
          height: 60px;
          width: 100%;
        }
        .selected,
        &-item:hover {
          .shade-picker__color-chip {
            opacity: 0;
          }
          .shade-picker__color-details {
            display: none;
          }
        }
      }
    }
    &__footer {
      @include swap_direction(padding, 1em);
    }
  }
  .loyalty-birthday-panel {
    .checkout__offer-code__wallet {
      .product__sku-details {
        .product {
          &__image-small {
            left: 25%;
            position: relative;
            width: 50%;
            padding: 0;
          }
          &__header {
            text-align: center;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
      .slick {
        &-slide img {
          margin: 0 auto;
        }
        &-prev {
          #{$ldirection}: 5px;
          top: 100px;
        }
        &-next {
          #{$rdirection}: 5px;
          top: 100px;
        }
      }
    }
    .slick-slider {
      margin-bottom: 0px;
    }
  }
}

.checkout-confirmation-page {
  ul {
    list-style: disc;
    list-style-image: image-url('global/mac-bullet-small.png');
    margin-#{$ldirection}: 0;
  }
}

.checkout-optimization {
  .checkout-confirmation-page {
    ul {
      margin-#{$ldirection}: 15px;
    }
    .loyalty-user__username {
      visibility: hidden;
      height: 0;
      width: 0;
      padding: 0;
      border: none;
      float: #{$ldirection};
    }
  }
  @include breakpoint($bp--medium-up) {
    #confirmation-page {
      .column {
        #loyalty-join-panel,
        #registration-panel-loyalty {
          .panel--loyalty-join__content {
            width: 75%;
            .first_name {
              margin-bottom: 0;
            }
            .field {
              width: 500px;
              &#form--loyalty--field--FIRST_NAME,
              &#form--loyalty--field--LAST_NAME {
                max-width: 100%;
              }
              &#form--loyalty--field--MAC_SELECT_ID {
                margin-bottom: 15px;
              }
            }
            .field-container {
              margin-bottom: 10px;
            }
            .password {
              .field {
                margin-#{$ldirection}: 0;
              }
            }
            .loyalty_btn {
              width: 224px;
              height: 60px;
            }
            .loyalty-qualify__subtitle {
              font-size: 30px;
              line-height: 36px;
              margin-top: 0;
            }
          }
        }
        #registration-panel-loyalty {
          .checkout__panel-content {
            width: 75%;
            .panel--loyalty-join__content {
              margin-#{$ldirection}: 0;
            }
            .loyalty-qualify__subtitle {
              margin-top: 0;
            }
            .continue-button-wrapper {
              text-align: #{$ldirection};
              .btn {
                width: 224px;
                height: 60px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

.mac-select__marketing-page {
  &__lover {
    margin: 0 2%;
    overflow-x: auto;
    @include breakpoint($bp--largest-up) {
      margin: 0 15%;
      overflow-x: hidden;
    }
    .mac-select__marketing-page__benefits__section {
      float: #{$ldirection};
      margin: 0;
      clear: none;
      flex: 1 0 0;
      width: auto;
      ul {
        list-style: none;
        list-style-image: none;
        margin-#{$ldirection}: 0;
        li {
          border-bottom: 1px solid $color--border;
          padding: 13px 0;
          margin: 0;
          height: 95px;
          display: flex;
          align-items: center;
          @include breakpoint($bp--largest-up) {
            height: 70px;
          }
        }
      }
      &-all {
        flex: 3 0 0;
        .mac-select__marketing-page__benefits__section-title {
          text-align: #{$ldirection};
          padding: 0;
        }
        ul li {
          padding-bottom: 12px;
          width: 160px;
          text-transform: uppercase;
          font-family: $ano-bold_regular-font;
          @include breakpoint($bp--largest-up) {
            width: auto;
          }
          span {
            font-size: 11px;
            font-family: $roboto-mono_regular-font;
          }
        }
        .mac-select__marketing-page__benefits__section-copy {
          border-#{$rdirection}: 0;
          padding: 0;
        }
      }
      &-copy {
        padding: 10px 0;
        border-#{$rdirection}: 1px solid $color--border;
        border-#{$ldirection}: 1px solid $color--border;
        margin-left: -1px;
        &.mac-select__swatch-tier1 {
          border-#{$ldirection}: 1px solid $color--border;
          margin-#{$ldirection}: 0;
        }
        &.mac-select__swatch-lovertier4,
        &.mac-select__swatch-tier3,
        &.mac-select__swatch-tier2,
        &.mac-select__swatch-tier1 {
          background: transparent;
          padding: 0;
          li {
            span {
              text-indent: -999em;
              border-radius: 10px;
              width: 20px;
              height: 20px;
              margin: 0 auto;
              display: block;
            }
          }
        }
        &.mac-select__swatch-lovertier4 {
          border-left: 0;
          li span {
            background-color: $lovertier4color1;
          }
        }
        &.mac-select__swatch-tier4 ul li span {
          background-color: $color--black;
        }
        &.mac-select__swatch-tier3 li span {
          background-color: $color--gray-tier3;
        }
        &.mac-select__swatch-tier2 li span {
          background-color: $color--red-tier2;
        }
        &.mac-select__swatch-tier1 ul li span {
          background-color: $color--pink-tier1;
        }
      }
    }
    .mac-select__marketing-page__benefits {
      border-bottom: 0;
      &__section-title {
        color: $color--black;
        text-align: #{$ldirection};
        background: transparent;
        @include swap_direction(padding, 15px 10px 0 10px);
        @include breakpoint($bp--largest-up) {
          height: 130px;
        }
        h3 {
          color: $color--white;
          font-size: 16px;
          line-height: 14px;
          text-align: #{$ldirection};
          font-family: $ano-bold_regular-font;
          @include breakpoint($bp--largest-up) {
            font-size: 20px;
          }
        }
        p {
          font-family: $roboto-condensed-regular-font;
          color: $color--white;
          font-size: 15px;
          text-transform: uppercase;
          line-height: 18px;
        }
        p + p {
          text-transform: none;
          font-size: 11px;
          width: 87px;
          padding-top: 2px;
          font-family: $ano-bold_regular-font;
          line-height: 12px;
          @include breakpoint($bp--largest-up) {
            width: auto;
          }
        }
        h1 {
          display: none;
          @include breakpoint($bp--largest-up) {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0;
            img {
              width: auto;
              height: 100%;
            }
          }
        }
      }
      &__wrapper {
        display: flex;
        &.mac-select__marketing-page__benefits__wrapper-hover {
          .inactive {
            .mac-select__marketing-page__benefits__section-title *,
            .mac-select__marketing-page__benefits__section-copy {
              @include opacity(0.4);
            }
            &.mac-select__marketing-page__benefits__section-all * {
              @include opacity(1);
            }
          }
        }
        .mac-select__marketing-page__benefits__section-copy,
        .mac-select__marketing-page__benefits__section-title * {
          @include opacity(1);
        }
      }
    }
  }
}

@if $mac-lover == true {
  .mac-select__account-panel {
    &__content {
      .mac-select__progress-level {
        position: absolute;
        top: 10px;
        color: $color--white;
        #{$ldirection}: 12px;
        text-align: #{$ldirection};
        font-family: $ano-bold_regular-font;
        &__title {
          font-size: 17px;
          line-height: 0.765;
          text-transform: uppercase;
        }
        &__tier {
          font-size: 10px;
        }
      }
      &-title {
        margin: 0;
        a {
          border: 0;
        }
        span {
          font-family: $ano-bold_regular-font;
          font-size: 15px;
        }
        p {
          padding: 0;
          margin: 0;
          font-size: 10px;
          font-family: $ano-bold_regular-font;
        }
      }
    }
  }
  .account-loyalty {
    .mac-select__color-tier1,
    .mac-select__color-tier2,
    .mac-select__color-tier3,
    .mac-select__color-lovertier4 {
      color: $color--white;
    }
  }
  .mac-select__progress {
    .mac-select__swatch-tier1 {
      background: $color--pink-tier1;
    }
    .mac-select__swatch-tier2 {
      background: $color--red-tier2;
    }
    .mac-select__swatch-tier3 {
      background: $color--gray-tier3;
    }
    .mac-select__swatch-tier4 {
      background: $color--black;
    }
  }
  // middle colors of tier gradients, for text effects
  .loyalty__panel,
  .site-my-mac__contents {
    .mac-select__color-tier1 {
      color: $color--pink-tier1;
    }
    .mac-select__color-tier2 {
      color: $color--red-tier2;
    }
    .mac-select__color-tier3 {
      color: $color--gray-tier3;
    }
    .mac-select__color-tier4 {
      color: $color--black;
    }
    &__next-tier__pending {
      color: $color--black;
    }
  }
}
