.enhanced_cart_page {
  .viewcart {
    .accordionPanel {
      .order-summary-panel__title {
        i {
          bottom: 0;
        }
      }
      .order-summary__content {
        .ship-label-sec {
          .shipping-label-data {
            text-transform: none;
          }
        }
      }
    }
    .shipmethod-price {
      display: none;
    }
    .shipping-option {
      text-transform: none;
    }
  }
}

.enhanced_signin_page {
  .responsive-container {
    .checkout {
      .checkout {
        &__content {
          #sign-in-panel {
            .checkout {
              &__new-account {
                #checkout_signin_guest_user {
                  .form-item {
                    .label {
                      height: auto;
                    }
                  }
                  .accepted-privacy-policy {
                    display: block;
                  }
                  .pc-email-promotion,
                  .accepted-privacy-policy {
                    .label {
                      margin-top: 0px;
                      .label-content {
                        display: block;
                        margin-top: calc(-54px - 1px);
                        padding: 19px 23px 0 14px;
                        line-height: 1.5;
                        font-size: 10.5px;
                        letter-spacing: 1.8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
#form {
  &--checkout_signin_new_user {
    &--field {
      &--NEW_USER_EMAIL_ADDRESS {
        height: calc(54px - -2px);
      }
    }
  }
}
