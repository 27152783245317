///
/// @file base/_typography--helpers.scss
///
/// \brief Reusable, generic typographic styles used throughout the site.
///
/// Guidelines:
///
/// Generic classes we can use sitewide for display elements.
/// WARNING do not change values unless you know why you are here

// Roboto Mono Regular
.font--roboto {
  font-family: $roboto-mono_regular-font;
}

// Roboto Mono Bold
.font--roboto-bold {
  font-family: $roboto-mono_bold-font;
}

// Helvetica
.font--helvetica {
  font-family: $helvetica-font;
}

// Ano Black Regular
.font--ano-black-regular {
  font-family: $ano-black_regular-font;
}

// Ano Bold Regular
.font--ano-bold-regular {
  font-family: $ano-bold_regular-font;
}

// Ano Regular
.font--ano-regular {
  font-family: $ano_regular-font;
}

// Heading 1
.title--h1 {
  @include h1();
}

// Heading 2
.title--h2 {
  @include h2();
}

// Heading 3
.title--h3 {
  @include h3();
}

// Heading 4
.title--h4 {
  @include h4();
}

// Heading 5
.title--h5 {
  @include h5();
}

// Heading 6
.title--h6 {
  @include h6();
}

// Buttons
.button,
.elc-button {
  @include button;
}

.button--disabled,
.elc-button--disabled {
  @include button--disabled;
}

.button--underline,
.elc-button--underline {
  @include button--underline;
}

// Links
.link,
.elc-link {
  @include link--base;
}

.link--underline,
.elc-link--underline {
  @include link--caps;
}

.link--small,
.elc-link--small {
  @include link--underline;
}

.link-unset-underline-extend {
  @include unset-underline-links($extend: false);
}

.link--no-underline {
  @include unset-underline-links($extend: true);
}

.clickable-processed {
  cursor: pointer;
}

.at-h1__typography {
  @include h1();
}

.at-h2__typography {
  @include h2();
}

.at-h3__typography {
  @include h3();
}

.at-h4__typography {
  @include h4();
}

.at-h5__typography {
  @include h5();
}

.at-h6__typography {
  @include h6();
}

// Breadcrumbs
.breadcrumb {
  @include breadcrumb--base();
}

// 2021 style refresh
// NEW typography helpers
// see global/typography.scss for mixins

// Title01
.title-01 {
  @include title-01();
}

// Title02
.title-02 {
  @include title-02();
}

// Title02 - Bold
.title-02--bold {
  @include title-02--bold();
}

// Title03 - Regular
.title-03 {
  @include title-03();
}

// Title03 - Bold
.title-03--bold {
  @include title-03--bold();
}

// Subtitle - Regular
.sub-title {
  @include sub-title();
}

// Subtitle - Bold
.sub-title--bold {
  @include sub-title--bold();
}

// Body - Regular
.body-text {
  @include body-text();
}

// Body - Bold
.body-text--bold {
  @include body-text--bold();
}

// Small Text - Regular
.body-text--small {
  @include body-text--small();
}

// Small Text - Bold
.body-text--small-bold {
  @include body-text--small-bold();
}

// Legal - Regular
.legal-text {
  @include legal-text();
}

// Legal - Bold
.legal-text--bold {
  @include legal-text--bold();
}

// ELC specific headings unded in react components
.elc-heading--3 {
  @include sub-title--bold;
}
