.enhanced-delivery-page {
  .checkout {
    &__sidebar {
      &.right {
        float: $ldirection;
        height: 100%;
        margin-top: 13px;
        width: 100%;
        @include breakpoint($width-largest) {
          background-color: $color--gray--background;
          margin-top: 0;
          width: 380px;
        }
        #order-summary-panel {
          background-color: $color--white;
          border-bottom: 1px solid $color--black;
          border-top: 1px solid;
          padding: 12px 17px;
          @include breakpoint($width-largest) {
            border-bottom: 1px solid $color--gray--dusty--disable;
            border-top: 1px solid $color--mercury;
            padding: 14px 13px 10px;
          }
          .order-summary {
            &__content {
              font-family: $roboto-mono_regular-font;
              margin-top: 12px;
              overflow: visible;
              @include breakpoint($width-largest) {
                margin-top: 16px;
              }
              .value.total {
                padding: 16px 0 0;
              }
              .label,
              .value {
                font-family: $roboto-mono_regular-font;
                font-size: 12px;
                letter-spacing: 0.25px;
                line-height: 1.7;
                margin-bottom: 5px;
                text-transform: none;
                @include breakpoint($width-largest) {
                  margin-bottom: 7px;
                }
              }
              .label {
                color: $color--gray--dusty--disable;
              }
              .value {
                color: $color--nero--thin;
                font-family: $ano-bold_regular-font;
                text-transform: uppercase;
              }
              .label.total,
              .value.total {
                background-color: $color--white;
                color: $color--nero--thin;
                font-family: $ano-bold_regular-font;
                font-size: 16px;
                line-height: 1;
                margin: 0 !important;
                padding: 20px 0 0;
                text-transform: uppercase;
              }
              .order-summary {
                &__tax {
                  &-label {
                    font-size: 12px;
                    line-height: 1.34;
                    margin: 12px 0 0;
                    width: 100%;
                  }
                  &-value {
                    display: none;
                  }
                }
              }
              .discount-save {
                clear: both;
                padding-top: 12px;
              }
            }
            &-panel__title {
              font-family: $ano-bold_regular-font;
              font-size: 16px;
              margin: 0;
              text-align: $ldirection;
              text-transform: uppercase;
            }
          }
        }
        .shipping-address-content-panel {
          &.panel {
            background-color: $color--white;
            border-bottom: 1px solid $color--black;
            @include breakpoint($width-largest) {
              border-bottom-color: $color--gray--dusty--disable;
            }
          }
          &__content {
            margin: 13px 0 10px;
            position: relative;
            width: 100%;
            p {
              color: $color--nero--thin;
              font-size: 12px;
              letter-spacing: 0.25px;
              line-height: 1.7;
              margin: 0;
              width: calc(100% - 24px);
            }
          }
          &-edit {
            #{$ldirection}: calc(100% - 22px);
            background: url('/media/images/checkout/edit_icon.png') no-repeat;
            background-size: cover;
            border: 0;
            cursor: pointer;
            display: block;
            height: 18px;
            position: absolute;
            width: 18px;
          }
        }
        .billing-address,
        .shipmethod {
          &-panel.panel {
            background-color: $color--white;
            border-bottom: 1px solid $color--black;
            @include breakpoint($width-largest) {
              border-bottom-color: $color--gray--dusty--disable;
            }
            &.collect,
            &.empty-billing-address {
              display: none;
            }
          }
          &__content {
            margin: 13px 0 10px;
            position: relative;
            width: 100%;
            .view-address,
            .payment-address {
              letter-spacing: 0.25px;
              margin: 0;
              width: calc(100% - 24px);
              p {
                color: $color--nero--thin;
                font-size: 12px;
                margin: 0;
                letter-spacing: 0.25px;
                line-height: 1.67;
              }
            }
            a {
              #{$ldirection}: calc(100% - 22px);
              background: url('/media/images/checkout/edit_icon.png') no-repeat;
              background-size: contain;
              border: 0;
              cursor: pointer;
              display: block;
              height: 18px;
              margin: 0;
              outline: 0;
              position: absolute;
              text-decoration: none;
              text-indent: -9999px;
              top: 0;
              width: 18px;
            }
          }
        }
        .links-panel {
          background: none;
          border-bottom: 1px solid $color--black;
          border-top: 1px solid $color--black;
          @include breakpoint($width-largest) {
            border-bottom: 0;
            border-top-color: $color--gray--dusty--disable;
          }
          &__title {
            display: none;
          }
          header {
            @include breakpoint($width-largest) {
              pointer-events: none;
            }
            .accordion-icon {
              @include breakpoint($width-largest) {
                display: none;
              }
            }
          }
          .content {
            display: none;
            margin-top: 20px;
            @include breakpoint($width-largest) {
              display: block;
              margin-top: 30px;
            }
            .link {
              border-bottom: none;
              display: block;
              font-family: $roboto-mono_regular-font;
              font-size: 12px;
              margin-bottom: 0;
              padding-bottom: 18px;
            }
            a {
              border-bottom: none;
              color: $color--nero--thin;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 0.25px;
              line-height: 1.34;
              text-decoration: underline;
              text-transform: uppercase;
            }
          }
        }
        #offer-code-panel {
          background-color: $color--white;
          border-bottom: 1px solid $color--black;
          padding: 12px 17px;
          @include breakpoint($width-largest) {
            border-bottom-color: $color--gray--dusty--disable;
            padding: 14px 13px 11px;
          }
          .content {
            .offer-code-error {
              color: $color--error--red;
              font-size: 14px;
              margin: 8px 0;
              .single-message,
              #offer_criteria_not_met_3for2,
              #offer_criteria_not_met_feb_gwp,
              #offer_criteria_not_met_cc_incentive_benefits {
                display: none;
              }
              .single-message {
                &:first-child,
                &.invalid_offer_code,
                &.offer-code-success,
                &.offer_criteria_not_met {
                  display: block;
                }
              }
              &.offer-code-success,
              .offer-code-success {
                color: $color--green--medium;
              }
            }
          }
          .offer-code {
            &__info {
              color: $color--green--medium;
              font-size: 12px;
              letter-spacing: 0.25px;
              line-height: 1.7;
              margin: 8px 0;
              position: relative;
              width: 90%;
            }
            &-panel__title {
              text-align: $ldirection;
            }
            &__edit-icon {
              #{$rdirection}: 0;
              background: url('/media/images/checkout/edit_icon.png') no-repeat;
              background-size: cover;
              cursor: pointer;
              display: none;
              float: $rdirection;
              height: 20px;
              position: absolute;
              top: 0;
              width: 20px;
            }
          }
          form {
            padding: 8px 0;
            position: relative;
            .form--offer_code_text {
              background: $color--white;
              border: 1px solid $color--gray--dusty;
              font-size: 12px;
              height: 56px;
              letter-spacing: 1px;
              margin-#{$rdirection}: 112px;
              opacity: 0.68;
              padding-#{$ldirection}: 15px;
              width: 100%;
              @include breakpoint($width-xlarge) {
                margin-#{$rdirection}: 0;
              }
            }
            .label-content {
              color: $color--gray--darker;
            }
            .offer-code {
              &__input {
                float: none;
                margin: 0;
                text-align: $ldirection;
                width: 65%;
                @include breakpoint($width-largest) {
                  @include swap_direction(margin, 0 125px 0 0);
                }
                .valid_marker,
                .invalid_marker {
                  z-index: 1;
                }
                .invalid_marker {
                  #{$rdirection}: 15px;
                  top: 16px;
                }
              }
              &__submit {
                #{$rdirection}: 0;
                position: absolute;
                top: 18px;
                @include breakpoint($width-largest) {
                  top: 15px;
                }
                .form-submit {
                  height: 56px;
                  line-height: 1.6;
                  margin: 0;
                  min-width: 100px;
                  padding: 0;
                  width: auto;
                  @include breakpoint($width-largest) {
                    min-width: 113px;
                  }
                }
              }
            }
          }
        }
        .checkout-panel {
          @include swap_direction(padding, 0 12px 0 13px);
          width: 100%;
          &--loyalty-points {
            margin-top: 20px;
            .loyalty-points-content__summary__non-member-content {
              margin-bottom: 0;
            }
          }
        }
        #shopping-bag-wrapper-panel {
          background-color: $color--white;
          border-bottom: 1px solid $color--black;
          @include breakpoint($width-largest) {
            border: 0;
          }
          .checkout__panel-title {
            span {
              display: none;
            }
          }
          .panel.shopping-bag-panel {
            background-color: $color--white;
            padding: 12px 17px;
            @include breakpoint($width-largest) {
              padding: 14px 13px 11px;
            }
            &__title {
              margin: 0;
            }
            .item-count {
              line-height: 0;
              position: relative;
              &__number,
              &__language {
                display: none;
              }
              &__edit {
                .edit {
                  border: 0;
                  display: block;
                  outline: 0;
                  text-decoration: none;
                  text-indent: -9999px;
                }
                .shopping-bag-edit-icon {
                  #{$ldirection}: calc(100% - 22px);
                  background: url('/media/images/checkout/edit_icon.png') no-repeat;
                  background-size: cover;
                  cursor: pointer;
                  display: block;
                  height: 18px;
                  position: absolute;
                  top: -3px;
                  text-indent: -9999px;
                  width: 18px;
                }
              }
            }
          }
          #viewcart-shopping-bag-panel {
            background: $color--white;
            border-bottom: 0;
            margin: 0;
            .viewcart-panel {
              &__content {
                margin: 0;
              }
            }
            .cart-items {
              max-height: none;
              .cart-item {
                background: $color--white;
                margin: 0;
                padding: 0 12px 10px;
                overflow: hidden;
                @include breakpoint($width-largest) {
                  @include swap_direction(padding, 0 12px 0 13px);
                  margin: 0 0 6px;
                }
                &__total {
                  display: block;
                  float: $rdirection;
                  font-family: $roboto-mono_bold-font;
                  font-size: 12px;
                  line-height: 1.69;
                  margin: 0;
                  position: relative;
                  text-transform: uppercase;
                  width: auto;
                }
                &__disc-price {
                  & + .cart-item__total {
                    width: auto;
                  }
                }
                &.sample {
                  .sub_line {
                    display: none;
                  }
                  .cart-item__total {
                    display: none;
                  }
                  .price-qty-total-column {
                    .product__price {
                      display: block;
                      margin: 0;
                    }
                  }
                  .sample-item,
                  .sample-item__qty {
                    color: $color--gray--dusty--disable;
                    display: block;
                    float: none;
                    font-family: $roboto-mono_regular-font;
                    font-size: 12px;
                    line-height: 1.69;
                    margin: 0 0 6px;
                    position: relative;
                    text-transform: none;
                    width: auto;
                  }
                }
                &__thumb {
                  margin: 0;
                  width: 117px;
                  @include breakpoint($width-largest) {
                    @include swap_direction(padding, 7px 7px 0 0);
                  }
                }
                &__desc {
                  padding: 0;
                  width: calc(100% - 117px);
                  @include breakpoint($width-largest) {
                    padding: 7px 0 0;
                  }
                }
                .price-qty-total-column {
                  margin-#{$ldirection}: 117px;
                  padding: 0;
                  width: calc(100% - 117px);
                  .product__price {
                    display: flex;
                    justify-content: space-between;
                  }
                }
                &__product-name {
                  color: $color--nero--thin;
                  font-size: 12px;
                  letter-spacing: 0.25px;
                  line-height: 1.34;
                  margin: 0 0 6px;
                }
                &__size {
                  color: $color--gray--dusty--disable;
                  font-size: 12px;
                  letter-spacing: 0.25px;
                  line-height: 1.67;
                  margin: 0 0 6px;
                }
                &__product-subname,
                &__price {
                  display: none;
                }
                del,
                &__unit-price,
                &__discount-percentage,
                &__disc-price,
                &__price__info,
                &__qty {
                  color: $color--gray--dusty--disable;
                  font-family: $roboto-mono_regular-font;
                  font-size: 12px;
                  letter-spacing: 0.25px;
                  line-height: 1.67;
                }
                &__unit-price,
                del,
                &__discount-percentage,
                &__disc-price {
                  width: auto;
                }
                &__price {
                  float: $ldirection;
                  width: 100%;
                  margin-top: 12px;
                  &-label__discount {
                    #{$rdirection}: 4px;
                    float: $rdirection;
                    position: relative;
                  }
                  &--after-discount {
                    display: none;
                  }
                }
                &__color {
                  display: inline-flex;
                  margin: 6px 0 0;
                  width: 100%;
                  .swatch {
                    @include swap_direction(margin, 0 8px 0 0);
                    border-radius: 50%;
                    height: 16px;
                    width: 16px;
                  }
                  .shade {
                    color: $color--gray--dusty--disable;
                    font-size: 12px;
                    letter-spacing: 0.25px;
                    line-height: 1.6;
                    margin: 0 0 6px;
                  }
                }
                &__qty {
                  color: $color--gray--dusty--disable;
                  float: none;
                  margin: 0;
                  padding: 0;
                  width: 100%;
                }
              }
              .kit-container {
                .cart-item {
                  padding: 15px 13px;
                }
              }
            }
          }
        }
        .accordionPanel {
          padding: 12px 17px 12px;
          @include breakpoint($width-largest) {
            padding: 14px 13px 11px;
          }
          .checkout {
            &__panel-title {
              border: 0;
              color: $color--nero--thin;
              font-size: 16px;
              letter-spacing: 0.25px;
              line-height: 1.4;
              margin: 0;
              padding: 0;
              text-transform: uppercase;
            }
          }
        }
        #need-help-panel {
          display: none;
        }
        #continue-btn {
          margin: 0;
          padding: 0 16px;
          .btn {
            background-color: $color--black;
            color: $color--white;
            cursor: pointer;
            font-size: 16px;
            height: auto;
            line-height: 1.5;
            margin: 24px 0 19px;
            padding: 12px 0;
            text-decoration: none;
            width: 100%;
            &.invalid {
              background-color: $color--gray--tier2;
              color: $color--black--opacity-4--checkout;
              pointer-events: none;
            }
          }
          .continue-payment-button,
          .continue-checkout-bottom {
            margin: 24px 0;
          }
        }
        .back_to_top {
          display: none;
          .sticky-back-to-top {
            @include transform(rotate(45deg));
            #{$rdirection}: 50%;
            position: absolute;
            top: 43%;
            &::before,
            &::after {
              background: $color--white;
              content: '';
              display: block;
              height: 2px;
              position: absolute;
              width: 9px;
            }
            &::after {
              height: 9px;
              width: 2px;
            }
          }
          @include breakpoint($width-largest) {
            #{$rdirection}: 80px;
            background: $color--black;
            border-radius: 50%;
            bottom: 40px;
            cursor: pointer;
            display: block;
            height: 45px;
            position: fixed;
            width: 45px;
            z-index: 1001;
          }
        }
        .payment-address {
          font-size: 12px;
          line-height: 1.7;
          .checkout__subtitle {
            display: none;
          }
        }
        .same-as-shipping {
          display: none;
        }
        .mobile-container-wrapper {
          display: none;
          @include breakpoint($width-largest) {
            display: block;
          }
          section#offer-code-panel {
            border-bottom: 1px solid $color--black;
            display: block;
            @include breakpoint($width-largest) {
              border-bottom-color: $color--gray--dusty--disable;
            }
            .offer-code {
              &__submit {
                top: 14px;
              }
            }
          }
        }
      }
      .panel {
        background-color: $color--white;
        margin-top: 0;
        @include breakpoint($width-largest) {
          background-color: $color--gray--background;
        }
      }
      .ship-label-sec {
        border-bottom: 1px solid $color--gray--tier5;
        display: block;
        padding-bottom: 16px;
        @include breakpoint($width-largest) {
          border-bottom: 1px solid $color--gray--dusty--disable;
        }
        .shipping-label-data {
          background-color: $color--white;
          border: 1px solid $color--gray--dusty--disable;
          color: $color--gray--dusty--disable;
          display: block;
          font-size: 12px;
          margin-top: 5px;
          line-height: 1.65;
          padding: 12px 14px;
          text-transform: none;
          @include breakpoint($width-largest) {
            line-height: 1.35;
          }
          .shipping-option {
            font-style: italic;
            margin-top: 10px;
          }
        }
      }
    }
  }
  &.checkout {
    &.active-panel-review {
      .checkout-header {
        display: block;
      }
    }
    &.active-panel-shipping {
      .checkout__sidebar {
        #shopping-bag-panel {
          display: block;
        }
      }
    }
    .site-container {
      .checkout__sidebar {
        #links-panel {
          margin-top: 13px;
          @include breakpoint($width-xlarge) {
            margin-top: 0;
          }
        }
      }
    }
  }
}
