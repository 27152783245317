.header-search {
  &__icon {
    border-bottom: none;
    display: block;
    font-size: 17px;
    height: $site-header-height;
    #{$ldirection}: 45px;
    line-height: $site-header-height;
    @include swap_direction(padding, 0 10px);
    position: absolute;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    &.search-active {
      background-color: $color--white;
      @include breakpoint($bp--medium-up) {
        display: none;
      }
      .icon--search {
        color: $color--black;
      }
    }
    .viewcart &,
    .checkout & {
      display: none;
      @include breakpoint($bp--large-up) {
        display: block;
      }
    }
    @include breakpoint($bp--largest-up) {
      position: static;
      font-size: 15px;
      @include swap_direction(margin, 0 0 0 -15px);
    }
    @include breakpoint($bp--xlarge-up) {
      font-size: 17px;
      @include swap_direction(margin, 0 0 0 -25px);
    }
    svg {
      fill: $color--white;
      height: 17px;
      width: 16px;
      .gnav-transparent--light:not(.site-header-formatter--sticky) & {
        fill: $color--white;
      }
      .gnav-transparent--dark:not(.site-header-formatter--sticky) & {
        fill: $color--black;
      }
    }
  }
  &__form {
    width: 100%;
    position: absolute;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    z-index: $z-highest;
    border-bottom: $border;
    margin-top: $site-header-height;
    background: $color--white;
    .site-header__fixed-wrapper & {
      //specificity override
      @include unset-reverse-text();
    }
    @include breakpoint($bp--largest-up) {
      margin-top: 0;
    }
  }
  &__form-remove {
    visibility: hidden;
    opacity: 0;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  .header-search__form--content {
    position: relative;
    flex-direction: column;
  }
  .header-search__field {
    // need extra specificity
    @include header-search-typography();
    @include swap_direction(padding, 0 $spacing);
    height: $site-header-height;
    border-width: 0;
    line-height: $site-header-height; //line-height: 1 not centering
    @at-root .touch & {
      @include swap_direction(padding, 15px $spacing);
      line-height: normal;
    }
    &:focus {
      border-width: 0;
    }
  }
  &__view-toggle {
    position: absolute;
    top: 19px;
    #{$rdirection}: 68px;
    @include swap_direction(padding, 0 10px);
    display: none;
    .section-esearch & {
      display: inline-block;
      @include breakpoint($bp--small-up) {
        display: none;
      }
    }
    &:hover {
      cursor: pointer;
    }
    > span {
      display: block;
      width: 4px;
      height: 4px;
      background: $color--black;
      margin-bottom: 4px;
    }
  }
  &__close {
    top: 5px;
    position: absolute;
    #{$rdirection}: $spacing;
    font-size: 30px;
    color: $color--black;
    z-index: ($z-highest + 1);
  }
  //begin typeahead and predictive search results
  &__typeahead-wrapper {
    @include swap_direction(padding, 0 $spacing);
    text-align: #{$ldirection};
  }

  &__see-results {
    height: $site-header-height;
    font-family: $body-font-family;
    border-top: $border;
    display: none;
    padding-top: 10px;
    line-height: $site-header-height;
    > a {
      @include unset-underline-links();
    }
    .search-results {
      @include breakpoint($bp--small-up) {
        float: #{$rdirection};
        padding-#{$ldirection}: 5px;
      }
      text-decoration: underline;
    }
    .search-count {
      @include breakpoint($bp--small-down) {
        display: block;
      }
    }
  }
  &__results {
    border-top: $border;
    @include swap_direction(padding, $spacing 0);
    @include clearfix();
    .header-search__result {
      width: 100%;
      @include breakpoint($bp--large-up) {
        @include layout($grid--2, $spacing) {
          &:nth-of-type(2n + 1) {
            @include grid-span(1, 1);
            clear: both;
          }
          &:nth-of-type(2n + 2) {
            @include grid-span(1, 2);
          }
        }
      }
      @include breakpoint($bp--largest-up) {
        @include layout($grid--4, $spacing) {
          &:nth-of-type(4n + 1) {
            @include grid-span(1, 1);
            margin-#{$ldirection}: 0;
            clear: both;
          }
          &:nth-of-type(4n + 2) {
            @include grid-span(1, 2);
          }
          &:nth-of-type(4n + 3) {
            @include grid-span(1, 3);
          }
          &:nth-of-type(4n + 4) {
            @include grid-span(1, 4);
          }
        }
      }
    }
    .search-overlay-landing {
      width: 100%;
    }
    a {
      @include unset-underline-links();
    }
  }
  &__result {
    &__body {
      @include breakpoint($bp--small-up) {
        float: #{$rdirection};
        width: 50%;
        padding-#{$ldirection}: ($spacing / 2);
      }
      .header-search__result__price {
        padding-top: 5px;
      }
    }
    &__image {
      display: none;
      @include breakpoint($bp--small-up) {
        display: block;
        float: #{$ldirection};
        width: 50%;
        padding-#{$rdirection}: ($spacing / 2);
      }
    }
    &__link {
      @include header-search-typography($bp: false);
      display: block;
      @include swap_direction(padding, 3px 0);
      @include breakpoint($bp--small-up) {
        @include fontsize-rem($typesize--18px);
        @include swap_direction(padding, 0);
        display: inline-block;
      }
    }
  }
}

.block-template-site-search-v1 {
  &.contextual-links-region,
  .contextual-links-region {
    position: static;
  }
}

.header-search__result__description {
  @include body-mono();
  text-transform: uppercase;
  &.sku-shaded .description-copy {
    display: none;
  }
  padding-top: 10px;
}

.section-esearch {
  #search-wrapper {
    .search-results__summary {
      .search-results__no-results {
        @include swap_direction(padding, 0 10px);
      }
    }
  }
}
