.loyalty__panel__next-tier {
  @include loyalty-panel;
  @include breakpoint($bp--largest-up) {
    border: none;
    margin: 0;
  }
  .loyalty__page__index-2col & {
    display: none;
    @include breakpoint($bp--largest-up) {
      display: block;
      float: left;
      width: 49%;
    }
  }
  .loyalty__page__index-mobile & {
    display: block;
    @include breakpoint($bp--largest-up) {
      display: none;
    }
  }
  &__title {
    margin: 0 0 15px 0;
  }
  &__pending {
    color: $color--gray;
    @include breakpoint($bp--largest-up) {
      width: 50%;
    }
  }
}
