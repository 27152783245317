.checkout-panel--wp-samples {
  h2.checkout-panel__heading {
    font-size: 18px;
    @include breakpoint($bp--small-up) {
      font-size: 24px;
    }
  }
  .checkout-panel__subheading {
    font-size: 12px;
  }
}

#checkout_wp_samples_container {
  .wp_samples-content__sample-header {
    border-top: 1px solid #dbdbdb;
  }
  .wp-samples-content__button-group {
    .wp-samples-content__button--checkout {
      float: right;
      padding-top: 15px;
      .button {
        width: 180px;
        margin-bottom: 5px;
      }
    }
    .wp-samples-content__button--return {
      text-align: left;
      padding-top: 15px;
      width: auto;
      float: left;
      @include breakpoint($bp--small-up) {
        float: right;
        padding-right: 80px;
      }
    }
  }
  & .wp_sample_offer {
    h3.wp_samples-content__sample-header {
      font-size: 18px;
      @include breakpoint($bp--small-up) {
        font-size: 24px;
      }
    }
    .wp_samples-content__sample-subheader {
      font-size: 12px;
    }
    .product__desc {
      width: 50%;
      float: left;
      margin-left: 15px;
      margin-right: 0;
      padding-right: 0;
      clear: right;
    }
    .samples-grid .sample-select-button {
      width: unset;
    }
    .samples-grid .samples-grid--item {
      width: 95%;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    .product_name {
      margin-bottom: 10px;
    }
    .product {
      background: $color--gray--white;
      margin-top: 10px;
      padding: 10px;
      &:first-child {
        margin-top: 0;
      }
    }
    .product__image {
      border-bottom: 0px;
      float: left;
      width: 45%;
    }
    .product__select {
      text-align: unset;
      clear: unset;
    }
    .btn--small {
      height: 60px;
      line-height: 64px;
      margin-left: 15px;
    }
    @include breakpoint($bp--small-up) {
      .product__select {
        text-align: #{$rdirection};
        clear: both;
      }
      .form-item {
        margin-bottom: 0px;
      }
      .btn--small {
        height: 30px;
        line-height: 30px;
      }
      .product {
        background: unset;
        margin-top: 10px;
        padding: 10px;
        &:first-child {
          margin-top: 0;
        }
      }
      .samples-grid .samples-grid--item {
        width: 25%;
        margin: 0 auto;
        margin-bottom: 0px;
      }
      .product__image {
        border-bottom: 1px solid #dbdbdb;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}

.checkout-optimization {
  .checkout-panel--wp-samples {
    .checkout-panel__heading {
      font-size: 30px;
      padding-top: 22px;
      padding-bottom: 12px;
    }
    .wp-samples-content__button-group {
      margin-bottom: 0;
      @include breakpoint($bp--large-up) {
        margin-bottom: 31px;
        padding-#{$rdirection}: 10px;
      }
    }
    .wp_samples-content__sample-header {
      padding-top: 10px;
      text-indent: 5px;
    }
  }
  #checkout_wp_samples_container {
    .wp-samples-content__button-group {
      .wp-samples-content__button--checkout {
        .button {
          width: 224px;
          height: 60px;
          font-size: 18px;
          margin-bottom: 0;
          @include breakpoint($bp--large-up) {
            margin-bottom: 5px;
          }
        }
      }
    }
    .wp-samples {
      &.checkout__samples {
        @include breakpoint($bp--medium-up) {
          float: none;
          width: 100%;
          .samples-grid--item {
            margin-top: 0;
            width: 25%;
            float: #{$ldirection};
            padding-#{$rdirection}: 2em;
            clear: none;
            .product__image {
              border-bottom: none;
              min-height: auto;
            }
            .product__select {
              text-align: center;
              padding-bottom: 30px;
              .sample-select-button {
                line-height: 64px;
                width: 224px;
                height: 60px;
                font-size: 18px;
                margin-#{$ldirection}: 0;
                &.sample-select-button--disabled,
                &.sample-select-button--disabled:hover {
                  background-color: $color--gray--thick;
                  border-color: $color--gray--thick;
                  color: $color--gray--dusty;
                  pointer-events: none;
                }
              }
            }
            .product__description {
              height: auto;
              float: #{$ldirection};
            }
            .product__info {
              float: #{$ldirection};
            }
          }
        }
        @include breakpoint($bp--medium-down) {
          .samples-grid--item {
            .product__select {
              text-align: center;
              .sample-select-button {
                width: 150px;
                max-width: 100%;
                height: 40px;
                line-height: 40px;
              }
            }
          }
        }
      }
    }
    .wp-samples-content__sample-header {
      margin: auto;
      padding: 14px 0 10px;
      text-indent: 0;
    }
    .wp-samples-content__sample-content {
      .wp-samples.checkout__samples {
        .samples-grid--item {
          @include swap_direction(padding, 1em 0.5em 55px 1em);
          float: $ldirection;
          margin-bottom: 0;
          position: relative;
          width: 50%;
          @include breakpoint($bp--medium-up) {
            padding: 1em 1em 40px;
            width: 25%;
          }
          .sample-select-button {
            #{$ldirection}: 1em;
            #{$rdirection}: 0.5em;
            @include breakpoint($bp--medium-up) {
              #{$rdirection}: 1em;
            }
          }
          &:nth-child(2n) {
            @include swap_direction(padding, 1em 1em 55px 0.5em);
            @include breakpoint($bp--medium-up) {
              padding: 1em 1em 40px;
            }
            .sample-select-button {
              #{$rdirection}: 1em;
              #{$ldirection}: 0.5em;
              @include breakpoint($bp--medium-up) {
                #{$ldirection}: 1em;
              }
            }
          }
        }
        .product__info {
          border-top: 1px solid $color--gray--lightest;
          width: 100%;
        }
        .product__description {
          border-top: 0;
        }
        .product__select {
          .sample-select-button {
            background: $color--white;
            bottom: 15px;
            color: $color--black;
            margin: auto;
            position: absolute;
            width: auto;
            &.selected {
              background: $color--black;
              color: $color--white;
            }
          }
        }
        .product__image {
          width: 100%;
        }
        .product__desc {
          border-top: 1px solid $color--gray--lightest;
          margin-#{$ldirection}: 0;
          padding-top: 15px;
          width: 100%;
          @include breakpoint($bp--medium-up) {
            width: 70%;
          }
        }
      }
    }
    @include breakpoint($bp--medium-down) {
      .wp-samples-content__button-group {
        &.samples-content__button-group--bottom {
          position: fixed;
          bottom: 0;
          width: 100%;
          z-index: 100;
        }
        .wp-samples-content__button--checkout {
          float: none;
          padding: 30px;
          background: $color--white;
          .button {
            width: 100%;
          }
        }
      }
    }
    .wp_sample_offer {
      .product {
        background: none;
      }
    }
  }
  &.wp-samples__page--landing {
    .checkout-panel--wp-samples {
      .checkout-panel__heading {
        letter-spacing: normal;
      }
    }
    #checkout_wp_samples_container {
      .wp-samples.checkout__samples {
        .samples-grid--item {
          .product__select {
            .sample-select-button {
              &.sample-select-button--disabled,
              &.sample-select-button--disabled:hover {
                background-color: $color--gray--thick;
                border-color: $color--gray--thick;
                color: $color--gray--dusty;
                pointer-events: none;
              }
              @include breakpoint($bp--medium-up) {
                font-size: 15px;
              }
            }
          }
        }
      }
      .wp-samples-content__button-group {
        @include breakpoint($bp--large-up) {
          margin-top: 31px;
        }
        .wp-samples-content__button--checkout,
        .wp-samples-content__button--return {
          padding-top: 0;
        }
      }
    }
    .wp_samples-content__sample-subheader {
      padding: 0 5px;
    }
  }
}

.wp-samples {
  &__icon--expand,
  &__icon--collapse {
    #{$rdirection}: 0;
    height: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    svg {
      height: 100%;
      vertical-align: top;
      width: 100%;
    }
  }
  &-content__sample-header {
    cursor: pointer;
    letter-spacing: -1px;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    position: relative;
    width: calc(100% - 2rem);
  }
  &-content__sample-content {
    &[aria-hidden='true'] {
      height: 0;
      overflow: hidden;
      position: relative;
    }
  }
  &-content__container {
    .wp-samples-content__button-group {
      display: none;
    }
    .product.deactivate {
      opacity: 0.4;
    }
  }
  &-content {
    .wp-samples__icon--expand {
      display: block;
    }
    .wp-samples__icon--collapse {
      display: none;
    }
    &[aria-expanded='true'] {
      .wp-samples__icon--expand {
        display: none;
      }
      .wp-samples__icon--collapse {
        display: block;
      }
    }
  }
  &-content__tray {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    row-gap: 10px;
    @include breakpoint($bp--medium-up) {
      flex-direction: row;
    }
    .wp-samples-content {
      &__tray-items {
        align-items: center;
        display: flex;
        gap: 10px;
        order: 2;
        @include breakpoint($bp--medium-up) {
          align-self: center;
          order: 1;
        }
      }
      &__tray-message-container {
        align-self: center;
        order: 1;
        @include breakpoint($bp--medium-up) {
          margin-#{$ldirection}: 10px;
          order: 2;
        }
        .wp-samples-content__tray-cta-continue-button {
          float: none;
          margin-#{$ldirection}: 0;
          margin-top: 10px;
        }
      }
      &__tray-cta {
        order: 3;
        position: relative;
        @include breakpoint($bp--medium-up) {
          align-self: center;
          margin-#{$ldirection}: auto;
        }
        .wp-samples-content__tray {
          &-cta-button {
            width: 80%;
            @include breakpoint($bp--medium-up) {
              width: auto;
            }
          }
        }
      }
    }
    &-threshold {
      font-weight: bold;
      line-height: 3.6;
      margin-#{$rdirection}: 10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include breakpoint($bp--medium-up) {
        position: static;
      }
    }
    &-item {
      background: transparent;
      border: solid 1px $color--gray--light;
      cursor: pointer;
      flex: 1;
      height: 60px;
      max-width: 60px;
      position: relative;
      text-align: center;
      vertical-align: bottom;
      @include breakpoint($bp--medium-up) {
        max-width: 60px;
        width: 60px;
      }
      .icon {
        #{$rdirection}: 5px;
        height: 10px;
        position: absolute;
        top: 5px;
        width: 10px;
      }
      &-image {
        height: 100%;
        max-width: 100%;
      }
      &--empty {
        border: solid 1px $color--gray--lightest;
        cursor: default;
        .icon {
          display: none;
        }
      }
    }
  }
  &-content__tray-cta-button {
    float: $rdirection;
    &.disabled {
      background-color: $color--gray--light;
      cursor: default;
    }
  }
}
.wp-samples-content__page--landing,
.wp-samples-content__page {
  .drawer-container {
    display: none;
  }
  .wp-samples-content__tray {
    background: $color--white;
    bottom: 0;
    box-shadow: 0 0 20px $color--gray--lightest;
    position: absolute;
    width: 100%;
  }
  &.sticky-gwp-tray {
    .wp-samples-content__tray {
      position: fixed;
      z-index: 999;
    }
  }
  .checkout-panel--wp-samples {
    .wp-samples-content__container {
      padding-bottom: 80px;
    }
  }
  .site-container {
    position: relative;
  }
  .checkout-panel__header {
    margin-bottom: 20px;
  }
  .wp-samples-content__tray-cta-continue-button {
    display: none;
  }
}

.wp-samples-content__page {
  .wp-samples-content__tray {
    &-cta-continue-button {
      padding-bottom: 5px;
    }
  }
  .wp-samples-content__tray-skip-cta {
    order: 4;
    text-align: center;
    display: block;
    @include breakpoint($bp--medium-up) {
      display: none;
    }
    .wp-samples-content__tray-cta-continue-button {
      display: inline-block;
    }
  }
  .wp-samples-content__tray-message-container {
    .wp-samples-content__tray-cta-continue-button {
      display: none;
      @include breakpoint($bp--medium-up) {
        display: inline-block;
      }
    }
  }
}
