$border_color: #dbdbdb;
$background_color: #d4d4d4;
$order_color: #bdbdbd;

.shipping .checkout-progress__shipping,
.review .checkout-progress__review,
.billing .checkout-progress__billing,
.confirm .checkout-progress__confirm {
  font-weight: bold;
}

#offer-code-panel {
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: none;
}

#shipmethod-panel {
  border-bottom: none;
}

.checkout {
  .checkout-header #promo-message {
    text-align: center;
  }
  #shipmethod-panel {
    #checkout_shipmethod {
      .ship-method-group {
        // On small screens, allow a bigger margin to stop the text overlapping the button
        // On desktops this is not visible as the dropdown is replaced
        // Duplicated in \sections\checkout\_checkout.scss for desktop
        select {
          padding-right: 10%;
          -webkit-padding-end: 10%;
        }
      }
    }
  }
  @include breakpoint($bp--small-down) {
    .checkout__content .cart-item .cart-item__qty {
      max-width: 60px;
      margin-top: -3px;
      select {
        padding-left: 0;
        border: none;
      }
    }
    .checkout .cart-item .cart-item__qty {
      margin-top: 3px;
    }
    .checkout-header .continue-buttons {
      height: 95px;
    }
  }
  ol.checkout-progress {
    li {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 3px 0);
      width: 50%;
      float: #{$ldirection};
      list-style-position: inside;
      display: list-item;
      list-style-type: decimal;
      line-height: 1.4em;
    }
  }
  &.viewcart {
    a.continue-checkout {
      margin-top: 30px;
    }
  }
  .recommended-products-panel {
    display: none;
    .recommended-item {
      clear: both;
      width: 100%;
      margin: 5px;
      text-align: center;
    }
  }
  .offer-code__input {
    text-align: center;
  }
  #one-offer-only {
    text-align: center;
    text-transform: uppercase;
  }
  .additional_info {
    margin-top: 10px;
  }
}

.panel {
  @include breakpoint($bp--medium-down) {
    select {
      // Reinstate the border hidden in mac_base/scss/40-components/forms_generic.scss:111
      height: 57px;
      border-width: 1px;
      margin-bottom: 0;
      option {
        text-decoration: none;
      }
    }
  }
}

.samples {
  .product.deactivate {
    .sample-select-button {
      background-color: $background_color;
      cursor: auto;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .picker-checkbox {
    .picker-handle {
      display: none;
    }
  }
  .samples-panel {
    border-top: 1px solid $border_color;
    padding-top: 15px;
  }
  .product-list {
    .product:last-child {
      margin-bottom: 0;
    }
    .product {
      @include swap_direction(margin, 15px 0);
    }
  }
  .return-link {
    float: #{$ldirection};
    width: 55%;
  }
  .product-list {
    overflow: hidden;
  }
  .checkout__panel-title {
    display: block;
  }
  .sample-select-button {
    width: 125px;
  }
  .continue-buttons {
    margin-#{$ldirection}: 33%;
  }
}

#shipping {
  .address_form_container,
  #address_form_container {
    .form_element {
      @include swap_direction(margin, 10px 0);
    }
  }
  .address_controls {
    .radio {
      float: #{$ldirection};
      width: 50%;
    }
  }
}

.border_ruler {
  @include swap_direction(border, 1px solid $order_color);
  clear: both;
}

.change_link {
  float: #{$rdirection};
  margin-#{$rdirection}: 20px;
}

.transaction-details {
  address {
    font-style: normal;
  }
}

.recommended-product-items {
  .info {
    height: auto;
  }
}

#new-or-returning-radios {
  fieldset {
    float: left;
  }
  .picker .picker-handle {
    margin-right: 5px;
  }
  .picker-label {
    float: left;
  }
}

.form-item {
  .password {
    .invalid_marker {
      top: 10px;
    }
  }
}

.offer-code__input.form-item {
  padding-top: 7px;
}

.checkout {
  #offer-code-panel {
    padding: 1em;
    &.accordionPanel {
      i {
        padding-left: 0;
      }
    }
  }
  .checkout__panel-title {
    display: block;
  }
}

.site-container {
  .checkout__sidebar {
    .viewcart-header {
      h2 {
        width: auto;
        padding: 0 25px 0 0;
        margin: 0;
        line-height: 20px;
      }
    }
  }
}

.checkout,
.panel {
  .viewcart-paypal-button {
    margin-bottom: 12px;
    @include breakpoint($bp--medium-up $bp--large-up) {
      float: left;
      width: 60%;
    }
    .paypal-button {
      margin-left: 16%;
      margin-bottom: 10px;
    }
    input {
      opacity: inherit;
      margin-top: 4px;
      float: left;
    }
    .terms-conditions-txt {
      margin-left: 16px;
    }
  }
}

.active-panel-review,
.enhanced_signin_page {
  .left {
    .shipmethod-panel {
      display: none;
    }
  }
}
