/* ===========================================================================
   Components Molecules - View All Shades
   ========================================================================== */

.view-all-shades {
  @include h6();
  height: 60px;
  background-color: $color--gray--lightest;
  position: relative;
  @include swap_direction(margin, 0);
  cursor: pointer;
  &:hover {
    background-color: $color--black;
    color: $color--white;
    .picker-label {
      color: $color--white;
    }
    .view-all-shades__icon {
      &:before {
        color: $color--white;
      }
    }
  }
  .product--show-mobile-shade-column & {
    background-color: $color--gray--lightest;
  }
}

.view-all-shades--mobile {
  background-color: $color--black;
  color: $color--white;
  line-height: 60px;
  margin: 15px 15px 0 15px;
  text-align: center;
  vertical-align: middle;
  .collection-quickshop & {
    display: none;
  }
}

.view-all-shades--desktop {
  @include swap_direction(padding, 22px 19px 0 10px);
}

.view-all-shades__icon {
  @include icon(arrow--right);
  position: absolute;
  #{$rdirection}: 0;
  font-size: 15px; // arbitrary
  margin-#{$rdirection}: 10px;
  @include breakpoint($bp--xxlarge-up) {
    &:before {
      content: map-get($icons, arrow--right-medium);
    }
  }
  .view-all-shades--mobile & {
    bottom: 24px;
  }
  .view-all-shades--desktop & {
    bottom: 20px; // arbitrary
  }
}
