/* ==========================================================================
   Layout - Single Row Grid Block Formatter
   ========================================================================== */

.singlerow-blocks-formatter__layout {
  @include clearfix();
  .grid--item {
    @include breakpoint($bp--largest-up) {
      float: #{$ldirection};
    }
  }
  @include breakpoint($bp--largest-up) {
    // 2 items
    &.layout--50-50 {
      .grid--item {
        width: 50%;
      }
    }
    &.layout--25-75 {
      .grid--item {
        &:nth-of-type(1) {
          width: 25%;
        }
        &:nth-of-type(2) {
          width: 75%;
        }
      }
    }
    &.layout--75-25 {
      .grid--item {
        &:nth-of-type(1) {
          width: 75%;
        }
        &:nth-of-type(2) {
          width: 25%;
        }
      }
    }
    // 3 items
    &.layout--25-50-25 {
      .grid--item {
        &:nth-of-type(1) {
          width: 25%;
        }
        &:nth-of-type(2) {
          width: 50%;
        }
        &:nth-of-type(3) {
          width: 25%;
        }
      }
    }
    &.layout--25-25-50 {
      .grid--item {
        &:nth-of-type(1) {
          width: 25%;
        }
        &:nth-of-type(2) {
          width: 25%;
        }
        &:nth-of-type(3) {
          width: 50%;
        }
      }
    }
    &.layout--50-25-25 {
      .grid--item {
        &:nth-of-type(1) {
          width: 50%;
        }
        &:nth-of-type(2) {
          width: 25%;
        }
        &:nth-of-type(3) {
          width: 25%;
        }
      }
    }
    &.layout--33-33-33 {
      .grid--item {
        &:nth-of-type(1) {
          width: 33.33%;
        }
        &:nth-of-type(2) {
          width: 33.34%;
        }
        &:nth-of-type(3) {
          width: 33.33%;
        }
      }
    }
    // 4 items
    &.layout--25-25-25-25 {
      .grid--item {
        width: 50%;
        @include breakpoint($bp--large-up) {
          width: 25%;
        }
      }
    }
    &.layout--20-20-20-20-20 {
      .grid--item {
        @include breakpoint($bp--medium-up) {
          width: 20%;
          float: #{$ldirection};
        }
      }
    }
  }
}

.single-blocks-home {
  &.layout--20-20-20-20-20 {
    max-width: 1440px;
  }
}

//layout-padding
.singlerow-blocks-formatter__layout.layout-padding {
  .grid--item {
    padding: 0 10px;
  }
}

// equal height children
.singlerow-blocks-formatter__item {
  .collection-text-block,
  .media-block,
  .text-block,
  .block-nodeblock,
  .node {
    height: 100%;
  }
  .media-block {
    background-color: $black;
  }
  .media-block__caption {
    @include breakpoint($bp--small-up) {
      height: auto;
    }
  }
}

.singlerow-blocks-formatter__header {
  @include swap_direction(padding, 2em);
}
