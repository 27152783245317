.bopis-map-container {
  width: 100%;
  float: right;
  position: relative;
  @include breakpoint($bp--xlarge-up) {
    width: 55%;
  }
}

.bopis-map {
  height: 525px;
  @include breakpoint($bp--medium-down) {
    height: 220px;
  }
}

.bopis-results {
  float: left;
  @include breakpoint($bp--xlarge-up) {
    width: 100%;
  }
}

.invis-start__panel {
  letter-spacing: 0.24px;
  .bopis-shipping-icon {
    width: 31px;
    height: 22px;
  }
  .postmates-shipping-icon {
    width: 24px;
    margin-left: 3px;
    position: absolute;
  }
  .postmates_enabled {
    .invis-start__link--open-store,
    .invis-start__msg--available {
      cursor: pointer;
    }
  }
}

.bopis-search-current-location {
  width: 50px;
  background: url(/media/export/cms/two_hour_delivery/current_location.png) no-repeat $color--black;
  display: inline-block;
  cursor: pointer;
  .postmates_search_form & {
    background-position: 50%;
    width: 45px;
    height: 45px;
  }
}

.bopis-error-messages {
  color: $color--red;
  text-transform: uppercase;
}

.search__row {
  .no-doors-available & {
    flex: unset;
  }
}

.bopis_search_store {
  background: $white;
  display: block;
  flex-direction: column;
  .invalid-zip-error {
    color: $color--red;
    display: none;
    @include breakpoint($bp--large-up) {
      position: absolute;
      left: 50px;
      margin-top: -25px;
    }
  }
  &.invalid-zip {
    @include breakpoint($bp--large-up) {
      padding-top: 10px;
    }
    .invalid-zip-error {
      display: block;
    }
    .invis-form__input--zip {
      border-color: $color--red;
    }
  }
  @include breakpoint($bp--large-up) {
    display: flex;
    position: absolute;
    right: 0;
    height: 50px;
    width: 420px;
    z-index: 1;
    margin-top: 0;
    justify-content: flex-end;
    flex-direction: row;
    top: 30px;
    .bopis-checkout-select_store & {
      position: initial;
      margin-bottom: 15px;
    }
  }
  .search__row & {
    top: 0;
  }
  .buttons-container {
    display: flex;
    @include breakpoint($bp--medium-down) {
      padding: 10px 0 20px;
    }
  }
  .invis-form__input--zip,
  .invis-form__btn--submit,
  .search_icon,
  .bopis-search-current-location {
    height: 45px !important;
    line-height: 45px;
    margin: 0 0 0 10px;
    display: inline-block;
    max-width: 150px;
  }
  .invis-form__input--zip {
    width: 150px;
    border-radius: 0;
    box-shadow: none;
  }
  @include breakpoint($bp--medium-down) {
    .invis-form__input--zip {
      width: 100%;
      max-width: 150px;
      margin: 0;
    }
    .invis-form__btn--submit {
      margin-left: 0;
    }
  }
  .search_icon {
    background: url(/media/export/cms/two_hour_delivery/search_image.png) no-repeat 50% 50%;
    width: 30px;
  }
}

.bopis-overlay {
  .bopis-results-title {
    margin: 15px 0 -15px 0;
  }
}

.bopis-spp-or-text {
  font-size: 15px;
  line-height: 0.5;
  font-weight: unset;
  font-family: $ano-bold_regular-font;
}

.bopis-more-info {
  border-radius: 50%;
  background: $color--black;
  color: $color--white;
  cursor: pointer;
  display: inline-block;
  width: 13px;
  height: 13px;
  text-align: center;
  font-weight: 700;
  line-height: 13px;
  font-size: 10px;
  font-family: serif;
  text-transform: none;
  margin-left: 5px;
  margin-top: 2px;
}

.bopis-spp-delivery-text {
  text-align: center;
  padding: 15px 0;
  &.bopis-mobile {
    padding-bottom: 0;
  }
}

.bopis-info-container {
  @include breakpoint($bp--large-up) {
    text-align: center;
    padding: 10px 40px;
  }
}

.bopis-zip-quicksearch {
  display: flex;
  padding: 0 25px;
  margin-bottom: $bp-sp;
  input {
    width: 150px;
    margin-right: 5px;
  }
}

.bopis-quicksearch-error {
  color: $color--red;
  margin-bottom: $bp-sp;
}

.bopis_modal_header {
  text-transform: uppercase;
  font-size: 28px;
  font-family: $ano-bold_regular-font;
  padding-right: 20px;
}

.bopis-checkout-select_store {
  .bopis_modal_header {
    margin-top: -5px;
    @include breakpoint($bp--large-up) {
      margin: 15px 0 0;
    }
  }
  .search__row {
    margin-top: 0;
  }
  .invis-form__container {
    align-self: center;
  }
  .button {
    @include btn;
  }
}

.bopis-flex {
  display: flex;
}

.no_results_found {
  display: none;
}

.bopis-doors-unavailable {
  .no_results_found {
    display: block;
  }
  .invis-results,
  .bopis-results {
    display: none;
  }
}

.bopis-inv-status {
  width: 165px;
  padding: 0 15px;
  @include breakpoint($bp--large-down) {
    width: 100%;
    padding: $bp-sp-sm 0 0 60px;
    margin-bottom: 0;
  }
  .pick-up-day {
    margin-bottom: 10px;
  }
  .bopis-inv-status-text {
    color: $color--red;
    min-height: 25px;
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    &.bopis-inv-status-available {
      color: $color--green-light;
    }
    img {
      margin: 0 5px 2px 0;
    }
  }
}

.bopis-edit-bag {
  display: none;
  .picker {
    margin: 0;
  }
  .partial_results_found {
    padding: $bp-sp;
  }
  .partial_results_found-store {
    margin: $bp-sp $bp-sp 0 0;
    @include breakpoint($bp--large-down) {
      flex-direction: column;
    }
    .bopis-edit-selected-store {
      @include breakpoint($bp--large-down) {
        margin-left: 0;
        background: transparent;
        padding: 0;
      }
    }
  }
  .bopis-edit-selected-store {
    background: $color--gray--white;
    padding: $bp-sp-sm;
    margin-left: $bp-sp;
  }
  .partial_results_found-products {
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $bp-sp;
    @include breakpoint($bp--large-down) {
      grid-template-columns: repeat(1, 1fr);
    }
    img {
      max-height: 100px;
      margin-right: $bp-sp;
    }
  }
  .prod_avail_info {
    width: 200px;
  }
  .prod_info {
    flex: 1;
  }
  .store_dist {
    padding: 5px 0 5px 10px;
  }
  .continue-action-header-text {
    text-transform: uppercase;
    margin-bottom: $bp-sp;
  }
  .continue-action-radio-container {
    justify-content: space-around;
    .partial_results_found-store {
      white-space: nowrap;
    }
  }
  .partial_results_found-select-continue-action {
    margin-bottom: $bp-sp;
  }
  .partial_results_found-product {
    background: $color--gray--white;
    padding: $bp-sp;
    margin-bottom: 10px;
  }
  .button {
    padding: 0 80px;
    margin-top: $bp-sp;
  }
}

.bopis_no_stores_message {
  display: none;
  margin: $bp-sp 0;
}

.bopis-top-errors {
  color: $color--red;
  @include breakpoint($bp--large-up) {
    text-align: center;
  }
}

.bopis-search-mobile {
  clear: both;
  padding: $bp-sp-sm 0;
}

.bopis-search-form-mobile {
  display: none;
  margin-top: $bp-sp-sm;
}

.bopis-spacing-top {
  margin-top: $bp-sp;
}

.get-it-tomorrow {
  display: none;
}

.bopis-mobile {
  @include breakpoint($bp--large-up) {
    display: none !important;
  }
}

.bopis-pc {
  @include breakpoint($bp--medium-down) {
    display: none !important;
  }
}

.bopis-cb {
  clear: both;
}

.bopis-lowercase {
  text-transform: lowercase;
}

.bopis-inline-block {
  display: inline-block;
}

.bopis-edit-option {
  border-top: 1px solid $color--gray--white;
  padding: $bp-sp 0;
}

.bopis-centered {
  text-align: center;
}

.bopis-prod_image {
  display: flex;
  margin-right: $bp-sp-sm;
  flex: 1;
}

.bopis-search-new-loc {
  float: right;
  padding-right: $bp-sp;
  line-height: 50px;
  .arrow {
    border: solid $color--black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    margin-bottom: 2px;
  }
  &.open {
    .arrow {
      transform: rotate(-135deg);
      margin-bottom: -2px;
    }
  }
}

.bopis-link-open-finder {
  cursor: pointer;
  text-transform: none;
  margin-bottom: 2px;
  display: inline-block;
  border-bottom: 0.5px solid $color--black;
  font-family: $helvetica-font;
  &:hover {
    border-bottom: 0.5px solid $color--black;
  }
}

.get_today_info_content {
  display: flex;
  @include breakpoint($bp--large-down) {
    flex-direction: column;
  }
  justify-content: space-between;
  .item {
    width: 30%;
    @include breakpoint($bp--large-down) {
      width: 100%;
    }
  }
}

.postmates_info_content {
  .postmates_info_divider {
    width: 30%;
    border: 1px solid $color--gray--lightest;
    margin-bottom: 20px;
  }
}

.bopis-info-subheader {
  width: 80%;
  margin-top: 10px;
}

.postmates-overlay {
  @include breakpoint($bp--large-up) {
    padding: 10px 20px;
  }
  .delivery_method {
    margin-top: 30px;
    font-family: $ano-bold_regular-font;
    font-size: 18px;
    text-transform: uppercase;
    @include breakpoint($bp--xsmall-down) {
      font-size: 14px;
      img {
        max-width: 150px;
      }
    }
    img {
      margin-top: -8px;
      margin-right: 8px;
    }
  }
  .postmates_search_form {
    margin-bottom: 10px;
  }
  &.invalid-zip {
    .invis-form__input--zip {
      border-color: $color--red;
    }
  }
}

.spp-postmates-availability {
  text-transform: uppercase;
  padding-left: 53px;
  margin-bottom: 20px;
  @include breakpoint($bp--xsmall-down) {
    padding-left: 50px;
  }
  .available {
    color: $color--green-light;
  }
  .not-available {
    color: $color--red;
  }
}

.paypal_unavailable_message {
  margin: 0 18px 0 0;
  width: 250px;
  display: inline-table;
  vertical-align: middle;
  text-align: right;
  padding-bottom: 0;
}

.postmates_search_form {
  .search_btn {
    @include btn;
    width: 220px;
  }
  .buttons-container {
    display: inline-flex;
    @include breakpoint($bp--medium-down) {
      display: flex;
      margin-top: 10px;
    }
  }
  .search_btn,
  .invis-form__input--zip {
    width: 180px !important;
    margin-right: 10px;
    margin-top: 0;
    height: 45px;
    line-height: 45px;
  }
  .message {
    margin: 10px 0;
  }
}

.postmates-content {
  display: none;
  .courier-available & {
    display: inherit;
  }
}

.bopis-only-content {
  display: none;
  .bopis-only-available & {
    display: inherit;
  }
}

.toggle-bopis-search-form {
  font-size: 12px;
  margin: 10px 0 20px 0;
  text-decoration: underline;
  cursor: pointer;
  .postmates-error-modal & {
    display: none;
  }
  i {
    float: none;
    margin-left: 5px;
    font-size: 18px;
  }
}

.delivery-options {
  .two_hour_delivery_option_label {
    @include breakpoint($bp--small-down) {
      > span {
        display: block;
        margin-top: 5px;
      }
    }
  }
}

.bopis-curbside-pickup-message {
  color: $color--green-light;
  font-weight: bold;
  display: block;
  margin-top: 5px;
}

.bopis-quantity-notice {
  display: none;
  font-style: italic;
  font-size: 14px;
  color: $color--red;
}