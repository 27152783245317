[class*='grid-container--'] {
  @include clearfix();
}

.grid-container--2 {
  @include layout($grid--2) {
    .grid--item {
      &:nth-of-type(2n + 1) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(2n + 2) {
        @include grid-span(1, 2);
      }
    }
  }
}

.grid-container--3 {
  @include layout($grid--3) {
    .grid--item {
      &:nth-of-type(3n + 1) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(3n + 2) {
        @include grid-span(1, 2);
      }
      &:nth-of-type(3n + 3) {
        @include grid-span(1, 3);
      }
    }
  }
}

.grid-container--4 {
  @include layout($grid--4) {
    .grid--item {
      &:nth-of-type(4n + 1) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(4n + 2) {
        @include grid-span(1, 2);
      }
      &:nth-of-type(4n + 3) {
        @include grid-span(1, 3);
      }
      &:nth-of-type(4n + 4) {
        @include grid-span(1, 4);
      }
    }
  }
}

.grid-container--5 {
  @include layout($grid--5) {
    .grid--item {
      &:nth-of-type(5n + 1) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(5n + 2) {
        @include grid-span(1, 2);
      }
      &:nth-of-type(5n + 3) {
        @include grid-span(1, 3);
      }
      &:nth-of-type(5n + 4) {
        @include grid-span(1, 4);
      }
      &:nth-of-type(5n + 5) {
        @include grid-span(1, 5);
      }
    }
  }
}

.grid-container--6 {
  @include layout($grid--6) {
    .grid--item {
      &:nth-of-type(6n + 1) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(6n + 2) {
        @include grid-span(1, 2);
      }
      &:nth-of-type(6n + 3) {
        @include grid-span(1, 3);
      }
      &:nth-of-type(6n + 4) {
        @include grid-span(1, 4);
      }
      &:nth-of-type(6n + 5) {
        @include grid-span(1, 5);
      }
      &:nth-of-type(6n + 6) {
        @include grid-span(1, 6);
      }
    }
  }
}

// 50% / 50% layout
.grid-item--1-of-2 {
  @include layout($grid--2) {
    @include grid-span(1, 1);
  }
}

.grid-item--2-of-2 {
  @include layout($grid--2) {
    @include grid-span(1, 2);
  }
}

// 75% / 25% layout
.grid-item--1-3-of-4 {
  @include layout($grid--4) {
    @include grid-span(3, 1);
  }
}

.grid-item--4-of-4 {
  @include layout($grid--4) {
    @include grid-span(1, 4);
  }
}

// 25% / 50% / 25% layout
.grid-item--1-of-4 {
  @include layout($grid--4) {
    @include grid-span(1, 1);
  }
}

.grid-item--2-3-of-4 {
  @include layout($grid--4) {
    @include grid-span(2, 2);
  }
}

// 50% / 25% / 25% layout
.grid-item--1-2-of-4 {
  @include layout($grid--4) {
    @include grid-span(2, 1);
  }
}

.grid-item--3-of-4 {
  @include layout($grid--4) {
    @include grid-span(1, 3);
  }
}

.grid-item--4-of-4 {
  @include layout($grid--4) {
    @include grid-span(1, 4);
  }
}

// Assymetrical examples
.grid-container--2-3 {
  @include layout($grid--2-3) {
    .grid--item {
      &:nth-of-type(2n + 1) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(2n + 2) {
        @include grid-span(1, 2);
      }
    }
  }
}

.grid-container--3-2 {
  @include layout($grid--3-2) {
    .grid--item {
      &:nth-of-type(2n + 1) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(2n + 2) {
        @include grid-span(1, 2);
      }
    }
  }
}

.grid-container--3-2-3 {
  @include layout($grid--3-2-3) {
    .grid--item {
      &:nth-of-type(1) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(2) {
        @include grid-span(1, 2);
      }
      &:nth-of-type(3) {
        @include grid-span(1, 3);
      }
      &:nth-of-type(4) {
        @include grid-span(2, 1);
        clear: both;
      }
      &:nth-of-type(5) {
        @include grid-span(1, 3);
      }
      &:nth-of-type(6) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(7) {
        @include grid-span(2, 2);
      }
    }
  }
}

.pyramid-grid {
  @include pyramid-grid();
}

.pyramid-grid--3-across {
  @include pyramid-grid-3across();
}

.pyramid-grid--collapsible {
  @include pyramid-grid-collapsible();
}
