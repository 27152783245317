/* ==========================================================================
   Layout - Section Giving Back - Back to Mac
   ========================================================================== */
.back-to-mac__body {
  nav.account-nav {
    display: none;
  }
}

.back-to-mac {
  UL.error {
    @include swap_direction(margin, 0 15px 20px 15px);
  }
  INPUT {
    -webkit-appearance: none;
    @include swap_direction(border-radius, 0);
  }
  .form-item-wrapper {
    display: block;
    vertical-align: top;
    display: inline-block;
    width: 23%;
    min-width: 200px;
    margin-#{$ldirection}: 15px;
  }
  .form-type-select {
    .select2-dropdown-open {
      border-width: 1px;
    }
  }
  .back-to-mac__wrapper {
    margin-#{$ldirection}: -4px;
  }
  .back-to-mac__intro {
    @include swap_direction(margin, 60px 0);
    h5,
    h2 {
      width: 25%;
      margin-#{$ldirection}: 15px;
      float: #{$ldirection};
    }
    div {
      float: #{$ldirection};
      width: 60%;
    }
  }
  .back-to-mac__step {
    clear: both;
    float: none;
  }
  .back-to-mac__header {
    color: $color--gray--lighter;
    height: 60px;
    border-top: 1px solid $color--gray--light;
    h6,
    h3 {
      font-size: 17px;
      @include swap_direction(padding, 20px 0 0 15px);
    }
  }
  .back-to-mac__header-last {
    border-bottom: 1px solid $color--gray--light;
  }
  .back-to-mac__step-content {
    @include swap_direction(margin, 20px 0 20px 0);
    display: none;
  }
  .back-to-mac__step-desc {
    @include swap_direction(margin, 60px 0 28px 15px);
    width: 85%;
    @include breakpoint($bp--larger-up) {
      width: 24%;
    }
  }
  .back-to-mac__field-group {
    width: auto;
    display: inline-block;
    @include breakpoint($bp--larger-up) {
      width: 76%;
    }
  }
  .back-to-mac__btn {
    width: 23%;
    min-width: 200px;
    height: 30px;
    display: inline-block;
    @include swap_direction(border, 0);
    line-height: 34px;
    vertical-align: bottom;
    @include swap_direction(margin, 0 15px 0 15px);
    -webkit-appearance: none;
    @include swap_direction(border-radius, 0);
    @include clearfix();
    @include breakpoint($bp--larger-up) {
      @include swap_direction(margin, 0 0 15px 0);
    }
  }
  .back-to-mac__btn-gray {
    background-color: $color--gray--light;
  }
  .back-to-mac__return_packaging {
    .form-item-wrapper {
      width: 30%;
    }
  }
  .back-to-mac__free_product {
    .product {
      position: static;
    }
    .select2-choice {
      @include swap_direction(border, 1px solid $color--gray--lighter);
    }
    .select2-dropdown-open {
      .select2-choice {
        border-width: 0;
      }
    }
    .form-item-wrapper {
      width: 30.5%;
      text-align: #{$ldirection};
      @include swap_direction(margin, 0 15px 15px 0);
    }
    .back-to-mac__color-chip {
      position: static;
      width: 100%;
      height: 40px;
      margin-top: 6px;
    }
    .back-to-mac__btn {
      clear: both;
      @include swap_direction(margin, 0 0 0 15px);
      @include breakpoint($bp--larger-up) {
        @include swap_direction(margin, 0 15px 0 0);
        float: #{$rdirection};
      }
    }
  }
  .back-to-mac__free_product-lipstick {
    width: 23%;
    display: inline-block;
    margin-#{$ldirection}: 15px;
    div {
      margin-#{$ldirection}: -30px;
      overflow: hidden;
      height: 150px;
    }
    img {
      width: 124px;
      height: 163px;
      margin-top: -20px;
    }
  }
  .back-to-mac__free_product-shades {
    display: block;
    width: auto;
    @include swap_direction(margin, 0 0 15px 15px);
    @include breakpoint($bp--larger-up) {
      float: #{$rdirection};
      text-align: #{$rdirection};
      display: inline-block;
      width: 75%;
      margin-#{$ldirection}: 0;
    }
  }
  .back-to-mac__shipping_address {
    .back-to-mac__step-content {
      @include swap_direction(margin, 0 0 40px 15px);
    }
    .back-to-mac__field-group {
      display: block;
      @include breakpoint($bp--larger-up) {
        display: inline-block;
        width: 74%;
      }
    }
    .field-container {
      max-width: 98%;
    }
    .field-container--grid {
      max-width: 98%;
    }
    p.back-to-mac__shipping-limited {
      margin-top: 20px;
    }
    UL.error {
      margin-#{$ldirection}: 0;
    }
    .back-to-mac__btn {
      @include swap_direction(margin, 0 15px 15px 0);
    }
  }
  .back-to-mac__send_your_package {
    margin-bottom: 110px;
    .back-to-mac__step-content {
      @include swap_direction(margin, 60px 0 0 15px);
    }
    .back-to-mac__btn {
      clear: both;
      margin-#{$ldirection}: 0;
      margin-bottom: 60px;
      @include breakpoint($bp--medium-up) {
        float: #{$rdirection};
      }
    }
    .back-to-mac__send_your_package--column {
      width: auto;
      margin-bottom: 20px;
      @include breakpoint($bp--medium-up) {
        width: 23%;
        display: inline-block;
        vertical-align: top;
        &:nth-of-type(3) {
          width: 30%;
        }
      }
      h6,
      h4 {
        margin-bottom: 0.7em;
      }
      .back-to-mac__product-name {
        text-transform: uppercase;
      }
    }
  }
  .back-to-mac__active {
    .back-to-mac__header {
      background-color: $color--gray--lightest;
      color: $color--black;
      @include swap_direction(border, 0);
      h6,
      h3 {
        @include swap_direction(padding, 24px 0 0 15px);
      }
    }
    .back-to-mac__header-last {
      @include swap_direction(border, 0);
    }
    .back-to-mac__step-content {
      display: block;
    }
  }
}

.back-to-mac__overlay {
  display: none;
  @include swap_direction(margin, 20px 60px 0 20px);
  li {
    list-style: disc;
  }
}

.back-to-mac-printable {
  @include swap_direction(margin, 60px);
  .back-to-mac__site-logo {
    width: 124px;
    height: 18px;
  }
  .back-to-mac__intro {
    border-bottom: 1px solid gray;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 60px 0 40px 0);
    h5 {
      margin-#{$ldirection}: 0;
    }
    div {
      width: 70%;
    }
  }
  .back-to-mac__send_your_package {
    @include swap_direction(padding, 40px 0);
  }
  .back-to-mac__send_your_package--column {
    width: 24%;
  }
}
