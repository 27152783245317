// setup for each specific palette pan - use percentages!
@mixin custom-palette-settings(
  $palette--padding,
  $palette--zone-padding,
  $palette--zone-height,
  $palette--zone-shade-width,
  $palette--zone-shade-height,
  $palette--zone-grid
) {
  .product__detail-zones {
    @include swap_direction(padding, $palette--padding);
  }
  .product__detail-zone {
    height: $palette--zone-height;
    @include swap_direction(padding, $palette--zone-padding);
    width: $palette--zone-grid;
    .shade-picker__color-wrapper {
      height: $palette--zone-shade-height;
      width: $palette--zone-shade-width;
    }
    .shade-picker__color-details {
      width: $palette--zone-shade-width;
    }
  }
}

@mixin custom-palette-draggable($palette--drag-size-smallest) {
  .ui-draggable-active,
  .ui-draggable-active .shade-picker__color-wrapper {
    height: $palette--drag-size-smallest;
    width: $palette--drag-size-smallest;
    @include breakpoint($bp--medium-up) {
      height: $palette--drag-size-smallest * 1.3;
      width: $palette--drag-size-smallest * 1.3;
    }
    @include breakpoint($bp--large-up) {
      height: $palette--drag-size-smallest * 1.6;
      width: $palette--drag-size-smallest * 1.6;
    }
    @include breakpoint($bp--largest-up) {
      height: $palette--drag-size-smallest * 1.9;
      width: $palette--drag-size-smallest * 1.9;
    }
  }
}

.page--spp__custom_palette {
  .product {
    .shade-picker__wrapper {
      bottom: auto;
      overflow: hidden;
      position: fixed;
      display: none;
      #{$rdirection}: 0;
      top: 60px;
      width: 25%;
      z-index: $z-highest;
      margin-top: 60px;
      &.at-bottom {
        bottom: 0;
        top: 0;
        position: absolute;
      }
    }
    &__wrapper {
      .palette_error {
        display: none;
        color: red;
      }
    }
    .shade-picker__previous {
      top: 60px;
    }
    .shade-picker__colors {
      @include swap_direction(margin, 30px 0);
      max-height: 768px;
      overflow: auto;
      overflow-x: hidden !important;
      overflow-y: scroll !important;
      width: 100%;
      .os--Windows & {
        width: calc(100% + 17px);
      }
      &-overlay {
        @include opacity(0.5);
        background: $color--white;
        cursor: not-allowed;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }
      .shade-picker__color-name {
        @include swap_direction(padding, 0 10px);
      }
    }
    .shade-picker__tools {
      background: $color--gray--lightest;
      text-transform: uppercase;
      &-heading {
        @include h6();
        background: $color--gray--lightest;
        border-bottom: $border;
        height: 60px;
        line-height: 1;
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 32px 10px 0 10px);
        width: 100%;
      }
      &-title {
        @include h6();
        border-bottom: $border;
        height: 60px;
        line-height: 1;
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 32px 10px 0 10px);
      }
      &-sort {
        border-bottom: $border;
        min-height: 60px;
        @include swap_direction(padding, 0 10px);
        &-toggle {
          @include icon(arrow--down);
          cursor: pointer;
          line-height: 60px;
          &:before {
            float: #{$rdirection};
            line-height: 60px;
          }
          &.active {
            @include icon(arrow--up);
            &:before {
              float: #{$rdirection};
              line-height: 60px;
            }
          }
        }
        &-items {
          @include swap_direction(padding, 0 0 10px 0);
          li {
            @include swap_direction(padding, 5px 0 15px 0);
          }
        }
      }
      &-filters {
        &-toggle {
          @include icon(arrow--down);
          border-bottom: $border;
          cursor: pointer;
          line-height: 60px;
          min-height: 60px;
          @include swap_direction(padding, 0 16px 0 10px);
          &:before {
            float: #{$rdirection};
            font-size: 18px;
            line-height: 60px;
          }
          &.active {
            @include icon(arrow--up);
            @include swap_direction(border, none);
            &:before {
              float: #{$rdirection};
              font-size: 18px;
              line-height: 60px;
            }
          }
        }
        .shade-picker__tools-title {
          @include icon(arrow--down);
          cursor: pointer;
          line-height: 60px;
          @include swap_direction(padding, 0 16px 0 10px);
          &:before {
            float: #{$rdirection};
            line-height: 60px;
          }
          &.active {
            @include icon(arrow--up);
            @include swap_direction(border, none);
            &:before {
              float: #{$rdirection};
              line-height: 60px;
            }
          }
        }
        &-clear {
          @include icon(remove);
          border-bottom: $border;
          cursor: pointer;
          line-height: 60px;
          min-height: 60px;
          @include swap_direction(padding, 0 16px 0 10px);
          &:before {
            float: #{$rdirection};
            line-height: 60px;
          }
        }
        &-items {
          border-bottom: $border;
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 0 16px 10px 10px);
          li {
            line-height: normal;
            @include swap_direction(padding, 5px 0 15px 0);
          }
          a {
            display: block;
            &.active {
              @include icon(check);
              &:before {
                float: #{$rdirection};
              }
            }
          }
        }
      }
    }
    &__wrapper {
      display: inline;
      float: #{$ldirection};
      @include swap_direction(padding, 60px 20px 0 20px);
      width: 75%;
    }
    &__header {
      @include swap_direction(margin, 0 auto);
      max-width: 940px;
      padding: 20px 0 0 0;
      position: relative;
      z-index: 5;
      @include breakpoint($bp--xlarge-up) {
        padding: 0;
      }
      .toggle-artist-overlay {
        position: absolute;
        top: -40px;
        #{$rdirection}: 0;
      }
      &-intro {
        #{$ldirection}: 0;
        position: absolute;
        text-align: center;
        top: -20px;
        width: 100%;
        p {
          margin: 0;
        }
      }
    }
    &__detail {
      @include swap_direction(margin, 0 auto 60px auto);
      max-width: 640px;
      position: relative;
      &-image {
        display: block;
        height: auto;
        max-width: 940px;
        width: 100%;
      }
      &-inserts {
        @include clearfix();
        height: 100%;
        #{$ldirection}: 0;
        max-width: 940px;
        position: absolute;
        top: 0;
        width: 100%;
      }
      &-insert {
        float: #{$ldirection};
        height: 100%;
        position: relative;
        width: 100%;
        &__overlay {
          @include opacity(0.5);
          background: $color--white;
          cursor: pointer;
          height: 100%;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 2;
        }
        &.active {
          .product__detail-insert__overlay {
            display: none;
          }
        }
      }
      &-zones {
        @include clearfix();
        #{$ldirection}: auto;
        height: 100%;
        max-width: 940px;
        position: relative;
        top: auto;
        width: 100%;
      }
      &-zone {
        display: inline;
        float: #{$ldirection};
        &-spacer {
          clear: both;
          @include swap_direction(padding, 13% 0 0 0);
        }
      }
      .shade-picker__color {
        height: 100%;
      }
      .shade-picker__color-wrapper {
        @include border-radius(100%);
        @include swap_direction(margin, 0 auto);
        min-height: 0;
        overflow: hidden;
      }
      .shade-picker__color-texture {
        display: block;
      }
      .shade-picker__color-chip {
        display: none;
      }
      .shade-picker__color-details {
        #{$ldirection}: auto;
        @include swap_direction(margin, 0 auto);
        position: relative;
        text-align: center;
        top: auto;
        z-index: 2;
      }
      .shade-picker__color-name {
        @include body-mono();
        line-height: normal;
        padding: 0;
      }
    }
    .shade-picker__color-tooltip {
      @include opacity(0.8);
      @include transform(translateX(flipX(-50%, #{$rdirection})));
      bottom: -15px;
      #{$ldirection}: 50%;
      min-width: 300px;
      @include swap_direction(padding, 10px);
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;
      z-index: 100;
      .shade-picker__color-name {
        display: block;
      }
      .shade-picker__color-description {
        display: block;
      }
      &.shade-light {
        .shade-picker__color-name {
          color: $color--black;
        }
        .shade-picker__color-description {
          color: $color--black;
        }
      }
      &.shade-dark {
        .shade-picker__color-name {
          color: $color--white;
        }
        .shade-picker__color-description {
          color: $color--white;
        }
      }
    }
    .shade-picker__shadename-toggle-wrap {
      line-height: normal;
      @include swap_direction(margin, -55px 0 0 -20px);
      position: absolute;
      text-align: center;
      width: 75%;
      z-index: 100;
      .show-link {
        display: none;
      }
      .hide-link {
        display: inline;
      }
    }
    &__footer {
      border-top: 1px solid $color--gray--lighter;
      @include swap_direction(margin, 0 auto);
      max-width: 940px;
      @include swap_direction(padding, 20px 0 60px 0);
      .product__add-to-bag,
      .custom_palette_notify-me {
        @include btn--small;
        display: inline;
        float: #{$rdirection};
      }
      .product__add-to-bag.btn--disabled {
        opacity: 1;
        cursor: not-allowed;
        background: $color--gray--light;
        &:hover {
          text-decoration: none;
        }
      }
      &__preview-toggle {
        &-wrap {
          line-height: 2;
          margin: 0 0 15px 0;
          padding: 0 15px;
          text-align: center;
          @include breakpoint($bp--medium-up) {
            display: inline;
            float: #{$rdirection};
            margin: 0;
            text-align: #{$rdirection};
          }
          .preview-link {
            display: inline;
          }
          .edit-link {
            display: none;
          }
        }
      }
    }
    .product__name-editable {
      min-height: 30px;
      .product__name,
      .product__subline {
        .edit-link {
          @include body-mono();
          @include swap_direction(padding, 0 15px);
        }
        .close-link {
          display: none;
          @include swap_direction(padding, 0 15px);
          a {
            @include swap_direction(border, none);
            text-decoration: none;
          }
        }
      }
      .product__name-field {
        @include h6();
        @include swap_direction(border, none);
        display: none;
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
        width: 250px;
      }
      &.editing {
        .default-title {
          display: none;
        }
        .edit-link {
          display: none;
        }
        .close-link {
          display: inline;
        }
        .product__name-field {
          display: inline;
          float: #{$ldirection};
        }
      }
    }
    .hide-shadenames {
      .shade-picker__color-details {
        visibility: hidden;
      }
      .shade-picker__shadename-toggle-wrap {
        .show-link {
          display: inline;
        }
        .hide-link {
          display: none;
        }
      }
    }
    .preview-palette {
      .product__detail-insert__overlay {
        @include opacity(0);
        cursor: not-allowed;
        display: block;
      }
      .product__detail-zones {
        .product__detail-zone:empty {
          &:before {
            display: none;
          }
        }
      }
      .product__footer__preview-toggle-wrap {
        .preview-link {
          display: none;
        }
        .edit-link {
          display: inline;
        }
      }
    }
  }
  .ui-draggable-active {
    .shade-picker__color-wrapper {
      @include border-radius(100%);
      @include swap_direction(margin, 0 auto);
      min-height: 0;
      overflow: hidden;
    }
    .shade-picker__color-texture {
      display: block;
    }
    .shade-picker__color-chip {
      display: none;
    }
    .shade-picker__color-details {
      display: none;
    }
    .shade-picker__color-tooltip {
      display: none;
    }
  }
  &-overlay {
    background: $color--white;
    height: 100%;
    #{$rdirection}: 27.5%;
    position: absolute;
    top: 0;
    width: 70%;
    z-index: 100;
  }
  .custom-palette__artist-formatter {
    @include swap_direction(margin, 0 auto);
    max-width: 940px;
    @include swap_direction(padding, 20px 0 0 0);
    &-close {
      @include swap_direction(border, none);
      display: inline;
      float: #{$rdirection};
      font-size: 30px;
      @include swap_direction(margin, 0 -1% 0 0);
      i {
        vertical-align: top;
      }
    }
    &-wrapper {
      clear: both;
      @include swap_direction(padding, 60px);
    }
    &-controls {
      background: $color--white;
      color: $color--gray;
      line-height: 1;
      @include swap_direction(margin, 30px auto);
      @include swap_direction(padding, 0 0 15px 0);
      position: relative;
      text-align: center;
      text-transform: uppercase;
      z-index: 100;
      width: 100%;
      .slick-prev {
        background-image: image-url('global/sprites-carousel-arrows.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        height: 7px;
        width: 58px;
        #{$ldirection}: 0;
      }
      .slick-next {
        background-image: image-url('global/sprites-carousel-arrows.png');
        background-repeat: no-repeat;
        background-position: 0 -7px;
        height: 7px;
        width: 58px;
        #{$rdirection}: 0;
      }
      .slick-prev,
      .slick-next {
        @include unset-underline-links($extend: true);
        display: inline-block;
        @include swap_direction(padding, 10px 0 0);
        text-indent: -9999px;
        width: 58px;
      }
      .count {
        display: inline-block;
        @include swap_direction(padding, 0 20px);
        vertical-align: top;
      }
    }
  }
  .custom-palette__artist {
    &-tile {
      display: inline;
      float: #{$ldirection};
      width: 41%;
      .artist-tile__alt_image {
        display: none;
      }
    }
    &-wrap {
      border-top: 1px solid $color--gray--lighter;
      display: inline;
      float: #{$rdirection};
      @include swap_direction(padding, 0 0 130px 0);
      position: relative;
      width: 53%;
    }
    &-title {
      @include swap_direction(margin, 20px 0 0 0);
    }
    &-quote {
      @include swap_direction(margin, 20px 0 0 0);
      @include swap_direction(padding, 0 60px 0 0);
      p {
        @include swap_direction(margin, 0 0 15px 0);
      }
    }
    &-image {
      @include swap_direction(padding, 20px 30px 0 30px);
      img {
        display: block;
        height: 100%;
        @include swap_direction(margin, 0 auto);
        max-width: 100%;
        width: auto;
      }
    }
    &-footer {
      border-top: 1px solid $color--gray--lighter;
      bottom: 0;
      #{$rdirection}: 0;
      @include swap_direction(padding, 15px 0);
      position: absolute;
      text-align: #{$rdirection};
      width: 53%;
    }
    &-total {
      @include h6();
      display: inline;
      float: #{$ldirection};
      @include swap_direction(margin, 0);
      line-height: 40px;
    }
  }
  &.is-mobile {
    overflow: hidden;
    .product__wrapper {
      display: block;
      float: none;
      @include swap_direction(margin, 0 auto);
      width: 96%;
      @include breakpoint($bp--xlarge-up) {
        padding-top: 60px;
      }
      .product__detail {
        margin-bottom: 20px;
      }
      .product__detail-zone:empty {
        cursor: pointer;
        position: relative;
        &:before {
          color: $color--white;
          content: '+';
          cursor: pointer;
          display: block;
          font-size: 22px;
          height: 100%;
          line-height: 1;
          padding-top: 34%;
          text-align: center;
          width: 100%;
        }
      }
      .shade-picker__color-details {
        display: none;
      }
      .palette_error {
        padding-top: 25px;
      }
    }
    .product {
      .shade-picker__wrapper {
        display: block;
        #{$ldirection}: 100%;
        top: auto;
        #{$rdirection}: auto;
        width: 88.5%;
      }
      .shade-picker__controls {
        display: none;
      }
      .shade-picker__colors {
        @include swap_direction(margin, 0);
        -webkit-overflow-scrolling: touch;
        -webkit-transform: translateZ(0px);
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-perspective: 1000;
      }
    }
    .product__footer {
      position: relative;
    }
    .product__add-to-bag {
      bottom: 0;
      float: none;
      height: 60px;
      #{$ldirection}: 0;
      line-height: 64px;
      position: fixed;
      width: 100%;
      z-index: 2000;
    }
    .product__shade_list {
      &-insert {
        @include swap_direction(margin, 15px 0 0 0);
      }
      .shade-picker__color {
        &-wrapper,
        &-details,
        &-tooltip {
          display: none;
        }
        &-list {
          display: block !important;
          visibility: visible !important;
        }
        &-name,
        &-description {
          display: inline;
          line-height: normal;
          @include swap_direction(padding, 0);
        }
      }
    }
  }

  //
  // $palette--padding,
  // $palette--zone-padding,
  // $palette--zone-height,
  // $palette--zone-shade-width,
  // $palette--zone-shade-height,
  // $palette--zone-grid
  //
  &.MAC_2_PALETTE {
    .toggle-artist-overlay {
      top: 20px;
    }
    .product__header-intro {
      top: 40px;
    }
    @include custom-palette-settings(39% 19% 0, 0, 50%, 78%, 89%, 50%);
    @include custom-palette-draggable(80px);
  }
  &.MAC_2_PALETTE_2 {
    .toggle-artist-overlay {
      top: 20px;
    }
    .product__header-intro {
      top: 40px;
    }
    @include custom-palette-settings(39% 19% 0, 0, 50%, 78%, 89%, 50%);
    @include custom-palette-draggable(80px);
  }
  &.MAC_1x1_PALETTE {
    .toggle-artist-overlay {
      top: 20px;
    }
    .product__header-intro {
      top: 40px;
    }
    @include custom-palette-draggable(80px);
    .product__detail-insert-1 {
      @include custom-palette-settings(78% 0 0 38%, 0, 50%, 78%, 89%, 100%);
      width: 50%;
    }
    .product__detail-insert-2 {
      @include custom-palette-settings(78% 38% 0 0, 0, 50%, 78%, 89%, 100%);
      width: 50%;
    }
  }
  &.MAC_4_PALETTE {
    .toggle-artist-overlay {
      top: 0;
    }
    .product__header-intro {
      top: 20px;
    }
    @include custom-palette-settings(28% 28% 0, 6% 0 0 0, 32.5%, 78%, 90%, 50%);
    @include custom-palette-draggable(56px);
  }
  &.MAC_4_PALETTE_2 {
    .toggle-artist-overlay {
      top: 0;
    }
    .product__header-intro {
      top: 20px;
    }
    @include custom-palette-settings(28% 28% 0, 6% 0 0 0, 32.5%, 78%, 90%, 50%);
    @include custom-palette-draggable(56px);
  }
  &.MAC_2x2_PALETTE {
    .toggle-artist-overlay {
      top: 0;
    }
    .product__header-intro {
      top: 20px;
    }
    &[data-insert='1'] {
      @include custom-palette-draggable(56px);
    }
    &[data-insert='2'] {
      @include custom-palette-draggable(56px);
    }
    .product__detail-insert-1 {
      @include custom-palette-settings(58% 0 0 56%, 6% 0 0 0, 32.5%, 80%, 90%, 100%);
      width: 50%;
    }
    .product__detail-insert-2 {
      @include custom-palette-settings(58% 56% 0 0, 6% 0 0 0, 32.5%, 78%, 90%, 100%);
      width: 50%;
    }
  }
  &.MAC_6_PALETTE {
    .toggle-artist-overlay {
      top: -30px;
    }
    .product__header-intro {
      top: -30px;
    }
    @include breakpoint($bp--medium-up) {
      .product__header-intro {
        top: 10px;
      }
    }
    @include custom-palette-settings(24% 33% 0, 0, 27%, 93%, 83%, 50%);
    @include custom-palette-draggable(56px);
  }
  &.MAC_2x4_PALETTE {
    .toggle-artist-overlay {
      top: -30px;
    }
    .product__header-intro {
      top: 10px;
    }
    &[data-insert='1'] {
      @include custom-palette-draggable(53px);
    }
    &[data-insert='2'] {
      @include custom-palette-draggable(53px);
    }
    .product__detail-insert-1 {
      @include custom-palette-settings(24% 33% 0, 0, 100%, 94%, 92.1%, 50%);
      height: 44%;
    }
    .product__detail-insert-2 {
      @include custom-palette-settings(1.5% 33% 0, 0, 37%, 96%, 84%, 50%);
      height: 56%;
    }
  }
  &.MAC_2_CREME_PALETTE {
    .toggle-artist-overlay {
      top: -30px;
    }
    .product__header-intro {
      top: 10px;
    }
    @include custom-palette-settings(23% 33% 0, 0, 44%, 68%, 76%, 100%);
    @include custom-palette-draggable(80px);
  }
  &.MAC_2_BLUSH_PALETTE {
    .toggle-artist-overlay {
      top: -30px;
    }
    .product__header-intro {
      top: 10px;
    }
    @include custom-palette-settings(23% 33% 0, 0, 40%, 80%, 95%, 100%);
    @include custom-palette-draggable(90px);
  }
  &.MAC_12_PALETTE {
    .toggle-artist-overlay {
      top: -30px;
    }
    .product__header-intro {
      top: 10px;
    }
    @include custom-palette-settings(24% 15.5% 0 16%, 0, 27%, 93%, 83%, 24.25%);
    @include custom-palette-draggable(52px);
    .product__detail-zone:nth-child(2n) {
      margin-right: 2%;
    }
    .product__detail-zone:nth-child(4n) {
      margin-right: 0;
    }
  }
  &.MAC_2x6_PALETTE {
    .toggle-artist-overlay {
      top: -30px;
    }
    .product__header-intro {
      top: 10px;
    }
    &[data-insert='1'] {
      @include custom-palette-draggable(90px);
    }
    &[data-insert='2'] {
      @include custom-palette-draggable(52px);
    }
    .product__detail-insert-1 {
      @include custom-palette-settings(47% 0 0 32%, 0, 40%, 81%, 95%, 100%);
      width: 49%;
    }
    .product__detail-insert-2 {
      @include custom-palette-settings(47% 32% 0 3%, 0, 27%, 95%, 84%, 50%);
      width: 51%;
    }
  }
  &.MAC_9_PALETTE {
    .toggle-artist-overlay {
      top: -30px;
    }
    .product__header-intro {
      top: 10px;
    }
    @include custom-palette-settings(24% 17.25% 0 17.75%, 0, 27%, 74%, 83%, 33%);
    @include custom-palette-draggable(56px);
    .product__detail-insert-1 {
      .product__detail-zone:empty::before {
        padding-top: 26%;
      }
    }
  }
  &.MAC_15_PALETTE {
    .toggle-artist-overlay {
      top: 20px;
    }
    .product__header-intro {
      top: 40px;
    }
    @include custom-palette-settings(30.5% 17.75% 0, 3% 0 0 0, 19%, 78%, 100%, 20%);
    @include custom-palette-draggable(33px);
    .product__detail-insert-1 {
      .product__detail-zone:empty::before {
        padding-top: 26%;
      }
    }
  }
  &.MAC_2x9_BLUSH_PALETTE {
    .toggle-artist-overlay {
      top: 0;
    }
    .product__header-intro {
      top: 20px;
    }
    &[data-insert='1'] {
      @include custom-palette-draggable(66px);
    }
    &[data-insert='2'] {
      @include custom-palette-draggable(38px);
    }
    .product__detail-insert-1 {
      @include custom-palette-settings(78% 0 0 30%, 0, 32%, 76%, 96%, 100%);
      width: 38%;
    }
    .product__detail-insert-2 {
      @include custom-palette-settings(49% 22% 0 2%, 0, 22%, 76%, 83%, 33%);
      width: 62%;
      .product__detail-zone:empty::before {
        padding-top: 26%;
      }
    }
  }
  &.MAC_2x9_CREME_PALETTE {
    .toggle-artist-overlay {
      top: 0;
    }
    .product__header-intro {
      top: 20px;
    }
    &[data-insert='1'] {
      @include custom-palette-draggable(56px);
    }
    &[data-insert='2'] {
      @include custom-palette-draggable(38px);
    }
    .product__detail-insert-1 {
      @include custom-palette-settings(82% 5.4% 0 37.6%, 0, 35%, 78%, 75%, 100%);
      width: 37.1%;
    }
    .product__detail-insert-2 {
      @include custom-palette-settings(48.1% 22.7% 0.95% 3.7%, 0, 22%, 76%, 83%, 33.33%);
      width: 62.9%;
      .product__detail-zone:empty::before {
        padding-top: 26%;
      }
    }
  }
  &.MAC_6_BLUSH_PALETTE_2 {
    .toggle-artist-overlay {
      top: 0;
    }
    .product__header-intro {
      top: 20px;
    }
    @include custom-palette-settings(29% 11% 0, 0, 33%, 78%, 95%, 33%);
    @include custom-palette-draggable(66px);
  }
  &.MAC_6_BLUSH_PALETTE {
    @include custom-palette-settings(29% 15% 0, 3% 0 0 0, 30%, 75%, 100%, 33%);
    @include custom-palette-draggable(60px);
  }
  &.MAC_6_PRO_LONGWEAR_PALETTE {
    @include custom-palette-settings(30% 19.5% 0, 5% 0 0 0, 28%, 75%, 100%, 33%);
    @include custom-palette-draggable(50px);
  }
  &.MAC_4x2_PALETTE {
    .toggle-artist-overlay {
      top: 0;
    }
    .product__header-intro {
      top: 20px;
    }
    &[data-insert='1'] {
      @include custom-palette-draggable(66px);
    }
    &[data-insert='2'] {
      @include custom-palette-draggable(56px);
    }
    .product__detail-insert-1 {
      @include custom-palette-settings(47% 0 0 19%, 0, 32.5%, 79%, 94%, 50%);
      width: 63%;
    }
    .product__detail-insert-2 {
      @include custom-palette-settings(82% 33% 0 0, 0, 35%, 68%, 75%, 100%);
      width: 37%;
      .product__detail-zone:empty::before {
        padding-top: 26%;
      }
    }
  }
  &.MAC_2x12_PALETTE {
    .toggle-artist-overlay {
      top: 0;
    }
    .product__header-intro {
      top: 20px;
    }
    &[data-insert='1'] {
      @include custom-palette-draggable(67px);
    }
    &[data-insert='2'] {
      @include custom-palette-draggable(38px);
    }
    .product__detail-insert-1 {
      @include custom-palette-settings(82% 0 0 33%, 0, 32%, 85%, 96%, 100%);
      width: 36%;
    }
    .product__detail-insert-2 {
      @include custom-palette-settings(47% 18% 0 2%, 0, 21.75%, 94%, 84%, 24.25%);
      .product__detail-zone:nth-child(2n) {
        margin-right: 0.5%;
      }
      width: 64%;
    }
  }
  &.MAC_2x2x6_PALETTE {
    .toggle-artist-overlay {
      top: 0;
    }
    .product__header-intro {
      top: 20px;
    }
    &[data-insert='1'] {
      @include custom-palette-draggable(66px);
    }
    &[data-insert='2'] {
      @include custom-palette-draggable(56px);
    }
    &[data-insert='3'] {
      @include custom-palette-draggable(38px);
    }
    .product__detail-insert-1 {
      @include custom-palette-settings(80.5% 1.3% 0 34.5%, 0, 32.4%, 83%, 94%, 100%);
      width: 36.8%;
    }
    .product__detail-insert-2 {
      @include custom-palette-settings(121.3% 12% 0 18%, 0, 35%, 94%, 74%, 100%);
      width: 25.1%;
    }
    .product__detail-insert-3 {
      @include custom-palette-settings(79.8% 34.1% 0 2.42%, 0, 21.61%, 94%, 83%, 50%);
      width: 38.1%;
    }
  }
  &.MAC_15x4x2_PALETTE {
    &[data-insert='1'] {
      @include custom-palette-draggable(30px);
    }
    &[data-insert='2'] {
      @include custom-palette-draggable(56px);
    }
    &[data-insert='3'] {
      @include custom-palette-draggable(46px);
    }
    .product__detail-insert-1 {
      @include custom-palette-settings(10% 19% 0, 0, 31%, 79.1%, 84.4%, 20%);
      height: 50%;
      width: 100%;
      .product__detail-zone:empty::before {
        padding-top: 24%;
      }
    }
    .product__detail-insert-2 {
      @include custom-palette-settings(6.4% 0% 0.5% 29.4%, 0, 41%, 78%, 95%, 50%);
      height: 50%;
      width: 60.5%;
    }
    .product__detail-insert-3 {
      @include custom-palette-settings(11.4% 49.1% 0 6.2%, 0, 43.8%, 79%, 76%, 100%);
      height: 50%;
      width: 39.5%;
    }
  }
  &.MAC_30_PALETTE {
    @include custom-palette-settings(13% 22% 0, 0, 12.75%, 80%, 86%, 20%);
    @include custom-palette-draggable(30px);
    .product__detail-insert-1 {
      .product__detail-zone:empty::before {
        padding-top: 26%;
      }
    }
  }
}

// for cart overlay and cart landing
// DO NOT USE RTL MIXINS
.cart-product-kit-shades {
  @include clearfix();
  background-color: $color--black;
  margin: 0 auto;
  padding: 10px 5px 5px 10px;
  &-insert {
    display: inline;
    float: left;
    width: 100%;
  }
  .cart-product-kit-shade {
    @include border-radius(50%);
    display: inline;
    float: left;
    margin: 0 4% 4% 0;
  }
  // 100% / columns, subtract margin (4%)
  &.MAC_2_PALETTE {
    .cart-product-kit-shade {
      padding-top: 46%;
      width: 46%;
    }
  }
  &.MAC_2_PALETTE_2 {
    .cart-product-kit-shade {
      padding-top: 46%;
      width: 46%;
    }
  }
  &.MAC_4_PALETTE {
    width: 89%;
    .site-bag__contents &.cart-product-img {
      margin: 20px 22px 0 7px;
      width: 110px;
    }
    .cart-product-kit-shade {
      padding-top: 46%;
      width: 46%;
    }
  }
  &.MAC_4_PALETTE_2 {
    width: 89%;
    .site-bag__contents &.cart-product-img {
      margin: 20px 22px 0 7px;
      width: 110px;
    }
    .cart-product-kit-shade {
      padding-top: 46%;
      width: 46%;
    }
  }
  &.MAC_6_PALETTE {
    width: 63%;
    .site-bag__contents &.cart-product-img {
      margin: 20px 38px 0 23px;
      width: 78px;
    }
    .cart-product-kit-shade {
      padding-top: 46%;
      width: 46%;
    }
  }
  &.MAC_2_CREME_PALETTE {
    width: 50%;
    .site-bag__contents &.cart-product-img {
      margin: 20px 46px 0 31px;
      width: 62px;
    }
    .cart-product-kit-shade {
      padding-top: 96%;
      width: 96%;
    }
  }
  &.MAC_2_BLUSH_PALETTE {
    width: 50%;
    .site-bag__contents &.cart-product-img {
      margin: 20px 46px 0 31px;
      width: 62px;
    }
    .cart-product-kit-shade {
      padding-top: 96%;
      width: 96%;
    }
  }
  &.MAC_12_PALETTE {
    .cart-product-kit-shade {
      padding-top: 21%;
      width: 21%;
    }
  }
  &.MAC_9_PALETTE {
    .cart-product-kit-shade {
      padding-top: 29%;
      width: 29%;
    }
  }
  &.MAC_15_PALETTE {
    .cart-product-kit-shade {
      padding-top: 16%;
      width: 16%;
    }
  }
  &.MAC_6_BLUSH_PALETTE_2 {
    .cart-product-kit-shade {
      padding-top: 29%;
      width: 29%;
    }
  }
  &.MAC_6_BLUSH_PALETTE {
    .cart-product-kit-shade {
      padding-top: 29%;
      width: 29%;
    }
  }
  &.MAC_6_PRO_LONGWEAR_PALETTE {
    .cart-product-kit-shade {
      padding-top: 29%;
      width: 29%;
    }
  }
  &.MAC_30_PALETTE {
    width: 76%;
    .site-bag__contents &.cart-product-img {
      margin: 20px 30px 0 15px;
      width: 94px;
    }
    .cart-product-kit-shade {
      padding-top: 16%;
      width: 16%;
    }
  }
  &.MAC_1x1_PALETTE {
    .insert-1 {
      width: 50%;
      .cart-product-kit-shade {
        padding-top: 96%;
        width: 96%;
      }
    }
    .insert-2 {
      width: 50%;
      .cart-product-kit-shade {
        padding-top: 96%;
        width: 96%;
      }
    }
  }
  &.MAC_2x2_PALETTE {
    width: 89%;
    .site-bag__contents &.cart-product-img {
      margin: 20px 22px 0 7px;
      width: 110px;
    }
    .insert-1 {
      width: 50%;
      .cart-product-kit-shade {
        padding-top: 96%;
        width: 96%;
      }
    }
    .insert-2 {
      width: 50%;
      .cart-product-kit-shade {
        padding-top: 96%;
        width: 96%;
      }
    }
  }
  &.MAC_2x4_PALETTE {
    width: 63%;
    .site-bag__contents &.cart-product-img {
      margin: 20px 38px 0 23px;
      width: 78px;
    }
    .insert-1 {
      height: 44%;
      .cart-product-kit-shade {
        padding-top: 46%;
        width: 46%;
      }
    }
    .insert-2 {
      height: 56%;
      .cart-product-kit-shade {
        padding-top: 46%;
        width: 46%;
      }
    }
  }
  &.MAC_2x6_PALETTE {
    .insert-1 {
      width: 47.5%;
      .cart-product-kit-shade {
        padding-top: 79%;
        width: 79%;
      }
    }
    .insert-2 {
      width: 52.5%;
      .cart-product-kit-shade {
        padding-top: 46%;
        width: 46%;
      }
    }
  }
  &.MAC_2x9_BLUSH_PALETTE {
    .insert-1 {
      width: 38%;
      .cart-product-kit-shade {
        padding-top: 77%;
        width: 77%;
      }
    }
    .insert-2 {
      width: 62%;
      .cart-product-kit-shade {
        padding-top: 29%;
        width: 29%;
      }
    }
  }
  &.MAC_2x9_CREME_PALETTE {
    .insert-1 {
      width: 38%;
      .cart-product-kit-shade {
        padding-top: 77%;
        width: 77%;
      }
    }
    .insert-2 {
      width: 62%;
      .cart-product-kit-shade {
        padding-top: 29%;
        width: 29%;
      }
    }
  }
  &.MAC_4x2_PALETTE {
    .insert-1 {
      width: 63%;
      .cart-product-kit-shade {
        padding-top: 46%;
        width: 46%;
      }
    }
    .insert-2 {
      width: 37%;
      .cart-product-kit-shade {
        margin: 0 0 11% 11%;
        padding-top: 74%;
        width: 74%;
      }
    }
  }
  &.MAC_2x12_PALETTE {
    .insert-1 {
      width: 36%;
      .cart-product-kit-shade {
        padding-top: 74%;
        width: 74%;
      }
    }
    .insert-2 {
      width: 64%;
      .cart-product-kit-shade {
        margin: 5% 4% 0 0;
        padding-top: 21%;
        width: 21%;
      }
    }
  }
  &.MAC_2x2x6_PALETTE {
    .insert-1 {
      width: 33%;
      .cart-product-kit-shade {
        padding-top: 90%;
        width: 90%;
      }
    }
    .insert-2 {
      width: 33%;
      .cart-product-kit-shade {
        margin: 5% 4% 4% 0;
        padding-top: 80%;
        width: 80%;
      }
    }
    .insert-3 {
      width: 33%;
      .cart-product-kit-shade {
        margin: 15% 10% 0 0;
        padding-top: 40%;
        width: 40%;
      }
    }
  }
  &.MAC_15x4x2_PALETTE {
    width: 74%;
    .site-bag__contents &.cart-product-img {
      margin: 20px 31px 0 16px;
      width: 91px;
    }
    .insert-1 {
      height: 50%;
      width: 100%;
      .cart-product-kit-shade {
        padding-top: 16%;
        width: 16%;
      }
    }
    .insert-2 {
      height: 50%;
      width: 63%;
      .cart-product-kit-shade {
        padding-top: 46%;
        width: 46%;
      }
    }
    .insert-3 {
      height: 50%;
      width: 37%;
      .cart-product-kit-shade {
        margin: 6% 0 9% 14%;
        padding-top: 70%;
        width: 70%;
      }
    }
  }
}

// used when tooltipster is inside of a container with scrollbars
// https://github.com/iamceege/tooltipster/pull/59
.tooltipster-body-overflow-fix {
  overflow-x: visible !important;
}
