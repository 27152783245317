// _text_brick

.image-text-brick {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  &__image {
    width: 100%;
  }
  &__caption {
    background-color: $color--white;
    @include swap_direction(padding, 15px 30px 15px 30px);
    @include breakpoint($bp--medium-up) {
      margin: 0 auto;
      min-height: 178px;
      @include swap_direction(padding, 54px 10.5% 52px 10.5%);
    }
    @include breakpoint($bp--largest-up) {
      bottom: 0;
      #{$ldirection}: 0;
      max-width: $width-xxlarge;
      //position: absolute;
      #{$rdirection}: 0;
      top: auto;
      width: 100%;
    }
  }
  &__caption-inner {
    @include clearfix;
    margin: 0 auto;
    position: relative;
  }
  &__image {
    //display: none;
    margin: 0 auto;
    text-align: center;
    width: 200px;
    @include breakpoint($bp--medium-up) {
      display: inline;
      float: #{$ldirection};
      @include swap_direction(margin, 0 23px 15px 0);
    }
  }
  &__content {
    clear: both;
    margin: $spacing 0;
    @include breakpoint($bp--medium-up) {
      clear: none;
      margin: 0;
      max-width: 783px;
      @include swap_direction(padding, 0 0 0 223px);
    }
    p {
      margin: 0;
      position: relative;
      top: -0.4em;
    }
  }
  &__cta {
    padding-top: 1.4em;
  }
  &__cta-link {
    /* text-transform: none;
    border-bottom: none;
    color: pink; */
  }
}
