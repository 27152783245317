/**
 * @file
 * Product Full - NEW for 2018 Creative Refresh
 *
 * Beware to NOT use classes below - these are used in old styles and targeted by the old js library...
 *
 * 1) Full SPP: .product--full
 * 2) MPP grid: .product--teaser
 * 3) Collections quickshop: .collection-quickshop
 * 4) Mini teaser variant: .product--teaser--mini
 * 5) Tiny teaser variant: .product--teaser--tiny
 * 6) List view variant: .product--list-view
 */

// Overrides in sites/maccosmetics/themes/$locale/scss/$locale_pc.scss
$product-full-overview-height: 365px !default;
$pad-width: 24px;

.product-full {
  max-width: $width-xxlarge;
  margin: 0 auto;
  position: relative;
  width: 100%;
  @include breakpoint(($bp--large-up) (orientation landscape)) {
    padding: 3.7em 1em 0;
    overflow: hidden;
  }
  @include breakpoint(bp--xxlarge-up) {
    padding: 4em 0;
  }
  &__details-container {
    float: #{$ldirection};
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      width: 50%;
      margin-bottom: 0;
    }
    @include breakpoint($bp--xlarge-up) {
      width: 40%;
      min-width: 400px;
    }
    @include breakpoint($bp--medium-landscape) {
      width: 50%;
    }
    @include breakpoint($bp--medium-portrait) {
      width: 100%;
    }
    .product-full__iln-disclaimer {
      margin-top: 10px;
    }
  }
  &__shades-panel-link {
    @include swap_direction(margin, -23px $pad-width 10px 0);
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      @include swap_direction(margin, 0 $pad-width 10px 0);
    }
  }
  &__images {
    position: relative;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      top: 0;
    }
    .zoomImg {
      background: $color--white;
    }
  }
  &__sku-details {
    background-color: $color--white;
    position: relative;
    z-index: 1;
    margin-top: 30px;
    border-bottom: $color--gray--light solid 1px;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      border: $color--gray--light solid 1px;
      border-top: none;
      // @todo hardcoded this instead of min-width
      width: 500px;
      z-index: 1;
      margin-top: -10px;
      margin-bottom: 15px;
      &.sticky {
        position: fixed;
        top: 150px;
      }
      &.stuck {
        position: absolute;
        bottom: 0;
      }
    }
    @include breakpoint($bp--medium-portrait) {
      width: auto;
    }
  }
  &__rating {
    cursor: pointer;
    margin-#{$rdirection}: $pad-width;
    padding-bottom: 4px;
    display: none;
    &-noclick {
      display: block;
      pointer-events: none;
    }
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      height: 20px;
      margin: 0;
      border-bottom: 0;
      display: block;
    }
    .product__rating-count {
      display: none;
      padding-bottom: 4px;
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        border-bottom: 0;
        padding-bottom: 5px;
        &:hover {
          border-bottom: 1px solid $color--gray--lightest;
          padding-bottom: 4px;
        }
      }
    }
    .product__rating-count-underline {
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        border-bottom: 1px solid $color--gray--lightest;
        padding-bottom: 4px;
      }
    }
  }
  &__sku-extras {
    font-family: $ano-bold_regular-font;
    font-size: 15px;
    position: relative;
    vertical-align: middle;
    width: 100%;
    @if $fonts_update == true {
      @include h6();
    }
    @media screen and (-webkit-min-device-pixel-ratio: 2) {
      // iphones zoom the page if select text is lower then 16px
      font-size: 16px;
      @if $fonts_update == true {
        font-size: 15px;
      }
    }
    &.product-full__sku-extras--has-replenishment,
    &.product-full__sku-extras--has-wishlist,
    &.product-full__sku-extras--has-share {
      border-top: $color--gray--light solid 1px;
      display: table;
      height: 50px;
      background-color: $color--white;
    }
    &-wishlist {
      display: none;
      width: 100%;
      .product-full__sku-extras--has-replenishment & {
        float: #{$ldirection};
        width: 50%;
      }
      .product-full__sku-extras--has-wishlist & {
        display: block;
        height: 50px;
        line-height: 50px;
        overflow: hidden;
      }
      .product-full__sku-extras--has-share & {
        float: #{$ldirection};
        width: 50%;
      }
    }
    &-replenishment {
      display: none;
      width: 100%;
      .product-full__sku-extras--has-wishlist & {
        float: #{$ldirection};
        width: 50%;
      }
      .product-full__sku-extras--has-replenishment & {
        display: block;
        height: 50px;
        line-height: 50px;
      }
    }
    &-share {
      display: none;
      width: 100%;
      .product-full__sku-extras--has-wishlist & {
        float: #{$ldirection};
        width: 50%;
      }
      .product-full__sku-extras--has-share & {
        display: block;
        height: 50px;
        line-height: 50px;
      }
    }
    &.product-full__sku-extras--has-replenishment {
      &.product-full__sku-extras--has-wishlist {
        .product-full__sku-extras-share {
          float: none;
          width: 100%;
        }
      }
    }
  }
  &__media {
    max-width: 100%;
    min-height: 100px;
    position: relative;
    @include breakpoint($bp--medium-up) {
      height: 500px;
    }
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      vertical-align: top;
      width: 50%;
      height: auto;
      min-height: auto;
      float: #{$ldirection};
    }
    @include breakpoint($bp--xlarge-up) {
      width: 60%;
    }
    @include breakpoint($bp--medium-landscape) {
      width: 50%;
    }
    @include breakpoint($bp--medium-portrait) {
      width: 100%;
    }
  }
  &__overview-tabs {
    text-transform: uppercase;
    background: $color--white;
    border-bottom: $color--gray--light solid 1px;
    height: 30px;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    white-space: nowrap;
    font-size: 0;
    &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
    }
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      margin: 0 -1px;
      overflow-x: hidden;
    }
    &--pc-tabs {
      display: none;
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        display: block;
        background: transparent;
        margin: 0;
        border-bottom: none;
      }
    }
  }
  &__overview-trigger {
    display: inline-block;
    span {
      display: inline-block;
      color: $color--gray--light;
      cursor: pointer;
      font-family: $ano-bold_regular-font;
      font-size: 15px;
      line-height: 30px;
      height: 30px;
      padding: 0 $pad-width;
      white-space: nowrap;
      @if $fonts_update == true {
        @include h6();
        height: 25px;
        line-height: 25px;
      }
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        padding: 0 32px;
      }
    }
    &:after {
      border-#{$rdirection}: $color--gray--light solid 1px;
      content: ' ';
      position: relative;
      display: inline-block;
      height: 14px;
    }
    &:last-child {
      &:after {
        border-#{$rdirection}: 0;
      }
    }
    // only 2 tabs are visible on PC we need this specificity
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      &:nth-child(2) {
        &:after {
          border-#{$rdirection}-width: 0;
          display: none;
        }
      }
    }
    &.current {
      span {
        border-bottom: $color--gray--lighter solid 3px;
        color: $color--black;
      }
    }
    &--mobile-tab {
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        display: none;
      }
    }
  }
  &__title {
    margin-#{$ldirection}: $pad-width;
    width: 50%;
    font-family: $ano-bold_regular-font;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      margin: 1.3333em 0 0 0;
      width: 100%;
    }
  }
  &__name {
    font-family: $ano-bold_regular-font;
    font-size: 15px;
    line-height: 1;
    margin: 0;
    position: relative;
    text-transform: uppercase;
    @if $fonts_update == true {
      @include h4();
      letter-spacing: 0;
    }
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      font-size: 20px;
    }
    @include breakpoint($bp--xlarge-up) {
      @if $fonts_update == true {
        font-size: 23px;
        line-height: 25px;
      }
    }
    .product-full__title--has-badge & {
      display: inline-block;
    }
  }
  &__badge {
    color: $color--red;
    text-transform: uppercase;
    &--mobile {
      font-size: 12px;
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        display: none;
      }
    }
    &--pc {
      display: none;
      font-size: 12px;
      line-height: 20px;
      margin-top: 0;
      height: 20px;
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        display: inline-block;
        margin-#{$ldirection}: 10px;
        &:before {
          border-#{$ldirection}: $color--gray--light solid 1px;
          content: ' ';
          margin-#{$ldirection}: -10px;
          margin-top: -3px;
          height: 20px;
          position: absolute;
        }
      }
    }
  }
  &__short-desc {
    color: $color--gray--light;
    display: block;
    font-family: $roboto-mono_regular-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.6;
    margin: 0.2em 0 1em 0;
    text-transform: lowercase;
    @if $fonts_update == true {
      @include h5();
      font-size: 15px;
      font-weight: normal;
      line-height: 24px;
      text-transform: lowercase;
    }
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      margin: 0.15em 0 0.3em 0;
      font-size: 12px;
      line-height: 1.5;
      @if $fonts_update == true {
        font-size: 15px;
        line-height: 24px;
        text-transform: lowercase;
      }
    }
  }
  &__tabbed-content {
    overflow: hidden;
    opacity: 0;
    position: absolute;
    #{$rdirection}: 0;
    transition: all 0.75s ease;
    visibility: hidden;
    &.current {
      position: relative;
      opacity: 1;
      visibility: visible;
      width: 100%;
    }
    &--shades {
      transition: none;
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        overflow: visible;
        margin-top: -27px;
      }
    }
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      transition: none;
    }
  }
  &__price-details {
    position: relative;
    margin-bottom: 5px;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      float: #{$ldirection};
      margin: 5px 0;
      position: static;
    }
  }
  &__price {
    font-family: $ano-bold_regular-font;
    font-size: 15px;
    display: inline-block;
    line-height: 1.5;
    .product-price-installment {
      display: block;
      position: absolute;
      font-size: 12px;
      bottom: -12px;
      @if $fonts_update == false {
        font-family: $roboto-mono_regular-font;
      }
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        bottom: auto;
      }
    }
    > div {
      @if $fonts_update == true {
        @include h5();
        letter-spacing: 0;
        line-height: 24px;
        @include breakpoint($bp--xlarge-up) {
          font-size: 15px;
        }
      }
    }
  }
  .spp-shades__price {
    .product-price-installment {
      display: none;
    }
  }
  &__size,
  &__ppu {
    color: $color--gray--light;
    display: inline-block;
    @if $fonts_update == true {
      @include h5();
      font-size: 12px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
  &__ppu:before {
    content: '-';
    padding-inline: 8px 3px;
  }
  &__shades-panel-link {
    display: none;
    float: #{$rdirection};
    font-family: $ano-bold_regular-font;
    font-size: 15px;
    @if $fonts_update == true {
      @include h6();
      margin-bottom: 1.3em;
      margin-top: -22px;
    }
    @include breakpoint($bp--xxlarge-up) {
      top: 0;
      display: block;
    }
    @include breakpoint($bp--xlarge-up) {
      @if $fonts_update == true {
        margin-top: calc(0.64286em + 5px);
      }
    }
  }
  &__overview {
    padding: 1em 0 0;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      min-height: $product-full-overview-height;
      padding: 2em 2em 0;
      overflow: hidden;
    }
    .product-full__short-desc,
    .product-full__price-details,
    .product-full__shades-filters,
    .product-full__installment_price,
    .product-full__early-access {
      padding: 0 $pad-width;
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        padding-#{$ldirection}: 0;
      }
    }
    .product-full__shades-filters {
      padding-bottom: 15px;
    }
  }
  &__findation {
    margin-inline-start: 15px;
    @include breakpoint($bp--large-up) {
      margin-inline-start: 0;
    }
  }
  &__details {
    padding: 2em;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      height: 369px;
      max-width: 100%;
      overflow-y: scroll;
    }
    &-body {
      margin-bottom: 1em;
      ul {
        list-style: disc;
        list-style-image: image-url('global/mac-bullet-small.png');
        margin-#{$ldirection}: 0;
      }
    }
  }
  &__shade-info {
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    padding-#{$ldirection}: $pad-width;
    padding-top: 25px;
    z-index: 1;
    -webkit-font-smoothing: antialiased;
    padding-bottom: 10px;
    @if $fonts_update == true {
      font-size: 15px;
    }
    &-name {
      &[data-hex='#ffffff'] {
        // override inline color from JS, background is white
        color: $color-cta-grey !important;
      }
      @if $fonts_update == true {
        font-family: $ano-bold_regular-font;
        font-size: 12px;
        letter-spacing: 0;
      } @else {
        font-family: $roboto-mono_bold-font;
      }
    }
    &-description {
      @if $fonts_update == true {
        font-family: $helvetica-font;
        font-size: 12px;
        letter-spacing: 0;
      }
    }
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      font-size: 14px;
      z-index: 1;
      padding-#{$ldirection}: 0;
      padding-top: 0;
      order: 2;
    }
    &-name,
    &-description {
      display: inline-block;
    }
  }
  &__smart-traffik {
    margin-top: 10px;
    cursor: pointer;
  }
  &__smart-traffik,
  &__add-to-bag {
    display: block;
    width: 100%;
    background-color: $color--black;
  }
  &__smart-traffik,
  &__add-to-bag .product-add-to-bag {
    display: block;
    border: none;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    color: $color--white;
    text-align: center;
    @if $fonts_update == true {
      @include h6();
      line-height: 50px;
    } @else {
      font-family: $ano-bold_regular-font;
      font-size: 18px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  &__add-to-bag .product-add-to-bag {
    @include breakpoint($bp--xlarge-up) {
      @if $fonts_update == true {
        @include h6();
        font-size: 15px;
        height: 50px;
        line-height: 50px;
      }
    }
  }

  // specificity below is needed
  &__find-in-store {
    .icon--geolocation {
      display: none;
    }
    .invis-start__content {
      border-top: 0;
      padding-left: 0;
      padding: 0 0.5em;
      text-align: center;
      @if $fonts_update == true {
        @include h6();
      }
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        padding: 15px 0.5em 0;
      }
    }
    .invis-start__panel .invis-start__link--open-finder {
      margin: 0 auto;
    }
    .invis-start__panel {
      margin: 0;
      border-bottom: 0;
    }
  }
  // Preorder inventory label specific to spp display
  &__preorder-label {
    color: $color--red;
    font-family: $ano-bold_regular-font;
    font-size: 15px;
    line-height: 1;
    margin: 0;
    position: relative;
    text-transform: uppercase;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      font-size: 20px;
    }
  }
  .bopis-spp-container {
    display: flex;
    max-width: $width-xxlarge;
    margin: 0 auto;
    > div {
      padding: 10px;
      position: relative;
      &.bopis-spp-separator {
        height: 90px;
        background: $color--gray--white;
        padding: 0;
        width: 2px;
        align-self: center;
      }
    }
    > div:first-child {
      border-left: 0;
    }
    .product-full__offer,
    .product-full__find-in-store {
      width: 50%;
    }
    .spp-inline-bopis {
      width: 50%;
    }
    &.bopis-no-stores-available {
      .product-full__offer {
        width: 100%;
      }
    }
  }
  &__installment_price {
    clear: both;
    line-height: 1;
    .afterpay-paragraph {
      margin-bottom: 0.25em;
      display: flex;
      @if $fonts_update == true {
        font-size: 15px;
      }
      a {
        border: none;
      }
      &:first-letter {
        text-transform: uppercase;
      }
      .afterpay-link {
        width: 15px;
        height: 15px;
        background: $color--gray--lighter;
        font-family: $roboto-mono_bold-font;
        border-radius: 50%;
        text-align: center;
        padding-#{$rdirection}: 1px;
        font-size: 9.69px;
        font-weight: 400;
        text-transform: lowercase;
        text-decoration: none !important;
        color: $color--white !important;
        line-height: 1.56;
        vertical-align: text-bottom;
        order: 3;
        border: none;
      }
    }
    img {
      width: 80px;
      order: 2;
      margin-#{$rdirection}: 5px;
    }
    .afterpay-text2 {
      order: 1;
      margin-#{$rdirection}: 5px;
    }
  }
  &__installment_price.v1 {
    position: relative;
    height: 60px;
    margin-top: 40px;
    img {
      width: 130px;
    }
  }
  &__early-access {
    color: $color--red;
  }
  &__offer {
    text-align: center;
  }
  &--enhanced {
    &.spp-breadcrumbs {
      display: block;
      margin-#{$ldirection}: 10px;
      @include breakpoint($bp--medium-down) {
        margin-#{$ldirection}: 20px;
        padding-#{$ldirection}: 0;
      }
    }
    .product-full {
      &__klarna-price-container {
        @include fontsize-rem(12px);
        margin-top: 15px;
        order: 3;
        width: 100%;
      }
      &__overview {
        @include breakpoint(($bp--large-up) (orientation landscape)) {
          padding-top: 0;
        }
      }
      &__badge--pc:before {
        @include breakpoint(($bp--large-up) (orientation landscape)) {
          display: none;
        }
      }
      &__overview-tabs {
        height: 0;
        border-bottom: none;
        li.product-full__overview-trigger {
          display: none;
        }
        @include breakpoint(($bp--large-up) (orientation landscape)) {
          display: none;
        }
      }
      &__title {
        display: none;
        @include breakpoint($bp--large-up) {
          display: block;
        }
        @include breakpoint($bp--medium-down) {
          width: 85%;
          margin-#{$ldirection}: 20px;
        }
        @include breakpoint(($bp--large-up) (orientation landscape)) {
          margin-top: 0;
        }
        .product-full__name {
          font-size: 23px;
          line-height: 1;
          @include breakpoint($bp--large-up) {
            font-size: 28px;
            line-height: 1.07;
          }
          &--rgn_name_below_subline {
            font-size: 15px;
            display: block;
          }
        }
        .product-full__short-desc {
          @include breakpoint($bp--medium-down) {
            color: $color--black;
            line-height: 1.2;
          }
        }
        .product-full__badge {
          display: none;
          &--enhanced {
            display: block;
          }
          &--mobile.product-full__sku-badge {
            display: block;
            @include breakpoint($bp--large-up) {
              display: none;
            }
          }
        }
      }
      &__badge--pc {
        display: none;
        margin: 0;
        color: $color--black;
        font-weight: bold;
        line-height: 1.19;
        font-size: 11px;
        height: inherit;
        @include breakpoint(($bp--large-up) (orientation landscape)) {
          display: block;
          order: 2;
          line-height: 1.15;
        }
      }
      &__price-details {
        & > div {
          line-height: 1.07;
        }
        .product-price-v1,
        .product-full__size,
        .product-full__ppu {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          @include breakpoint($bp--medium-down) {
            font-weight: normal;
            line-height: 0.87;
          }
        }
      }
      &__sku-details {
        border-bottom: none;
        @include breakpoint(($bp--large-up) (orientation landscape)) {
          border: none;
        }
      }
      &__short-desc {
        line-height: 1.07;
        display: block;
        color: $color--black;
        @include breakpoint($bp--medium-down) {
          display: none;
          line-height: 1.2;
          width: 85%;
        }
      }
      &__installment_price {
        .afterpay-paragraph {
          margin-bottom: 2px;
        }
        .afterpay-text2,
        .afterpay-text1 {
          margin-#{$rdirection}: 10px;
        }
      }
      &__installment_price.v1 {
        height: unset;
        margin-top: unset;
      }
      &__shades-container {
        .product-full__shade-info {
          @include breakpoint(($bp--large-up) (orientation landscape)) {
            padding-bottom: 5px;
          }
        }
      }
      .delivery_cntdwn_c.sticky {
        //important is used due to Inline code
        @include breakpoint(($bp--large-up) (orientation landscape)) {
          display: flex;
          flex-flow: column;
          flex: 1 1 100%;
          width: 100%;
          max-width: 100% !important;
          top: 0 !important;
        }
      }
      &__tabs-block {
        float: #{$ldirection};
        width: 100%;
        .accordion {
          overflow: hidden;
          width: 100%;
          max-width: 1280px;
          margin: 0 auto;
          background: $color--white;
          padding: 40px 0;
          &-tabs {
            display: none;
          }
          &-active {
            background: $color--transparent;
          }
          &-item {
            border-bottom: 1px solid $color--gray--lightest;
            &__label {
              margin: 0;
              padding: 1.25rem;
              cursor: pointer;
              position: relative;
              display: flex;
              &:hover {
                color: $color--gray--light;
                @include breakpoint($bp--medium-down) {
                  color: $color--black;
                }
              }
              &:after {
                @include arrow-pointer($color: $color--black, $direction: $rdirection);
                content: '';
                #{$rdirection}: 23px;
              }
            }
            &__container {
              padding: 0 1.25em;
              height: 0;
              overflow: hidden;
              opacity: 0;
              -webkit-transition: padding 0.2s ease, opacity 0.5s 0.15s ease;
              transition: padding 0.2s ease, opacity 0.5s 0.15s ease;
            }
          }
          &-active {
            .accordion-item {
              &__label {
                padding-bottom: 5px;
                &:hover {
                  background: $color--transparent;
                  color: $color--black;
                }
                &:after {
                  @include transform(rotate(45deg));
                  top: 8px;
                  #{$rdirection}: 20px;
                }
              }
              &__container {
                padding: 1.25em;
                height: auto;
                opacity: 1;
              }
            }
          }
          @include breakpoint($bp--large-up) {
            &-tabs {
              padding: 0;
              list-style: none;
              width: 100%;
              display: flex;
              z-index: 2;
              flex-wrap: wrap;
              max-width: 1280px;
              margin: 0 auto;
              &:focus {
                outline: none;
              }
              .accordion-tab {
                -webkit-transition: background 0.1s ease;
                transition: background 0.1s ease;
                background: $color--white;
                padding: 15px 0;
                -webkit-box-flex: 1;
                flex: 1;
                border: none;
                margin: 0;
                cursor: pointer;
                overflow: hidden;
                position: relative;
                text-transform: uppercase;
                flex-grow: 1;
                text-align: center;
                outline: none;
                color: $color--gray--light;
                &:before {
                  z-index: 2;
                  position: absolute;
                  content: '';
                  border-bottom: 1px solid $color--gray--lightest;
                  width: 100%;
                  bottom: 0;
                  #{$ldirection}: 0;
                  #{$rdirection}: 0;
                }
                &:hover {
                  h6 {
                    color: $color--gray--lightest;
                  }
                }
                &.accordion-tab--claims {
                  overflow: visible;
                  white-space: nowrap;
                }
                &.accordion-active {
                  background-color: $color--white;
                  z-index: 3;
                  color: $color--black;
                  text-decoration: none;
                  outline: none;
                  &:hover {
                    .accordion-tab__headings {
                      color: $color--black;
                    }
                  }
                  .accordion-tab__headings {
                    &:after {
                      #{$ldirection}: 0;
                      bottom: -1.09em;
                      width: 100%;
                      height: 0;
                      border-bottom: 1px solid $color--black;
                      content: '';
                      position: absolute;
                      z-index: 3;
                    }
                  }
                }
                .accordion-tab__headings {
                  @include swap_direction(margin, 0 5px 0 0);
                  position: relative;
                  display: inline;
                }
                &:first-of-type {
                  text-align: #{$ldirection};
                }
                &:last-of-type {
                  text-align: #{$rdirection};
                  .accordion-tab__headings {
                    margin-#{$rdirection}: 0;
                  }
                }
              }
              .accordion-tab:first-child:nth-last-child(1) {
                text-align: #{$ldirection};
              }
              .accordion-tab:first-child:nth-last-child(2),
              .accordion-tab:first-child:nth-last-child(2) ~ .accordion-tab {
                flex: 0 0 25%;
              }
              .accordion-tab:first-child:nth-last-child(3),
              .accordion-tab:first-child:nth-last-child(3) ~ .accordion-tab {
                flex: 0 0 19%;
                text-align: #{$ldirection};
              }
              .accordion-tab:first-child:nth-last-child(3):last-of-type,
              .accordion-tab:first-child:nth-last-child(3) ~ .accordion-tab {
                text-align: center;
              }
              .accordion-tab:first-child:nth-last-child(2).accordion-tab--claims,
              .accordion-tab:first-child:nth-last-child(2):last-of-type,
              .accordion-tab:first-child:nth-last-child(2) ~ .accordion-tab {
                text-align: #{$ldirection};
                flex: 0 1 auto;
              }
            }
            &-item {
              padding: 25px 0;
              display: none;
              border: none;
              background: $color--transparent;
              &.accordion-active {
                display: block;
                .accordion-item__container {
                  padding: 0;
                  height: auto;
                  opacity: 1;
                }
              }
              &:last-child {
                border: none;
              }
              &__label {
                display: none;
                &:after {
                  display: none;
                }
              }
            }
            &-content {
              min-height: 150px;
              margin-top: -1px;
              z-index: 1;
              &:before {
                position: absolute;
                content: '';
                border-bottom: 1px solid $color--gray--lightest;
                max-width: 1280px;
                width: 100%;
                z-index: 1;
              }
            }
          }
        }
        .product-full__iln-disclaimer {
          margin-top: 10px;
        }
      }
      &__media {
        @include breakpoint($bp--medium-down) {
          padding-bottom: 30px;
        }
        @include breakpoint($bp--medium-portrait) {
          margin-bottom: 20px;
        }
      }
      &__header {
        margin-top: 5px;
        @include breakpoint($bp--large-up) {
          margin-top: 0;
        }
        .product-full__badge--mobile-enhanced,
        .product-full__title,
        .product-full__short-desc,
        .product-full__rating {
          display: block;
          @include breakpoint($bp--large-up) {
            display: none;
          }
        }
        .product-full__badge--mobile-enhanced,
        .product-full__short-desc,
        .product-full__rating {
          margin-#{$ldirection}: 24px;
          @include breakpoint($bp--medium-down) {
            margin-#{$ldirection}: 20px;
            margin-#{$rdirection}: 20px;
          }
        }
        .product-full__badge--mobile-enhanced {
          font-family: $ano-bold_regular-font;
          font-size: 12px;
        }
        .product__rating-count {
          display: block;
        }
      }
    }
  }
  #teester-player {
    margin: 0 auto;
    padding-bottom: 56.25%;
  }
  .teester-wrapper {
    margin: 10px 0 15px 0;
  }
  .teester-link {
    clear: both;
    margin: 0 0 10px 0;
    @include breakpoint($bp--medium-down) {
      text-align: center;
    }
    a {
      display: block;
      clear: both;
      font-size: 15px;
      font-weight: 500;
      text-transform: revert;
      font-family: $ano-bold_regular-font;
      text-decoration: underline;
      border: none;
      i {
        font-size: 25px;
      }
      margin: 25px 0 15px 0;
      @include breakpoint($bp--medium-up) {
        margin: 10px 0 0 0;
      }
    }
  }
  @if $klarna_feature_enabled == true {
    // Klarna SPP instalments.
    &__klarna-price-wrapper {
      align-items: center;
      background: $color-grey-fill;
      display: flex;
      font-family: $ano-bold_regular-font;
      font-size: 11px;
      gap: 6px;
      letter-spacing: 0.3px;
      line-height: 1.416667;
      padding: 15px;
      text-transform: uppercase;
      @include breakpoint($bp--large-up) {
        font-size: 12px;
      }
      &:empty {
        visibility: hidden;
      }
      .klarna-account {
        &__message {
          width: 50%;
          @include breakpoint($bp--large-up) {
            width: 67%;
          }
        }
        &__prepend {
          display: none;
        }
        &__logo {
          background-image: url('#{$base-theme-path}svg-icons/src/icon--klarna.svg');
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 30px;
          min-width: 55px;
          text-indent: -999em;
          width: 55px;
        }
        &__learn-more {
          background-color: $color-text-grey;
          background-image: url('#{$base-theme-path}svg-icons/src/icon--info.svg');
          background-position: center center;
          background-size: 7px;
          background-repeat: no-repeat;
          border: 0;
          filter: invert(1);
          height: 13px;
          width: 13px;
          align-items: center;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          min-width: 14px;
          text-indent: -999rem;
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.product-full__inventory-status {
  text-transform: uppercase;
  position: relative;
  background-color: $color--white;
  .product-inventory-status {
    &__temp-oos-text,
    &__coming-soon-text {
      .notify-status {
        background-color: $color--black;
        color: $color--white;
        width: 100%;
        text-decoration: none;
        margin-bottom: 10px;
        @if $fonts_update == true {
          @include h6();
          line-height: 60px;
          margin-bottom: 10px;
        } @else {
          height: 50px;
          line-height: 50px;
          font-family: $ano-bold_regular-font;
          font-size: 18px;
        }
        &:hover {
          text-decoration: underline;
        }
        a.notify_me {
          color: $color--white;
          border-bottom: none;
        }
      }
    }
  }
}

#afterpay-modal-overlay {
  font-size: 16px;
  a {
    border: none;
  }
  .afterpay_modal_wrapper {
    h2 {
      font-size: 1.5em;
      text-transform: none;
    }
    p {
      margin-top: 1em;
    }
  }
}
