// Shared styles for <i> elements
[class*='icon--'] {
  @include font-icon-base();
  @include unset-underline-links($extend: false);
}

// Generate classes for each icon
@each $font-icon in $icons {
  .icon--#{nth($font-icon, 1)}::before {
    content: nth($font-icon, 2);
  }
}

// true as second options returns only path
$icon-path: image-url('', true) + '/icons/output/fonts';
// Font Source Files
@font-face {
  font-family: 'icons';
  src: url('#{$icon-path}/icons.eot?#{random(1000)}');
  src: url('#{$icon-path}/icons.eot?#{random(1000)}#iefix') format('embedded-opentype'), url('#{$icon-path}/icons.woff?#{random(1000)}') format('woff'), url('#{$icon-path}/icons.ttf?#{random(1000)}') format('truetype'), url('#{$icon-path}/icons.svg?#{random(1000)}#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.corner-triangle {
  @include corner-triangle;
}
