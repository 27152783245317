$fb_btn_bkgrd: #3c5a99;
$soc_network_btn: #4a76a8;

.social_login_v2 {
  &__social-login-container {
    text-align: center;
  }
  &__delimeter {
    position: relative;
    @include breakpoint($bp--medium-up) {
      clear: both;
      display: block;
      padding: 15px;
    }
  }
  &__delimeter-horizontal {
    @include swap_direction(margin, 15px auto 21px);
    position: relative;
    font-weight: 400;
    font-size: 17px;
    line-height: 15px;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
    &:before {
      content: '';
      background: $black;
      width: 100%;
      height: 2px;
      display: inline-block;
      position: absolute;
      top: 6px;
      #{$ldirection}: 0;
    }
  }
  &__delimeter-label {
    font-weight: 800;
    font-size: 17px;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
    background: $white;
    position: relative;
  }
}

.social-login-container {
  &__social-network {
    @include swap_direction(margin, 0 22px 0);
    height: 60px;
    display: inline-block;
    position: relative;
  }
  &__social-network-blocker {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    #{$ldirection}: 0;
    cursor: pointer;
    &_hidden {
      display: none;
    }
  }
  &__social-network-button {
    width: 60px;
    height: 60px;
    letter-spacing: -0.75px;
    border: none;
    line-height: 3;
    text-decoration: none;
    color: $white;
    font-size: 13px;
    background: $soc_network_btn url(/media/images/social_login/vk_icon.svg) 0 center no-repeat;
    background-size: 60px 60px;
    display: block;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    @include breakpoint($bp--medium-up) {
      width: 264px;
      height: 40px;
      background-size: 38px 26px;
      background-position-x: 10px;
    }
    &-label {
      display: none;
      @include breakpoint($bp--medium-up) {
        display: block;
      }
    }
  }
  &__social-network-button:visited {
    color: $white;
  }
  &__social-network-button_facebook {
    background: $fb_btn_bkgrd url(/media/images/social_login/fb_icon.svg) 0 center no-repeat;
    background-size: 49px 60px;
    letter-spacing: 0.2px;
    word-spacing: 7.5px;
    @include breakpoint($bp--medium-up) {
      background-size: 40px 40px;
    }
  }
}

.sign-in-component_ru {
  &__legal-container {
    margin-top: 13px;
    padding-#{$ldirection}: 10px;
    @include breakpoint($bp--large-up) {
      padding-#{$ldirection}: 44px;
    }
    .legal-container {
      &__acceptance-block_error {
        color: $color--red;
        .picker {
          label,
          a {
            color: $color--red;
            :hover {
              color: $color--red;
            }
          }
        }
      }
    }
  }
}

.sign-in-page {
  .social_login_v2 {
    &__delimeter {
      display: block;
      @include breakpoint($bp--medium-up) {
        display: none;
      }
    }
  }
}

.checkout {
  .social-login-container {
    &__social-network {
      &-button {
        display: inline-block;
      }
    }
  }
}

.legal-container {
  &__acceptance-block {
    .picker-label {
      width: 82%;
      float: none;
      text-align: #{$ldirection};
      margin-#{$ldirection}: 39px;
      @include breakpoint($bp--medium-up) {
        width: 93%;
      }
    }
  }
}

.site-my-mac-v1 {
  .site-my-mac__contents {
    .site-my-mac {
      &__contents-signin {
        .sign-in-component_ru {
          &__legal-container {
            margin-top: 0;
            padding-#{$ldirection}: 0;
            .legal-container {
              &__acceptance-block_error {
                label,
                a {
                  color: $color--red;
                }
              }
            }
          }
        }
      }
    }
  }
  .picker-label {
    width: 87%;
  }
  .social-login-container {
    &__social-network {
      &-button {
        @include breakpoint($bp--medium-up) {
          width: 60px;
          height: 60px;
          background-size: 60px 60px;
          background-position-x: 0;
          &_vkontakte {
            background-size: 60px 27px;
          }
        }
        &-label {
          @include breakpoint($bp--medium-up) {
            display: none;
          }
        }
      }
    }
  }
}

.social-info {
  .social-login-container {
    &__social-network-button {
      display: inline-block;
      margin-#{$ldirection}: 130px;
      background-size: 15px 13px;
      width: 170px;
      height: 19px;
      background-position-x: 10px;
      @include breakpoint($bp--medium-up) {
        margin-#{$ldirection}: 170px;
      }
    }
  }
  .vkontakte-login {
    line-height: 1;
    letter-spacing: -2px;
    font-size: 12px;
  }
  &__vk {
    padding-bottom: 10px;
  }
}
