.site-container {
  .grid--mpp {
    &__item {
      .product {
        &__link-custom {
          display: none;
        }
        &__detail {
          .cta-vto {
            display: none;
          }
        }
      }
    }
  }
  .artist--products {
    &__list {
      .product {
        &__link-custom {
          display: none;
        }
      }
    }
  }
  .artistry-video-player-wrapper {
    .prod_inv_status-2,
    .prod_inv_status-3 {
      .product__footer {
        .temp-out-of-stock {
          .temp-outof-stock_text {
            position: absolute;
            top: 55px;
          }
        }
        .coming-soon {
          .coming-soon_text {
            position: absolute;
            top: 55px;
          }
        }
        .product__price {
          @include swap_direction(margin, 0 0 20px 0);
        }
      }
    }
    .product__footer {
      .product__add-to-faves {
        // align to the top of the price (assuming no margin-top)
        top: 0px;
      }
      .product__price_ppu {
        display: none;
      }
      .product__price {
        @include swap_direction(margin, 0);
      }
      a.notify_me {
        @include swap_direction(margin, 0);
      }
    }
    .product__header {
      .product__price_ppu {
        display: block !important;
      }
    }
    .product__inventory-status {
      margin-bottom: 0;
    }
  }
  .prod_inv_status-2 {
    .product__footer {
      .product__add-to-bag {
        display: none;
      }
      .product__inv-status-item.temp-out-of-stock {
        display: block;
      }
    }
  }
  .prod_inv_status-3 {
    .product__footer {
      .product__add-to-bag {
        display: none;
      }
    }
  }
  .prod_inv_status-7 {
    .product__footer {
      .product__inv-status-item.sold-out {
        display: block;
      }
    }
  }
  .layout--artistry-artist {
    .shades {
      .product--shaded,
      .product--teaser {
        .product__footer {
          a.product__add-to-bag {
            @include swap_direction(margin, 10px 0 0 0);
            font-size: 11px;
            @include breakpoint($bp--large-up) {
              width: 140px;
              font-size: 12px;
            }
            @include breakpoint($bp--medium-up) {
              width: 120px;
            }
          }
        }
      }
    }
    .artist--faves {
      .artist--products__carousel-slide {
        .product {
          &__header {
            padding-bottom: 0;
            height: 100px;
            .product__name-link,
            .product__mini-detail {
              display: flex;
              text-align: $ldirection;
            }
          }
          &__images {
            position: static;
          }
          &__footer {
            position: relative;
            .product__price_ppu {
              position: fixed;
              top: 80px;
            }
          }
        }
        .product--not-shoppable {
          .product__shade-column {
            .shade-picker__trigger {
              @include breakpoint($bp--medium-up) {
                bottom: 110px;
              }
            }
          }
        }
      }
    }
  }
  .page--spp__product {
    .product__shipping {
      clear: both;
    }
  }
  .artistry-videos__formatter {
    .artistry-videos__filter {
      span.select2-chosen,
      p.sec-nav__link {
        text-decoration: underline;
      }
    }
  }
  .shade-picker-dropdown {
    .select2-container {
      .select2-choice {
        .select2-chosen {
          text-decoration: underline;
        }
      }
    }
  }
}

//for rtl add the LRM character after the product name to show product names with punctation (eg brackets) correctly
[dir='rtl'] {
  .site-container {
    .product__header {
      .product__name:after,
      .product__mini-detail:after {
        content: '\200E';
      }
    }
  }
  .site-header {
    .site-header__tools {
      .site-bag__contents {
        .cart-product-name {
          a:after {
            content: '\200E';
          }
        }
      }
    }
  }
}

.product__price_ppu {
  .product__footer & {
    .product--full & {
      margin: 15px;
      @include swap_direction(margin, $spacing);
      @include breakpoint($bp--xlarge-up) {
        margin-#{$ldirection}: 0;
      }
    }
  }
}

.product--teaser {
  .product__rating {
    @include breakpoint($bp--large-up) {
      .product__rating-non {
        display: block;
        max-width: 100px;
        text-align: $rdirection;
      }
    }
  }
}

// Adjust the "notify me" button size and position on the account pages to match the "add to bag" buttons.
// A lot of these styles "undo" styles applied in mac_base that contradict the "add to bag" styling, or clash.
// This tries to harmonise all the buttons on both the favourites and "past purchases"
// The max-width breakpoint is used to avoid reoverriding the global PC styles
.past-purchases,
.favorites-board,
.collection-detail-formatter {
  .product--teaser {
    .product__footer {
      // PC, small button, right aligned in the product footer with text beneath
      @include breakpoint($bp--small-up) {
        .notify-status {
          a.notify_me {
            float: #{$rdirection};
            margin: 0px;
            @include swap_direction(padding, 0px 15px);
            width: auto;
            line-height: 30px;
            position: static;
            @include breakpoint($bp--large-down) {
              float: #{$ldirection};
            }
          }
        }
        .product-item__out-of-stock,
        .product-item__coming-soon,
        .product-item__comingsoon {
          position: absolute;
          top: auto;
          bottom: auto;
          #{$rdirection}: 0;
          @if $fonts_update == true {
            font-size: 14px;
            margin-top: 40px;
          } @else {
            margin-top: 30px;
          }
          @include breakpoint($bp--large-down) {
            #{$ldirection}: 0;
          }
        }
      }
      // Mobile, full width buttons, with status text above it
      @include breakpoint(max-width $width-small - 1) {
        .notify-status {
          a.notify_me {
            position: relative;
            height: 60px;
            width: 100%;
            padding: 0 20px;
            line-height: 64px;
            @if $fonts_update == true {
              margin-bottom: 0.3em;
              margin-top: 0.3em;
            } @else {
              font-size: 1.5rem;
              margin: 25px 0 0 0;
            }
          }
        }
        .product-item__out-of-stock,
        .product-item__coming-soon,
        .product-item__comingsoon {
          position: relative;
          height: 0px;
          bottom: 85px;
          text-align: center;
        }
      }
    }
  }
}
