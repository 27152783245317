/* ==========================================================================
   Components Pages - Page SPP
   ========================================================================== */

// Demo'ing behavior
.page--spp-wide {
  .site-content,
  .responsive-container {
    max-width: 1400px;
  }
}

.page--spp-full {
  .site-content,
  .responsive-container {
    max-width: 100%;
  }
}

// Ultra wide variant request demo
.page--spp-ultra-wide {
  //  background: blue;
  .product__sticky-container {
    width: 100%;
    max-width: 100%;
  }
  .product--full {
    .product__header,
    .product__footer,
    .product__description__overlay-accordion {
      @include breakpoint($bp--xxlarge-up) {
        width: 320px;
      }
    }
    .shade-picker-dropdown {
      @include breakpoint($bp--xxlarge-up) {
        width: 320px;
        #{$rdirection}: 25%;
        #{$ldirection}: auto;
      }
    }
  }
  .product--full.product--shadegrid {
    .shade-picker-dropdown {
      @include breakpoint($bp--xxlarge-up) {
        width: 100%;
        #{$rdirection}: auto;
      }
    }
  }
}
//   SPP Zoom Image
.page-product {
  .ZoomContainer {
    z-index: 999;
    cursor: zoom-in;
    cursor: -webkit-zoom-in;
  }
  .zoomWindowContainer {
    visibility: hidden;
    display: none;
  }
  &.zoom-show {
    .ZoomContainer {
      cursor: zoom-out;
      cursor: -webkit-zoom-out;
    }
    .zoomWindowContainer {
      visibility: visible;
      display: block;
    }
    .product__header,
    .product__footer,
    .shade-picker-dropdown,
    .product__shade-column {
      opacity: 1;
    }
    &.zoom-hide-background {
      .product__header,
      .product__footer,
      .shade-picker-dropdown,
      .product__shade-column {
        opacity: 0;
      }
    }
  }
}
