.address-book-page__content.panel {
  @include swap_direction(padding, 0 10px);
  @include breakpoint($bp--medium-up) {
    @include layout($grid--2, 1em) {
      .address-book {
        @include grid-span(1, 1);
      }
      .payment-info {
        @include grid-span(1, 2);
      }
    }
  }
  .address-book__item {
    @include swap_direction(padding, 1em 0);
    max-width: 480px;
  }
  .payment-item__header {
    margin-top: 1em;
  }
  .address-book__header,
  .payment-info__header,
  .payment-item__header {
    text-align: center;
    @include breakpoint($bp--medium-up) {
      text-align: #{$ldirection};
    }
  }
}

@if $fonts_update == true {
  .address-form {
    .address-popup {
      .address-form__header {
        @include breakpoint($bp--large-up) {
          font-size: 30px;
        }
      }
    }
  }
  #address_form_container {
    form[name='address'] {
      h2 {
        font-size: 30px;
      }
    }
  }
}

.adyen-payment-fields {
  max-width: 660px;
  .adyen-payment-field {
    @include breakpoint($bp--medium-up) {
      width: 50%;
      padding-#{$rdirection}: 16px;
    }
    margin: 10px 0;
    width: 100%;
    float: #{$ldirection};
    p {
      margin-bottom: 0;
      text-transform: uppercase;
    }
    .input-field {
      outline: 0;
      border: 1px solid $color--gray--lighter;
      border-radius: 0;
      padding: 10px 10px 6px;
      display: block;
      margin-top: 5px;
      @include breakpoint($bp--medium-up) {
        &[data-cse='encryptedExpiryYear'] {
          margin-top: 23px;
        }
      }
    }
    iframe {
      height: 20px !important;
    }
  }
}
