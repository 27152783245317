/* ==========================================================================
   Components Organisms - Footer
   ========================================================================== */

.site-footer {
  color: $white;
  text-transform: uppercase;    
  clear: both;
  @if $cr20 {
    z-index: calc(#{$gnav-fixed-z-index} - 1);
  } @else {
    z-index: 1000;
  }
  .viewcart &,
  .checkout & {
    display: none;
    @include breakpoint($bp--large-up) {
      display: block;
    }
  }
  .mac-select-loyalty-footer-formatter-v1 {
    .mac-select-loyalty-footer-links a {
      color: $white;
      &:hover {
        border-bottom-color: $color--link-grey;
        color: $color--link-grey;
      }
    }
  }
}

.site-footer--top {
  @include reverse;
  @include fontsize-rem($typesize--12px);
  background: $color--gray--darkest;
  @include swap_direction(padding, 0);
  @if $fonts_update == true {
    font-size: 15px;
  }
  @include breakpoint($bp--largest-up) {
    @include fontsize-rem($typesize--12px);
    @include swap_direction(padding, 25px 0 74px 0);
    @if $fonts_update == true {
      font-size: 15px;
    }
  }
  .grid-container--6 {
    @include breakpoint($bp--largest-up) {
      @include layout($grid--6) {
        .grid--item {
          &:nth-of-type(6n + 1) {
            @include grid-span(1, 1);
          }
          &:nth-of-type(6n + 2) {
            @include grid-span(1, 2);
          }
          &:nth-of-type(6n + 3) {
            @include grid-span(1, 3);
          }
          &:nth-of-type(6n + 4) {
            @include grid-span(1, 4);
          }
          &:nth-of-type(6n + 5) {
            @include grid-span(1, 5);
          }
          &:nth-of-type(6n + 6) {
            @include grid-span(1, 6);
          }
        }
      }
    }
    .grid--item {
      background: transparent;
      display: block;
      float: none;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0 1.2em !important);
      text-align: #{$ldirection};
      width: 100%;
      @include breakpoint($bp--largest-up) {
        display: inline-block;
        float: #{$ldirection};
        margin-bottom: 0.5em;
        @include swap_direction(padding, 1em !important);
        vertical-align: top;
      }
    }
  }
  h6,
  .at-h6__sitefooter {
    @include antialiased;
    display: block;
    font-size: 18px;
    @include swap_direction(margin, 0.1875em 0 1.3em 0);
    @if $fonts_update == true {
      @include h4();
      letter-spacing: 0;
      margin: 0.1875em 0 1.3em;
    }
  }
  p {
    margin-bottom: 0.8em;
  }
  ul {
    li {
      @include swap_direction(margin, 0);
      @include breakpoint($bp--largest-up) {
        @include swap_direction(margin, 0 0 5px 0);
      }
      @if $fonts_update == true {
        font-family: $helvetica-font;
        font-size: 15px;
        text-transform: capitalize;
        a {
          text-transform: capitalize;
        }
      }
    }
  }
  .block--footer-mobile-help {
    @include swap_direction(margin, 0 $spacing);
    @include swap_direction(padding, ($spacing * 3) 0);
    @include breakpoint($bp--largest-up) {
      display: none;
    }
    &__title {
      @include fontsize-rem($typesize--18px);
      @include swap_direction(margin, 0 0 39px 0);
      text-align: center;
      display: block;
      @if $fonts_update == true {
        font-family: $ano-black_regular-font;
        font-weight: 400;
        letter-spacing: 0;
        line-height: $h6-lineheight;
      }
    }
    .btn {
      border-bottom: 1px solid $color--white;
      display: block;
      height: auto;
      @include swap_direction(margin, 0 2px);
      @include swap_direction(padding, 13px 5px);
      @if $fonts_update == true {
        font-size: 12px;
        line-height: 16px;
      } @else {
        font-size: 16px;
        line-height: normal;
      }
      @include breakpoint($bp--small-up) {
        font-size: 22px;
        @if $fonts_update == true {
          line-height: 24px;
        }
      }
      &:hover {
        border-bottom: 1px solid $color--white;
        text-decoration: none;
      }
      @include breakpoint($bp--xxxsmall-up) {
        font-size: 14px;
      }
      @include breakpoint($bp--xxsmall-up) {
        font-size: 15px;
      }
    }
  }
  .block--footer-stores {
    display: none;
    @include breakpoint($bp--largest-up) {
      display: inline-block;
    }
  }
  .block--footer-extra {
    @include swap_direction(margin, 30px 0 0 0);
    text-align: center;
    @include breakpoint($bp--largest-up) {
      @include swap_direction(margin, 0);
      text-align: #{$ldirection};
    }
    h6 {
      display: block;
    }
    .social-links {
      ul {
        @include clearfix;
        @include swap_direction(margin, 0 0 35px 0);
      }
      li {
        display: inline-block;
        float: none;
        @include breakpoint($bp--largest-up) {
          float: #{$ldirection};
          @include swap_direction(margin, 0 6.5% 0 0);
          &:last-child {
            @include swap_direction(margin, 0);
          }
        }
        @include breakpoint($bp--xxlarge-up) {
          @include swap_direction(margin, 0 18px 0 0);
          @if $fonts_update == true {
            @include swap_direction(margin, 0 15px 0 0);
          }
          &:last-child {
            @include swap_direction(margin, 0);
          }
        }
      }
      a {
        font-size: 30px;
        line-height: 1;
        @include unset-underline-links();
        @include breakpoint($bp--small-up) {
          font-size: 60px;
        }
        @include breakpoint($bp--largest-up) {
          font-size: 18px;
        }
      }
      svg {
        width: 18px;
        height: 18px;
        fill: $color--white;
      }
    }
    .language-picker {
      border-top: 1px solid $color--gray--light;
      color: $color--gray--light;
      @include swap_direction(margin, 0 0 15px);
      @include swap_direction(padding, 15px 0 0 0);
      @include breakpoint($bp--largest-up) {
        border-top: none;
        color: $color--white;
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
      }
      a {
        color: $color--gray--light;
      }
      .country {
        display: none;
        @include swap_direction(margin, 0);
        @include breakpoint($bp--largest-up) {
          display: block;
        }
      }
    }
  }
  .language-select__link--selected {
    border-bottom: none;
    text-decoration: none;
  }
  .accessibility_icon {
    margin-top: 25px;
  }
}

.footer-menu--pc {
  display: none;
  @include breakpoint($bp--largest-up) {
    display: block;
  }
  li {
    @include swap_direction(margin, 0);
    position: relative;
    color: $color--white;
    a {
      border-bottom: none;
      display: block;
      color: $color--white;
      @include swap_direction(padding, 0);
      text-decoration: underline;
    }
  }
  .liveperson,
  .liveperson img {
    margin-left: -9999px;
  }
}

.footer-menu--mobile {
  @include breakpoint($bp--largest-up) {
    display: none;
  }
  .field-menu > .menu > li {
    color: $color--gray--light;
    @include swap_direction(margin, 0);
    position: relative;
    &.submenu-visible {
      > a {
        &:before {
          @include transform(rotate(90deg));
          margin-#{$ldirection}: -5px;
        }
      }
      .menu-container {
        display: block;
      }
    }
    > a {
      @include icon(arrow--right);
      border-top: 1px solid $color--gray--light;
      border-bottom-width: 0;
      color: $color--gray--light;
      display: block;
      @include swap_direction(margin, 0 $spacing);
      @include swap_direction(padding, $spacing ($spacing * 1) $spacing 0);
      text-decoration: none;
      &:before {
        color: $color--white !important;
        line-height: 1.5;
        margin-top: -16px;
        position: absolute;
        #{$rdirection}: $spacing;
        top: ($spacing * 2);
      }
    }
  }
  .menu-container {
    display: none;
    background: $color--gray--darker;
    a {
      @include swap_direction(padding, $spacing);
      border-width: 0;
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.footer-lang--mobile {
  @include swap_direction(margin, 0 $spacing);
  @include swap_direction(padding, $spacing 0);
  border-top: 1px solid $color--gray--light;
  @include breakpoint($bp--largest-up) {
    display: none;
  }
  p {
    @include swap_direction(margin, 0);
  }
  .country {
    display: none;
  }
  .language {
    color: $color--gray--light;
    text-align: center;
    > a {
      color: $color--gray--light;
    }
  }
}

.footer-social--mobile {
  border-top: 1px solid $color--gray--light;
  @include swap_direction(margin, 0 $spacing);
  @include swap_direction(padding, ($spacing * 2) 0);
  @include breakpoint($bp--largest-up) {
    display: none;
  }
  .social-links {
    text-align: center;
    li {
      display: inline-block;
      padding-#{$ldirection}: 15px;
      &:first-child {
        padding-#{$ldirection}: 0px;
      }
    }
    a {
      @include unset-underline-links($extend: false);
      font-size: 20px;
      display: inline-block;
      margin-#{$ldirection}: 2px;
    }
    svg {
      width: 18px;
      height: 18px;
      fill: $color--white;
    }
  }
}

.block-nodeblock-520 {
  @include breakpoint($bp--largest-up) {
    display: none;
  }
}

.block-nodeblock-38 {
  display: none;
  @include breakpoint($bp--largest-up) {
    display: block;
  }
}

.site-footer--bottom {
  @include antialiased;
  @include clearfix;
  @include fontsize-rem($typesize--11px);
  font-family: $headline-font-family;
  @include swap_direction(padding, $spacing);
  background: $color--black;
  color: $color--gray--dark;
  text-align: center;
  @if $fonts_update == true {
    font-family: $ano-bold_regular-font;
  }
  @include breakpoint($bp--largest-up) {
    @include fontsize-rem($typesize--14px);
    text-align: #{$ldirection};
    @if $fonts_update == true {
      font-size: $typesize--13px;
    }
  }
  @include breakpoint($bp--xxlarge-up) {
    @include fontsize-rem($typesize--18px);
    @if $fonts_update == true {
      font-size: $typesize--15px;
    }
  }
  p {
    @include swap_direction(margin, 0);
  }
  .node-menu {
    @include responsive-container;
    text-align: center;
    @include breakpoint($bp--largest-up) {
      @include swap_direction(padding, 0);
      text-align: #{$ldirection};
    }
  }
  .menu {
    display: block;
    @include breakpoint($bp--largest-up) {
      display: table;
    }
    > li {
      display: inline-block;
      height: auto;
      line-height: 1;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0 0.2em);
      vertical-align: middle;
      @if $fonts_update == true {
        font-family: $ano-bold_regular-font;
        font-size: 12px;
      }
      &:before {
        content: '|';
        margin-#{$rdirection}: 7px;
      }
      &:first-child:before {
        content: '';
        margin-#{$rdirection}: 0;
      }
      @include breakpoint($bp--largest-up) {
        display: table-cell;
        height: 60px;
        @include swap_direction(margin, 0 0 20px 0);
        @include swap_direction(padding, 0 1.8em 0 0.7em);
        &:before {
          display: none;
        }
      }
    }
    a {
      border-bottom: none;
      color: $color--gray--dark !important;
      float: left;
      &:hover {
        border-bottom: none !important;
        color: $color--gray !important;
      }
    }
  }
}

.back-to-top {
  width: 34px;
  height: 34px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAARhJREFUeNrs2UEKgkAUBuDpIUERQrRw0dIzuHHtwiPoETyOO2+gBwjd2Ak8gweIKCGiICJzFm3apbzJpv8HHWZghvkYhJlx0rbtRgixFr+fk9G9Zt2z1AAjSGgUYIABBhhggAEGGGDGE6NPJ9d1Le6JxXHcOI5z+6TPpDvPbLvS1mBhGnwzwAADDDD/iYmiaFlV1fTnMWEYrpIkMT3Ps7hBxA3Jsmzh+/5F1rlBxA0JguBcFMW+LMsdN4i4IWmaHmSb3DRyg0gF5BVuEKmCqACRSgg3iFRDOEH0DQgXiL4F4QD1xtR1beR5Ph8CeQeZpvkYMs6gOwAJsm37rsUdwIggOAIAAwwwwAADDDDAjCzyN+BRE8v1KcAAOOS7Z8d7besAAAAASUVORK5CYII=');
  -moz-transition: opacity 2s ease-in-out;
  -o-transition: opacity 2s ease-in-out;
  -webkit-transition: opacity 2s ease-in-out;
  transition: opacity 2s ease-in-out;
  position: fixed;
  bottom: 40px;
  right: 10px;
  text-align: center;
  opacity: 0;
  @include breakpoint($bp--medium-up) {
    bottom: 10px;
  }
}

.back-to-top-show {
  cursor: pointer;
  opacity: 1;
  z-index: 100;
  @include breakpoint($bp--largest-up) {
    opacity: 0.8;
  }
}

body.section-home .back-to-top,
body.front .back-to-top {
  display: none;
}

/** Sticky Footer Styles For MAC loyalty Overlay **/
.site-footer {
  &--sticky {
    position: relative;
    &.is-sticky {
      bottom: 0;
      position: fixed;
      width: 100%;
      z-index: 101;
    }
    .sticky-footer-links-formatter__links {
      li {
        a {
          @if $fonts_update == true {
            @include h6();
            font-size: 13px;
            letter-spacing: 0;
          }
        }
      }
    }
  }
  &--bottom,
  &--top {
    position: relative;
    z-index: 10;
  }
}
