.ups-validation-notification-overlay {
  .ups-validation-header {
    margin-top: 32px;
    margin-bottom: 14px;
    text-align: center;
    padding: 0 70px;
    @include breakpoint($bp--medium-up) {
      padding: 0;
    }
  }
  .ups-validation-text {
    font-weight: bold;
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
    padding: 0 20px;
    @include breakpoint($bp--medium-up) {
      margin-bottom: 40px;
      padding: 0;
    }
  }
  .address-header {
    font-family: $ano-bold_regular-font;
    font-size: 14px;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  .address-grid {
    float: #{$ldirection};
    width: 100%;
    padding: 20px;
    @include breakpoint($bp--medium-up) {
      @include swap_direction(padding, 0 20px 0 40px);
      width: 50%;
    }
    .button.keep-address {
      color: $black;
      border-color: $black;
    }
    .button {
      padding: 0 35px;
    }
  }
  .address-grid.suggested-address {
    border-#{$ldirection}: none;
    float: #{$ldirection};
    @include breakpoint($bp--medium-up) {
      border-#{$ldirection}: $border;
      float: #{$rdirection};
    }
  }
  .address-grid.user-address {
    border-top: $border;
    @include breakpoint($bp--medium-up) {
      border-top: none;
    }
  }
}
