/*
 * Copied from /sites/all/libraries/elc_video_player/ELCVideoDefaults.css
 * @TODO clean this mess up!
 */

.video_player_container {
  float: #{$ldirection};
  position: relative;
  height: 100%;
  overflow: hidden;
  width: 100%;
  video,
  object {
    max-width: 100%;
  }
}

.video-player-wrapper {
  position: relative;
  top: 0;
  #{$ldirection}: 0;
  max-width: 100%;
  height: 100%;
}

.video_player {
  float: #{$ldirection};
  #{$ldirection}: 0;
  top: 0;
  max-width: 52%;
  position: relative;
}

#ELCVideoPlayer_video_player1 {
  height: 97%;
  width: 100%;
}

.video_player .video_player_poster {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  max-width: 100%;
}

.video_player .video_player_poster .end {
}

.video_player .video_player_poster img.poster {
  max-width: 100%;
  height: 100%;
}

.video_player_fullscreen .video_player_poster img.poster {
  height: 100%;
  width: 100%;
}

.video-info-container {
  float: #{$ldirection};
  height: 45%;
  position: relative;
  top: 0;
  width: 48%;
  transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}

@media screen and (max-width: 400px) {
  .video-info-container {
    font-size: 0.5em;
  }
}

@media screen and (max-width: 800px) {
  .video-info-container {
    font-size: 0.6em;
  }
}

#videoProductContainer div.image img {
  height: 70%;
  clear: both;
  width: 35%;
}

.video_player_controls_wrapper {
  #{$ldirection}: 0;
  position: relative;
  top: 0;
  width: 52%;
}

.video_player_controls {
  width: 100%;
  max-width: 100%;
}

.video_player1_controls {
  clear: both;
  float: #{$ldirection};
  width: 100%;
}

.progress_container_wrapper {
  position: relative;
  height: 17px;
  background-color: #eee;
  padding-top: 3px;
  padding-#{$ldirection}: 5px;
  float: #{$ldirection};
  margin-top: 2%;
  margin-#{$rdirection}: 1%;
  width: 71%;
}

.progress_container {
  position: relative;
  overflow: hidden;
  max-width: 81%; /* 410px; */
  margin-#{$rdirection}: 5px;
  height: 8px;
  background-color: #b8b8b8;
  float: #{$ldirection};
  cursor: pointer;
  width: 100%;
}

.progress_container .progress_bar {
  position: absolute;
  width: 0px;
  height: 8px;
  background-color: #81b390;
}

.progress_container .progress_bar .playhead {
  width: 8px;
  height: 8px;
  float: #{$rdirection};
  background-image: url(1.2.30/demos/clinique/images/playhead.png);
  background-repeat: no-repeat;
  position: relative;
  #{$ldirection}: 4px;
}

.progress_container .buffered_bar {
  position: absolute;
  width: 0px;
  height: 8px;
  background-color: green;
}

.progress_container .chapter_marker {
  display: none;
  position: absolute;
  width: 1px;
  height: 8px;
  #{$ldirection}: 0px;
  top: 0px;
  background-color: #fff;
}

.progress_container .chapter_title {
  display: none;
  position: absolute;
  height: 5px;
  #{$ldirection}: 0px;
  top: -18px;
  color: #a8a8a8;
  font-family: Arial;
  font-size: 10px;
}

.progress_time {
  position: relative;
  top: -1px;
  float: #{$ldirection};
  color: #81b390;
  font-family: Arial;
  font-size: 67.3%;
}

@media screen and (min-width: 800px) {
  .progress_time {
    max-width: 18%;
    font-size: 67.3%;
    transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -moz-transition: all 0.1s linear;
    -webkit-transition: all 0.2s linear;
  }
}

@media screen and (max-width: 800px) {
  .progress_time {
    max-width: 20%;
    font-size: 65.3%;
    transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -moz-transition: all 0.1s linear;
    -webkit-transition: all 0.2s linear;
  }
}

.video_player_button {
  background-size: 100% 100%;
  width: 4%;
  height: 17px;
  cursor: pointer;
  float: #{$ldirection};
  margin-top: 2%;
  margin-#{$rdirection}: 1%;
  overflow: hidden;
}

.video_player_button.play {
  background-image: url(1.2.30/demos/clinique/images/play.png);
}

.video_player_button.pause {
  background-image: url(1.2.30/demos/clinique/images/pause.png);
}

.video_player_button.sound {
  background-image: url(1.2.30/demos/clinique/images/sound.png);
}

.video_player_button.mute {
  background-image: url(1.2.30/demos/clinique/images/sound_off.png);
}

.video_player_fullscreen .video_player_button {
  width: 2%;
}

.video_player_button.fullscreen,
.video_player_button.fullscreen_over {
  background-image: url(1.2.30/demos/clinique/images/fullscreen_over.png);
}

.video_player_button.fullscreen.disabled,
.video_player_button.fullscreen.disabled:hover,
.video_player_button.fullscreen_over.disabled {
  background-image: url(1.2.30/demos/clinique/images/fullscreen.png);
  opacity: 0.5;
  cursor: default;
}
/* ---- Volume Bar  ---- */

.volume_container_wrapper {
  position: relative;
  height: 17px;
  background-color: #eee;
  padding-top: 5px;
  padding-#{$ldirection}: 5px;
  float: #{$ldirection};
  margin-top: 2%;
  margin-#{$rdirection}: 5px;
  margin-#{$ldirection}: -5px;
  max-width: 10%;
  width: 100%;
}

.volume_container {
  width: 100%;
  height: 8px;
  background-color: #b8b8b8;
  float: #{$ldirection};
  cursor: pointer;
}

.volume_container .volume_bar {
  width: 0px;
  height: 8px;
  background-color: #81b390;
}
/* ---- Fullscreen  ---- */

.video_player.video_player_fullscreen {
  z-index: 100000;
  position: fixed;
  top: 0px;
  #{$ldirection}: 0px;
  width: 100%;
  height: 100% !important;
  max-width: 100%;
}

.video_player.video_player_fullscreen video {
  top: 0px;
  #{$ldirection}: 0px;
  width: 100%;
  height: 99%;
}

.video_player.video_player_fullscreen .video_player_poster {
  top: 0px;
  position: relative;
  @include swap_direction(margin, 0 auto);
  height: 100%;
  width: 100%;
  z-index: 10;
}

.video_player_controls.video_player_fullscreen {
  position: relative;
  width: 100% !important;
  height: 100% !important;
  z-index: 100001;
}

.video_player_controls_wrapper.video_player_fullscreen {
  width: 100%;
  z-index: 100002;
  position: fixed;
  bottom: 0px;
  #{$ldirection}: 0px;
  margin-top: -10px;
  top: auto;
}

.video_player_controls.video_player_fullscreen .progress_container {
  max-width: 93%;
}
/* ---- Scrollable Video List ---- */

.video_list {
  width: 140px;
  clear: both;
  @include swap_direction(margin, 20px 0 10px 15px);
  float: #{$ldirection};
}

.video_list .item {
  width: 100px;
  height: 80px;
  @include swap_direction(padding, 5px);
  cursor: pointer;
}

.video_list .item .thumbnail {
  width: 100px;
  height: 60px;
}

.video_list .item .title {
  font-family: sans-serif;
  font-size: 11px;
  color: #81b390;
}

.video_list .viewport {
  width: 110px;
  height: 290px;
  overflow: hidden;
  position: relative;
}

.video_list .overview {
  list-style: none;
  position: absolute;
  #{$ldirection}: 0;
  top: 0;
}

.video_list .thumb .end,
.video_list .thumb {
  background-image: url(1.2.30/demos/clinique/images/scrollbar.png);
}

.video_list .scrollbar {
  position: relative;
  float: #{$ldirection};
  width: 10px;
  @include swap_direction(border, 1px solid #eee);
}

.video_list .track {
  background-color: #fff;
  height: 100%;
  width: 10px;
  position: relative;
}

.video_list .thumb {
  height: 16px;
  width: 10px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.video_list .thumb .end {
  overflow: hidden;
  height: 5px;
  width: 13px;
}

.video_list .disable {
  display: none;
}
/* ---- Video Title and Description ---- */

.video_player_titles {
  clear: both;
  font-family: Arial;
  font-size: 11px;
  line-height: 15px;
  width: 570px;
  padding-top: 15px;
}

.video_player_titles .title {
  color: #81b390;
}

.video_player_titles .description {
  color: #000;
  padding-top: 10px;
}

.spinner {
  display: none;
  position: absolute;
  top: 150px;
  #{$ldirection}: 275px;
  z-index: 100001;
  background-repeat: no-repeat;
  background-image: url(1.2.30/demos/clinique/images/spinner.gif);
  width: 16px;
  height: 16px;
}

.preload {
  #{$ldirection}: -1000px;
  width: 0px;
  height: 0px;
  overflow: hidden;
  position: absolute;
}

.preload {
  background: url(1.2.30/demos/clinique/images/playhead.png);
  background: url(1.2.30/demos/clinique/images/spinner.gif);
  background: url(1.2.30/demos/clinique/images/play.png);
  background: url(1.2.30/demos/clinique/images/pause.png);
  background: url(1.2.30/demos/clinique/images/sound.png);
  background: url(1.2.30/demos/clinique/images/sound_off.png);
  background: url(1.2.30/demos/clinique/images/fullscreen.png);
  background: url(1.2.30/demos/clinique/images/fullscreen_over.png);
}
/* safari hack */

video {
  height: auto;
}
@media screen and (max-width: 400px) {
  .video-player-wrapper {
    max-width: 30%;
  }
  .audio,
  canvas,
  video {
    height: auto;
  }
}

@media screen and (min-width: 401px) and (max-width: 800px) {
  .video-player-wrapper {
    max-width: 70%;
  }
  .audio,
  canvas,
  video {
    height: auto;
  }
}

@media screen and (min-width: 801px) and (max-width: 1100px) {
  .video-player-wrapper {
    max-width: 80%;
  }
  .audio,
  canvas,
  video {
    height: auto;
  }
}
