$lookup_border_color: #999;
$lookup_background_color: #d8d8d8;

.profile-info__fieldset {
  .select2-container {
    margin-bottom: 15px;
  }
}

.address-overlay-shipping {
  .form_element {
    margin-top: 15px;
  }
  .country_container {
    label {
      display: block;
    }
  }
}

.ac_list {
  border: 1px solid $lookup_border_color;
  overflow: hidden;
  padding: 0;
  width: 100%;
  z-index: 20;
  ul {
    height: auto;
    list-style: outside none none;
    margin: 0;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    width: 100%;
    li {
      border-top: 1px solid $lookup_border_color;
      cursor: pointer;
      display: block;
      margin: 0;
      overflow: hidden;
      padding: 2px 5px;
      position: relative;
      &:first-child {
        border-top: none;
      }
    }
    .ac_hover {
      background-color: $lookup_background_color;
    }
  }
}

.account-order-history {
  .past-purchases {
    &__item {
      padding: 0;
      .rating {
        margin-left: 48% !important;
      }
      .quantity {
        margin-left: 26% !important;
      }
      .price {
        margin-right: 27% !important;
      }
      .product {
        &__rate {
          word-break: break-word;
          width: 17.11111% !important;
          margin-left: 48% !important;
        }
        &__price {
          margin-left: 65% !important;
        }
      }
    }
  }
}

.order-details {
  .order-details__item {
    .gift_message {
      display: none;
    }
  }
}

.culture-landing__item {
  .culture-landing {
    &__teaser-wrap {
      max-height: initial;
    }
  }
}

@if $password_strengthen {
  .profile-password-update {
    &__rules {
      li {
        width: 50%;
      }
    }
  }
}

@media only(min-width: $width-large) {
  .basic-carousel-item {
    .wrapper {
      .mobile-img {
        display: block;
      }
    }
  }
  .tout-block-landscape {
    img {
      width: 100%;
    }
  }
}
