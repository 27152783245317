//One Trust Implementation
#optanon {
  .vendor-header-container {
    #optanon-popup-more-info-bar {
      @include breakpoint($bp--xsmall) {
        width: 35%;
      }
      label {
        white-space: nowrap;
      }
    }
    .header-3 {
      width: 65%;
    }
  }
}

.optanon-alert-box-wrapper {
  .optanon-alert-box-bg {
    .optanon-alert-box {
      &-body {
        @include breakpoint($bp--medium-up) {
          margin: 0 40px;
        }
      }
      &-button-container {
        @include breakpoint($bp--medium-up) {
          position: relative;
          margin: 5px;
        }
      }
    }
  }
  .optanon-alert-box-bottom-top {
    .optanon-alert-box-corner-close {
      @include breakpoint($bp--medium-up) {
        top: auto;
        bottom: 22px;
      }
    }
  }
}

.cookie-settings-ot {
  margin-bottom: 15px;
  .optanon-show-settings-popup-wrapper {
    height: 35px;
  }
}
