.LPMoverlay:has(.icon-chat--overlay) {
  // Override LP inline style.
  z-index: 996 !important;
}
.icon-chat--footer,
.icon-chat--overlay {
  position: fixed;
  bottom: 10%;
  #{$rdirection}: 20px;
  z-index: 995;
  height: 50px;
  @include breakpoint($bp--large-up) {
    #{$rdirection}: 5%;
  }
  a {
    &.chat--icon {
      padding: 0;
      position: absolute;
      top: 0;
      #{$ldirection}: -20px;
      background: $color--black;
      border-radius: 25px;
      border: 2px solid $color--white;
      width: 50px;
      height: 50px;
      display: flex;
      cursor: pointer;
    }
  }
  .icon--chat {
    width: 23px;
    height: 24px;
    fill: $color--white;
    @include swap_direction(padding, 1px 1px 1px 0);
    margin: 12px;
  }
}
.icon-chat--overlay {
  background: $color--white;
  padding: 0 45px;
  border-radius: 12px;
  box-shadow: 0px 2px 8px $color--black--opacity-12;
  height: 50px;
  padding-top: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  // override inline styles from live person
  top: auto !important;
  #{$ldirection}: auto !important;
  .body-text {
    line-height: get-line-height(16px, 19px);
  }
  a {
    text-transform: none;
    display: flex;
    line-height: get-line-height(16px, 19px);
    border-color: $color--black;
    margin-#{$rdirection}: auto;
    cursor: pointer;
    &.close--icon {
      background: $color--black;
      position: absolute;
      top: 15px;
      #{$rdirection}: 8px;
      padding: 5px;
      border-radius: 15px;
      width: 21px;
      height: 21px;
    }
  }
  .icon--close {
    cursor: pointer;
    width: 11px;
    height: 11px;
    fill: $color--white;
  }
}

.icon-chat--footer {
  width: 50px;
  #{$rdirection}: 21px;
  bottom: calc(10% + 1px);
  margin-top: -1px;
  @include breakpoint($bp--large-up) {
    #{$rdirection}: calc(5% + 1px);
  }
  a {
    &.chat--icon {
      #{$ldirection}: 0;
    }
  }
}
