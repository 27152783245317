/* ==========================================================================
   Components Atoms - Forms, Text-fields
   ========================================================================== */

/* Mixin - Form Item Active
   ========================================================================== */

@mixin form-item--active {
  @include swap_direction(border, 1px solid $color--border-active);
  outline: none;
}

/* Mixin - Text-field Base
   ========================================================================== */

@mixin text-field-base {
  outline: 0;
  @include swap_direction(padding, 21px 12px);
  border-width: 1px;
  border-style: solid;
  border-color: $color--border;
  @include swap_direction(border-radius, 0);
  width: 100%;
  @include single-transition(border-color);
  &:focus {
    @include form-item--active;
  }
}

@mixin form-text--small {
  padding-top: 12px;
  padding-bottom: 12px;
}

/* Placeholder Vendor Prefixes
   ========================================================================== */

::-webkit-input-placeholder {
  text-transform: uppercase;
}

::-moz-placeholder {
  text-transform: uppercase;
}

:-ms-input-placeholder {
  text-transform: uppercase;
}

[type='text'],
[type='number'],
[type='email'],
[type='tel'],
[type='password'],
[type='search'],
textarea {
  @include text-field-base;
}

input[type='search'] {
  // overridding Normalize
  @include box-sizing(border-box);
}

.form-text {
  @include text-field-base;
  &--small {
    @include form-text--small;
  }
}

.form-type-textfield {
  &--required {
    .required {
      color: $color--error;
    }
  }
  &--error {
    label {
      color: $color--error;
    }
    .form-text {
      border-color: $color--error;
      color: $color--error;
      &:focus {
        border-color: $color--error;
      }
    }
  }
}

textarea,
select,
input[type='text'],
input[type='button'],
input[type='email'],
input[type='submit'],
input[type='password'] {
  @include swap_direction(border-radius, 0);
  -webkit-appearance: none;
}

//IE10+ shows a "x" on any focused element with text
input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
