/* ==========================================================================
   Components Organisms - Carousels
   ========================================================================== */

.carousel {
  width: 100%;
}

.carousel-slide {
  //  text-align: left;
  //  width: 240px; // @todo: yeahno, fix this
  //  display: block;
  &__title {
    display: none;
    position: absolute;
    max-width: 120px;
    bottom: 12px;
    // EMEABEX-1675 - RTL support has been disabled intentionaly.
    right: 66px;
    color: $color--white;
    @include breakpoint($bp--medium-up) {
      display: block;
    }
  }
}

.carousel-controls {
  position: relative;
  height: 60px;
  line-height: 60px;
  text-align: center;
  a,
  button {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
  }
}

.carousel-control-prev,
.carousel-control-next {
  @include hide-text;
  @include background(image-url('global/carousel-arrows.png') no-repeat 0 0);
  display: block;
  width: 120px;
  height: 60px;
  position: absolute;
  // EMEABEX-1675 - RTL support has been disabled intentionaly.
  left: 0;
  top: 0;
  &.inactive {
    opacity: 0.4;
  }
}

.carousel-control-next {
  // EMEABEX-1675 - RTL support has been disabled intentionaly.
  left: auto;
  right: 0;
  background-position: -185px 0;
}

/* jCarousel
   ========================================================================== */
/*
 * refactor this (from vendor/jcarousel)
 */

/*
 * This is the visible area of you carousel.
 * Set a width here to define how much items are visible.
 * The width can be either fixed in px or flexible in %.
 * Position must be relative!
 */

.jcarousel {
  position: relative;
  overflow: hidden;
}

/*
 * This is the container of the carousel items.
 * You must ensure that the position is relative or absolute and
 * that the width is big enough to contain all items.
 */

.jcarousel ul {
  width: 20000em;
  position: relative;

  // Optional, required in this case since it's a <ul> element
  list-style: none;
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0);
}

/*
 * These are the item elements. jCarousel works best, if the items
 * have a fixed width and height (but it's not required).
 */

.jcarousel {
  position: relative;
  > ul {
    @include clearfix;
    > li {
      // Required only for block elements like <li>'s
      float: #{$ldirection};
    }
  }
}

/* Slick
   ========================================================================== */
/*
 * Adapted from bower_components/slick.js/slick/slick.scss
 */

// Default Variables
$slick-font-path: './fonts/' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-prev-caret: '\003C' !default;
$slick-next-caret: '\003E' !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return imag-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}

// Slider
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: auto;
  touch-action: auto;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  &:not(.cr21-refresh) & {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
  }
  &:focus {
    outline: none;
  }
  .slick-loading & {
    background: #fff slick-image-url('ajax-loader.gif') center center no-repeat;
  }
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-list,
.slick-track,
.slick-slide,
.slick-slide img {
  @include transform(translate3d(0, 0, 0));
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  zoom: 1;
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: #{$ldirection};
  height: 100%;
  min-height: 1px;
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }
  display: none;
  &.dragging img {
    pointer-events: none;
  }
  .slick-initialized & {
    display: block;
  }
  .slick-loading & {
    visibility: hidden;
  }
  .slick-vertical & {
    display: block;
    height: auto;
    @include swap_direction(border, 1px solid transparent);
  }
}

// Icons
@font-face {
  font-family: 'slick';
  src: slick-font-url('slick.eot');
  src: slick-font-url('slick.eot?#iefix') format('embedded-opentype'), slick-font-url('slick.woff') format('woff'), slick-font-url('slick.ttf') format('truetype'), slick-font-url('slick.svg#slick') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Arrows
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  @include swap_direction(padding, 0);
  @include swap_direction(border, none);
  outline: none;
  z-index: 999;
  &:focus {
    outline: none;
  }
  &.slick-disabled:before {
    opacity: 0.25;
  }
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  color: $slick-arrow-color;
  opacity: 0.85;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  // EMEABEX-1675 - RTL support has been disabled intentionaly.
  left: -30px;
  &:before {
    content: $slick-prev-character;
  }
}

.slick-next {
  // EMEABEX-1675 - RTL support has been disabled intentionaly.
  right: -30px;
  &:before {
    content: $slick-next-character;
  }
}

// Dots
.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: ($spacing / 2);
  // EMEABEX-1675 - RTL support has been disabled intentionaly.
  left: 0;
  right: 0;
  text-align: center;
  color: $color--gray;
  > li {
    display: none;
    .carousel--has-pager & {
      &.slick-active {
        display: block;
      }
    }
    .carousel--has-dots & {
      color: transparent;
      display: inline-block;
      @include swap_direction(margin, 0 6px);
      width: 10px;
      button {
        @include border-radius(10px);
        @include swap_direction(border, 1px solid $color--gray--black);
        height: 10px;
        width: 10px;
        &:before {
          content: '';
          height: 10px;
          line-height: 10px;
          width: 10px;
        }
      }
      &.slick-active,
      &:hover {
        button {
          background-color: $color--gray--black;
        }
      }
    }
  }
}

.device-pc .carousel--has-dots {
  .slick-dots {
    bottom: -35px;
    li {
      width: 10px;
    }
  }
}

.device-mobile .carousel--has-dots {
  .slick-dots {
    bottom: 0;
  }
}

.carousel--has-dots {
  &.carousel-dark-theme {
    .slick-dots {
      > li {
        button {
          border-color: $color--white;
        }
        &.slick-active {
          button {
            background-color: $color--white;
          }
        }
      }
    }
  }
  .carousel-controls {
    display: none;
    margin-top: 40px;
  }
}

/* Mixin - Slider Item Active
   ========================================================================== */
/*
 * Carousel slider used in collections
 */

@mixin slider-item--active--carousel {
  @include pseudo-base();
  @include abs-pos();
  bottom: -20px;
  width: 100%;
  height: 5px;
  background: $color--black;
}

.slider-item--active {
  @include slider-item--active--carousel;
}

.slider-carousel__mask {
  overflow-x: scroll;
  position: relative;
  padding-bottom: 80px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.slider-carousel__scroller {
  width: 6000px; // starting width, overridden by js
}

.slider-carousel__item {
  min-height: 200px;
  float: #{$ldirection};
  position: relative;
  cursor: pointer;
  &:hover {
    @include slider-item--active--carousel;
  }
}

.slider-carousel__controls {
  text-align: center;
}

.slider-carousel-control {
  @include reset-button();
}

.slider-carousel-control--prev {
  // EMEABEX-1675 - RTL support has been disabled intentionaly.
  @include icon('arrow--left-medium', $text-replace: true);
  float: left;
}

.slider-carousel-control--next {
  // EMEABEX-1675 - RTL support has been disabled intentionaly.
  @include icon('arrow--right-medium', $text-replace: true);
  float: right;
}

.carousel {
  .slick-slider {
    margin-bottom: 0;
  }
  .slick-prev {
    // EMEABEX-1675 - RTL support has been disabled intentionaly.
    @include icon(arrow--left-medium);
    left: 15px;
    bottom: ($spacing / 2);
    top: inherit;
    width: 81px;
    @include breakpoint($bp--largest-up) {
      left: 30px;
    }
    &:before {
      font-size: 12px;
      color: $color--gray;
    }
  }
  .slick-next {
    // EMEABEX-1675 - RTL support has been disabled intentionaly.
    @include icon(arrow--right-medium);
    right: 15px;
    bottom: ($spacing / 2);
    top: inherit;
    width: 81px;
    @include breakpoint($bp--largest-up) {
      right: 30px;
    }
    &:before {
      font-size: 12px;
      color: $color--gray;
    }
  }
  .carousel-controls {
    .btn {
      vertical-align: middle;
      &.btn--light {
        border-color: $color--black;
        color: $color--black;
        &:hover {
          border-bottom: 1px solid;
          border-color: $color--black;
        }
      }
    }
    .next-slide,
    .slick-prev:before,
    .slick-next:before {
      color: $color--black;
    }
    .slick-prev,
    .slick-next {
      bottom: auto;
      height: 60px;
      @include swap_direction(margin, 0);
      top: 0;
    }
  }

  // modify bg-img with a carousel setting
  // this removes the blur that can occur with background-size:cover
  &.make--bg-img-contained .bg-img {
    background-size: contain;
  }
}

.carousel.carousel--has-pager {
  ul.slick-slider {
    button.slick-next {
      bottom: 19px;
    }
    button.slick-prev {
      bottom: 19px;
    }
  }
}

// Homepage block replace specific
.geolocation-block-replace__content.has-carousel {
  height: 100%;
}

// EMEABEX-1675 - Slick RTL is disabled due to lack of support
[dir='rtl'] {
  .slick {
    &__carousel {
      direction: ltr;
    }
    &-list {
      direction: ltr;
    }
    &-slide {
      float: left;
      direction: rtl;
    }
    &-list {
      .grid--mpp__item {
        &.slick-slide {
          float: left;
        }
      }
    }
    &-dots {
      direction: ltr;
    }
  }

  .site-container {
    .slick-prev {
      position: absolute;
      // EMEABEX-1675 - RTL support has been disabled intentionaly.
      right: auto;
      left: 15px;
      @include breakpoint($bp--largest-up) {
        left: 30px;
      }
    }
    .slick-next {
      position: absolute;
      // EMEABEX-1675 - RTL support has been disabled intentionaly.
      left: auto;
      right: 15px;
      @include breakpoint($bp--largest-up) {
        right: 30px;
      }
    }
    // BEXMAC-915 - should check what is going on with this.
    // RTL should be supported overall.
    .content-block--slider > {
      .slick-prev {
        left: 0;
      }
      .slick-next {
        right: unset;
        left: 75px;
      }
    }
  }
}
