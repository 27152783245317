body.toolbar-drawer {
  padding-top: 0;
  .profile-page {
    .profile-info__fieldset {
      select {
        margin-bottom: 15px;
      }
    }
  }
}

.address-overlay-shipping {
  .form_element {
    margin-top: 15px;
  }
  .country_container {
    label {
      display: block;
    }
  }
}

#colorbox.address-delete-overlay {
  height: 240px !important;
}

.site-container {
  @include breakpoint($bp--small-down) {
    .account-nav {
      .responsive-container {
        .account-utilities {
          clear: both;
        }
      }
    }
  }
  .account-page {
    .orders-list__table {
      th {
        font-size: 1.2rem;
        padding-right: 5px;
        word-wrap: break-word;
        @include breakpoint($bp--medium-down) {
          @if $fonts_update == true {
            font-size: 0.8em;
          }
        }
      }
      td {
        @include breakpoint($bp--medium-down) {
          @if $fonts_update == true {
            font-size: 12px;
          }
        }
      }
    }
  }
  .account-order-history {
    .past-purchases {
      li.tracking-link {
        overflow: hidden;
        width: 100%;
      }
      .past-purchases__item {
        .order-status,
        .tracking-link {
          .tracking-link-list {
            .order-status,
            .tracking-link {
              margin-left: 0;
            }
          }
        }
        .product-header {
          h6 {
            font-size: 9px;
            &.quantity {
              margin-#{$ldirection}: 29%;
            }
            &.rating {
              margin-#{$ldirection}: 43%;
            }
            &.price {
              margin-#{$ldirection}: 60%;
              width: auto;
              float: $ldirection;
            }
          }
        }
        .product--teaser {
          .product__detail {
            h3 {
              a {
                font-size: 11px;
                word-wrap: break-word;
              }
            }
          }
          .product__qty {
            margin-#{$ldirection}: 28%;
          }
          .product__rate {
            margin-#{$ldirection}: 42%;
          }
          .product__price {
            margin-#{$ldirection}: 60%;
            font-size: 11px;
          }
          .product__add {
            width: 23%;
            .product__add-to-bag {
              width: 100%;
              padding: 5px 0;
              font-size: 10px;
              line-height: 24px;
              height: 34px;
            }
          }
          .product__rating {
            @include breakpoint($bp--small-down) {
              max-width: 125px;
            }
          }
        }
      }
    }
  }
}

.address-form {
  .postal_code_container {
    position: relative;
  }
}