///
// Grid Filter
//
// See molecules/components/grid-filter.mustache
///

// temp
//.page--mpp .header-tout,
//.block-template-site-my-mac-v1,
//.block-template-site-my-shades-v1 {
//  display: none !important;
//}

//.mpp--grid__item {
//  transition: all 1s ease-in-out;
//  -webkit-transition: all 1s ease-in-out;
//  -moz-transition: all 1s ease-in-out;
//  -ms-transition: all 1s ease-in-out;
//  -o-transition: all 1s ease-in-out;
//}

// States
.grid-filter--open {
}

.grid-filter--hidden {
}

.js-product-visibility--hide {
  display: none;
}

.js-product-visibility--show {
}

.grid-filter--open.page--mpp .header-tout {
  display: none;
}

.grid-filter {
  text-transform: uppercase;
  position: relative;
}

.grid-filter__container {
  position: relative;
}

.grid-filter__columns {
  //  padding: $spacing;
  background-color: $color--gray--lightest;
  display: none;
  width: 100%;
  z-index: $z-above-clinks;
  .grid-filter--open & {
    display: table;
    //    margin-top: -60px;
  }
}

.grid-filter__column {
  border-bottom: 1px solid $color--gray;
  @include swap_direction(padding, $spacing);
  &:last-child {
    border-bottom: none;
    border-#{$rdirection}: none;
  }
  @include breakpoint($bp--large-up) {
    border-bottom: none;
    border-#{$rdirection}: 1px solid $color--gray;
    width: 25%;
    display: table-cell;
  }
}

.grid-filter__title {
  @include swap_direction(padding, $spacing);
  @include swap_direction(margin, 0);
}

.grid-filter__facet-item {
  line-height: 32px;
  cursor: pointer;
}

.grid-filter__facet-item--checked {
  @include icon(check, $pseudo: after);
  &:after {
    display: inline-block;
    line-height: inherit;
    float: #{$rdirection};
  }
}

.grid-filter__trigger {
  @include h6();
  background-color: $color--gray--lightest;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  @include swap_direction(margin, 0);
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: middle;
  @include breakpoint($bp--large-up) {
    width: 25%;
    position: absolute;
    top: -61px;
    #{$rdirection}: 0;
    padding-#{$ldirection}: 10px;
    text-align: #{$ldirection};
    z-index: $z-above-prod-sub-nav;
  }
}

.grid-filter__trigger_icon--right {
  bottom: 24px;
  #{$rdirection}: 10px;
  position: absolute;
  .grid-filter--open & {
    display: none;
  }
}

.grid-filter__trigger-text {
  .grid-filter--open & {
    display: none;
  }
}

.grid-filter__trigger_icon--left {
  display: none;
  bottom: 24px;
  #{$rdirection}: 10px;
  position: absolute;
  .grid-filter--open & {
    display: inline;
  }
}

.grid-filter__startover-text {
  display: none;
  .grid-filter--open & {
    display: inline;
  }
}

.grid-filter__facet-item--count-zero {
  color: $color--gray--lighter !important;
  &:before {
    border-color: $color--gray--lighter;
  }
}

// hide the header block when we open filter
.grid-filter__header-block {
  .grid-filter--open & {
    display: none;
  }
}

.grid-filter__results-title {
  @include swap_direction(margin, 0 0 60px 0);
  text-align: center;
}

//
// Styles for MPP Filter template mpp-filter-img-v1
//

// mobile only filter section
$filter-grid-color: #000;
$filter-text-color: #231f20;
$color-text-dimmed: #676767;

.mpp-filter-with-attributes-mobile {
  display: none;
  @include breakpoint($bp--medium-down) {
    display: block;
    padding: 20px;
    margin: 50px 15px 15px 15px;
    background-color: #c8c6c8;
    color: #000;
    text-align: center;
    cursor: pointer;
  }
  @include breakpoint($bp--small-down) {
    margin-bottom: 5px;
  }
  .filter-by-title {
    text-transform: uppercase;
  }
  .filter-label {
    text-transform: uppercase;
    &:after {
      content: '/';
    }
    &:last-child:after {
      content: '';
    }
  }
  .filter-launch-button-mobile {
    font-size: 17px;
    font-family: $ano-bold_regular-font;
  }
}

.mpp-filter-with-attributes {
  position: relative;
  width: 100%;
  z-index: 101;
  margin-top: 30px;
  @include breakpoint($bp--medium-down) {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    margin-top: 0;
    display: none;
    padding: 15px;
    background: #fff;
    color: $filter-grid-color;
    position: fixed !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }
  .filter-popup-buttons-mobile {
    display: none;
    @include breakpoint($bp--medium-down) {
      display: block;
      height: 45px;
    }
    .filter-popup-close-button {
      width: 25px;
      height: 25px;
    }
    .filter-popup-close-button:hover {
      opacity: 1;
    }
    .filter-popup-close-button:before,
    .filter-popup-close-button:after {
      position: absolute;
      left: 20px;
      content: ' ';
      height: 20px;
      width: 1px;
      background-color: #000;
    }
    .filter-popup-close-button:before {
      transform: rotate(45deg);
    }
    .filter-popup-close-button:after {
      transform: rotate(-45deg);
    }
    .filter-popup-label {
      padding-left: 0;
      padding-right: 10px;
      line-height: 30px;
      font-size: 17px;
      font-family: $ano-bold_regular-font;
      font-weight: normal;
      color: $filter-grid-color;
    }
    .filter-popup-clear-all-button {
      text-decoration: underline;
      cursor: pointer;
      padding-top: 10px;
      margin-right: 15px;
      float: right;
      display: none;
    }
    .filter-popup-done-button {
      display: inline-block;
      vertical-align: top;
      padding: 10px 25px;
      line-height: 17px;
      font-size: 17px;
      font-family: $ano-bold_regular-font;
      font-weight: normal;
      letter-spacing: 1px;
      background: $filter-grid-color;
      color: white;
      float: right;
      margin-top: -3px;
      &:hover {
        cursor: pointer;
        background: lighten($filter-grid-color, 10%);
      }
    }
  }
  .grid-filter {
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    //font-family: Helvetica neue, Helvetica, Arial, sans-serif;
  }
  .grid-filter__columns-img {
    @include clearfix();
    margin-bottom: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    @include breakpoint($bp--medium-down) {
      display: block;
    }
  }
  .grid-filter__column {
    position: relative;
    background: $filter-grid-color;
    border: 1px solid $filter-grid-color;
    float: left;
    width: 33%;
    width: -webkit-calc(100% / 4 - 3);
    width: calc(100% / 3 - 3);
    transition-property: all;
    transition-duration: 150ms;
    transition-timing-function: ease-out;
    padding: 0;
    margin-right: 1px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    @include breakpoint($bp--medium-down) {
      & {
        width: 100%;
        float: none;
        margin-bottom: 1px;
        border-width: 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 100%;
        -moz-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
      }
    }
  }
  .grid-filter__column-title {
    z-index: 1;
    position: relative;
    margin: 0;
    padding: 20px;
    line-height: 17px;
    font-size: 17px;
    font-weight: normal;
    color: #fff;
    transition-property: all;
    transition-duration: 150ms;
    transition-timing-function: ease-out;
    @if $fonts_update == false {
      font-family: $ano-bold_regular-font;
    }
    &:before {
      content: '';
      position: absolute;
      right: 20px;
      bottom: 10px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-right-color: inherit;
      border-bottom-color: inherit;
      @include breakpoint($bp--medium-down) {
        border: solid #fff;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 6px;
        bottom: 25px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  .grid-filter_facet-list {
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0);
    transform-origin: top;
    z-index: 2;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    background: white;
    box-shadow: 0 1px $filter-grid-color, -1px 0 $filter-grid-color, 1px 0 $filter-grid-color,
      -1px 1px $filter-grid-color, 1px 1px $filter-grid-color;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-out;
    @include breakpoint($bp--medium-down) {
      & {
        position: relative;
        top: 0;
        left: 0;
        display: none;
        box-shadow: 0 1px $filter-grid-color;
      }
    }
  }
  .grid-filter__facet-item {
    position: relative;
    border-top: 1px solid rgba($filter-grid-color, 0.25);
    padding: 5px 10px 5px 38px;
    font-size: 12px;
    color: $color-text-dimmed;
    line-height: 42px;
    text-transform: uppercase;
    @if $fonts_update == false {
      font-family: $roboto-mono_regular-font;
    }
    & .grid-filter__facet-item-count {
      display: none;
    }
    &.grid-filter__facet-item--count-zero {
      pointer-events: none;
      cursor: default;
    }
    &:before {
      position: absolute;
      content: '';
      top: 16px;
      left: 12px;
      border: 1px solid rgba($filter-grid-color, 0.25);
      border-radius: 4px;
      width: 16px;
      height: 16px;
      line-height: 18px;
      text-align: center;
      transition-property: all;
      transition-duration: 150ms;
      transition-timing-function: ease-out;
    }
    &:after {
      /* Add another block-level blank space */
      content: '';
      display: block;
      position: absolute;
      top: 20px;
      left: 18px;
      visibility: hidden;
      transition-property: all;
      transition-duration: 150ms;
      transition-timing-function: ease-out;
      /* Make it a small rectangle so the border will create an L-shape */
      width: 3px;
      height: 6px;
      /* Add a white border on the bottom and left, creating that 'L' */
      border: solid #000;
      border-width: 0 2px 2px 0;
      /* Rotate the L 45 degrees to turn it into a checkmark */
      transform: rotate(45deg);
    }
    &:hover,
    &.grid-filter__facet-item--checked {
      cursor: pointer;
      color: darken($color-text-dimmed, 20%);
      &:before {
        border: 1px solid $filter-grid-color;
      }
    }
    &.grid-filter__facet-item--checked {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .grid-filter__facet-item--last {
    text-align: right;
    padding: 10px;
    min-height: 0;
    border-top: 0;
    &:before,
    &:after {
      display: none;
    }
  }
  .grid-filter__done-button-wrapper {
    padding: 0 10px 10px;
    text-align: right;
    @include breakpoint($bp--medium-down) {
      display: none;
    }
  }
  .grid-filter__done-button {
    display: inline-block;
    vertical-align: top;
    line-height: 22px;
    letter-spacing: 1px;
    background: $filter-grid-color;
    color: #fff;
    font-size: 17px;
    font-weight: normal;
    @if $fonts_update == true {
      font-family: $ano-black_regular-font;
      padding: 8px 25px 5px 25px;
    } @else {
      font-family: $ano-bold_regular-font;
      padding: 5px 25px;
    }
    &:hover {
      cursor: pointer;
      background: lighten($filter-grid-color, 10%);
    }
  }
  .grid-filter__column:not(.grid-filter__column--active):hover {
    background: lighten($filter-grid-color, 10%);
  }
  .grid-filter__column--active {
    background: #fff;
    @include breakpoint($bp--medium-down) {
      background: #fff;
      color: $filter-grid-color;
    }
    // box-shadow: inset 0 1px $filter-grid-color,
    //             inset -1px 0  $filter-grid-color,
    //             inset 1px 0 $filter-grid-color;

    .grid-filter__column-title {
      color: $filter-grid-color;
      @include breakpoint($bp--medium-down) {
        color: #fff;
        background: $filter-grid-color;
      }
    }
    .grid-filter_facet-list {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
      @include breakpoint($bp--medium-down) {
        & {
          display: block;
        }
      }
    }
  }
  .grid-filter__column--open.grid-filter__column--active {
    .grid-filter__column-title {
      &:before {
        @include breakpoint($bp--medium-down) {
          transform: rotate(227deg);
          -webkit-transform: rotate(227deg);
          bottom: 18px;
        }
      }
    }
  }
  .grid-filter__partial-results-list {
    display: none;
    margin-bottom: -1px;
    background: white;
    padding: 20px 15px 10px 20px;
    box-shadow: 0 1px lighten($filter-grid-color, 70%);
    &:empty {
      display: none;
    }
    .grid-filter__results-item {
      padding-bottom: 10px;
      font-size: 10px;
      color: $color-text-dimmed;
      line-height: 14px;
      text-transform: uppercase;
    }
  }
  @include breakpoint($bp--medium-down) {
    .grid-filter__partial-results-list {
      display: block;
    }
    .grid-filter__column--active .grid-filter__partial-results-list {
      display: none;
    }
    .grid-filter__results-label,
    .grid-filter__results-list {
      display: none;
    }
  }
}

.grid-filter__results {
  display: none;
  padding: 10px 20px;
  line-height: 12px;
  font-size: 12px;
  height: 70px;
  letter-spacing: default;
  color: $filter-text-color;
  font-family: $ano-bold_regular-font;
  width: 100%;
  max-width: 1281px;
  margin: 0 auto -30px;
  @include breakpoint($bp--medium-down) {
    height: 130px;
  }
  position: relative;
  z-index: 1;
  & + div.grid--mpp {
    @include breakpoint($bp--small-down) {
      margin-top: 0px;
    }
  }
}

.grid-filter__results-list,
.grid-filter__results-label,
.grid-filter__results-clear {
  display: inline-block;
  vertical-align: top;
}

.grid-filter__results-label {
  margin-right: 10px;
  letter-spacing: default;
  font-family: $ano-bold_regular-font;
  font-size: 12px;
  font-height: 12px;
  text-transform: uppercase;
  @include breakpoint($bp--medium-down) {
    & {
      display: block;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}

.grid-filter__results-list {
  margin-right: 20px;
  word-wrap: break-word;
  word-break: break-word;
  @include breakpoint($bp--medium-down) {
    & {
      display: block;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}

.grid-filter__results-item {
  display: inline-block;
  vertical-align: top;
  margin-right: 19px;
  white-space: nowrap;
  position: relative;
  font-size: 12px;
  text-transform: uppercase;
  font-family: $roboto-mono_regular-font;
  @include breakpoint($bp--medium-down) {
    padding-right: 5px;
  }
}

.grid-filter__results-item-title {
  display: inline-block;
  vertical-align: top;
  float: left;
  margin-right: 8px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: $roboto-mono_regular-font;
  color: $filter-grid-color;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: lighten($filter-text-color, 20%);
  }
}

.grid-filter__results-item-remove {
  cursor: pointer;
  i.icon-remove {
    position: absolute;
    top: 0;
    width: 20px;
    height: 15px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: $roboto-mono_regular-font;
    vertical-align: top;
    cursor: pointer;
  }
  i.icon-remove:hover {
    opacity: 1;
  }
  i.icon-remove:before,
  i.icon-remove:after {
    position: absolute;
    left: 10px;
    content: ' ';
    height: 12px;
    width: 1px;
    background-color: #000;
    cursor: pointer;
  }
  i.icon-remove:before {
    transform: rotate(45deg);
  }
  i.icon-remove:after {
    transform: rotate(-45deg);
  }
  &:hover {
    cursor: pointer;
    color: lighten($filter-text-color, 20%);
  }
}

.grid-filter__results-clear {
  box-shadow: 0 1px;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: lighten($filter-text-color, 20%);
  }
}

.grid--mpp:not(.grid--mpp__custom-layout) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.js-product-visibility--hide {
  display: block !important;
  height: 0 !important;
  width: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  transition: all 0.5s, opacity 0.9s -0.6s, width 0s 0.2s, height 0s 0.2s, padding 0s 0.4s !important;
  overflow: hidden !important;
  padding: 0 !important;
  height: 0 !important;
}

.js-product-visibility--show {
  display: block !important;
  opacity: 1 !important;
  transition: all 0.5s, opacity 0.6s 0.3s, width 0s -0.1s, height 0s -0.1s, padding 0s -0.3s !important;
}

.grid--mpp:not(.grid--mpp__custom-layout) .grid--mpp__item {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -moz-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  float: none;
}

.js-product-visibility--hide {
  margin-right: -100% !important;
}

@media (min-width: 30.0625em) {
  .grid--mpp:not(.grid--mpp__custom-layout) .grid--mpp__item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .js-product-visibility--hide {
    margin-right: -50% !important;
  }
}

@media (min-width: 40.0625em) {
  .grid--mpp:not(.grid--mpp__custom-layout) .grid--mpp__item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .js-product-visibility--hide {
    margin-right: -33.33333% !important;
  }
}

@media (min-width: 48.0625em) {
  .grid--mpp:not(.grid--mpp__custom-layout) .grid--mpp__item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .js-product-visibility--hide {
    margin-right: -25% !important;
  }
}

div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: 0;
}

.js-filtered-product {
  visibility: hidden;
}
