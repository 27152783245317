.overlay-block {
  display: none;
  max-width: 768px;
  &__image {
    text-align: center;
  }
  &__copy {
    @include swap_direction(padding, 22px 0);
  }
}
