.enhanced-delivery-page {
  .checkout-shipping-panel-section {
    .select-address {
      margin-top: 16px;
    }
  }
  .checkout__content {
    .field-container {
      position: relative;
    }
    .default-shipping {
      .picker-checkbox {
        .picker-handle {
          border-color: $color--light--black;
        }
        .picker-label {
          color: $color--nero--thin;
        }
      }
    }
    select {
      background: none;
      padding: 1px 17px;
    }
    .select-arrow {
      &::before {
        #{$rdirection}: 20px;
        border: 5px solid transparent;
        border-top-color: $color--black--opacity-54;
        content: '';
        pointer-events: none;
        position: absolute;
        top: 22px;
      }
    }
  }
  #shipping-panel {
    .messages-container {
      display: inline-block;
      padding: 0 16px;
      width: 100%;
      @include breakpoint($width-largest) {
        padding: 0;
      }
      .single-message {
        border: 1px solid $color--faux--sandy--brown;
        border-radius: 3px;
        color: $color--faux--sandy--brown;
        margin-bottom: 20px;
        padding: 12px;
        word-break: break-all;
        &:first-child {
          margin-top: 20px;
        }
      }
    }
    .checkout-additional-info {
      padding: 0 17px;
      width: 100%;
      @include breakpoint($width-largest) {
        padding: 0;
      }
    }
    #checkout_shipping_panel {
      .select-address.form-container {
        margin-bottom: 0;
      }
    }
  }
  .select2-container {
    .select2-choice {
      text-decoration: none;
      width: 100%;
    }
    .select2-arrow {
      b {
        &::before {
          bottom: 16px;
          font-size: 19px;
          transform: rotate(0);
        }
      }
    }
  }
  .checkout {
    &__panel-title {
      color: $color--nero--thin;
      font-family: $ano-bold_regular-font;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.25px;
      margin: 0;
    }
    @include breakpoint($width-largest) {
      &__content {
        margin-#{$rdirection}: 0;
        padding: 0 25px 25px;
        width: calc(100% - 380px);
      }
    }
    &.panel {
      background: $color--white;
      margin: 0;
      padding: 0;
      @include breakpoint($width-largest) {
        background-image: linear-gradient(
          to #{$rdirection},
          $color--white 0,
          $color--white calc(100% - 383px),
          $color--gray--lighter calc(100% - 380px),
          $color--gray--thick calc(100% - 380px),
          $color--gray--background 100%
        );
      }
    }
    .accordionPanel {
      i {
        float: $rdirection;
        margin-top: 0;
        position: relative;
        top: 10px;
        width: 24px;
        &::before,
        &::after {
          @include transform(translate(-50%, -50%));
          #{$ldirection}: 50%;
          background: $color--black;
          content: '';
          display: block;
          height: 2px;
          position: absolute;
          top: 50%;
          width: 16px;
        }
        &::after {
          height: 16px;
          width: 2px;
        }
      }
      .active {
        i {
          &::after {
            visibility: hidden;
          }
        }
      }
    }
    .delivery_instructions {
      label {
        &::before {
          line-height: 1;
        }
      }
    }
  }
  .shipping-address-display {
    p {
      margin: 0 0 16px;
    }
  }
  .delivery-address-container {
    border-bottom: 1px solid $color--light--black;
    margin: 20px 0 0;
    padding: 0 !important;
    &:last-child {
      margin-bottom: 0;
    }
    .picker {
      font-size: 12px;
      line-height: 1.7;
      margin: 0 0 20px;
      position: relative;
      .change_link {
        #{$rdirection}: 0;
        background: url('/media/images/checkout/edit_icon.png') no-repeat;
        background-size: 18px;
        border-bottom: 0;
        display: block;
        height: 18px;
        margin-#{$rdirection}: 17px;
        position: absolute;
        text-indent: -9999px;
        width: 20px;
        &.cancel_link {
          background: none;
          color: $color--nero--thin;
          font-family: $roboto-mono_regular-font;
          font-size: 12px;
          text-decoration: underline;
          text-indent: 0;
          text-transform: lowercase;
          width: auto;
        }
      }
      .delivery_address {
        color: $color--nero--thin;
        font-size: 12px;
        text-decoration: none;
        text-transform: capitalize;
        a[href^='tel'] {
          border: 0;
          color: inherit;
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
          text-decoration: none;
        }
        .co_default {
          display: none;
        }
        &.nonreg_user_address {
          .co_default {
            display: none;
          }
        }
      }
    }
    &:first-child {
      .picker {
        .delivery_address {
          .co_default {
            display: block;
            font-family: $ano-bold_regular-font;
            text-transform: uppercase;
            margin-bottom: 3px;
          }
          &.nonreg_user_address {
            .co_default {
              display: none;
            }
          }
        }
      }
    }
    .edit-address-container {
      padding: 0 17px;
      @include breakpoint($width-xlarge) {
        padding: 0;
      }
      .state_container {
        label,
        .valid_marker {
          display: none;
        }
      }
      .edit-shipping-address {
        .info-link-content {
          display: none;
        }
      }
      .default_ship_container {
        &.radio {
          display: flex;
          margin: 0;
          input[type='checkbox'] {
            @include appearance(none);
            border: 0;
            display: block;
            flex: 0 0 20px;
            min-width: 20px;
            position: relative;
            &::before,
            &:checked::after {
              #{$ldirection}: 0;
              content: '';
              height: 18px;
              position: absolute;
              width: 18px;
            }
            &::before {
              border: 1px solid $color--black--opacity-54;
              border-radius: 3px;
              top: 0;
            }
            &:checked {
              &::after {
                #{$ldirection}: 1px;
                background: url('/media/dev/icons/src/check.svg') no-repeat center/92%;
                top: 1px;
              }
            }
          }
          label {
            color: $color--black;
            flex: 1 1 100%;
            font-size: 12px;
            line-height: 1.7;
            margin-#{$ldirection}: 15px;
            text-transform: uppercase;
          }
        }
        .info-link-content {
          margin: 25px 0 35px;
          padding-#{$ldirection}: 0;
        }
      }
      .field-container {
        max-width: 562px;
      }
    }
  }
  &.checkout {
    &.adpl_enabled {
      .adpl {
        .country-id.select {
          height: 54px;
        }
        select.field,
        .field[type='text'],
        .field[type='tel'] {
          background-color: $color--white;
          border-color: $color--gray--dusty--disable;
          height: 54px;
          padding: 17px 15px;
          @include breakpoint($width-largest) {
            @include swap_direction(padding, 16px 20px 14px 15px);
            border-color: $color--gray--lighter;
            color: $color--black;
          }
          &.error {
            border-color: $color--error--red;
          }
        }
        .field[type='text'],
        .field[type='tel'] {
          & + label {
            color: $color--nero--thin;
            font-size: 10px;
            line-height: 1;
            &::before {
              font-size: 14px;
              margin-top: 24px;
              transform: none;
            }
            @include breakpoint($width-xlarge) {
              color: $color--gray-warning;
            }
          }
          &:focus + label,
          &.adaptive-label-mode + label,
          &:required:valid[value=''] + label,
          &:required:valid:empty + label {
            &::before {
              @include transform(translateY(-25px) scale(0.8, 0.8));
            }
          }
        }
      }
      #offer_code {
        .form--offer_code_text[type='text'] {
          &.touched + label,
          &:focus + label,
          &.adaptive-label-mode + label {
            &::before {
              @include transform(translateY(-23px) translateY(-0.5px) scale(0.8, 0.8));
              background: $color--white;
            }
          }
        }
      }
    }
    .corporate-invoice {
      margin-bottom: 67px;
      @include breakpoint($width-large) {
        margin-bottom: 60px;
      }
      .corporate-vat-lookup {
        height: 48px;
        line-height: normal;
        margin-bottom: 8px;
        width: 100%;
        @include breakpoint($width-large) {
          float: $rdirection;
          width: 348px;
        }
      }
      .picker {
        &-checkbox {
          margin: 0;
          padding: 0 17px;
          @include breakpoint($width-large) {
            padding: 0;
          }
        }
        &-flag {
          &:before {
            bottom: 0;
            font-size: 17px;
            position: absolute;
          }
        }
        &-label {
          color: $color--nero--thin;
        }
        &-handle {
          border-color: $color--light--black;
          border-radius: 2px;
          height: 18px;
          margin-#{$rdirection}: 10px;
          position: relative;
          width: 18px;
        }
      }
      .corporate_options {
        margin: 0 auto;
        max-width: 562px;
        padding: 0 16px;
        @include breakpoint($width-xlarge) {
          padding: 0;
        }
        .corporate_invoice_under_checkbox_text {
          margin-top: 5px;
          margin-bottom: 15px;
          color: rgb(255, 0, 0);
        }
        .form-item {
          margin-bottom: 22px;
          &--disabled {
            pointer-events: none;
            .field {
              background-color: $color--gray--thick;
              &::placeholder {
                color: $color--black;
              }
            }
          }
          &:not(.form-item--disabled) {
            .field::placeholder {
              font-size: 14px;
            }
          }
        }
      }
    }
    .shipping-panel {
      &-form-section {
        padding: 0;
      }
      &__title {
        display: none;
      }
    }
    .legal_frame {
      margin-bottom: 18px;
    }
    #continue-btn {
      #{$ldirection}: 0;
      bottom: 0;
      padding: 9px;
      position: fixed;
      text-align: $rdirection;
      width: 100%;
      z-index: 2;
      @include breakpoint($width-large) {
        @include swap_direction(padding, 40px 0 9px 9px);
        position: relative;
      }
      .btn {
        background-color: $color--black;
        color: $color--white;
        cursor: pointer;
        font-size: 16px;
        height: auto;
        line-height: 1.5;
        margin: 0;
        padding: 13px 0 11px;
        text-decoration: none;
        width: 100%;
        @include breakpoint($width-large) {
          height: 48px;
          line-height: normal;
          padding: 12px 18px;
          width: 348px;
        }
        &.invalid {
          background-color: $color--gray--tier2;
          color: $color--black--opacity-4--checkout;
          pointer-events: none;
        }
      }
    }
    .checkout-header {
      border-bottom: 0;
      &__title {
        font-size: 36px;
        line-height: 1.1;
        margin: 36px 0 24px;
        padding: 0 16px;
        @include breakpoint($width-xlarge) {
          line-height: 0.9;
          margin-top: 42px;
          padding: 0;
        }
      }
    }
    .guarantee-panel {
      background-color: $color--white;
      border-bottom: 1px solid;
      border-top: 1px solid;
      color: $color--black;
      margin: 30px auto 10px;
      max-width: 562px;
      padding: 12px 16px;
      .content {
        padding-top: 18px;
        p {
          margin-bottom: 5px;
        }
      }
    }
    &.active-panel-shipping {
      .checkout {
        &-header {
          display: block;
        }
        &__content {
          .select-address {
            .delivery-address-container .SELECTED_ADDRESS_ID,
            .address-to-use .picker-element {
              @include appearance(none);
              @include swap_direction(margin, 0 12px 12px 16px);
              border: 1px solid;
              border-radius: 50%;
              cursor: pointer;
              float: $ldirection;
              height: 20px;
              opacity: 1;
              position: relative;
              width: 20px;
              z-index: 1;
              &:checked {
                &::before {
                  #{$ldirection}: 2px;
                  background: $color--black;
                  border-radius: 50%;
                  content: '';
                  display: block;
                  height: 14px;
                  margin: 0 auto;
                  position: absolute;
                  top: 2px;
                  width: 14px;
                }
              }
            }
            .picker-radio.picker .picker-handle,
            .picker-radio.picker.checked .picker-handle {
              display: none;
              .picker-flag {
                border-radius: 50%;
                border-width: 1px;
                display: none;
                height: 14px;
                margin: 2px;
                width: 14px;
              }
            }
            .address-to-use {
              margin-bottom: 30px;
              padding: 6px 0 0;
              .ship_group_1 {
                @include display-flex;
                border-bottom: 1px solid $color--light--black;
                padding: 0 0 3px;
                .picker {
                  .picker-label {
                    color: $color--nero--thin;
                    font-size: 12px;
                  }
                }
                .picker-element {
                  @include swap_direction(margin, 15px 12px 15px 16px);
                  @include breakpoint($width-largest) {
                    margin-top: 11px;
                  }
                }
                &:first-child {
                  display: none;
                }
                .picker-radio {
                  margin-top: 15px;
                  @include breakpoint($width-largest) {
                    margin-top: 11px;
                  }
                }
              }
              &.new-form-expand {
                .ship_group_1 {
                  border-bottom: 0;
                }
                & + .new-address-container {
                  border-bottom: 1px solid $color--light--black;
                }
              }
            }
            .set-pickup-point {
              .select-pickup-point {
                .select2-container {
                  margin-bottom: 22px;
                  width: 100% !important;
                }
              }
            }
            .ship_type,
            .ship_type_container {
              .picker-radio.picker {
                margin-#{$ldirection}: 0;
              }
              .picker-radio.picker,
              .picker-radio.picker.checked {
                .picker-handle {
                  display: block;
                }
                &.checked {
                  .picker-handle {
                    .picker-flag {
                      display: block;
                    }
                  }
                }
              }
            }
            #continue-btn {
              background: $color--black;
              border-radius: 0;
              color: $color--white;
              display: block;
              float: none;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              height: 48px;
              line-height: 1;
              margin: 21px auto;
              padding: 0;
              position: relative;
              text-decoration: none;
              text-align: center;
              text-transform: uppercase;
              width: 100%;
              @include breakpoint($width-largest) {
                @include swap_direction(margin, 20px 0 25px auto);
                margin-#{$ldirection}: auto;
                padding: 10px;
                width: 348px;
              }
            }
          }
        }
        &__sidebar {
          #offer-code-panel,
          #order-summary-panel,
          #shipmethod-panel,
          #shopping-bag-wrapper-panel,
          #viewcart-panel,
          #viewcart-shopping-bag-panel,
          .continue-checkout-bottom {
            display: block;
          }
          .shipping-address-content-panel,
          .billing-address-panel,
          .continue-payment-button,
          .shipmethod-panel {
            display: none;
          }
        }
      }
      .saved-billing-address {
        padding: 15px 0;
        position: relative;
        header.checkout-header {
          display: block;
          padding: 0;
        }
        .address-content {
          border-bottom: 1px solid $color--light--black;
          border-top: 1px solid $color--light--black;
          color: $color--nero--thin;
          font-size: 12px;
          line-height: 1.7;
          margin-top: 16px;
          padding: 20px 14px;
          text-transform: capitalize;
          @include breakpoint($width-xlarge) {
            padding: 16px;
          }
          a[href^='tel'] {
            border: 0;
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            text-decoration: none;
          }
        }
        &.bill-form-expand {
          .address-content {
            border-bottom: 0;
          }
          & + input[name='PROCESS_BILLING_FORM'] + .billing-address-form-fields {
            border-bottom: 1px solid $color--light--black;
            margin-bottom: 15px;
            header.checkout-header {
              display: none;
            }
          }
        }
        #billing-panel-edit {
          #{$rdirection}: -3px;
          background: url('/media/images/checkout/edit_icon.png') no-repeat;
          background-size: contain;
          border-bottom: 0;
          display: block;
          height: 18px;
          min-width: 20px;
          outline: 0;
          position: absolute;
          text-indent: -9999px;
          top: 75px;
          width: 20px;
          @include breakpoint($width-xlarge) {
            #{$rdirection}: -6px;
          }
          &.cancel_link {
            #{$rdirection}: 0;
            background: none;
            color: $color--nero--thin;
            font-family: $roboto-mono_regular-font;
            font-size: 12px;
            text-decoration: underline;
            text-indent: 0;
            text-transform: lowercase;
            top: 50px;
            width: auto;
            @include breakpoint($width-xlarge) {
              top: 46px;
            }
          }
        }
      }
      .billing-address-container {
        .billing-address {
          .co_default {
            display: none;
          }
        }
      }
      .billing-address-container,
      .address-to-use {
        .picker-radio {
          .picker-handle {
            appearance: none;
            border: 1px solid;
            border-radius: 50%;
            cursor: pointer;
            float: $ldirection;
            height: 20px;
            margin: 0 12px 12px;
            opacity: 1;
            position: relative;
            width: 20px;
            z-index: 1;
          }
          &.checked .picker-handle {
            .picker-flag {
              #{$ldirection}: 0;
              background: $black;
              border-radius: 50%;
              content: '';
              display: block;
              height: 18px;
              margin: 0 auto;
              position: absolute;
              top: 0;
              width: 18px;
            }
          }
          .picker-label {
            color: $color--nero--thin;
          }
        }
      }
      .info-container.info-link-content {
        @include swap_direction(margin, 0 0 62px 17px);
        padding-#{$ldirection}: 0;
        @include breakpoint($width-xlarge) {
          margin-#{$ldirection}: 0;
        }
        a.info-link {
          float: $ldirection;
          margin-bottom: 0;
          padding-top: 1px;
        }
      }
      .edit-shipping-address {
        .info-link-content {
          display: none;
        }
      }
    }
    .review-panel.panel {
      display: none;
    }
    &:not([class*='active-panel-signin']) {
      #sign-in-panel {
        border-bottom: 0;
        display: block;
        margin: 0 auto;
        max-width: 562px;
        padding: 0 16px;
        @include breakpoint($width-xlarge) {
          padding: 0;
        }
        .signin-header {
          display: none;
        }
        .content {
          background: $color--gray--white;
          border-top: 1px solid;
          color: $color--black;
          font-size: 14px;
          font-style: normal;
          font-weight: normal;
          line-height: 1.7;
          padding: 15px 12px;
        }
      }
      .shipping-panel,
      .review-panel,
      .guarantee-panel,
      .sign-in-panel.panel,
      .checkout-header {
        visibility: hidden;
      }
      .info-link {
        @include swap_direction(padding, 1px 0 0 30px);
        border: 0;
        font-size: 12px;
        position: relative;
        text-decoration: underline;
        width: 100%;
        &::before {
          #{$ldirection}: 0;
          background: url('/media/images/checkout/privacy-policy-info-icon.png') no-repeat 1px 0;
          background-size: 92%;
          bottom: 18px;
          content: '';
          display: block;
          height: 20px;
          opacity: 1;
          position: absolute;
          top: 0;
          width: 20px;
          @include breakpoint($width-xlarge) {
            bottom: 2px;
          }
        }
      }
      .column.checkout__sidebar {
        #continue-btn {
          .btn {
            visibility: hidden;
          }
        }
      }
      &.active-panel-shipping {
        .shipping-panel,
        .review-panel,
        .guarantee-panel,
        .sign-in-panel,
        .checkout-header {
          visibility: visible;
        }
        .checkout__sidebar.right {
          #continue-btn {
            .btn.continue-checkout-bottom {
              margin: 24px 0;
              visibility: visible;
            }
          }
        }
      }
    }
    #shipping-panel {
      border-bottom: 0;
      margin: 0 auto;
      max-width: 562px;
      padding: 0;
      &.shipping-panel {
        .checkout-header {
          margin: 0 17px;
          text-align: $ldirection;
          @include breakpoint($width-largest) {
            margin: 0;
          }
        }
      }
      header {
        a {
          #{$ldirection}: 90%;
          background: url('/media/images/checkout/edit_icon.png') no-repeat;
          background-size: 18px;
          border-bottom: 0;
          display: block;
          height: 18px;
          position: relative;
          top: 40px;
        }
      }
      &.panel {
        .valid_marker {
          #{$ldirection}: auto;
          #{$rdirection}: 18px;
          top: 5px;
        }
        .field-container--grid,
        .field-container {
          .form-item {
            .invalid_marker {
              #{$rdirection}: 15px;
              background: url('/media/images/checkout/icon-error.png') no-repeat 1px 4px $color--white;
              background-size: 100%;
              height: 30px;
              margin-#{$rdirection}: 0;
              text-indent: -9999px;
              top: 8px;
              width: 30px;
            }
          }
        }
        .name-fields {
          .form-item {
            float: none;
            margin: 0 0 22px;
            width: 100%;
            &.first-name,
            &.last-name {
              float: none !important;
              margin-#{$rdirection}: 0;
              width: 100% !important;
            }
          }
        }
        .new-address-container {
          margin: 16px 0 30px;
          padding: 0 17px;
          @include breakpoint($width-largest) {
            margin-top: 25px;
            padding: 0;
          }
          .packstation-address1 {
            background: $color--gray--thick;
            border: 1px solid $color--gray--dusty--disable;
            @include breakpoint($width-largest) {
              border-color: $color--gray--lighter;
            }
          }
          .state-country,
          .country {
            @include swap_direction(padding, 16px 20px 14px 15px);
            background: $color--gray--thick;
            border: 1px solid $color--gray--dusty--disable;
            height: 54px;
            margin-bottom: 22px;
            @include breakpoint($width-largest) {
              padding-#{$ldirection}: 12px;
              border-color: $color--gray--lighter;
            }
            .form-item {
              &.select,
              &.state {
                margin-bottom: 0;
                label {
                  .label-content {
                    display: block;
                    font-size: 14px;
                    line-height: 1.7;
                    text-transform: capitalize;
                  }
                }
              }
            }
            &.country_container {
              display: none;
            }
          }
          .address6 {
            .description {
              margin-bottom: 22px;
            }
          }
          .bill-to-shipping {
            margin: 0 0 52px;
            .picker-checkbox {
              .picker-handle {
                border-color: $color--light--black;
                border-radius: 2px;
                height: 18px;
                margin-#{$rdirection}: 10px;
                width: 18px;
                .picker-flag {
                  &::before {
                    bottom: 2px;
                    font-size: 17px;
                    position: relative;
                  }
                }
              }
              .picker-label {
                color: $color--nero--thin;
                width: auto;
              }
            }
          }
          .default-shipping {
            .picker-checkbox.picker {
              .picker-flag {
                &::before {
                  bottom: 0;
                  font-size: 17px;
                  position: absolute;
                }
              }
              .picker-handle {
                border-radius: 2px;
                height: 18px;
                margin-#{$rdirection}: 10px;
                position: relative;
                width: 18px;
              }
              label.label-content {
                color: $color--nero--thin;
              }
            }
          }
        }
        .checkout {
          &__subtitle {
            font-family: $ano-bold_regular-font;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.25px;
            margin-top: 0;
          }
          &-shipping-panel-section {
            padding: 0;
            .checkout__subtitle {
              font-family: $ano-bold_regular-font;
              font-weight: 400;
              letter-spacing: 0.25px;
              padding-#{$ldirection}: 16px;
              @include breakpoint($width-largest) {
                padding-#{$ldirection}: 0;
              }
            }
            .required-text {
              display: none;
            }
          }
          .shipping-panel-form-section {
            padding: 0 17px;
            @include breakpoint($width-largest) {
              padding: 0;
            }
            .checkout {
              &__subtitle {
                line-height: 1;
                margin: 0;
              }
            }
            .required-text {
              display: none;
            }
          }
        }
      }
    }
    .messages-container {
      [class*='error_offer_criteria_met'] {
        display: none;
      }
    }
    .address-overlay {
      &-billing,
      &-shipping {
        h2 {
          font-size: 22px;
          line-height: 1.2;
        }
      }
    }
  }
  .content.adpl {
    margin: 0;
    position: relative;
    width: 100%;
  }
  .select-menu {
    border-top: 1px solid $color--light--black;
    margin-bottom: 0;
  }
}
