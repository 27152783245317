.enhanced-delivery-page {
  &.checkout {
    &.active-panel-review {
      .checkout-header {
        &.column-header {
          display: none;
        }
      }
      .billing-address-panel {
        .checkout-header {
          display: block;
        }
      }
      .checkout {
        &__content {
          .sign-in-panel {
            display: none;
          }
        }
        &.panel {
          @include breakpoint($width-largest) {
            background-color: $color--white;
          }
        }
        &__content,
        &__sidebar {
          margin-top: 16px;
          .continue-checkout-bottom {
            display: none;
          }
          .continue-payment-button {
            display: block;
          }
        }
        .checkout__content {
          #shipping-panel,
          .review-panel .content:first-child {
            display: none;
          }
          .select-address {
            .picker-radio.picker.checked {
              .picker-flag {
                border-radius: 7px;
                border-width: 1px;
                margin: 2px;
              }
            }
          }
        }
      }
      .review-panel.panel {
        border: 0;
        display: block;
        margin: 0 auto;
        max-width: 100%;
        padding: 0;
        width: 100%;
        @include breakpoint($width-xlarge) {
          max-width: 562px;
        }
        .single-message {
          border: 1px solid $color--faux--sandy--brown;
          border-radius: 3px;
          color: $color--faux--sandy--brown;
          margin-bottom: 20px;
          padding: 12px;
          word-break: break-all;
        }
        .checkout {
          &__title {
            font-size: 36px;
            margin-bottom: 16px;
            text-align: center;
            text-transform: uppercase;
          }
          &__subtitle {
            font-size: 21px;
            text-align: center;
          }
        }
        header {
          .checkout {
            &__panel-title {
              display: none;
            }
          }
        }
        .payment-instruction {
          border: 1px solid $color--gray--dusty--disable;
          margin-bottom: 24px;
          padding: 13px 14px;
        }
        .payment-description {
          border: 1px solid $color--gray--lighter;
          padding: 10px 20px;
        }
        .payment-type {
          margin-top: 61px;
          @include breakpoint($width-xlarge) {
            margin-top: 71px;
          }
          .payment-type {
            margin-top: 0;
          }
          h3.cc-header {
            border-top: 1px solid $color--gray--dusty;
            font-size: 12px;
            letter-spacing: 0.25px;
            line-height: 1.7;
            margin: 0;
            padding: 17px 52px 16px;
          }
          .picker-radio {
            border-top: 1px solid $color--mercury;
            float: $ldirection;
            width: 48%;
            &-radio {
              @include swap_direction(padding, 4px 6px 0 15px);
            }
            &-handle {
              float: $ldirection;
              margin-top: 15px;
              width: 20px;
            }
            &-label {
              float: $ldirection;
              height: auto;
              vertical-align: middle;
              width: 84%;
              .text-label {
                float: $ldirection;
                font-size: 12px;
                margin-top: 2px;
              }
              .img_section {
                float: $rdirection;
                width: 46px;
              }
            }
          }
          .text-select-container {
            width: 100%;
            .text-container {
              width: 60%;
              display: inline-block;
              margin-top: 1px;
              input {
                width: 100%;
                height: 57px;
                padding-left: 2rem;
              }
            }
            .select-container {
              width: 35%;
              display: inline-block;
              margin-left: 0;
            }
          }
          .payment-option-payu-txt {
            color: $color--black--opacity-28;
            margin-#{$ldirection}: 0;
          }
          .payment-type-cc {
            margin-top: 0;
            .picker {
              &-radio {
                .img_section {
                  float: $rdirection;
                  margin-top: -12px;
                }
              }
            }
          }
          .two-column {
            border-#{$ldirection}: 1px solid $color--gray--tier5;
            float: $ldirection;
            margin-top: 1px;
            width: 50%;
            &.odd-column {
              border-#{$ldirection}: 0;
            }
            .picker {
              &-radio {
                @include swap_direction(padding, 4px 6px 0 15px);
                @include breakpoint($width-xlarge) {
                  padding: 4px 15px 0;
                }
              }
              .picker {
                &-handle {
                  float: $ldirection;
                  margin-top: 0;
                  width: 20px;
                }
                &-label {
                  float: $ldirection;
                  height: auto;
                  vertical-align: middle;
                  width: 84%;
                  .text-label {
                    float: $ldirection;
                    margin-top: 0;
                  }
                  .img_section {
                    float: $rdirection;
                    width: 46px;
                  }
                }
              }
            }
          }
        }
        .checkoutdotcom-container {
          #checkout_payment {
            .checkoutdotcom-field {
              margin-bottom: 6px;
            }
          }
        }
        .view-address {
          a {
            #{$ldirection}: 90%;
            background: url('/media/images/checkout/edit_icon.png') no-repeat;
            background-size: 18px;
            border-bottom: 0;
            display: block;
            height: 18px;
            position: relative;
          }
        }
        #checkout_saved_payment {
          padding: 10px 17px;
        }
        #checkout_complete {
          .picker-element {
            &.field {
              visibility: hidden;
            }
          }
          .picker-checkbox {
            border-top: 1px solid $color--mercury;
            display: inline-flex;
            margin: 0;
            padding: 18px 16px;
            width: 100%;
            .picker-handle {
              @include swap_direction(margin, 0 16px 0 0);
              .picker-flag {
                &::before {
                  bottom: 1px;
                  position: relative;
                }
              }
            }
            .label-content {
              color: $color--nero--thin;
            }
          }
          .checkout__title {
            font-size: 36px;
            margin-bottom: 16px;
            text-align: center;
            text-transform: uppercase;
          }
          .order-terms {
            .picker-checkbox {
              border-top: 0;
              padding-top: 0;
              .picker-handle {
                display: none;
              }
              .picker-label {
                padding-top: 16px;
                width: 100%;
              }
            }
          }
          .sub-section {
            padding: 0 16px;
            .picker-radio {
              &.picker.checked {
                .picker-handle {
                  .picker-flag {
                    border-radius: 50%;
                    height: 18px;
                    margin: 0;
                    width: 18px;
                  }
                }
              }
            }
          }
          .payment-type {
            div.picker-radio {
              margin: 0;
              padding: 17px 16px 16px;
              width: 100%;
              .picker-handle {
                @include swap_direction(margin, 0 16px 0 0);
              }
              #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_sofort_label {
                .img_section {
                  margin-top: -2px;
                }
              }
              &.picker.checked {
                .picker-handle {
                  .picker-flag {
                    border-radius: 50%;
                    height: 18px;
                    margin: 0;
                    width: 18px;
                  }
                }
              }
              .picker-label {
                color: $color--black--opacity-28;
                height: 0;
                width: 89%;
                @include breakpoint($width-xlarge) {
                  width: 93%;
                }
                .cc-logos {
                  img.image_section_cc {
                    bottom: 0;
                    margin-#{$ldirection}: 6px;
                    width: 37px;
                    &:last-child {
                      margin-#{$ldirection}: 0;
                    }
                  }
                }
                img {
                  float: $rdirection;
                  height: auto;
                  margin-top: -1px;
                  width: 37px;
                  @include breakpoint($width-xlarge) {
                    margin-top: -2px;
                  }
                }
              }
            }
            &.two-column {
              div.picker {
                &-radio {
                  padding-#{$rdirection}: 10px;
                  .picker-label {
                    font-size: 10px;
                    width: 83%;
                    @include breakpoint($width-xlarge) {
                      width: 84%;
                    }
                  }
                }
                &-handle {
                  margin-#{$rdirection}: 6px;
                }
                &-label {
                  width: 83%;
                  @include breakpoint($width-xlarge) {
                    width: 84%;
                  }
                  .img_section {
                    margin-top: -12px;
                  }
                }
              }
            }
          }
          &.cc-enable {
            .payment-type {
              .picker {
                &-radio {
                  border-top: 1px solid $color--gray--dusty;
                }
              }
              &.payment-type-cc {
                .picker {
                  &-radio {
                    border-top: 1px solid $color--mercury;
                  }
                }
              }
            }
            .picker-checkbox {
              border-top: 1px solid $color--gray--dusty;
            }
          }
        }
      }
      #checkout_review {
        .divide {
          margin-top: 30px;
          .checkout__subtitle {
            text-align: $ldirection;
          }
        }
      }
      .checkoutdotcom-container {
        .checkoutdotcom-field {
          border-color: $color--mercury;
        }
      }
    }
    &:not([class*='active-panel-signin']) {
      .edit-shipping-address {
        #form--errors--address {
          display: inline-block;
          width: 100%;
          li {
            border: 1px solid $color--faux--sandy--brown;
            border-radius: 3px;
            color: $color--faux--sandy--brown;
            margin-bottom: 20px;
            padding: 12px;
          }
        }
        .info-link {
          &::before {
            bottom: 0;
          }
        }
        .country {
          p {
            @include swap_direction(padding, 16px 20px 14px 15px);
            background: $color--gray--thick;
            border: 1px solid $color--gray--dusty--disable;
            font-size: 14px;
            height: 54px;
            line-height: 1.7;
            margin-bottom: 22px;
            text-transform: capitalize;
            @include breakpoint($width-largest) {
              border-color: $color--gray--lighter;
            }
          }
        }
        .delivery_instructions {
          label {
            display: block;
          }
        }
        #continue-btn {
          display: block;
          font-size: 16px;
          height: 48px;
          line-height: 1;
          margin-#{$ldirection}: auto;
          padding: 10px;
          text-align: center;
          width: 348px;
        }
      }
      &.active-panel-review {
        .guarantee-panel {
          display: none;
        }
        #sign-in-panel {
          .content {
            display: none;
          }
        }
        .shipping-panel,
        .review-panel,
        .guarantee-panel,
        .sign-in-panel,
        .checkout-header {
          visibility: visible;
        }
        .checkout__sidebar.right {
          #continue-btn {
            .btn.continue-payment-button {
              visibility: visible;
            }
          }
        }
        .checkout__content {
          .checkout-header {
            display: none;
          }
        }
      }
    }
    &.adpl_enabled {
      .adpl {
        .country-id.select {
          height: 54px;
        }
        select.field {
          height: 54px;
        }
        select.field,
        textarea.field {
          background-color: $color--white;
          border-color: $color--gray--dusty--disable;
          padding: 17px 15px;
          @include breakpoint($width-largest) {
            @include swap_direction(padding, 16px 20px 14px 15px);
            border-color: $color--gray--lighter;
            color: $color--black;
          }
        }
        textarea.field {
          height: 94px;
          & + label {
            #{$ldirection}: 0;
            color: $color--nero--thin;
            font-size: 10px;
            height: 48px;
            line-height: 1;
            margin-top: 0;
            max-width: 100%;
            padding: 0;
            position: absolute;
            top: 14px;
            &::before {
              font-size: 14px;
              white-space: normal;
            }
            @include breakpoint($width-xlarge) {
              color: $color--gray-warning;
            }
          }
          &:focus + label,
          &.adaptive-label-mode + label {
            &::before {
              @include transform(translateY(-21px) scale(0.8, 0.8));
              font-size: 14px;
            }
          }
        }
      }
      #offer_code {
        .form--offer_code_text[type='text'] {
          &.touched + label,
          &:focus + label {
            &::before {
              @include transform(translateY(-23px) translateY(-0.5px) scale(0.8, 0.8));
              background: $color--white;
            }
          }
        }
      }
    }
    .panel {
      &#shipping-panel {
        .field-container {
          .form-item {
            &.address2,
            &.company-name,
            &.form-item-container,
            &.select {
              margin-bottom: 22px;
            }
            &.address4 {
              float: none;
              margin-bottom: 0;
              margin-#{$rdirection}: 0;
              padding: 0;
              width: 100%;
              &-container {
                margin-bottom: 22px;
              }
            }
            &.state {
              margin-bottom: 0;
            }
            &.select-state-container {
              margin-bottom: 22px;
            }
            &.state-select {
              float: $rdirection;
              .valid_marker,
              .invalid_marker {
                display: none;
              }
            }
            &.delivery_instructions {
              margin-bottom: 5px;
              .field {
                height: 94px;
              }
            }
            .select2-chosen {
              margin-#{$ldirection}: 14px;
            }
          }
          &--grid {
            .form-item {
              &:nth-child(2n + 1),
              &:nth-child(2n + 2) {
                float: none;
                margin-bottom: 22px;
                margin-#{$rdirection}: 0;
                padding-#{$rdirection}: 0;
                width: 100%;
                .invalid_marker {
                  & + .field:required {
                    &:invalid {
                      border-color: $color--error--red;
                      &:empty,
                      &:not(:checked) {
                        border-color: $color--error--red;
                        &.error,
                        &.checkedEmpty,
                        &.touched {
                          border-color: $color--error--red;
                        }
                        & + label {
                          &::before {
                            color: $color--error--red;
                          }
                        }
                        &:focus + label {
                          &::before {
                            color: inherit;
                          }
                        }
                      }
                    }
                  }
                }
              }
              select {
                border-color: $color--gray--dusty--disable;
                padding: 18px 12px 19px;
                @include breakpoint($width-largest) {
                  border-color: $color--gray--lighter;
                }
              }
              input[type='text'],
              input[type='tel'] {
                height: 54px;
                padding: 18px 12px 19px;
              }
              &.city,
              &.postal-code {
                float: none;
                margin-#{$rdirection}: 0;
                width: 100%;
              }
              &.country_container {
                @include swap_direction(padding, 16px 20px 14px 15px);
                background: $color--gray--thick;
                border: 1px solid $color--gray--dusty--disable;
                margin-bottom: 22px;
                @include breakpoint($width-largest) {
                  border-color: $color--gray--lighter;
                }
              }
            }
            .select2-container {
              @include breakpoint($width-largest) {
                color: $color--black;
                padding: 0;
                width: 100%;
                .select2-choice {
                  color: $color--gray--dusty--disable;
                  height: 54px;
                  text-decoration: none;
                  .select2-chosen {
                    margin-#{$ldirection}: 14px;
                  }
                  .select2-arrow {
                    display: block;
                    b {
                      &::before {
                        #{$rdirection}: 3px;
                        bottom: 18px;
                        font-size: 18px;
                        opacity: 0.5;
                        transform: rotate(0);
                      }
                    }
                  }
                }
              }
            }
            &.city-state-zip {
              .form-item {
                &.postal-look-up,
                &.city-look-up {
                  float: none;
                  width: 100%;
                }
              }
              &.field-container {
                .form-item {
                  margin-#{$rdirection}: 0;
                  width: 100%;
                  &.city {
                    .field[type='text'] {
                      display: block !important;
                    }
                  }
                  &.city,
                  &.postal-code {
                    margin-#{$rdirection}: 0;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        .billing-address-form-fields {
          border-bottom: 0;
          margin-bottom: 52px;
          padding: 0 17px;
          @include breakpoint($width-xlarge) {
            margin-bottom: 60px;
            padding: 0;
          }
          .checkout-header {
            margin: 0;
          }
          .checkout_billing_address_under_subtitle {
            color: rgb(255, 0, 0);
            margin-bottom: 5px;
          }
          .checkout__subtitle {
            margin-bottom: 5px;
          }
          .field-container {
            .form-item.address4,
            .form-item.state {
              margin-bottom: 22px;
            }
            .delivery_instructions {
              display: none;
            }
            .country-id.select,
            .phone-prefix {
              .select2-arrow {
                display: none;
              }
              &::after {
                #{$rdirection}: 25px;
                border: 5px solid transparent;
                border-top-color: $color--black;
                content: '';
                opacity: 0.5;
                pointer-events: none;
                position: absolute;
                top: 26px;
              }
              select {
                background-image: none;
              }
            }
            .select2-chosen {
              margin-#{$ldirection}: 12px;
            }
          }
        }
        .saved-billing-address {
          &.bill-form-expand {
            & + input[name='PROCESS_BILLING_FORM'] + .billing-address-form-fields {
              border-bottom: 1px solid $color--light--black;
              margin-bottom: 15px;
              padding-bottom: 15px;
            }
          }
        }
        .form-item {
          &.ship_type,
          &.ship_type_container {
            display: flex;
            gap: 10%;
            margin-bottom: 22px;
            .form-item.radio {
              margin: 0;
              width: auto;
              .picker-radio {
                margin: 0;
              }
            }
            label:first-child {
              display: none;
            }
          }
        }
      }
    }
  }
}
