/* Account signin page styling */
.sign-in-page {
  @include breakpoint($bp--medium-up) {
    .sign-in-container {
      width: 60%;
      @include breakpoint($bp--xxlarge-up) {
        width: 50%;
      }
    }
    .new-account,
    .return-user {
      margin-#{$rdirection}: 30px;
    }
    .field-container--grid .form-item {
      float: none;
      margin-#{$rdirection}: 0;
      padding-#{$rdirection}: 0;
      clear: both;
    }
    .divider {
      .social-login__divider {
        .inner {
          padding: 5px 2px;
        }
      }
    }
    .social-login-section {
      margin-top: 155px;
    }
    .error_messages {
      width: 93%;
    }
  }
  .field-container-section {
    .divider {
      display: block;
    }
  }
  .divider {
    .social-login {
      &__divider {
        display: none !important;
        &:before {
          #{$ldirection}: 100%;
        }
        .inner {
          //Altered (or) text divider, to display at center.
          #{$rdirection}: -23px;
          #{$ldirection}: inherit;
        }
      }
    }
  }
  .social-login {
    &__email-opt-in {
      margin-top: 20px;
      margin-#{$ldirection}: 0;
      padding: 0;
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 55px;
      }
    }
    &__divider {
      @include swap_direction(margin, 11px auto 8px);
    }
    &__terms {
      text-align: $ldirection;
      margin-bottom: 0;
      @include breakpoint($bp--medium-up) {
        margin-bottom: 10px;
      }
    }
    .social-login__divider {
      .inner {
        padding: 5px 2px;
      }
    }
  }
}
/* GNAV styling */
.social-login.gnav,
.confirm-container {
  .social-login {
    &__button {
      margin-top: 10px;
    }
    &__email-opt-in {
      margin-top: 20px;
      padding: 0 10px;
    }
    &__terms {
      text-align: $ldirection;
    }
    &__divider {
      @include swap_direction(margin, 20px auto 0);
      .inner {
        padding: 5px 2px;
      }
    }
  }
}
/* Checkout page Styling */
.checkout {
  &__content {
    .signin-panel,
    .sign-in-panel {
      .social-clear {
        clear: both;
      }
      .social-login {
        &__email-opt-in {
          padding: 0;
          margin-top: 20px;
        }
        &__terms {
          text-align: $ldirection;
          padding-#{$ldirection}: 0;
          margin-top: 15px;
        }
        &__divider {
          margin-bottom: 20px;
          .inner {
            padding: 5px 2px;
          }
        }
        &__container {
          padding-bottom: 4px;
          @include breakpoint($bp--medium-up) {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  &__return-user {
    margin-bottom: 13px;
    @include breakpoint($bp--medium-up) {
      margin-bottom: 0;
    }
  }
}
/* Order confirmation page */
#confirmation-page {
  .checkout__content {
    padding-bottom: 10px;
  }
  .social-login {
    margin-top: 30px;
    padding-bottom: 20px;
    &__button {
      margin: 0;
      text-align: center;
    }
    &__email-opt-in {
      padding: 0;
      margin-top: 20px;
    }
  }
}
/* Profile preferences page */
.social-info {
  &__header {
    padding-top: 25px;
    border-top: $border;
  }
  &__content {
    padding: 0;
  }
  &__connect {
    margin-bottom: 15px;
    .connected-status {
      display: none;
    }
  }
  &__image {
    margin-#{$rdirection}: 10px;
    width: 30px;
    height: 30px;
    border-radius: 3px;
  }
  &__status {
    @include breakpoint($bp--medium-up) {
      margin-#{$rdirection}: 50px;
    }
    margin-#{$rdirection}: 10px;
  }
  .connect-facebook-button {
    display: none;
    height: 28px;
    padding: 0 20px;
    line-height: 20px;
    font-family: $roboto-condensed-bold-font;
    font-size: 15px;
    text-align: center;
    background-color: $black;
    text-transform: uppercase;
    cursor: pointer;
    color: $white;
    border: none;
    outline: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .fb-overlay-social-info {
    display: inline-block;
  }
  .fb-login-button {
    vertical-align: middle;
  }
  #vkontakte_disconnect input.form-submit,
  #facebook_disconnect input.form-submit {
    height: 28px;
    line-height: 20px;
    background-color: $white;
    border: 1px solid $black;
    color: $black;
    outline: none;
  }
}

.profile-page {
  &__social {
    padding-#{$ldirection}: 0;
  }
}

.account-nav {
  .connected-status {
    display: none;
  }
  .social-image {
    height: 22px;
    width: 22px;
  }
  .connected-label {
    margin-#{$rdirection}: 10px;
  }
  .account-profile__connected-accounts {
    margin-bottom: 15px;
  }
}

.social-info__status,
.connect-facebook-button,
.social-image {
  display: inline-block;
}
/* Appointment booking page */
.appt-book {
  .appt-book-section {
    .social-login {
      &__terms {
        padding: 0;
      }
      &__email-opt-in {
        text-align: #{$ldirection};
        padding: 0;
        display: block;
        margin-top: 20px;
      }
      label {
        vertical-align: top;
        display: inline;
      }
      #facebook__email-opt-in-register-panel {
        width: 20px;
        display: inline-block;
        height: 18px;
        margin: 0;
      }
      input {
        width: auto;
        height: auto;
      }
    }
  }
}

.site-header {
  .site-header__tools {
    .site-my-mac__contents {
      overflow-y: scroll;
      height: 550px;
      width: 400px;
    }
  }
}

.social-login {
  &__email-opt-in {
    margin-top: 15px;
    padding: 0 20px;
  }
  .picker-label {
    float: none;
    text-align: $ldirection;
    margin-#{$ldirection}: 39px;
  }
  &__button {
    width: 292px;
    margin: 0 auto;
    visibility: visible;
    background-color: $color--blue--dark;
    color: $white;
    height: 50px;
    letter-spacing: 0.25px;
    line-height: 47px;
    cursor: pointer;
    .fb-image {
      width: 24px;
      margin-#{$rdirection}: 8px;
      vertical-align: middle;
    }
    .fb-content {
      font-family: Helvetica, Arial, sans-serif;
      font-size: 16px;
    }
  }
}
/* Account Signin Page Content Bottom */
.sign-in-page.responsive-container {
  .social-login-section {
    .social-terms__container {
      display: none;
    }
    .social-login__terms {
      display: block;
      padding: 0;
      margin-top: 20px;
      color: $color--border;
      @include breakpoint($bp--xlarge-up) {
        margin-#{$ldirection}: 0;
      }
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 55px;
      }
    }
    .social-login__email-opt-in {
      @include breakpoint($bp--medium-up) {
        padding: 0;
      }
    }
  }
}
/* Write a review page */
#power_review_container {
  .social-login {
    &__email-opt-in {
      padding: 0;
    }
  }
}
