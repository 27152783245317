.enhanced_signin_page {
  background-color: $color--snow;
  .responsive-container {
    &.checkout {
      max-width: 562px;
      background: $white;
      margin-top: 4em;
      margin-bottom: 2em;
      margin: 0 auto;
    }
    .mobile-breadcrumb {
      display: none;
    }
    .checkout {
      &.panel {
        padding: 0;
        background: $white;
        max-width: 562px;
        margin: 0;
        @media (min-width: $width-medium) {
          padding: 24px 0;
          margin: 24px 0;
        }
      }
      .checkout-header {
        display: none;
      }
      .checkout__content {
        @media (min-width: $width-medium) {
          width: 100%;
          padding-#{$rdirection}: 24px;
          padding-#{$ldirection}: 24px;
        }
        .sign-in-page__tab-nav {
          max-width: inherit;
          clear: both;
          overflow: auto;
          height: 56px;
          margin-bottom: 40px;
          .sign-in-page__link {
            float: #{$ldirection};
            width: 50%;
            cursor: pointer;
            height: 56px;
            padding: 18px 3px;
            margin: 0;
            background-color: $white;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 1.25px;
            text-align: center;
            text-transform: uppercase;
            color: $color--gray--dusty;
            border-bottom: 1px solid $color--gray--tier2;
            @media (min-width: $width-medium) {
              font-size: 16px;
              line-height: 16px;
              margin-bottom: 20px;
              margin: 0;
              padding: 0;
              height: 33px;
            }
            &.active {
              border-bottom: 2px solid $black;
              color: $color--black--opacity-88;
            }
          }
          @media (min-width: $width-medium) {
            height: 48px;
            margin: 0;
            padding-top: 15px;
            background: $white;
          }
        }
        #sign-in-panel {
          border-bottom: none;
          padding-top: 0;
          .signin-header {
            border-top: 0;
            display: none;
          }
          .checkout__return-user {
            padding: 0 16px;
            display: none;
            #return-account-h {
              border: 0;
              margin: 0;
              @media (min-width: $width-medium) {
                margin-bottom: 16px;
              }
            }
            #checkout_signin {
              width: 100%;
              margin: 0;
              .form-item {
                padding: 0.5em 0;
                margin: 0;
                position: relative;
                @media (min-width: $width-medium) {
                  padding: 6px 0;
                }
                .field {
                  &.js-label-mode {
                    + .label {
                      line-height: 32px;
                    }
                  }
                }
                .label {
                  height: 54px;
                  margin-top: calc(-54px - 1px);
                  font-size: 13px;
                  line-height: 24px;
                  text-transform: uppercase;
                  color: $color--gray--dusty;
                  padding-top: 16px;
                  padding-#{$ldirection}: 15px;
                  @if $fonts_update == false {
                    font-family: $roboto-mono_regular-font;
                  }
                  &:before {
                    margin-#{$ldirection}: 0;
                    max-width: 99%;
                  }
                  .label-content {
                    display: none;
                  }
                }
                .invalid_marker {
                  top: 16px;
                  #{$rdirection}: 22px;
                }
                .valid_marker {
                  top: 16px;
                  #{$rdirection}: 12px;
                }
                &--password-field {
                  .valid_marker {
                    #{$rdirection}: 40px;
                  }
                  .invalid_marker {
                    #{$rdirection}: 52px;
                  }
                }
                .return-user-email,
                .return-user-password {
                  height: 54px;
                  margin: 0;
                  width: 100%;
                  padding-#{$ldirection}: 15px;
                  @media (min-width: $width-medium) {
                    width: 358px;
                  }
                }
              }
              .password-eye-icon-container {
                float: #{$rdirection};
                width: 60px;
                position: relative;
                bottom: 37px;
                #{$rdirection}: 10px;
                .show-password-icon {
                  float: #{$rdirection};
                }
              }
              .form-submit {
                height: 48px;
                line-height: 24px;
                font-weight: normal;
                letter-spacing: 1.25px;
                width: 100%;
                @if $fonts_update == false {
                  font-size: 16px;
                  font-family: $ano-bold_regular-font;
                }
              }
              .checkout-forgot-password {
                height: 16px;
                margin: 10px 0;
                margin-bottom: 19px;
                clear: both;
                @media (min-width: $width-medium) {
                  height: 20px;
                }
                .forgot-password {
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.25px;
                  color: $black;
                  text-decoration: underline;
                  border-bottom: 0;
                  @if $fonts_update == false {
                    font-family: $roboto-mono_regular-font;
                  }
                }
              }
              @media (min-width: $width-medium) {
                width: 70%;
                margin: 0 auto;
              }
            }
            .signin-hidden-form {
              @media (min-width: $width-medium) {
                margin-bottom: 0;
              }
            }
            @media (min-width: $width-medium) {
              width: 100%;
              float: none;
              margin: 0 auto;
              padding: 0;
            }
          }
          .checkout__new-account {
            padding: 0 16px;
            #new-account-h {
              display: block;
              border: 0;
              margin-bottom: 7px;
              @media (min-width: $width-medium) {
                margin-bottom: 24px;
              }
            }
            #checkout_signin_guest_user {
              .form-item {
                padding: 0.5em 0;
                .field {
                  &.js-label-mode {
                    + .label {
                      line-height: 32px;
                    }
                  }
                }
                .label {
                  height: 54px;
                  margin-top: calc(-54px - 1px);
                  font-size: 13px;
                  line-height: 24px;
                  text-transform: uppercase;
                  color: $color--gray--dusty;
                  padding-top: 16px;
                  padding-#{$ldirection}: 15px;
                  @if $fonts_update == false {
                    font-family: $roboto-mono_regular-font;
                  }
                  &:before {
                    margin: 0;
                    max-width: 99%;
                  }
                  .label-content {
                    display: none;
                  }
                }
                .valid_marker {
                  @media (min-width: $width-medium) {
                    top: 10px;
                  }
                  top: 14px;
                  #{$rdirection}: 12px;
                }
                .invalid_marker {
                  @media (min-width: $width-medium) {
                    top: 10px;
                  }
                  top: 16px;
                  #{$rdirection}: 22px;
                }
                .validate-email {
                  width: 100%;
                  height: 54px;
                  margin: 0 1px;
                  @media (min-width: $width-medium) {
                    width: 358px;
                    padding-#{$ldirection}: 15px;
                    margin: 0;
                  }
                }
                @media (min-width: $width-medium) {
                  padding: 0;
                  margin-bottom: 20px;
                }
              }
              .accepted-privacy-policy {
                display: none;
              }
              .form-submit {
                height: 48px;
                line-height: 24px;
                font-weight: normal;
                letter-spacing: 1.25px;
                width: 100%;
                @if $fonts_update == false {
                  font-size: 16px;
                  font-family: $ano-bold_regular-font;
                }
              }
              .signin-legal {
                margin: 9px 0;
                .info-link-container {
                  padding-top: 2px;
                }
                .info-link {
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.25px;
                  text-decoration-line: underline;
                  color: $color--black--opacity-88;
                  display: inline;
                  clear: none;
                  float: none;
                  border-bottom: none;
                  margin-top: 4px;
                  @if $fonts_update == false {
                    font-family: $roboto-mono_regular-font;
                  }
                }
                .privacy-policy-info-icon {
                  float: #{$ldirection};
                  margin-#{$rdirection}: 8px;
                }
                @media (min-width: $width-medium) {
                  margin: 0 0 20px;
                }
              }
              @media (min-width: $width-medium) {
                width: 70%;
                margin: 0 auto;
              }
            }
            @media (min-width: $width-medium) {
              width: 100%;
              float: none;
              margin: 0 auto;
              padding: 0;
            }
          }
          .social-login {
            margin: 0 auto;
            .social-login__container {
              padding: 0 16px;
              .fb-overlay-container {
                width: 100%;
                .custom-fb-login-btn-container {
                  display: block;
                  background: $color--chambray;
                  height: 48px;
                  width: 100%;
                  line-height: 48px;
                  position: absolute;
                  .fb-btn-icon {
                    padding: 15px;
                    width: 50px;
                    height: 48px;
                    float: #{$ldirection};
                    border-#{$rdirection}: 1px solid $color--steel--blue;
                  }
                  .fb-btn-text {
                    color: $white;
                    @if $fonts_update == true {
                      font-weight: bold;
                    } @else {
                      font-size: 16px;
                      font-family: $ano-bold_regular-font;
                      text-transform: uppercase;
                    }
                  }
                }
                .fb-login-button {
                  width: 100%;
                  opacity: 0;
                  height: 48px;
                }
              }
              .fb-social-tooltip-container {
                padding: 0;
                margin-top: 27px;
                .fb-disclaimer,
                .fb-disclaimer a {
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.25px;
                  color: $color--black--opacity-88;
                  @if $fonts_update == false {
                    font-family: $roboto-mono_regular-font;
                  }
                }
                .fb-disclaimer {
                  margin-#{$ldirection}: 30px;
                }
                .picker {
                  margin: 0;
                  margin-bottom: 15px;
                  .picker-handle {
                    margin-top: 0;
                    margin-#{$rdirection}: 0;
                    width: 18px;
                    height: 18px;
                    .picker-flag {
                      background-color: $black;
                      &:before {
                        color: $white;
                        bottom: 2px;
                        font-size: 17px;
                        position: relative;
                      }
                    }
                  }
                }
                .fb-social-tooltip-disclaimer {
                  display: none;
                }
              }
              .social-login__email-opt-in {
                margin: 13px 0 0;
                padding: 0;
                .picker-handle {
                  margin-top: 0;
                  margin-#{$rdirection}: 0;
                  width: 18px;
                  height: 18px;
                  .picker-flag {
                    background-color: $black;
                    &:before {
                      color: $white;
                      bottom: 2px;
                      font-size: 17px;
                      position: relative;
                    }
                  }
                }
              }
              .social-login__opt-in-label {
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.25px;
                color: $color--black--opacity-88;
                margin-#{$ldirection}: 30px;
                @if $fonts_update == false {
                  font-family: $roboto-mono_regular-font;
                }
              }
              .tooltip {
                margin-#{$ldirection}: 30px;
                margin-top: 7px;
                line-height: 0.25px;
                color: $color--black--opacity-88;
              }
              @media (min-width: $width-medium) {
                padding: 0;
              }
            }
            @media (min-width: $width-medium) {
              width: 70%;
            }
          }
          .social-login__divider,
          .social_login_v2__delimeter-horizontal {
            width: 100%;
            height: 16px;
            margin: 15px 0 20px;
            &:before {
              top: 62%;
              opacity: 0.1;
              height: 1px;
            }
            .inner,
            .social_login_v2__delimeter-label {
              @include swap_direction(padding, 0 7px 0 9px);
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.25px;
              color: $color--black--opacity-64;
              width: 28%;
              border: none;
              background: $white;
              @if $fonts_update == false {
                font-family: $roboto-mono_regular-font;
              }
              @media (min-width: $width-medium) {
                width: 41%;
              }
            }
            @media (min-width: $width-medium) {
              margin: 26px 0;
            }
          }
          .checkout__subtitle {
            font-weight: normal;
            font-size: 24px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.14px;
            color: $color--black--opacity-88;
            margin: 0 0 18px;
            @if $fonts_update == false {
              font-family: $ano-bold_regular-font;
            }
            @media (min-width: $width-medium) {
              font-size: 36px;
              line-height: 32px;
              color: $black;
              margin: 0;
            }
          }
          .social-login-container {
            &__social-network {
              width: 100%;
              margin: 0 auto;
              border-radius: 0;
              &.vk-social-login {
                margin: 12px auto;
              }
            }
          }
          .social-login-container,
          .social_login_v2__delimeter,
          .social_login_v2__social-login-container,
          .sign-in-component_ru__legal-container,
          .messages-container {
            @media (min-width: $width-medium) {
              padding: 0;
              width: 70%;
            }
            margin: 0 auto 10px;
            width: 100%;
            padding: 0 16px;
          }
          .messages {
            @media (min-width: $width-medium) {
              margin: 40px auto;
            }
            &::after {
              display: none;
            }
          }
          .social_login_v2__social-login-container {
            .social-login-container__social-network-button {
              width: 100%;
              border-radius: 0;
              height: 48px;
              font-size: 16px;
              &_facebook {
                @media (min-width: $width-medium) {
                  background: $color--chambray url('/media/images/checkout/fb-icon.png') 21px/20px no-repeat;
                }
                background: $color--chambray url('/media/images/checkout/fb-icon.png') 16px/20px no-repeat;
                position: relative;
                font-weight: normal;
                .social-login-container__social-network-button-label {
                  border-#{$ldirection}: 1px solid $color--steel--blue;
                }
              }
              &_vkontakte {
                position: relative;
                background: $color--cyan--blue;
                .social-login-container__social-network-button-label {
                  border-#{$ldirection}: 1px solid $color--chetwode--blue;
                }
                .vkontakte_button_icon {
                  display: block;
                  float: $ldirection;
                  margin-#{$ldirection}: 13px;
                  line-height: 20px;
                  @media (min-width: $width-medium) {
                    margin-#{$ldirection}: 16px;
                  }
                  .vkontakte_icon {
                    width: 23px;
                    height: auto;
                  }
                }
              }
              &-label {
                @media (min-width: $width-medium) {
                  width: calc(100% - 60px);
                  #{$ldirection}: 60px;
                }
                position: absolute;
                width: calc(100% - 52px);
                height: 100%;
                #{$ldirection}: 52px;
                top: 0;
                display: block;
                line-height: 48px;
              }
            }
          }
          .sign-in-component_ru__legal-container {
            .picker {
              margin: 0 0 15px;
              .picker-label {
                margin-#{$ldirection}: 30px;
                color: $black;
                opacity: 0.88;
                letter-spacing: 0.25px;
              }
              .picker-handle {
                margin-top: 0;
                margin-#{$rdirection}: 0;
                width: 18px;
                height: 18px;
                .picker-flag {
                  background-color: $black;
                  &:before {
                    color: $white;
                    bottom: 2px;
                    position: relative;
                    font-size: 17px;
                  }
                }
              }
            }
          }
        }
        #shipping-panel,
        #review-panel,
        #guarantee-panel,
        .registration-panel {
          display: none;
        }
        .accepted-privacy-policy {
          display: none;
        }
        .checkbox-container {
          display: block;
          position: relative;
          padding-#{$ldirection}: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark {
              background-color: $color--nero;
              &:after {
                display: block;
              }
            }
          }
          .checkmark {
            position: absolute;
            top: 0;
            border: 1px solid $black;
            #{$ldirection}: 0;
            height: 25px;
            width: 25px;
            background-color: $color--gray--light--checkout;
            &:after {
              content: '';
              position: absolute;
              display: none;
              #{$ldirection}: 9px;
              top: 5px;
              width: 5px;
              height: 10px;
              border: solid $white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
          &:hover {
            input {
              & ~ .checkmark {
                border: 1px solid $black;
              }
            }
          }
          @media (min-width: $width-medium) {
            padding-#{$ldirection}: 32px;
          }
        }
        .messages-container {
          border-bottom: none;
          margin: 0 auto;
          .single-message {
            padding: 0;
            margin-bottom: 30px;
          }
        }
      }
      .checkout__sidebar {
        display: none !important;
      }
    }
  }
  @media (min-width: $width-medium) {
    background: $color--snow;
  }
  .LPMcontainer,
  & ~ .LPMcontainer {
    display: none !important;
  }
}

.custom-fb-login-btn-container {
  display: none;
}

.social-login-container__social-network-button {
  .vkontakte_button_icon {
    display: none;
  }
}
