.product-foundation-vto {
  &__btn {
    width: 260px;
  }
  &__guide-title {
    font-size: 31px;
    margin: 25% auto 8%;
    width: 87%;
  }
  &__info-container {
    margin-bottom: 5px;
  }
  &__all-shades {
    margin-top: 3px;
  }
  &__mobile-ctrl {
    .container {
      .product-size {
        margin-top: 1px;
      }
    }
  }
}

.grid--mpp {
  .product {
    &__detail {
      .cta-vto {
        display: none;
      }
    }
    &__link-custom {
      display: none;
    }
  }
}
