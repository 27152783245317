/* ==========================================================================
   Components Atoms - Forms, Selects
   ========================================================================== */

/* Mixin - Select Base
   ========================================================================== */
@mixin select-base {
  height: 56px;
  line-height: 58px;
  background: #ffffff;
  @include swap_direction(border-radius, 0);
  color: $color--text;
  border-width: 0;
  border-width: 1px;
  @include swap_direction(padding, 0);
  width: 100%;
  text-transform: uppercase;
  text-decoration: underline;
}
@mixin select--small {
  height: 40px;
  line-height: 40px;
}
@mixin select--border {
  border-width: 1px;
}

select {
  // touch and non-JS styles
  @include select-base;
  @include appearance(none);
  -o-appearance: none;
  appearance: none;
  @include background(image-url('fallbacks/corner-triangle.png'));
  //  background-image: url("../images/icons/src/arrow--down.svg");
  @include swap_direction(padding, 0 7% 0 8px);
  background-repeat: no-repeat;
  background-position: 95% 54%;
  background-size: 12px;
  border-color: $color--gray--lightest;
  option {
    text-transform: uppercase;
  }
  &.select--small {
    @include select--small;
  }
  .address-form & {
    @include select--border;
  }
  &::-ms-expand {
    display: none;
  }
}

@mixin select--arrow-down() {
  .select2-choice {
    .select2-arrow {
      b {
      }
    }
  }
}

.select2-container {
  // http://ivaynberg.github.io/select2/
  @include swap_direction(border, 1px solid $color--gray);
  .select2-choice {
    @include select-base;
    > .select2-chosen {
    }
    .select2-arrow {
      background: white;
      @include swap_direction(border-radius, 0);
      border-width: 0;
      width: 14px;
      top: auto;
      bottom: 0;
      #{$rdirection}: 16px;
      b {
        @include icon(caret-down);
        background: none;
        font-size: 16px;
        font-weight: normal;
        &:before {
          position: absolute;
          bottom: 11px;
          #{$rdirection}: 0;
          @include transform(rotate(-45deg));
          font-size: 26px;
        }
      }
    }
  }
  &.select--small {
    .select2-choice {
      @include select--small;
    }
  }
  .address-form & {
    .select2-choice {
      @include select--border;
    }
  }
  &.select2-drop-above {
    .select2-arrow {
      //b {
      //  @include icon(arrow--up);
      //}
    }
    .select2-choice {
      @include select-base;
    }
    &.select2-container-active {
      .select2-choice {
        border-color: $color--gray;
      }
    }
  }
  &.select--border {
    @include swap_direction(border, 1px solid $color--border);
    select,
    .select2-drop-active,
    .select2-choice {
      border-color: $color--border;
    }
    &.select2-drop-above {
      .select2-choice {
        border-color: $color--border;
      }
    }
    &.select2-dropdown-open {
      // overriding no border for the dropdown open state
      .select2-choice {
        border-color: $color--border;
      }
    }
  }
  &.select--arrow-down {
    @include select--arrow-down();
  }

  // on full spps
  .product & {
    @include select--arrow-down();
  }
  /* list all small borderless selects here */
  .cart-item__qty &,
  .alter_replenishment_form--container &,
  .ship-method & {
    border-width: 0;
    .select2-choice {
      border-bottom: none;
      height: 60px;
      line-height: 30px;
      .select2-arrow b::before {
        bottom: 0;
      }
    }
    .select2-choice > .select2-chosen {
      @include swap_direction(margin, 0 0 0 26px);
      border-bottom-width: 0;
    }
  }
  .cart-item__qty & {
    .select2-choice {
      height: 45px;
      .select2-arrow b::before {
        bottom: 14px;
      }
    }
  }
  .alter_replenishment_form--container & {
    .select2-choice > .select2-chosen {
      margin-#{$ldirection}: 0;
    }
    .select2-choice {
      height: 30px;
    }
  }
}

.select2-results {
  text-transform: uppercase;
  @if $fonts_update == true {
    @include h6();
    font-size: 15px;
    letter-spacing: 0;
    @include breakpoint($bp--xlarge-up) {
      @include h4();
      font-size: 15px;
      letter-spacing: 0;
    }
  }
  .select2-result-label {
    @if $fonts_update == true {
      .viewcart &,
      .checkout & {
        font-size: 12px;
      }
    }
  }
  .select2-highlighted {
    background: $color--black;
  }
}

.select2-drop {
  box-shadow: none;
  &.select2-drop-active {
    //    border-width: 0;
    &.select2-drop-above {
      border-width: 0;
      box-shadow: none;
    }
  }
}

.select2-dropdown-open {
  border-width: 1px 1px 0;
  border-style: solid;
  border-color: $color--gray--lighter;
}

.checkout-optimization {
  .cart-item__qty,
  .alter_replenishment_form--container {
    .select2-container {
      @include swap_direction(border, 1px solid $color--black);
      .select2-choice {
        height: 60px;
        line-height: 60px;
        text-decoration: none;
      }
    }
  }
  .ship-method {
    .select2-choice {
      height: 52px;
      line-height: 52px;
    }
  }
}
