/**
 * @file
 * Components Molecules - Navigation, Sec Nav
 *
 * High padding white sub nav "breadcrumb"
 */

.sec-nav {
  &.site-banner__sticky-fix .sec-nav_wrapper a {
    float: left;
    border-bottom: none;
    .sec-nav__title span:hover {
      text-decoration: underline;
    }
  }
  .sec-nav_wrapper {
    &.site-banner__sticky-fix a .sec-nav__title {
      span:hover {
        text-decoration: underline;
      }
    }
    .sec-nav__title {
      width: auto;
      min-width: auto;
      @include swap_direction(padding, 22px 0 0px 10px);
      border-#{$rdirection}: none;
      @include breakpoint($bp--xlarge-up) {
        font-size: 1.5rem;
      }
    }
    .sec-nav__title_name {
      @include icon(arrow--right, $pseudo: after);
      &::after {
        font-size: 1rem;
        padding-#{$ldirection}: 10px;
        position: relative;
        top: -1px;
      }
    }
  }
  position: absolute;
  background: $color--white;
  z-index: $z-high;
  @include breakpoint($bp--xlarge-up) {
    border-bottom: $border;
    min-height: 60px;
    top: 60px;
    width: 100%;
    z-index: $z-highest;
  }
  .site-header & {
    top: 60px;
    width: 100%;
    z-index: $z-high;
    .sg-pattern-body & {
      position: relative;
    }
  }
  ul {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    list-style-type: none;
    li.sec-nav__sub-item {
      a.sec-nav__sub-link:hover {
        color: $color--gray;
      }
    }
  }
  body.page-product & {
    @include breakpoint($bp--large-down) {
      display: none;
    }
  }
}

.field-elc-mpp-content .sec-nav,
.page-products .sec-nav {
  height: 48px;
  position: absolute;
  top: 60px;
  z-index: 102;
  .sec-nav__sections .sec-nav__item .sec-nav__arrow {
    display: none;
    @include icon('arrow--left');
  }
  @include breakpoint($bp--large-down) {
    width: 100%;
    .sec-nav__sections .sec-nav__item {
      position: relative;
      .sec-nav__arrow {
        display: block;
        font-size: 18px;
        position: absolute;
        top: 11px;
      }
      &--has-sub-nav .sec-nav__link:before {
        left: 0;
        display: none;
      }
      span {
        margin-left: 0;
      }
      .sec-nav__link {
        @include swap_direction(padding, 0px 20px);
      }
    }
  }
  @include breakpoint($bp--xlarge-down) {
    position: fixed;
  }
}

// The first item in the list, far left. Only shows medium-up
.sec-nav__title {
  @include fontsize-rem(30px);
  @include layout(12) {
    width: column-span(2, 1);
  }
  border-#{$rdirection}: $border;
  display: none; // hide for small
  float: #{$ldirection};
  height: 60px;
  line-height: 1;
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 24px 0 0px 10px);
  min-width: 200px;
  // small up
  @include breakpoint($bp--xlarge-up) {
    display: block;
  }
  @include breakpoint($bp--large-down) {
    display: block;
    font-size: 20px;
    padding: 21px 0 0 0;
    text-align: center;
    width: 100%;
  }
}

// MPP breadcrumb & sub-category title
.sec-nav__title-category {
  display: none;
}

body.page-products {
  .sec-nav__arrow {
    display: block;
    font-size: 18px;
    #{$ldirection}: 12px;
    position: absolute;
    top: 15px;
    @include icon('arrow--left');
    @include breakpoint($bp--xlarge-up) {
      display: none;
    }
  }
  .sec-nav__title {
    @include breakpoint($bp--large-down) {
      display: none;
    }
  }
  .sec-nav__title-category {
    @include breakpoint($bp--large-down) {
      display: block;
      font-size: 20px;
      padding: 18px 0 0 0;
      text-align: center;
      width: 100%;
    }
  }
}

.sec-nav__sections {
  float: #{$ldirection};
  @include breakpoint($bp--medium-down) {
    position: absolute;
  }
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0);
  list-style-type: none;
  @include breakpoint($bp--xlarge-up) {
    max-width: 70%;
  }
}

body.page-products .sec-nav__sections,
body.page-product .sec-nav__sections {
  @include breakpoint($bp--large-down) {
    display: none;
  }
}

// Items visible in the bar (after title)
.sec-nav__item {
  // mobile-up
  list-style-type: none;
  position: relative;
  text-align: center;
  display: none;
  @include swap_direction(padding, $spacing 0);
  @include swap_direction(margin, 0 $spacing);
  z-index: $z-high;

  // medium-up:
  @include breakpoint($bp--xlarge-up) {
    @include layout(12) {
      border-bottom: none;
      border-#{$rdirection}: $border;
      display: table-cell;
      height: 60px;
      line-height: 15px;
      @include swap_direction(margin, 0);
      min-width: 275px;
      max-width: 375px;
      @include swap_direction(padding, 0px 20px 8px $spacing);
      text-align: #{$ldirection};
      vertical-align: bottom;
    }
  }
}

.sec-nav__item--has-sub-nav {
  // Only show dropdown when medium-up
  @include breakpoint($bp--xlarge-up) {
    &:hover {
      .sec-nav__sub-nav {
        display: block;
      }
    }
  }
  // mobile: left arrow, medium-up: triangle
  .sec-nav__link {
    // place psuedo icon
    @include icon('arrow--left');
    // Icons need help with abs pos
    &:before {
      position: absolute;
      #{$ldirection}: 10px;
      top: 35px;
    }
    // Triangle for medium-up
    @include breakpoint($bp--xlarge-up) {
      &:before {
        @include corner-triangle(); // not extending due to inside mq
        content: '';
        #{$ldirection}: auto;
        #{$rdirection}: 11px;
        bottom: 11px;
      }
    }
    &,
    &:visited {
      border-bottom: none;
    }
  }
}

// Element inside of .sec-nav__item that is the actual a link
.sec-nav__link {
  @include h6();
  @include swap_direction(margin, 0);
  display: block;
  //  height: 60px;
  @include breakpoint($bp--xlarge-up) {
    display: block;
    line-height: 1;
    height: auto;
    padding-#{$rdirection}: $spacing;
    border-bottom: none;
    @if $fonts_update == true {
      font-size: 1.5rem;
    }
  }
}

// Dropdown below .sec-nav__item
.sec-nav__sub {
  &-nav {
    background: $color--white;
    @include swap_direction(border, $border);
    border-top: 0;
    display: none;
    list-style-type: none;
    position: absolute;
    top: 59px;
    #{$ldirection}: -1px;
    #{$rdirection}: -1px;
    width: 100%;
    width: calc(100% + 2px);
    max-height: 280px;
    overflow: auto;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
  }
  &-item {
  }
  &-link,
  &-link:active,
  &-link:focus,
  &-link:visited {
    @include unset-underline-links($extend: false);
    @include heading-base();
    @include fontsize-rem(15px);
    line-height: 1.1;
    color: $color--gray--light;
    border-bottom-color: inherit;
    @include swap_direction(padding, 15px);
    display: block;
  }
  &-link:hover {
    color: $color--gray;
  }
}

.field-content .custom-grid {
  .trustmark-header {
    width: 75%;
    border-#{$rdirection}: 1px solid $color--white;
  }
  .trustmark-img {
    top: 12%;
    width: 25%;
    position: relative;
    float: #{$rdirection};
    @include swap_direction(padding, 0 3%);
    text-align: center;
  }
  .trustmark-txt {
    text-align: center;
    color: $color--white;
  }
}
