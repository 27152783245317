/* ==========================================================================
   Global Variables - Settings
   ========================================================================== */

$spacing: 15px !default;
$gap: 6px !default;
$site-header-height: 60px;
$site-banner-height: 60px;
$site-banner-mobile-height: 6em;

@if $gnav_update == true {
  $site-header-height: 50px;
  $site-banner-height: 32px;
}

@if $cr20 == true {
  $site-banner-mobile-height: 55px;
  $site-header-height: 50px;
  $site-banner-height: 32px;
}

// Content Blocks.
$content-block-signup-height: 70px;
$content-block-signup-mobile-height: 125px;
$site-subnav-height: 60px;

// Site Header - 2020 Creative Refresh variables.
$gnav-fixed-z-index: 995 !default;
$navbar-height: 57px;
$social-shopping-bar-height: 65px;
$social-shopping-bar-height-large: 80px;
$gnav-height: $navbar-height + $site-banner-height;

@if $cr21 == true {
  $gnav-height: $gnav-height + 12px;
  $site-banner-mobile-height: 42px;
  $content-block-signup-height: $content-block-signup-height - 12px;
}

// Collapsing my account menu on payment info page for cr21.
$payment_info_collapse: false !default;

$gnav-icon--inline: 14px !default;
$gnav-mobile-hpad--outer: 22px !default;
$gnav-link-height: 66px !default;
$gnav-mobile-link-height: 64px !default;
$gnav-content-max-height: 377px !default;
$gnav_max_width: $width-xxxlarge !default;
// Content containers outer pad vars
$container-pad-small: 20px !default;
$container-pad-landscape: 30px !default;

// Compass Overrides
$default-transition-duration: 0.3s;
$default-transition-function: ease-in-out;

$z-lowest: 1 !default;
$z-low: 10 !default;
$z-mid: 50 !default;
$z-mid-high: 75 !default;
$z-high: 100 !default;
$z-highest: 500 !default;
// add a custom z-index for when we need to beat contextual links
$z-above-clinks: 1000 !default;
// add a custom z-index for when we need to beat mpp filters
$z-above-filters: 1001 !default;
// add a custom z-index for when we need to beat product sub-nav
$z-above-prod-sub-nav: 1002 !default;

// sets the whitespace around sprites
$sprites-spacing: 25px;

// defaults to including the sprite dimensions
$sprites-sprite-dimensions: true;

// set per-sprite customizations here
//$sprites-down-arrow-position: 100%; // set specific sprite to right side of spritesheet
//$sprites-search-position: 100%; // example
//$sprites-search_hover-position: 100%; // example
//$sprites-country_chooser-position: 100%; // "for country_chooser.png, move it to right side of spriteesheet"
//$sprites-country_chooser_hover-position: 100%;

// Used for various compass mixins
$browser-minimum-versions: (
  'ie': null,
  'android': '4',
  'chrome': '31',
  'safari': '5',
  'firefox': '25',
  'ios-safari': '6.0-6.1',
  'android-chrome': '0'
);

// Internet Explorer "Classes"; use like this: #{$ie8}
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
$ie11: "html[data-useragent*='MSIE 11.0']";

// LBO default SCSS variable configuration for not loading of LBO related CSS to all locales
$lbo-enabled: false !default;
$loyalty-redesign: false !default;

// Outline style on focused elements for keyboard accessibility
$outline: 1px dotted $color--blue--light;

// SMACSS/perlgem/OPC/opc_updates
$opc_update: false !default;

// Hide the SMS option
$hide_sms_opt: false !default;
//apending value for Live Chat text
$customer-servie: 'Customer Service, ';
//TMO UI Refesh
$tmo_order_tracking_update: false !default;
