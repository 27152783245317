.contact-us-page {
  @include swap_direction(padding, 0 1em);
  @include breakpoint($bp--small-up) {
    margin-top: 50px;
  }
  .contact-nav {
    @include clearfix();
    li {
      width: 100%;
      text-transform: uppercase;
      @include breakpoint($bp--small-up) {
        margin-top: -1px;
        @include layout($grid--2, 2em) {
          &:nth-of-type(2n + 1) {
            @include grid-span(1, 1);
            clear: both;
          }
          &:nth-of-type(2n + 2) {
            @include grid-span(1, 2);
          }
        }
      }
      @include breakpoint($bp--large-up) {
        @include layout($grid--4, 2em) {
          &:nth-of-type(4n + 1) {
            @include grid-span(1, 1);
            margin-#{$ldirection}: 0;
            clear: both;
          }
          &:nth-of-type(4n + 2) {
            @include grid-span(1, 2);
          }
          &:nth-of-type(4n + 3) {
            @include grid-span(1, 3);
          }
          &:nth-of-type(4n + 4) {
            @include grid-span(1, 4);
          }
        }
      }
      .title {
        font-size: 15px;
        padding-top: 20px;
        margin-bottom: 20px;
        @if $fonts_update == false {
          font-family: $ano-bold_regular-font;
        } @else {
          font-weight: bold;
        }
        @include breakpoint($bp--small-up) {
          border-top: 1px solid #7f7f7f;
        }
      }
      .details {
        @if $fonts_update == true {
          font-size: 12px;
        }
        @include breakpoint($bp--small-up) {
          min-height: 230px;
          padding-bottom: 10px;
          border-bottom: 1px solid #7f7f7f;
        }
        a {
          border-bottom-color: #000;
        }
        .phone-number {
          border-bottom: 0;
        }
      }
    }
    .chat-live-icon,
    .text-icon,
    .call-icon,
    .email-icon {
      display: inline-block;
      margin-#{$ldirection}: 4px;
    }
  }
  .js-email-customer-service-details {
    padding-top: 100px;
  }
  .email-customer-service-form,
  .email-artists-form {
    @include swap_direction(margin, 50px 0);
  }
  .customer-service-title,
  .email-artist-title {
    font-family: $ano-bold_regular-font;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .question {
    font-family: $ano-bold_regular-font;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &__holiday_note {
    text-align: center;
    @include breakpoint($bp--large-up) {
      padding-bottom: 14px;
    }
  }
}
