// Setting Defaults for Adaptive Placeholder
$adaptive-placeholder: (
  height: 58px,
  margin: 12px,
  border-radius: 0,
  font-size: 12px,
  border: 1px
);

.adpl_enabled {
  .adpl {
    input[type='email'],
    input[type='tel'],
    input[type='password'],
    input[type='text'],
    select {
      @include adaptive-placeholder(
        (
          height: map-get($adaptive-placeholder, height),
          margin: map-get($adaptive-placeholder, margin),
          border-radius: map-get($adaptive-placeholder, border-radius),
          font-size: map-get($adaptive-placeholder, font-size),
          border: map-get($adaptive-placeholder, border)
        )
      );
    }
    fieldset {
      overflow: visible;
      max-width: 100%;
    }
    .form-item {
      position: relative;
      overflow: visible;
    }
  }
  #offer-code-panel {
    .adpl {
      input[type='text'] {
        &.js-label-mode,
        &:focus {
          & + label:before {
            background-color: $color--gray--white;
          }
        }
      }
      label {
        text-align: left;
      }
    }
  }
}

.checkout__content {
  .with-field-lookup {
    .postcode-lookup {
      width: 37%;
    }
    .find-town {
      width: 61%;
    }
  }
}

.panel {
  .invalid_marker,
  .valid_marker {
    top: 12px;
    bottom: auto;
  }
}

// The global/jQuery picker label style uses float:left; which causes the label to move underneath when longer than the container.
// Some instances are fixed with a max-width:85%; but this leaves gaps on larger screens.
// Removing the float and adding a margin allows it to align to the right of the picker, and wrap within itself.
// The handle margin is also adjusted for consistency.
// Note, this is conditional so it can be enabled for specific locales after removing locale level overrides.
$use-picker-label-positioning: false !default;

@if $use-picker-label-positioning {
  .picker {
    .picker-handle {
      margin-#{$rdirection}: 5px;
    }
    .picker-label {
      float: none;
      padding-#{$ldirection}: 30px;
      // Set the width back to workaround other attempts at fixing this
      max-width: 100%;
      width: 100%;
    }
  }
}

//For hiding or-divider in EMEA sites which doesn't have social login
.field-container-section {
  .divider {
    display: none;
  }
}

// For RU redesign in profile preference page
.social-info__status,
.connect-facebook-button,
.social-image {
  display: none;
}
