/* ==========================================================================
   Components Molecules - Subnav menu
   ========================================================================== */
$submenu-breakpoint: $bp--large-up;

.submenu {
  @include swap_direction(margin, 0 auto);
  @include breakpoint($submenu-breakpoint) {
    border-bottom: $border;
  }
  &--small-hide {
    &-menu {
      .submenu__nav {
        display: none;
        @include breakpoint($submenu-breakpoint) {
          display: block;
        }
      }
    }
    &-title {
      .submenu__header {
        display: none;
        @include breakpoint($submenu-breakpoint) {
          display: block;
        }
      }
    }
    &-all {
      display: none;
      @include breakpoint($submenu-breakpoint) {
        display: block;
      }
    }
  }
  &--sticky {
    @include breakpoint($submenu-breakpoint) {
      position: fixed;
      width: 100%;
      background: $white;
      @if $cr20 {
        top: 0;
        @include single-transition($property: top);
        z-index: calc(#{$gnav-fixed-z-index} - 1);
        .gnav-offers-hidden &,
        .site-header-formatter--sticky & {
          top: $gnav-height;
        }
        .site-header-formatter--hidden & {
          top: 0;
        }
      } @else if $gnav_update {
        top: $site-header-height;
        z-index: 9995;
      } @else {
        top: 60px;
        z-index: 9995;
      }
    }
  }
  &--portrait-hide {
    @include breakpoint($bp--medium) {
      div {
        display: none;
      }
    }
  }
}

.submenu__header {
  position: relative;
  display: block;
  @include swap_direction(padding, 0 $spacing);
  text-align: center;
  border-bottom: $border;
  h3,
  .at-h2__submenu {
    @include h3();
    @include fontsize-rem($typesize--30px);
    @include swap_direction(margin, 18px 0 12px 0);
    line-height: 30px;
  }
  a {
    @include unset-underline-links($extend: false);
    @include single-transition($property: border-bottom-color);
    border-bottom-color: white;
    color: black;
    &:focus,
    &:hover {
      color: black;
      border-bottom: 1px solid black;
    }
  }
  @include breakpoint($submenu-breakpoint) {
    border-#{$rdirection}: 1px solid $color--gray--lightest;
    float: #{$ldirection};
    width: 28%;
    text-align: #{$ldirection};
    border-bottom: none;
  }
  .artistry--artist & a {
    @include icon('arrow--left');
    &:before {
      position: absolute;
      top: 5px;
      #{$ldirection}: 10px;
      font-size: 18px;
      @include breakpoint($submenu-breakpoint) {
        display: none;
      }
    }
  }
}

.submenu__nav {
  @include swap_direction(padding, $spacing);
  border-bottom: $border;
  a {
    @include single-transition($property: border-bottom-color);
    text-decoration: none;
    border-bottom-color: white;
    color: $color--black;
    @include swap_direction(padding, ($spacing / 2) 0);
    display: block;
    font-family: $ano-black_regular-font;
    font-size: 14px;
    &:focus,
    &:hover,
    &.active {
      border-bottom: 1px solid $color--black;
      color: $color--black;
    }
    &.active {
      cursor: default;
    }
  }
  @include breakpoint($submenu-breakpoint) {
    float: #{$ldirection};
    @include swap_direction(padding, 0);
    border-bottom: none;
    li {
      @include swap_direction(padding, 0 $spacing);
      float: #{$ldirection};
      display: block;
    }
    a {
      @include swap_direction(padding, 0);
      line-height: 1;
      margin-top: 22px;
    }
  }
}
