/**
 * social-login
 * This file contains all styilngs needed for facebook login implementation.
 *
 */

.social-login {
  &__container {
    @include breakpoint($bp--medium-up) {
      padding: 0;
    }
    text-align: center;
    padding-bottom: 15px;
  }
  &__divider {
    @include h7;
    @include swap_direction(margin, 15px auto 0);
    width: 100%;
    @include breakpoint($bp--medium-up) {
      @include swap_direction(margin, 0 auto 31px);
    }
    @include breakpoint($bp--xxlarge-up) {
      @include swap_direction(margin, 15px auto 15px);
    }
  }
  &__terms {
    @include breakpoint($bp--medium-up) {
      margin-top: 15px;
      text-align: $ldirection;
    }
    padding: 0 12px;
    margin-top: 26px;
    line-height: 17px;
    p {
      margin: 0;
    }
  }
  &__title,
  &__info {
    display: none;
  }
}
/* GNAV styling */
.social-login.gnav,
.confirm-container {
  .social-login__divider {
    @include swap_direction(margin, 3px auto 0);
    .inner {
      border: 9px solid;
      border-radius: 25px;
      padding: 4px 5px;
      background: $color--black;
      color: $color--white;
      letter-spacing: 0.02px;
      background-clip: padding-box;
    }
  }
}
/* Disconnect button styling */
#vkontakte_disconnect,
#facebook_disconnect {
  display: inline;
  margin-#{$ldirection}: 15px;
  input.form-submit {
    height: 20px;
    margin-bottom: 4px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
  }
}
/* Order confirmation page */
.checkout-confirmation-page {
  .social-login {
    padding-bottom: 30px;
    border-bottom: 1px solid $color--gray--lightest;
    @include breakpoint($bp--largest-up) {
      border-bottom: 0;
    }
    &__title {
      font-size: 30px;
      display: block;
    }
    &__container {
      text-align: $ldirection;
      padding-bottom: 0;
    }
    &__terms,
    &__divider {
      display: none;
    }
    &__info {
      margin-bottom: 10px;
      display: block;
    }
  }
}
/* Social info section on Account preferences page */
.social-info {
  margin-top: 10px;
  padding-#{$ldirection}: 10px;
  &__header {
    text-align: center;
    padding-bottom: 15px;
    @include breakpoint($bp--medium-up) {
      text-align: $ldirection;
    }
  }
  &__content {
    padding-#{$rdirection}: 2%;
    padding-top: 1%;
  }
  &__connect {
    margin-bottom: 20px;
  }
}

.account-profile {
  &__connected-accounts {
    text-transform: uppercase;
  }
}

.checkout {
  .checkout__return-user__toggle {
    @include swap_direction(margin, 8px 0 15px 0);
  }
  &__content {
    .signin-panel,
    .sign-in-panel {
      .social-clear {
        clear: both;
      }
      .social-login {
        &__divider {
          @include swap_direction(margin, 0 auto 12px);
          @include breakpoint($bp--medium-up) {
            @include swap_direction(margin, 25px auto 25px);
          }
        }
        &__terms {
          margin-top: 26px;
          margin-bottom: 11px;
        }
        &__title {
          display: none;
        }
        &__divider .inner {
          border: 9px solid;
          border-radius: 25px;
          padding: 4px 5px;
          background: $color--black;
          color: $color--white;
          letter-spacing: 0.02px;
          background-clip: padding-box;
        }
      }
    }
  }
}
/* Account signin page styling */
.sign-in-page {
  @include breakpoint($bp--medium-up) {
    position: relative;
    .social-login-section {
      .social-login__divider {
        display: none;
      }
    }
    .field-container-section {
      position: relative;
    }
    .divider {
      .social-login__divider {
        text-transform: uppercase;
        &:before {
          content: '';
          background: $color--black;
          width: 1px;
          top: 0;
          #{$ldirection}: 97%;
          display: inline-block;
          position: absolute;
          height: 100%;
          @include breakpoint($bp--largest-up) {
            #{$ldirection}: 100%;
          }
        }
        .inner {
          background: $color--black;
          margin-top: 0;
          top: 39%;
          #{$ldirection}: 93%;
          position: absolute;
          color: $color--white;
          padding: 4px 5px;
          border-radius: 25px;
          border: 10px solid;
          background-clip: padding-box;
          letter-spacing: 0.02px;
          @include breakpoint($bp--largest-up) {
            #{$ldirection}: 96.7%;
          }
        }
      }
    }
    .social-login-section {
      .social-terms__container {
        .social-login__terms {
          margin-#{$ldirection}: 0;
          padding-bottom: 10px;
          text-transform: uppercase;
          display: block;
          @include breakpoint($bp--xxlarge-up) {
            margin-#{$ldirection}: 55px;
          }
        }
      }
      .social-login {
        &__terms {
          display: none;
        }
      }
      vertical-align: top;
      margin-top: 120px;
      width: 39%;
      @include breakpoint($bp--xxlarge-up) {
        margin-top: 158px;
        width: 49%;
      }
    }
    .social-login-section,
    .divider,
    .sign-in-container {
      display: inline-block;
    }
    .sign-in-container {
      position: relative;
      width: 60%;
      @include breakpoint($bp--xxlarge-up) {
        width: 50%;
      }
    }
    .social-login__divider {
      position: relative;
    }
  }
  &.responsive-container {
    .social-terms__container {
      .social-login__terms {
        display: none;
        @include breakpoint($bp--medium-up) {
          display: block;
        }
      }
    }
    .divider {
      .social-login__divider {
        display: none;
        @include breakpoint($bp--medium-up) {
          display: block;
        }
      }
    }
  }
  .social-login {
    .social-login__divider .inner {
      border: 12px solid;
      border-radius: 25px;
      padding: 4px 5px;
      background: $color--black;
      color: $color--white;
      letter-spacing: 0.02px;
    }
    &__container p {
      margin-bottom: 11px;
    }
  }
  .form-item.submit-button {
    margin-bottom: 3px;
  }
}
/* GNAV content */
.site-header {
  .site-my-mac {
    &.active {
      .site-my-mac__contents {
        display: block;
      }
    }
    &__contents {
      display: none;
    }
  }
}
/* Write a review section */
#power_review_container {
  .social-login {
    float: $rdirection;
    width: 100%;
    position: relative;
    @include breakpoint($bp--large-up) {
      width: 50%;
    }
    &__divider {
      @include swap_direction(margin, 0 auto 10px);
    }
    &__email-opt-in {
      margin-#{$ldirection}: 0;
    }
    &__terms {
      padding: 0;
    }
    &__divider:before {
      #{$rdirection}: 0;
      top: 22px;
    }
  }
}
