* {
  @include box-sizing(border-box);
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

body {
  @include body-mono();
  background: $bg-color;
  overflow-x: hidden;
}

body.toolbar-drawer {
  padding-top: 0;
}

body.toolbar {
  padding-top: 6em;
}

// important is needed here to ensure that all padding rules are overridden
// when a content block is shown in an iframe in Vulcan.
body.page-iframe {
  padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
  min-height: unset !important; /* stylelint-disable-line declaration-no-important */
  .site-container {
    min-height: unset !important; /* stylelint-disable-line declaration-no-important */
  }
}

// Heading Element Styles
h1 {
  @include h1();
}

h2 {
  @include h2();
}

h3 {
  @include h3();
}

h4 {
  @include h4();
}

h5 {
  @include h5();
}

h6 {
  @include h6();
}

a {
  @include links-extend;
}

// Outline style on focused elements for keyboard accessibility
a,
button,
input,
select,
textarea,
iframe,
[tabindex='0'] {
  &:focus {
    .ada-enabled &,
    .no-js & {
      // Use important to override some existing outline none in the templates, libs like slick.
      @if $cr20 {
        outline: 1px dotted $color--blue--light !important;
      }
    }
  }
}

[tabindex='-1'] {
  &:focus {
    @if $cr20 {
      outline: none;
    }
  }
}

b,
strong {
  @include body-mono($bold: true);
  font-weight: normal;
}

p {
  margin-top: 0;
  margin-bottom: $base-line-em;
}

// hide elements for conditional loading
// only used for responsive comments plugin
*[data-usn-if] {
  display: none;
}

html,
button,
input,
select,
textarea {
  font-family: $body-font-family;
  font-size: $body-font-size;
  color: $color--text;
  @if $fonts_update == true {
    font-family: $helvetica-font;
    font-size: 15px;
    line-height: $lineheight--30-24;
  }
}

input,
select {
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 767px) {
    font-size: $input-font-size;
  }
}

input::-webkit-input-placeholder {
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 767px) {
    font-size: $body-font-size;
  }
}

a:hover,
a:active {
  text-decoration: none;
}

hr {
  display: block;
  height: 1px;
  @include swap_direction(border, 0);
  border-top: $border;
  @include swap_direction(margin, $base-line-em 0);
  @include swap_direction(padding, 0);
}

// Remove bullets from lists
dl,
ol,
ul {
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0);
}

ul {
  list-style: none;
}

ol {
  margin-#{$ldirection}: $spacing;
}

th {
  font-weight: normal;
}

/*
 * Remove the gap between images, videos, audio and canvas and the bottom of
 * their containers: h5bp.com/i/440
 */

audio,
canvas,
img,
svg,
video {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

// Remove default fieldset styles.
fieldset {
  @include swap_direction(border, 0);
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0);
}

// Allow only vertical resizing of textareas.
textarea {
  resize: vertical;
}

blockquote {
  margin-#{$ldirection}: 0;
  margin-#{$rdirection}: 0;
}

// special styles to share media query info to JS
head {
  // set clear on head to show Unison is set up correctly
  clear: both;
  // store hash of all breakpoints
  title {
    font-family: '#{$mq-sync}';
  }
}

body {
  @include breakpoint($bp--largest-up) {
    @include display-flex();
    min-height: 100vh;
    @include flex-direction(column);
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 100%; // IE10+
  }
}

// debug styles to see breakpoint info
body:after {
  display: none;
}

@if $debug == true {
  body:after {
    display: block;
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    width: 100%;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.8);
    text-align: center;
    color: #fff;
    @include swap_direction(padding, 10px);
    z-index: 1000;
    font-size: 12px;
    font-family: sans-serif;
  }
}

.mobile-hidden {
  display: none;
  @include breakpoint($bp--medium-up) {
    display: block;
  }
}

.pc-hidden {
  display: block;
  @include breakpoint($bp--medium-up) {
    display: none;
  }
}

.pc-hidden-inline {
  display: inline;
  @include breakpoint($bp--medium-up) {
    display: none;
  }
}
