.replenishment__products {
  .products-header {
    border-bottom: 1px solid $color--gray--lightest;
    margin-bottom: 1em;
    @include heading--small;
    @include clearfix();
    @include layout($grid--8, 1em) {
      .product-header__info {
        @include grid-span(3, 1);
      }
      .product-header__next-order-date {
        @include grid-span(2, 4);
      }
      .product-header__price {
        @include grid-span(1, 6);
      }
    }
  }
  .products-list__item {
    border-bottom: 1px solid $color--gray--lightest;
    margin-bottom: 1em;
    @include swap_direction(padding, 1em 0);
    @include clearfix();
    @include breakpoint($bp--medium-up) {
      @include layout($grid--8, 1em) {
        .product__image {
          @include grid-span(1, 1);
        }
        .product__info {
          @include grid-span(2, 2);
        }
        .product__next-order-date {
          @include grid-span(2, 4);
        }
        .product__price {
          @include grid-span(1, 6);
        }
        .product__actions {
          @include grid-span(2, 7);
          text-align: $rdirection;
        }
      }
    }
  }
}

.replenishment-detail-page {
  .frequency-table {
    @include breakpoint($bp--medium-up) {
      @include layout($grid--4) {
        .frequency-table__item {
          &:nth-of-type(3n + 1) {
            @include grid-span(1, 1);
            clear: both;
          }
          &:nth-of-type(3n + 2) {
            @include grid-span(1, 2);
          }
          &:nth-of-type(3n + 3) {
            @include grid-span(1, 3);
          }
        }
      }
    }
  }
  .address-container,
  .payment-info {
    margin-bottom: 1em;
  }
  .shipping-address__menu {
    @include clearfix();
    @include breakpoint($bp--medium-up) {
      @include layout($grid--4, 1em) {
        .address-container {
          @include grid-span(1, 1);
        }
        .address-controls {
          @include grid-span(3, 2);
        }
      }
    }
    @include breakpoint($bp--large-up) {
      @include layout($grid--4, 1em) {
        .address-controls {
          @include grid-span(2, 2);
        }
      }
    }
  }
  .payment-method__fieldset {
    @include clearfix();
    @include breakpoint($bp--medium-up) {
      @include layout($grid--4, 1em) {
        .payment-info {
          @include grid-span(1, 1);
        }
        .payment-controls {
          @include grid-span(3, 2);
        }
      }
    }
    @include breakpoint($bp--large-up) {
      @include layout($grid--4, 1em) {
        .payment-controls {
          @include grid-span(2, 2);
        }
      }
    }
  }
  .btn.form-submit {
    float: #{$rdirection};
  }
}

.replenishment-top-products__item {
  @include clearfix();
  @include breakpoint($bp--medium-up) {
    @include layout($grid--8, 1em) {
      .product__image {
        @include grid-span(1, 1);
      }
      .product__detail {
        @include grid-span(3, 2);
      }
      .product__replenishment-form {
        @include grid-span(2, 6);
      }
      .product__add {
        @include grid-span(1, 8);
      }
    }
  }
  .product--teaser {
    min-height: 100%;
  }
}

.replenishment-service {
  .form-item {
    @include layout($grid--2, 1em) {
      &:nth-of-type(2n + 1) {
        @include grid-span(1, 1);
        clear: both;
      }
      &:nth-of-type(2n + 2) {
        @include grid-span(1, 2);
      }
    }
    @include breakpoint($bp--medium-up) {
      @include layout($grid--4, 1em) {
        &:nth-of-type(2n + 1) {
          @include grid-span(3, 1);
          clear: both;
        }
        &:nth-of-type(2n + 2) {
          @include grid-span(1, 4);
        }
      }
    }
  }
}

.replenishment-page {
  @include breakpoint($bp--xlarge-down) {
    @include swap_direction(padding, 0 10px);
  }
}

// Replenishment Reskin

@if $replenishment_reskin {
  .replenishment-page__content {
    padding: 1em;
    .shipping-frequency__info {
      @include breakpoint($bp--medium-up) {
        width: 65%;
        margin: 0 auto;
      }
    }
    .replenishment-detail-page {
      border: 1px solid $color--gray--lightest;
      border-radius: 5px;
      padding: 2em;
      .replenishment__header {
        display: flex;
        flex-direction: column;
        @include breakpoint($bp--medium-up) {
          flex-direction: row;
        }
        .shippment__info {
          flex: 1;
          margin-bottom: 10px;
        }
      }
      .error_messages {
        li {
          padding-bottom: 15px;
        }
      }
      .product-detail {
        @include clearfix();
        text-align: center;
        @include breakpoint($bp--medium-up) {
          display: flex;
          flex-direction: row;
          text-align: $ldirection;
        }
        .product {
          @include breakpoint($bp--medium-up) {
            &__image {
              flex: 1;
              padding-#{$rdirection}: 1.5em;
            }
            &__info {
              flex: 5;
            }
            &__price {
              flex: 2;
            }
            &__frequency {
              flex: 3;
            }
          }
          &__swatch {
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 50px;
            position: relative;
            top: 3px;
          }
          &__size,
          &__shade {
            padding-top: 5px;
          }
          &__price {
            padding: 15px 0;
            font-size: 20px;
          }
        }
        border: 1px solid $color--gray--lightest;
        padding: 1.5em;
        border-radius: 5px;
      }
      .account-detail {
        @include clearfix();
        display: flex;
        flex-direction: column;
        @include breakpoint($bp--medium-up) {
          flex-direction: row;
        }
        .shipping-address,
        .payment-info {
          flex: 1;
          border: 1px solid $color--gray--lightest;
          border-radius: 5px;
          padding: 1.5em;
          margin-bottom: 1em;
          &__header {
            margin-bottom: 15px;
          }
          &__info {
            margin: 15px 0;
          }
        }
        .shipping-address {
          @include breakpoint($bp--medium-up) {
            margin-#{$rdirection}: 20px;
          }
        }
        .payment-method {
          &__info {
            margin: 15px 0;
          }
        }
      }
      .btn {
        width: 100%;
        margin: 5px 0;
        @include breakpoint($bp--medium-up) {
          width: auto;
          margin: 0;
        }
        &--disabled {
          pointer-events: none;
          background: $color--gray--light;
          &:hover {
            text-decoration: none;
          }
        }
        &--med {
          @include breakpoint($bp--medium-up) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .replenish-send,
  .replenish-skip,
  .replenish-cancel {
    &-confirm {
      &__header {
        font-size: 24px;
      }
      &__link {
        &:first-child {
          margin-#{$rdirection}: 25px;
        }
      }
    }
  }
}
