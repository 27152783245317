.egiftcard-retrieve {
  &__panel {
    &--form {
      float: #{$ldirection};
      @include swap_direction(padding, 0 2rem 0 1.5rem);
      margin-top: 0.25rem;
      @include breakpoint($bp--largest-up) {
        margin-top: 0;
      }
      .retrieve-form {
        &--title {
          @include h4();
          padding-bottom: 0.85rem;
        }
        &--form {
          margin-bottom: 2.75rem;
          @include breakpoint($bp--medium-up) {
            width: 24.25em;
          }
          .form-submit {
            width: 100%;
          }
        }
        &--text {
          margin-top: 1em;
        }
      }
    }
  }
}

.egiftcard-redeem {
  .redeem-info {
    background-color: $color--gray--lighter;
    &__card {
      width: 100%;
      @include swap_direction(margin, 0 auto);
      @include swap_direction(padding, 3.75rem 2rem 1rem);
      min-height: 41em;
      color: $color--white;
      font-size: 0.85em;
      @include breakpoint($bp--largest-up) {
        width: 58%;
      }
      .store-card {
        &__info {
          @include swap_direction(margin, 0 auto);
          text-align: center;
          font-family: $ano-bold_regular-font;
          text-transform: uppercase;
          font-size: 1.35rem;
          & .sender {
            &__list {
              margin-bottom: 0.5em;
              &--item {
                display: inline;
                text-transform: uppercase;
                margin-#{$rdirection}: 1rem;
                &:last-child {
                  margin-#{$rdirection}: 0;
                }
              }
            }
            &__message {
              clear: both;
              margin-bottom: 1em;
            }
          }
        }
        &__barcode {
          background-color: $color--white;
          color: $color--black;
          max-width: 100%;
          @include swap_direction(padding, 1.67em 0);
          text-align: center;
          @include swap_direction(margin, 0 auto 5em);
          @include breakpoint($bp--largest-up) {
            max-width: 24.41rem;
          }
          & .barcode {
            &__barcode {
              @include swap_direction(margin, 0 auto 1.67em);
            }
            &__hdr {
              @include swap_direction(margin, 0);
            }
            &__number {
              margin-bottom: 0.3rem;
              &:last-child {
                @include swap_direction(margin, 0);
              }
            }
          }
        }
        &__links {
          @include swap_direction(margin, 0 auto 4em);
          @include breakpoint($bp--largest-up) {
            max-width: 43rem;
          }
          &--link {
            border: 1px solid $color--white;
            background-color: transparent;
            color: $color--white;
            min-width: 100%;
            height: 3.34rem;
            line-height: 3.34rem;
            margin-bottom: 1.67rem;
            @include breakpoint($bp--largest-up) {
              min-width: 20rem;
              margin-bottom: 0;
              min-height: 5rem;
              line-height: 4.75rem;
              &:last-child {
                float: #{$rdirection};
              }
            }
          }
        }
      }
    }
    &__about {
      background-color: $color--white;
      @include swap_direction(margin, 3.33rem auto 0);
      @include swap_direction(padding, 0 1.3rem);
      &--hdr {
        float: #{$ldirection};
        @include swap_direction(margin, 0 8rem 1.67rem 0);
      }
      &--txt {
        float: #{$ldirection};
        max-width: 100%;
        &:last-child {
          margin-bottom: 4rem;
        }
        @include breakpoint($bp--largest-up) {
          max-width: 67%;
        }
      }
    }
  }
}

.egiftcard-print {
  @include swap_direction(margin, 3rem auto 0);
  width: 70rem;
  text-align: center;
  font-size: 1.3rem;
  &__barcode {
    border-bottom: 1px solid $color--black;
    padding-bottom: 5.8rem;
    .barcode {
      &__site-logo {
        margin-bottom: 4.5em;
      }
      &__barcode {
        @include swap_direction(margin, 0 auto);
      }
      &__hdr {
        margin-bottom: 0;
      }
    }
  }
  &__card {
    font-family: $ano-bold_regular-font;
    border-bottom: 1px solid $color--black;
    @include swap_direction(padding, 3rem 0 2rem);
    text-transform: uppercase;
    font-size: 2rem;
    .list {
      &__item {
        display: inline;
        &--to {
          margin-#{$rdirection}: 1em;
        }
        &--amount {
          display: block;
        }
      }
    }
    .card-info {
      &__message {
        @include swap_direction(margin, 2.5rem 0 1.5rem);
      }
    }
  }
  &__instructions {
    @include swap_direction(margin, 2rem 0 0);
    @include swap_direction(padding, 3rem);
  }
}

@include breakpoint($bp--medium-down) {
  .giftcard_landing_content {
    @include breakpoint($width-medium) {
      .singlerow-blocks-formatter__layout {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        .grid--item:nth-child(1) {
          order: 1;
        }
        .grid--item:nth-child(2) {
          order: 3;
        }
        .grid--item:nth-child(3) {
          order: 2;
        }
        .grid--item:nth-child(4) {
          order: 4;
        }
      }
    }
  }
}
