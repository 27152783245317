.past-purchases {
  .products {
    @include pyramid-grid(1em, 'li.product');
    @include breakpoint($bp--small-down) {
      li.product:nth-child(4) {
        display: none;
      }
    }
  }
  .product__footer {
    .product__add-to-bag {
      @include breakpoint($bp--small-down) {
        @include swap_direction(margin, 25px 0 0 0);
      }
    }
    .product-item {
      &__out-of-stock {
        height: 30px;
        @include breakpoint($bp--xlarge-up) {
          position: absolute;
          bottom: 0px;
          right: 0px;
          @include swap_direction(padding, 0);
          top: 48px;
        }
        @include breakpoint($bp--medium-down) {
          height: 30px;
        }
        @include breakpoint($bp--small-down) {
          position: relative;
          bottom: 85px;
          @include swap_direction(margin, 0);
        }
      }
    }
  }
  .past-purchases__instructions {
    text-align: center;
    padding-bottom: 15px;
  }
  .past-purchases__no-purchases-text {
    padding-bottom: 15px;
  }
  .product-item__replenishment {
    margin-bottom: 1em;
  }
  .account-order-history & {
    border-top: 1px solid $color--gray--lightest;
    margin-bottom: 1em;
    .past-purchases__item {
      @include layout(2 2 2 2 1, 1em) {
        .created-date {
          @include grid-span(1, 1);
        }
        .trans-id {
          @include grid-span(1, 2);
        }
        .order-status {
          @include grid-span(1, 3);
        }
        .order-cost {
          @include grid-span(1, 4);
        }
        .view-details {
          @include grid-span(1, 5);
        }
      }
      @include breakpoint($bp--medium-down) {
        @if $fonts_update == true {
          font-size: 10px;
        } @else {
          font-size: 0.75rem;
        }
      }
      border-bottom: 1px solid $color--gray--lightest;
      @include swap_direction(padding, 1.5em);
      @include breakpoint($bp--xsmall-down) {
        @include swap_direction(padding, 1.5em 0.5em);
      }
      .grid--item {
        line-height: 2em;
      }
      .tracking-link {
        clear: both;
        width: 22.22222%;
        float: #{$ldirection};
        margin-#{$rdirection}: -100%;
        margin-#{$ldirection}: 44.44444%;
        padding-#{$rdirection}: 1em;
      }
      .view-details-link {
        @include swap_direction(border, none);
        cursor: pointer;
        background-color: transparent;
        line-height: 0;
        margin: -4px 0;
        i {
          @include icon(arrow--down);
          font-size: 2em;
          @if $fonts_update == true {
            &:before,
            &:after {
              color: $color--gray;
            }
          }
        }
      }
      &.active {
        background-color: $color--gray--lightest;
        i {
          @include icon(arrow--up);
        }
        .trans-id a {
          border-color: $color--gray--lighter;
        }
      }
      .order-status {
        margin-#{$ldirection}: 47%;
      }
      .order-cost,
      .view-details {
        text-align: #{$rdirection};
      }
      .product-header {
        border-bottom: 1px solid $color--gray--lightest;
        margin-bottom: 1em;
        @include swap_direction(padding, 1.3rem 0);
        @include clearfix();
        @include layout(5 3 3 2 5, 1em) {
          .product {
            @include grid-span(1, 1);
          }
          .status {
            @include grid-span(1, 2);
          }
          .quantity {
            @include grid-span(1, 3);
            @include breakpoint($bp--xsmall-down) {
              margin-#{$ldirection}: 43%;
            }
          }
          .rating {
            @include grid-span(1, 4);
            @include breakpoint($bp--xsmall-down) {
              margin-#{$ldirection}: 60%;
            }
          }
          .price {
            @include grid-span(1, 5);
          }
          h6 {
            @include breakpoint($bp--medium-down) {
              font-size: 1.1rem;
            }
          }
        }
        @include breakpoint($bp--small-down) {
          .at-h6__typography {
            @if $fonts_update == true {
              font-size: 12px;
            }
          }
        }
      }
      .product--teaser {
        @include swap_direction(padding, 2em 0);
        @include swap_direction(border, none);
        min-height: 0;
        @include clearfix();
        @include layout(2 3 3 3 2 2 3, 1em) {
          h3 {
            @include breakpoint($bp--medium-down) {
              font-size: 1.1rem;
              min-height: 0;
            }
            @include breakpoint($bp--xsmall-down) {
              word-break: break-word;
              @include swap_direction(padding, 0);
              @if $fonts_update == true {
                font-size: 11px;
              }
            }
          }
          .product__image {
            @include grid-span(1, 1);
          }
          .product__detail {
            @include grid-span(1, 2);
          }
          .product__status {
            @include grid-span(1, 3);
          }
          .product__qty {
            @include grid-span(1, 4);
            @include breakpoint($bp--xsmall-down) {
              margin-#{$ldirection}: 47%;
            }
          }
          .product__rate {
            @include grid-span(1, 5);
            word-break: break-word;
            @include breakpoint($bp--medium-down) {
              @if $fonts_update == true {
                text-align: center;
              }
            }
          }
          .product__price {
            @include grid-span(1, 6);
            @include breakpoint($bp--medium-down) {
              @if $fonts_update == true {
                font-size: 10px;
              } @else {
                font-size: 1.1rem;
              }
            }
          }
          .product__add {
            @include grid-span(1, 7);
            .product__add-to-bag {
              @include breakpoint($bp--medium-down) {
                width: 90%;
                @if $fonts_update == true {
                  font-size: 10px;
                } @else {
                  font-size: 1.1rem;
                }
                @include swap_direction(padding, 0);
                height: 3em;
                line-height: 3em;
                @include breakpoint($bp--xsmall-down) {
                  width: 94%;
                  float: #{$rdirection};
                }
              }
            }
          }
        }
      }
    }
  }
}
