/* ==========================================================================
   Components Organisms - Header
   ========================================================================== */

// This is the fixed portion of the header that moves with the page
// .site-header actually wraps this and is static, pushing content down the page
.site-header__fixed-wrapper {
  @include reverse;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  height: $site-header-height;
  .sg-pattern-body & {
    position: relative;
  }
  .mobile-checkout-back {
    @include breakpoint($bp--largest-up) {
      display: none;
    }
  }
}

// Header Grid - keeping styles out of main tree as those will be refactored soon
.site-header {
  position: relative;
  @include layout($grid--12) {
    &__prefix {
      @include grid-span(4, 5);
    }
    &__menu {
      @include grid-span(4, 1);
    }
    &__suffix {
      @include grid-span(4, 9);
    }
    @include breakpoint($bp--small-up) {
      &__prefix {
        @include grid-span(6, 4);
      }
      &__menu {
        @include grid-span(3, 1);
      }
      &__suffix {
        @include grid-span(3, 10);
      }
    }
    @include breakpoint($bp--largest-up) {
      &__prefix {
        @include grid-span(2, 1);
      }
      &__menu {
        @include grid-span(7, 3);
      }
      &__suffix {
        @include grid-span(3, 10);
      }
    }
  }
}

body.ie-11 {
  .site-header {
    .site-header__prefix {
      .site-logo {
        .site-logo__image {
          background-size: auto;
        }
      }
    }
  }
}

.site-header {
  @include antialiased;
  @include clearfix;
  font-family: $headline-font-family;
  height: $site-header-height;
  min-height: $site-header-height;
  @if $fonts_update == true {
    font-family: $headline-ano-black-font;
  }
  &--has-sec-nav {
    height: (2 * $site-header-height);
  }
  .has-subnav & {
    @include breakpoint($bp--xlarge-up) {
      height: (2 * $site-header-height);
    }
  }
  #toolbar {
    z-index: 10000;
  }
  .site-header__container {
    height: $site-header-height;
    text-align: center;
    background-color: $color--black;
    @include breakpoint($bp--largest-up) {
      text-align: #{$ldirection};
    }
  }
  .site-header__prefix {
    height: $site-header-height;
    vertical-align: top;
    .site-logo {
      @include swap_direction(margin, 0);
      .site-logo__image {
        background: no-repeat scroll 0 50% / 100% auto transparent;
        background-size: auto 20%;
        color: $color--white;
        display: inline;
        height: $site-header-height;
        @include swap_direction(margin, 0 auto);
        max-width: 119px;
        text-indent: -9999px;
        width: 100%;
        @include set-link-colors(
          $color--black,
          $color--black,
          $color--black,
          $color--black,
          $color--black
        );
        @include single-transition($property: border-bottom-color);
        border-bottom: none;
        @include breakpoint($bp--large) {
          width: 90%;
        }
        @include breakpoint($bp--largest-up) {
          @include swap_direction(margin, 0 0 0 12px);
        }
        svg {
          fill: $color--white;
          width: 112px;
          height: 12px;
          .gnav-transparent--light:not(.site-header-formatter--sticky) & {
            fill: $color--white;
          }
          .gnav-transparent--dark:not(.site-header-formatter--sticky) & {
            fill: $color--black;
          }
        }
        &.no-svg {
          display: none;
          visibility: hidden;
        }
        html.no-svg & {
          display: none;
          visibility: hidden;
          &.no-svg {
            display: block;
            visibility: visible;
          }
        }
      }
    }
  }
  .site-header__extra {
    @include breakpoint($bp--largest-up) {
      display: inline;
      float: #{$ldirection};
      text-align: center;
      width: 2.5%;
    }
    #mobile-nav {
      @include animation('all 4s ease infinite');
      @include transform(rotate(0deg));
      display: inline;
      .viewcart &,
      .checkout & {
        display: none;
        @include breakpoint($bp--large-up) {
          display: inline;
        }
      }
      float: #{$ldirection};
      font-size: 15px;
      height: $site-header-height;
      line-height: $site-header-height;
      @include swap_direction(padding, 0 10px);
      vertical-align: top;
      @include unset-underline-links();
      @include breakpoint($bp--largest-up) {
        display: none;
      }
      .sidr-open & {
        @include transform(rotate(90deg));
      }
      svg {
        fill: $color--white;
        width: 23px;
        height: 15px;
        .gnav-transparent--light:not(.site-header-formatter--sticky) & {
          fill: $color--white;
        }
        .gnav-transparent--dark:not(.site-header-formatter--sticky) & {
          fill: $color--black;
        }
      }
    }
    .mobile-checkout-back {
      @include breakpoint($bp--largest-up) {
        display: none;
      }
      font-size: 15px;
      color: $color--gray--white;
      text-align: left;
      margin: 18px 0 0 10px;
      .confirmation-page & {
        display: none;
      }
    }
  }
  .site-navigation {
    display: none;
    @include breakpoint($bp--largest-up) {
      display: inline;
      float: #{$ldirection};
      width: 97%;
    }
    ul {
      display: block;
      width: 100%;
      @include clear-drupal-wrappers();
      &.site-navigation__menu.main-menu {
        height: 60px;
        overflow: hidden;
      }
    }
  }
  li.site-navigation__item,
  li.site-navigation__more {
    display: inline;
    float: #{$ldirection};
    font-size: 17px;
    height: $site-header-height;
    line-height: $site-header-height;
    @include swap_direction(padding, 0 15px);
    position: relative;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    @if $fonts_update == true {
      font-size: 13px;
    }
    @include breakpoint($bp--xxlarge-up) {
      @include swap_direction(padding, 0 25px);
    }
    a {
      border-bottom: 1px solid transparent;
      vertical-align: middle;
      &:hover {
        border-color: $color--white;
      }
    }
    &.is-dropdown,
    &.is-carousel {
      ul {
        display: none;
      }
      .site-navigation__carousel {
        display: none;
        ul {
          display: block;
        }
        border-bottom: $color--gray--lightest 1px solid;
      }
      .site-navigation__dropdown {
        display: none;
        border-bottom: $color--gray 1px solid;
        height: $site-header-height + 1;
        .responsive-container {
          > ul {
            display: flex;
            @if $gnav_update == true {
              @include breakpoint($bp--large-up) {
                justify-content: center;
              }
            }
          }
        }
      }
      &.fit-main-menu {
        .site-navigation__menu {
          @if $gnav_update == true {
            @include breakpoint($bp--large-up) {
              margin-#{$ldirection}: calc(172px / 2);
            }
          }
        }
      }
      &.align-submenu-#{$ldirection} {
        .site-navigation__dropdown .site-navigation__item .site-navigation__link {
          @include breakpoint($bp--large-up) {
            text-align: $ldirection;
          }
        }
      }
      &.align-submenu-center {
        .site-navigation__dropdown .site-navigation__item .site-navigation__link {
          @include breakpoint($bp--large-up) {
            text-align: center;
          }
        }
      }
      &.align-submenu-#{$rdirection} {
        .site-navigation__dropdown .site-navigation__item .site-navigation__link {
          @include breakpoint($bp--large-up) {
            text-align: $rdirection;
          }
        }
      }
      &.is-active {
        > a {
          border-color: $color--white;
        }
      }
      .slick-slide {
        overflow: hidden;
      }
      .site-navigation__dropdown,
      .site-navigation__carousel {
        background: $color--white;
        #{$ldirection}: 0;
        @include swap_direction(margin, -1px 0 0 0);
        position: fixed;
        top: auto;
        width: 100%;
        zoom: 1;
        z-index: 999997;
        .site-navigation__item {
          @include swap_direction(padding, 0);
          position: relative;
          flex: auto;
          &:last-child {
            .site-navigation__submenu {
              #{$rdirection}: 0;
            }
          }
          .site-navigation__link {
            border-bottom: none;
            color: $color--black;
            background: $color--white;
            display: block;
            height: $site-header-height;
            line-height: $site-header-height;
            @include swap_direction(padding, 0 15px);
            width: auto;
            @if $gnav_update == true {
              @include breakpoint($bp--large-up) {
                text-align: center;
              }
            }
          }
          .site-navigation__submenu {
            @include swap_direction(padding, 15px 0 0 15px);
            background-color: $color--gray--lighter;
            display: none;
            line-height: normal;
            min-height: 245px;
            position: absolute;
            width: auto;
            z-index: 999997;
            @if $gnav_update == true {
              background-color: $color--black;
              @include breakpoint($bp--large-up) {
                min-width: 100%;
                min-height: auto;
              }
            }
          }
          &.is-active,
          &:hover {
            > a {
              background-color: $color--gray--lighter;
              border-bottom: none;
            }
            .site-navigation__submenu {
              display: block;
            }
            ul:not(.contextual-links) {
              display: block;
            }
          }
          @if $fonts_update == true {
            &.subnav {
              .site-navigation__link {
                font-size: 14px;
              }
            }
          }
          @if $gnav_update == true {
            &.is-active {
              > a {
                background-color: $color--white;
              }
            }
          }
        }
        .site-navigation__submenu-col {
          display: inline-block;
          @include swap_direction(padding, 0 15px 0 15px);
          vertical-align: top;
          @include breakpoint($bp--xxlarge-up) {
            min-width: 160px;
          }
          &:first-child {
            padding-#{$ldirection}: 0;
          }
          h3 {
            color: $color--black;
            font-size: 15px;
            @include swap_direction(margin, 0 0 5px 0);
            @if $fonts_update == true {
              font-size: 12px;
            }
          }
          ul:not(.contextual-links) {
            @include swap_direction(margin, 0 0 15px 0);
            background: inherit;
          }
          li {
            display: block;
            float: none;
            font-size: 15px;
            height: auto;
            line-height: normal;
            @include swap_direction(margin, 0 0 5px 0);
            @include swap_direction(padding, 5px);
          }
          a {
            color: $color--gray;
            font-size: 15px;
            @if $gnav_update == true {
              color: $color--white;
            }
            &:hover,
            &:active,
            &:focus {
              border-bottom-color: $color--gray;
              @if $gnav_update == true {
                border-bottom-color: $color--white;
              }
            }
            @if $fonts_update == true {
              font-size: 12px;
            }
          }
          .no-desktop {
            display: block;
            @include breakpoint($bp--largest-up) {
              display: none;
            }
          }
          .no-mobile {
            display: none;
            @include breakpoint($bp--largest-up) {
              display: block;
            }
          }
        }
        .site-navigation__submenu-tout {
          background: $color--gray--light;
          height: 100px;
          @include swap_direction(margin, 0 0 15px 0);
          position: relative;
          width: 185px;
          img {
            display: block;
            height: auto;
            max-height: 100px;
            max-width: 100%;
            width: 100%;
          }
          &-label {
            padding-top: 20%;
            color: $color--white;
            height: 125px;
            #{$ldirection}: 0;
            line-height: 1em;
            position: absolute;
            text-align: center;
            top: 0;
            width: 185px;
            white-space: normal;
          }
          > a {
            display: block;
          }
        }
      }
      .site-navigation__carousel {
        .contextual-links-container {
          display: none;
        }
        .block-image-hover {
          padding-top: 52px;
          &__title {
            background: $color--white;
            position: absolute;
            top: 0;
            white-space: normal;
            width: 100%;
            z-index: 100;
          }
          &__image {
            position: relative;
            top: 0;
            img {
              display: block;
              height: auto;
              width: 100%;
            }
          }
          &__content {
            height: 100%;
            @include swap_direction(padding, 52px 0 0 0);
            top: 0;
            width: 100%;
          }
          &__excerpt {
            line-height: normal;
            @include swap_direction(margin, 0);
            @include swap_direction(padding, 20px);
            white-space: normal;
          }
          &__link {
            line-height: normal;
            @include swap_direction(padding, 0 20px 0 0);
          }
        }
      }
    }
    &.site-navigation__more {
      display: none;
      position: relative;
      &.active {
        .icon--caret-down {
          content: map-get($icons, caret-down);
        }
      }
      ul {
        background: $color--black;
        display: none;
        #{$ldirection}: 5px;
        @include swap_direction(padding, 0 0 7px 0);
        position: absolute;
        width: auto;
        z-index: 999998;
        > li {
          @include swap_direction(margin, 0 0 7px 0);
          @include swap_direction(padding, 0 15px);
          float: none;
          height: auto;
          line-height: 1.4;
          @if $fonts_update == false {
            font-size: 17px;
          }
        }
      }
    }
  }
  .site-header__tools {
    font-size: 17px;
    position: relative;
    @if $fonts_update == true {
      font-size: 12px;
    }
    @include breakpoint($bp--medium-up) {
      font-size: 13px;
      @include swap_direction(margin, 0);
      @if $fonts_update == true {
        font-size: 12px;
        .site-email-signup__link-icon {
          font-size: 13px;
        }
      }
    }
    @include breakpoint($bp--large-up) {
      font-size: 15px;
      @if $fonts_update == true {
        font-size: 12px;
        .site-email-signup__link-icon {
          font-size: 15px;
        }
      }
    }
    @include breakpoint($bp--xlarge-up) {
      font-size: 17px;
      @if $fonts_update == true {
        font-size: 12px;
        .site-email-signup__link-icon {
          font-size: 17px;
        }
      }
    }
    > .block {
      @include breakpoint($bp--largest-up) {
        display: inline;
        float: #{$rdirection};
      }
    }
    a {
      @include unset-underline-links();
      @if $fonts_update == true {
        text-transform: none;
      }
    }
    .block-template-site-email-signup-v1 {
      display: none;
      @include breakpoint($bp--largest-up) {
        display: inline-block;
      }
    }
    .site-email-signup {
      height: $site-header-height;
      @include swap_direction(margin, 0);
      min-width: 45px;
      position: relative;
      vertical-align: top;
      @include breakpoint($bp--largest-up) {
        @include swap_direction(padding, 0 25%);
      }
      &__link {
        line-height: $site-header-height;
        text-align: center;
        &-icon {
          fill: $color--white;
          width: 22px;
          height: 20px;
          .gnav-transparent--light:not(.site-header-formatter--sticky) & {
            fill: $color--white;
          }
          .gnav-transparent--dark:not(.site-header-formatter--sticky) & {
            fill: $color--black;
          }
        }
      }
      &__contents {
        @include body-mono();
        background: $color--white;
        color: $color--black;
        visibility: hidden;
        opacity: 0;
        width: 322px;
        position: absolute;
        #{$ldirection}: -81px;
        text-align: #{$ldirection};
        top: auto;
        z-index: 999999;
        a:not(.btn) {
          @include set-link-colors();
        }
        &-header {
          @include clearfix();
          line-height: 30px;
          @include swap_direction(padding, 15px 15px 5px 15px);
        }
        &-close {
          display: inline;
          float: #{$rdirection};
          font-size: 23px;
          i {
            vertical-align: top;
          }
        }
        &-title {
          @include h5();
          line-height: 1;
          @include swap_direction(margin, 0);
          a {
            @if $fonts_update == true {
              @include h4();
            }
          }
        }
        &-form {
          @include swap_direction(padding, 0 15px 15px 15px);
          a:not(.btn) {
            @include set-underline-links();
          }
          p {
            @include swap_direction(margin, 0 0 10px 0);
          }
          .intro {
            width: 96%;
          }
          .error {
            display: block;
          }
          .form-item {
            @include swap_direction(margin, 0 0 15px 0);
          }
          .form-type-checkbox,
          .form-type-label {
            margin-bottom: 0;
            display: none;
          }
          .picker-checkbox {
            display: block;
            @include swap_direction(margin, 0);
          }
          .picker-handle {
            float: #{$ldirection};
          }
          .picker-label {
            text-transform: none;
            width: 96%;
            p {
              @include swap_direction(margin, 0 0 15px 0);
            }
          }
        }
        &-submit {
          @include btn;
          @include btn--full;
        }
        &-thank-you {
          &__title {
            @include swap_direction(margin, 0 0 15px 0);
          }
          &__copy {
            line-height: 1.5;
          }
        }
      }
      &.active {
        background: $color--white;
        color: $color--black;
        .site-email-signup__link,
        .site-email-signup__link-icon {
          color: $color--black;
          fill: $color--black;
        }
        .site-email-signup__contents {
          opacity: 1;
          visibility: visible;
          max-height: 430px;
          overflow-y: auto;
        }
      }
    }
    .block-template-site-my-shades-v1 {
      display: none;
      height: 60px;
      max-width: 36px;
      vertical-align: top;
      width: 16%;
      @include breakpoint($bp--largest-up) {
        display: none;
        float: #{$rdirection};
      }
      .my-shades {
        width: 100%;
      }
      .site-my-shades--1,
      .site-my-shades--2,
      .site-my-shades--3 {
        width: 100%;
      }
      &[data-my-shades='1'] {
        .site-my-shades--1 {
          height: 100%;
        }
        .site-my-shades--2 {
          display: none;
        }
        .site-my-shades--3 {
          display: none;
        }
      }
      &[data-my-shades='2'] {
        .site-my-shades--1 {
          height: 50%;
        }
        .site-my-shades--2 {
          height: 50%;
        }
        .site-my-shades--3 {
          display: none;
        }
      }
      &[data-my-shades='3'] {
        .site-my-shades--1 {
          height: 60%;
        }
        .site-my-shades--2 {
          height: 20%;
        }
        .site-my-shades--3 {
          height: 20%;
        }
      }
    }
    .block-template-site-my-mac-v1 {
      display: inline;
      float: #{$rdirection};
      position: absolute;
      #{$rdirection}: 50px;
      @include breakpoint($bp--largest-up) {
        position: relative;
        #{$rdirection}: auto;
        width: 41%;
      }
    }
    .site-my-mac {
      height: $site-header-height;
      @include swap_direction(padding, 0 16.5%);
      position: relative;
      vertical-align: top;
      @include breakpoint($bp--largest-up) {
        background-color: $color--gray--black;
      }
      &__link {
        @include heading-mono;
        display: block;
        .viewcart &,
        .checkout & {
          display: none;
          @include breakpoint($bp--large-up) {
            display: inline;
          }
        }
        font-size: 10px;
        height: $site-header-height;
        line-height: normal;
        @include swap_direction(padding, 18px 0 0 0);
        text-align: #{$ldirection};
        text-transform: uppercase;
        white-space: nowrap;
        @if $fonts_update == true {
          font-family: $helvetica-font;
          font-size: 11px;
        }
        br {
          display: block;
          @include breakpoint($bp--xlarge-up) {
            display: none;
          }
        }
        .first-name {
          display: none;
          white-space: normal;
          @include breakpoint($bp--xlarge-up) {
            display: block;
          }
        }
      }
      &__contents {
        @include body-mono();
        background: $color--white;
        color: $color--black;
        visibility: hidden;
        opacity: 0;
        width: 322px;
        position: absolute;
        #{$rdirection}: 0;
        text-align: #{$ldirection};
        top: auto;
        z-index: 999999;
        a:not(.btn) {
          @include set-link-colors();
        }
        &-header {
          @include clearfix();
          line-height: 30px;
          @include swap_direction(padding, 15px 15px 5px 15px);
        }
        &-close {
          display: inline;
          float: #{$rdirection};
          font-size: 23px;
          i {
            vertical-align: top;
          }
        }
        &-title {
          @include h5();
          line-height: 1;
          @include swap_direction(margin, 0);
          @if $fonts_update == true {
            @include h4();
            @include breakpoint($bp--large-up) {
              font-size: $h4-new-size;
            }
          }
          &__alt {
            display: none;
          }
        }
        &-signin {
          @include swap_direction(padding, 0 15px 15px 15px);
          .error {
            text-transform: uppercase;
          }
          a:not(.btn) {
            @include set-underline-links();
          }
          .registration {
            @include swap_direction(margin, 0 0 15px 0);
          }
          .form-item {
            @include swap_direction(margin, 0 0 15px 0);
          }
          .password-request {
            @include swap_direction(margin, 0 0 15px 0);
            text-align: #{$rdirection};
          }
        }
        &-submit {
          @include btn;
          @include btn--full;
        }
        &-signed_in {
          display: none;
          a:not(.btn) {
            @include set-underline-links();
          }
        }
        &-account {
          @include swap_direction(padding, 0 15px 10px 15px);
          li {
            display: block;
            @include swap_direction(margin, 0 0 5px 0);
          }
        }
        &-footer {
          border-top: 1px solid $color--gray--lighter;
          @include swap_direction(padding, 15px);
          position: relative;
          &-title {
            @include h5();
            line-height: 1;
          }
          &.has-link {
            .site-my-mac__contents-footer-title {
              @include swap_direction(margin, 0 0 45px 0);
            }
            &:hover {
              @include reverse;
              border-top-color: $color--black;
            }
          }
          &-link {
            bottom: 15px;
            position: absolute;
          }
          &__loyalty {
            &.has-link {
              .site-my-mac__contents-footer-title {
                @include h6();
                @include swap_direction(margin, 0 0 15px 0);
              }
              .site-my-mac__contents-footer-copy {
                @include swap_direction(margin, 0 0 60px 0);
              }
            }
          }
        }
        form {
          .picker-checkbox {
            @include breakpoint($bp--large-up) {
              width: 100%;
            }
            .picker-handle {
              @include breakpoint($bp--large-up) {
                @include swap_direction(margin, 0 4px 0 0);
              }
            }
            .picker-label {
              @include breakpoint($bp--large-up) {
                float: none;
                text-align: #{$ldirection};
              }
            }
          }
        }
      }
      &.active {
        background: $color--white;
        color: $color--black;
        .site-my-mac__link {
          color: $color--black;
        }
        .site-my-mac__contents {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .block-template-site-bag-v1 {
      display: inline;
      float: #{$rdirection};
    }
    .site-bag {
      height: $site-header-height;
      @include swap_direction(margin, 0);
      min-width: 50px;
      position: relative;
      vertical-align: top;
      @include unset-underline-links();
      @include breakpoint($bp--medium-up) {
        @include swap_direction(padding, 0 20%);
      }
      @include breakpoint($bp--large-up) {
        @include swap_direction(padding, 0 22%);
      }
      @include breakpoint($bp--xlarge-up) {
        @include swap_direction(padding, 0 25%);
      }
      &__link {
        display: inline-block;
        .viewcart &,
        .checkout & {
          display: none;
          @include breakpoint($bp--large-up) {
            display: inline-block;
          }
        }
        width: 100%;
        font-size: 10px;
        line-height: 62px;
        text-align: center;
        text-transform: uppercase;
        svg {
          fill: $color--white;
          width: 21px;
          height: 31px;
          .gnav-transparent--light:not(.site-header-formatter--sticky) & {
            fill: $color--white;
          }
          .gnav-transparent--dark:not(.site-header-formatter--sticky) & {
            fill: $color--black;
          }
        }
        &-icon {
          font-size: 31px;
          #{$ldirection}: 49%;
          @include swap_direction(margin, 0 0 0 -10px);
          position: absolute;
          top: 12px;
        }
        &-count {
          color: $color--black;
          display: block;
          height: 60px;
          text-align: center;
          position: absolute;
          top: 4px;
          #{$rdirection}: 23px;
          .gnav-transparent--light:not(.site-header-formatter--sticky) & {
            fill: $color--white;
          }
          .gnav-transparent--dark:not(.site-header-formatter--sticky) & {
            fill: $color--black;
          }
          @include breakpoint($bp--largest-up) {
            width: auto;
          }
        }
      }
      &__contents {
        @include body-mono();
        background: $color--white;
        @include swap_direction(border, 1px solid $color--gray--lighter);
        color: $color--black;
        display: none;
        width: 322px;
        position: absolute;
        #{$rdirection}: -1px;
        top: auto;
        text-align: left;
        z-index: 999999;
        a:not(.btn) {
          @include set-link-colors();
        }
        &-header {
          @include clearfix();
          line-height: 30px;
          @include swap_direction(padding, 15px);
        }
        &-close {
          display: inline;
          float: #{$rdirection};
          font-size: 30px;
          i {
            vertical-align: top;
          }
        }
        &-edit {
          display: inline;
          float: #{$rdirection};
          @include swap_direction(margin, 0 15px);
          text-decoration: underline;
        }
        &-title {
          @include h5();
          @include swap_direction(margin, 0);
          &__alt {
            display: none;
          }
        }
        &-products {
          overflow: auto;
        }
        .cart-product {
          @include clearfix();
          @include swap_direction(padding, 0 15px 15px 15px);
          &-img {
            display: inline;
            float: #{$ldirection};
            @include swap_direction(margin, 0 15px 0 0);
            width: 124px;
          }
          &-column {
            display: inline;
            float: #{$ldirection};
            width: 132px;
          }
          &-info {
            min-height: 84px;
            @include swap_direction(padding, 20px 0 0 0);
          }
          .cart-product-kit-shades {
            margin-top: 20px;
          }
          &-name {
            @include h6();
            line-height: 1;
            @include swap_direction(margin, 0);
          }
          &-shade,
          &-preorder {
            @include swap_direction(padding, 10px 0);
            text-transform: uppercase;
          }
          &-preorder {
            font-weight: bold;
          }
          &-price {
            @include h6();
            line-height: 1;
            @include swap_direction(margin, 0 0 10px 0);
            &--sale {
              color: $color--red;
            }
            &--non-sale {
              text-decoration: line-through;
            }
          }
          &-button {
            @include btn;
            @include btn--full;
          }
          &-quantity {
            @include swap_direction(padding, 10px 0);
          }
        }
        &-footer {
          border-top: 1px solid $color--gray--lighter;
          @include swap_direction(padding, 15px);
        }
        &-subtotal {
          @include h6();
          @include swap_direction(margin, 0);
          text-align: #{$rdirection};
          &__title {
            display: inline;
            float: #{$ldirection};
            text-align: #{$ldirection};
          }
          &__value {
          }
        }
        &-checkout {
          @include btn;
          @include btn--full;
          @include swap_direction(margin, 15px 0);
        }
        &-message {
          @include swap_direction(margin, 0);
          text-align: center;
          text-transform: uppercase;
        }
        &-recommendations {
          border-top: 1px solid $color--gray--lighter;
          display: none;
          @include swap_direction(padding, 0 15px);
          @include breakpoint($bp--xlarge-up) {
            display: block;
          }
          &__title {
            @include h5();
            cursor: pointer;
            line-height: 1;
            @include swap_direction(margin, 0);
            @include swap_direction(padding, 15px 0);
            &-toggle {
              @include icon(arrow--down);
              display: inline;
              float: #{$rdirection};
              &:before {
                vertical-align: top;
              }
            }
          }
          &-carousel {
            display: none;
            position: relative;
            .slick-slider {
              @include swap_direction(margin, 0);
            }
            .cart-product {
              @include swap_direction(padding, 0 0 15px 0);
              &-column {
                width: 149px;
              }
            }
          }
          &-carousel__controls {
            border-top: 1px solid $color--gray--lighter;
            line-height: 1;
            @include swap_direction(margin, 0 auto);
            @include swap_direction(padding, 15px 0);
            position: relative;
            text-align: center;
            text-transform: uppercase;
            z-index: 100;
            .slick-prev {
              background-image: image-url('global/sprites-carousel-arrows.png');
              background-repeat: no-repeat;
              background-position: 0 0;
              height: 7px;
              width: 58px;
              #{$ldirection}: 0;
            }
            .slick-next {
              background-image: image-url('global/sprites-carousel-arrows.png');
              background-repeat: no-repeat;
              background-position: 0 -7px;
              height: 7px;
              width: 58px;
              #{$rdirection}: 0;
            }
            .slick-prev,
            .slick-next {
              @include unset-underline-links($extend: true);
              display: inline-block;
              @include swap_direction(margin, -5px 0 0 0);
              @include swap_direction(padding, 10px 0 0);
              text-indent: -9999px;
              width: 58px;
            }
            .count {
              display: inline-block;
              @include swap_direction(padding, 0 20px);
              vertical-align: top;
            }
          }
          &.active {
            .site-bag__contents-recommendations__title-toggle {
              @include icon(arrow--up);
              &:before {
                vertical-align: top;
              }
            }
            .site-bag__contents-recommendations-carousel {
              display: block;
            }
          }
        }
      }
      &.active {
        background: $color--white;
        color: $color--black;
        .site-bag__link,
        .site-bag__link-icon,
        .site-bag__link-count {
          color: $color--black;
          fill: $color--black;
        }
        .site-bag__contents {
          display: block;
        }
      }
    }
  }
}

.site-header__dim-overlay {
  @include opacity(0.85);
  background: $color--black;
  height: 100%;
  #{$ldirection}: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2500;
}

.site-banner {
  position: relative;
  height: 6em;
  @include breakpoint($bp--medium-up) {
    height: 5em;
  }
  background-color: $color--black;
  border-bottom: 1px solid $color--white;
  color: $color--white;
  display: none;
  .site-banner-copy {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    max-width: 1281px;
    @include swap_direction(margin, 0 auto);
    @if $gnav_update == true {
      @include breakpoint($bp--largest-up) {
        font-family: $ano-bold_regular-font;
      }
    }
    @if $fonts_update == true {
      font-family: $helvetica-font;
      font-size: 15px;
      @include breakpoint($bp--largest-up) {
        font-family: $helvetica-font;
      }
    } @else {
      font-family: $body-font-family;
    }
    p {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0 40px);
      @include breakpoint($bp--medium-up) {
        @include swap_direction(padding, 0px 10px);
      }
      a {
        color: $color--white;
      }
    }
  }
  .site-banner-close {
    position: absolute;
    #{$rdirection}: 14px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    i.icon--remove {
      font-size: 20px;
    }
  }
}

// Gnav Updates

@if $gnav_update == true {
  .site-header {
    height: $site-header-height;
    min-height: $site-header-height;
    &--has-sec-nav {
      height: $site-header-height + $site-subnav-height;
    }
    .has-subnav & {
      @include breakpoint($bp--largest-up) {
        height: $site-header-height + $site-subnav-height;
      }
    }
    &__fixed-wrapper {
      height: $site-header-height;
      min-height: $site-header-height;
      li.site-navigation {
        &__item,
        &__more {
          font-size: 18px;
        }
      }
      .header-search__icon {
        &.mobile-hidden {
          display: none;
        }
      }
      .header-search__close {
        &.pc-hidden {
          display: block;
        }
      }
      .site-header__top {
        position: relative;
        margin: 0 auto;
        max-width: $gnav_max_width;
        width: 100%;
      }
      .site-header__suffix {
        .site-header__tools {
          .header-search {
            display: none;
            @include breakpoint($bp--largest-up) {
              display: block;
              height: 32px;
            }
          }
          .site-bag__link-icon {
            &.mobile-hidden {
              display: none;
            }
            &.pc-hidden {
              display: block;
            }
          }
          .site-my-mac__link {
            &-icon {
              display: none;
              @include breakpoint($bp--largest-up) {
                display: block;
              }
            }
            &-header {
              display: block;
              @include breakpoint($bp--largest-up) {
                display: none;
              }
            }
          }
        }
      }
      .header-search__suggestions-wrapper {
        display: none;
      }
      .site-header__extra {
        .header-search {
          &-results {
            display: none;
            color: $color--black;
            margin-top: 120px;
            a {
              color: $color--black;
            }
            &__suggestions {
              > .header-search__term-result {
                top: -42px;
              }
            }
          }
          .header-search__field {
            height: 60px;
            &::placeholder {
              font-size: 2rem;
              @if $fonts_update == true {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
  @include breakpoint($bp--largest-up) {
    //968px
    .site-header .site-header__fixed-wrapper {
      .site-header__container,
      .site-header__prefix,
      .site-header__prefix .site-logo .site-logo__image,
      .site-navigation ul.site-navigation__menu.main-menu,
      li.site-navigation__item,
      li.site-navigation__more {
        height: $site-header-height;
        line-height: $site-header-height;
      }
      .site-navigation__menu.main-menu {
        width: fit-content;
      }
      .site-header__container {
        max-width: $gnav_max_width;
      }
      .site-header__prefix .site-logo .site-logo__image {
        background-size: auto;
      }
      .site-header__menu {
        width: calc(100% - 172px);
        .site-header__extra {
          width: auto;
          display: inline-block;
          height: $site-header-height;
          .checkout & {
            display: none;
          }
        }
      }
      // site search
      .header-search {
        &.header-search-display-input {
          display: none;
        }
        &__suggestions-wrapper {
          display: block;
        }
      }
      .site-header__extra .header-search {
        &-results {
          @include unset-reverse-text();
          width: 100%;
          position: absolute;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          z-index: 500;
          margin-top: $site-header-height;
          background: $color--white;
          color: $color--black;
          display: block;
          .header-search__suggestions {
            .header-search__term-result {
              display: none;
            }
          }
        }
      }

      // site-banner
      .site-banner {
        height: 32px;
        border-bottom: none;
        .site-banner-copy {
          text-align: $ldirection;
          p {
            padding-#{$ldirection}: 15px;
          }
        }
      }
      .site-header__suffix {
        width: auto;
        position: absolute;
        top: 0;
        #{$rdirection}: 0;
      }
      .site-header__suffix .site-header__tools {
        float: $rdirection;
        .checkout & {
          display: none;
        }
        .header-search,
        .site-email-signup,
        .site-my-mac,
        .site-bag {
          display: block;
          padding: 0 7px;
          background-color: $color--black;
          &.active {
            background-color: $color--white;
            color: $color--black;
          }
        }
        .active {
          .header-search,
          .site-email-signup,
          .site-my-mac,
          .site-bag {
            &__link {
              a,
              i {
                color: $color--black;
              }
              span {
                color: $color--white;
              }
            }
          }
        }
        // site-bag
        .site-bag {
          height: 32px;
          min-width: 16px;
          &__link {
            height: 32px;
            line-height: 32px;
            &-icon {
              font-size: 17px;
              line-height: 32px;
              margin: 0;
              top: auto;
              #{$ldirection}: auto;
              width: 100%;
              position: relative;
              &.mobile-hidden {
                display: block;
              }
              &.pc-hidden {
                display: none;
              }
            }
            &-count {
              line-height: 30px;
              height: 32px;
              color: $color--black;
              position: absolute;
              top: 0;
              #{$ldirection}: 50%;
              transform: translateX(-50%);
              padding-top: 5px;
            }
          }
        }
        // my-mac
        .block-template-site-my-mac-v1 {
          width: auto;
        }
        .site-my-mac {
          height: 32px;
          &__link {
            font-size: 17px;
            line-height: 32px;
            height: 32px;
            padding: 0;
            &-icon {
              line-height: 32px;
            }
          }
        }
        // email-signup
        .block-template-site-email-signup-v1 {
          position: relative;
          &:after {
            content: '|';
            top: 5px;
            position: absolute;
            #{$rdirection}: -2px;
          }
        }
        .site-email-signup {
          height: 32px;
          &.active {
            span {
              color: $color--black;
            }
            .site-email-signup__contents {
              #{$ldirection}: auto;
              #{$rdirection}: -54px;
            }
          }
          &__link {
            line-height: 32px;
            &-header {
              text-transform: none;
              text-decoration: underline;
              font-size: 12px;
              font-family: $helvetica-font;
            }
          }
          &.active {
            > .site-email-signup__link {
              height: 32px;
              display: block;
            }
          }
        }
      }
      // site search
      .header-search-display-input.header-search {
        .search-active {
          background-color: $color--black;
          .icon--search {
            color: $color--white;
          }
        }
        .header-search__field {
          @include swap_direction(padding, 0 15px 0 0);
          font-family: $helvetica-font;
          color: $color--white;
          background-color: $color--black;
          border: none;
          border-bottom: 1px solid $color--border;
          height: 22px;
          width: 127px;
          float: $rdirection;
          font-size: 12px;
          line-height: 22px;
          &:focus {
            border: none;
            border-bottom: 1px solid $color--border;
          }
          &::placeholder {
            text-transform: none;
            font-size: 12px;
          }
        }
        .header-search__form {
          @include transform(translateY(-50%));
          top: 50%;
          border: none;
          visibility: visible;
          opacity: 1;
          position: relative;
          background: transparent;
          &--content {
            overflow: hidden;
            max-width: 100%;
          }
        }
        .header-search__icon {
          top: 0;
          display: block;
          position: absolute;
          line-height: 22px;
          height: 22px;
          padding: 0;
          #{$rdirection}: 0;
          #{$ldirection}: auto;
          font-size: 13px;
          color: $color--black;
          z-index: 501;
          i {
            font-size: 15px;
            color: $color--white;
            height: 22px;
          }
        }
        .header-search__typeahead-wrapper,
        > .header-search__icon {
          display: none;
        }
        .header-search__suggestions {
          > .header-search__term-result {
            top: 0;
            font-size: 12px;
            font-family: $helvetica-font;
            color: $color--gray--white;
            line-height: 22px;
          }
          &-wrapper {
            text-align: $ldirection;
            display: block;
          }
        }
      }
      .site-header__extra {
        .header-search-display-input.header-search {
          height: 32px;
        }
      }
    }
  }
  @if $fonts_update == true {
    @include breakpoint($bp--xlarge-up) {
      .site-header .site-header__fixed-wrapper {
        li.site-navigation__item,
        li.site-navigation__more {
          font-family: $ano-black_regular-font;
          font-size: 13px;
        }
        .site-navigation__item {
          &.is-dropdown {
            li.subnav {
              a {
                font-family: $ano-black_regular-font;
                font-size: 14px;
              }
              .site-navigation__submenu-col {
                a {
                  font-family: $ano-black_regular-font;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
  @include breakpoint($bp--xxlarge-up) {
    // 1280px
    .site-header .site-header__fixed-wrapper {
      .site-navigation {
        width: 86%;
      }
      // site-banner
      .site-banner {
        .site-banner-copy {
          text-align: center;
        }
      }
      .site-header__extra {
        .header-search {
          &.header-search-display-input {
            display: block;
            padding-#{$rdirection}: 22px;
            height: $site-header-height;
          }
          &-results {
            margin-top: 0;
          }
        }
      }
      .site-header__suffix {
        padding-#{$rdirection}: 17px;
        .site-header__tools {
          // email-signup
          .block-template-site-email-signup-v1 {
            &:after {
              content: none;
            }
          }
          // site search
          .block-template-site-search-v1 {
            display: none;
            width: 100%;
            position: absolute;
            padding: 0;
            #{$rdirection}: 0;
            top: 100%;
          }
        }
      }
    }
  }
}
