/**
 * @file
 * Product Full - shades
 * SPP oversview shades - NOT the new shade grid / shade picker at _shade_picker
 *
 */
$pc_swatch_size: 90px;
$mobile_swatch_size: (100% / 7);

#{$ie11} .product-full__shades {
  position: relative;
  min-width: 475px;
}

// Grid elements
.product-full__shades {
  clear: both;
  position: relative;
  width: 100%;
  @include breakpoint(($bp--large-up) (orientation landscape)) {
    min-width: 475px;
    height: 250px;
    position: static;
    bottom: 0;
    order: 1;
    @if $fonts_update == true {
      padding-top: 16px;
    } @else {
      padding-top: 10px;
    }
  }
  &-grid {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    font-size: 0;
    margin-bottom: 20px;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      height: 150px; // @todo make a var, update, this is temporary.
      position: absolute;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      max-width: 475px;
      white-space: normal;
      margin-top: 55px;
    }
    &.product-full__shades-grid--has-filters {
      &.mixitup--initialized {
        .product-full__shades-grid-item {
          display: none;
          &.shade-hex-item {
            display: inline-block;
          }
        }
      }
    }
  }
  &-grid-item {
    cursor: pointer;
    display: inline-block;
    width: $mobile_swatch_size;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      min-height: 126px; // @todo setting a hard height, because mixitup version :(
      width: $pc_swatch_size;
    }
    &[data-hex='#ffffff'] {
      .product-full__shade-swatch {
        box-shadow: 0 0 0 1px $color-cta-grey inset;
      }
    }
    &.active {
      .product-full__shade-swatch {
        box-shadow: 0 0 0 2px $color--black inset;
      }
    }
    .product-full__shade-swatch {
      min-height: 45px;
    }
  }
  // Filter section - display on desktop only
  &-filters {
    display: none;
    text-transform: lowercase;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      display: table;
    }
    & + .product-full__shades-grid {
      margin-top: 0;
    }
  }
  &-filter-label {
    color: $color--gray--light;
    display: table-cell;
    padding-#{$rdirection}: 1em;
    white-space: nowrap;
    @if $fonts_update == true {
      font-family: $helvetica-font;
      font-size: 15px;
      letter-spacing: 0;
    }
  }
  &-filter-controls {
    @include swap_direction(padding, 0 0 0 1em);
    display: table-cell;
  }
  &-filter-button {
    background: $color--white;
    border: none;
    border-bottom: $color--gray--light;
    color: $color--gray--light;
    font-size: 12px;
    text-transform: lowercase;
    text-decoration: underline;
    &.active {
      border-bottom: none;
      color: $color--black;
      font-family: $roboto-mono_bold-font;
      text-decoration: none;
      @if $fonts_update == true {
        font-family: $helvetica-font;
        font-weight: bold;
      }
    }
  }
  &-next {
    background-color: transparent;
    border: none;
    display: inline-block;
    color: $color--white;
    position: absolute;
    width: 20px;
    height: 100%;
    line-height: 100%;
    top: 0;
    z-index: 1000;
    right: 5px;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      display: none;
    }
  }
  &-prev {
    background-color: transparent;
    border: none;
    display: inline-block;
    color: $color--white;
    position: absolute;
    width: 20px;
    height: 100%;
    line-height: 100%;
    top: 0;
    z-index: 1000;
    left: 5px;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      display: none;
    }
  }
  &-panel-link {
    @if $fonts_update == true {
      width: 40%;
      @include breakpoint($bp--xlarge-up) {
        width: 35%;
      }
    }
  }
}

// Shade inside the grid item
.product-full__shade {
  border: none;
  position: relative;
  text-decoration: none;
  width: 100%;
  @include breakpoint(($bp--large-up) (orientation landscape)) {
    display: block;
    width: $pc_swatch_size;
    height: $pc_swatch_size;
  }
  &:active,
  &:visited {
    border: none;
  }
  &:focus {
    outline: none;
  }
  &-swatch {
    height: 0;
    width: 100%;
    padding-top: 100%;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      height: $pc_swatch_size;
      width: $pc_swatch_size;
      float: $ldirection;
      padding-top: 0;
    }
  }
  &-name {
    display: none;
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      display: block;
      float: #{$ldirection};
      font-size: 11px;
      line-height: 1;
      margin: 0.25em 0 0.75em 0;
      padding-#{$rdirection}: 0.5em;
      text-align: #{$ldirection};
      text-transform: uppercase;
      word-wrap: break-word;
      max-height: 22px;
    }
    @if $fonts_update == true {
      @include breakpoint($bp--xlarge-up) {
        font-family: $helvetica-font;
        font-size: 11px;
        letter-spacing: 0;
      }
    }
  }
}

// Inventory status specific - sold out shade
// status 7 - sold out
.shade-inv-status--7 {
  .product-full__shade:after {
    @include cr18-shade--disabled-ie11(
      36px,
      39px
    ); // absolute values because IE11 doesn't know calc()
    content: ' ';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    @include breakpoint($bp--medium-up) {
      @include cr18-shade--disabled-ie11(
        76px,
        79px
      ); // absolute values because IE11 doesn't know calc()
    }
    @include breakpoint($bp--large-up) {
      @include cr18-shade--disabled-ie11(
        62px,
        65px
      ); // absolute values because IE11 doesn't know calc()
    }
  }
}

$shade-list-4: 4;
$shade-list-8: 8;
$shade-list-16: 16;

.product-full--enhanced {
  &.product-full {
    padding: 0;
    width: 100%;
    .product-full__shades-container {
      @include breakpoint($bp--large-up) {
        display: flex;
        flex-direction: column;
      }
    }
    .product-full__price-details {
      margin: 0;
      &.has-installments {
        .product-full__price {
          padding-bottom: 20px;
          width: auto;
          text-align: #{$rdirection};
          @include breakpoint($bp--medium-up) {
            text-align: initial;
          }
        }
      }
    }
    @include breakpoint(($bp--large-up) (orientation landscape)) {
      max-width: 100%;
    }
    .product-full__spp-block {
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
      }
    }
    .product-full__details-container {
      float: none;
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        float: #{$ldirection};
      }
      .product-full {
        &__overview {
          padding: 0;
          min-height: auto;
          padding-bottom: 21px;
          overflow: visible;
          z-index: 2;
        }
        &__rating {
          .product__rating-count {
            display: block;
          }
        }
        &__shades {
          height: 100%;
          div.product-full__shade-info {
            padding: 0 20px 5px;
            display: block;
            line-height: 1.1;
            &-name,
            &-description {
              @include breakpoint($bp--medium-down) {
                display: inline;
              }
            }
            &-name {
              @include breakpoint($bp--medium-down) {
                font-size: 16px;
                font-family: $ano-black_regular-font;
              }
            }
            &-description {
              @include breakpoint($bp--medium-down) {
                font-size: 14px;
                font-family: $ano-bold_regular-font;
              }
            }
            @include breakpoint(($bp--large-up) (orientation landscape)) {
              display: none;
            }
          }
          .product-full__badge--mobile {
            @include breakpoint($bp--medium-down) {
              padding: 6px 20px 0;
              font-size: 12px;
              color: $color--black;
              font-weight: 600;
              line-height: 1.17;
            }
          }
          &-filters {
            display: block;
            padding-#{$rdirection}: 0;
            padding-bottom: 20px;
            @include breakpoint($bp--medium-down) {
              padding: 0;
              margin-bottom: 7px;
            }
            .icon--arrow--left {
              display: none;
              @include breakpoint($bp--medium-down) {
                display: block;
                position: absolute;
                #{$ldirection}: -1px;
                top: 0;
                background: $color--white;
                border: 0;
                padding: 0 5px;
                width: 25px;
                height: 20px;
                overflow: hidden;
                &:before {
                  @include arrow-pointer($color: $color--black, $direction: $ldirection);
                  #{$ldirection}: 10px;
                  bottom: 0;
                  content: '';
                }
              }
            }
            .icon--arrow--right {
              display: none;
              @include breakpoint($bp--medium-down) {
                display: block;
                position: absolute;
                #{$rdirection}: -1px;
                top: 0;
                background: $color--white;
                border: 0;
                padding: 0 5px;
                width: 25px;
                height: 20px;
                overflow: hidden;
                &:before {
                  @include arrow-pointer($color: $color--black, $direction: $rdirection);
                  #{$rdirection}: 10px;
                  bottom: 0;
                  content: '';
                }
              }
            }
          }
          &-container {
            .product-full__shade-info {
              @include breakpoint($bp--medium-down) {
                display: none;
              }
            }
          }
          &-next,
          &-prev {
            top: 0;
            min-height: 45px;
            max-height: 110px;
            height: 100%;
            &:before {
              content: '';
            }
          }
          &-next {
            overflow: hidden;
            &:before {
              @include arrow-pointer($color: $color--white, $direction: $rdirection);
              #{$rdirection}: 35%;
            }
          }
          &-prev {
            overflow: hidden;
            &:before {
              @include arrow-pointer($color: $color--white, $direction: $ldirection);
              #{$ldirection}: 35%;
            }
          }
          &-filter-controls {
            @include swap_direction(padding, 0 0 0 20px);
            margin-bottom: 0;
            overflow-x: auto;
            overflow-y: hidden;
            width: 100%;
            margin: 0 auto;
            display: flex;
            position: relative;
            white-space: nowrap;
            @include breakpoint($bp--medium-down) {
              div {
                border-bottom: 1px solid $color--gray--lightest;
              }
            }
            @include breakpoint(($bp--large-up) (orientation landscape)) {
              @include swap_direction(padding, 15px 0 0 0);
              max-width: 510px;
              padding: 0;
              display: block;
              overflow: hidden;
              white-space: unset;
            }
            .slick-prev {
              #{$ldirection}: 0;
              font-size: 11px;
              background: $color--white;
              top: 1px;
              bottom: 0;
              height: auto;
              min-height: 24px;
              max-height: 32px;
              overflow: hidden;
              &:before {
                @include arrow-pointer($color: $color--black, $direction: $ldirection);
                content: '';
                #{$ldirection}: 35%;
                bottom: 1px;
                top: 20%;
              }
              @include breakpoint(($bp--large-up) (orientation landscape)) {
                top: 5px;
                &:before {
                  top: 0;
                }
              }
            }
            .slick-next {
              #{$rdirection}: 0;
              font-size: 11px;
              background: $color--white;
              bottom: 0;
              top: 1px;
              height: auto;
              min-height: 24px;
              max-height: 32px;
              overflow: hidden;
              &:before {
                @include arrow-pointer($color: $color--black, $direction: $rdirection);
                content: '';
                bottom: 1px;
                top: 20%;
              }
              @include breakpoint(($bp--large-up) (orientation landscape)) {
                top: 5px;
                &:before {
                  top: 0;
                }
              }
            }
            .slick-disabled {
              font-size: 0;
              background: transparent;
              z-index: -1;
              width: 0;
              height: 0;
              overflow: hidden;
            }
            .slick-list,
            .slick-track {
              border-bottom: 0;
            }
            .slick-list {
              width: inherit;
            }
            .slick-slide {
              display: block;
              @include breakpoint(($bp--large-up) (orientation landscape)) {
                border-bottom: 1px solid $color--gray--lightest;
                &:last-of-type {
                  border-bottom: 0;
                }
                &:last-child button {
                  padding-#{$rdirection}: 2px;
                  border-bottom: 1px solid $color--gray--lightest;
                }
              }
            }
            .slick-track {
              @include breakpoint(($bp--large-up) (orientation landscape)) {
                display: flex;
              }
            }
          }
          &-panel-link,
          &-filter-label {
            display: none;
          }
          &-filter-button {
            @include swap_direction(padding, 3px 0 10px 0);
            margin: 0 15px;
            font-size: 13px;
            float: $ldirection;
            text-transform: uppercase;
            text-decoration: none;
            font-family: $ano-black_regular-font;
            line-height: 1.31;
            font-weight: normal;
            @include breakpoint(($bp--large-up) (orientation landscape)) {
              @include swap_direction(padding, 0 0 2px 0);
              @include swap_direction(margin, 0 22px 0 0);
              outline: none;
              font-size: 14px;
              line-height: 1.25;
              white-space: nowrap;
            }
            &.active {
              outline: none !important;
              display: inline;
              position: relative;
              &:after {
                #{$ldirection}: 0;
                bottom: -1px;
                width: 100%;
                height: 0;
                border-bottom: 1px solid $color--black;
                content: '';
                position: absolute;
                z-index: 3;
              }
            }
          }
        }
        &__sku-details {
          width: 100%;
          border: 0;
          margin: 0;
          position: static;
        }
      }
      .shade-select__wrapper {
        line-height: 0;
        padding-top: 20px;
        @include breakpoint($bp--medium-down) {
          padding-top: 15px;
        }
      }
      .shade-select__label.product-full__shade-info {
        @include swap_direction(padding, 0 4em 0 1em);
        display: inline-block;
        position: relative;
        height: 43px;
        line-height: 43px;
        border: $color--gray--light solid 1px;
        white-space: nowrap;
        overflow: hidden;
        text-align: $ldirection;
        cursor: pointer;
        width: 100%;
        &:after {
          content: '';
          @include arrow-pointer($color: $color--black, $direction: down);
          #{$rdirection}: 21px;
          bottom: 3px;
        }
        span {
          &.shade-picker__shade.shade-select__color {
            width: 15px;
            height: 15px;
            background: $color--white;
            -webkit-transition: all, 0.2s, ease-in-out;
            -moz-transition: all, 0.2s, ease-in-out;
            -o-transition: all, 0.2s, ease-in-out;
            transition: all 0.2s ease-in-out;
            -webkit-box-shadow: $color--white 0 0 1px 1px;
            -moz-box-shadow: $color--white 0 0 1px 1px;
            box-shadow: $color--white 0 0 1px 1px;
            display: inline-block;
            vertical-align: text-top;
            border: 1px solid $color-cta-grey;
            font-size: 0;
          }
          &.shade-picker__shade-item--label {
            white-space: nowrap;
            overflow: hidden;
            position: absolute;
            width: 72%;
            text-overflow: ellipsis;
            padding-#{$ldirection}: 10px;
            text-transform: none;
            color: $color-shade-name;
          }
        }
      }
      .shade-select__wrapper.open {
        .shade-select__label.product-full__shade-info {
          &:after {
            @include transform(rotate(225deg));
            top: 5px;
            #{$rdirection}: 21px;
          }
        }
      }
      .shade-select__options {
        display: none;
        position: absolute;
        width: 100%;
        #{$ldirection}: 0;
        max-height: 9em;
        margin: 0;
        background: $color--white;
        border: $color--gray--light solid 1px;
        cursor: pointer;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: $color--gray--light;
          border-radius: 2px;
        }
        .shade-picker__shade-item {
          padding: 3px;
          float: none;
          height: 43px;
          line-height: 43px;
          padding-#{$ldirection}: 1em;
          position: relative;
          margin: 0;
          white-space: nowrap;
          display: block;
          .shade-select__color.shade-picker__shade {
            width: 15px;
            height: 15px;
            background: $color--white;
            -webkit-transition: all, 0.2s, ease-in-out;
            -moz-transition: all, 0.2s, ease-in-out;
            -o-transition: all, 0.2s, ease-in-out;
            transition: all 0.2s ease-in-out;
            -webkit-box-shadow: $color--white 0 0 1px 1px;
            -moz-box-shadow: $color--white 0 0 1px 1px;
            box-shadow: $color--white 0 0 1px 1px;
            display: inline-block;
            border: 1px solid $color--black;
            font-size: 0;
          }
          .shade-picker__shade-item--label {
            display: block;
            #{$ldirection}: 40px;
            line-height: inherit;
            overflow: hidden;
            position: absolute;
            text-overflow: ellipsis;
            top: 0;
            white-space: nowrap;
            width: 80%;
          }
        }
      }
      .shade-select__option[data-inventory-status='4'],
      .shade-select__option[data-inventory-status='7'] {
        .shade-picker__shade-item--label {
          text-decoration: line-through;
        }
      }
      .shade-select .open .shade-select__options {
        display: block;
        .shade-select__option:hover {
          background: $color--blue--lighter;
        }
      }
      .product-full__shades-grid_wrapper {
        position: relative;
        @include breakpoint($bp--large-up) {
          position: initial;
        }
      }
      .product-full__shades-grid {
        position: relative;
        height: 100%;
        max-width: 100%;
        margin-top: 0;
        .product-full__shade {
          height: 100%;
          width: 100%;
          .product-full__shade-swatch {
            min-height: inherit;
            width: 100%;
            height: 100%;
          }
          .product-full__shade-name {
            display: none;
          }
        }
        @include breakpoint($bp--medium-down) {
          @for $value from 1 through $shade-list-8 {
            &.products-shade-length-#{$value} + {
              .icon--arrow--left,
              .icon--arrow--right {
                display: none;
              }
            }
          }
          .product-full__shades-grid-item {
            .product-full__shade {
              &:after {
                @include cr18-shade--disabled-ie11(49%, 51%);
              }
            }
          }
        }
        @include breakpoint(($bp--large-up) (orientation landscape)) {
          .product-full__shades-grid-item {
            min-height: 32px;
            width: 32px;
            .product-full__shade {
              height: 32px;
              &:after {
                @include cr18-shade--disabled-ie11(49%, 51%);
              }
            }
          }
          @for $value from 1 through $shade-list-4 {
            &.products-shade-length-#{$value} {
              .product-full__shades-grid-item {
                min-height: 128px;
                width: 128px;
                .product-full__shade {
                  height: 128px;
                  &:after {
                    @include cr18-shade--disabled-ie11(49%, 51%);
                  }
                }
              }
            }
          }
          @for $value from 5 through $shade-list-16 {
            &.products-shade-length-#{$value} {
              .product-full__shades-grid-item {
                min-height: 64px;
                width: 64px;
                .product-full__shade {
                  height: 64px;
                  &:after {
                    @include cr18-shade--disabled-ie11(49%, 51%);
                  }
                }
              }
            }
          }
          &.products-shade-length-full {
            .product-full__shades-grid-item {
              min-height: 32px;
              width: 32px;
              .product-full__shade {
                height: 32px;
              }
            }
          }
          .product-full__shades-grid-item {
            &.low-contrast {
              @include low-contrast-shadow;
              border-radius: 50%;
            }
          }
        }
      }
      .product-full {
        &__addtobag-block {
          display: flex;
          flex-flow: wrap;
          position: relative;
        }
        &__inventory-status {
          width: 100%;
          @include breakpoint(($bp--large-up) (orientation landscape)) {
            .product-inventory-status--button {
              height: 60px;
              margin: 0;
              line-height: 4;
            }
          }
        }
        &__notify-me {
          width: 100%;
          @include breakpoint(($bp--large-up) (orientation landscape)) {
            width: auto;
            float: $ldirection;
            flex: 1;
            min-width: 50%;
          }
          &[data-notify-me-inventory-status='7'] {
            display: none;
          }
          &.notify-display--soldout-139 {
            display: block;
          }
          .notify-status {
            @include h6();
            text-align: center;
            height: 60px;
            background-color: $color--black;
            line-height: 60px;
            margin-bottom: 10px;
            a {
              color: $color--white;
              font-weight: bold;
              border: 0;
              line-height: 3.6;
              display: block;
            }
          }
        }
        &__sku-extras-replenishment {
          margin: 0 auto;
          @include breakpoint(($bp--large-up) (orientation landscape)) {
            width: 100%;
          }
          @include breakpoint($bp--medium-down) {
            .spp-autoreplenish--select-icon--down {
              #{$rdirection}: 19px;
              top: 12px;
              z-index: 0;
              background: transparent;
              &:before {
                @include arrow-pointer($color: $color--black, $direction: down);
                content: '';
              }
            }
          }
        }
        &__sku-extras {
          float: $ldirection;
          width: 100%;
          text-align: center;
          order: 3;
          border: 0;
          margin-top: 0;
          .spp-autoreplenish {
            select {
              width: 100%;
              @include breakpoint($bp--medium-down) {
                line-height: 1;
                width: 100%;
                z-index: 1;
                position: relative;
                display: flex;
                text-align: $ldirection;
                flex-flow: column;
                padding: 1.1rem;
              }
            }
            &--select-icon--down {
              @include breakpoint(($bp--large-up) (orientation landscape)) {
                display: inline-flex;
                float: none;
                #{$rdirection}: 28%;
                margin: auto;
                background: transparent;
                &:before {
                  @include arrow-pointer($color: $color--black, $direction: down);
                  content: '';
                }
              }
              .no-touch & {
                display: none;
              }
            }
            .select2-container {
              .select2-choice {
                border: 0;
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                @include breakpoint(($bp--large-up) (orientation landscape)) {
                  text-align: $ldirection;
                }
                .select2-chosen {
                  text-align: center;
                  display: inline-flex;
                  @include breakpoint(($bp--large-up) (orientation landscape)) {
                    display: block;
                  }
                }
                .select2-arrow {
                  float: none;
                  position: relative;
                  display: inline-flex;
                  margin-#{$rdirection}: -30px;
                  @include breakpoint(($bp--large-up) (orientation landscape)) {
                    #{$rdirection}: 0;
                    margin-#{$rdirection}: 0;
                    float: none;
                    &:before {
                      top: -3px;
                    }
                    b:before {
                      @include arrow-pointer($color: $color--black, $direction: down);
                      content: '';
                      #{$rdirection}: 0;
                      top: -4px;
                      bottom: 8px;
                    }
                  }
                }
              }
            }
          }
        }
        &__add-to-bag {
          float: none;
          width: 100%;
          text-align: center;
          @include breakpoint(($bp--large-up) (orientation landscape)) {
            width: auto;
            float: $ldirection;
            flex: 1;
          }
        }
        &__live-chat-container {
          &.fullwidth {
            width: 100%;
          }
          @include breakpoint($bp--medium-down) {
            position: fixed;
            bottom: 10%;
            #{$rdirection}: 2%;
            z-index: 99;
          }
          .LPMcontainer {
            margin: 0 !important;
          }
          .tout-block-landscape__cta {
            @include breakpoint($bp--medium-down) {
              background: url(/media/images/global/spp_live_chat_mobile.png) center center/cover
                no-repeat;
              padding: 0;
              height: 45px;
              width: 45px;
              z-index: 99;
              border: 0 !important;
              .product-full_live-chat_image {
                opacity: 0;
              }
            }
            @include breakpoint(($bp--large-up) (orientation landscape)) {
              height: 60px;
              line-height: 4;
              width: 100%;
              padding: 0 66px;
              margin: 0;
              flex: 1;
              border: 1px solid $color--black !important;
            }
          }
        }
      }
    }
    .product-details {
      &__bopis-spp-container {
        width: 100%;
        float: $ldirection;
        font-size: 12px;
        .spp-shipping-head {
          font-family: $ano-bold_regular-font;
          margin-top: 15px;
          & > b {
            font-family: $ano-bold_regular-font !important;
            font-size: 16px;
          }
        }
        .postmates_enabled {
          .spp-shipping-head {
            margin-top: 10px;
          }
        }
        .spp-shipping-text {
          font-family: $helvetica-font;
          font-size: 15px;
          letter-spacing: 0.3px;
          line-height: 16px;
          @include breakpoint($bp--medium-down) {
            font-size: 12px;
          }
          & > b {
            font-family: inherit !important;
            font-size: inherit;
            letter-spacing: inherit;
            line-height: inherit;
          }
        }
        .bopis-spp-block {
          background-color: $color-grey-fill;
          &::after {
            width: 900px;
            margin: 0 auto;
            text-align: center;
          }
          .bopis-spp-delivery-text {
            text-transform: capitalize;
            font-size: 13px;
          }
          .bopis-no-stores-available {
            .spp-shipping-head {
              background: url(/media/images/global/shipping.png) no-repeat center top;
              padding-top: 30px;
            }
          }
          .product-full__find-in-store {
            .invis-start__content {
              padding-top: 5px;
            }
            .invis-start__link--open-finder {
              border: 0;
              font-family: $ano-black_regular-font;
            }
          }
          .product-offer__shipping {
            .shipping-modal-link {
              border: 0;
              font-size: 13px;
              display: block;
            }
          }
          .bopis-spp-separator {
            background: $color-cta-grey;
          }
        }
      }
      &__bopis-spp-left.product-full__offer {
        float: $ldirection;
        width: 50%;
      }
      &__bopis-spp-right {
        float: $rdirection;
        width: 50%;
        .invis-start__panel {
          font-family: $ano-black_regular-font;
        }
      }
    }
  }
}
