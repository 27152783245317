@mixin opc_pickpoint {
  //breakpoint
  $pp-small-down: max-width $width-large - 2; //only mobile
  $pp-large-up: min-width $width-large - 1; //ipad portrait fixing = min-with 768px
  $pp-ipad-portrait: $width-large - 1 $width-xlarge;

  //Order Details styles
  .order-details-page {
    .order-details {
      .order-details__item {
        &.pickpoint_address_container {
          width: 100%;
          @include breakpoint($bp--medium-up) {
            width: 100%;
          }
        }
        #delivery-address-h {
          margin-top: 24px;
        }
      }
    }
  }
  #pickpoint_customer_info {
    .pickpoint_dropdowns {
      .form-item {
        label {
          display: block;
        }
        .invalid_marker {
          top: 28px;
          display: none;
        }
        .valid_marker {
          display: none;
        }
        select {
          &.checkedEmpty {
            color: #b84947;
          }
          @include breakpoint($pp-large-up) {
            border-width: 1px;
          }
        }
      }
    }
    .country {
      margin-bottom: 15px;
    }
  }
  @include breakpoint($pp-small-down) {
    #pickpoint_customer_info {
      .local-collection {
        .show-collection-point {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
      .set-collection-point {
        .local-collection-button {
          width: 100%;
        }
      }
    }
  }
  .click_and_collect_map {
    .overlay-content {
      .map-container {
        .search-box-cancel {
          position: absolute;
          top: 0;
          right: 0;
          margin: 5px 10px;
          width: auto;
          height: auto;
          background-color: transparent;
          color: #000;
          font-weight: lighter;
          padding: 0;
          &:after {
            content: '\274c';
          }
        }
        .address-map-location-panel {
          left: 0;
          height: 550px;
          top: 0;
          width: 265px;
          padding-right: 0;
          display: inline-block;
          position: relative;
          margin: 65px 0 0 10px;
          overflow: hidden;
          @include breakpoint($pp-ipad-portrait) {
            width: 30%;
            margin-top: 63px;
            &.active {
              height: 550px;
            }
          }
          @include breakpoint($pp-small-down) {
            top: 9999px;
            height: auto;
            display: none;
            &.active {
              top: 311px;
              position: absolute;
              height: 100%;
              width: 100%;
              margin: 40px 0 0 0;
            }
          }
          label {
            display: inline-block;
          }
          .close {
            display: none !important;
          }
          header,
          .messages {
            display: none;
          }
          .locations {
            background-color: #f3f3f3;
            border: 1px solid transparent;
          }
          .location {
            border-color: #666666;
            margin: 0;
            padding-top: 1em;
            &:first-child {
              padding-top: 20px;
              &:hover {
                background-color: transparent;
                padding: 20px 0 0.5em 1em;
              }
              &.selected {
                background-color: #c7c7c7;
              }
              @include breakpoint($pp-ipad-portrait) {
                padding-top: 13px;
              }
            }
            &:hover {
              background-color: transparent;
              padding: 1em 0 0.5em 1em;
            }
            &.selected {
              background-color: #c7c7c7;
              padding: 1em 0 0.5em 1em;
            }
            input {
              position: relative;
              float: left;
            }
            .service_provider,
            .title,
            .address,
            .location_delivery_cost {
              margin-left: 20px;
            }
            .button {
              margin: 0 0 1em 0;
              .select-access-point {
                background-color: #fafafa;
                border: 1px solid #6e6e6e;
                color: #000000;
                cursor: pointer;
                height: auto;
                margin: 10px 0 0;
                padding: 7px 10px;
                text-align: center;
                text-decoration: none !important;
                width: 140px;
                &.disabled {
                  border: 1px solid #cccccc;
                  background-color: #ffffff;
                  color: #cccccc;
                  cursor: default;
                }
              }
            }
          } // location
        }
        .map-wrapper {
          height: 630px;
          width: 686px;
          vertical-align: top;
          display: inline-block;
          margin-top: 30px;
          @include breakpoint($pp-ipad-portrait) {
            width: 68%;
          }
          @include breakpoint($pp-small-down) {
            height: 100%;
            width: 100%;
            margin-top: 0;
          }
          .address-map-options-search-panel {
            position: relative;
            top: 0;
            left: 0;
            margin: 35px auto 10px;
            .options-search-box-wrapper {
              .options-search-box {
                select {
                  background-color: #fafafa;
                  border: 1px solid #6e6e6e;
                  height: auto;
                  padding: 21px 12px;
                  margin: 0 10px;
                  text-align: center;
                  width: 100%;
                  max-width: 202px;
                  line-height: normal;
                  @include breakpoint($pp-ipad-portrait) {
                    width: 31%;
                    height: 31px;
                    padding: 7px 18px 7px 10px;
                    margin-right: 0;
                  }
                }
              }
            }
            .address-map-search-panel {
              float: none;
              position: relative;
              margin: 0 0 0 10px;
              display: inline-block;
              vertical-align: top;
              select {
                background-color: #fafafa;
                border: 1px solid #6e6e6e;
                height: 29px;
                margin: 0 10px;
                text-align: center;
                width: 200px;
                vertical-align: bottom;
              }
              .change-date-container {
                margin-top: 8px;
              }
              @include breakpoint($pp-ipad-portrait) {
                margin-left: 0;
                width: 31%;
              }
              @include breakpoint($pp-small-down) {
                position: relative;
                z-index: 1;
                margin: 0;
                width: 100%;
                max-width: initial;
              }
            }
            @include breakpoint($pp-small-down) {
              margin: 35px 20px 10px 20px;
              .options-search-box-wrapper {
                .options-search-box {
                  select {
                    height: auto;
                    margin: 0 0 20px 0;
                    width: 100%;
                    max-width: initial;
                  }
                }
              }
            }
          } // end address-map-options-search-panel

          .maps {
            width: 660px;
            height: 460px;
            margin: 25px 25px 30px 12px;
            position: relative;
            @include breakpoint($pp-ipad-portrait) {
              width: 96%;
              margin: 17px 25px 13px 10px;
              height: 500px;
            }
            @include breakpoint($pp-small-down) {
              height: 100%;
              width: 100%;
              position: relative;
              margin: 0;
            }
            .maps-toggle-container {
              position: absolute;
              top: 0;
              right: 0;
              z-index: 2;
              span {
                line-height: 30px;
                padding: 8px 10px;
                border-radius: 0 0 5px 5px;
                color: #ffffff;
                background-color: #000000;
                border: 1px solid #000000;
                margin: 0 1px;
                &:hover,
                selected {
                  border: 1px solid #000000;
                  color: #000000;
                  background-color: #ffffff;
                }
                &.location-toggle {
                  display: none;
                }
              }
              @include breakpoint($pp-small-down) {
                position: relative;
                text-align: right;
                margin: 0;
                top: auto;
                span.location-toggle {
                  display: inline;
                }
              }
            }
            .metro-map {
              .popup_container {
                position: absolute;
                padding: 14px 14px 9px;
                background-color: #f1f1f1;
                border: 1px solid #5d5e5c;
                border-radius: 6px;
                min-width: 100px;
                min-height: 100px;
                z-index: 101;
                top: 50px;
                .location {
                  .statuspoint {
                    border-radius: 5px;
                    border: 1px solid #000;
                    width: 9px;
                    height: 9px;
                    position: absolute;
                    right: 0px;
                    top: 28px;
                  }
                }
                .close {
                  position: absolute;
                  right: 5px;
                  top: 5px;
                  text-decoration: none;
                  font-size: 14px;
                  font-weight: bold;
                  color: #666;
                  z-index: 102;
                  span {
                    &:before {
                      content: 'X';
                    }
                  }
                } // close
                .atop {
                  position: absolute;
                  top: -12px;
                  left: 50%;
                  margin-left: -12px;
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 12px 12px 12px;
                  border-color: transparent transparent #232323 transparent;
                  &:after {
                    position: absolute;
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 11px 11px 11px;
                    border-color: transparent transparent #f1f1f1 transparent;
                    content: '';
                    top: 1px;
                    left: -11px;
                  }
                } //atop
                .abot {
                  position: absolute;
                  bottom: -12px;
                  left: 50%;
                  margin-left: -12px;
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 12px 12px 0 12px;
                  border-color: #232323 transparent transparent transparent;
                  &:after {
                    position: absolute;
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 11px 11px 0 11px;
                    border-color: #f1f1f1 transparent transparent transparent;
                    content: '';
                    top: -12px;
                    left: -11px;
                  }
                } //abot
                .itemspopuplist_one {
                  height: 280px;
                  width: 320px;
                  .location_wrapper {
                    height: 270px;
                    width: 305px;
                  }
                }
                .itemspopuplist_two {
                  height: 315px;
                  width: 580px;
                  .location_wrapper {
                    height: 305px;
                    width: 270px;
                  }
                }
                .itemspopuplist_one,
                .itemspopuplist_two {
                  h3 {
                    font-size: 19px;
                    @include breakpoint($pp-small-down) {
                      border-bottom: 1px solid #333;
                    }
                  }
                  .address {
                    font-size: 17px;
                  }
                  a.button {
                    bottom: 62px;
                    font-size: 17px;
                    padding: 10px;
                    position: absolute;
                    width: 210px;
                    &.disabled {
                      border: 1px solid #cccccc;
                      background-color: #ffffff;
                      color: #cccccc;
                      cursor: default;
                    }
                    @include breakpoint($pp-small-down) {
                      width: 230px;
                    }
                    &.select-access-point {
                      bottom: 0;
                    }
                  }
                }
                .andmore {
                  height: 315px;
                }
                .location_wrapper {
                  display: inline-block;
                  font-size: 15px;
                  margin-right: 15px;
                  overflow: hidden;
                  position: relative;
                  text-align: initial;
                }
                .mCustomScrollBox {
                  position: absolute;
                  overflow: hidden;
                  height: 93%;
                  max-width: 100%;
                  outline: none;
                  direction: ltr;
                  left: 0px;
                  overflow: auto;
                  padding: 0px 0px 0 16px;
                }
                .select-access-point,
                .more-info {
                  background-color: #000000;
                  border: 1px solid #000;
                  color: #ffffff;
                  cursor: pointer;
                  font-size: 12px;
                  height: auto;
                  line-height: 1.5em;
                  margin: 10px 0 5px;
                  padding: 7px 10px;
                  text-align: center;
                  text-decoration: none !important;
                  width: 140px;
                  &.disabled {
                    border: 1px solid #cccccc;
                    background-color: #ffffff;
                    color: #cccccc;
                    cursor: default;
                  }
                }
                .more-info {
                  background-color: #fafafa;
                  border: 1px solid #6e6e6e;
                  color: #000000;
                  line-height: 1.5em;
                  font-family: 'tstar_mono_roundregular', Helvetica, Arial, sans-serif;
                  font-size: 12px;
                }
              }
            }
            .map-title {
              display: none;
              @include breakpoint($pp-small-down) {
                display: block;
              }
            }
          }
          //maps
          .metro-map {
            height: 100%;
            width: 100%;
            background-color: #fff;
          }
          #metromap {
            height: 100%;
            width: 100%;
            position: relative;
            @include breakpoint($pp-small-down) {
              overflow: hidden;
            }
            .points,
            .customzoom {
              position: absolute;
              display: none;
            }
            .points {
              margin-top: 17px;
              z-index: 11;
              @include breakpoint($pp-small-down) {
                z-index: 1;
              }
              div.point_metro_img {
                position: absolute;
                @include breakpoint($pp-small-down) {
                  .metro_image {
                    max-width: none;
                  }
                }
              }
            }
            .metroPoint {
              position: absolute;
              width: 20px;
              height: 20px;
              background: url(//pickpoint.ru/select/design/metro_marker2.png) center center no-repeat;
              z-index: 1;
            }
          }
          #metrozoom {
            z-index: 101;
            position: absolute;
            left: 30px;
            top: 50px;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            .metroPoint {
              position: absolute;
              width: 20px;
              height: 20px;
              background: url(//pickpoint.ru/select/design/metro_marker2.png) center center no-repeat;
              z-index: 1;
            }
          }
          .map {
            .location {
              input[name='LOCATION_GROUP'] {
                display: none;
              }
              .statuspoint {
                .status_opened {
                  background-color: #71a751;
                  border: 1px solid #71a751;
                }
              }
              .select-access-point,
              .more-info {
                background-color: #000000;
                border: 1px solid #000;
                color: #ffffff;
                cursor: pointer;
                font-size: 12px;
                height: auto;
                line-height: 1.5em;
                margin: 10px 0 5px;
                padding: 7px 10px;
                text-align: center;
                text-decoration: none !important;
                width: 140px;
                &.disabled {
                  border: 1px solid #cccccc;
                  background-color: #ffffff;
                  color: #cccccc;
                  cursor: default;
                }
              }
              .more-info {
                background-color: #fafafa;
                border: 1px solid #6e6e6e;
                color: #000000;
                line-height: 1.5em;
                font-family: 'tstar_mono_roundregular', Helvetica, Arial, sans-serif;
                font-size: 12px;
              }
              @include breakpoint($pp-small-down) {
                .title {
                  h3 {
                    font-size: 12px;
                    border-bottom: 1px solid #333;
                  }
                }
              }
            }
            .gm-style {
              .map-info-window {
                .location {
                  input {
                    display: none !important;
                  }
                }
              }
            }
          } //map
        } //map-wrapper

        .map-title {
          font-size: 15px;
          font-weight: bold;
          margin: 0;
          padding: 20px;
          border-color: #666666;
          text-align: center;
          @include breakpoint($pp-ipad-portrait) {
            padding: 9px;
          }
          @include breakpoint($pp-small-down) {
            font-size: 12px;
          }
        }
        &.map {
          .locations {
            .location-info-windows {
              display: block;
            }
          }
        }
        &.metro {
          .locations {
            .metro-list,
            .location-info-windows {
              display: none;
            }
          }
        }
        .locations {
          .metro-list {
            padding: 10px 20px 10px 10px;
            .metro-info {
              padding: 3px 0;
            }
            .metro-qty {
              float: right;
            }
            @include breakpoint($pp-small-down) {
              display: none;
              .metro-qty {
                margin-top: -1px;
              }
            }
          }
          @include breakpoint($pp-small-down) {
            .location-info-windows {
              display: block;
            }
            &.metro-on {
              .metro-list {
                display: block;
              }
              .location-info-windows {
                display: none;
              }
            }
            .map-title {
              display: none;
            }
          }
        } // end locations

        .map-window-info {
          font-size: 12px;
          .title {
            margin-top: 20px;
          }
          .address {
            margin: 6px 0;
          }
          h3 {
            font-size: 14px;
          }
          p {
            margin: 0;
          }
        }
        .map-window-more-info {
          background: #fff;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          z-index: 202;
          @include breakpoint($pp-small-down) {
            width: 270px;
          }
          .content {
            padding: 0 20px 20px;
            overflow: auto;
            max-height: 595px;
            margin-top: 35px;
            @include breakpoint($pp-small-down) {
              max-height: 350px;
            }
            div {
              margin-bottom: 6px;
            }
            .button {
              display: inline-block;
              a.button {
                background-color: #000000;
                border: 1px solid #000000;
                color: #ffffff;
                cursor: pointer;
                font-family: 'tstar_mono_roundregular', Helvetica, Arial, sans-serif;
                font-size: 12px;
                height: auto;
                line-height: 1.5em;
                margin: 10px 0 5px;
                padding: 7px 10px;
                text-align: center;
                text-decoration: none !important;
                width: 140px;
                &.disabled {
                  border: 1px solid #cccccc;
                  background-color: #ffffff;
                  color: #cccccc;
                  cursor: default;
                }
              }
            }
            p {
              margin-bottom: 0;
            }
            h3 {
              font-size: inherit;
            }
            .image {
              img {
                display: block;
                margin: 0 auto;
              }
            }
            button {
              overflow: hidden;
              .select-access-point {
                background-color: #fafafa;
                border: 1px solid #6e6e6e;
                color: #000000;
                cursor: pointer;
                height: auto;
                margin: 10px 0 0;
                padding: 7px 10px;
                text-align: center;
                text-decoration: none !important;
                width: 140px;
                &.disabled {
                  border: 1px solid #cccccc;
                  color: #cccccc;
                  cursor: default;
                }
              }
            }
            .hint {
              display: inline-block;
              padding: 4px 14px;
              margin-bottom: 10px;
              border-radius: 5px;
            }
            .hint_green {
              background: #71a751;
              color: #ffffff;
            }
            .hint_red {
              background: #cc0000;
              color: #ffffff;
            }
          }
          .inner {
            position: relative;
            overflow: hidden;
            .close {
              position: absolute;
              top: 0;
              right: 0;
              padding: 10px;
              cursor: pointer;
              font-size: 23px;
              font-style: normal;
              font-weight: normal;
            }
          } //inner
          @include breakpoint($pp-small-down) {
            background-color: white;
          }
        }
        .submit {
          bottom: 1px;
          left: 50%;
          margin-top: 20px;
          position: absolute;
          width: auto;
          z-index: 1;
          button {
            display: none;
          }
        }
      } //map-container
      .customzoom {
        .minus {
          position: absolute;
          width: 24px;
          height: 23px;
          background: url(//pickpoint.ru/select/design/zoomminus.gif) center center no-repeat;
          cursor: pointer;
          top: 115px;
        }
        .plus {
          position: absolute;
          width: 24px;
          height: 22px;
          background: url(//pickpoint.ru/select/design/zoomplus.gif) center center no-repeat;
          cursor: pointer;
        }
        .drag {
          position: absolute;
          width: 10px;
          height: 22px;
          background: url(//pickpoint.ru/select/design/zoomdrag.gif) center center no-repeat;
          cursor: pointer;
          left: 7px;
          top: 25px;
          z-index: 2;
        }
        .line {
          position: absolute;
          background: silver;
          width: 1px;
          height: 75px;
          top: 35px;
          left: 12px;
          z-index: 1;
        }
      }
      .map-container {
        @include breakpoint($pp-small-down) {
          overflow: auto;
        }
        .address-map-search-panel {
          position: inherit;
          z-index: 1;
          margin: 0;
          .search-box-toggle {
            height: 20px !important;
          }
          .status-bar-wrapper {
            position: fixed;
            bottom: 0;
            left: 50%;
            width: 30em;
            margin-left: -10em;
            z-index: 2;
            background-color: #000;
            @include breakpoint($bp--large-down) {
              margin-left: -15em;
            }
            @include breakpoint($pp-ipad-portrait) {
              margin-left: -8em;
            }
          }
          .search-box-wrapper {
            @include breakpoint($pp-small-down) {
              padding-left: 0;
            }
          }
          .search-box-field {
            width: 155px;
            margin-right: 60px;
            height: 61px !important; /* this is needed to override the !important in the emea theme */
            float: right;
            padding: 21px 12px !important;
            -webkit-appearance: none;
            border-radius: 0;
            -webkit-border-radius: 0px;
            font-size: 12px;
            @include breakpoint($pp-ipad-portrait) {
              width: 80%;
              float: left;
              margin: 0;
              height: 31px !important;
              padding: 7px 10px !important;
              border-color: #6e6e6e;
            }
            @include breakpoint($pp-small-down) {
              float: left;
              width: 75%;
              padding: 5px;
            }
          }
          .search-box-submit {
            height: 63px;
            width: 60px;
            position: absolute;
            top: -1px;
            right: 0;
            line-height: normal;
            color: white;
            padding: 17px 12px;
            span {
              display: block;
              margin-top: -2px;
            }
            @include breakpoint($pp-ipad-portrait) {
              height: 32px;
              padding: 2px 10px;
              width: 38px;
            }
            @include breakpoint($pp-small-down) {
              background-color: black;
              width: 25%;
              margin-right: 0;
            }
          }
          .search-box {
            overflow: hidden;
            width: auto;
            position: relative;
          }
        }
      } // map-container
    }
    .status_green {
      background-color: #0d9344;
    }
    .status_red {
      background-color: #be1d2c;
    }
    @include breakpoint($pp-small-down) {
      .group_location_radio {
        float: left;
        width: 25px;
      }
      .group_location_list {
        margin-left: 30px;
      }
      .metro_list_radio {
        float: left;
        width: 25px;
      }
    }
  }
  #map-foreground-node {
    margin-left: -483px;
    left: 50%;
    top: 50%;
    padding: 0;
    background-color: #f0f0f0;
    border-color: #dbdbdb;
    position: fixed;
    height: 630px;
    max-height: 630px;
    transform: translateY(-50%);
    @include breakpoint($pp-small-down) {
      left: 50%;
      margin-left: 0;
      top: 0;
      transform: none;
      max-height: initial;
    }
  }
  #map-overlay-background {
    left: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    display: block;
    background-color: #000;
    opacity: 0.7;
    height: initial !important;
    z-index: 0;
    position: fixed;
  }
}
