/**
 * @file
 * Components Organisms - Product
 *
 * Product styles for all instances:
 *
 * 1) Full SPP: .product--full
 * 2) MPP grid: .product--teaser
 * 3) Collections quickshop: .collection-quickshop
 * 4) Mini teaser variant: .product--teaser--mini
 * 5) Tiny teaser variant: .product--teaser--tiny
 * 6) List view variant: .product--list-view
 *
 */

$product-teaser-min-height: 480px;
$product-teaser-tiny-min-height: 215px;
$product-teaser-img-height: 320px;
$product-detail-min-height: 600px;

.product {
  @include clearfix();
  position: relative;
  .select2-container {
    @include select--arrow-down();
  }
  .product__inventory-status li.coming-soon-text {
    @include btn;
    position: absolute;
    font-size: 1.5em;
    right: 0;
    background-color: $color--gray--light;
    display: none;
    padding: 0 20px;
    margin: 15px;
    @include breakpoint($bp--medium-up) {
      margin: 35px 0 15px;
    }
  }
  .product--shaded .product__inventory-status li.coming-soon-text {
    display: none;
  }
}

.product--teaser {
  border-top: $border;
  position: relative;
  padding-top: 15px;
  text-transform: uppercase;
  @include breakpoint($bp--small-up) {
    min-height: 480px;
    height: 100%;
  }
  @include breakpoint($bp--medium-up) {
    margin-bottom: 15px;
  }
  &.product-brief-v2 {
    border-top: 0;
  }
  &.product--teaser--tiny {
    min-height: $product-teaser-tiny-min-height;
  }
  &.product--teaser--tiny.prod_inv_status-3,
  &.product--teaser--tiny.prod_inv_status-2 {
    @include swap_direction(margin, 0);
    min-height: 260px;
  }
  .product--random & {
    border-top: none;
  }
  .video-player__product-slide & {
    @include breakpoint($bp--small-up) {
      border-top: none;
    }
  }
  .product__faves--teaser {
    display: none;
    &.product__faves--teaser_mobile {
      display: block;
    }
    @include breakpoint($bp--xlarge-up) {
      display: block;
      &.product__faves--teaser_mobile {
        display: none;
      }
    }
  }
  .product__detail {
    .product__footer {
      .error-message-limited-remaining {
        display: none;
      }
      .product-replenishment-select {
        display: none;
      }
    }
  }
}

.product--full {
  .product__name-link {
    display: block;
    &:hover {
      color: inherit;
    }
  }
  .shade-picker__trigger-btn {
    display: none;
  }
  .product__rating--header {
    @include breakpoint($bp--xlarge-up) {
      display: none;
    }
  }
  .product__rating--title {
    display: none;
    @include breakpoint($bp--xlarge-up) {
      display: block;
    }
  }
  .product__rating-non {
    text-align: #{$rdirection};
  }
  .product__rating--no_rating {
    text-transform: uppercase;
    position: relative;
    top: 9px;
    @include breakpoint($bp--medium-up) {
      top: 0;
    }
  }
  .product__rating-stars {
    #{$rdirection}: 0;
    top: 5px;
    @include breakpoint($bp--medium-up) {
      #{$rdirection}: 0;
      top: 0;
    }
  }
  .product__footer {
    .product__price-installment {
      position: absolute;
      top: 58px;
      @include breakpoint($bp--large-down) {
        top: 48px;
      }
    }
    .product__price_ppu {
      @include swap_direction(padding, 6px 0 0 0);
    }
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(padding, 15px);
      float: #{$rdirection};
      clear: #{$rdirection};
      width: 25%;
      padding-top: 45px;
      border-#{$ldirection}: $border;
    }
    .product {
      &__size {
        &--separator {
          display: none;
        }
      }
    }
    .product-price-details {
      .product {
        &__size {
          float: #{$ldirection};
          &--separator {
            display: inline;
          }
        }
        &__price_ppu {
          @include swap_direction(padding, 0);
          @include swap_direction(margin, 0 0 0 5px);
          display: inline-block;
        }
        &__price {
          float: #{$ldirection};
          clear: #{$ldirection};
          @include breakpoint($bp--large-up) {
            clear: none;
          }
        }
      }
    }
    .product-replenishment-select {
      padding-#{$ldirection}: 0px;
      @include breakpoint($bp--medium-down) {
        @include swap_direction(margin, 0 15px);
        padding-bottom: 15px;
        border-bottom: 1px solid $color--gray--lightest;
      }
      .select2-choice {
        border-width: 0;
        .select2-chosen {
          margin-#{$ldirection}: 0;
        }
      }
      &__select {
        @include breakpoint($bp--medium-up) {
          border: 1px solid $color--gray--lightest;
        }
        @include breakpoint($bp--medium-down) {
          @include swap_direction(padding, 0 10px);
          height: auto;
          line-height: 36px;
          outline: none;
          text-decoration: none;
          font-weight: 400;
        }
        .select2-choice {
          @include breakpoint($bp--medium-up) {
            @include swap_direction(padding, 0 10px);
            height: auto;
            line-height: 36px;
            outline: none;
            text-decoration: none;
            font-weight: 400;
          }
          .select2-arrow {
            top: 7px;
            #{$rdirection}: 2px;
            b::before {
              @include breakpoint($bp--medium-up) {
                bottom: 5px;
              }
              .csstransforms & {
                @include corner-triangle();
              }
            }
          }
        }
      }
      &__info {
        @include swap_direction(padding, 0 10px);
        font-weight: 400;
        a {
          border-bottom-color: $black;
        }
      }
    }
    .product_content_fav_mobile {
      .product-replenishment-select {
        @include breakpoint($bp--medium-down) {
          border-bottom: none;
        }
      }
    }
  }
  .product_rgn_name_below_subline {
    @include breakpoint($bp--large-down) {
      display: block;
    }
  }
  .product__shade-sticky {
    width: 25%;
    #{$ldirection}: 75%;
    max-height: 0px;
  }

  // Full Product Layout: defaults to NOT shaded
  .product__header {
    @include breakpoint($bp--xlarge-up) {
      float: #{$rdirection};
      width: 25%;
      padding-top: 25px;
      border-#{$ldirection}: $border;
    }
  }
  &.product--shaded .product__header,
  &.product--shaded--single .product__header {
    @include breakpoint($bp--xlarge-up) {
      margin-#{$rdirection}: 25%;
    }
  }
  .product__images {
    position: relative;
    min-height: 225px; // mobile sensible height from comps
    @include breakpoint($bp--xlarge-up) {
      min-height: $product-detail-min-height;
      float: #{$ldirection};
      width: 75%;
    }
  }
  &.product--shaded .product__images,
  &.product--shaded--single .product__images {
    @include breakpoint($bp--xlarge-up) {
      width: 50%;
    }
  }
  &.product--shaded .product__footer,
  &.product--shaded--single .product__footer {
    @include breakpoint($bp--xlarge-up) {
      margin-#{$rdirection}: 25%;
    }
  }
  .error-message-limited-remaining {
    display: none;
  }
  &.prod_inv_status-1 {
    .error-message-limited-remaining {
      display: block;
    }
    .product__bag-bar--sticky .error-message-limited-remaining {
      position: absolute;
      #{$rdirection}: 8%;
      top: 22px;
      color: $color--white;
      display: block;
    }
  }
}

.product--list-view {
  @include swap_direction(padding, 5px 0);
  border-top: $border;
  position: relative;
  text-transform: uppercase;
}

.product__detail {
  .product--teaser & {
    height: 100%;
  }
  .product--teaser--tiny & {
    min-height: $product-teaser-tiny-min-height;
  }
  .product--full & {
    position: relative;
    @include breakpoint($bp--xlarge-up) {
      min-height: $product-detail-min-height;
    }
  }
}

.product__header {
  .product--full & {
    @include swap_direction(padding, 15px 0);
    @include swap_direction(margin, 0 15px);
    border-top: 1px solid $color--gray--lighter;
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(padding, 15px);
      @include swap_direction(margin, 0);
      border-top: none;
    }
    @include breakpoint($bp--xlarge-down) {
      padding-bottom: 0;
    }
  }
  .product--teaser & {
    float: #{$rdirection};
    width: 50%;
    @include breakpoint($bp--small-up) {
      float: none;
      width: 100%;
      min-height: 80px;
      padding-bottom: $product-teaser-img-height;
      position: relative;
      height: auto;
      z-index: 1;
    }
  }
  .product--teaser.product--teaser--tiny & {
    float: #{$rdirection};
    width: 50%;
    min-height: 0;
    padding-bottom: 0;
    position: relative;
    height: auto;
  }
  .product--list-view & {
    float: none;
    width: 100%;
  }
}

.product__name,
.product__subline {
  @include swap_direction(margin, 0);
  a {
    @include swap_direction(border, none);
  }
  .collection-quickshop &,
  .product--teaser & {
    @include emphasis-caps;
    line-height: normal;
  }
  .product--teaser & {
    padding-#{$rdirection}: $spacing; // slight indent on right
    @include breakpoint($bp--xlarge-up) {
      padding-#{$rdirection}: 130px; // make room for rating to right
    }
  }
  .product--teaser--tiny & {
    padding-#{$rdirection}: 0;
  }
  .product--full & {
    @include title-section;
    margin-#{$rdirection}: 88px;
    @include breakpoint($bp--xlarge-up) {
      margin-#{$rdirection}: 0;
    }
  }
}

.product__rating {
  .collection-quickshop &,
  .product--teaser--tiny & {
    display: none;
  }
  .product--teaser & {
    line-height: 1;
    margin-bottom: 16px;
    @include breakpoint($bp--medium) {
      @include swap_direction(margin, 0 0 10px 0);
    }
    @include breakpoint($bp--xlarge-up) {
      margin-bottom: 0;
      position: absolute;
      #{$rdirection}: 0;
      text-transform: uppercase;
      top: 0;
    }
  }
  .product-brief-v2.product--teaser & {
    @include breakpoint($bp--xlarge-up) {
      #{$rdirection}: unset;
      #{$ldirection}: 0;
    }
  }
  .product--full & {
    position: absolute;
    top: 6px;
    #{$rdirection}: 9px;
    width: 70px;
    @include breakpoint($bp--xlarge-up) {
      position: static;
      width: 100%;
      top: 15px;
      #{$rdirection}: 15px;
    }
  }
}

.product__description-short {
  @include swap_direction(margin, 14px 0 15px 0);
  line-height: 1.25;
  @include breakpoint($bp--xlarge-up) {
    @include swap_direction(margin, $spacing 0 $spacing 0);
  }
  .product--teaser & {
    position: relative;
    z-index: 100;
    @include breakpoint($bp--small-up) {
      @include swap_direction(margin, 6px 80px 6px 0);
      @if $fonts_update == true {
        font-size: 12px;
      }
    }
    @include breakpoint($bp--medium) {
      @include swap_direction(margin, 6px 25px 6px 0);
    }
  }

  // RESTRICT_GROUP styles for all templates
  .product--restricted & {
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(margin, 6px $spacing $spacing 0);
    }
    .product__restricted {
      &-desc {
        color: $color--black;
      }
      a {
        border-color: $color--red;
        color: $color--red;
      }
    }
    p.product__restricted {
      @include swap_direction(margin, 0 0 5px 0);
    }
  }
  .product--full & {
    @include breakpoint($bp--large-down) {
      display: none;
    }
  }
  .product--teaser--mini.product--sku-product &,
  .product--teaser.product--sku-product.product--teaser--tiny & {
    display: none;
    &.product_rgn_name_below_subline {
      display: block;
    }
  }
  .product--teaser.product--teaser--tiny & {
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(margin, 6px 0 $spacing 0);
    }
  }
  // RESTRICT_GROUP styles for SPP only
  .product--restricted.product--full & {
    display: block;
    .product__restricted-desc {
      @include breakpoint($bp--large-down) {
        display: none;
      }
    }
  }
}

.product__description-shadename {
  clear: both;
}

.product__description-buzzwords {
  margin-#{$rdirection}: 75px;
  text-transform: uppercase;
  display: none;
}

// @todo check with bloom on this display
.product__more-details {
  //display: none; // suzi commented out for small screen display
  .collection-quickshop & {
    display: inline-block;
    //margin-top: $spacing;
  }
}

.product__images {
  .product--teaser & {
    float: #{$ldirection};
    width: 50%;
    @include breakpoint($bp--small-up) {
      float: none;
      width: 100%;
      position: absolute;
      bottom: 59px;
      z-index: 2;
    }
  }
  .collection-quickshop &,
  .product--teaser--tiny &,
  .product--list-view & {
    display: none;
  }

  // Product carousel
  // Specificity here comes from overriding imported styles. We use slick to place
  // this element elsehwere in $product
  text-align: center;
  .slick-initialized {
    cursor: pointer;
    .product-full__video {
      .video-launcher {
        display: block;
        border-bottom: none;
      }
      img {
        @include breakpoint($bp--large-up) {
          margin-top: 20px;
        }
        @include breakpoint($bp--xlarge-up) {
          @include swap_direction(padding, 0 20px);
          margin-top: 0;
        }
        @include breakpoint($bp--xxlarge-up) {
          @include swap_direction(padding, 0);
        }
      }
    }
  }
  .slick-dots {
    @include swap_direction(padding, 20px);
    bottom: 0;
    min-width: 300px;
    position: relative;
    #{$rdirection}: auto;
    width: auto;
    @include breakpoint($bp--large-down) {
      padding-bottom: 0;
      padding-top: 0;
    }
    li {
      @include swap_direction(margin, 0 6px);
      display: inline-block;
      @include breakpoint($bp--large-up) {
        @include swap_direction(margin, 10px 6px);
      }
      width: 10px;
      button {
        @include border-radius(10px);
        @include swap_direction(border, 1px solid $color--gray--black);
        height: 10px;
        width: 10px;
        &:before {
          content: '';
          height: 10px;
          line-height: 10px;
          width: 10px;
        }
      }
      &.slick-active,
      &:hover {
        button {
          background-color: $color--gray--black;
        }
      }
      &.thumb-image-slick {
        @include breakpoint($bp--large-up) {
          float: #{$ldirection};
          margin-#{$ldirection}: 24px;
        }
      }
    }
  }
}

.product__images-inner {
  .product--teaser & {
    text-align: center;
  }
  .product--full & {
    @include transform(translate(flipX(-50%, #{$rdirection}), -50%));
    position: absolute;
    #{$ldirection}: 50%;
    top: 50%;
    height: 100%;
    &.carousel-wrapper {
      @include transform(none);
      width: 100%;
      position: relative;
      top: 0;
      #{$ldirection}: 0;
      img {
        @include swap_direction(margin, 0 auto);
        @include breakpoint($bp--xlarge-up) {
          max-height: 640px;
        }
      }
      > img {
        @include swap_direction(margin, 0 auto);
        display: block;
      }
      .product-full__img .product__sku-image {
        min-height: inherit;
      }
    }
    @include breakpoint($bp--xlarge-up) {
      height: auto;
      min-height: 533px;
      // No declaration of h/w means collapse to size of items inside
      // width: 100%;
      // height: 100%;
    }
    img {
      max-width: 640px;
      width: 100%;
      @include breakpoint($bp--xlarge-up) {
        max-height: none;
      }
    }
  }
}

.product__image-small {
  float: #{$ldirection};
  width: 50%;
}

.product__size {
  display: none;
  .product--full & {
    @include swap_direction(padding, 0 15px);
    display: block;
    margin-top: 10px;
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(padding, 10px 0 0);
      border-top: $border;
      min-height: 29px;
    }
  }
}

.add-to-favorites {
  border-bottom: none;
  text-decoration: none;
  .black-heart {
    color: $color--black;
    .shade-picker__color-actions & {
      color: $color--white;
    }
  }
}

.product--full .product__detail .product__inventory-status {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  @include breakpoint($bp--xlarge-up) {
    margin-left: 0;
    margin-right: 0;
  }
}

.product__add-to-faves {
  font-size: 15px;
  line-height: 1;
  @include breakpoint($bp--xlarge-up) {
    line-height: 2;
  }
  .product--teaser & {
    float: #{$rdirection};
    margin-#{$rdirection}: 5px;
    @include breakpoint($bp--xlarge-up) {
      margin-top: 0;
      margin-#{$rdirection}: 0;
      position: absolute;
      top: 1.2em;
      #{$rdirection}: 0;
    }
  }
  .product--teaser--tiny &,
  .product--list-view & {
    display: none;
  }
  .product--full & {
    float: #{$rdirection};
    font-size: 1.4em;
    margin-#{$rdirection}: 15px;
    @include breakpoint($bp--xlarge-up) {
      position: static;
      width: auto;
      height: auto;
      background: none;
      margin-top: 19px;
      float: #{$rdirection};
      margin-#{$rdirection}: 0;
      border-top: none;
      border-bottom: none;
      > i {
        @include vertically-center($undo: true);
      }
    }
  }
  .collection-quickshop & {
    float: #{$rdirection};
    margin-top: -5px; // pull up a bit
  }
  .product--teaser .product__footer & {
    display: none;
  }
  .product--teaser--tiny .product__footer & {
    display: block;
    margin-top: 0;
    top: -2px;
  }
}

.product__price,
h3.product__price {
  @include emphasis-caps;
  @if $fonts_update == true {
    @include h5();
    @include breakpoint($bp--large-up) {
      font-size: 15px;
    }
  }
  .collection-quickshop & {
    @include swap_direction(margin, 0 0 16px 0);
    display: inline-block;
  }
  .product__header & {
    .product--teaser & {
      float: #{$ldirection};
      margin-bottom: $spacing;
      @include breakpoint($bp--xlarge-up) {
        display: none;
      }
    }
    .product--list-view & {
      float: none;
      position: absolute;
      #{$rdirection}: 0;
      top: 5px;
    }
    .product--full &,
    .product--teaser--tiny & {
      display: none;
    }
  }
  .product__footer & {
    .product--teaser & {
      display: none;
      @if $fonts_update == true {
        @include h5();
        font-size: 15px;
        @include breakpoint($bp--large-up) {
          margin: 0;
        }
      }
      @include breakpoint($bp--xlarge-up) {
        display: block;
        float: none;
        line-height: get-line-height(15px, 28px); // approx size of inline button nearby
        height: 28px;
      }
    }
    .product--teaser--tiny & {
      display: block;
      float: none;
      line-height: 2;
    }
    .product--full & {
      @include swap_direction(margin, $spacing);
      @include breakpoint($bp--xlarge-up) {
        margin-top: 30px;
        margin-bottom: 20px;
        margin-#{$ldirection}: 0;
      }
    }
  }
  .product__price--sale {
    color: $color--red;
  }
  .product__price--non-sale {
    text-decoration: line-through;
  }
  @include breakpoint($bp--xlarge-down) {
    .product__sticky-footer & {
      font-size: 24px;
    }
  }
}

@mixin notifymebtn {
  @include swap_direction(margin, 30px 0);
  color: $color--white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  text-align: center;
  border-style: solid;
  border-color: $color--white;
  border-width: 0;
  display: inline-block;
  background-color: $color--black;
  text-transform: uppercase;
  cursor: pointer;
  @if $fonts_update == true {
    font-family: $ano-black_regular-font;
  } @else {
    font-family: $ano-bold_regular-font;
  }
}

.site-container {
  .product__footer {
    a.notify_me {
      @include notifymebtn;
      @include swap_direction(padding, 5px 15px);
      position: absolute;
      #{$rdirection}: 0;
      &:hover {
        text-decoration: underline;
      }
    }
    a.notify_me:hover {
      text-decoration: underline;
    }
    .product__inv-status-item.sold-out {
      cursor: default;
      background-color: $color--gray--light;
      text-decoration: none;
      font-size: 15px;
      @include breakpoint($bp--small-down) {
        font-size: 18px;
      }
    }
  }
  .artistry--artist,
  .artistry-video-player__products {
    .prod_inv_status-2,
    .prod_inv_status-3 {
      .product__footer {
        .temp-out-of-stock-short__text,
        .temp-out-of-stock-short__msg,
        .coming-soon_text {
          position: absolute;
          bottom: 88%;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          @include breakpoint($bp--small-down) {
            top: -11px;
            #{$ldirection}: 0;
            #{$rdirection}: 0;
          }
        }
        .coming-soon_text {
          @include breakpoint($bp--small-down) {
            #{$ldirection}: 0;
            #{$rdirection}: 0;
          }
        }
        .temp-out-of-stock__text,
        .temp-out-of-stock__msg {
          display: none;
        }
        .temp-out-of-stock-short__text,
        .temp-out-of-stock-short__msg {
          display: block;
        }
      }
    }
    li.product__inv-status-item.sold-out {
      height: 30px;
      line-height: 34px;
      width: 100%;
    }
  }
  .artistry--artist {
    .prod_inv_status-2,
    .prod_inv_status-3 {
      .product__footer {
        a.notify_me {
          @include breakpoint($bp--large-down) {
            @include swap_direction(margin, 0);
          }
          width: 100%;
        }
      }
    }
  }
  .artistry-video-player__products {
    .product__footer {
      .temp-out-of-stock,
      .coming-soon {
        a.notify_me {
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 10px 0);
          width: 100%;
          position: static;
        }
      }
      .temp-out-of-stock__text,
      .coming-soon {
        #{$ldirection}: 0;
        #{$rdirection}: 0;
      }
      .product_content_fav_mobile {
        top: 11px;
      }
    }
    .product--teaser.product--teaser--tiny.prod_inv_status-2,
    .product--teaser.product--teaser--tiny.prod_inv_status-3 {
      min-height: 0;
    }
    .product--shaded.prod_inv_status-2.product--not-orderable,
    .product--shaded.prod_inv_status-3.product--not-orderable {
      .product__footer {
        .product_content_fav_mobile {
          .product__link-to-spp {
            display: inline-block;
          }
        }
      }
    }
  }
  .artist--products__carousel {
    .product__footer {
      .product__inventory-status {
        a.notify_me {
          @include swap_direction(margin, 0);
          width: auto;
          @include breakpoint($bp--large-down) {
            top: 14%;
            height: 60px;
            line-height: 48px;
            width: 100%;
          }
        }
      }
    }
  }
  .collection-products__list,
  .collection-detail-formatter {
    .prod_inv_status-2,
    .prod_inv_status-3 {
      .product__footer {
        a.notify_me {
          width: auto;
          line-height: 49px;
          top: 44%;
          @include breakpoint($bp--large-down) {
            top: 14%;
            height: 60px;
            line-height: 48px;
            font-size: 18px;
          }
        }
        .product__inv-status-item {
          @include breakpoint($bp--large-down) {
            @include swap_direction(margin, 0);
            height: 45px;
          }
        }
      }
    }
    .product--teaser {
      .product__inv-status-item {
        @include breakpoint($bp--large-down) {
          float: none;
        }
      }
    }
  }
  .artistry--artist .carousel {
    li.product__inv-status-item.sold-out {
      width: auto;
      @include breakpoint($bp--large-down) {
        width: 100%;
        height: 60px;
        line-height: 64px;
      }
    }
  }
  .product__inventory-status {
    .js-inv-status-7.sold-out {
      .notify-status {
        display: none;
      }
    }
    .temp-out-of-stock-short__text,
    .temp-out-of-stock-short__msg {
      display: none;
      width: 50%;
    }
    &.misc_flag-139 {
      .js-inv-status-7.sold-out {
        padding: 0;
        @include breakpoint($bp--medium-down) {
          @include swap_direction(margin, 26px 0);
        }
        background-color: transparent;
        .sold_out_btn {
          display: none;
        }
        .notify-status {
          display: block;
          @include breakpoint($bp--medium-down) {
            .notify_me {
              @include swap_direction(padding, 0 15px);
              margin: 0;
              width: 100%;
              line-height: 60px;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

.page-product-products,
.page-product-pro {
  .site-container {
    .product__footer {
      a.notify_me {
        @include notifymebtn;
        @include swap_direction(padding, 0px 20px);
        width: 100%;
        position: static;
        font-size: 1.5rem;
        height: 60px;
        line-height: 64px;
      }
      a.notify_me:hover {
        text-decoration: underline;
      }
    }
  }
}

.product__view-all {
  font-size: 1.5rem;
}

.product__load-more {
  margin-bottom: 10px;
}

.product-full__smart-traffik,
.product__add-to-bag {
  @include btn;
  .product--teaser & {
    @include fontsize-rem($typesize--18px);
    width: 100%;
    clear: both;
    @include breakpoint($bp--small-up) {
      @include btn--small();
      @include swap_direction(padding, 0 $spacing);
      font-size: 15px;
      width: auto;
      clear: none;
      float: #{$rdirection};
    }
    @include breakpoint($bp--large-down) {
      float: #{$ldirection};
      @if $fonts_update == true {
        font-size: 15px;
      }
    }
  }
  .product--full & {
    @include swap_direction(padding, 0 60px 0 0);
    z-index: $z-mid;
    font-size: 1.5em;
    width: 100%;
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(margin, 15px 0);
      @include swap_direction(padding, 0 20px);
      min-width: 100%;
      position: static;
    }
    @include breakpoint($bp--large-down) {
      @include swap_direction(padding, 0);
      margin-#{$ldirection}: 15px;
      margin-#{$rdirection}: 15px;
      display: block;
      width: inherit;
    }
  }
  .collection-quickshop & {
    width: 100%;
    margin-top: $spacing;
  }
  .product--shaded.product--teaser & {
    display: none;
  }
  .product--teaser--mini.product--sku-product & {
    display: inline-block;
  }
  .product--teaser--tiny.product--sku-product & {
    @include btn--small();
    display: inline-block;
    width: 100%;
    clear: both;
    float: none;
  }
  .artistry-video-player-wrapper & {
    width: 100%;
  }
  .sku--purchased & {
    display: none;
  }
}

.product__replenish {
  @include btn;
  @include swap_direction(margin, 15px 0);
  width: 100%;
  min-width: 100%;
  position: static;
  display: none;
  .sku--purchased & {
    display: inline-block;
  }
}

.product-item__unavailable-message {
  @include breakpoint($bp--small-up) {
    @include swap_direction(margin, 0);
    float: #{$rdirection};
    height: 40px;
    line-height: 40px;
  }
}

.product__footer {
  @include clearfix;
  .product--teaser & {
    @include swap_direction(padding, 2px 0 0 0);
    clear: both;
    width: 100%;
    @include breakpoint($bp--small-up) {
      bottom: 0;
      position: absolute;
    }
    @include breakpoint($bp--small-up) {
      float: none;
      border-top: $border;
      padding-top: 18px;
    }
  }
  .product--full & {
    @include breakpoint($bp--xlarge-up) {
      min-height: 600px;
    }
  }
  .product--teaser.product--teaser--tiny & {
    clear: none;
    width: 50%;
    border-top: none;
    padding-#{$ldirection}: $spacing; // abs pos makes this wider need to account for container pad
    padding-top: $spacing;
    position: absolute;
    bottom: $spacing;
    #{$rdirection}: $spacing;
  }
  .product--list-view & {
    display: none;
  }
  .collection-quickshop & {
    border-top: $border;
    padding-top: $spacing; // smoosh handles this apparently
    #{$ldirection}: 0;
    bottom: 0;
    width: 100%;
  }
  @include breakpoint($bp--large-down) {
    .BVRRRatingSummary {
      @include swap_direction(padding, 1.5em 15px);
    }
  }
  .product_content_fav_mobile {
    position: relative;
    margin-bottom: -30px;
    @include breakpoint($bp--xlarge-up) {
      float: #{$ldirection};
      display: inline-block;
      width: 100%;
    }
    a.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.product__link-to-spp {
  .collection-quickshop & {
    display: none;
  }
  .product--full & {
    display: none;
  }
  .product--teaser & {
    display: none;
  }
  .product--teaser.product--shaded & {
    @include btn;
    display: block;
    width: 100%;
    clear: both;
    @if $fonts_update == false {
      @include fontsize-rem($typesize--18px);
    }
    @include breakpoint($bp--small-up) {
      @include btn--small();
      clear: none;
      display: inline-block;
      float: #{$rdirection};
      font-size: 15px;
      width: auto;
    }
    @include breakpoint($bp--large-down) {
      float: #{$ldirection};
    }
    .artistry-video-player-v1.artistry-video-player-wrapper & {
      width: 100%;
    }
  }
  .product--teaser--tiny.product--shaded & {
    @include swap_direction(padding, 0 10px);
    float: none;
    font-size: 15px;
    width: auto;
  }
}

// ow
.product--teaser.product--shaded.product--teaser--mini.product--sku-product .product__link-to-spp,
.product--teaser.product--shaded.product--teaser--tiny.product--sku-product .product__link-to-spp {
  display: none;
}

.hide__shop__shade .product__link-to-spp {
  display: none !important;
}

.hide__shop__shade .product__add-to-bag,
.hide__shop__shade.prod_inv_status-7 .sold-out,
.hide__shop__shade.product--coming-soon .coming-soon-text {
  display: block !important;
}

.product__shipping,
.product__edd {
  text-align: center;
  text-transform: uppercase;
  padding-top: 15px;
}

.product__product-details-shade {
  display: none;
  .product--full & {
    @include swap_direction(margin, $spacing $spacing 0);
    display: block;
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(margin, 0);
    }
  }
  .collection-quickshop & {
    display: block;
    margin-bottom: $spacing;
  }
  &--single {
    border-top: $border;
    div.product__product-details-shade-name {
      margin-top: 7px;
    }
  }
}

.product__share-wrapper--pc {
  float: #{$rdirection};
  margin-top: 20px;
  .product__share {
    position: relative;
    &:hover .product__social-links {
      display: block;
    }
  }
}

.product__share-wrapper--mobile {
  @include swap_direction(padding, $spacing 0);
  text-align: center;
  .product__social-links {
    @include swap_direction(margin, 0 auto);
    overflow: hidden;
  }
}

.product__social-links {
  @include breakpoint($bp--large-up) {
    position: absolute;
    top: 0;
    #{$rdirection}: 0;
  }
  display: none;
  background: $color--white;
  width: 5.5em;
  li {
    float: #{$ldirection};
    clear: none;
    margin-#{$rdirection}: 5px;
    cursor: pointer;
  }
  &.share_show {
    display: block;
  }
}

.product__find-store {
  float: #{$rdirection};
  display: none; // @todo Feature not available at launch hiding temp
  @include breakpoint($bp--medium-down) {
    float: none;
  }
}

.shade-picker-dropdown {
  display: none;
  .select2-container {
    border-top: $border;
    border-bottom: $border;
    border-width: 1px;
    border-style: solid;
    border-color: $color--gray--lighter transparent;
    width: calc(100% - 15px);
    margin-#{$ldirection}: 7px;
    .select2-choice {
      margin-#{$ldirection}: -7px;
      overflow: visible;
      .select2-arrow {
        #{$rdirection}: -20px;
        top: 25px;
      }
    }
    &.select2-dropdown-open {
      .select2-choice {
        margin-#{$ldirection}: 0;
        overflow: hidden;
        .select2-arrow {
          #{$rdirection}: -5px;
        }
      }
    }
    .select2-chosen {
      margin-#{$ldirection}: 7px;
    }
  }
  .select2-dropdown-open {
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: $color--gray--lighter;
    width: 100% !important;
    margin-#{$ldirection}: 0;
    .select2-choice {
      margin-#{$ldirection}: 0;
    }
  }
  .product--full & {
    .select2-arrow b {
      @include corner-triangle();
      .csstransforms & {
        border-width: 7px;
        margin-top: 0;
      }
      &:before {
        @include transform(rotate(0deg));
        bottom: 1.4em;
        content: none;
      }
    }
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(padding, 0 7px);
      display: block;
      #{$ldirection}: 50%;
      position: absolute;
      width: 25%;
      z-index: 100;
      .select2-choice {
        @include swap_direction(border, none);
        padding-#{$ldirection}: 0;
      }
      .select2-arrow b {
        height: 43px;
        display: inline-block;
      }
    }
  }
}

.product__product-details-shade-smoosh {
  display: none;
  .product--full & {
    display: block;
    @include breakpoint($bp--xlarge-up) {
      display: none;
    }
  }
  .collection-quickshop & {
    @include swap_direction(margin, 0 0 $spacing);
    display: block;
  }
}

.product__product-details-shade-smoosh--mini {
  display: none;
  .mobile-collection-slide & {
    display: block;
    margin: 0 0 $spacing;
  }
}

.product__product-details-shade-name {
  .product--full & {
    @include heading--small;
    margin-top: 20px;
  }
  .collection-quickshop & {
    @include emphasis-caps;
  }
}

.product__shade-finish {
  .collection-quickshop & {
    clear: both;
    margin-bottom: $spacing;
  }
}

// Product Sticky Bar
.product__sticky-wrapper {
  display: none;
  z-index: $z-highest;
  .product--full & {
    // Medium up, .product--full: sticky to top
    @include breakpoint($bp--xlarge-up) {
      width: 100%;
      display: block;
      overflow: visible;
      position: fixed;
      top: 60px;
      #{$ldirection}: 0;
      height: 0; // allows bars underneathe to interact, make sure overflow: visible
      z-index: $z-above-prod-sub-nav;
    }

    // specific to pl view all
    .sg-pattern & {
      @include breakpoint($bp--xlarge-up) {
        position: absolute;
      }
    }
  }
  .toolbar .product--full & {
    top: 120px;
  }
  .product__inv-status-item.coming-soon {
    background: $color--black;
    font-family: $ano-bold_regular-font;
    font-size: 15px;
    text-decoration: none;
    .notify-status {
      background-color: none;
    }
    .coming-soon_text {
      display: none;
    }
    a.notify_me {
      background-color: $color--black;
      color: $color--white;
      border-bottom: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.product__sticky-container {
  .product--full & {
    position: relative;
  }
}

.view-all-shades {
  pointer-events: all;
  .product--full & {
    @include breakpoint($bp--xlarge-up) {
      width: 100%;
    }
  }
  .product--teaser & {
    display: none;
  }
}

.product__bag-bar {
  pointer-events: all;
  .product--full & {
    @include animation-all;
    @include breakpoint($bp--xlarge-up) {
      position: absolute;
      width: 100%;
      height: 60px;
      top: -60px;
      display: block;
      z-index: $z-above-clinks;
    }
  }
}

.product__bag-bar--sticky {
  .product--full & {
    // animation works on min-height
    top: 0;
  }
}

.product__bag-bar--sticky.product__bag-bar--sticky-banner-down {
  .product--full & {
    // animation works on min-height
    top: 60px;
  }
}

/* Grid Layout for SPP
  ========================================================================== */
/*
* We need the entire SPP to span the whole width but leave room for
* shade picker column.
*/

.product__sku-details {
  .product--teaser & {
    height: 100%;
  }
}

.product__shade-column,
.product__shade-single {
  position: relative;
  z-index: 100;
  // Column far right, full height on full
  .product--full & {
    @include animation-all;
    @include transform(translate3d(flipX(100%, #{$rdirection}), 0, 0));
    background: $color--gray--dark;
    position: fixed;
    top: 60px;
    bottom: 0;
    #{$rdirection}: 0;
    width: 87.5%;
    min-height: 100%;
    z-index: $z-high;
    @include breakpoint($bp--xlarge-up) {
      @include transform(translate3d(0, 0, 0));
      background: none;
      padding-#{$ldirection}: ($spacing * 2);
      position: absolute;
      top: 0;
      #{$rdirection}: 0;
      width: 25%;
      min-height: $product-detail-min-height; // set to same height as image
      border-#{$ldirection}: none;
    }
  }
  @include transform(translate3d(0, 0, 0));
  .collection-quickshop & {
    display: none;
  }
  .product--show-mobile-shade-column & {
    #{$rdirection}: 87.5%;
    @include breakpoint($bp--xlarge-up) {
      #{$rdirection}: 0;
    }
  }
}

.spp-workswith__header {
  @include h5();
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 21px 0 15px);
  line-height: 1;
  @include breakpoint($bp--xlarge-up) {
    @include fontsize-rem($typesize--36px);
  }
}

.spp-workswith__products {
  margin-top: 0;
  padding-top: 0;
}

//SPP Alt Video Overlay
.overlay__alt-video {
  #cboxLoadedContent {
    @include swap_direction(padding, 0);
  }
  #cboxClose {
    color: $white;
  }
}

// Hide the default image if we've rendered a js sku
.product__sku-image--default {
  [data-product*='"rendered":true'] & {
    display: none;
  }
}

// custom CTA button only displays if we have the proper wrapper
.product__footer {
  .product__link-to-spp {
    .product__link-custom {
      display: none;
      @include btn;
      float: #{$rdirection};
      @include breakpoint($bp--small-up) {
        @include btn--small();
      }
    }
  }
}
.product--cta-custom .product__link-to-spp {
  .product--cta-custom {
    display: inline-block;
  }
}

.product--cta-custom {
  .product__add-to-bag,
  .product__add-to-faves {
    display: none;
  }
}

// Low inventory styling, yes, totally change this to something sane
.product--inventory-zero {
  opacity: 0.25;
  background-color: $color--blue;
}

// mini sku teaser
// following classes are in play: product--teaser--mini product--sku-product

.product__mini-detail {
  @include swap_direction(margin, 14px 0 15px 0);
  clear: both;
  line-height: 1.25;
  .product--teaser & {
    @include breakpoint($bp--small-up) {
      @include swap_direction(margin, 6px 80px $spacing 0);
      @if $fonts_update == true {
        font-size: 12px;
      }
    }
  }
}

.product--teaser--mini.product--shaded .product__mini-detail--size {
  display: none;
}

// product description small screen
.product__description--compact {
  @include breakpoint($bp--xlarge-up) {
    display: none;
  }
}
// Accordion hide/show overlay PC/large screen
.product__description-overlay-accordion {
  display: none;
  position: relative;
  z-index: 500;
  @include breakpoint($bp--xlarge-up) {
    display: block;
  }
}

.product__description-dummy-column {
  visibility: hidden;
}

.product__description-group {
  position: relative;
}

.product__description-trigger-title {
  @include body-mono();
  @include set-underline-links();
  cursor: pointer;
  display: inline-block;
}

.product__description-group-body {
  display: none;
  min-height: 29.5em;
  $active-bg-color: rgba($color--white, 0.95);
  .active & {
    display: block;
    position: absolute;
    top: 25px;
    #{$ldirection}: 0;
    width: 100%;
    padding-bottom: 15px;
    background: $active-bg-color;
    z-index: 1;
  }
  ul {
    list-style: disc;
    list-style-image: image-url('global/mac-bullet-small.png');
    margin-#{$ldirection}: 0;
  }
}

.product__description-desc--trigger {
  margin-bottom: 5px;
  .icon--remove {
    position: absolute;
    #{$rdirection}: 15px;
  }
}

// Medium images
img.product__sku-image--alt--medium {
  display: none;
}

.product__image-medium--alt:hover {
  .product__sku-image--default--medium {
    display: none;
  }
  .product__sku-image--alt--medium {
    display: block;
  }
}

.product__image-medium-link {
  border-bottom: none;
  outline: none !important;
}

// hiding content based on inventory status
.hide-non-shoppable-product.product--not-shoppable,
.hide-coming-soon-product.product--coming-soon,
.hide-non-orderable-product.product--not-orderable,
.product--not-shoppable .hide-non-shoppable-product,
.product--shoppable .hide-shoppable-product,
.product--coming-soon .hide-coming-soon-product,
.product--not-preorder .hide-non-preorder,
.prod_inv_status-2.product--preorder .temp-out-of-stock,
.prod_inv_status-3.product--preorder .coming-soon,
.prod_inv_status-5.product--preorder .inactive,
.prod_inv_status-7.product--preorder .sold-out {
  display: none !important;
}

.product--sku-product.product--not-orderable .hide-non-orderable-product {
  display: none;
}

.product--preorder.product--not-shoppable .product__inventory-status li.preorder-noshop {
  display: block;
  background-color: $color--gray--light;
  cursor: default;
  text-decoration: none;
  line-height: normal;
  padding-top: 21px;
  @include breakpoint($bp--large-down) {
    @include swap_direction(margin, 0 15px);
    font-size: 1.5em;
  }
  .grid--mpp__item & {
    @include swap_direction(padding, 0 25px 0 25px);
    float: right;
    height: 2em;
    line-height: 2em;
    font-size: 1.25em;
    margin: 0;
    @include breakpoint($bp--large-down) {
      height: 60px;
      line-height: 62px;
      text-align: center !important;
      float: none !important;
      font-size: 18px;
    }
  }
}

.product__name-eyebrow--preorder {
  @include emphasis-caps;
  color: $color--red;
  display: none;
}

.product--preorder .product__name-eyebrow--preorder {
  display: block;
}

.product_header_details .product__product-details-preorder-desc {
  text-transform: uppercase;
  margin: 15px 0 0 0;
  @include breakpoint($bp--xlarge-up) {
    margin: 1em 0 1em 0;
  }
}

.product--teaser__msg-preorder {
  display: none;
}

.product--preorder .product--teaser__msg-preorder {
  display: block;
}

// fading images/content based on inventory status
//.product--not-shoppable .fade-non-shoppable-product,
//.product--coming-soon .fade-coming-soon-product,
.product--not-orderable .fade-non-orderable-product {
  opacity: 0.5;
}

.product--shop-shades-hidden.prod_inv_status-7 {
  .product__footer {
    .product_content_fav_mobile {
      li.sold-out {
        display: block;
      }
      .product__link-to-spp {
        display: none !important;
      }
    }
  }
}

// for fading images - hide background loading gif
.product--not-shoppable .product__images,
.product--coming-soon .product__images,
.product--not-orderable .product__images {
  background-image: none;
}

// for specific inventory status messages
.product__inventory-status {
  li.product__inv-status-item {
    @include swap_direction(padding, 0 15px 15px);
  }
  li.temp-out-of-stock,
  li.coming-soon {
    @include swap_direction(padding, 6px 0px);
  }
}

.product__inv-status-item {
  display: none;
  .product--teaser & {
    @include swap_direction(margin, 0);
    float: #{$rdirection};
  }
  .product--teaser--tiny & {
    float: none;
  }
}

.prod_inv_status-2.product--not-preorder .temp-out-of-stock,
.prod_inv_status-3.product--not-preorder .coming-soon,
.prod_inv_status-5.product--not-preorder .inactive,
.prod_inv_status-7.product--not-preorder .sold-out {
  display: block;
  .grid--mpp__item & {
    display: none;
  }
  .artist--products__carousel-slide & {
    display: none;
  }
  .product__bag-bar & {
    @include swap_direction(margin, $spacing 0 0 0);
  }
}

.prod_inv_status-2 .temp-out-of-stock.display__notify-me {
  .grid--mpp__item & {
    display: block;
  }
}

.artist--products__carousel-slide {
  .shade-picker__colors-mask {
    .prod_inv_status-1 {
      .sold-out {
        display: none;
      }
    }
  }
}

.prod_inv_status-3 .coming-soon,
.prod_inv_status-7 .sold-out {
  .product__bag-bar & {
    @include swap_direction(margin, $spacing 0 0 0);
  }
}

.prod_inv_status-7 .product__footer .sold-out {
  .grid--mpp__item & {
    .site-container & {
      @include breakpoint($bp--small-up) {
        @include swap_direction(padding, 0px 15px);
        display: inline-block;
        height: 30px;
        line-height: 30px;
        clear: none;
        float: #{$ldirection};
        font-size: 15px;
        width: auto;
      }
      @include breakpoint($bp--large-up) {
        float: #{$rdirection};
      }
      height: 60px;
      line-height: 62px;
      text-align: center !important;
      float: none;
      font-size: 18px;
    }
  }
}

li.shade-picker__color.prod_inv_status-5 .inactive,
li.shade-picker__color.prod_inv_status-3 .coming-soon,
li.shade-picker__color.prod_inv_status-2 .temp-out-of-stock,
li.shade-picker__color.prod_inv_status-7 .sold-out {
  .field-elc-mpp-content .grid--mpp__item & {
    display: block;
  }
  .artist--products__carousel-slide & {
    display: block;
  }
}

li.shade-picker__color.prod_inv_status-2 .hide-inventory__status .temp-out-of-stock {
  .field-elc-mpp-content .grid--mpp__item & {
    display: none;
  }
}

.shop-the-collection__quickshop .prod_inv_status-2,
.shop-the-collection__quickshop .prod_inv_status-3,
.shop-the-collection__quickshop .prod_inv_status-5,
.shop-the-collection__quickshop .prod_inv_status-7 {
  .product__inv-status-item {
    @include swap_direction(margin, 15px 0 0 0);
    font-size: 12px;
    line-height: 1.25;
    text-transform: uppercase;
  }
  .product__inv-status-item.sold-out {
    line-height: 4.25;
    @include breakpoint($bp--large-up) {
      @include swap_direction(margin, 14% 0 0 0);
    }
  }
}

.shop-the-collection__quickshop .prod_inv_status-2,
.shop-the-collection__quickshop .prod_inv_status-1 {
  .product__product-details-shade {
    min-height: 0;
  }
  .product__inv-status-item {
    @include swap_direction(margin, 0);
  }
  .product__add-to-bag {
    margin-top: 6px;
  }
}

.shop-the-collection__quickshop .prod_inv_status-3 {
  .product__inv-status-item {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 6px 0);
    height: 50px;
  }
}

.shop-the-collection__quickshop .prod_inv_status-2 {
  .product__inv-status-item.display__notify-me {
    height: 105px;
  }
}
// product life
.product__product-life-tooltip {
  @include arrow($color: $color--gray, $direction: up);
  display: none;
  position: relative;
  top: -13px;
  margin-#{$ldirection}: 5px;
  content: '';
  height: 0;
  width: 0;
  &:hover,
  &:visited {
    border-bottom-color: $color--gray;
  }

  // .prod_life_of_product-2 = basic reorder
  .prod_life_of_product-1 &,
  .prod_life_of_product-3 & {
    display: inline;
  }
}

// spp override with 3-up images showing
.product-full__3up-images {
  @include breakpoint($bp--xlarge-up) {
    .product-full__img {
      clear: #{$rdirection};
      float: #{$rdirection};
      max-width: 320px;
      width: 25%;
      max-height: 315px;
      &:first-child {
        clear: none;
        float: #{$ldirection};
        max-width: none;
        width: 75%;
        text-align: center;
        max-height: none;
      }
      img {
        width: 100%;
        max-width: 640px;
      }
    }
  }
}

// single product display randomized from array
.product--random {
  @include swap_direction(padding, 0 $spacing);
}

.after-product {
  @include breakpoint($bp--xlarge-up) {
    border-top: $border;
  }
  .product__reviews-spp {
    @include breakpoint($bp--small-down) {
      .BVRRContainer {
        .BVFieldSearch {
          margin: 0 15px;
        }
        .BVRRDisplayContentLinkWrite a,
        .BVDIInside.BVDI_QTInside,
        .BVDI_QTSummaryBox,
        .BVDI_QTHeader,
        #BVRRQuickTakeContentContainerID {
          border-width: 0;
        }
      }
    }
    #BVSubmissionContainer {
      @include swap_direction(margin, 0 auto);
      #BVDivProductImageContainerID .carousel-slide {
        max-width: 600px;
      }
    }
    .BVRRContainer .BVDI_QTHeader .BVDI_QTTitle {
      margin-bottom: 0;
    }
    #BVRRQuickTakeContentContainerID {
      border-top: 0;
    }
  }
}

.product__reviews-spp {
  #BVRRContainer.BVRRNoReviewContainerHide {
    display: none;
    @include breakpoint($bp--medium-down) {
      display: block;
    }
  }
  .BVRRContainer .BVDI_QTHeader .BVDI_QTTitle {
    margin-bottom: 0;
  }
  .BVRRRatingsOnlySummary {
    border-top: 0;
  }
}

// new styles for star ratings
// off stars - grey
.product__rating-stars {
  display: inline-block;
  position: relative;
  width: 90px;
  height: 20px;
  background-image: url('/sites/maccosmetics/themes/mac_base/images/icons/src/stars_reviews_0_stars.svg');
  background-repeat: no-repeat;
  .product__rating-percentage {
    display: inline-block;
    position: absolute;
    height: 20px;
    overflow: hidden;
  }
}
// on stars - black
.product__rating-stars-rating {
  width: 90px;
  position: absolute;
  height: 110%;
  @include breakpoint($bp--medium-up) {
    height: 100%;
  }
  overflow: hidden;
  background-image: url('/sites/maccosmetics/themes/mac_base/images/icons/src/stars_reviews_5_stars.svg');
  background-repeat: no-repeat;
}
//MPP star
.grid--mpp__item {
  .product-brief-v2 {
    .product__rating-stars {
      background-image: none;
      .opaque {
        opacity: 0.5;
      }
    }
  }
}
// hide stars on custom palette MPP
.mpp-custom--custom-palette .product__rating {
  display: none;
}

.mpp-custom--custom-palette {
  .product__header {
    padding-#{$ldirection}: 15px;
    @include breakpoint($bp--medium-up) {
      padding-#{$ldirection}: 0;
    }
  }
  .product__footer {
    .product__link-custom {
      float: $rdirection;
      @if $cr21 == false {
        @include btn;
        @include breakpoint($bp--small-up) {
          @include btn--small();
        }
      } @else {
        @include cta-primary-filled;
      }
    }
    .product__inventory-status {
      .product__inv-status-item {
        &.coming-soon,
        &.sold-out {
          @include body-text--small-bold;
          background-color: transparent;
          color: $color--gray--light;
        }
        &.coming-soon {
          text-transform: lowercase;
        }
        &.sold-out {
          text-transform: uppercase;
        }
      }
    }
  }
  .all-shaded-sold-out {
    .product__link-custom {
      display: none;
    }
  }
}

.bv_hide {
  display: none;
}

// padding for PC product subgroup headers; typically expando-block for small screen
.product__subgroup-heading {
  @include breakpoint($bp--xlarge-up) {
    @include swap_direction(padding, 40px 0);
  }
}

// tiny detail specifics
.product__tiny-detail {
  display: none;
  .product--sku-product & {
    display: block;
  }
}

.page--spp__product {
  .shade-picker__colors {
    width: 96%;
    @include breakpoint($bp--medium-up) {
      width: 100%;
    }
  }
  .prod_inv_status-2 {
    .product__footer {
      .product__add-to-bag {
        @include swap_direction(margin, 0);
        @include breakpoint($bp--large-down) {
          @include swap_direction(margin, 0 15px 0 15px);
        }
      }
    }
  }
  .product--not-shoppable.prod_inv_status-2 {
    .product__footer {
      .product__inventory-status.temp-out-of-stock_notifyme-spacing {
        @include swap_direction(margin, 0 0 60px 0);
      }
      .product__inventory-status.temp-out-of-stock__message-spacing {
        @include swap_direction(margin, 0 0 45px 0);
      }
    }
  }
  .product--not-shoppable.prod_inv_status-7 {
    .product__shipping--pc {
      @include breakpoint($bp--large-up) {
        clear: both;
      }
    }
  }
  .shade-picker__tools {
    background: $color--gray--lightest;
    text-transform: uppercase;
    &-heading {
      @include h6();
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 32px 10px 0 10px);
      background: $color--gray--lightest;
      border-bottom: $border;
      text-transform: uppercase;
      height: 60px;
      line-height: 1;
      width: 100%;
    }
    &-title {
      @include h6();
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 32px 10px 0 10px);
      border-bottom: $border;
      height: 60px;
      line-height: 1;
    }
    &-sort {
      @include swap_direction(padding, 0 10px);
      border-bottom: $border;
      min-height: 60px;
      &-toggle {
        @include icon(arrow--down);
        cursor: pointer;
        line-height: 60px;
      }
      &.active {
        @include icon(arrow--up);
        &:before {
          float: #{$rdirection};
          line-height: 60px;
        }
      }
      &-items {
        @include swap_direction(padding, 0 0 10px 0);
        li {
          @include swap_direction(padding, 5px 0 15px 0);
        }
      }
    }
    &-filters {
      &-toggle {
        @include icon(arrow--down);
        border-bottom: $border;
        cursor: pointer;
        line-height: 60px;
        min-height: 60px;
        padding: 0 10px;
        &:before {
          @include swap_direction(padding, 0 10px);
          float: #{$rdirection};
          line-height: 60px;
          min-height: 60px;
          &:before {
            float: #{$rdirection};
            line-height: 60px;
          }
        }
      }
      .shade-picker__tools-title {
        @include icon(arrow--down);
        @include swap_direction(padding, 0 16px 0 10px);
        cursor: pointer;
        line-height: 60px;
        &:before {
          float: #{$rdirection};
          line-height: 60px;
        }
        &.active {
          @include icon(arrow--up);
          @include swap_direction(border, none);
          &:before {
            float: #{$rdirection};
            line-height: 60px;
          }
        }
      }
      &-clear {
        @include icon(remove);
        border-bottom: $border;
        cursor: pointer;
        line-height: 60px;
        min-height: 60px;
        padding: 0 10px;
        &:before {
          @include swap_direction(padding, 0 10px);
          float: #{$rdirection};
          line-height: 60px;
          min-height: 60px;
          &:before {
            float: #{$rdirection};
            line-height: 60px;
          }
        }
      }
      &-items {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0 10px 10px 10px);
        border-bottom: $border;
        li {
          @include swap_direction(padding, 5px 0 15px 0);
          line-height: normal;
        }
        a {
          display: block;
          &.active {
            @include icon(check);
            &:before {
              float: #{$rdirection};
            }
          }
        }
      }
    }
  }
  .product--full {
    .product {
      &__add-to-bag {
        @include breakpoint($bp--large-down) {
          @include swap_direction(margin, 11px 15px 0 15px);
        }
      }
    }
  }
}

.grid--mpp__item,
.artist--products__carousel,
.artistry-video-player__products {
  .product--shaded.prod_inv_status-2,
  .product--shaded.prod_inv_status-7,
  .product--shaded.prod_inv_status-3 {
    &.product--not-orderable {
      .product__footer {
        .product_content_fav_mobile {
          .product__link-to-spp {
            display: inline-block;
          }
        }
      }
    }
    .product__footer {
      .product__inventory-status {
        .product__inv-status-item {
          display: none;
        }
      }
    }
    .temp-out-of-stock__text,
    .coming_soon-full_text {
      display: none;
    }
    .fade-non-shoppable-product {
      opacity: 1;
    }
  }
  .all-shaded-sold-out.prod_inv_status-2,
  .all-shaded-sold-out.prod_inv_status-7,
  .product--single-not-orderable.prod_inv_status-7 {
    &.product--not-orderable {
      .product__footer {
        .product_content_fav_mobile {
          .product__link-to-spp {
            display: none;
          }
        }
      }
    }
    .product__footer {
      .product__inventory-status {
        .sold-out {
          display: block;
        }
      }
    }
    .fade-non-shoppable-product {
      opacity: 0.5;
    }
  }
}

.section-esearch .grid--mpp__item,
.section-esearch .artist--products__carousel,
.section-esearch .artistry-video-player__products {
  .product--sku-product.prod_inv_status-2.product--not-orderable,
  .product--sku-product.prod_inv_status-3.product--not-orderable,
  .product--sku-product.prod_inv_status-7.product--not-orderable {
    .product__footer {
      .product_content_fav_mobile {
        .product__link-to-spp {
          display: none;
        }
      }
    }
  }
  .product--sku-product.prod_inv_status-2 {
    .product__footer {
      .product__inventory-status {
        .temp-out-of-stock.display__notify-me {
          display: block;
        }
      }
    }
  }
  .product--sku-product.prod_inv_status-3 {
    .product__footer {
      .product__inventory-status {
        .coming-soon {
          display: block;
        }
      }
    }
  }
  .product--sku-product.prod_inv_status-7 {
    .product__footer {
      .product__inventory-status {
        .sold-out {
          display: block;
        }
      }
    }
  }
  .product--sku-product.all-shaded-sold-out {
    .product_content_fav_mobile {
      .product__add-to-bag {
        display: none;
      }
    }
  }
}

.artistry--artist {
  .product--teaser--tiny {
    .product__footer {
      .product__price {
        @include breakpoint($bp--small-down) {
          float: none;
          margin: 0;
        }
      }
      .product_content_fav_mobile {
        @include breakpoint($bp--large-up) {
          position: relative;
        }
      }
    }
  }
  .product--teaser--tiny.prod_inv_status-2,
  .product--teaser--tiny.prod_inv_status-3 {
    .product__price {
      @include breakpoint($bp--large-up) {
        @include swap_direction(margin, 0 0 27px 0);
      }
    }
  }
  .product--teaser--tiny.prod_inv_status-2 {
    .product__price.product__price_status {
      @include breakpoint($bp--large-up) {
        @include swap_direction(margin, 0);
        display: inline-block;
        float: left;
        line-height: 40px;
      }
    }
  }
  .product--teaser--tiny.prod_inv_status-3 {
    .product__price.product__price_status {
      @include breakpoint($bp--large-up) {
        display: inline-block;
        float: left;
        line-height: 40px;
        margin: 0 0 22px 0;
      }
    }
  }
  .product--teaser--tiny.prod_inv_status-7 {
    .product__price.product__price_status {
      @include breakpoint($bp--large-up) {
        display: inline-block;
        float: none;
        line-height: 40px;
      }
    }
  }
  .product--teaser.product--teaser--tiny.prod_inv_status-1 {
    @include swap_direction(margin, 0);
    min-height: 240px;
  }
  .artist--products__carousel-slide {
    .product__inventory-status {
      .product__inv-status-item {
        .temp-out-of-stock-short__text,
        .temp-out-of-stock-short__msg {
          display: none;
        }
      }
    }
  }
}
//ppu & installment price alignment in same line
.shop-the-collection {
  &__quickshop {
    .product {
      &__footer {
        .product_content_fav_mobile {
          .product__price {
            display: block;
          }
          .product__price_ppu {
            float: #{$ldirection};
          }
          .product__price-installment {
            float: #{$rdirection};
          }
          .product {
            &__inventory-status {
              clear: both;
            }
          }
        }
      }
    }
  }
}

.artistry-shop-colors-v1 {
  .product--teaser {
    .product__footer {
      .product__price {
        @include breakpoint($bp--xlarge-up) {
          display: inline-block;
          float: #{$ldirection};
          line-height: 40px; // approx size of inline button nearby
        }
      }
    }
  }
}

.artistry-shop-colors-v1,
.artistry-video-player__products {
  .product--teaser.prod_inv_status-3 {
    .product__footer {
      .product__inventory-status li.coming-soon-text {
        @include swap_direction(padding, 0);
        position: absolute;
        bottom: 88%;
      }
    }
  }
}

.grid--mpp__item,
.artistry--artist,
.artistry-video-player__products {
  .product__header {
    .product_header_details {
      .product_rgn_name_below_subline {
        display: block;
      }
    }
  }
}

.header-search__result {
  .header-search__result__description {
    .product_rgn_name_below_subline {
      display: block;
    }
  }
}

.product_rgn_name_below_subline {
  text-transform: uppercase;
  @include breakpoint($bp--large-down) {
    @include swap_direction(margin, 14px 0 15px 0);
  }
}

.site-bag__contents-products {
  .cart-product-name {
    .product__subline {
      @include swap_direction(padding, 0 0 10px 0);
      display: block;
    }
    .product_rgn_name_below_subline {
      @include swap_direction(padding, 10px 0);
      display: block;
      line-height: 1.5;
      font-size: 12px;
      @if $fonts_update == true {
        font-family: $body-new-font-family;
      } @else {
        font-family: $body-font-family;
      }
    }
  }
}

.grid--mpp__item {
  .hide__shop__shade.product--shaded {
    &.prod_inv_status-2,
    &.prod_inv_status-3,
    &.prod_inv_status-7 {
      &.product--not-orderable {
        .product__footer {
          .product__link-to-spp {
            display: none;
          }
        }
      }
    }
    &.prod_inv_status-2 {
      .product__footer {
        .product__inventory-status {
          .temp-out-of-stock.display__notify-me {
            display: block;
          }
        }
      }
    }
    &.prod_inv_status-3 {
      .product__footer {
        .product__inventory-status {
          .coming-soon {
            display: block;
          }
        }
      }
    }
    &.prod_inv_status-7 {
      .product__footer {
        .product__inventory-status {
          .sold-out {
            display: block;
          }
        }
      }
      .fade-non-shoppable-product {
        opacity: 0.5;
      }
    }
  }
}

.tabbed-block {
  .tabbed-block__tabs {
    text-align: center;
  }
  .tabbed-block__title {
    font-family: $ano-bold_regular-font;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 3.2rem;
    line-height: 1;
    margin: 0.25em 0.5em;
    text-align: center;
  }
  .tabbed-block__tabs-item {
    display: inline-block;
    vertical-align: top;
    font-family: $ano-bold_regular-font;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 2.2rem;
    line-height: 0.9;
    padding: 0.15em 1em;
    border: 1px solid $color--black;
    background: $white;
    color: $black;
    @include breakpoint($bp--medium-down) {
      font-size: 1.8rem;
      padding: 0.15em 0;
      width: 84px;
      height: 40px;
      p {
        margin-top: 3px;
      }
    }
    @include breakpoint($bp--large-up) {
      width: 139px;
      height: 40px;
    }
  }
  .tabbed-block__tabs-item:hover,
  .tabbed-block__tabs-item:active,
  .tabbed-block__tabs-item.active {
    color: $white;
    background: $black;
    border-color: $black;
  }
  .expando-block {
    border: 0;
  }
  .expando-block__content {
    height: auto;
    overflow: hidden;
    @include breakpoint($bp--xxxsmall-up) {
      margin-bottom: 0px;
    }
    @include breakpoint($bp--medium-up) {
      padding: $spacing $spacing 0;
    }
  }
  .expando-block--expanded .expando-block__content {
    height: auto;
    @include breakpoint($bp--medium-up) {
      display: block;
      height: auto;
    }
  }
  .tabbed-block__content .tabbed-block__content-item.show-on-hover .grid--mpp .grid--mpp__item {
    .product__header,
    .product__footer {
      @include breakpoint($bp--medium-up) {
        opacity: 0;
        transition: all 300ms ease-out;
        -moz-transition: all 300ms ease-out;
        -webkit-transition: all 300ms ease-out;
      }
    }
    .product--teaser {
      $border-top-color: rgba(199, 199, 199, 0);
      @include breakpoint($bp--medium-up) {
        border-top-color: $border-top-color;
        transition: all 300ms ease-out;
        -moz-transition: all 300ms ease-out;
        -webkit-transition: all 300ms ease-out;
      }
    }
  }
  .tabbed-block__content .grid--mpp .grid--mpp__item {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    @include breakpoint($bp--small-up) {
      min-height: 495px;
      width: 50%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -moz-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 15px;
    }
    @include breakpoint($bp--medium-up) {
      min-height: 495px;
      width: 25%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
      -moz-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    @include breakpoint($bp--medium-up) {
      .product--teaser .shade-picker {
        bottom: 49px;
      }
    }
  }
  .tabbed-block__content-item.show-on-hover .grid--mpp .grid--mpp__item:hover {
    .product__header,
    .product__footer {
      @include breakpoint($bp--medium-up) {
        opacity: 1;
      }
    }
    .product--teaser {
      $border-top-color: rgba(199, 199, 199, 1);
      @include breakpoint($bp--medium-up) {
        border-top-color: $border-top-color;
      }
    }
  }
  .slick-dots {
    position: relative;
    text-align: center;
    padding: 0.5rem;
    margin: 0;
    list-style: none;
    li {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      padding: 0;
      width: auto;
      height: auto;
      line-height: 1;
      button {
        margin: 0 5px;
        padding: 0;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        border: 1px solid $color--black;
        display: inline-block;
        vertical-align: top;
        &:hover {
          background: $color--black;
        }
        &:before {
          display: none;
        }
      }
      &.slick-active button {
        background: $color--black;
      }
    }
  }
  .mpp-complementary-products {
    @include breakpoint($bp--large-up) {
      max-height: 520px;
      overflow: hidden;
    }
  }
}

.page-products {
  .site-container {
    article.contextual-links-region {
      @include breakpoint($bp--small-down) {
        position: inherit;
      }
    }
  }
}

.mpp-sort__container {
  max-width: 1280px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  padding: 0 25px;
  @include breakpoint($bp--large-down) {
    z-index: auto;
  }
  @include breakpoint($bp--xlarge-up) {
    body.has-grid-filter-open & {
      margin-top: -80px;
    }
    body.has-grid-filter & {
      z-index: 2;
    }
  }
  &--banner {
    @include breakpoint($bp--small-down) {
      margin-top: 60px;
      + .site-banner__sticky-fix + .mpp-sort__container.select--plain {
        margin-top: 20px;
      }
    }
  }
  @include breakpoint($bp--xlarge-up) {
    height: 1px;
    &.position-in-place {
      height: auto;
    }
    position: relative;
    &.is-mpp-sticky {
      position: fixed;
      #{$rdirection}: 0;
      z-index: $z-highest;
      background-color: none;
      left: 0;
    }
    &.filters-closed {
      margin-top: 0;
    }
    &.filters-open:not(.is-mpp-sticky) {
      margin-top: -80px;
    }
  }
  .mpp-sort__label {
    display: inline-block;
    line-height: 24px;
    font-size: 12px;
    text-transform: uppercase;
    vertical-align: middle;
    @if $fonts_update == false {
      font-family: $body-font-family--bold;
      font-weight: normal;
    }
  }
  &.is-mpp-sticky {
    .mpp-sort__label {
      @include breakpoint($bp--xlarge-up) {
        background-color: $color--white;
        padding: 7px;
        margin-#{$rdirection}: -7px;
      }
    }
  }
  div.mpp-sort-menu div.size-select--dropdown {
    text-align: left;
    width: 15%;
    max-width: 15%; // removed !important from width, i presume it was to overwrite the inline set by select2
    @include breakpoint($bp--medium-down) {
      width: 100%;
      min-width: 15%;
    }
    &.select2-container {
      @include breakpoint($bp--large-up) {
        border: 1px solid $color--gray--lighter;
        a.select2-choice {
          border-bottom: none;
          @include breakpoint($bp--large-down) {
            border-bottom: none;
          }
        }
      }
      @include breakpoint($bp--xlarge-up) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      a.select2-choice {
        height: 36px;
        & > .select2-chosen {
          line-height: 36px;
          font-weight: 400;
          margin-left: 10px;
        }
      }
      &.select2-dropdown-open {
        @include breakpoint($bp--large-up) {
          border: 1px solid $color--gray--lighter;
          a.select2-choice {
            border-bottom: none;
          }
        }
      }
      @include breakpoint($bp--medium-down) {
        border: none;
      }
    }
    .select2-arrow {
      @include breakpoint($bp--large-up) {
        bottom: -10px;
        right: 6px;
      }
    }
    .select2-arrow b {
      @include breakpoint($bp--medium-down) {
        &:before {
          bottom: 3px;
        }
      }
    }
  }
  &.select--plain {
    @include breakpoint($bp--xlarge-down) {
      margin-top: $site-header-height;
    }
    &.filters-open {
      select.mpp-sort-menu {
        @include breakpoint($bp--large-up) {
          margin-top: 10px;
        }
      }
    }
    &.filters-closed {
      select.mpp-sort-menu {
        @include breakpoint($bp--large-up) {
          margin-top: 0;
        }
      }
    }
    select.mpp-sort-menu {
      @include breakpoint($bp--large-up) {
        width: auto;
      }
    }
    $border-color: #dbdbdb;
    @include breakpoint($bp--medium-down) {
      border: 1px solid $border-color;
      width: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: row nowrap;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0;
      position: relative;
      margin: 0 15px;
      span.mpp-sort__label {
        padding: 0 15px;
        width: auto;
        padding-right: 10px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        text-align: center;
      }
      select.mpp-sort-menu {
        background-image: none;
      }
      &:before {
        content: '';
        position: absolute;
        right: 15px;
        bottom: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-right-color: $color--black;
        border-bottom-color: $color--black;
      }
    }
    @include breakpoint($bp--medium-down) {
      margin-top: $site-header-height;
    }
  }
}

.js-product-visibility--hide {
  margin-right: -100% !important;
  @include breakpoint($bp--small-up) {
    margin-right: -50% !important;
  }
  @include breakpoint($bp--medium-up) {
    margin-right: -25% !important;
  }
}

@media (max-width: 48em) {
  select.js-mpp-sort-menu.mpp-sort-menu {
    border: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0;
  }
  span.mpp-sort__label {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    min-width: 0;
    padding: 0 20px;
  }
  .grid-filter__results {
    margin: 0 auto -70px;
    @include breakpoint($bp--medium-down) {
      margin-bottom: 0;
    }
    height: auto;
    padding-bottom: 5px;
    & + div.mpp-sort__container {
      margin-top: 0;
    }
  }
  .page-products .mpp-sort__container.select--plain + .grid--mpp.responsive-container {
    @include breakpoint($bp--small-down) {
      margin-top: 5px;
    }
    margin-top: 15px;
  }
}

.node-elc-mpp.no-sidebars {
  .field-elc-mpp-content {
    @include breakpoint($bp--large-up) {
      width: 100%;
      .mpp-sort__container {
        float: none;
        max-width: auto;
        width: auto;
        padding: 0 20px;
        div.mpp-sort-menu {
          min-width: 200px;
        }
      }
      .grid-filter__results {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        float: none;
        max-width: auto;
        width: auto;
        padding-top: 14px;
      }
    }
  }
}

.product__detail .product__name-link {
  display: inline-block;
  border-bottom: none;
}

.look-bundles__carousel {
  margin-bottom: 40px;
  .product--teaser {
    border: 0;
  }
  .slick-dots {
    bottom: -20px;
  }
}

.select2-drop {
  width: 250px;
  .select2-results {
    max-height: 260px;
    li {
      padding: 12px 0;
    }
  }
}

.mpp-sort-wrapper {
  position: absolute;
  width: 100%;
  @include breakpoint($bp--xlarge-up) {
    z-index: 1;
    #{$rdirection}: 0;
  }
  .has-filters & {
    @include breakpoint($bp--xlarge-up) {
      position: relative;
      z-index: 1;
    }
  }
}

.mpp-sort__container {
  // need to use both clases for specificity
  &.mpp-sort__container--v3 {
    .filters_container--inner.has-filters & {
      width: calc(50% - 16.5px);
      @include swap_direction(margin, 10px 6.5px 0 10px);
      right: unset;
      @include breakpoint($bp--xlarge-up) {
        position: relative;
        @include swap_direction(margin, 0 0 0 auto);
        width: auto;
        z-index: 1;
      }
    }
    &.select--plain {
      border: none;
      margin: 10px 10px 0;
      position: absolute;
      #{$rdirection}: 0;
      width: calc(100% - 20px);
      &:before {
        color: $color--black;
        border: solid $color--white;
        border-width: 0 2.4px 2.4px 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        bottom: 22px;
        #{$rdirection}: 22px;
        font-size: 5px;
        width: 5px;
        height: 5px;
      }
      @include breakpoint($bp--medium-up) {
        width: auto;
        min-width: 200px;
      }
      @include breakpoint($bp--xlarge-up) {
        &:before {
          display: none;
        }
        top: -10px;
        margin: 0;
        padding-#{$rdirection}: 0;
      }
      // overwriting existing css from v1
      .node-elc-mpp.no-sidebars & {
        @include breakpoint($bp--xlarge-up) {
          padding-#{$rdirection}: 0;
        }
        @include breakpoint($bp--xlarger-up) {
          margin-#{$rdirection}: 10px;
        }
      }
    }
    select.mpp-sort-menu {
      line-height: 45px;
      height: 50px;
      background-color: $color--black;
      color: $color--white;
      text-decoration: none;
      font-family: $ano-bold_regular-font;
      font-size: 18px;
      border: 1px solid $color--gray--lighter;
      @include swap_direction(padding, 0 20px 0 32px);
      -webkit-padding-end: 32px;
      -webkit-padding-start: 20;
      @if $fonts_update == true {
        @include h6();
        margin: 0;
      }
    }
  }
}

div.mpp-sort-menu--v3,
.size-select--dropdown {
  &.select2-container {
    margin-top: 0;
    width: auto;
    max-width: unset;
    .mpp-sort__container.mpp-sort__container--v3 &,
    .product-size-select-v1 & {
      margin-top: 0;
      .select2-choice {
        min-width: 150px;
        padding-#{$rdirection}: 50px;
        height: 50px;
        background-color: $color--black;
        color: $color--white;
        text-decoration: none;
        font-family: $ano-bold_regular-font;
        font-size: 16px;
        @include breakpoint($bp--xlarge-up) {
          height: 45px;
        }
        .select2-chosen {
          line-height: 46px;
          margin-#{$ldirection}: 30px;
          font-size: 18px;
          @include breakpoint($bp--xlarge-up) {
            font-size: 16px;
          }
          @if $fonts_update == true {
            @include h6();
            line-height: 46px;
            margin-bottom: 0;
            margin-top: 0;
            @include breakpoint($bp--xlarge-up) {
              font-size: 15px;
            }
          }
        }
        .select2-arrow {
          bottom: -2px;
          background: $color--black;
          #{$rdirection}: 15px;
          b {
            &:before {
              @if $cr21 == false {
                color: $color--black;
                border: solid $color--white;
                border-width: 0 2.4px 2.4px 0;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                bottom: 22px;
                #{$rdirection}: 5px;
                font-size: 5px;
              } @else {
                content: '';
                position: absolute;
                top: 50%;
                margin-top: -10px;
                background: url('#{$base-theme-path}svg-icons/src/icon--chevron-down.svg')
                  no-repeat;
                transform: unset;
                width: 15px;
                height: 15px;
                font-size: 10px;
              }
            }
          }
        }
      }
      &.select2-dropdown-open {
        border: none;
        background: $color--white;
        .select2-chosen {
          text-align: #{$rdirection};
          padding-#{$rdirection}: 20px;
        }
        .select2-choice,
        .select2-arrow {
          color: $color--black;
          background: $color--white;
        }
        .select2-choice {
          border: none;
          b {
            &:before {
              @if $cr21 == false {
                color: $color--white;
                border: solid $color--black;
                border-width: 0 2.4px 2.4px 0;
                transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
                bottom: 22px;
                #{$rdirection}: 5px;
                font-size: 5px;
              } @else {
                content: '';
                position: absolute;
                top: 50%;
                margin-top: -10px;
                background: url('#{$base-theme-path}svg-icons/src/icon--chevron-up.svg') no-repeat;
                transform: unset;
                width: 15px;
                height: 15px;
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
  &.select2-drop {
    overflow: hidden;
    width: 100%;
    .select2-results {
      // hiding the scroll, while keeping the scroll functionality in place
      width: calc(100% + 20px);
      padding: 0;
      margin: 0;
      text-transform: none;
    }
  }
}
