/* ==========================================================================
   Components Molecules - Blocks - Customer Service menu
   ========================================================================== */

// from the CS page formatter
// ~/mac_base/template_api/customer_service/cs_formatter_v1/cs_formatter_v1.inc
.customer-service__navigation {
  display: none;
  @include clearfix;
  @include reverse;
  background: $color--gray--black;
  color: $white;
  margin-bottom: 2em;
  @include swap_direction(padding, 2em 1em);
  text-transform: uppercase;
  @include breakpoint($bp--largest-up) {
    display: block;
  }
  a {
    @include set-link-colors(
      $color--gray--light,
      $color--gray--light,
      $color--gray--light,
      $color--gray--light,
      $color--gray--light,
      false
    );
  }
  h6,
  .at-h6__customerservice {
    @include fontsize-rem($typesize--14px);
  }
  .basic-responsive-v1 {
    @include responsive-container;
    padding-#{$ldirection}: 25px;
  }
}

// classes used in custom html markup via the CMS
// shared on all customer service pages
.customer-service__menu {
  @include layout($grid--12) {
    @include breakpoint($bp--medium-up) {
      @include grid-span(9, 3);
    }
  }
}

.customer-service__menu-content {
  line-height: 2.5em;
}

.customer-service__page-content {
  padding-#{$ldirection}: 20px;
  padding-#{$rdirection}: 20px;
  @if $fonts_update == true {
    h2 {
      font-size: 40px;
    }
    h3 {
      font-size: 25px;
    }
  }
}
