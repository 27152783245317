/**
 * @file
 * Slideaway clickaway
 *
 * A generic style for bricks that start in one state and slide to the left on
 * user interaction
 */

.slidein-clickaway {
  overflow: hidden;
  width: 100%;
}

.slidein-clickaway__rail {
  @include animation-translate;
  @include transform(translate3d(0, 0, 0));
}

.slidein-clickaway__content,
.slidein-clickaway__starting {
  float: #{$ldirection};
}

.slidein-clickaway__content {
  position: relative;
  ul.shop-the-collection__carousel-slidee {
    li.product--not-shoppable {
      &.prod_inv_status-7 {
        opacity: 0.5;
      }
    }
  }
}

.slidein-clickaway__close {
  // .icon--remove within is 12px x 12px
  width: 24px;
  height: 24px;
  @include swap_direction(margin, 6px 0 0 6px);
  cursor: pointer;
  position: absolute;
  #{$rdirection}: 0;
  top: 32px;
  [data-start-open='true'] & {
    @include hidden();
  }
}
