/* ==========================================================================
   Components Atoms - Forms, Radios & Checkboxes
   ========================================================================== */

/*
 * Custom radio buttons and checkboxes
 * Docs on JS library used: http://formstone.it/components/picker
 */

$picker-size: 20px;

.form-type-radio,
.form-type-checkbox {
  position: relative;
  padding-#{$ldirection}: ($picker-size + 19);
  input,
  .picker-handle {
    position: absolute;
    top: 1em;
    #{$ldirection}: 0;
    @if $fonts_update == true {
      top: 0.2em;
    }
  }
}

.picker {
  @include swap_direction(margin, 1em 1em 1em 0);
  display: block;
  .picker-label {
    font-size: inherit;
    line-height: $picker-size;
    text-transform: uppercase;
    float: #{$ldirection};
    @if $fonts_update == true {
      font-size: 14px;
    }
    &.error,
    &.error a {
      color: $color--error;
    }
  }
  .picker-handle {
    border-width: 1px;
    border-style: solid;
    border-color: $color--border;
    float: #{$ldirection};
    @include swap_direction(margin, 0 17px 0 2px);
  }
  &:focus {
    .picker-handle {
      border-color: $color--gray;
    }
  }
  &:hover {
    .picker-handle {
      border-color: $black;
    }
  }
}

// checkboxes and radio buttons
.picker.picker-radio,
.picker.picker-checkbox {
  .picker-handle {
    height: $picker-size;
    width: $picker-size;
    display: inline-block;
  }
}

//set float attr for labels on questions form(product-tips...), to align larger labels properly to the radio
.question_type .picker-label {
  float: none;
}

// radio buttons
.picker-radio.picker {
  .picker-handle {
    @include border-radius(($picker-size / 2));
    @include single-transition(
      $property: background,
      $duration: (
        $default-transition-duration / 2
      )
    );
  }
  .picker-flag {
    // used when a dot inside a circle is the desired design; they just want the circle fully filled in
    display: none;
  }
  &.checked {
    .picker-handle {
      background: $white;
      .picker-flag {
        @include swap_direction(margin, 3px);
        @include swap_direction(border, 2px solid $white);
        @include swap_direction(border-radius, 8px);
        height: 14px;
        background: $black;
        display: block;
        width: 14px;
      }
    }
  }
}

// checkboxes
.picker-checkbox.picker {
  .picker-flag {
    background: none;
    cursor: pointer;
    @include border-radius(0);
    @include icon(check, $size: ($picker-size - 1));
    @include opacity(0);
    @include single-transition(
      $property: opacity,
      $duration: (
        $default-transition-duration / 2
      )
    );
  }
  &.checked {
    .picker-flag {
      background: none;
      @include opacity(1);
    }
  }
}
