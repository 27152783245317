// build each media query for js ingestion
@each $mq in $mq-sync {
  @media screen and (min-width: nth($mq, 2)) {
    head {
      font-family: '#{nth($mq, 1)} #{nth($mq, 2)}';
    }
    body:after {
      content: '#{nth($mq, 1)} - min-width: #{nth($mq, 2)}';
    }
  }
}
