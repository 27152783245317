.enhanced_cart_page {
  overflow: visible;
  .checkout {
    .checkout-header {
      border: 0;
      .checkout-header {
        &__title {
          padding: 24px 0;
          margin: 0;
          width: 100%;
        }
      }
      #promo-message {
        border: 1px solid $color--black--opacity;
        padding: 12px 12px 14px;
        background: $color--black--opacity-02;
        text-align: #{$ldirection};
        letter-spacing: 0.25px;
        text-transform: lowercase;
        margin: 0 16px 24px;
        width: auto;
        @if $fonts_update == false {
          font-size: 12px;
        }
        @include breakpoint($width-largest) {
          font-size: 14px;
          margin: 0 0 24px;
        }
      }
    }
    &.panel {
      margin: 0;
      padding: 0;
      background: $color--gray--thin;
      @include breakpoint($width-largest) {
        @include box-shadow(3px 0 3px $color--gray--lighter);
        @include background(
          linear-gradient(
            #{$ldirection},
            $color--gray--thin 0,
            $color--gray--thin 69.3%,
            $color--gray--lighter 69.6%,
            $color--gray--thick 65%,
            $color--gray--thick 100%
          )
        );
      }
      &.pg_wrapper {
        &.checkout {
          margin: 0;
          padding: 0;
          text-align: #{$ldirection};
        }
        .column {
          &.left {
            .checkout-header {
              width: 100%;
              &.hasPaypal {
                padding-bottom: 0;
              }
            }
          }
          &.right {
            .continue-buttons {
              display: none;
              @include breakpoint($width-largest) {
                display: block;
                margin-top: 24px;
                width: 100%;
              }
              .btn {
                width: 90%;
                height: 48px;
                line-height: 50px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .checkout {
      &__sidebar {
        .messages-container {
          background: none;
        }
      }
      &__content {
        background: $color--gray--thin;
        @include breakpoint($width-largest) {
          padding: 0 25px 25px;
          width: 69.6%;
          margin-#{$rdirection}: 0;
          background: none;
        }
        .messages-container {
          background: none;
        }
        #viewcart-panel {
          border-bottom: none;
          padding-bottom: 0;
          padding-top: 0;
          .remove_link,
          .remove-sample,
          .favorite-actions__link {
            transform: rotate(45deg);
            width: 24px;
            height: 24px;
            background: none;
            margin-top: 15px;
            margin-#{$rdirection}: 15px;
            top: 0;
            #{$rdirection}: 0;
            padding: 0;
            .icon--remove {
              display: none;
            }
            &::before,
            &::after {
              @include transform(translate(-50%, -50%));
              content: '';
              background: $black;
              display: block;
              height: 2px;
              width: 22px;
              position: absolute;
              left: 50%;
              top: 50%;
            }
            &::after {
              width: 2px;
              height: 22px;
            }
          }
          .viewcart-samples {
            padding: 0 0 15px;
            margin: 0 auto 15px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            border-top: 1px solid $black;
            border-bottom: 1px solid $black;
            background: $white;
            @media (min-width: $width-medium) {
              padding-bottom: 0;
              margin-bottom: 24px;
            }
            &__header {
              margin: 15px 16px 0;
              h3 {
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                letter-spacing: 0.25px;
                text-transform: uppercase;
                color: $black;
                opacity: 0.8;
                margin-bottom: 0;
                @if $fonts_update == false {
                  font-family: $ano-bold_regular-font;
                }
                @media (min-width: $width-medium) {
                  font-size: 16px;
                  line-height: 16px;
                  margin-bottom: 11px;
                  opacity: 1;
                }
                .samples-expand-toggle {
                  float: $rdirection;
                  position: relative;
                  width: 24px;
                  height: 24px;
                  cursor: pointer;
                  margin-top: -5px;
                  &::before,
                  &::after {
                    @include transform(translate(-50%, -50%));
                    content: '';
                    background: $color--black--opacity-54;
                    display: block;
                    height: 2px;
                    width: 16px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                  }
                  &::after {
                    width: 2px;
                    height: 16px;
                  }
                  &.icon--minus::after {
                    visibility: hidden;
                  }
                }
              }
            }
            &__message {
              text-align: center;
              font-size: 14px;
              margin: 0 auto;
              @if $fonts_update == false {
                font-family: $roboto-mono_regular-font;
              }
              @media (min-width: $width-medium) {
                width: 100%;
              }
              .max {
                font-family: $roboto-mono_regular-font;
                font-size: 11px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.25px;
                color: $color--black--opacity-6--checkout;
                margin-top: 13px;
                display: none;
                @media (min-width: $width-medium) {
                  margin-top: 14px;
                  font-size: 14px;
                  line-height: 20px;
                  color: $black;
                }
              }
              .available {
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.25px;
                color: $color--black--opacity-6--checkout;
                margin-top: 13px;
                @if $fonts_update == false {
                  font-family: $roboto-mono_regular-font;
                  font-size: 11px;
                }
                @media (min-width: $width-medium) {
                  margin-top: 14px;
                  line-height: 20px;
                  color: $black;
                  @if $fonts_update == true {
                    font-size: 15px;
                  } @else {
                    font-size: 14px;
                  }
                }
              }
            }
            &__content {
              margin-top: 17px;
              .samples-carousel {
                padding: 0 10px;
                @media (min-width: $width-medium) {
                  padding: 0 20px;
                }
                .samples-grid {
                  margin-bottom: 0;
                  .slick-track {
                    margin: 0 auto;
                  }
                  .product {
                    padding: 0 7px;
                    text-align: center;
                    float: $ldirection;
                    margin: 0;
                    margin-bottom: 15px;
                    border-bottom: none;
                    &:nth-child(n) {
                      display: none;
                      width: 50%;
                    }
                    &:nth-child(1),
                    &:nth-child(2) {
                      display: block;
                    }
                    @media (min-width: $width-medium) {
                      padding: 0 10px;
                      &:nth-child(n) {
                        width: 25%;
                      }
                      &:nth-child(3),
                      &:nth-child(4) {
                        display: block;
                      }
                      margin-bottom: 0;
                    }
                    &.deactivate {
                      .sample-select-button {
                        background-color: $color--gray--checkout;
                        cursor: default;
                      }
                    }
                    &__remove {
                      display: none;
                    }
                    &__container {
                      display: flex;
                      flex-direction: column;
                      position: relative;
                      .product-name {
                        margin-bottom: 12px;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 20px;
                        text-align: center;
                        letter-spacing: 0.25px;
                        text-transform: uppercase;
                        color: $black;
                        @if $fonts_update == false {
                          font-family: $ano-bold_regular-font;
                        }
                      }
                      .product-size {
                        font-size: 14px;
                        line-height: 16px;
                        color: $color--black--opacity-64;
                        @if $fonts_update == false {
                          font-family: $roboto-mono_regular-font;
                        }
                        @media (min-width: $width-medium) {
                          color: $black;
                        }
                      }
                    }
                    &__image {
                      margin: 0 auto;
                      width: 104px;
                      height: 104px;
                      @media (min-width: $width-large) {
                        width: 152px;
                        height: 152px;
                      }
                      img {
                        width: 100%;
                        height: 104px;
                        @media (min-width: $width-medium) {
                          height: 152px;
                        }
                      }
                    }
                    &__description {
                      margin: 13px 0 82px;
                      .product_name {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        letter-spacing: 0.25px;
                        text-transform: uppercase;
                        color: $black;
                        padding: 0 12px;
                        @if $fonts_update == false {
                          font-family: $ano-bold_regular-font;
                        }
                        @media (min-width: $width-medium) {
                          font-size: 15px;
                          line-height: 20px;
                          width: 100%;
                          max-height: 200px;
                          padding: 0;
                        }
                      }
                      .grid-item--1-3-of-4 {
                        width: 100%;
                        float: none;
                        margin-#{$ldirection}: 0;
                        margin-#{$rdirection}: 0;
                        .grid-item--4-of-4 {
                          position: absolute;
                          #{$rdirection}: 0;
                          #{$ldirection}: 0;
                          bottom: 64px;
                          letter-spacing: 0.25px;
                          width: 100%;
                          float: none;
                          @media (min-width: $width-medium) {
                            bottom: 65px;
                            clear: both;
                            min-height: 21px;
                          }
                          @if $fonts_update == false {
                            font-family: $roboto-condensed-bold-font;
                          }
                        }
                      }
                    }
                    &__select {
                      margin-bottom: 15px;
                      position: absolute;
                      #{$rdirection}: 0;
                      bottom: 0;
                      #{$ldirection}: 0;
                      @media (min-width: $width-medium) {
                        margin-bottom: 23px;
                      }
                      .button {
                        padding: 7px 0 0;
                        border: 1px solid $color--gray--checkout;
                        margin-top: 0;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        color: $black;
                        background-color: $white;
                        height: 36px;
                        width: 80%;
                        min-width: 80%;
                        @if $fonts_update == false {
                          font-weight: normal;
                          font-family: $ano-bold_regular-font;
                        }
                        @media (min-width: $width-medium) {
                          padding: 7px 7px 0;
                          width: auto;
                          line-height: 20px;
                        }
                        &.selected {
                          color: $white;
                          background-color: $black;
                        }
                      }
                      .sample-select {
                        padding: 0;
                      }
                    }
                  }
                  .slick-prev {
                    #{$ldirection}: -10px;
                    @media (min-width: $width-medium) {
                      #{$ldirection}: -15px;
                    }
                    [dir='rtl'] & {
                      #{$rdirection}: 0;
                      #{$ldirection}: auto;
                    }
                    &::before {
                      content: '';
                      display: block;
                      height: 20px;
                      width: 20px;
                      background: url('/media/images/checkout/prev-arrow-m.png') no-repeat center;
                      @media (min-width: $width-medium) {
                        background: url('/media/images/checkout/prev-arrow.png') no-repeat center;
                      }
                    }
                  }
                  .slick-next {
                    #{$rdirection}: -10px;
                    @media (min-width: $width-medium) {
                      #{$rdirection}: -15px;
                    }
                    [dir='rtl'] & {
                      #{$ldirection}: 0;
                    }
                    &::before {
                      content: '';
                      display: block;
                      height: 20px;
                      width: 20px;
                      background: url('/media/images/checkout/next-arrow-m.png') no-repeat center;
                      @media (min-width: $width-medium) {
                        background: url('/media/images/checkout/next-arrow.png') no-repeat center;
                      }
                    }
                  }
                  &.slick-initialized .product {
                    display: flex;
                  }
                }
              }
            }
          }
          .messages {
            border: 0;
            padding: 0;
            margin: 0;
            clear: both;
            &.error {
              .single-message {
                color: $color--orange--dark;
                border: 1px solid $color--orange--light;
                padding: 9px 12px;
                font-size: 15px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0.25px;
                margin: 0 16px 15px;
                @include breakpoint($width-largest) {
                  margin: 0 0 20px;
                }
                &.error_invalid_offer_code {
                  border: 1px solid $color--red;
                  color: $color--red;
                }
              }
            }
          }
          .cart-items {
            .product__desc {
              .info {
                & > div {
                  margin-bottom: 10px;
                  margin-top: 0;
                  &.cart-item__remove-form {
                    margin-bottom: 0;
                  }
                }
                .color {
                  clear: both;
                  width: auto;
                  margin-top: 0;
                  @media (min-width: $width-large) {
                    width: 60%;
                    height: auto;
                    margin-bottom: 0;
                  }
                }
              }
            }
            .thumb {
              width: 33.5%;
              padding-#{$rdirection}: 10px;
              padding-#{$ldirection}: 0;
              padding-bottom: 0;
              float: $ldirection;
              text-align: center;
              .product__thumb-image {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
              @media (min-width: $width-large) {
                width: 26.5%;
                padding-#{$rdirection}: 15px;
              }
              @media (min-width: $width-medium) {
                width: 18%;
                position: absolute;
                #{$ldirection}: 0;
                top: 0;
                bottom: 0;
              }
            }
            .product-name {
              font-size: 14px;
              font-weight: bold;
              a {
                color: $black;
                font-family: $roboto-mono_regular-font;
              }
              @media (min-width: $width-medium) {
                font-size: 17px;
              }
            }
            .size {
              font-size: 12px;
              color: $black;
              opacity: 0.4;
              @if $fonts_update == false {
                font-family: $roboto-mono_regular-font;
              }
              @media (min-width: $width-medium) {
                font-size: 14px;
                opacity: 0.64;
              }
            }
            .color {
              height: 18px;
              @media (min-width: $width-medium) {
                float: $ldirection;
                width: 47%;
              }
              .swatch {
                border-radius: 50%;
                margin-#{$rdirection}: 8px;
                float: $ldirection;
                width: 16px;
                height: 16px;
              }
              .shade {
                opacity: 0.64;
                @if $fonts_update == true {
                  font-size: 14px;
                } @else {
                  font-size: 12px;
                  font-family: $roboto-mono_regular-font;
                }
                @media (min-width: $width-medium) {
                  color: $black;
                  line-height: 16px;
                  #{$ldirection}: 0;
                }
              }
            }
            .cart-item {
              border-bottom: 1px solid $black;
              margin: 0 0 16px;
              border-top: 1px solid $black;
              padding: 12px 16px;
              position: relative;
              background: $white;
              @media (min-width: $width-medium) {
                padding-#{$ldirection}: 215px;
                padding-#{$rdirection}: 15%;
                margin: 0 0 25px;
              }
              &.sample {
                .remove-sample {
                  font-size: 0;
                  width: 14px;
                  height: 10px;
                  border-bottom: none;
                  position: absolute;
                  top: 0;
                  #{$rdirection}: 0;
                  @media (min-width: $width-medium) {
                    width: 19px;
                    height: 19px;
                  }
                }
                .desc,
                .price-qty-total-column {
                  padding: 0;
                }
                .qty {
                  border: 0;
                  float: $ldirection;
                  position: static;
                  line-height: normal;
                  clear: none;
                  opacity: 0.6;
                  max-width: 100%;
                  width: 50%;
                  @if $fonts_update == false {
                    font-family: $roboto-mono_regular-font;
                  }
                  @media (min-width: $width-medium) {
                    font-size: 14px;
                  }
                }
                .total {
                  display: none;
                }
                .price {
                  text-align: $rdirection;
                  float: $rdirection;
                  padding-#{$rdirection}: 0;
                  width: 50%;
                  font-size: 12px;
                  @media (min-width: $width-medium) {
                    font-size: 14px;
                  }
                  .cart-item__total {
                    display: none;
                  }
                }
              }
              .kit-container {
                .cart-item {
                  padding: 12px 0;
                  border-bottom: none;
                  margin-bottom: 10px;
                  .color {
                    .shade {
                      margin-top: 6px;
                    }
                  }
                }
              }
            }
            .cart-item__remove-form {
              padding-top: 0;
              position: static;
              display: none;
              .remove_btn {
                &.remove_link {
                  @media (min-width: $width-medium) {
                    font-size: 0;
                    width: 19px;
                    height: 19px;
                    border-bottom: none;
                    position: absolute;
                  }
                }
              }
              .cart-item__add-to-favorites {
                text-decoration: underline;
                font-size: 12px;
                line-height: 16px;
                color: $black;
                border: 0;
                @if $fonts_update == false {
                  font-family: $roboto-mono_regular-font;
                }
                @media (min-width: $width-large) {
                  float: $rdirection;
                  width: 53%;
                  @if $fonts_update == true {
                    font-size: 15px;
                  } @else {
                    font-size: 14px;
                  }
                }
                @media (min-width: $width-medium) {
                  width: auto;
                  position: relative;
                  top: 2px;
                  border-bottom: none;
                }
              }
            }
            .product_name {
              line-height: 1.4;
              padding-#{$rdirection}: 30px;
              @if $fonts_update == true {
                font-size: 15px;
              } @else {
                font-size: 14px;
                font-weight: 400;
              }
              a {
                color: $black;
                @if $fonts_update == true {
                  font-size: 15px;
                } @else {
                  font-weight: 400;
                  font-size: 20px;
                  font-family: $ano-bold_regular-font;
                }
              }
              @if $fonts_update == false {
                @media (min-width: $width-large) {
                  font-size: 20px;
                }
              }
              @media (min-width: $width-medium) {
                margin-#{$rdirection}: -70px;
                padding-#{$rdirection}: 0;
              }
            }
            .product_subname {
              display: none;
            }
            .desc {
              width: 66%;
              padding: 0;
              position: static;
              .cart-item__remove-form {
                display: block;
                width: auto;
                float: none;
              }
              @media (min-width: $width-medium) {
                width: auto;
                float: none;
                padding-bottom: 15px;
              }
            }
            .price-qty-total-column {
              position: relative;
              float: $rdirection;
              width: 66%;
              padding-bottom: 50px;
              margin: 5px 0 0;
              @media (min-width: $width-medium) {
                @include swap_direction(padding, 8px 0 9px 110px);
                float: none;
                width: auto;
                margin: 0 0 24px;
              }
              .price {
                margin: 0;
                padding: 0;
                float: $ldirection;
                width: 100%;
                font-size: 14px;
                @if $fonts_update == true {
                  font-family: $ano-bold_regular-font;
                } @else {
                  font-family: $roboto-mono_regular-font;
                }
                @media (min-width: $width-medium) {
                  @if $fonts_update == true {
                    font-size: 15px;
                  } @else {
                    font-size: 17px;
                  }
                }
                .cart-item__price__info {
                  float: $ldirection;
                  del {
                    text-decoration: line-through;
                    opacity: 0.2;
                  }
                  margin-top: 10px;
                  width: 50%;
                  &:nth-of-type(3) {
                    text-align: right;
                  }
                  @media (min-width: $width-medium) {
                    margin: 0;
                    text-align: right;
                    width: auto;
                  }
                }
                .cart-item__unit-price {
                  margin-#{$rdirection}: 0;
                  @media (min-width: $width-medium) {
                    margin-#{$rdirection}: 15px;
                  }
                }
              }
              .total {
                text-align: $rdirection;
                float: $rdirection;
                display: block;
                margin: 10px 0 0;
                padding: 0;
                width: auto;
                font-size: 14px;
                @if $fonts_update == false {
                  font-family: $roboto-mono_bold-font;
                }
                @media (min-width: $width-medium) {
                  float: $rdirection;
                  margin: 0;
                  @if $fonts_update == true {
                    font-size: 15px;
                  } @else {
                    font-size: 17px;
                  }
                }
              }
              .qty {
                padding: 0;
                width: 31%;
                border: 1px solid $black;
                margin: 0;
                position: absolute;
                #{$ldirection}: 0;
                bottom: 0;
                @media (min-width: $width-medium) {
                  top: 0;
                  width: 100%;
                  bottom: auto;
                }
                #cart {
                  width: 100%;
                  position: relative;
                  display: inline-block;
                  &:before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    #{$rdirection}: 30px;
                    top: 0;
                    border-#{$rdirection}: 1px solid $black;
                  }
                  &:after {
                    content: normal !important;
                  }
                  .select2-choice {
                    height: 30px;
                    text-decoration: none;
                    border: 0;
                    .select2-chosen {
                      font-size: 20px;
                      line-height: 30px;
                      border-#{$rdirection}: 1px solid;
                      margin-#{$ldirection}: 17px;
                      margin-top: 0;
                      width: 29%;
                      height: 34px;
                    }
                    .select2-arrow {
                      #{$rdirection}: 11px;
                      top: 10px;
                      b:before {
                        transform: rotate(0deg);
                      }
                    }
                  }
                  @media (min-width: $width-medium) {
                    &:after {
                      content: '';
                      position: absolute;
                      border: 0.3em solid $black;
                      border-color: $black transparent transparent transparent;
                      top: 16px;
                      #{$rdirection}: 11px;
                    }
                  }
                  .selectbox {
                    @include swap_direction(padding, 0 36px 0 9px);
                    background-image: linear-gradient(
                        45deg,
                        $color--bg--white--transparent 50%,
                        $black 50%
                      ),
                      linear-gradient(135deg, $black 50%, $color--bg--black--transparent 50%),
                      linear-gradient(to bottom, $white, $white);
                    background-position: calc(100% - 16px) calc(1em - 4px),
                      calc(100% - 11px) calc(1em - 4px), 100% 0;
                    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
                    border-radius: 0;
                    background-repeat: no-repeat;
                    text-decoration: none;
                    border: 0;
                    &.select2-container {
                      @include appearance(none);
                      background-image: none;
                      border: 1px solid $black;
                      outline: 0;
                      padding-#{$ldirection}: 0;
                      padding-#{$rdirection}: 0;
                      font-size: 20px;
                      height: 32px;
                    }
                  }
                }
                @media (min-width: $width-medium) {
                  width: 25%;
                  border: 0;
                }
              }
            }
          }
          .cart-item__discount-percentage {
            display: none;
          }
          #favorites-pane {
            border-top: 1px solid;
            border-bottom: 1px solid;
            margin-bottom: 24px;
            background: $white;
            @media (min-width: $width-medium) {
              margin-bottom: 0;
            }
          }
          #favorites-panel {
            margin-bottom: 0;
            padding-top: 12px;
            padding-bottom: 8px;
            border-bottom: 0;
            header {
              border: 0;
              padding: 0 15px;
            }
            .checkout__panel-title {
              font-size: 16px;
              color: $color--black--opacity-88;
              text-transform: uppercase;
              line-height: 16px;
              float: none;
              padding: 0;
              font-weight: 400;
              width: 100%;
              @if $fonts_update == false {
                font-family: $ano-bold_regular-font;
              }
              .count {
                color: $black;
                opacity: 0.64;
                @if $fonts_update == false {
                  font-family: $ano-bold_regular-font;
                }
              }
            }
            .instructions {
              display: none;
            }
            .expand-toggle {
              background: url(/media/images/checkout/minus-icon.png) no-repeat;
              background-size: 70%;
              width: 24px;
              height: 24px;
              float: $rdirection;
            }
            .active {
              .expand-toggle {
                background: url(/media/images/checkout/plus-icon.png) no-repeat;
              }
            }
            .product {
              border: 0;
              margin: 0;
            }
            .product-img {
              width: 33.5%;
              margin: 0;
              max-width: none;
              text-align: unset;
              padding-#{$rdirection}: 10px;
              float: $ldirection;
              @media (min-width: $width-large) {
                padding-#{$rdirection}: 15px;
                width: 28%;
              }
              @media (min-width: $width-medium) {
                width: 27.5%;
                text-align: center;
                max-width: 150px;
              }
              img {
                width: auto;
                background: $white;
                @media (min-width: $width-medium) {
                  min-width: 95px;
                }
              }
            }
            .product__desc {
              width: 66.5%;
              float: $ldirection;
              margin-bottom: 12px;
              margin-top: 12px;
              margin-#{$ldirection}: 0;
              @media (min-width: $width-medium) {
                width: 72.5%;
                margin-bottom: 0;
              }
            }
            .product-name {
              font-size: 14px;
              color: $black;
              width: 85%;
              margin-top: 0;
              margin-bottom: 8px;
              @if $fonts_update == false {
                font-family: $ano-bold_regular-font;
              }
              @media (min-width: $width-medium) {
                @if $fonts_update == true {
                  font-size: 15px;
                } @else {
                  font-size: 20px;
                }
              }
              a {
                color: $black;
                font-weight: 400;
                line-height: 24px;
                @if $fonts_update == false {
                  font-family: $ano-bold_regular-font;
                }
              }
            }
            .content {
              padding: 0;
              width: 100%;
              p {
                margin: 0 0 5px;
                padding: 0 15px;
                @if $fonts_update == false {
                  font-size: 12px;
                  font-family: $roboto-mono_regular-font;
                }
              }
              .product-list {
                min-height: auto;
              }
              .product {
                height: auto;
                position: relative;
                padding: 1px;
                margin: 16px;
                background: $color--gray--thin;
                @media (min-width: $width-medium) {
                  padding: 0;
                  background: none;
                  margin: 15px 0;
                }
              }
            }
            .product-subhead {
              display: none;
            }
            .swatch {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              float: $ldirection;
              margin-#{$rdirection}: 8px;
            }
            .skus {
              margin-top: 10px;
              li {
                margin-bottom: 10px;
              }
            }
            .product-size,
            .shade {
              color: $black;
              opacity: 0.64;
              line-height: 16px;
              @if $fonts_update == false {
                font-family: $roboto-mono_regular-font;
                font-size: 12px;
              }
              @media (min-width: $width-medium) {
                font-size: 14px;
              }
            }
            @if $fonts_update == false {
              .shade {
                font-size: 12px;
              }
            }
            .price {
              margin-top: 10px;
              line-height: 20px;
              letter-spacing: 0.53px;
              text-transform: uppercase;
              @if $fonts_update == false {
                font-family: $roboto-mono_bold-font;
                font-size: 16px;
              }
              @media (min-width: $width-medium) {
                margin-bottom: 12px;
              }
            }
            .add-to-cart {
              margin-top: 5px;
              .btn {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                background: $black;
                padding: 6px 25px;
                color: $white;
                border: 0;
                height: auto;
                min-width: 132px;
                @if $fonts_update == false {
                  font-family: $ano-bold_regular-font;
                }
              }
            }
            .remove-action {
              position: absolute;
              #{$rdirection}: 0;
              top: 7px;
              .favorite-actions__link {
                display: block;
                margin-top: 0;
                margin-#{$rdirection}: 5px;
                font-size: 0;
                width: 19px;
                height: 19px;
                border-bottom: none;
                @media (min-width: $width-medium) {
                  margin-top: 4px;
                  margin-#{$rdirection}: 15px;
                }
              }
            }
            footer {
              display: none;
            }
          }
          .cart-item {
            &__thumb,
            &__desc,
            &__total,
            &__qty,
            &__price {
              @include breakpoint($width-medium) {
                margin-#{$rdirection}: 0;
                margin-#{$ldirection}: 0;
              }
            }
          }
          &.empty_cart {
            padding-top: 0;
            .viewcart-samples,
            .favorites-panel,
            .viewcart-banner {
              display: none;
            }
            .error {
              .single-message {
                color: $color--red--dark--checkout;
                background: $color--bg--carmine--transparent;
                padding: 12px;
                border: 1px solid $color--red--dark--checkout;
                margin-bottom: 16px;
                line-height: 20px;
                letter-spacing: 0.25px;
                @if $fonts_update == false {
                  font-family: $roboto-mono_regular-font;
                  font-size: 14px;
                }
              }
            }
            .signin-to-see-cart {
              line-height: 16px;
              letter-spacing: 0.25px;
              color: $color--black--opacity-6--checkout;
              @if $fonts_update == false {
                font-family: $roboto-mono_regular-font;
                font-size: 14px;
              }
              @media (min-width: $width-medium) {
                max-width: 420px;
                margin: 0 auto;
              }
            }
            #favorites-pane {
              border: 0;
            }
          }
          .viewcart-header {
            width: 100%;
            float: $ldirection;
          }
        }
        #bottom-viewcart-buttons {
          margin-top: 26px;
          margin-bottom: 37px;
          .continue-shopping {
            display: none;
          }
          .continue-buttons {
            padding-#{$rdirection}: 0 !important;
            height: 48px;
            display: none;
            width: 100%;
            @include breakpoint($width-largest) {
              display: block;
            }
            .btn-primary {
              width: 328px;
              height: 48px;
              font-size: 16px;
              line-height: 24px;
              text-transform: uppercase;
              padding: 12px 0;
              @if $fonts_update == false {
                font-weight: 400;
                font-family: $ano-bold_regular-font;
              }
            }
          }
        }
        #recommended-products-panel {
          padding-top: 0;
          margin-top: 50px;
          border: 0;
          display: none;
          @media (min-width: $width-largest) {
            display: block;
          }
          .recommended-products-panel__title {
            margin: 0 auto;
            width: 100%;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            color: $black;
            opacity: 0.88;
            @if $fonts_update == false {
              font-family: $ano-bold_regular-font;
            }
          }
          .recommended-products-panel__content {
            .recommended-product-items {
              margin-#{$ldirection}: -6px;
              margin-#{$rdirection}: -6px;
              .recommended-item {
                text-align: center;
                min-height: 347px;
                background: $white;
                border-#{$ldirection}: 6px solid $color--gray--thin;
                border-#{$rdirection}: 6px solid $color--gray--thin;
                .recommended-product-img {
                  margin: 12px 38px;
                }
                .description {
                  width: 100%;
                  min-height: 102px;
                  .info {
                    max-width: 100%;
                    .product_name {
                      margin-bottom: 16px;
                      padding: 0 20px;
                      margin-top: 0;
                      min-height: 50px;
                      a {
                        border: 0;
                        line-height: 20px;
                        text-align: center;
                        text-transform: uppercase;
                        color: $black;
                        @if $fonts_update == true {
                          font-size: 15px;
                        } @else {
                          font-weight: 400;
                          font-size: 18px;
                          font-family: $ano-bold_regular-font;
                        }
                      }
                    }
                    .formatted_price {
                      line-height: 20px;
                      letter-spacing: 0.53px;
                      color: $black;
                      margin-bottom: 12px;
                      margin-top: 12px;
                      width: 100%;
                      @if $fonts_update == false {
                        font-family: $roboto-mono_regular-font;
                        font-size: 16px;
                      }
                    }
                  }
                }
                .addtobag {
                  width: 100%;
                  bottom: 0;
                  .btn-mini {
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    text-transform: uppercase;
                    color: $white;
                    height: 36px;
                    width: auto;
                    min-width: 80%;
                    text-decoration: none;
                    position: relative;
                    @if $fonts_update == false {
                      font-weight: 400;
                      font-family: $ano-bold_regular-font;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &__panel-title {
        font-size: 16px;
        @if $fonts_update == false {
          font-weight: 400;
          font-family: $ano-bold_regular-font;
        }
      }
      &__sidebar {
        @include breakpoint($width-largest) {
          background: none;
          width: 30.4%;
          float: #{$ldirection};
          height: 100%;
        }
        #top-viewcart-buttons {
          display: none;
          width: auto;
          @include breakpoint($width-largest) {
            display: block;
          }
          .continue-buttons {
            padding-#{$rdirection}: 0 !important;
            .btn {
              width: 348px;
            }
          }
        }
        .continue-buttons {
          display: none;
          margin-top: 24px;
          margin-bottom: 18px;
          @include breakpoint($width-largest) {
            display: block;
          }
          .btn {
            width: 348px;
            margin: 0;
          }
        }
        .viewcart-paypal-button {
          float: none;
          width: 100%;
          text-align: center;
          margin: 0 auto;
          .paypal-button {
            width: 87%;
            text-align: center;
            margin: 0 auto;
            height: 48px;
            border: 1px solid $color--gray--darkest;
            line-height: 48px;
            .paypal-link {
              line-height: 24px;
              text-align: center;
              text-transform: uppercase;
              color: $color--black--opacity-88;
              border-bottom: 0;
              @if $fonts_update == false {
                font-family: $ano-bold_regular-font;
                font-size: 16px;
              }
              .paypal-icon {
                position: relative;
                bottom: 2px;
                #{$ldirection}: 13px;
              }
            }
          }
          .terms-conditions {
            width: 86%;
            margin: 8px auto;
            position: relative;
            .check-terms-conditions {
              width: 18px;
              height: 18px;
            }
            .check-terms-conditions-label {
              width: 18px;
              height: 18px;
              position: absolute;
              #{$ldirection}: 0;
              top: 4px;
              display: block;
              pointer-events: none;
              &::before {
                content: '';
                position: absolute;
                #{$ldirection}: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $black;
                border-radius: 3px;
                z-index: 0;
                display: none;
              }
              &::after {
                content: '';
                position: absolute;
                #{$ldirection}: 3px;
                top: 5px;
                width: 10px;
                height: 4px;
                border-#{$ldirection}: 2px solid $white;
                border-bottom: 2px solid $white;
                transform: rotate(-45deg);
                display: none;
              }
            }
            .check-terms-conditions:checked + .check-terms-conditions-label {
              &::before,
              &::after {
                display: block;
              }
            }
            .terms-conditions-txt {
              line-height: 20px;
              letter-spacing: 0.25px;
              color: $color--black--opacity-88;
              text-align: $ldirection;
              margin-#{$ldirection}: 28px;
              @if $fonts_update == false {
                font-family: $roboto-mono_regular-font;
                font-size: 14px;
              }
              a {
                border-bottom: 0;
                text-transform: capitalize;
              }
              &.error {
                color: $color--error;
                a {
                  color: $color--error;
                }
              }
            }
          }
        }
        &__background {
          background: $white;
          padding: 16px;
        }
        #offer-code-panel {
          background: $white;
          margin-top: 0;
          border-bottom: 1px solid $black;
          padding: 10px 16px;
          @include breakpoint($width-largest) {
            border: 0;
            padding: 16px;
          }
          .note {
            font-family: $roboto-mono_regular-font;
            font-size: 10px;
            margin: 10px 0 0;
            color: $color--black--opacity-6;
          }
          .offer-code__input {
            .form--offer_code_text {
              background: $white;
              border: 1px solid;
              font-size: 16px;
              letter-spacing: 1px;
              opacity: 0.68;
              padding-#{$ldirection}: 15px;
              @if $fonts_update == false {
                font-family: $roboto-mono_regular-font;
              }
            }
            .label-content {
              color: $color--gray--darker;
              @if $fonts_update == false {
                font-family: $roboto-mono_regular-font;
              }
            }
          }
          .offer-code__submit {
            .form-submit {
              width: auto;
              min-width: 108px;
              height: 56px;
              line-height: 24px;
              margin: 0;
            }
          }
        }
        .offer-code-panel {
          form {
            position: relative;
            padding: 8px 0;
            .offer-code__input {
              padding-#{$rdirection}: 130px;
            }
            .form--offer_code_text {
              @include breakpoint($width-xlarge) {
                width: 227px;
              }
              height: 56px;
            }
            .offer-code__submit {
              position: absolute;
              #{$rdirection}: 0;
              top: 18px;
              @media (min-width: $width-medium) {
                top: 15px;
              }
            }
          }
        }
        .back_to_top {
          display: none;
          font-size: 0;
          .sticky-back-to-top {
            #{$rdirection}: 50%;
            transform: rotate(45deg);
            position: absolute;
            top: 43%;
            &:before,
            &:after {
              content: '';
              background: $white;
              display: block;
              height: 2px;
              width: 9px;
              position: absolute;
            }
            &:after {
              width: 2px;
              height: 9px;
            }
          }
          @media (min-width: $width-medium) {
            position: fixed;
            display: block;
            bottom: 40px;
            #{$rdirection}: 80px;
            background: $black;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 1001;
            font-size: 14px;
          }
        }
      }
    }
    &.cart-empty {
      @include box-shadow(none);
      background: none;
      #sticky-checkout-button {
        display: none;
      }
      .pg_wrapper-full-width {
        float: none;
        text-align: center;
        margin: 0 auto;
        .checkout-header__title {
          padding: 16px 0;
          font-size: 36px;
          line-height: 32px;
          width: auto;
        }
        #promo-message {
          margin: 0 auto;
          text-align: center;
          background: none;
          border: 1px solid $color--black--opacity;
          padding: 12px 12px 14px;
          @if $fonts_update == false {
            font-size: 14px;
          }
        }
        #bottom-viewcart-buttons {
          display: block;
          .continue-buttons {
            text-align: center;
            letter-spacing: 1px;
            padding-#{$rdirection}: 0 !important;
            .btn {
              width: 100%;
              line-height: 51px;
              height: 48px;
              @media (min-width: $width-medium) {
                width: auto;
              }
            }
          }
        }
      }
    }
    section#order-summary-panel {
      background: $white;
      padding: 10px 16px;
      margin-top: 0;
      border-top: 1px solid $black;
      border-bottom: 1px solid $black;
      @include breakpoint($width-largest) {
        border-color: $color--gray--tier2;
        padding: 16px;
      }
    }
    .order-summary-panel {
      &__title {
        font-size: 16px;
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
        span {
          width: 92%;
          display: inline-block;
        }
      }
      .order-summary__content {
        width: 100%;
        .label,
        .value {
          line-height: 16px;
          letter-spacing: 0.1px;
          font-weight: normal;
          color: $color--black--opacity-6--checkout;
          text-transform: none;
          margin-bottom: 12px;
          @if $fonts_update == true {
            font-size: 15px;
          } @else {
            font-size: 14px;
            font-family: $roboto-mono_regular-font;
          }
        }
        .label {
          width: 69%;
        }
        .value {
          font-weight: bold;
          margin-bottom: 12px;
          color: $black;
          width: 31%;
          text-transform: uppercase;
          @if $fonts_update == false {
            font-family: $roboto-condensed-bold-font;
          }
        }
        .order-summary__subtotal-label,
        .order-summary__subtotal-value {
          margin-top: 12px;
          text-transform: capitalize;
        }
        .order-summary__subtotal-value {
          text-transform: uppercase;
        }
        .order-summary__total-label,
        .order-summary__total-value {
          color: $black;
          background: 0;
          font-weight: 400;
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 0 !important;
          padding: 17px 0;
          @if $fonts_update == false {
            font-family: $ano-bold_regular-font;
          }
        }
        .order-summary__tax-label,
        .order-summary__tax-value {
          margin-bottom: 0;
          font-size: 12px;
        }
        .order-summary__tax-value {
          display: none;
        }
        .order-summary__total-value {
          padding-#{$rdirection}: 0;
        }
        .ship-label-sec {
          border-bottom: 1px solid $color--gray--tier5;
          padding-bottom: 16px;
          display: block;
          .shipping-label-data {
            display: block;
            background-color: $color--white;
            padding: 15px 12px;
            margin-top: 5px;
            font-size: 12px;
            border: 1px solid $color--gray--dusty;
            color: $color--gray--dusty;
            text-transform: capitalize;
            .shipping-option {
              font-style: italic;
              margin-top: 10px;
            }
          }
        }
      }
      .discount-save {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.08px;
        font-family: $roboto-mono_regular-font;
        color: $color--black--opacity-6--checkout;
        clear: both;
        float: $ldirection;
        margin-top: 5px;
      }
      .ship-method-group {
        .form-item {
          margin-bottom: 15px;
        }
      }
    }
    .shipmethod-panel {
      &__title {
        display: none;
      }
      .content {
        display: block !important;
        border-bottom: 1px solid $color--gray--tier5;
        .form-item {
          border: 1px solid $color--gray--dusty;
          padding: 13px 12px 9px;
          background: $color--gray--thin;
          letter-spacing: 0.25px;
          color: $color--gray--dusty;
        }
      }
      label {
        display: block;
      }
    }
    #paypal-express-panel {
      background: $white;
      color: $black;
      padding: 12px 16px;
      border-top: 1px solid;
      border-bottom: 1px solid;
      margin-top: 16px;
      .viewcart-paypal-button {
        margin-top: 19px;
        .paypal-button,
        .terms-conditions {
          width: 100%;
        }
      }
    }
    #need-help-panel,
    .links-panel {
      background: 0;
      color: $black;
      .link {
        margin-bottom: 10px;
        display: block;
        border-bottom: 0;
        text-decoration: underline;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.15px;
        color: $color--black--opacity-88;
        .overlay-link-cms,
        a {
          border: none !important;
          color: $black;
          @if $fonts_update == true {
            font-size: 15px;
          } @else {
            font-family: $roboto-mono_regular-font;
          }
        }
        &:hover {
          font-size: 14px;
          color: $black;
        }
        &.chat {
          display: none;
        }
        .or_phone {
          font-family: $roboto-mono_regular-font;
        }
      }
      .checkout__panel-title {
        color: $black;
      }
    }
    .need-help-panel,
    .links-panel {
      padding: 12px 16px;
      background: $white;
      border-top: 1px solid $black;
      border-bottom: 1px solid $black;
      margin-top: 16px;
      @include breakpoint($width-largest) {
        background: none;
        border: 0;
        margin-top: 10px;
        header {
          pointer-events: none;
        }
        .need-help-panel__title i {
          display: none;
        }
        .content {
          display: block !important;
        }
      }
      .links_list.first {
        margin-top: 13px;
      }
    }
    #links-panel {
      padding: 0;
      display: block;
      margin-top: 0 !important;
      .content {
        display: block !important;
      }
      .checkout__panel-title {
        display: none;
      }
    }
    .mobile-sidebar-summary {
      padding: 0;
      border-color: $color--black;
      &__title {
        padding: 12px;
      }
    }
  }
  .accordionPanel {
    i {
      height: 24px;
      width: 24px;
      position: relative;
      float: $rdirection;
      margin-top: -3px;
      &::before,
      &::after {
        @include transform(translate(-50%, -50%));
        content: '';
        background: $black;
        display: block;
        height: 1px;
        width: 16px;
        position: absolute;
        left: 50%;
        top: 50%;
      }
      &::after {
        width: 1px;
        height: 16px;
      }
    }
    .active {
      i {
        &::after {
          visibility: hidden;
        }
      }
    }
  }
  &.adpl_enabled {
    #offer-code-panel {
      .adpl {
        .form--offer_code_text {
          &.touched + label,
          &:focus + label {
            &:before {
              @include transform(translateY(-24px) translateY(-0.5px) scale(0.8, 0.8));
              @include breakpoint($width-xlarge) {
                padding: 0 7px;
                margin-#{$ldirection}: 10px;
              }
              color: $black;
              background: $white;
            }
          }
        }
      }
    }
  }
  .messages {
    border: 0;
    padding: 0;
    &.error {
      .single-message {
        margin: 25px 0 10px;
        padding: 12px;
        font-size: 15px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0.25px;
        @include breakpoint($width-xlarge) {
          margin-bottom: 25px;
        }
      }
      [class*='error_offer_criteria_met'] {
        color: $color--green !important;
        border: 1px solid $color--green !important;
      }
      .single-message,
      .error_offer_criteria_not_met {
        color: $color--orange--light;
        border: 1px solid $color--orange--light;
      }
      .error_invalid_offer_code {
        border: 1px solid $color--red;
        color: $color--red;
      }
    }
  }
  .success {
    .single-message {
      color: $color--green;
      border: 1px solid color--grren-light;
      padding: 12px 16px 12px;
    }
  }
  .sticky-checkout-button {
    display: block;
    @include breakpoint($width-largest) {
      display: none;
    }
  }
  .LPMcontainer,
  & ~ .LPMcontainer {
    display: none !important;
  }
  .customer-service {
    #max-purchase {
      line-height: 1em;
    }
  }
}
