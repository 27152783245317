@mixin appt_booking {
  //font family
  $ab-font-family: $body-font-family; //$tstar-font
  $ab-font-family-light: $body-font-family; //$tstar-font
  $ab-bold-font-family: $body-font-family--bold; //$tstar-bold-font
  $ab-font-family-gothic: $headline-font-family; //$ano-bold_regular-font
  $ab-font-family-btn: $headline-font-family;
  $ab-font-family-times: $times-font;
  $ab-font-family-roboto: $roboto-condensed-regular-font;
  $ab-font-family-roboto-bold: $roboto-condensed-bold-font;

  //breakpoint
  $ab-small-down: max-width $width-large - 2; //ipad portrait fixing - max-width 767 px
  $ab-large-up: min-width $width-large - 1; //ipad portrait fixing - min-with 768px
  $ab-ipad-portrait: $width-large - 1 $width-xlarge - 1;
  $ab-ipad-landscape: $bp--large-down;
  $ab-mob-portrait: max-width $width-xsmall - 1;

  $ab-max-width: 960px;

  //MAC UK AB colors
  $ab--color--pantone-orange-21c: #ff5000;
  $ab--color--orange: #fe782f;
  $ab--color--gray: $color--gray; //#E6E6E6;
  $ab--color--gray--white: $color--gray--white; //#F0F0F0
  $ab--color--gray--lightest: $color--gray--lightest; //#DBDBDB;
  $ab--color--gray--lighter: $color--gray--lighter; //#C7C7C7
  $ab--color--gray-light: $color--gray--light; //#999999;
  $ab--color--gray-light_1: hsl(0, 0%, 65%) !default; //#A6A6A6;
  $ab--color--gray-1: hsl(0, 0%, 75%) !default; //#BFBFBF;
  $ab--color--gray-2: #6a6969;
  $ab--color--gray--dark: $color--gray--dark; //#4D4D4D;
  $ab--color--gray--darker: $color--gray--darker; //#333333;
  $ab--color--gray--darkest: $color--gray--darkest; //#292929;
  $ab--color--gray--black: $color--gray--black; // #1A1A1A;

  $ab--color--btn-black: $color--black;
  $ab_color--btn-gray-disabled: $ab--color--gray-light_1;
  $ab_color--btn-gray-disabled-alt: $ab--color--gray--lightest;

  $ab--color--gray--service-disable: #fdfdfd;
  $ab--color--gray--service-hover: #d9d9d9;
  $ab--color--gray_td_text_default: #a6a6a6; //hsl(0, 0%, 50.2%) !default; //#808080
  $ab--color--gray_link-disable: #a6a6a6;
  $ab--color--gray_td_bg_default: $ab--color--gray-light_1;
  $ab--color--gray_td_bg_hilite: hsl(0, 0%, 90%) !default; //#E6E6E6
  $ab--color--gray_td_bg_pika_default_week: hsl(216, 2.6%, 62.5%) !default; //#9D9FA2
  $ab-color--hot-brown-approx: #b84947;
  $ab--color--gray_table_border: $ab--color--gray--lighter;
  $ab--color--gray-dot-look: #7f7f7f;
  $color--gallery-approx: #f0eeee;
  $ab--color--gray--dropdown: #cccccc;
  $ab--color--white: #ffffff;

  $lb--light--class: '.lb-light-theme';

  // Appt Menu formatter
  @include breakpoint($ab-small-down) {
    .submenu {
      &--sticky {
        &.appointment-nav {
          position: fixed;
          width: 100%;
          background-color: #f1f2f2;
          z-index: 9995;
          text-align: center;
          display: block !important;
          @if $cr20 == true {
            @include single-transition($property: top);
            top: $navbar-height + $site-banner-mobile-height;
            .gnav-offers-hidden & {
              top: $navbar-height;
            }
            .site-header-formatter--hidden & {
              top: 0;
            }
          } @else {
            top: 60px;
          }
          @if $appt_booking_static_header == false {
            &.collapse .submenu__nav {
              display: none;
            }
          }
          .submenu__header h3 {
            margin: 0;
          }
          .submenu__nav {
            border-bottom: 0;
          }
          .submenu__nav li a {
            margin-top: 5px;
          }
          .submenu__nav,
          .submenu__header {
            padding: 0;
            border-bottom: inherit;
            .appointment-nav__link {
              padding: 0 15px;
              display: block;
              line-height: 60px;
              background-color: #f1f2f2;
              &.appointment-header-nav__link {
                background: #f1f2f2 url('/media/export/cms/makeup_services/gnav_hd_make_services.png') no-repeat center
                  center;
                background-size: 190px auto;
                height: 60px;
                margin-top: 4px;
                text-indent: -9999px;
              }
            }
            .appointment-header-nav__link-arrow {
              content: '';
              background: #f1f2f2 url('/media/export/cms/makeup_services/arrow_top.png') no-repeat center center;
              border-bottom: none;
              height: 35px;
              width: 35px;
              display: inline-block;
              top: 11px;
              position: absolute;
              #{$rdirection}: 8%;
              text-indent: -9999px;
              cursor: pointer;
            }
          }
          &.collapse {
            .submenu__header {
              .appointment-header-nav__link-arrow {
                content: '';
                background: #f1f2f2 url('/media/export/cms/makeup_services/arrow_bottom.png') no-repeat center center;
              }
            }
          }
        }
      }
    }
  }

  // Collection formatter - main container
  @include breakpoint($ab-small-down) {
    abbr,
    abbr[title] {
      border: none;
      cursor: default;
    }
    .section-cancel-appointment .appt-book-page-header {
      display: none;
    }
  }
  .appt-book-loader {
    position: absolute;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background: white url(/media/images/ajax-loading.gif) no-repeat center center;
    text-align: center;
  }
  .appt-book {
    position: relative;
    padding: 0;
    margin: 0 auto 16px;
    text-align: center;
    letter-spacing: 0;

    //Back To Link On Mobile
    @include breakpoint($ab-small-down) {
      a.back-to-link,
      a.back-to-link:hover {
        width: auto;
        font-family: $ab-font-family;
        font-size: 12px;
        color: $color--black;
        text-decoration: none;
        border-color: $color--black;
        margin: 0;
      }
    }
    .appt-book-sections-container {
      position: relative;
      //max-width: $ab-max-width;
      margin: 0 auto;
      @include breakpoint($ab-small-down) {
        padding: 0 15px;
        text-align: #{$ldirection};
      }
      .location {
        .location-direction-links {
          display: inline-block;
          font-family: $ab-font-family-gothic;
          background: $color--black;
          color: $color--white;
          font-size: 18px;
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
          margin-top: 5px;
        }
      }
    }
    &.services .appt-book-sections-container,
    &.appointments .appt-book-sections-container {
      @include breakpoint($ab-small-down) {
        padding: 0;
      }
    }
    &.confirmation .appt-book-sections-container {
      @include breakpoint($ab-small-down) {
        text-align: center;
      }
    }
    .appt-book-section {
      position: relative;
      width: 100%;
      .appt-book-section-content {
        position: relative;
        width: 100%;
        margin-bottom: 15px;
        @include breakpoint($ab-small-down) {
          margin-bottom: 20px;
          padding-top: 3px;
        }
        &__banner {
          margin-top: 12px;
          margin-bottom: 30px;
          img {
            width: 100%;
            display: block;
          }
        }
      }
    }
    .appt-basic-responsive {
      position: relative;
      display: block;
      font-size: 17px;
      letter-spacing: 0;
      font-family: $ab-font-family;
      @include breakpoint($ab-small-down) {
        font-size: 15px;
      }
      a {
        margin-top: 2px;
        @include breakpoint($ab-small-down) {
          margin-top: 0;
        }
      }
    }
    p {
      margin: 18px auto 11px;
      letter-spacing: 0;
      @include breakpoint($ab-small-down) {
        margin-top: 11px;
        font-size: 12px;
        line-height: 14.4px;
        letter-spacing: 0;
      }
    }
    .appt-book-link {
      text-transform: uppercase;
      font-family: $ab-bold-font-family;
      padding-bottom: 5px;
      text-decoration: none;
      border-bottom: 1px solid $ab--color--gray-light;
      font-size: 12px;
      letter-spacing: 0;
      @include breakpoint($ab-small-down) {
        padding-bottom: 0;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .button.disabled {
      background-color: $ab_color--btn-gray-disabled;
      cursor: default;
    }

    // Appointment Booking page header
    .appt-book-page-header {
      position: relative;
      color: $color--black;
      text-decoration: none;
      display: block;
      margin: 0 auto;
      @include breakpoint($ab-small-down) {
        margin-bottom: 17px;
      }
      .appt-book-page-header-menu {
        .submenu__header {
          @include breakpoint($ab-small-down) {
            display: block;
          }
        }
        .submenu__nav {
          @include breakpoint($ab-small-down) {
            ul {
              display: block;
              li {
                max-width: 100%;
              }
            }
          }
        }
      }
      .appt-book-page-header-bg {
        display: block;
        width: 100%;
        height: auto;
      }
      .appt-book-page-header-bg.bg-img-mobile {
        display: none;
      }
      // Don't show the pc background image on mobile
      @include breakpoint($ab-small-down) {
        .appt-book-page-header-bg.bg-img-pc {
          display: none;
        }
        .appt-book-page-header-bg.bg-img-mobile {
          display: block;
        }
      }
      .appt-book-page-header-content {
        .section-cancel-appointment &,
        .appointments-page & {
          top: 20%;
        }
        .inner {
          text-align: center;
          position: relative;
          //max-width:$ab-max-width;
          margin: 0 auto;
          padding-top: 55px;
          @include breakpoint($ab-small-down) {
            padding-top: 40px;
          }
        }
        .inner.has_hero_banner {
          padding-top: 0;
          @include breakpoint($ab-small-down) {
            padding-top: 40px;
          }
        }
        h2,
        h3,
        h4 {
          text-align: center;
          margin-bottom: 0;
          line-height: 1.2;
          font-family: $ab-font-family-gothic;
          b,
          strong {
            font-family: $ab-font-family-gothic;
          }
        }
        h2 {
          font-size: 48px;
          letter-spacing: -0.02em;
          color: $color--black;
          font-family: $ab-font-family-gothic;
          margin-top: 1px;
          @include breakpoint($ab-small-down) {
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            font-family: $ab-font-family-gothic;
            margin-top: 3px;
          }
        }
        h3 {
          font-size: 27px;
          @include breakpoint($ab-small-down) {
            font-size: 30px;
            line-height: 36px;
            color: $color--black;
            letter-spacing: -0.02em;
            margin: 2px 0 10px;
          }
          .confirmation & {
            @include breakpoint($ab-small-down) {
              margin-bottom: -1px;
            }
          }
        }
        h4 {
          font-size: 19px;
          line-height: 1.3;
          color: $color--black;
          margin-#{$ldirection}: 4px;
          padding-top: 10px;
          @include breakpoint($ab-small-down) {
            font-size: 16px;
            line-height: 18px;
            text-align: #{$ldirection};
            padding-top: 12px;
          }
        }
        .multi {
          white-space: nowrap;
        }
        .multi--mobile-inline {
          display: inline-block;
          @include breakpoint($ab-small-down) {
            display: block;
          }
        }
        &__subhead {
          width: 650px;
          margin: 0 auto;
          p {
            margin: 18px auto 11px;
            font-size: 12px;
            line-height: 18px;
            &:first-child {
              margin-top: -7px;
            }
          }
          @include breakpoint($ab-small-down) {
            width: 88%;
            max-width: 335px;
            p {
              margin-top: 0;
            }
          }
        }
        &__dots {
          color: $ab--color--pantone-orange-21c;
          display: none;
          font-size: 12px;
          list-style-type: none;
          font-family: $ab-font-family;
          margin-#{$ldirection}: 0;
          margin-bottom: 30px;
          padding-bottom: 6px;
          text-align: center;
          text-transform: uppercase;
          @include breakpoint($ab-small-down) {
            font-weight: bold;
            margin-#{$ldirection}: 0;
            padding-bottom: 0;
          }
          li {
            color: $color--black;
            display: inline-block;
            font-family: $ab-font-family-gothic;
            margin-#{$ldirection}: -2px;
            font-size: 18px;
            font-weight: normal;
            line-height: 18px;
            position: relative;
            min-width: 80px;
            @include breakpoint($ab-large-up) {
              min-width: 130px;
            }
            &.active {
              border-bottom: 2px solid $color--black;
              span {
                opacity: 0.3;
              }
            }
            &:nth-last-child(2) {
              span {
                display: none;
              }
            }
            &:last-child {
              &.disabled {
                display: none;
              }
            }
          }
          li.disabled {
            opacity: 0.3;
          }
        }
        &__separator {
          width: 1px;
          height: 16px;
          background: $color--black;
          position: absolute;
          #{$rdirection}: -3px;
          bottom: 0;
        }
        &__banner {
          margin-top: 12px;
          margin-bottom: 20px;
          img {
            width: 100%;
            display: block;
          }
        }
      }
    }
    &.services .appt-book-page-header .appt-book-page-header-content {
      &__subhead {
        p.max_duration_message {
          margin-top: 18px;
        }
      }
      @include breakpoint($ab-small-down) {
        .inner {
          padding-top: 18px;
        }
        &__title {
          margin-bottom: -2px;
        }
        &__subhead {
          p {
            margin-bottom: 1px;
            br {
              display: none;
            }
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
    &.appointments .appt-book-page-header {
      @include breakpoint($ab-small-down) {
        margin-bottom: 11px;
      }
    }
    &.confirmation .appt-book-page-header .appt-book-page-header-content {
      @include breakpoint($ab-small-down) {
        &__subhead {
          p {
            br {
              display: none;
            }
          }
        }
      }
    }
    &.confirmation {
      .appt-book-page-header-content {
        &__dots {
          display: none;
        }
      }
    }
    .appt-book-content-header {
      margin: 0;
      p {
        @include breakpoint($ab-small-down) {
          font-size: 18px;
        }
      }
      .section-header {
        margin-top: 3px;
        margin-bottom: 3px;
        @include breakpoint($ab-small-down) {
          width: 100%;
          font-size: 20px;
          font-family: $ab-font-family-gothic;
          text-align: center;
        }
        .section-header__inner {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    &.services .appt-book-content-header {
      @include breakpoint($ab-small-down) {
        margin: 0 auto;
      }
    }
    .top {
      .book-now-bar {
        &-inner {
          position: fixed; //fix to sticky
          #{$rdirection}: 0;
          top: 180px;
          width: auto;
          padding: 0 25px;
          background: $ab--color--orange;
          font-family: $ab-font-family-light;
          font-size: 26px;
          line-height: 60px;
          color: $color--white;
          // margin-top: -312px; //0 fix sticky
          height: 60px;
          z-index: 9994;
          .btn-continue {
            display: none;
          }
          @include breakpoint($ab-small-down) {
            width: 100%;
            top: 120px;
            // margin-top: -380px;
            padding: 0 0 0 15px;
            text-align: #{$ldirection};
            opacity: 0.95;
            .btn-continue {
              display: block;
              position: absolute;
              #{$rdirection}: 15px; //8%;
              top: 21px;
              height: 17px;
              line-height: normal;
              width: 38px;
              background: transparent
                url('/media/export/cms/makeup_services/appointment_booking/services/serv_arrow_left_white.png')
                no-repeat center #{$rdirection};
              border-bottom: none; //1px solid white;
              padding-bottom: 1px;
              text-indent: -9999px;
              &.disabled {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    &.appointments .appt-book-content-header {
      margin: 0;
    }

    /*
     * Appointments Overlay
     */
    &.appointments .overlay-container {
      background-color: $color--white;
      border: 1px solid #cccccc;
      overflow: auto;
      padding: 20px;
      width: 100%;
      position: absolute;
      z-index: 10002;
      .close-container {
        position: absolute;
        top: 10px;
        #{$rdirection}: 10px;
        z-index: 999;
        a.close-link {
          background: transparent
            url('/media/export/cms/makeup_services/appointment_booking/common/overlay_close_btn.png') no-repeat top #{$rdirection};
          background-size: 17px auto;
          display: block;
          width: 22px;
          height: 21px;
          overflow: hidden;
          text-indent: -9999px;
          font-size: 0;
          line-height: 0;
          cursor: pointer;
        }
      }
    }
    &.services .book-now-bar {
      @include clearfix;
      letter-spacing: 0;
      height: auto;
      margin: 0;
      .minutes,
      .price {
        &.disabled {
          color: $color--white;
        }
      }
      .minutes {
        margin-#{$rdirection}: 5px;
        text-transform: lowercase;
        @include breakpoint($ab-small-down) {
          text-transform: uppercase;
          margin-#{$rdirection}: 0;
        }
      }
      .price {
        margin-#{$ldirection}: 5px;
        @include breakpoint($ab-small-down) {
          margin-#{$ldirection}: 0;
        }
      }
      &.bottom .button {
        width: 330px;
        display: block;
        margin: 0 auto;
        height: 60px;
        line-height: 60px;
        color: $color--white;
        text-transform: uppercase;
        letter-spacing: 0;
        background: $ab--color--btn-black;
        font-size: 18px;
        font-family: $ab-font-family-btn;
        cursor: pointer;
        @include breakpoint($ab-small-down) {
          width: 100%;
        }
        &.disabled {
          width: 400px;
          background-color: $ab_color--btn-gray-disabled-alt;
          color: $color--white;
          cursor: default;
          @include breakpoint($ab-small-down) {
            width: 100%;
          }
          .book-now-bar {
            &__details {
              display: none;
            }
          }
        }
        .book-now-bar {
          &__details {
            height: 60px;
            line-height: 60px;
            display: inline-block;
            padding: 0 7px;
            float: #{$ldirection};
            background: $ab--color--orange;
            font-family: $ab-font-family-light;
            font-size: 18px;
            .minutes,
            .separator,
            .price {
              float: #{$ldirection};
              margin: 0;
            }
          }
        }
      }
      &.top .button {
        cursor: pointer;
        &:hover {
          .minutes,
          .price {
            border-bottom: 1px solid $color--white;
          }
        }
        &.disabled {
          background-color: $ab--color--gray--white;
          color: $ab--color--orange;
          cursor: default;
          &:hover {
            .minutes,
            .price {
              border-bottom: none;
            }
          }
          @include breakpoint($ab-small-down) {
            display: none;
          }
        }
      }
      .start-over {
        display: inline-block;
        margin: 18px auto;
        font-size: 12px;
        font-family: $ab-font-family;
      }
      @include breakpoint($ab-small-down) {
        width: 100%;
        padding: 0 15px;
        text-align: center;
        &.top {
          margin-top: 0;
        }
        &.bottom {
          margin-top: 7px;
        }
      }
    }
    &.counters .book-now-bar {
      &-inner {
        @include breakpoint($ab-small-down) {
          display: none;
        }
      }
    }
    .appt-book-location-selector {
      #appt_store_map_container {
        width: 100%;
        height: 650px;
        display: none;
        margin-top: 25px;
        @include breakpoint($ab-small-down) {
          height: 400px;
        }
        #appt_store_map {
          width: 100%;
          height: 100%;
          float: left;
          button.gm-ui-hover-effect {
            @include breakpoint($ab-small-down) {
              right: 8px !important;
              top: -4px !important;
            }
          }
          .gm-style {
            .gm-style-iw-t::after {
              display: none;
            }
            .gm-style-iw-a {
              @include breakpoint($ab-small-down) {
                width: 326px;
              }
            }
            button img {
              filter: invert(100%);
            }
          }
          .gm-style-iw {
            @include breakpoint($ab-small-down) {
              & > div {
                width: 318px !important;
                max-width: 318px !important;
              }
            }
            background-color: black;
            padding: 0px 0px 10px;
            @include breakpoint($ab-small-down) {
              width: 326px !important;
              max-width: 326px !important;
              left: -42px !important;
              left: calc((100% - 326px) / 2) !important;
            }
            .gm-style-iw-d {
              overflow: auto !important;
            }
          }
          .location_info {
            width: 540px;
            padding: 15px;
            text-align: left;
            @include breakpoint($ab-small-down) {
              width: 310px;
              padding: 10px 5px 5px 15px;
            }
            .location_info-col1 {
              float: left;
              width: 49%;
              h6 {
                margin: 0;
                @include breakpoint($ab-small-down) {
                  font-size: 1.3rem;
                }
              }
            }
            .location_info-col2 {
              float: left;
              width: 48%;
              height: auto;
              font-family: roboto-mono_regular, Helvetica, Arial, sans-serif;
              font-size: 9.5px;
              line-height: 1.6;
              @include breakpoint($ab-small-down) {
                font-size: 0.8rem;
              }
              a {
                color: white;
                border-bottom-color: white;
              }
              div.distance {
                float: right;
                text-transform: none;
                @include breakpoint($ab-small-down) {
                  margin-top: 0px;
                }
              }
            }
          }
        }
      }
      .ui-select {
        padding-bottom: 15px;
      }
      position: relative;
      text-align: center;
      font-size: 12px;
      padding: 0;
      @include breakpoint($ab-small-down) {
        padding: 0 0 4px 0;
        font-size: 12px;
      }
      .appt-book-location-selector-copy {
        text-align: center;
        display: block;
        letter-spacing: 0;
        font-size: 12px;
        font-family: $ab-font-family;
        @include breakpoint($ab-small-down) {
          text-align: center;
        }
        &-top {
          display: none;
        }
        &-bottom {
        }
      }
      .form-container {
        position: relative;
        display: block;
        overflow: hidden;
        margin-top: 10px;
        @include breakpoint($ab-small-down) {
          width: 100%;
          margin-top: 3px;
        }
      }
      .submit_geoloc_container {
        width: 380px;
        margin: 0px auto 72px;
        padding-bottom: 20px;
        .location-submit.with-geoloc {
          width: 83%;
          width: calc(100% - 60px);
          float: left;
          margin: 0;
          display: block;
        }
        a.geo_search {
          float: right;
          &.btn {
            margin-top: 0;
          }
        }
        @include breakpoint($ab-small-down) {
          width: 100%;
          margin: 5px auto 60px;
        }
      }
      .city-select,
      .locationGroup-select,
      .location-select,
      .location-submit {
        display: block;
        width: 380px;
      }
      .ui-select {
        @include breakpoint($ab-small-down) {
          margin-bottom: 44px;
          margin-top: 17px;
        }
      }
      .city-select,
      .locationGroup-select {
        margin-bottom: 20px;
      }
      .city-select,
      .locationGroup-select,
      .location-select {
        color: $color--black;
        letter-spacing: 0.02em;
        padding: 0 30px 0 9px;
        font-family: $ab-font-family;
        text-decoration: none;
        height: 60px;
        line-height: 60px;
        margin: 0 auto;
        border: 1px solid $ab--color--gray--dropdown;
        @include breakpoint($ab-small-down) {
          width: 100%;
          padding: 0 7% 0 8px;
        }
      }
      .location-submit {
        font-family: $ab-font-family-btn;
        height: 60px;
        line-height: 60px;
        padding: 0;
        margin: 15px auto 35px;
        letter-spacing: 0;
        font-size: 18px;
        background-color: $ab--color--btn-black;
        width: 380px;
        @include breakpoint($ab-small-down) {
          display: none;
          line-height: 64px;
          margin-top: 17px;
          margin-bottom: 44px;
          width: 100%;
        }
        &.disabled {
          background-color: $ab_color--btn-gray-disabled;
          &:hover {
            background-color: $ab_color--btn-gray-disabled;
            text-decoration: none;
          }
        }
        &:hover {
          background-color: $ab--color--btn-black;
        }
      }
    }
    .appt-book-location-selector.location_group_dropdown_enabled {
      .form-container {
        .ui-select {
          @include breakpoint($ab-small-down) {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }
      }
    }
    .location-select-section {
    }
    &.services .appt-book-page-header {
      @include breakpoint($ab-small-down) {
        margin-bottom: 0;
      }
    }
    .service-select {
      width: auto;
      margin: 0 auto 23px;
      overflow: hidden;
      .appt-book-section-content {
        &__select {
          &.bottom {
            .button {
              margin: 20px auto;
              &.disabled {
                display: none;
              }
            }
          }
        }
      }
      @include breakpoint($ab-small-down) {
        margin-bottom: -16px;
        &.appt-book-section-content {
          padding-top: 0;
          margin-bottom: 0;
        }
      }
      .section-header__inner {
        @include breakpoint($ab-small-down) {
          font-size: 16px;
        }
      }
      .services-container {
        width: 100%;
        //max-width:$ab-max-width;
        overflow: hidden;
        margin-bottom: 35px;
        //padding: 0 36px;
        background: transparent url('/media/export/cms/appointment_booking/services/vert_line_bg.gif') repeat-y top
          center;
        @include breakpoint($ab-small-down) {
          background: none;
          border: none;
          margin-top: 0;
          margin-bottom: 0;
          padding: 0;
        }
      }
      .services-lesson {
        @include clearfix;
        &-header {
          display: none;
          h3 {
            padding: 0 20px;
          }
        }
      }
      .service {
        display: block;
        width: 50%;
        padding: 6px 0;
        min-height: 210px;
        overflow: hidden;
        float: #{$ldirection};
        &_tile {
          @include clearfix;
          background: $ab--color--gray--white;
          height: 100%;
          cursor: pointer;
          &_wrapper {
            height: 100%;
            float: #{$rdirection};
            padding: 10px 15px;
            @include breakpoint($ab-ipad-portrait) {
              width: 377px;
            }
            @include breakpoint($bp--xlarge-up) {
              width: 408px;
            }
            @include breakpoint($bp--xxlarge-up) {
              width: 520px;
              margin-bottom: 8px;
              margin-#{$rdirection}: 15px;
            }
          }
          &:hover {
            @include breakpoint($ab-large-up) {
              background: $ab--color--gray--service-hover;
            }
          }
        }
        @include breakpoint($ab-large-up) {
          &:nth-child(2n) {
            padding-#{$ldirection}: 6px;
            .service_tile_wrapper {
              float: #{$ldirection};
            }
          }
          &:nth-child(2n + 1) {
            padding-#{$rdirection}: 6px;
          }
        }
        @include breakpoint($ab-small-down) {
          float: none;
          width: 100%;
          min-height: 0;
          padding: 8px 15px;
          height: auto !important;
          &_tile {
            &_wrapper {
              float: none;
              width: auto;
            }
          }
          .service-content-container {
            border: 1px solid $color--white;
          }
          &.selected .service-content-container {
            background: $color--black;
            border: 1px solid $color--black;
          }
          .service-head {
            position: relative;
            width: 100%;
            height: 53px;
            overflow: hidden;
            cursor: pointer;
            .service-title {
              display: block;
              float: #{$ldirection};
              width: 80%;
              font-size: 25px;
              font-family: $ab-font-family;
              color: $ab--color--gray-light_1;
              padding: 0;
              margin-top: 3px;
              margin-#{$ldirection}: 15px;
            }
            .icon {
              display: block;
              width: 28px;
              height: 25px;
              float: #{$rdirection};
              padding: 0;
              margin: 6px 0 0 0;
              background: url('/media/export/cms/appointment_booking/services/serv_arrow_closed.png') no-repeat;
            }
          }
        }
        .service-content {
          border: 1px solid $color--white;
        }
        &.selected {
          .service-content {
            padding-bottom: 9px;
          }
          .service_tile {
            background: $color--black;
          }
          .service-details {
            h3.service-title,
            .service-description,
            .service-message,
            .selection-bar {
              color: $color--white;
            }
          }
        }
        &.time-out-range {
          .service_tile {
            opacity: 0.8;
            cursor: default;
          }
        }
        .service-image {
          text-align: center;
          width: 100%;
          padding: 0 10.43%;
          display: block;
          @include breakpoint($ab-small-down) {
            width: 45.35%;
            display: block;
            float: #{$ldirection};
            padding: 0;
          }
        }
        .service-details {
          text-align: #{$ldirection};
          position: relative;
          height: 100%;
          //margin-bottom:20px;
          @include breakpoint($ab-small-down) {
            text-align: #{$ldirection};
            width: auto;
          }
          h3 {
            margin: 0;
            line-height: 1.1;
            &.service-type {
              font-family: $ab-font-family;
              color: $ab--color--pantone-orange-21c;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0;
            }
            &.service-title {
              font-family: $ab-font-family-gothic;
              letter-spacing: 0;
              margin-top: 4px;
              line-height: 34px;
              font-size: 30px;
              color: $color--black;
              height: 50px;
              @include breakpoint($ab-small-down) {
                font-size: 24px;
                margin-top: 3px;
                letter-spacing: 0;
                line-height: 30px;
                height: auto;
              }
              @include breakpoint($ab-large-up) {
                min-height: 68px;
              }
            }
          }
          .service-description {
            overflow: hidden;
            margin-top: 12px;
            @include breakpoint($ab-large-up) {
              min-height: 160px;
            }
            p {
              font-size: 12px;
              font-family: $ab-font-family;
              line-height: 18.4px;
              letter-spacing: 0;
              width: 68%;
              float: #{$ldirection};
              padding-#{$rdirection}: 8px;
              margin: 0;
              @include breakpoint($ab-small-down) {
                margin-top: 0;
                margin-bottom: 0;
              }
            }
          }
          .lesson_description {
            p {
              margin-top: 0;
              margin-bottom: 0;
              line-height: 18.4px;
              font-size: 12px;
              font-family: $ab-font-family;
              letter-spacing: 0;
              min-height: 50px;
              @include breakpoint($ab-small-down) {
                font-size: 12px;
                line-height: 18.4px;
                letter-spacing: 0;
                font-family: $ab-font-family;
              }
            }
          }
          &__thumbnail {
            width: 32%;
            float: #{$ldirection};
            img {
              width: 100%;
            }
          }
        }
        .selection-bar {
          letter-spacing: 0;
          color: $color--black;
          padding: 0;
          height: 38px;
          position: relative;
          margin-top: 15px;
          margin-bottom: 5px;
          @include breakpoint($ab-small-down) {
            padding: 0;
            height: auto;
          }
          > div {
            line-height: 1;
            text-transform: uppercase;
            text-align: #{$ldirection};
            padding: 0;
            margin: 0;
            font-family: $ab-font-family-light;
            @include breakpoint($ab-small-down) {
              padding: 0;
            }
          }
          .selection-data {
            font-size: 26px;
            line-height: 1; //47.2px;
            display: inline-block;
            padding: 20px 0 0 0;
            @include breakpoint($ab-large-up) {
              position: absolute;
              bottom: 6px;
              padding: 0;
            }
            @include breakpoint($ab-small-down) {
              font-size: 24px;
              line-height: 1;
              padding: 10px 0 16px 0;
              .selection-time-date-replacement {
                font-size: 18px !important;
              }
            }
            .selection-time-date-replacement {
              font-size: 20px;
            }
            .selection-time {
              padding-#{$ldirection}: 10px;
            }
            &__button {
              float: #{$rdirection};
            }
          }
          .selection-selector {
            text-transform: uppercase;
            cursor: pointer;
            line-height: 18.4px;
            letter-spacing: 0;
            position: absolute;
            top: 5px;
            #{$rdirection}: 0;
            font-family: $ab-font-family-gothic;
            background: $color--black;
            color: $color--white;
            width: 32%;
            padding: 10px 0;
            font-size: 14px;
            text-align: center;
            @include breakpoint($ab-large-up) {
              top: 0;
              font-size: 16px;
            }
            @include breakpoint($bp--xlarge-up) {
              font-size: 18px;
            }
          }
          .selection-selector.selected {
            color: $color--black;
            background: $color--white;
          }
        }
      }
    }
    .appointment-select {
      width: auto;
      margin: 0 auto;
      overflow: hidden;
      &.appt-book-section-content {
        margin-bottom: 30px;
        @include breakpoint($ab-small-down) {
          padding-#{$ldirection}: 15px;
          padding-#{$rdirection}: 15px;
        }
      }
      .appointments-container {
        .booking-step {
          @include clearfix;
          margin-bottom: 22px;
          text-align: #{$ldirection};
          border-top: 1px solid $ab--color--gray--lighter;
          padding-top: 45px;
          margin-top: 40px;
          &.step1 {
            border: none;
            margin: 0;
            padding: 0;
            text-align: center;
            @include breakpoint($ab-small-down) {
              margin-bottom: 0;
              margin-top: 0;
              padding: 0;
              border: none;
            }
            .appt-book-legal-text {
              margin-#{$ldirection}: 80%;
              display: block;
            }
          }
          &.step2 {
            padding-top: 28px;
            margin-bottom: 15px;
            @include breakpoint($ab-small-down) {
              padding-top: 18px;
              margin-bottom: 20px;
              margin-top: 19px;
            }
          }
          .book-now-bar {
            @include clearfix;
            text-align: center;
            letter-spacing: 0;
            height: auto;
            margin: 28px auto 0;
            @include breakpoint($ab-small-down) {
              width: 100%;
              padding: 0;
              margin-top: 0;
              text-align: center;
            }
            .button {
              width: 400px;
              display: block;
              margin: 0 auto;
              height: 60px;
              line-height: 60px;
              color: $color--white;
              text-transform: uppercase;
              letter-spacing: 0;
              background: $ab--color--btn-black;
              font-size: 18px;
              font-family: $ab-font-family-btn;
              cursor: pointer;
              &.disabled {
                background-color: $ab_color--btn-gray-disabled-alt;
                color: $color--white;
              }
              @include breakpoint($ab-small-down) {
                width: 100%;
                &.disabled {
                  width: 100%;
                }
              }
            }
            .start-over {
              display: inline-block;
              margin: 18px auto;
              font-size: 12px;
              font-family: $ab-font-family;
            }
          }
        }
        .step-header {
          text-align: center;
          padding: 0;
          margin-bottom: 28px;
          margin-top: 0;
          line-height: 22px;
          font-size: 30px;
          letter-spacing: 0;
          font-family: $ab-font-family-gothic;
          color: $color--black;
          @include breakpoint($ab-small-down) {
            letter-spacing: 0;
            font-size: 18px;
            line-height: 21.6px;
            font-family: $ab-font-family-gothic;
            padding-bottom: 0;
            padding-top: 0;
            margin-bottom: 0;
          }
        }
        .step1 {
          .step-header {
            margin: 0;
            @include breakpoint($ab-small-down) {
              display: none;
            }
          }
        }
        .step2 {
          .step-header {
            @include breakpoint($ab-small-down) {
              //margin-bottom:0;
            }
          }
        }
        .services {
          @include clearfix;
          margin-top: 10px;
          margin-bottom: 12px;
          @include breakpoint($ab-small-down) {
            margin-bottom: 8px;
          }
          h4 {
            font-family: $ab-bold-font-family;
            font-size: 16px;
            padding-bottom: 18px;
            letter-spacing: 0.2em;
            @include breakpoint($ab-small-down) {
              letter-spacing: 0.02em;
              font-family: $ab-font-family;
              text-transform: uppercase;
              font-size: 17px;
              padding-bottom: 0;
              margin-bottom: 8px;
            }
          }
        }
        .service {
          display: block;
          width: 50%;
          padding: 6px 0;
          overflow: hidden;
          float: #{$ldirection};
          height: auto;
          text-align: #{$ldirection};
          &_tile {
            @include clearfix;
            background: $color--black; //$ab--color--gray--white;
            height: 100%;
            &_wrapper {
              height: 100%;
              width: 424px;
              float: #{$rdirection};
              padding: 15px;
              position: relative;
            }
          }
          @include breakpoint($ab-large-up) {
            &:nth-child(2n) {
              padding-#{$ldirection}: 6px;
              .service_tile_wrapper {
                float: #{$ldirection};
              }
            }
            &:nth-child(2n + 1) {
              padding-#{$rdirection}: 6px;
            }
            &.only-service-selected {
              float: none;
              width: 424px;
              padding-#{$rdirection}: 0;
              margin: 0 auto;
            }
          }
          @include breakpoint($ab-ipad-portrait) {
            &_tile {
              &_wrapper {
                width: 379px;
              }
            }
            &.only-service-selected {
              width: 379px;
            }
          }
          @include breakpoint($ab-small-down) {
            width: 100%;
            float: none;
            height: auto;
            padding: 8.5px 0;
            &_tile {
              &_wrapper {
                @include clearfix;
                float: none;
                width: auto;
                padding: 21px 15px 20px 15px;
              }
            }
          }
          .service-type {
            color: $ab--color--pantone-orange-21c;
            font-size: 12px;
            line-height: 18px;
            font-family: $ab-font-family;
            letter-spacing: 0;
            margin-top: 1px;
            @include breakpoint($ab-small-down) {
              float: #{$ldirection};
              margin: 0;
              padding-#{$rdirection}: 6px;
            }
            @media screen and (-webkit-min-device-pixel-ratio: 0) {
              //remove link animation breaks text in chrome
              font-weight: bold;
              -webkit-backface-visibility: hidden;
            }
          }
          .service-head {
            color: $color--white;
            font-family: $ab-font-family-gothic;
            font-size: 30px;
            line-height: 34px;
            letter-spacing: 0;
            margin: 0;
            height: auto !important;
            @include breakpoint($ab-small-down) {
              font-size: 12px;
              line-height: 18px;
              float: #{$ldirection};
            }
          }
          .selection-data {
            @include clearfix;
            overflow: hidden;
            color: $color--white;
            letter-spacing: 0;
            text-align: #{$ldirection};
            font-size: 26px;
            line-height: 37.2px;
            font-family: $ab-font-family-light;
            text-transform: uppercase;
            > div {
              display: inline-block;
              margin: 0;
              @include breakpoint($ab-small-down) {
                font-size: 12px;
                line-height: 18px;
              }
            }
            @include breakpoint($ab-small-down) {
              float: #{$ldirection};
              line-height: 18px;
              font-size: 12px;
              padding-#{$ldirection}: 5px;
            }
          }
          .change-services {
            position: absolute;
            #{$rdirection}: 15px;
            top: 10px;
            a#change-services-link {
              @include breakpoint($ab-large-up) {
                color: $color--white;
                font-size: 12px;
                line-height: 10px;
                font-family: $ab-font-family;
                letter-spacing: 0;
                border: none;
                text-align: #{$rdirection};
                display: block;
                z-index: 10;
                @include transition(all 0s ease 0.1s, opacity 0.1s ease 0s, transform 0.1s ease 0.1s);
                &:before,
                &:after {
                  background-color: #fff;
                  content: '';
                  height: 9px;
                  #{$ldirection}: 0;
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  width: 44px;
                  @include transition(all 0s ease 0.1s, opacity 0.1s ease 0s, transform 0.1s ease 0.1s);
                }
                &:hover {
                  color: #000;
                  &:before,
                  &:after {
                    opacity: 1;
                  }
                  &:before {
                    @include transform(rotate(45deg) scale3d(0.5, 0.25, 1));
                  }
                  &:after {
                    @include transform(rotate(-45deg) scale3d(0.5, 0.25, 1));
                  }
                }
              }
              @include breakpoint($ab-small-down) {
                background: transparent
                  url('/media/export/cms/makeup_services/appointment_booking/common/overlay_close_btn.png') no-repeat
                  top #{$rdirection};
                background-size: 17px;
                width: 50px;
                height: 17px;
                overflow: hidden;
                text-indent: 9999px;
                white-space: nowrap;
                border: none;
                display: block;
              }
            }
            @include breakpoint($ab-small-down) {
              top: 20px;
            }
          }
        }
        #back-to-link,
        #appt-book-sign-in-link,
        .start-over {
          font-family: $ab-font-family;
          padding-bottom: 5px;
          text-decoration: none;
          border-bottom: 1px solid $color--black;
          font-size: 18px;
          letter-spacing: 0;
          color: $color--black;
          @include breakpoint($ab-small-down) {
            font-size: 12px;
            padding-bottom: 0;
            text-transform: uppercase;
            font-size: 12px;
          }
        }
        .start-over {
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 0.02em;
          line-height: 14.4px;
        }
        #read-all-artist-link {
          margin-#{$ldirection}: 51.8%;
        }
        .artist-select-container {
          display: none;
          margin: 0 auto 28px;
          padding: 0;
          text-align: center;
          border-bottom: 1px solid $ab--color--gray--lighter;
          @include breakpoint($ab-small-down) {
            width: 100%;
            margin: 0 auto;
            border-bottom: none;
            padding: 20px 0;
          }
          .step-header {
            margin-bottom: 28px;
            line-height: 24px;
            @include breakpoint($ab-small-down) {
              .artist-select {
                margin-bottom: 28px;
              }
            }
          }
          .artist-select {
            display: block;
            margin: 0 auto;
            margin-bottom: 30px;
            @include breakpoint($ab-small-down) {
              margin-bottom: 20px;
            }
            &.selectbox {
              height: 60px;
              color: $color--black;
              font-family: $ab-font-family;
              width: 426px;
              text-decoration: none;
              border: 1px solid $ab--color--gray--dropdown;
              @include breakpoint($ab-small-down) {
                width: 100%;
              }
            }
          }
          h4 {
            cursor: pointer;
            border-bottom: 1px solid $color--black;
            display: inline;
            font-family: $ab-font-family;
            font-size: 12px;
            line-height: 14.4px;
            padding-bottom: 6px;
            letter-spacing: 0.02em;
            color: $color--black;
            text-transform: none;
            @include breakpoint($ab-small-down) {
              margin: -3px 0 20px 0;
            }
          }
          .artist-info {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .selects-container {
          overflow: hidden;
          width: 100%;
          margin-top: 0;
          margin-bottom: 28px;
          padding: 0;
          @include breakpoint($ab-small-down) {
            margin-top: 3px;
            margin-bottom: 8.5px;
          }
          .select-container {
            margin: 0 auto;
            text-align: center;
            padding: 0;
            width: 50%;
            @include breakpoint($ab-ipad-portrait) {
              width: 56%;
            }
            @include breakpoint($ab-small-down) {
              width: 100%;
              margin-bottom: 8.5px;
            }
            h4 {
              font-family: $ab-font-family;
              font-size: 12px;
              line-height: 14.4px;
              padding-bottom: 6px;
              letter-spacing: 0.02em;
              color: $color--black;
              text-transform: none;
              margin: 0;
              padding: 0;
              @include breakpoint($ab-small-down) {
                margin: -3px 0 20px 0;
              }
            }
            .selectbox {
              height: 60px;
              color: $color--black;
              font-family: $ab-font-family;
              width: 426px;
              text-decoration: none;
              border: 1px solid $ab--color--gray--dropdown;
              @include breakpoint($ab-small-down) {
                width: 100%;
                font-size: 12px;
              }
            }
          }
          .counter-select-container {
            @include breakpoint($ab-small-down) {
            }
          }
        }
        .appointment-select {
          h4 {
            font-size: 12px;
            line-height: 14.4px;
            font-family: $ab-font-family;
            text-transform: uppercase;
            letter-spacing: 0;
            margin: 0 0 28px;
            text-align: center;
          }
          .calendar-head {
            position: relative;
            text-align: center;
            height: auto;
            margin: 0;
            @include breakpoint($ab-small-down) {
              text-align: #{$ldirection};
              height: auto;
              margin: 0 0 4px 0;
              font-family: $ab-bold-font-family;
              font-size: 17px;
              span {
                font-family: $ab-font-family;
              }
            }
            .cal-head-content {
              position: relative;
              overflow: hidden;
              margin: 0 auto 28px;
              width: 495px;
              @include breakpoint($ab-small-down) {
                width: 100%;
                margin: 0;
              }
              > * {
                float: #{$ldirection};
              }
              .cal-copy,
              .default-cal-copy {
                background: transparent
                  url('/media/export/cms/makeup_services/appointment_booking/appointments/date_arrow_copy.png')
                  no-repeat scroll 95% 54% / 12px auto;
                position: relative;
                font-family: $ab-font-family;
                font-size: 12px;
                line-height: 60px;
                height: 60px;
                width: 426px;
                margin-#{$ldirection}: 34px;
                text-transform: uppercase;
                text-align: #{$ldirection};
                padding: 0 7% 0 8px;
                letter-spacing: 0;
                border: 1px solid $ab--color--gray--dropdown;
                @include breakpoint($ab-small-down) {
                  width: 100%;
                  margin-#{$ldirection}: 0;
                }
              }
            }
            .cal-head-controls {
              position: relative;
              overflow: hidden;
              margin: 0 auto 28px;
              max-width: 854px;
              width: 100%;
              .cal-controls {
                // cursor: pointer;
                margin-top: 5px;
                text-transform: uppercase;
                &.next {
                  background: transparent
                    url('/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_right.png')
                    no-repeat #{$rdirection} center;
                  width: 20%;
                  height: auto;
                  text-indent: -26px;
                  float: #{$rdirection};
                }
                &.previous {
                  background: transparent
                    url('/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_left.png')
                    no-repeat #{$ldirection} center;
                  width: 20%;
                  height: auto;
                  text-indent: 55px;
                  float: #{$ldirection};
                }
                &.day-time {
                  margin: 0px auto;
                  float: #{$ldirection};
                  text-align: center;
                  width: 60%;
                  .morning,
                  .afternoon,
                  .evening {
                    padding: 0;
                    margin-#{$ldirection}: 19px;
                    margin-#{$rdirection}: 19px;
                    border-bottom: 1px solid $color--white;
                    display: inline-block;
                    &:hover,
                    &.selected {
                      border-bottom: 1px solid $color--black;
                    }
                    &.store_closed {
                      color: $ab--color--gray_link-disable;
                      cursor: default !important;
                      &:hover,
                      &.selected {
                        border-bottom: 1px solid $color--white;
                      }
                    }
                  }
                }
              }
              @include breakpoint($ab-ipad-portrait) {
                max-width: 738px;
              }
            }
            .date-picker {
              position: absolute;
              #{$ldirection}: 0;
              top: 0;
              background: transparent
                url('/media/export/cms/makeup_services/appointment_booking/appointments/cal_date_picker_icon.png')
                no-repeat 0 20px;
              width: 460px;
              height: 60px;
              cursor: pointer;
              overflow: hidden; // for img replacement
              text-indent: 100%; // for img replacement
              white-space: nowrap; // for img replacement
              z-index: 200;
              @include breakpoint($ab-small-down) {
                background: inherit;
                position: relative;
                height: auto;
                width: 100%;
                text-indent: inherit;
                white-space: inherit;
                margin-bottom: 13px;
              }
            }
          }
          .appt-book-datepicker-overlay {
            z-index: 1000;
            position: absolute;
            top: 59px;
            .overlay-content-container {
              width: 426px;
              top: 0;
              #{$ldirection}: 50%;
              margin-#{$ldirection}: -214px;
              margin-top: 0;
              background-color: $color--white;
              border-top: none;
              border-color: $ab--color--gray--dropdown; //$ab--color--gray--lightest;
              padding: 0;
              .pika-lendar {
                margin: 0;
                width: 100%;
              }
              .pika-single {
                color: $color--black;
                border: none;
                padding-top: 15px;
              }
              .pika-table {
                th {
                  color: $color--black;
                  background-color: transparent;
                  font-family: $ab-font-family;
                  border: none;
                  letter-spacing: 0;
                  font-size: 10px;
                  padding: 0;
                  line-height: 20px;
                }
                abbr[title],
                abbr {
                  border: none;
                  text-decoration: none;
                  cursor: default;
                }
                tbody {
                  td {
                    color: $color--black;
                    background-color: transparent;
                    font-family: $ab-font-family;
                    border: none;
                    letter-spacing: 0;
                    font-size: 10px;
                    padding: 0;
                  }
                  tr {
                    td.is-today.is-selected .pika-button {
                      box-shadow: none;
                      background-color: $color--black;
                      color: $color--white;
                    }
                    &:hover {
                      background-color: $ab--color--gray-1;
                      td {
                        &.is-today .pika-button {
                          // background: $ab--color--gray_td_bg_pika_default_week;
                        }
                        &.is-selected .pika-button,
                        &.is-today.is-selected .pika-button,
                        &:hover .pika-button {
                          box-shadow: none;
                          background-color: $color--black;
                          color: $color--white;
                        }
                        &.is-disabled:hover .pika-button,
                        .pika-button {
                          background-color: $ab--color--gray-1;
                          color: $color--white;
                        }
                      }
                    }
                  }
                }
              }
              .pika-button {
                background: transparent;
                font-family: $ab-font-family;
                color: $color--black;
                border-radius: 0;
                font-size: 10px;
                padding: 20px;
                text-align: center;
                @include breakpoint($ab-small-down) {
                  font-size: 24px;
                  padding: 25px 20px;
                  line-height: 5px;
                }
                &:hover {
                  background-color: $color--black;
                  color: $color--white;
                  border-radius: 0;
                }
              }
              .is-selected {
                .pika-button {
                  box-shadow: none;
                  background-color: $color--black;
                  color: $color--white;
                }
              }
              .is-today .pika-button {
                // background: $ab--color--gray_td_bg_pika_default_week;
              }
              .pika-prev,
              .is-rtl .pika-next,
              .pika-next,
              .is-rtl .pika-prev {
                width: 53px;
                text-indent: 9999px;
              }
              .pika-prev,
              .is-rtl .pika-next {
                background: transparent
                  url('/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_left.png') no-repeat
                  #{$ldirection} center;
                margin-#{$ldirection}: 10px;
              }
              .pika-next,
              .is-rtl .pika-prev {
                background: transparent
                  url('/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_right.png')
                  no-repeat #{$rdirection} center;
                margin-#{$rdirection}: 10px;
              }
              .pika-prev.is-disabled,
              .pika-next.is-disabled {
                visibility: hidden;
              }
              .overlay-close {
                z-index: 10000;
                top: -58px;
                background: transparent
                  url('/media/export/cms/makeup_services/appointment_booking/appointments/date_arrow_copy.png')
                  no-repeat scroll 95% 54% / 5px auto;
                width: 460px;
                height: 58px;
                #{$rdirection}: 0;
                text-indent: 9999px;
              }
            }
          }
          .calendar_wrapper {
            @include breakpoint($ab-ipad-portrait) {
              width: 738px !important;
            }
            display: inline-block;
            border: 1px solid #ccc;
            position: relative;
            &__no-slot {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              align-items: center;
              z-index: 1;
            }
          }
          table {
            text-align: center;
            border: none; //1px solid $ab--color--gray;
            margin: 0 auto;
            width: 100%;
            font-family: $ab-font-family;
            max-width: 854px;
            @include breakpoint($ab-small-down) {
              border: none;
              /* border-collapse: separate;
              border-spacing: 1px; */
            }
            tbody {
              display: block;
              overflow: auto;
              height: 400px;
            }
            &.calendar-view {
              tbody {
                position: relative;
                &.timeslots {
                  td {
                    margin: 0;
                    &.enabled {
                      position: absolute;
                      display: table-cell;
                      border: 1px solid transparent;
                    }
                  }
                }
              }
            }
            thead {
              display: block;
            }
            th {
              background-color: $ab--color--gray_td_bg_default;
              border: none;
              text-align: center;
              font-size: 12px;
              padding: 15px 0;
              letter-spacing: 0;
              color: $color--black;
              text-transform: uppercase;
              &.hilite {
                text-decoration: underline;
              }
              width: 120px;
            }
            tr {
              display: table;
              width: 100%;
            }
            td {
              border: none; //1px solid $color--iron-approx;
              text-align: center;
              color: $ab--color--gray_td_text_default;
              cursor: default;
              font-size: 12px;
              padding: 15px 0;
              letter-spacing: 0;
              background-color: $color--white;
              width: 14.25%;
              @include breakpoint($ab-small-down) {
                font-size: 12px;
                letter-spacing: 0;
                padding: 0;
                height: 60px;
                line-height: 60px;
                background-color: $color--white;
                color: $ab--color--gray_td_text_default;
                border-bottom: 1px solid $ab--color--gray_table_border;
              }
              &.hilite {
                background-color: $ab--color--gray_td_bg_hilite;
              }
            }
            td.enabled {
              font-family: $ab-font-family;
              background-color: $color--white;
              color: $color--black;
              cursor: pointer;
              @include breakpoint($ab-small-down) {
                font-weight: bold;
              }
              &:hover {
                background-color: $color--white;
                color: $color--black;
                @include breakpoint($ab-small-down) {
                  background-color: $color--white;
                  color: $color--black;
                }
              }
            }
            td.selected,
            td.selected:hover {
              font-family: $ab-font-family;
              background-color: $color--black;
              color: $color--white;
            }
            &.weekdays {
              border-collapse: separate;
              td {
                border: 1px solid $color--white;
              }
              td.store_open {
                &.enabled {
                  font-family: $ab-font-family;
                  background-color: $color--white;
                  color: $color--black;
                  cursor: pointer;
                  @include breakpoint($ab-small-down) {
                    font-weight: bold;
                  }
                  &:hover {
                    background-color: $color--white;
                    border: 1px solid $color--black;
                    color: $color--black;
                    @include breakpoint($ab-small-down) {
                      background-color: $color--white;
                      color: $color--black;
                    }
                  }
                } //enabled
                &.selected,
                &.selected:hover {
                  font-family: $ab-font-family;
                  background-color: $color--black;
                  border: 1px solid $color--black;
                  color: $color--white;
                }
              }
            } //weekdays
          }
        }
      }
    }
    .confirm {
      &.appt-book-section-content {
        margin-bottom: 5px;
        .appt-book-content-header {
          @include breakpoint($ab-small-down) {
            margin: 0;
          }
        }
      }
      .confirm-container {
        .error-messages {
          display: none;
          margin-top: 20px;
          .error-message {
            display: none;
            color: $ab-color--hot-brown-approx;
            margin-bottom: 5px;
          }
        }
        .picker-handle.error {
          border-color: red;
        }
        .booking-step {
          margin-bottom: 22px;
          text-align: #{$ldirection};
          &.step3 {
            padding: 0 5px;
            max-width: 864px;
            margin: 0 auto;
            @include breakpoint($ab-ipad-portrait) {
              padding: 0 15px;
            }
            @include breakpoint($ab-small-down) {
              padding: 0;
            }
            .js-phone-multiple-prefix-select {
              display: block;
              font-size: 12px;
              height: 60px;
              width: 20%;
              border-#{$rdirection}: none !important;
              float: #{$ldirection};
              outline: 0;
              border-width: 1px;
              border-style: solid;
              border-color: $ab--color--gray-light;
            }
          }
          @include breakpoint($ab-small-down) {
            .step3 {
              .step-header {
                margin-#{$ldirection}: 20px;
                margin-#{$rdirection}: 20px;
              }
              .error-messages,
              .sign-in-container,
              .book-appt-container {
                padding: 0 20px;
              }
            }
          }
        }
        .sign-in-container {
          display: block;
          overflow: hidden;
          width: 100%;
          height: auto;
          margin: 20px 0 0 0;
          letter-spacing: 0.03em;
          @include breakpoint($ab-small-down) {
            letter-spacing: 0;
            margin-top: 10px;
          }
          h4 {
            margin-bottom: 0;
            font-size: 16px;
            color: $color--black;
            font-size: 12px;
            font-family: $ab-font-family;
            font-weight: normal;
            text-transform: none;
            line-height: normal;
            @include breakpoint($ab-small-down) {
              margin-bottom: 3px;
              font-size: 15px;
            }
            &.sign-in-head,
            &.guest-head {
              font-size: 12px;
              font-family: $ab-font-family;
              font-weight: normal;
              text-transform: none;
              float: #{$ldirection};
            }
          }
          .sign-in-link-container {
            @include clearfix;
            margin-top: 5px;
            a {
              font-size: 12px;
              float: #{$ldirection};
              color: $ab--color--pantone-orange-21c;
              border-color: $ab--color--pantone-orange-21c;
              @include breakpoint(max-width $width-xsmall - 1) {
                float: none;
              }
            }
            h4 {
              padding-#{$ldirection}: 5px;
              @include breakpoint(max-width $width-xsmall - 1) {
                margin-#{$ldirection}: 0;
                text-indent: 55px;
                margin-top: -20px;
                line-height: 22px;
                padding-#{$ldirection}: 0;
              }
            }
          }
          .guest-link-container {
            @include clearfix;
            display: none;
            margin-bottom: 24px;
            h4 {
              padding-#{$rdirection}: 5px;
            }
            a {
              font-size: 12px;
              float: #{$ldirection};
              color: $ab--color--pantone-orange-21c;
              border-color: $ab--color--pantone-orange-21c;
            }
          }
          .sign-in-form-container {
            display: none;
            position: relative;
            overflow: hidden;
            input {
              padding: 0 18px;
              font-family: $ab-font-family;
              display: block;
              width: 49.3%;
              margin-bottom: 15px;
              height: 60px;
              font-size: 12px;
              letter-spacing: 0.02em;
              @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 767px) {
                font-size: 16px;
              }
              @include breakpoint($ab-small-down) {
                margin-bottom: 18px;
                width: 100%;
              }
              &.login__email {
                float: #{$ldirection};
                @include breakpoint($ab-small-down) {
                  width: 100%;
                  float: none;
                }
              }
              &.login__password {
                float: #{$rdirection};
                margin-bottom: 0;
                @include breakpoint($ab-small-down) {
                  float: none;
                  margin-bottom: 15px;
                  width: 100%;
                }
              }
              &.login__submit {
                background-color: $color--black;
                clear: both;
                display: block;
                float: initial;
                font-size: 18px;
                font-family: $ab-font-family-gothic;
                letter-spacing: 0;
                line-height: 60px;
                margin: 20px auto 0;
                padding: 0;
                width: 380px;
                &:hover {
                  background-color: $color--black;
                }
                @include breakpoint($ab-small-down) {
                  float: none;
                  line-height: 1;
                  margin-top: 20px;
                  padding: 0;
                  width: 100%;
                }
              }
              &.placeholder {
                color: $ab--color--gray-2;
              }
            }
            a.login_forgot {
              margin-#{$ldirection}: 51%;
              @include breakpoint($ab-small-down) {
                margin-bottom: 15px;
                margin-#{$ldirection}: 0;
              }
            }
          }
        }
        .book-appt-container {
          display: block;
          overflow: hidden;
          width: 100%;
          height: auto;
          margin: 12px 0 35px;
          letter-spacing: 0;
          @include breakpoint($ab-small-down) {
            margin-bottom: 0;
          }
          a {
            color: $color--black;
          }
          h4.book-appt-header {
            margin-bottom: 8px;
            font-size: 12px;
            color: $color--black;
            line-height: 18.4px;
            text-transform: none;
            font-family: $ab-font-family;
            @include breakpoint($ab-small-down) {
              margin-bottom: 10px;
            }
          }
          .book-appt-fieldset {
            @include clearfix;
          }
          .book-appt-titles {
            .picker {
              display: inline-block;
            }
          }
          input,
          fieldset {
            display: block;
            width: 49.3%;
            margin-bottom: 12px;
            font-family: $ab-font-family;
            height: 60px;
            font-size: 12px;
            letter-spacing: 0.02em;
            @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 767px) {
              font-size: 16px;
            }
            @include breakpoint($ab-small-down) {
              width: 100%;
              float: none;
              height: 60px;
              padding: 0 12px;
              margin-bottom: 18px;
            }
            &.appt-book-first-name,
            &.appt-book-email {
              float: #{$ldirection};
              @include breakpoint($ab-small-down) {
                width: 100%;
                float: none;
              }
            }
            &.appt-book-last-name,
            &.mobile-phone-fieldset,
            &.appt-book-mobile {
              float: #{$rdirection};
              @include breakpoint($ab-small-down) {
                width: 100%;
                float: none;
              }
            }
            &.appt-book-mobile {
              box-shadow: none;
              -webkit-appearance: none;
            }
            &.mobile-phone-fieldset {
              input.appt-book-mobile-prefix {
                display: inline-block;
                width: 20%;
                border-right: none;
              }
              input.appt-book-mobile.appt-placeholder {
                width: 80%;
                float: right;
              }
              border: none;
              padding: 0;
              overflow: hidden;
            }
            &.register__password {
              width: 100%;
            }
            &.placeholder {
              color: $ab--color--gray-2;
            }
          }
          fieldset {
            @include breakpoint($ab-small-down) {
              margin: 0 0 15px;
              padding: 0;
            }
          }
          .picker {
            &-checkbox {
              margin-#{$ldirection}: 90px;
              @include breakpoint($ab-small-down) {
                margin-#{$ldirection}: 0;
                margin-#{$rdirection}: 0;
              }
            }
            &-handle {
              border-color: $color--black;
              &.error {
                border-color: $color--red;
              }
            }
            &-flag {
              &:before {
                color: $color--black !important;
              }
            }
          }
          p.appt-book-register-password-text {
            margin: 10px auto 49px;
            @include breakpoint($ab-small-down) {
              margin-bottom: 24px;
            }
          }
          .appt-book-note {
            width: 100%;
            display: block;
            height: 149px;
            margin-bottom: 16px;
            font-family: $ab-font-family;
            font-size: 16px;
            letter-spacing: 0.03em;
            @include breakpoint($ab-small-down) {
              letter-spacing: 0.03em;
              height: 90px;
              margin-bottom: 12px;
              font-size: 12px;
            }
          }
          .registration__privacy-list,
          .registration__privacy-list-commercial,
          .registration__email-list,
          .registration__sms-list,
          .registration__appt-sms-list {
            @include clearfix;
            display: block;
            font-family: $ab-font-family;
            font-size: 12px;
            line-height: 18.4px;
            width: 90%;
            margin-#{$ldirection}: 0;
            max-width: 600px;
            letter-spacing: 0;
            input {
              width: 15px;
              height: 15px;
              float: #{$ldirection};
              margin: 2px 15px 15px 0;
              @include breakpoint($ab-small-down) {
                width: 15px;
                height: 15px;
                margin-#{$rdirection}: 10px;
                margin-bottom: 17px;
              }
            }
            &-text {
              width: 94%;
              float: #{$ldirection};
              text-transform: none;
              color: $color--black;
              @include breakpoint($ab-small-down) {
                width: 100%;
              }
              &.error {
                color: $ab-color--hot-brown-approx;
                a {
                  color: $ab-color--hot-brown-approx;
                }
              }
            }
            @include breakpoint($ab-small-down) {
              margin-#{$ldirection}: 0;
              margin-bottom: 24px;
              width: 85%;
            }
          }
          /* .registration__sms-list {
            @include breakpoint($ab-small-down) {
              width: auto;
              float: none;
            }
          } */
        }
        .booking-container {
          text-align: center;
          .tooltip {
            @include breakpoint($ab-large-up) {
              .tooltiptext-over {
                #{$ldirection}: 41%;
              }
            }
          }
          .appt-book-book-submit {
            padding: 0;
            width: 380px;
            text-transform: uppercase;
            cursor: pointer;
            height: 60px;
            font-size: 18px;
            line-height: 60px;
            letter-spacing: 0;
            margin: 0 auto;
            &.disabled {
              background-color: $ab_color--btn-gray-disabled;
              color: $color--white;
              cursor: default;
            }
            @include breakpoint($ab-small-down) {
              margin-top: 5px;
              font-family: $ab-font-family-gothic;
              width: 100%;
            }
          }
          .start-over {
            display: block;
            margin-top: 24px;
            text-transform: uppercase;
            letter-spacing: 0;
          }
        }
        input[type='password']::-ms-reveal,
        input[type='password']::-ms-clear {
          display: none;
        }
      }
    }
    .page-header {
      .page-header__title {
        font-family: $ab-bold-font-family;
        font-size: 39px;
        font-weight: bold;
        letter-spacing: 7px;
        padding-#{$ldirection}: 90px;
        text-align: #{$ldirection};
        text-transform: uppercase;
      }
      .page-header__subtitle {
        padding-#{$ldirection}: 90px;
        text-align: #{$ldirection};
        p {
          font-family: $ab-font-family-gothic;
          font-size: 24px;
          line-height: 1.3;
        }
      }
    }
    .confirmation {
      width: 100%;
      position: relative;
      border-top: 1px solid $ab--color--gray--lightest;
      margin-top: 30px;
      @include breakpoint($ab-small-down) {
        margin-top: 26px;
      }
      .appt-book-content-header {
        margin: 40px 0;
        display: none;
        p {
          width: 56%;
          margin: 18px auto;
        }
      }
      .confirmation-container {
        position: relative;
        max-width: 853px;
        overflow: hidden;
        margin: 30px auto;
        > div {
          float: #{$ldirection};
          width: 50%;
          min-height: 123px;
          padding: 18px 12px;
          text-align: #{$ldirection};
          overflow: hidden;
          font-size: 12px;
          line-height: 18.4px;
          font-family: $ab-font-family;
          text-transform: uppercase;
          @include breakpoint($ab-small-down) {
            float: none;
            width: 100%;
            min-height: auto;
            text-align: #{$ldirection};
            padding: 14px 0;
          }
          h4 {
            font-family: $ab-font-family;
            font-size: 12px;
            letter-spacing: 0;
            text-transform: uppercase;
            text-align: #{$ldirection};
            line-height: 18px;
            color: $ab--color--pantone-orange-21c;
          }
          span {
            display: block;
            text-align: #{$ldirection};
            margin: 0;
            color: $color--black;
            letter-spacing: 0;
          }
        }
        @include breakpoint($ab-small-down) {
          border-bottom: 0px;
          padding-bottom: 0px;
          margin-top: 34px;
        }
        .your-lessons {
          h4,
          span {
            text-align: #{$ldirection};
          }
          .services-name {
            float: #{$ldirection};
            padding-#{$rdirection}: 20px;
          }
          .duration-price {
            float: #{$ldirection};
          }
        }
        .date-time {
          .appt-date-cal {
            background: transparent
              url('/media/export/cms/makeup_services/appointment_booking/appointments/cal_date_picker_icon.png')
              no-repeat 10em 0.25em / 12px 12px;
            background-image: none;
            .add-cal-link {
              display: inline-block;
              cursor: pointer;
              font-family: $ab-font-family-gothic;
              background: $color--black;
              color: $color--white;
              font-size: 18px;
              height: 40px;
              padding: 0 20px;
              margin-top: 5px;
              line-height: 40px;
              @include breakpoint($ab-small-down) {
                padding-bottom: 0;
              }
            }
            .cals-container {
              width: 150px;
              display: none;
              margin-top: 5px;
              position: absolute;
              border: 1px solid $color--black;
              background-color: $color--white;
              .appt-book-link {
                border: none;
                display: block;
                padding: 8px 15px 0;
                &:first-child {
                  padding-top: 5px;
                }
                &:last-child {
                  padding-bottom: 5px;
                }
              }
              @include breakpoint($ab-small-down) {
                width: 100%;
              }
              &.expanded {
                display: block;
              }
            }
            .appt-book-link {
              font-size: 12px;
              line-height: 18.4px;
              font-family: $ab-font-family;
              letter-spacing: 0;
              border-color: 1px solid $color--black;
            }
          }
        }
        .artist {
          display: none;
          margin-top: 30px;
          border-top: 1px solid $ab--color--gray--lightest;
          width: 100%;
          padding-bottom: 33px;
          h4 {
            border: none;
            padding-bottom: 0;
          }
          @include breakpoint($ab-small-down) {
            display: none;
            margin-top: 0;
            border: none;
            h4 {
              border-bottom: 1px solid $ab--color--gray--lightest;
              padding-bottom: 5px;
            }
          }
        }
        .location {
          a {
            &.location-phone {
              color: $ab--color--gray-light_1;
              border-bottom: 1px solid $ab--color--gray-light_1;
              font-size: 24px;
              @include breakpoint($ab-small-down) {
                // font-size: 21px;
                font-size: 12px;
                border-bottom: 1px solid $color--black;
                color: $color--black;
              }
            }
          }
        }
      }
      .view-appts-container {
        margin-top: 35px;
        a.view-appts-button {
          font-family: $ab-font-family-gothic;
          background: $color--black;
          color: $color--white;
          display: inline-block;
          font-size: 18px;
          height: 60px;
          line-height: 60px;
          width: 380px;
          @include breakpoint($ab-small-down) {
            text-align: center;
            width: 100%;
          }
        }
        @include breakpoint($ab-small-down) {
          margin-top: 0;
        }
      }
      .rebook-appt-container {
        cursor: pointer;
        display: inline-block;
        margin: 0 auto;
        padding-top: 15px;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .my-appointments {
      width: 100%;
      position: relative;
      .error-messages {
        text-align: #{$ldirection};
      }
      #appt-book-my-appointments-no-signin {
        max-width: 874px;
        margin: 0 auto;
        padding: 0 10px;
        @include breakpoint($ab-small-down) {
          padding: 0;
          max-width: none;
        }
      }
      .sign_up-link-container,
      .sign-in-link-container {
        @include clearfix;
        h4 {
          margin-bottom: 0;
          font-size: 12px;
          color: $color--black;
          font-family: $ab-font-family;
          font-weight: normal;
          text-transform: none;
          line-height: normal;
          float: #{$ldirection};
          @include breakpoint($ab-small-down) {
            margin-bottom: 3px;
          }
          &.sign_up-head {
            padding-#{$rdirection}: 5px;
          }
          &.sign-in-head {
            padding-#{$ldirection}: 5px;
          }
        }
        a {
          font-size: 12px;
          color: $ab--color--pantone-orange-21c;
          border-color: $ab--color--pantone-orange-21c;
          float: #{$ldirection};
        }
      }
      .sign-in-link-container {
        h4 {
          @include breakpoint(max-width $width-xsmall - 1) {
            margin-#{$ldirection}: 0;
            text-indent: 55px;
            margin-top: -20px;
            line-height: 22px;
            padding-#{$ldirection}: 0;
          }
        }
        a {
          @include breakpoint($ab-small-down) {
            float: none;
          }
        }
      }
      .sign-in-container {
        margin: 35px 0 0;
        @include breakpoint($ab-small-down) {
          margin-top: 17px;
        }
        .sign-in-form-container {
          margin-top: 6px;
          input {
            padding: 0 18px;
            font-family: $ab-font-family;
            display: block;
            width: 49.3%;
            margin-bottom: 12px;
            height: 60px;
            font-size: 12px;
            letter-spacing: 0.02em;
            @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 767px) {
              font-size: 16px;
            }
            @include breakpoint($ab-small-down) {
              margin-bottom: 18px;
            }
            &.login__email {
              float: #{$ldirection};
              @include breakpoint($ab-small-down) {
                width: 100%;
                float: none;
              }
            }
            &.login__password {
              float: #{$rdirection};
              @include breakpoint($ab-small-down) {
                width: 100%;
                float: none;
              }
            }
            &.login__submit {
              width: 380px;
              float: none;
              font-size: 18px;
              font-family: $ab-font-family-gothic;
              letter-spacing: 0;
              background-color: $color--black;
              padding: 0 30px;
              line-height: 60px;
              margin: 38px auto 0;
              display: inline-block;
              &:hover {
                background-color: $color--black;
              }
              @include breakpoint($ab-small-down) {
                width: 100%;
                float: none;
                margin-top: 0;
              }
            }
          }
          .forgot_password_container {
            width: 100%;
            @include breakpoint($ab-small-down) {
              margin-bottom: 18px;
            }
            a.login_forgot {
              float: #{$ldirection};
              margin-#{$ldirection}: 51%;
              @include breakpoint($ab-small-down) {
                width: 100%;
                float: none;
                margin: 0;
              }
            }
          }
        }
      }
      .registration-container {
        margin: 35px 0 0;
        @include breakpoint($ab-small-down) {
          margin-top: 17px;
        }
        #my-appts-registration-form {
          margin-top: 6px;
          text-align: #{$ldirection};
          input {
            padding: 0 18px;
            font-family: $ab-font-family;
            display: block;
            width: 49.3%;
            margin-bottom: 12px;
            height: 60px;
            font-size: 12px;
            letter-spacing: 0.02em;
            float: #{$ldirection};
            @include breakpoint($ab-small-down) {
              margin-bottom: 18px;
              float: none;
              width: 100%;
            }
            &.registration-last-name,
            &.registration-mobile {
              float: #{$rdirection};
              @include breakpoint($ab-small-down) {
                float: none;
              }
            }
            &.registration__password {
              float: none;
              width: 100%;
            }
            &.registration__submit {
              width: 380px;
              float: none;
              font-size: 18px;
              font-family: $ab-font-family-gothic;
              letter-spacing: 0;
              background-color: $color--black;
              padding: 0 30px;
              line-height: 60px;
              margin: 38px auto 0;
              display: block;
              &:hover {
                background-color: $color--black;
              }
              @include breakpoint($ab-small-down) {
                width: 100%;
                float: none;
                margin-top: 18px;
              }
            }
          }
          .picker-checkbox {
            display: none;
          }
          .registration__privacy_policy-container {
            .picker {
              &-checkbox {
                display: block;
              }
              &-handle {
                border-color: $color--black;
                @include breakpoint($ab-small-down) {
                  margin-#{$rdirection}: 10px;
                }
              }
              &-flag {
                &:before {
                  color: $color--black !important;
                }
              }
            }
            .registration__privacy-list {
              width: 94.5%;
              color: $color--black;
              @include breakpoint($ab-small-down) {
                width: 87%;
              }
            }
          }
        }
      }
      #appt-book-my-appointments-no-appts {
        @include breakpoint($ab-small-down) {
          padding: 0 20px;
          text-align: center;
        }
        #appt-book-btn {
          color: white;
          display: inline-block;
          height: 60px;
          line-height: 60px;
          text-align: center;
          margin-bottom: 56px;
          @include breakpoint($ab-small-down) {
            width: 100%;
            margin-bottom: 0;
          }
        }
        .section-header {
          &__inner {
            font-size: 48px;
            font-family: $ab-font-family-gothic;
            letter-spacing: -0.02em;
            line-height: 57.6px;
            font-weight: normal;
            @include breakpoint($ab-small-down) {
              font-size: 30px;
              line-height: 36px;
            }
          }
        }
      }
      .appt-book-content-header {
        margin: 52px 0 0;
        //display: none;
        h3 {
          font-size: 48px;
          line-height: 57.6px;
          font-family: $ab-font-family-gothic;
          letter-spacing: 0.02em;
          margin: 0;
          @include breakpoint($ab-small-down) {
            font-size: 30px;
            line-height: 36px;
            letter-spacing: -0.02em;
          }
        }
      }
      .appointments-container {
        position: relative;
        width: auto;
        overflow: hidden;
        margin: 60px 0 0;
        @include breakpoint($ab-small-down) {
          margin: 8px 0 0;
          padding: 0;
        }
        .mobile-header {
          display: none;
        }
        h3.section-header {
          font-size: 48px;
          line-height: normal;
          letter-spacing: -0.02em;
          @include breakpoint($ab-small-down) {
            font-size: 29px;
            letter-spacing: -0.04em;
          }
        }
        &.current {
          margin-top: 37px;
          h3.section-header {
            @include breakpoint($ab-small-down) {
              margin-top: 21px;
              padding-bottom: 11px;
              text-align: center;
            }
          }
          .appointment-container {
            border-bottom: 1px solid $ab--color--gray--lightest;
            padding-bottom: 33px;
            @include breakpoint($ab-small-down) {
              padding-bottom: 49px;
            }
            &:last-child {
              border-bottom: none;
              @include breakpoint($ab-small-down) {
                padding-bottom: 9px;
              }
            }
            .appointment-details {
              max-width: 853px;
              margin: 0 auto;
            }
          }
        }
        &.past {
          background-color: none;
          height: auto;
          overflow: hidden;
          margin-top: 0;
          .appointment-container {
            max-width: none;
            margin: 0 20px;
            @include breakpoint($ab-small-down) {
              margin: 0;
              border-bottom: 1px solid $ab--color--gray--lightest;
            }
          }
          .past-appt-head {
            width: 100%;
            position: relative;
            text-align: center;
            a.view-link {
              font-family: $ab-font-family-gothic;
              font-size: 16px;
              text-transform: uppercase;
              color: $ab--color--gray-light_1;
              text-align: center;
              display: block;
              height: 62px;
              background-color: $color--gallery-approx;
              letter-spacing: 0;
              position: relative;
              border-bottom: 1px solid $color--white;
              line-height: 3.5;
              @include breakpoint($ab-small-down) {
                height: 77px;
                font-size: 18px;
                text-align: #{$ldirection};
                line-height: 4.5;
              }
            }
            .section-header {
              padding: 45px 0;
              margin-bottom: 46px;
              margin-top: 0;
              text-align: center;
              font-size: 48px;
              line-height: normal;
              letter-spacing: -0.02em;
              font-family: $ab-font-family-gothic;
              border-bottom: 1px solid $ab--color--gray--lightest;
              border-top: 1px solid $ab--color--gray--lightest;
              @include breakpoint($ab-small-down) {
                font-size: 30px;
                margin-top: 40px;
                padding: 11px 0;
                margin-bottom: 0;
              }
              .section-header .inner,
              .section-header__inner {
                color: $color--black;
                padding: 0 14px 0 18px;
                font-size: 48px;
                line-height: normal;
                letter-spacing: -0.02em;
                @include breakpoint($ab-small-down) {
                  font-size: 30px;
                  text-align: #{$ldirection};
                  padding: 0;
                }
              }
            }
            .appointment-header {
              @include clearfix;
              margin: 0 20px;
              padding: 10px 0 20px 0;
              border-bottom: 1px solid $ab--color--gray--lightest;
              @include breakpoint($ab-small-down) {
                display: none;
              }
              > div {
                float: #{$ldirection};
                width: 33.33%;
                text-align: #{$ldirection};
                font-size: 18px;
                line-height: 24px;
                font-family: $ab-font-family-gothic;
                letter-spacing: -0.01em;
                h4 {
                  font-size: 18px;
                  line-height: 24px;
                  font-family: $ab-font-family-gothic;
                  letter-spacing: -0.01em;
                }
              }
            }
            .column-width25 {
              > div {
                width: 25%;
              }
            }
          }
          .appointment-details {
            @include clearfix;
            margin: 0;
            border-bottom: 1px solid $ab--color--gray--lightest;
            padding-bottom: 0;
            @include breakpoint($ab-small-down) {
              border-bottom: none;
            }
            > div {
              width: 33.33%;
              text-align: #{$ldirection};
              padding: 20px 18px 20px 0;
              min-height: auto;
              span {
                display: inline;
              }
              @include breakpoint($ab-small-down) {
                width: 100%;
                padding: 0 0 0 5px;
              }
            }
            a.view-link {
              @include breakpoint($ab-small-down) {
                float: #{$rdirection};
                border-bottom: none;
                line-height: 60px;
                padding-#{$rdirection}: 5px;
                .hide-link-text {
                  display: none;
                }
                span {
                  border-bottom: 1px solid $color--black;
                  padding-bottom: 3px;
                }
              }
            }
            h4 {
              border: none;
            }
            .date-time {
              @include breakpoint($ab-small-down) {
                float: #{$ldirection};
                width: 85%;
                height: 60px;
                line-height: 60px;
              }
              .appt-date {
                &-day {
                  width: 115px;
                  display: inline-block;
                  @include breakpoint($ab-small-down) {
                    display: inline;
                    width: auto;
                    padding-#{$rdirection}: 30%;
                  }
                  @include breakpoint($bp--xsmall-down) {
                    padding-#{$rdirection}: 2%;
                    letter-spacing: -0.02em;
                  }
                }
                &-hours {
                  @include breakpoint($bp--xsmall-down) {
                    letter-spacing: -0.02em;
                  }
                }
              }
            }
            .location {
              &-direction-links {
                display: none;
              }
              @include breakpoint($ab-large-up) {
                span {
                  display: none;
                  &.location-name {
                    display: block;
                  }
                }
              }
              @include breakpoint($ab-small-down) {
                padding-bottom: 15px;
              }
            }
            .your-lessons {
              @include breakpoint($ab-small-down) {
                padding-bottom: 15px;
              }
            }
            .rebook-appt {
              &-btn {
                font-size: 12px;
                line-height: 18.4px;
                letter-spacing: 0;
                font-family: $ab-font-family;
              }
              @include breakpoint($ab-small-down) {
                padding-bottom: 20px;
              }
            }
          }
          .column-width25 {
            > div {
              width: 25%;
            }
          }
        }
      }
      .appointment-container {
        position: relative;
        max-width: none; //853px;
        overflow: hidden;
        margin: 35px auto 0;
        @include breakpoint($ab-small-down) {
          margin: 40px auto 0;
        }
        .appointment-details {
          overflow: hidden;
          font-size: 12px;
          line-height: 18.4px;
          font-family: $ab-font-family;
          padding-bottom: 30px;
          > div {
            float: #{$ldirection};
            width: 50%;
            padding: 18px 12px;
            text-align: #{$ldirection};
            overflow: hidden;
            font-size: 12px;
            line-height: 18.4px;
            font-family: $ab-font-family;
            text-transform: uppercase;
            @include breakpoint($ab-large-up) {
              min-height: 123px; //150px; fix equal height
            }
            @include breakpoint($ab-small-down) {
              float: none;
              width: 100%;
              min-height: auto;
              text-align: #{$ldirection};
              padding: 14px 0;
            }
            h4 {
              font-family: $ab-font-family;
              font-size: 12px;
              letter-spacing: 0;
              text-transform: uppercase;
              text-align: #{$ldirection};
              line-height: 18px;
              color: $ab--color--pantone-orange-21c;
            }
            span {
              display: block;
              text-align: #{$ldirection};
              margin: 0;
              color: $color--black;
              letter-spacing: 0;
            }
          }
          @include breakpoint($ab-small-down) {
            border-bottom: 0px;
            padding-bottom: 30px;
          }
          .your-lessons {
            h4,
            span {
              text-align: #{$ldirection};
            }
            .services-name {
              float: #{$ldirection};
              padding-#{$rdirection}: 20px;
            }
            .duration-price {
              float: #{$ldirection};
            }
          }
          .date-time {
            .appt-date-cal {
              background: transparent
                url('/media/export/cms/makeup_services/appointment_booking/appointments/cal_date_picker_icon.png')
                no-repeat 10em 0.25em / 12px 12px;
              background-image: none;
              .add-cal {
                &-title {
                  padding-top: 10px;
                }
                &-link {
                  display: inline-block;
                  cursor: pointer;
                  font-family: $ab-font-family-gothic;
                  background: $color--black;
                  color: $color--white;
                  font-size: 18px;
                  height: 40px;
                  padding: 0 20px;
                  margin-top: 5px;
                  line-height: 40px;
                }
              }
              .cals-container {
                width: 150px;
                display: none;
                margin-top: 5px;
                position: absolute;
                border: 1px solid $color--black;
                background-color: $color--white;
                .appt-book-link {
                  border: none;
                  display: block;
                  padding: 8px 15px 0;
                  &:first-child {
                    padding-top: 5px;
                  }
                  &:last-child {
                    padding-bottom: 5px;
                  }
                }
                @include breakpoint($ab-small-down) {
                  width: 100%;
                }
                &.expanded {
                  display: block;
                }
              }
              .appt-book-link {
                font-size: 12px;
                line-height: 18.4px;
                font-family: $ab-font-family;
                letter-spacing: 0;
                border-color: 1px solid $color--black;
              }
            }
          }
          .artist {
            display: none;
            margin-top: 30px;
            border-top: 1px solid $ab--color--gray--lightest;
            width: 100%;
            padding-bottom: 33px;
            h4 {
              border: none;
              padding-bottom: 0;
            }
            @include breakpoint($ab-small-down) {
              display: none;
              margin-top: 0;
              border: none;
              h4 {
                border-bottom: 1px solid $ab--color--gray--lightest;
                padding-bottom: 5px;
              }
            }
          }
          .location {
            a {
              &.location-phone {
                color: $ab--color--gray-light_1;
                border-bottom: 1px solid $ab--color--gray-light_1;
                font-size: 24px;
                @include breakpoint($ab-small-down) {
                  font-size: 12px;
                  color: $color--black;
                  border-bottom: 1px solid $color--black;
                }
              }
            }
          }
        }
        &:last-child {
          .appointment-details {
            margin-bottom: 0;
          }
        }
      }
      .appt-booking-links {
        margin: 60px 0 30px 0;
        @include breakpoint($ab-small-down) {
          margin: 0px 20px 20px 20px;
        }
        h4 {
          font-family: $ab-font-family;
          font-size: 19px;
          margin: 0 0 28px;
          letter-spacing: 0;
          color: $color--black;
        }
        a#view-makeup-lessons-btn {
          color: $color--black;
          font-size: 16px;
          letter-spacing: 0.02em;
          padding-bottom: 3px;
          border-bottom: 1px solid $color--black;
        }
      }
      .appointments-buttons {
        margin-bottom: 38px;
        @include breakpoint($ab-small-down) {
          margin-bottom: 0;
        }
        .appt-book-link {
          font-size: 12px;
          line-height: 20.4px;
          letter-spacing: 0;
          font-family: $ab-font-family;
          margin-#{$rdirection}: 36px;
          @include breakpoint($ab-small-down) {
            display: table;
            margin-bottom: 5px;
          }
        }
        .change-appt-btn {
          &.mobile {
            display: none;
          }
        }
        .rebook-appt-btn {
          margin-#{$rdirection}: 0;
        }
        a.appt-book-btn {
          color: $ab--color--gray-light_1;
          border: 1px solid $ab--color--gray-light_1;
          letter-spacing: -0.02em;
          padding: 0.6em 0.7em;
          margin-#{$rdirection}: 36px;
          font-size: 16px;
          background-color: $color--white;
          font-family: $ab-font-family-gothic;
          text-transform: uppercase;
          @include breakpoint($ab-small-down) {
            &.desktop {
              display: none;
            }
            &.cancel-appt-btn {
              float: #{$ldirection};
              font-size: 13px;
              height: 33px;
              letter-spacing: -0.02em;
              line-height: 1.3em;
              margin-#{$rdirection}: 0;
              padding: 0.6em 0.7em;
            }
          }
        }
      }
    }
    &-section-content.appointment-cancellation {
      width: 100%;
      position: relative;
      text-align: center;
      @include breakpoint($ab-small-down) {
        padding: 0 15px;
      }
      .appt-book-content-header,
      .confirm-content {
        .section-header {
          text-align: center;
        }
        .section-header {
          margin: 50px 0 30px 0;
          &__inner {
            text-align: center;
            font-family: $ab-font-family-gothic;
            font-size: 48px;
            line-height: 57.6px;
            color: $color--black;
            letter-spacing: -0.02em;
          }
          @include breakpoint($ab-small-down) {
            font-size: 30px;
            line-height: 36px;
            margin: 62px 0 40px 0;
            &__inner {
              font-size: 30px;
              line-height: 36px;
            }
          }
        }
        .require-confirm & {
          display: none;
        }
      }
      & .cancel-content,
      & .error-content,
      & .confirm-content {
        display: none;
        font-size: 12px;
        line-height: 18.4px;
        font-family: $ab-font-family;
        margin-bottom: 20px;
        p {
          font-size: 12px;
          line-height: 18.4px;
          font-family: $ab-font-family;
          margin-bottom: 40px;
        }
        .appt-book-link {
          font-size: 12px;
          font-family: $ab-font-family;
        }
      }
      .button-container {
        display: block;
        margin-top: 40px;
        text-align: center;
        .button {
          font-family: $ab-font-family-gothic;
          font-size: 18px;
          letter-spacing: -0.02em;
          background-color: $ab--color--gray-light_1;
          &:hover {
            background-color: $ab--color--gray-light_1;
          }
        }
        @include breakpoint($ab-small-down) {
          text-align: #{$ldirection};
          margin-top: 10px;
          .button {
            font-size: 14px;
          }
        }
      }
      &.require-confirm {
        .appt-book-content-header {
          display: none;
        }
        .confirm-content {
          display: block;
          .btn {
            min-width: 8em;
            @include breakpoint($bp--small-up) {
              min-width: 10em;
            }
            line-height: 4em;
            &__cancel {
              margin: 0 2em 0 0;
            }
          }
        }
      }
    }
    #appt-book-btn {
      margin-top: 20px;
      text-align: #{$ldirection};
      font-family: $ab-font-family-gothic;
      font-size: 18px;
      letter-spacing: -0.02em;
      background-color: $color--black;
      &:hover {
        background-color: $color--black;
      }
    }
    .appt-book-legal-text {
      display: none;
    }
  }
  .appt-book.services {
    .appt-book-legal-text {
      margin-#{$ldirection}: 80%;
      display: block;
    }
  }
  .appt-book-overlay {
    display: none;
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    bottom: 0;
    #{$rdirection}: 0;
    background-color: $color--white; //fallback
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10000;
    .overlay-content-container {
      position: absolute;
      display: block;
      margin-top: 100px;
      padding: 16px 25px;
      text-align: center;
      background-color: $color--black;
      border: 1px solid $color--black;
      z-index: 1;
      @include breakpoint($ab-small-down) {
        margin-top: 150px;
      }
      .overlay-close {
        position: absolute;
        display: block;
        #{$rdirection}: 15px;
        top: 15px;
        background: transparent
          url('/media/export/cms/makeup_services/appointment_booking/common/overlay_close_btn.png') no-repeat;
        background-size: 17px auto;
        width: 17px;
        height: 17px;
        cursor: pointer;
        z-index: 3;
        overflow: hidden; // for img replacement
        text-indent: 100%; // for img replacement
        white-space: nowrap; // for img replacement
      }
      .overlay-content {
        display: block;
        position: relative;
        text-align: #{$ldirection};
        h4 {
          font-family: $ab-font-family-gothic;
          color: $color--white;
          margin-bottom: 6px;
          letter-spacing: 0;
          margin-top: 7px;
          font-size: 24px;
          line-height: 29px;
          @include breakpoint($ab-small-down) {
            letter-spacing: 0;
            margin-bottom: 8px;
            margin-top: 13px;
          }
        }
        p {
          font-family: $ab-font-family;
          line-height: 20.4px;
          margin-top: 7px;
          font-size: 12px;
          letter-spacing: 0;
          color: $color--white;
          @include breakpoint($ab-small-down) {
            margin: 0 auto;
            width: 100%;
          }
        }
        a.button {
          background-color: $ab--color--gray-light_1;
          font-size: 16px;
          height: auto;
          letter-spacing: -0.015em;
          line-height: 1;
          margin-top: 20px;
          padding: 12px 20px;
        }
      }
    }
    &.appt-book-book-conflict-overlay .overlay-content-container {
      width: 500px;
      height: 140px;
      top: 50%;
      #{$ldirection}: 50%;
      margin-top: -70px;
      margin-#{$ldirection}: -260px;
      @include breakpoint($ab-small-down) {
        width: 300px;
        height: 232px;
        margin-#{$ldirection}: -150px;
        padding: 16px 30px;
      }
    }
    &.appt-book-services-overlay,
    &.appt-book-max-services-overlay,
    &.appt-book-services-unavailabe-overlay,
    &.appt-book-redeemable-services-overlay,
    &.appt-book-location-conflict-overlay {
      z-index: 9995;
      .overlay-content-container {
        width: 640px;
        height: 240px;
        top: 50%;
        #{$ldirection}: 50%;
        margin-top: -95px;
        margin-#{$ldirection}: -340px;
        @include breakpoint($ab-small-down) {
          width: 300px;
          height: 232px;
          margin-#{$ldirection}: -150px;
          margin-top: -30px;
          padding: 16px 30px;
        }
        h4 {
          margin-top: 50px;
          margin-bottom: 0;
          @include breakpoint($ab-small-down) {
            margin-top: 28px;
          }
        }
        p {
          margin-top: 0;
        }
        a.button {
          display: none;
        }
      }
    }
    &.appt-book-remove-service-overlay .overlay-content-container {
      #{$ldirection}: 50%;
      margin-#{$ldirection}: -200px;
      margin-top: 100px;
      top: 50%;
      width: 390px;
      @include breakpoint($ab-small-down) {
        margin-top: -11px;
        margin-#{$ldirection}: -150px;
        width: 300px;
        p {
          margin-bottom: 10px;
        }
      }
      .overlay-accept,
      .overlay-cancel {
        background-color: $color--white;
        color: $color--black;
        padding: 5px 10px;
        font-family: $ab-font-family-gothic;
      }
    }
    &.appt-book-datepicker-overlay {
      z-index: 1000;
      position: absolute;
      top: 808px; //870px; // fix position with dynamic height - javascript
      @include breakpoint($ab-small-down) {
        z-index: 10000;
        position: fixed;
        top: 0;
        #{$ldirection}: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        .date-picker {
          width: 100%;
          position: relative;
          &-container {
            overflow: auto;
            position: relative;
            width: 100%;
            opacity: 0.98;
            border: 1px solid #cccccc;
            background-color: white;
            // margin-#{$ldirection}: -15px;
            padding: 49px 15px 25px 15px;
            opacity: 0.98;
            height: 100vh;
            table {
              text-align: center;
              margin: 0 auto;
              width: 100%;
              font-family: $ab-font-family;
              max-width: 854px;
              border: none;
              th {
                background-color: $ab--color--gray_td_bg_default;
                border: none;
                text-align: center;
                font-size: 12px;
                padding: 15px 0;
                letter-spacing: 0;
                color: $color--black;
                text-transform: uppercase;
                &.hilite {
                  text-decoration: underline;
                }
              }
              td {
                border: none; //1px solid $color--iron-approx;
                text-align: center;
                cursor: default;
                font-size: 12px;
                letter-spacing: 0;
                padding: 0;
                height: 60px;
                line-height: 60px;
                background-color: $color--white;
                color: $ab--color--gray_td_text_default;
                border-bottom: 1px solid $ab--color--gray_table_border;
                &.hilite {
                  background-color: $ab--color--gray_td_bg_hilite;
                }
              }
              td.enabled {
                font-family: $ab-font-family;
                background-color: $color--white;
                color: $color--black;
                cursor: pointer;
                font-weight: bold;
                &:hover {
                  background-color: $color--white;
                  color: $color--black;
                }
              }
              td.selected,
              td.selected:hover {
                font-family: $ab-font-family;
                background-color: $color--black;
                color: $color--white;
              }
            }
          }
          &-close {
            background: transparent
              url('/media/export/cms/makeup_services/appointment_booking/common/close_btn_black.png') no-repeat center #{$rdirection};
            background-size: 17px;
            width: 17px;
            height: 27px;
            cursor: pointer;
            overflow: hidden;
            text-indent: 9999px;
            white-space: nowrap;
            position: absolute;
            #{$rdirection}: 15px;
            top: 25px;
          }
          .pikaday-container {
            padding: 0;
            .pika-single {
              z-index: 0;
              font-family: $ab-font-family;
              border: none;
              position: inherit;
              .pika-lendar {
                float: none;
                margin: 0;
                width: 100%;
                .pika-title {
                  font-size: 12px;
                  margin-top: 20px;
                  margin-bottom: 16px;
                  text-transform: capitalize;
                  .pika-prev.is-disabled,
                  .pika-next.is-disabled {
                    visibility: hidden;
                  }
                  .pika-label {
                    z-index: 0;
                    font-size: 12px;
                    padding-top: 0;
                    padding-bottom: 0;
                    &:last-child {
                      display: none;
                    }
                    .pika-select {
                      z-index: 0;
                      display: none;
                    }
                  }
                  .pika-prev,
                  .pika-next {
                    width: 53px;
                    height: 20px;
                    cursor: pointer;
                    overflow: hidden; // for img replacement
                    text-indent: 100%; // for img replacement
                    white-space: nowrap; // for img replacement
                  }
                  .pika-prev {
                    background: transparent
                      url('/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_left.png')
                      no-repeat center #{$ldirection};
                  }
                  .pika-next {
                    background: transparent
                      url('/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_right.png')
                      no-repeat center #{$rdirection};
                  }
                }
                table.pika-table {
                  border-collapse: collapse;
                  border-spacing: 0;
                  border: 1px solid $ab--color--gray_table_border;
                  td,
                  th {
                    border: none;
                    text-transform: uppercase;
                    background-color: transparent;
                    height: 60px; //35px;
                    padding: 0;
                    color: $color--black;
                  }
                  th {
                    abbr,
                    abbr[title] {
                      border: none;
                      cursor: default;
                    }
                    padding-top: 17px;
                  }
                  td {
                    .pika-button {
                      background-color: $color--white;
                      color: $color--black;
                      border-radius: 0;
                      box-shadow: none;
                      text-align: center;
                      font-size: 12px;
                      font-family: $ab-font-family;
                      height: 60px; //47px;

                      &:hover {
                        color: $color--black;
                        background-color: $color--white;
                      }
                    }
                    &.is-selected .pika-button {
                      background-color: $color--black;
                      color: $color--white;
                      &:hover {
                        background-color: $color--black;
                        color: $color--white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.calendar-view {
          .date-picker {
            &-container {
              table {
                &.day {
                  text-align: #{$ldirection};
                  tr {
                    width: 33%;
                    display: inline-block;
                    margin: 0 -1px;
                    position: relative;
                    text-align: center;
                  }
                  td {
                    display: none;
                    &.enabled {
                      display: inline;
                      border: none;
                      background-color: $color--black;
                      color: $color--white;
                      padding: 4px 8px;
                      margin: 4px;
                      &:before {
                        content: '';
                        position: absolute;
                        #{$ldirection}: 0;
                        #{$rdirection}: 0;
                        height: 1px;
                        background-color: $ab--color--gray_table_border;
                        bottom: 0;
                      }
                      &.selected {
                        background-color: $ab--color--pantone-orange-21c;
                      }
                    }
                  }
                }
              }
              .pikaday-container {
                .pika-single {
                  .pika-lendar {
                    table.pika-table {
                      td {
                        &.is-selected .pika-button {
                          background-color: $ab--color--pantone-orange-21c;
                        }
                      }
                    }
                  }
                }
              }
              &__no-slot {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                text-align: center;
                margin-top: 15px;
              }
            }
          }
        }
      }
      .overlay-content-container {
        width: 425px;
        top: 104px;
        #{$ldirection}: 50%;
        margin-#{$ldirection}: -213px;
        background-color: $color--white;
        border-top: none;
        border-color: $ab--color--gray--dropdown; // $ab--color--gray--lightest;
        padding: 0;
        @include breakpoint($ab-small-down) {
          top: 60px;
          padding-top: 65px;
          padding-bottom: 38px;
        }
        .pika-lendar {
          margin: 0;
          width: 100%;
        }
        .pika-single {
          color: $color--black;
          border: none;
          .pika-lendar {
            @include breakpoint($ab-small-down) {
              border-#{$rdirection}: 1px solid $ab--color--gray-light;
              width: 100%;
              padding-#{$rdirection}: 3%;
              padding-#{$ldirection}: 0.5%;
              margin: 0;
              &:last-child {
                border: none;
                padding-#{$rdirection}: 0.5%;
                padding-#{$ldirection}: 3%;
              }
            }
          }
        }
        .pika-title {
          padding: 0 10px;
          text-transform: capitalize;
          button {
            &:hover {
              background-color: transparent;
            }
          }
          select {
            display: none;
          }
        }
        .pika-label {
          font-family: $ab-font-family;
          font-size: 12px;
          letter-spacing: 0;
          @include breakpoint($ab-small-down) {
            font-size: 14px;
            letter-spacing: 1px;
          }
        }
        .pika-table {
          @include breakpoint($ab-small-down) {
            margin-top: 27px;
          }
          th {
            color: $color--black;
            font-family: $ab-font-family;
            border-bottom: none;
            letter-spacing: 0;
            font-size: 10px;
            line-height: 20px;
            @include breakpoint($ab-small-down) {
              font-size: 18px;
              padding: 0.7em 0 1em;
            }
          }
          abbr[title],
          abbr {
            border: none;
            text-decoration: none;
            cursor: default;
          }
          td {
            color: $color--black;
            font-family: $ab-font-family;
            border-bottom: none;
            letter-spacing: 0;
            font-size: 10px;
            @include breakpoint($ab-small-down) {
              font-size: 24px;
              padding: 0.47em 0;
            }
          }
        }
        .pika-button {
          background: $color--white;
          font-family: $ab-font-family;
          color: $color--black;
          border-radius: 0;
          font-size: 10px;
          padding: 20px;
          text-align: center;
          @include breakpoint($ab-small-down) {
            font-size: 24px;
            padding: 25px 20px;
            line-height: 5px;
          }
          &:hover {
            background-color: $color--black;
            color: $color--white;
            border-radius: 0;
          }
        }
        .is-selected {
          .pika-button {
            box-shadow: none;
            background-color: $color--black;
            color: $color--white;
          }
        }
        .pika-prev,
        .is-rtl .pika-next {
          background: transparent
            url('/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_left.png') no-repeat #{$ldirection}
            center;
          margin-#{$ldirection}: 10px;
        }
        .pika-next,
        .is-rtl .pika-prev {
          background: transparent
            url('/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_right.png') no-repeat #{$rdirection}
            center;
          margin-#{$rdirection}: 10px;
        }
        .pika-prev.is-disabled,
        .pika-next.is-disabled {
          visibility: hidden;
        }
        .overlay-close {
          z-index: 10000;
          top: -58px;
          background: transparent
            url('/media/export/cms/makeup_services/appointment_booking/appointments/date_arrow_copy.png') no-repeat
            scroll 95% 54% / 5px auto;
          width: 460px;
          height: 58px;
          #{$rdirection}: 0;
          text-indent: 9999px;
        }
      }
    }
    &.my-appointments-overlay .overlay-content-container {
      width: 440px;
      min-height: 200px;
      top: 50%;
      #{$ldirection}: 50%;
      margin-top: -70px;
      margin-#{$ldirection}: -250px;
      background-color: $color--black; //$color--white;
      color: $color--white; //$color--black;
      #confirm-cancel {
        p {
          font-family: $ab-font-family;
          letter-spacing: 0;
          font-size: 12px;
          margin: 30px 0 18px 0;
          line-height: 18.4px;
        }
        .button {
          width: 69px;
          height: 40px;
          background-color: $color--white;
          border: 1px solid $ab--color--gray-light_1;
          text-transform: uppercase;
          font-size: 18px;
          letter-spacing: -0.02em;
          text-align: center;
          line-height: 40px;
          padding: 0;
          color: $color--black;
          float: #{$ldirection};
          font-family: $ab-font-family-gothic;
          cursor: pointer;
          &.yes-btn {
            margin-#{$rdirection}: 36px;
            margin-#{$ldirection}: 107px;
            @include breakpoint($ab-small-down) {
              margin-#{$rdirection}: 0;
              margin-#{$ldirection}: 0;
            }
          }
          &.yes-btn-center {
            position: absolute;
            #{$ldirection}: 50%;
            transform: translate(-50%, -50%);
            margin: 20px 0 0 0;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      @include breakpoint($ab-small-down) {
        //width: 300px;
        //height: 232px;
        width: 310px;
        min-height: 232px;
        margin-#{$ldirection}: -155px; //-150px;
        margin-top: -70px; //-50px;
        #confirm-cancel {
          .button {
            width: 100%;
            height: 40px;
            line-height: 40px;
            margin: 6px 0;
            padding: 0;
            font-size: 18px;
          }
        }
      }
      .canceled {
        h4 {
          margin: 30px auto 12px;
          line-height: 18.4px;
          width: 100%;
          font-family: $ab-font-family;
          letter-spacing: 0;
          font-size: 12px;
          color: $color--white;
          @include breakpoint($ab-small-down) {
            width: auto;
          }
        }
        p {
          margin-top: 17px;
          margin-bottom: 23px;
          font-family: $ab-font-family;
          letter-spacing: 0;
          font-size: 12px;
          text-align: #{$ldirection};
          span {
            display: block;
            text-align: #{$ldirection};
            margin-bottom: 5px;
          }
        }
        .book-new-btn {
          padding: 0;
          letter-spacing: -0.02em;
          background-color: $color--white;
          font-family: $ab-font-family-gothic;
          font-size: 18px;
          height: 40px;
          line-height: 40px;
          display: block;
          text-align: center;
          color: $color--black;
          width: 260px;
          margin: 30px auto 7px;
          &:hover {
            text-decoration: underline;
          }
        }
        @include breakpoint($ab-small-down) {
          p {
            margin: 10px 0;
          }
          .book-new-btn {
            width: 100%;
          }
        }
      }
    }
  }
  /* Target Firefox only */
  @include breakpoint($ab-small-down) {
    @-moz-document url-prefix() {
      .appt-book .appt-book-location-selector .location-submit {
        line-height: 60px;
      }
    }
  }
  .appointments-page {
    .appt-book-sections-container {
      @include breakpoint($ab-small-down) {
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
      }
    }
  }
  .no-rgba .appt-book-overlay {
    background: transparent url(/media/export/cms/appointment_booking/common/overlay_tint.png) repeat;
  }

  // Appointment booking landing page
  .appointment-use-tout {
    position: relative;
    /* @include breakpoint($bp--large-down) {
      display: none;
    } */

    &__image {
      width: 100%;
      &__large {
        @include breakpoint($ab-small-down) {
          display: none;
        }
      }
      &__small {
        @include breakpoint($ab-large-up) {
          display: none;
        }
      }
    }
    &__caption {
      @include fill-rel-parent;
      &-inner {
        position: relative;
      }
      &-inner-inner {
        @include vertically-center($absolute-positioning: true);
        @include swap_direction(padding, 4% $spacing);
        top: 64%;
        text-align: #{$ldirection};
        width: 62.5%;
        position: absolute;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        @include swap_direction(margin, 0 auto);
        @include breakpoint($bp--medium-up) {
          width: 50%;
        }
        @include breakpoint($ab-large-up) {
          width: 50%;
        }
        @include breakpoint($bp--largest-up) {
          width: 60%;
        }
        @include breakpoint($ab-ipad-portrait) {
          width: 70%;
        }
        @include breakpoint($ab-small-down) {
          top: 92%; //92%;
          width: 100%;
        }
      }
    }
    &__hd-image {
      margin: 0;
    }
    &__title {
      @include heading-trade-large;
      @include h3();
      @include swap_direction(margin, 0);
      line-height: 1;
      height: 74px;
      background: transparent url('/media/export/cms/makeup_services/landing/hd_make_services.png') no-repeat center
        center;
      text-indent: -9999px;
      width: 317px;
      margin: 0 auto;
      @include breakpoint($bp--medium-up) {
        @include fontsize-rem($typesize--60px);
      }
      @include breakpoint($bp--xlarge-up) {
        @include fontsize-rem($typesize--100px);
      }
      @include breakpoint($bp--xsmall-down) {
        background-size: 55% auto;
        width: auto;
        height: 58px;
      }
      @include breakpoint(max-width $width-xsmall - 1) {
        height: 46px;
      }
    }
    &__body {
      @include body-mono();
      color: #ffffff;
      font-size: 12px;
      a {
        color: #ffffff;
      }
      &--dash {
        &:before {
          @include heading--small;
          content: '_';
          display: block;
          line-height: 0;
          margin-bottom: 36px;
        }
      }
    }
    &__top {
      padding: 46px 0;
      text-transform: uppercase;
      @include breakpoint($ab-ipad-portrait) {
        padding: 20px 0;
      }
      @include breakpoint($ab-small-down) {
        width: 65%;
        margin: 0 auto;
        padding: 10% 0 18% 0;
      }
      @include breakpoint($bp--small-down) {
        padding-bottom: 14%;
      }
      @include breakpoint($bp--xsmall-down) {
        width: 95%;
        margin: 0 auto;
      }
    }
    &__bottom {
      padding-top: 32px;
      @include breakpoint($ab-ipad-portrait) {
        padding-top: 12px;
      }
      @include breakpoint($ab-small-down) {
        color: #000;
        padding-top: 5%;
      }
      a {
        text-transform: none;
        @include breakpoint($ab-small-down) {
          color: #000;
        }
      }
    }
    &__cta {
      font-size: 18px;
      &.btn--fixed_width {
        width: 380px;
        @include breakpoint($ab-small-down) {
          width: 100%;
        }
      }
    }

    // alignment options
    &--align {
      &-raised {
        .appointment-use-tout__caption-inner-inner {
          @include transform(none);
          #{$ldirection}: 25%;
          #{$rdirection}: auto;
          text-align: #{$ldirection};
          top: 25%;
          &--no-body {
            @include transform(none);
            top: 20%;
            width: 75%;
            @include breakpoint($bp--xxlarge-up) {
              @include transform(none);
              top: 120px;
            }
          }
        }
      }
      &-center {
        .appointment-use-tout__caption-inner-inner {
          text-align: center;
          @include breakpoint($ab-large-up) {
            top: 50%;
          }
          @include breakpoint($bp--xlarge-up) {
            top: 60%;
            @include breakpoint(min-height 880px) {
              top: 68%;
            }
          }
          @include breakpoint($bp--xxlarge-up) {
            top: 70%;
            @include breakpoint(min-height 960px) {
              top: 70%;
            }
          }
          @include breakpoint($bp--xxxlarge-up) {
            top: 66%;
            @include breakpoint(min-height 1045px) {
              top: 66%;
            }
          }
          @include breakpoint($bp--wide-up) {
            top: 64%;
            @include breakpoint(min-height 1215px) {
              top: 64%;
            }
          }
          @include breakpoint($bp--xwide-up) {
            top: 61%;
            @include breakpoint(min-height 1305px) {
              top: 61%;
            }
          }
          @include breakpoint($bp--xxwide-up) {
            top: 59%;
            @include breakpoint(min-height 1500px) {
              top: 59%;
            }
          }
          @include breakpoint($ab-ipad-portrait) {
            top: 60%;
          }
          @include breakpoint($ab-small-down) {
            top: 40%;
            @include transform(none);
            @include breakpoint(max-height 376px) {
              top: 38%;
            }
          }
          @include breakpoint($bp--small-down) {
            top: 35%;
            @include breakpoint(min-height 360px) {
              top: 37%;
            }
            @include breakpoint(min-height 480px) {
              top: 25%; //36%
            }
          }
        }
      }
      &-left {
        .appointment-use-tout__caption-inner-inner {
          #{$ldirection}: 25%;
          #{$rdirection}: auto;
          text-align: #{$ldirection};
          width: 75%;
          .appointment-use-tout__body {
            width: 70%;
          }
        }
      }
      &-right {
        .appointment-use-tout__caption-inner-inner {
          #{$rdirection}: 25%;
          #{$ldirection}: auto;
          text-align: #{$rdirection};
        }
      }
      &-top-left {
        .appointment-use-tout__caption-inner-inner {
          @include transform(none);
          top: $spacing;
          #{$ldirection}: $spacing;
          #{$rdirection}: auto;
          padding-top: 0;
          padding-#{$ldirection}: 0;
          text-align: #{$ldirection};
        }
      }
      &-top-right {
        .appointment-use-tout__caption-inner-inner {
          @include transform(none);
          top: $spacing;
          #{$rdirection}: $spacing;
          #{$ldirection}: auto;
          padding-top: 0;
          padding-#{$rdirection}: 0;
          text-align: #{$rdirection};
        }
      }
    }
  }

  // classes we can use for positioning
  // appointment-use-tout__has-dash
  // appointment-use-tout__has-cta
  // appointment-use-tout__has-copy

  .appointment-use-tout--align-raised.appointment-use-tout__has-copy {
    .appointment-use-tout__caption-inner-inner {
      top: 10%;
    }
    &.appointment-use-tout__has-cta,
    &.appointment-use-tout__has-dash {
      .appointment-use-tout__caption-inner-inner {
        top: 0;
      }
    }
  }
  .section-book-appointment .site-container,
  .section-book-appointment-landing .site-container {
    @include breakpoint($bp--largest-up) {
      min-height: 450px;
    }
  }
  .section-book-appointment-landing {
    .site-container {
      @include breakpoint($bp--medium) {
        min-height: 740px;
      }
      @include breakpoint($ab-ipad-portrait) {
        min-height: 360px;
      }
    }
  }
  .section-lookbook {
    .site-container {
      min-height: 550px;
      @include breakpoint($bp--large-down) {
        min-height: 430px;
      }
    }
  }
  .section-book-appointment,
  .section-book-appointment-landing,
  .section-lookbook,
  .section-my-appointments {
    @include breakpoint($ab-ipad-portrait) {
      .site-header {
        height: 120px;
      }
    }
  }

  // Appointment booking Menu
  .submenu {
    &__header {
      .appointment-nav__link {
        border-bottom: 1px solid transparent;
        @include breakpoint(max-width $width-largest) {
          &.appointment-header-nav__link {
            font-size: 22px;
          }
        }
      }
    }
  }
  @include breakpoint($ab-large-up) {
    .appointment-nav {
      &.submenu--sticky,
      &.responsive-container {
        max-width: 100%;
        /* top: auto;
        margin-top: -61px; */
        // top: 120px;
      }
      .submenu {
        &__content {
          max-width: 1281px;
          margin: 0 auto;
        }
        &__header {
          padding-#{$ldirection}: 10px;
        }
      }
    }
  }
  @include breakpoint($ab-ipad-portrait) {
    .appointment-nav {
      border-bottom: 1px solid $ab--color--gray--lighter;
      &.submenu--sticky,
      &.responsive-container {
        max-width: 100%;
        position: fixed;
        top: 60px;
        z-index: 9995;
        background-color: $color--white;
        width: 100%;
        margin: 0 auto;
        height: 60px;
      }
      .submenu {
        &__header {
          border-bottom: none;
          border-#{$rdirection}: 1px solid $ab--color--gray--lightest;
          float: #{$ldirection};
          text-align: #{$ldirection};
          width: 25%;
          h3 {
            font-size: 2.5rem;
            line-height: 30px;
            margin: 12px 0 12px;
          }
        }
        &__nav {
          border-bottom: none;
          float: #{$ldirection};
          padding: 0;
          li {
            display: block;
            float: #{$ldirection};
            padding: 0 15px;
          }
          a {
            line-height: 1;
            margin-top: 22px;
            padding: 0;
          }
        }
      }
    }
  }
  .account-nav {
    .sidebar-menu {
      &__item {
        &.my_appointments {
          &.my_appt_pc {
            @include breakpoint($bp--xsmall-down) {
              display: none;
            }
          }
          &.my_appt_mob {
            @include breakpoint($bp--small-up) {
              display: none;
            }
          }
        }
      }
    }
  }
  .look-book {
    position: relative;
    width: 100%;
    #{$ldirection}: 0;
    top: 0;
    &__slide {
      color: $color--black;
      display: none;
      background-color: $color--white;
      &:first-child {
        display: block;
      }
      .slick-initialized & {
        display: block;
      }
      &__inner {
        margin: 0 auto;
        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0;
        &_img {
          @include breakpoint($ab-large-up) {
            &--center {
              width: auto;
            }
            &--right {
              float: #{$rdirection};
              width: 70%;
            }
            &--left {
              float: #{$ldirection};
              width: 70%;
            }
          }
        }
      }
      &__link {
        display: block;
        width: 100%;
        height: 100%;
        color: $color--gray--black;
        text-decoration: none;
      }
      &__wrap {
        height: auto;
        padding: 0;
        max-width: 1280px;
        margin: 0 auto;
      }
      &__abstract {
        position: absolute;
        top: 0;
        text-align: center;
        padding: 0 15px;
        max-width: 1024px;
        &__inner {
          margin-top: 0;
          @include breakpoint($ab-small-down) {
            margin-top: 75px;
            margin-bottom: 50px;
          }
          #{$lb--light--class} & {
            color: $color--white;
          }
        }
        &--default-y-pos {
          top: 34px;
          @include breakpoint($ab-small-down) {
            top: auto;
          }
        }
        &--center {
          width: 100%;
          .look-book__abstract__inner {
            margin-#{$ldirection}: auto;
            margin-#{$rdirection}: auto;
          }
        }
        &--right {
          #{$rdirection}: 0;
        }
        &--align-left {
          text-align: #{$ldirection};
        }
        &--align-right {
          text-align: #{$rdirection};
        }
        @include breakpoint($ab-small-down) {
          position: relative;
          color: $color--black;
          .look-book__link {
            font-size: 12px;
            letter-spacing: 0.24em;
            border-top: none;
            border-#{$ldirection}: none;
            border-#{$rdirection}: none;
            border-bottom-width: 3px;
            padding: 0 0 6px;
            &:hover {
              border-color: $color--white;
            }
          }
        }
      }
      &__headline {
        width: 340px;
        text-transform: uppercase;
        &__header {
          font-size: 30px;
          margin: 0;
          font-family: $ab-font-family-gothic;
          @include breakpoint($ab-small-down) {
            font-size: 24px;
            letter-spacing: -1px;
          }
        }
        &__subheader {
          font-size: 24px;
          text-transform: uppercase;
          @include breakpoint($ab-small-down) {
            font-size: 18px;
          }
        }
      }
      &__description {
        font-size: 12px;
        margin: 0;
        padding: 0;
        width: 324px;
        font-family: $ab-font-family;
        line-height: 18.4px;
        letter-spacing: 0;
        @include breakpoint($ab-ipad-portrait) {
          width: 300px;
        }
        &__subheader {
          font-size: 12px;
          line-height: normal;
          margin-top: 5px;
          text-transform: none;
          letter-spacing: 0;
          font-family: $ab-font-family-gothic;
          @include breakpoint($ab-small-down) {
            font-size: 18px;
          }
        }
        p {
          font-size: 12px;
          line-height: 18.4px;
          margin: 0;
          padding-top: 70px;
          @include breakpoint($ab-small-down) {
            display: none;
          }
        }
        &__link {
          background: $color--black;
          color: $color--white;
          font-size: 16px;
          font-family: $ab-font-family-gothic;
          letter-spacing: 0.02em;
          line-height: 60px;
          margin: 31px 0 0 0;
          text-decoration: none;
          text-transform: uppercase;
          text-align: center;
          display: inline-block;
          width: 240px;
          border: none;
          &:hover,
          &:active,
          &:visited {
            background: $color--black;
            color: $color--white;
          }
          @include breakpoint($ab-small-down) {
            display: none;
          }
        }
      }
      &__image {
        width: 100%;
      }
    } // slide

    &__action_buttons {
      #{$ldirection}: 3.45%;
      position: absolute;
      top: 0;
      z-index: 1;
      display: none;
      @include breakpoint($ab-small-down) {
        display: block;
        #{$ldirection}: 0;
        width: 100%;
        &_wrapper {
          max-width: 768px;
          margin: 0px auto;
          padding: 0 14px;
        }
      }
      &_slick-action {
        &.pause,
        &.play {
          top: 0;
          cursor: pointer;
          position: relative;
          #{$ldirection}: 13px;
          display: none;
          &:before {
            content: '';
          }
        }
      } // slick action
      &_slick-dots {
        text-align: center;
        margin-top: 36px;
        button::before {
          content: '';
        }
        button {
          width: 11px;
          height: 11px;
          border-radius: 20px;
          background: $ab--color--gray-dot-look;
          border: 1px solid $ab--color--gray-dot-look;
        }
        li {
          margin: 0 8px;
          width: 28px;
          height: 11px;
          vertical-align: middle;
          display: inline;
          button {
            width: 11px;
            height: 11px;
            text-indent: -9999px;
            &:hover,
            &:focus {
              width: 11px;
              height: 11px;
              background: $color--black;
              &:before {
                content: '';
              }
            }
          }
          &.slick-active button {
            width: 11px;
            height: 11px;
            background: $color--black;
            &:before {
              color: $color--black;
              opacity: 0.6;
            }
          }
        }
      }
    } //__action-buttons

    &__debug-layer {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      opacity: 0.5;
    }
    &__panel {
      background-color: #ddd;
      width: 100%;
      position: absolute;
      height: 427px;
      @include breakpoint($ab-small-down) {
        height: auto;
      }
      .desc {
        font-size: 25px;
        font-weight: bold;
        margin: 54px 0 0 47px;
      }
      &__nav {
        z-index: 100;
        position: relative;
        top: 125px;
        margin: 0 auto;
        width: 1280px;
        padding: 0 15px;
        color: $color--black;
        #{$lb--light--class} & {
          color: $color--white;
        }
        @include breakpoint($ab-small-down) {
          display: none;
        }
        > span {
          float: #{$ldirection};
        }

        // Slick navigation
        .slick-prev,
        .slick-next {
          color: $color--black;
          #{$lb--light--class} & {
            color: $color--white;
          }
          height: auto;
          font-size: 12px;
          margin-top: 0;
          //top: 100px;
          position: relative;
          float: #{$ldirection};
          width: auto;
          &:before {
            font-size: 12px;
            color: $color--black;
            #{$lb--light--class} & {
              color: $color--white;
            }
          }
          span {
            border-bottom: 1px solid $color--black;
            text-transform: uppercase;
            #{$lb--light--class} & {
              border-bottom: 1px solid $color--white;
            }
          }
        }
        .slick-prev {
          #{$ldirection}: auto;
          margin-#{$rdirection}: 5px;
          &:before {
            content: '<';
            margin-#{$rdirection}: 5px;
            font-size: 12px;
            font-family: 'slick';
            opacity: 0.85;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }
        .slick-next {
          #{$rdirection}: auto;
          margin-#{$ldirection}: 5px;
          &:after {
            content: '>';
            margin-#{$ldirection}: 5px;
            font-size: 12px;
            font-family: 'slick';
            opacity: 0.85;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: $color--black;
            #{$lb--light--class} & {
              color: $color--white;
            }
          }
          &:before {
            position: static;
            content: '';
          }
        }
      }
    } // look-book__panel
    &__inner {
      &.slick-slider {
        margin-bottom: 0;
      }
    }
  } //end look-book
  .no-csstransitions {
    .appt-book-sections-container {
      .appt-book-location-selector,
      .artist-select-container,
      .counter-select-container {
        .ui-select {
          border: 1px solid #cccccc;
          margin: 0 auto;
          overflow: hidden;
          z-index: 0;
          select {
            background: transparent;
            border: 0 !important;
            display: block;
            width: 120% !important;
            white-space: nowrap;
            z-index: 1;
          }
        }
      }
      .appt-book-location-selector {
        .ui-select {
          background: transparent url('../../mac_base/images/fallbacks/corner-triangle.png') no-repeat scroll 97% 54% /
            12px auto;
          width: 487px;
        }
      }
      .artist-select-container,
      .counter-select-container {
        .ui-select {
          background: transparent url('../../mac_base/images/fallbacks/corner-triangle.png') no-repeat scroll 95% 54% /
            12px auto;
          width: 426px;
          select {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  } //end ie
  .appt-artist-overlay {
    .icon--remove {
      font-size: 15px;
      #{$rdirection}: 0;
      &:before {
        font-weight: bold;
      }
    }
  }
  .app-artist-info {
    .slick-slide {
      width: 295px;
    }
    .slick-list {
      margin: 25px 38px 0 14px;
      @include breakpoint($ab-small-down) {
        margin: 10px 30px 0 -10px;
      }
    }
    .slick-track {
      @include breakpoint($ab-small-down) {
        #{$ldirection}: 7px;
        margin-top: 15px;
      }
    }
    .slick-prev {
      #{$ldirection}: -8px;
      &:before {
        content: '\2039';
        color: $ab--color--gray--black;
        font-size: 50px;
      }
    }
    .slick-next {
      #{$rdirection}: 20px;
      &:before {
        content: '\203A';
        color: $ab--color--gray--black;
        font-size: 50px;
      }
    }
    .slick-dots {
      @include breakpoint($ab-small-down) {
        bottom: -35px;
      }
      li {
        @include breakpoint($ab-small-down) {
          display: inline-block;
        }
        button {
          &:before {
            @include breakpoint($ab-small-down) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .artist-bio {
    margin-#{$rdirection}: 5px;
    margin-#{$ldirection}: 15px;
    @include breakpoint($ab-small-down) {
      margin: 0;
      margin-#{$rdirection}: 20px;
    }
    border-radius: 4px;
    border: 1px solid $ab--color--gray--lighter;
    overflow: hidden;
    .artist-image {
      padding: 0 0 1rem;
      img {
        width: 100%;
        height: auto;
      }
    }
    .artist-details {
      padding: 0px 0px 10px 10px;
      text-align: left;
      background-color: $ab--color--white;
      .artist_pre_header {
        text-transform: uppercase;
        font-size: 10px;
      }
      .artist-title {
        word-wrap: break-word;
        .artist-header {
          font-size: 12px;
          font-weight: bold;
          text-transform: capitalize;
        }
      }
      .artist-description {
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 16px;
      }
    }
  }
  @if $appt_booking_static_header == true {
    .appt-book-page-header-menu {
      .content {
        &:has(.content > .submenu--sticky .appointment-nav) {
            height: 65px;
        }
      }
    }
  }
} //end appt_booking mixin

.virtual-service-message {
  color: $lux-error;
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
}
